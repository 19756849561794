import React, { Component } from 'react';
import * as d3 from 'd3';
const moment = require('moment');

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.tooltipRef = React.createRef();
        this.state = {
            width: 0
        };
    }

    componentDidMount() {
        this.setState({ width: this.getParentWidth() }, () => {
            this.drawLineChart();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        d3.select(this.canvasRef.current).selectAll("svg").remove();
        this.drawLineChart();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            width: this.getParentWidth()
        });
    }

    getParentWidth() {
        const parentWidth = this.canvasRef.current ? this.canvasRef.current.parentElement.offsetWidth : 0;
        return parentWidth;
    }

    parseWeek = (week) => {
        const [year, weekNumber] = week.split("-W");
        const date = new Date(year);
        const dayOfYear = (weekNumber - 1) * 7; // 0-based week number
        date.setMonth(0);
        date.setDate(1 + dayOfYear);
        return date;
    }

    formatDateToISOWeek = (date) => {
        const momentDate = moment(date);
        // not sure why + 1 is needed maybe remove later
        const year = momentDate.isoWeekYear() + 1;
        const weekNumber = momentDate.isoWeek();
        return `${year}-W${String(weekNumber).padStart(2, '0')}`;
    }

    drawLineChart() {
        if (!this.props.data) {
            return;
        }
        let data = [].concat(this.props.data);
        data.sort((a, b) => {
            if (a.week > b.week) {
                return 1;
            } else {
                return -1;
            }
        })

        const margin = { top: 20, right: 30, bottom: 40, left: 50 };
        const width = this.state.width - margin.left - margin.right;
        const height = 150 - margin.top - margin.bottom;

        const zeroData = [];
        let startDate = data[0].week;
        for (let i = 0; i < 2; i++) {
            let s = startDate.split("-W");
            let weekString = parseInt(s[1]);
            weekString -= (i + 1);
            weekString = s[0] + "-W" + weekString;
            zeroData.push({
                week: weekString,
                mean: 0
            });
        }

        //data = zeroData.reverse().concat(data);

        // check if svg exists
        if (d3.select(this.canvasRef.current).selectAll("svg")._groups[0].length > 0) {
            d3.select(this.canvasRef.current).selectAll("svg").remove();
        }

        const svg = d3.select(this.canvasRef.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleTime()
            .domain(d3.extent(data, d => this.parseWeek(d.week)))
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([0, 100])
            .range([height, 0]);


        const yGridlines = d3.axisLeft(yScale)
            .tickSize(-width)  // negative width to draw lines to the left
            .tickFormat("");   // no labels next to gridlines

        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(0, 0)`)
            .call(yGridlines);

        const xGridlines = d3.axisBottom(xScale)
            .tickSize(-height)  // negative height to draw lines upwards
            .tickFormat("");    // no labels below gridlines

        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(0, ${height})`)
            .call(xGridlines);


        const line = d3.line()
            .x(d => xScale(this.parseWeek(d.week)))
            .y(d => yScale(d.mean * 100));

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "#1677ff")
            .attr("stroke-width", 2)
            .attr("d", line);

        const circles = svg.selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", d => xScale(this.parseWeek(d.week)))
            .attr("cy", d => yScale(d.mean * 100))
            .attr("r", 5)
            .attr("stroke", "#1677ff")
            .attr("stroke-width", 2)
            .attr('fill', '#fff')

        // Create the tooltip element and hide it initially
        let tooltip = d3.select(this.tooltipRef.current);
        if (tooltip.empty()) {
            console.log('tooltip creation');
            tooltip = d3.select("body")
                .append("div")
                .attr("class", "d3CustomTooltip")
                .attr("ref", this.tooltipRef)
                .style("opacity", 0);
        }

        svg.append("rect")
            .attr("width", width)
            .attr("height", height)
            .attr("fill", "transparent");

        // Attach mouse events to the transparent rectangle
        svg.select("rect").on("mousemove", () => {
            const parentRect = this.canvasRef.current.getBoundingClientRect();
            const mouseX = d3.event.clientX - parentRect.left - margin.left;  // Adjust for margin
            const mouseY = d3.event.clientY - parentRect.top;
            const mouseDate = xScale.invert(mouseX);

            let minDistance = Infinity;
            let closestPoint = null;
            let closestPointIdx = -1;

            data.forEach((point, idx) => {
                const pointX = xScale(this.parseWeek(point.week));
                const distance = Math.abs(pointX - mouseX);

                if (distance < minDistance) {
                    minDistance = distance;
                    closestPoint = point;
                    closestPointIdx = idx;
                }
            });


            if (closestPoint) {
                circles.attr("r", point => point === closestPoint ? 10 : 8);

                const tooltipWidth = 150; // Approximate width of the tooltip
                let tooltipLeft = xScale(this.parseWeek(closestPoint.week)) + margin.left + 15;
                const tooltipTop = yScale(closestPoint.mean * 100) + margin.top - 30;

                // Check if the tooltip goes offscreen to the right, and adjust its position if necessary
                const adjustedLeft = (tooltipLeft + tooltipWidth > this.state.width) ? (tooltipLeft - tooltipWidth - 30) : tooltipLeft;

                tooltip.transition().duration(100).style("opacity", .9);
                tooltip.html(`Week of: ${moment.utc(this.parseWeek(closestPoint.week)).format('MM/DD/YYYY')}<br/>Mean: ${(closestPoint.mean * 100).toFixed(2)}%`)
                    .attr("class", "d3CustomTooltip")
                    .style("position", "absolute")
                    .style("left", adjustedLeft + "px")
                    .style("top", tooltipTop + "px");
            }
        }).on("mouseout", () => {
            tooltip.transition().duration(200).style("opacity", 0);
            circles.attr("r", 8); // Reset circles
        });


        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale));

        svg.append("g")
            .attr("class", "y-axis")
            .call(d3.axisLeft(yScale)
                .ticks(5) // Set the number of ticks
                .tickFormat(d => `${d}%`))// Format the tick labels);
    }

    render() {
        return (
            <div className='d3-box-chart relative'>
                <div ref={this.canvasRef}></div>
                <div ref={this.tooltipRef}></div>
            </div>
        );
    }
}

export default LineChart;
