import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import ColType from '.././Types'
import {  Form,  Button, Row, Col, Checkbox } from 'antd'
import DistrictSearch from './DistrictSearch'
const FormItem = Form.Item

// This page can only be accessed by us, it is how you add admins that then can add other users.
class ChangeDistrictSandboxStatus extends Component {
  state = {
    districtInfo: [],
  }

  componentDidMount() {

  }

  setDistrict = (districtObj) => {
      console.log("set district", districtObj)
      if (districtObj.admin.hasOwnProperty("isLocked") && 
          districtObj.admin.isLocked) {
            this.props.form.setFieldsValue({"isLocked": districtObj.admin.isLocked})
      }
      else {
          this.props.form.setFieldsValue({"isLocked": false})
      }
      let districtInfo = this.state.districtInfo
      districtInfo.push(districtObj)
      this.setState({
          districtInfo: districtInfo,
      })
  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        if (this.state.districtInfo.length > 0) {
            var admin = this.state.districtInfo[0].admin
            console.log("districtId", admin.districtId)
            db.collection(ColType.users)
            .where("districtId", "==", admin.districtId)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  var user = flattenDoc(doc)
                  // can reset this field to true even if the user already
                  // has the property
                  if (values.isDistrictSandbox) {
                    user["isSandbox"] = true
                    // confusing key, whether I set it or not, if it goes through
                    // here I set it for the district, the user didnt just set it
                    // for themselves in the normal sandbox process. This is so they
                    // don't have to verify their emails if I set their sandbox stauts
                    // as that is very confusing for the user (we don't make them verify
                    // their email if I create their account, which I probably did if we
                    // are setting their sandbox status artificially here).
                    user["isSandboxSetByDistrict"] = true
                  }
                  // else remove isSandbox from the user, 
                  // don't need to remove isSandboxSetByDistrict, that only matters if they
                  // have a isSandbox key, which is being removed here.
                  else {
                    if (user.hasOwnProperty("isSandbox")) {
                        user["isSandbox"] = false
                        // setting this again anyways, shouldnt matter
                        user["isSandboxSetByDistrict"] = true
                    }
                  }
                  console.log("user before update", user)
                  db.collection(ColType.users)
                    .doc(user.id)
                    .update(user)
                    .then(() => {
                        console.log("user update success", user)
                    })
                    .catch((e) => {
                        console.log("user update error")
                    })
                })

            })
        }
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 25 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 25 },
        sm: { span: 17 },
      },
    }

    return (
        <div>
          <SuperAdminHeader menuKey={'change-sandbox-status'} />
          <div className="mt-150 p-3">
              <Row gutter={48}>
                  <Col span={12}>
      <div className="">
        <DistrictSearch onDistrictFound={this.setDistrict}/>
      </div>
      </Col>
      <Col span={12}>
      <div className="border br-4 p-3">
          <div className="font-20 mb-3">
          {this.state.districtInfo.length === 0 ?
          <div>District: No district selected</div>
          : ''}
          {this.state.districtInfo.map((districtInfo, idx) => {
               return <div key={"district-" + districtInfo.district.id}>
                   District: {districtInfo.district.district}</div>
          })}
          <div className="font-14">
              To set false, click and unclick the checkbox. You get a required
              form error on the checkbox if you try to submit without doing this first.
          </div>
          </div>

      <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem {...formItemLayout} label="Set Users as Sandbox">
            {getFieldDecorator('isDistrictSandbox', {
              rules: [{ required: true, message: 'Please select.' }],
            })(
             <Checkbox size={"large"}>
                 <span className="pl-1">
                    Set All Users in District as Sandbox</span></Checkbox>
            )}
          </FormItem>
          <FormItem className="mb-0">
            <Button
              size={"large"}
              disabled={this.state.districtInfo.length === 0}
              type="primary"
              htmlType="submit"
              className="login-form-button text-align-center w-100"
            >
              Submit
            </Button>
          </FormItem>
        </Form>
        </div>
      </Col>
      </Row>
      </div>
      </div>
    )
  }
}
export default Form.create()(ChangeDistrictSandboxStatus)