import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import IEPDrafts from './IEPDrafts'
import {
  flattenDoc, getIDFromURL, getQueryStringParam,
} from '../.././Util'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import { Layout, Button, Icon, Select, Form, DatePicker, Input } from 'antd'
import DraftStepContainer from './DraftStepContainer'
const { Content } = Layout
const Option = Select.Option
const FormItem = Form.Item
const { TextArea } = Input
var moment = require('moment')

class TeacherAddIEPGoalInformation extends Component {
  state = {
    teacherId: null,
    studentId: null,
    teacher: null,
    student: null,
    iepDraft: null,
    students: [],
    studentDict: {},
    iepDrafts: [],
    presentLevels: {},
    tabKey: '1',
    stepNum: 1,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var draftId = getQueryStringParam('draft')
    console.log(teacherId, studentId, draftId)

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      // this step needs student for the render before the iepDraft
      // is pulled.
      db.collection(ColType.student)
        .doc(studentId)
        .get()
        .then((doc) => {
          var student = flattenDoc(doc)
          this.setState({
            student: student,
          }, () => {
            db.collection(ColType.iepDrafts)
              .doc(draftId)
              .get()
              .then((doc) => {
                console.log(doc.id, '=>', doc.data())
                this.setState({
                  iepDraft: flattenDoc(doc),
                  iepDraftMounted: true,
                }, () => {
                  var iepDraft = this.state.iepDraft
                  if (iepDraft.presentLevel &&
                    !(Object.keys(iepDraft.presentLevel).length === 0 &&
                      iepDraft.presentLevel.constructor === Object)) {
                    // date picker needs the date to be a moment for the form
                    // convert it if its a string (its passed to the next form as a string)
                    if (iepDraft.presentLevel.hasOwnProperty('selNormDate') &&
                      !(moment.isMoment(iepDraft.presentLevel.selNormDate))) {
                      iepDraft.presentLevel.selNormDate =
                        moment(iepDraft.presentLevel.selNormDate, "MM/DD/YYYY")
                    }
                    if (iepDraft.presentLevel.hasOwnProperty('elaNormDate') &&
                      !(moment.isMoment(iepDraft.presentLevel.elaNormDate))) {
                      iepDraft.presentLevel.elaNormDate =
                        moment(iepDraft.presentLevel.elaNormDate, "MM/DD/YYYY")
                    }
                    if (iepDraft.presentLevel.hasOwnProperty('mathNormDate') &&
                      !(moment.isMoment(iepDraft.presentLevel.mathNormDate))) {
                      iepDraft.presentLevel.mathNormDate =
                        moment(iepDraft.presentLevel.mathNormDate, "MM/DD/YYYY")
                    }
                    console.log("passed state", iepDraft.presentLevel)
                    if (this.state.iepDraft.step.stepNum > this.state.stepNum) {
                      this.props.form.setFieldsValue(iepDraft.presentLevel)
                    }
                  }
                })
              })
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)
        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  // add a new event to the teacher's events
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.hasOwnProperty('selNormDate')) {
          values.selNormDate = moment.utc(values.selNormDate._d).format("MM/DD/YYYY")
        }
        if (values.hasOwnProperty('elaNormDate')) {
          values.elaNormDate = moment.utc(values.elaNormDate._d).format("MM/DD/YYYY")
        }
        if (values.hasOwnProperty('mathNormDate')) {
          values.mathNormDate = moment.utc(values.mathNormDate._d).format("MM/DD/YYYY")
        }
        if (!values.additionalInformation) {
          delete values.additionalInformation
        }
        console.log(values)

        var newState = this.state.iepDraft
        newState.presentLevel = values
        newState.presentLevel.subject = newState.subject
        newState.step = { stepNum: this.state.stepNum + 1, path: 'select' }

        db.collection(ColType.iepDrafts)
          .doc(this.state.draftId)
          .set(newState, { merge: true })
          .then(() => {
            this.props.history.push(
              {
                pathname: '/teacher/add-goal-select/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId +
                  '&draft=' + this.state.draftId,
                state: newState, // pass state to confirm page
              }
            )
          })
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    const formItemBlockLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                {/* 
                <div className="text-center">
                  <img src="/draft-plan/3.png" alt="steps" />
                </div>
                */}
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={this.state.iepDraftMounted && this.state.student}
                >
                  <div>

                    {this.state.iepDraftMounted && this.state.student ?
                      <div>
                        <div className="w-800 m-lr-auto background-fff br-15">
                          <div className='p-4 bkg-purple br-15'>
                            <div className='flex flex-v-center w-100'>
                              <div>
                                {this.props.teacherBaseProps &&
                                  this.props.teacherBaseProps.studentDict &&
                                  this.props.teacherBaseProps.studentDict
                                    .hasOwnProperty(this.state.studentId)
                                  ?
                                  <div className="flex flex-v-center mb-0 font-24">
                                    <div>
                                      <div className='font-20 font-fff'>
                                        IEP Draft for {this.props.teacherBaseProps
                                          .studentDict[this.state.studentId].firstName + ' ' +
                                          this.props.teacherBaseProps
                                            .studentDict[this.state.studentId].lastName}
                                      </div>
                                      <h1 className="font-40 mb-0 font-fff font-bold">
                                        Present Level
                                      </h1>


                                    </div>
                                  </div>
                                  : ''}
                              </div>
                              <img src="/draft-icons/2.png" alt='draft-iep' width={250} className='ml-auto' />
                            </div>
                          </div>


                          <div className='p-4'>

                            <Form onSubmit={this.handleSubmit}
                              className="block-label-form">

                              {/** SEL */}
                              {this.state.iepDraft.subject.indexOf('Social Emotional Learning') > -1 ?
                                <div>
                                  <h2 className="mb-2 pb-2 border-bottom font-30 font-normal">SEL</h2>

                                  <FormItem {...formItemLayout}
                                    label={<div>
                                      <div className='font-20 font-bold'>
                                        Assessment
                                      </div>
                                      <div className='font-16 font-normal'>
                                        Choose an assessment.
                                      </div>
                                    </div>}
                                  >
                                    {getFieldDecorator('selNormAssessment', {
                                      rules: [{
                                        required: true,
                                        message: 'Select an assessment.'
                                      }],
                                    })(
                                      <Select
                                        size={'large'}
                                        placeholder="Assessment...">
                                        <Option value={'teacher survey'}>
                                          Teacher survey
                                        </Option>
                                        <Option value={'student survey'}>
                                          Student survey
                                        </Option>
                                        <Option value={'teacher observation'}>
                                          Teacher observation
                                        </Option>
                                        <Option value={'behavioral assessment'}>
                                          Behavioral assessment
                                        </Option>
                                        <Option value={'multiple measures'}>
                                          Multiple measures
                                        </Option>
                                        <Option value={"(Not known at this time)"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Date administered
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the date this assessment was administered on. Select the current
                                      date if this information is not know at this time.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('selNormDate', {
                                      rules: [{
                                        required: true,
                                        message: 'Select date.'
                                      }],
                                    })(
                                      <DatePicker format={"MM/DD/YYYY"} />
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Score relative to peers
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the score on this assessment relative to the student's peers.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('selNormLevel', {
                                      rules: [{ required: true, message: 'Select a score.' }],
                                    })(
                                      <Select
                                        size={'large'}
                                        placeholder="Select score...">
                                        <Option value={'Low'}>
                                          Low
                                        </Option>
                                        <Option value={'Low Average'}>
                                          Low Average
                                        </Option>
                                        <Option value={'Average'}>
                                          Average
                                        </Option>
                                        <Option value={'High Average'}>
                                          High Average
                                        </Option>
                                        <Option value={'High'}>
                                          High
                                        </Option>
                                        <Option value={"(Not known at this time)"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>
                                </div>
                                : ''}

                              {(this.state.iepDraft.subject
                                .indexOf('Reading Comprehension in Literature') > -1 ||
                                this.state.iepDraft.subject
                                  .indexOf('Reading Comprehension in Informational Text') > -1 ||
                                this.state.iepDraft.subject
                                  .indexOf('Writing') > -1 ||
                                this.state.iepDraft.subject.indexOf('Reading Foundations') > -1) ?
                                <div>
                                  <h2 className="mb-2 pb-2 border-bottom font-30 font-normal">ELA</h2>
                                  {this.state.student &&
                                    this.state.student.grade >= 4 && this.state.student.grade <= 8 ?
                                    <div>
                                      <FormItem {...formItemLayout} label={<div>
                                        <div className='font-20 font-bold'>
                                          State assessment
                                        </div>
                                        <div className='font-16 font-normal'>
                                          Choose the score on this assessment.
                                        </div>
                                      </div>}
                                      >
                                        {getFieldDecorator('elaStateScore', {
                                          rules: [{ required: true, message: 'Select a score.' }],
                                        })(
                                          <Select
                                            size={'large'}
                                            placeholder="Select score...">
                                            <Option value={'1'}>
                                              1 - Well below
                                            </Option>
                                            <Option value={'2'}>
                                              2 - Below
                                            </Option>
                                            <Option value={'3'}>
                                              3 - On track
                                            </Option>
                                            <Option value={'4'}>
                                              4 - Exceeding
                                            </Option>
                                            <Option value={"(Not known at this time)"}>
                                              Not known at this time
                                            </Option>
                                          </Select>
                                        )}
                                      </FormItem>

                                      <FormItem {...formItemLayout} label={<div>
                                        <div className='font-20 font-bold'>
                                          Student met state assessment standard
                                        </div>
                                        <div className='font-16 font-normal'>
                                          Choose whether the student met the state assessment standard.
                                        </div>
                                      </div>}
                                      >
                                        {getFieldDecorator('elaStateMet', {
                                          rules: [{
                                            required: true,
                                            message: 'Select whether the student has met the standard'
                                          }],
                                        })(
                                          <Select
                                            size={'large'}
                                            placeholder="Select...">
                                            <Option value={'Met'}>
                                              Met
                                            </Option>
                                            <Option value={'Not met'}>
                                              Not met
                                            </Option>
                                            <Option value={"(Not known at this time)"}>
                                              Not known at this time
                                            </Option>
                                          </Select>
                                        )}
                                      </FormItem>
                                    </div>
                                    : ''}

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Norm referenced assessment
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose an assessment.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('elaNormAssessment', {
                                      rules: [{
                                        required: true,
                                        message: 'Select norm referenced assessment.'
                                      }],
                                    })(
                                      <Select
                                        size={'large'}
                                        placeholder="Select norm referenced assessment...">
                                        <Option value={'easyCBM'}>
                                          easyCBM
                                        </Option>
                                        <Option value={'MAP'}>
                                          MAP
                                        </Option>
                                        <Option value={'iReady'}>
                                          iReady
                                        </Option>
                                        <Option value={'AimsWEB'}>
                                          AimsWEB
                                        </Option>
                                        <Option value={'DIBELS'}>
                                          DIBELS
                                        </Option>
                                        <Option value={'MClass'}>
                                          MClass
                                        </Option>
                                        <Option value={'Istation'}>
                                          Istation
                                        </Option>
                                        <Option value={'STAR'}>
                                          STAR
                                        </Option>
                                        <Option value={'Achieve3000'}>
                                          Achieve3000
                                        </Option>
                                        <Option value={'Other'}>
                                          Other
                                        </Option>
                                        <Option value={"Not known at this time"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Date administered
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the date this assessment was administered on. Select the current
                                      date if this information is not know at this time.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('elaNormDate', {
                                      rules: [{
                                        required: true,
                                        message: 'Select norm referenced assessment.'
                                      }],
                                    })(
                                      <DatePicker format={"MM/DD/YYYY"} />
                                    )}
                                  </FormItem>


                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Score relative to peers
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the score on this assessment relative to the student's peers.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('elaNormLevel', {
                                      rules: [{ required: true, message: 'Select a score.' }],
                                    })(
                                      <Select
                                        size={'large'}
                                        placeholder="Select score...">
                                        <Option value={'Low'}>
                                          Low
                                        </Option>
                                        <Option value={'Low Average'}>
                                          Low Average
                                        </Option>
                                        <Option value={'Average'}>
                                          Average
                                        </Option>
                                        <Option value={'High Average'}>
                                          High Average
                                        </Option>
                                        <Option value={'High'}>
                                          High
                                        </Option>
                                        <Option value={"(Not known at this time)"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout}
                                    label={<div>
                                      <div className='font-20 font-bold'>
                                        Student's reading grade level
                                      </div>
                                      <div className='font-16 font-normal'>
                                        Choose the student's grade level for reading.
                                      </div>
                                    </div>}
                                  >
                                    {getFieldDecorator('elaGradeLevel', {
                                      rules: [{
                                        required: true,
                                        message: "Select student's grade level for this task."
                                      }],
                                    })(
                                      <Select size={'large'} placeholder="Select reading level...">
                                        <Option value="preK">preK</Option>
                                        <Option value="K">K</Option>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                        <Option value="6">6</Option>
                                        <Option value="7">7</Option>
                                        <Option value="8">8</Option>
                                        <Option value="9">9</Option>
                                        <Option value="10">10</Option>
                                        <Option value="11">11</Option>
                                        <Option value="12">12</Option>
                                        <Option value={"(Not known at this time)"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>
                                </div>
                                : ''}

                              {/**Math*/}
                              {this.state.iepDraft.subject.indexOf('Math') > -1 ?
                                <div>
                                  <h2 className="mb-2 pb-2 border-bottom font-30 font-normal">Math</h2>
                                  {this.state.student &&
                                    this.state.student.grade >= 4 && this.state.student.grade <= 8 ?
                                    <div>
                                      <FormItem {...formItemLayout} label={<div>
                                        <div className='font-20 font-bold'>
                                          State assessment
                                        </div>
                                        <div className='font-16 font-normal'>
                                          Choose the score on this assessment.
                                        </div>
                                      </div>}
                                      >
                                        {getFieldDecorator('mathStateScore', {
                                          rules: [{ required: true, message: 'Select a score.' }],
                                        })(
                                          <Select
                                            size={'large'}
                                            placeholder="Select score...">
                                            <Option value={'1'}>
                                              1 - Well below
                                            </Option>
                                            <Option value={'2'}>
                                              2 - Below
                                            </Option>
                                            <Option value={'3'}>
                                              3 - On track
                                            </Option>
                                            <Option value={'4'}>
                                              4 - Exceeding
                                            </Option>
                                            <Option value={"(Not known at this time)"}>
                                              Not known at this time
                                            </Option>
                                          </Select>
                                        )}
                                      </FormItem>

                                      <FormItem {...formItemLayout} label={<div>
                                        <div className='font-20 font-bold'>
                                          Student met state assessment standard
                                        </div>
                                        <div className='font-16 font-normal'>
                                          Choose whether the student met the state assessment standard.
                                        </div>
                                      </div>}
                                      >
                                        {getFieldDecorator('mathStateMet', {
                                          rules: [{
                                            required: true,
                                            message: 'Select whether the student has met the standard'
                                          }],
                                        })(
                                          <Select
                                            size={'large'}
                                            placeholder="Select...">
                                            <Option value={'Met'}>
                                              Met
                                            </Option>
                                            <Option value={'Not met'}>
                                              Not met
                                            </Option>
                                            <Option value={"(Not known at this time)"}>
                                              Not known at this time
                                            </Option>
                                          </Select>
                                        )}
                                      </FormItem>
                                    </div>
                                    : ''}

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Norm referenced assessment
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the norm referenced assessment for math.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('mathNormAssessment', {
                                      rules: [{
                                        required: true,
                                        message: 'Select norm referenced assessment.'
                                      }],
                                    })(
                                      <Select
                                        size={'large'}
                                        placeholder="Select norm referenced assessment...">
                                        <Option value={'easyCBM'}>
                                          easyCBM
                                        </Option>
                                        <Option value={'MAP'}>
                                          MAP
                                        </Option>
                                        <Option value={'iReady'}>
                                          iReady
                                        </Option>
                                        <Option value={'AimsWEB'}>
                                          AimsWEB
                                        </Option>
                                        <Option value={'DIBELS'}>
                                          DIBELS
                                        </Option>
                                        <Option value={'MClass'}>
                                          MClass
                                        </Option>
                                        <Option value={'Istation'}>
                                          Istation
                                        </Option>
                                        <Option value={'STAR'}>
                                          STAR
                                        </Option>
                                        <Option value={'Achieve3000'}>
                                          Achieve3000
                                        </Option>
                                        <Option value={'Other'}>
                                          Other
                                        </Option>
                                        <Option value={"Not known at this time"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Date administered
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the date this assessment was administered on. Select the current
                                      date if this information is not know at this time.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('mathNormDate', {
                                      rules: [{
                                        required: true,
                                        message: 'Select norm referenced assessment.'
                                      }],
                                    })(
                                      <DatePicker format={"MM/DD/YYYY"} />
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout} label={<div>
                                    <div className='font-20 font-bold'>
                                      Score relative to peers
                                    </div>
                                    <div className='font-16 font-normal'>
                                      Choose the score on this assessment relative to the student's peers.
                                    </div>
                                  </div>}
                                  >
                                    {getFieldDecorator('mathNormLevel', {
                                      rules: [{ required: true, message: 'Select a score.' }],
                                    })(
                                      <Select
                                        size={'large'}
                                        placeholder="Select score...">
                                        <Option value={'Low'}>
                                          Low
                                        </Option>
                                        <Option value={'Low Average'}>
                                          Low Average
                                        </Option>
                                        <Option value={'Average'}>
                                          Average
                                        </Option>
                                        <Option value={'High Average'}>
                                          High Average
                                        </Option>
                                        <Option value={'High'}>
                                          High
                                        </Option>
                                        <Option value={"(Not known at this time)"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>

                                  <FormItem {...formItemLayout}
                                    label={<div>
                                      <div className='font-20 font-bold'>
                                        Student's math grade level
                                      </div>
                                      <div className='font-16 font-normal'>
                                        Choose the student's grade level for math.
                                      </div>
                                    </div>}
                                  >
                                    {getFieldDecorator('mathGradeLevel', {
                                      rules: [{
                                        required: true,
                                        message: "Select student's grade level for this task."
                                      }],
                                    })(
                                      <Select size={'large'} placeholder="Select math grade level...">
                                        <Option value="preK">preK</Option>
                                        <Option value="K">K</Option>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                        <Option value="6">6</Option>
                                        <Option value="7">7</Option>
                                        <Option value="8">8</Option>
                                        <Option value="9">9</Option>
                                        <Option value="10">10</Option>
                                        <Option value="11">11</Option>
                                        <Option value="12">12</Option>
                                        <Option value={"(Not known at this time)"}>
                                          Not known at this time
                                        </Option>
                                      </Select>
                                    )}
                                  </FormItem>
                                </div>
                                : ''}

                              <FormItem {...formItemBlockLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Additional information (Optional)
                                  </div>
                                  <div className='font-16 font-normal'>
                                    Add any additional information your school requires.
                                  </div>
                                </div>}
                                className={"block-label"}>
                                {getFieldDecorator('additionalInformation', {
                                  rules: [{
                                    required: false,
                                    message: "Select student's grade level for this task."
                                  }],
                                })(
                                  <TextArea rows={4} />
                                )}
                              </FormItem>
                              {/**
                              <h4 className="mb-2 pt-3 border-top">Student strengths (Optional)</h4>
                              <FormItem {...formItemLayout} label={"Student strengths (Optional)"}
                                >
                                {getFieldDecorator('presentLevelStrengths', {
                                  rules: [{
                                    required: true,
                                    message: 'Not required.'
                                  }],
                                })(
                                  <TextArea 
                                  placeholder="Student strengths"
                                  autosize />
                                )}
                              </FormItem>*/}

                              <div className="mb-0 mt-4 pt-4 flex flex-v-center">
                                <Link
                                  to={'/teacher/add-goal-student/' +
                                    this.state.teacherId +
                                    '?draft=' + this.state.draftId}
                                  className="font-18"
                                >
                                  <span className="left-hover-parent text-cyan">
                                    <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                    <span>Previous Step</span>
                                  </span>
                                </Link>
                                <Button
                                  type="primary"
                                  size={'large'}
                                  htmlType="submit"
                                  className="lg-btn ml-auto"
                                >
                                  Continue
                                </Button>
                              </div>
                            </Form>
                          </div>

                        </div>
                      </div>
                      : ''}

                  </div>
                </DraftStepContainer>
              </div>

            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalInformation)