import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { Icon, Tooltip } from 'antd'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
//import { Row, Col } from 'antd'
//const { Content } = Layout

class PacingGuideDraggableStandard extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className='w-100'>
                <div className='font-bold font-20 mt-2 flex flex-v-center w-100'>
                    <div>
                        {this.props.item.standard}
                    </div>
                    {this.props.item.isPriority ?
                        <div className='flex flex-v-center font-14 ml-1 background-purple font-fff pl-1 pr-1 pt-05 pb-05 br-15'>
                            <Icon type='pushpin' className='mr-05' />
                            <div>Priority</div>
                        </div> : ''}
                </div>
                {this.props.scheduledNum &&
                    this.props.scheduledNum > 0 ?
                    <div className='font-16 text-success'>
                        <Icon type='check' className='mr-05' />
                        <span>Scheduled: {this.props.scheduledNum} {this.props.scheduledNum === 1 ? 'time' : 'times'}</span>
                    </div>
                    : ''}
                {this.props.hasOwnProperty('displayTitle') && !this.props.displayTitle ?
                    <div className='font-16 overflow-hidden text-blur relative'
                        style={{
                            maxHeight: '75px',
                        }}>
                        {this.props.item.title}</div> :
                    <div className='font-16'>{this.props.item.title}</div>}
            </div>
        )
    }
}

export default PacingGuideDraggableStandard
