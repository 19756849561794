import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import AddGroupForm from './AddGroupForm'
import ColType from '../.././Types'
import { getIDFromURL, flattenDoc, getInitials, capitalizeFirstChar, isReadOnlyTeacherId } from '../.././Util'
import { Layout, Input, Button, Form, Avatar, Collapse, Modal, Icon, Tooltip, message, Select, Checkbox, Tabs, Spin, Empty } from 'antd'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import ReactToPrint from "react-to-print"
import PlanView from '../student/PlanView'
import EditGroupForm from './EditGroupForm'
import UIChart from '../progressmonitoring/UIChart'
const TextArea = Input.TextArea
const FormItem = Form.Item
const Panel = Collapse.Panel
const Option = Select.Option
const TabPane = Tabs.TabPane
const Content = Layout.Content
var moment = require('moment')

const errorMessage = (description) => {
    message.destroy()
    message.error(description)
}

const successMessage = (description) => {
    message.destroy()
    message.success(description)
}

class ChatHome extends Component {
    state = {
        teachers: [],
        messages: [],
        goalMessages: [],
        chatGroups: [],
        selectedGroup: null,
        selectedTeacher: null,
        messageListener: null,
        messageGoalsListener: null,
        groupListener: null,
        deleteMessageModalVisible: false,
        deletingMessage: false,
        deletingMessageId: null,
        uploadGoalModalVisible: false,
        uploadingGoals: false,
        uploadingGoalIds: [],
        uploadingStudentGoals: [],
        uploadingGoalsSelectedStudentId: null,
        viewingIEP: null,
        viewingIEPVisible: false,
        viewingIEPMeasurementsDict: {},
        activeMessageTab: 'allMessages',
        loadingMessages: false,
        deleteGroupModalVisible: false,
        deletingGroup: false,
    }

    componentWillUnmount() {
        this.clearListeners()

        if (this.state.groupListener) {
            this.state.groupListener()
        }

        // clear previous viewing chat id in base props. the purpose
        // of the currently viewing chat id is to avoid header flicker of the
        // notification dot if an alert comes in for the chat you are currently
        // viewing.
        if (this.props.setCurrentlyViewingChatIdFunc) {
            this.props.setCurrentlyViewingChatIdFunc(null)
        }
    }

    componentDidMount() {
        document.title = 'Teams - Dot It'

        var teacherId = getIDFromURL(window.location)

        if (!teacherId) {
            return
        }

        this.setState({
            teacherId: teacherId,
        })

        this.startGroupListener()
    }

    componentWillReceiveProps(nextProps) {
        this.startGroupListener()

        this.possiblyUnwriteNotificationEvent(nextProps.chatNotificationDict)
    }

    possiblyUnwriteNotificationEvent = (chatNotificationDict) => {
        if (!chatNotificationDict) {
            return
        }

        // set notifications for the current chat group / direct message to false
        // if is currently true to unwrite the notification event
        if (this.state.selectedGroup) {
            let eventId = this.state.selectedGroup.id
            let hasNotification = chatNotificationDict &&
                chatNotificationDict.hasOwnProperty(eventId) &&
                chatNotificationDict[eventId]
            if (hasNotification) {
                chatNotificationDict[eventId] = false
                db.collection(ColType.chatNotification)
                    .doc(this.props.person.id)
                    .update(chatNotificationDict)
            }
        } else if (this.state.selectedTeacher) {
            let eventId = this.getSortedViewerId([this.props.person.id, this.state.selectedTeacher.id])
            let hasNotification = chatNotificationDict &&
                chatNotificationDict.hasOwnProperty(eventId) &&
                chatNotificationDict[eventId]
            if (hasNotification) {
                chatNotificationDict[eventId] = false
                db.collection(ColType.chatNotification)
                    .doc(this.props.person.id)
                    .update(chatNotificationDict)
            }
        }
    }

    // sort the viewer ids so that they are always in the same order
    // then append them to a string to get the unique id for this
    // direct message.
    getSortedViewerId = (viewerIds) => {
        viewerIds.sort()
        let output = ""
        viewerIds.map((viewerId, idx) => {
            output += viewerId
            return false
        })
        return output
    }

    startGroupListener = () => {
        if (!this.props.person) {
            return
        }

        // listener already started
        if (this.state.groupListener) {
            return
        }

        let groupListener = db.collection(ColType.chatGroup)
            .where('viewerIds', 'array-contains', this.props.person.id)
            // only query non-deleted groups, groups are not deleted in case they need
            // to be recovered later
            .where('isDeleted', '==', false)
            .orderBy('groupName')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                var chatGroups = []
                var selectedGroup = null

                querySnapshot.forEach((doc) => {
                    var chatGroup = flattenDoc(doc)
                    if (this.state.selectedGroup) {
                        if (chatGroup.id === this.state.selectedGroup.id) {
                            selectedGroup = chatGroup
                        }
                    }
                    chatGroups.push(chatGroup)
                })

                this.setState({
                    chatGroups: chatGroups,
                    selectedGroup: selectedGroup,
                })
            })

        this.setState({
            groupListener: groupListener,
        })
    }

    clearListeners = () => {
        if (this.state.messageListener) {
            this.state.messageListener()
        } if (this.state.messageGoalsListener) {
            this.state.messageGoalsListener()
        }
    }

    selectGroup = (group) => {
        // same group selected, return
        if (this.state.selectedGroup && group.id === this.state.selectedGroup.id) {
            return
        }

        // avoids flickering of a notification on the same chat, as there is a small delay
        // between getting a new message on an active chat an unwriting it (the dot
        // pops up and then disappears in around 100ms), this fixes that by not showing
        // a header notification for the active chat.
        if (this.props.setCurrentlyViewingChatIdFunc) {
            this.props.setCurrentlyViewingChatIdFunc(group.id)
        }

        // new grouop selected, clear previous listeners
        this.clearListeners()

        this.setState({
            messages: [],
            goalMessages: [],
            messageListener: null,
            messageGoalsListener: null,
            selectedGroup: group,
            selectedTeacher: null,
        }, () => {
            // if you clicked on a direct message that you had a notification event for,
            // clear the notification
            this.possiblyUnwriteNotificationEvent(this.props.chatNotificationDict)

            this.maybeStartListeners(this.state.activeMessageTab)
        })
    }

    selectTeacher = (teacher) => {
        if (!this.props.person) {
            return
        }

        // same teacher selected, return
        if (this.state.selectedTeacher && teacher.id === this.state.selectedTeacher.id) {
            return
        }

        // avoids flickering of a notification on the same chat, as there is a small delay
        // between getting a new message on an active chat an unwriting it (the dot
        // pops up and then disappears in around 100ms), this fixes that by not showing
        // a header notification for the active chat.
        if (this.props.setCurrentlyViewingChatIdFunc) {
            this.props.setCurrentlyViewingChatIdFunc(
                this.getSortedViewerId([this.props.person.id, teacher.id]))
        }

        // new teacher selected, clear previous listeners
        this.clearListeners()

        this.setState({
            messages: [],
            goalMessages: [],
            messageListener: null,
            messageGoalsListener: null,
            selectedGroup: null,
            selectedTeacher: teacher,
        }, () => {
            // if you clicked on a direct message that you had a notification event for,
            // clear the notification
            this.possiblyUnwriteNotificationEvent(this.props.chatNotificationDict)

            this.maybeStartListeners(this.state.activeMessageTab)
        })
    }

    getDirectMessages = (teacher) => {
        if (!this.props.person) {
            return
        }

        // already have a listener, return
        if (this.state.messageListener) {
            return
        }

        this.setState({
            loadingMessages: true,
        }, () => {
            let messageListener = db.collection(ColType.chatDirectMessage)
                .where('viewerSortedId', '==',
                    this.getSortedViewerId([this.props.person.id, teacher.id]))
                .orderBy('timeStamp', 'desc')
                .limit(25)
                .onSnapshot({ includeMetadataChanges: true }, (querySnapshot) => {
                    // always want data from the server, never from cache
                    // can cause a double call of this function, one from cache
                    // with old data, and a new one when the server responds.
                    if (querySnapshot.metadata.fromCache) {
                        this.setState({
                            messages: [],
                        })
                        return
                    }

                    var messages = []

                    querySnapshot.forEach((doc) => {
                        messages.push(flattenDoc(doc))
                    })

                    messages.reverse()

                    this.setState({
                        messages: messages,
                        loadingMessages: false,
                    }, () => {
                        // scroll to bottom on new message
                        if (this.chatBox) {
                            this.chatBox.scrollTop = this.chatBox.scrollHeight
                        }
                    })
                })

            this.setState({
                messageListener: messageListener,
            })
        })
    }

    getDirectMessagesGoals = (teacher) => {
        if (!this.props.person) {
            return
        }

        // already have a listener, return
        if (this.state.messageGoalsListener) {
            return
        }

        this.setState({
            loadingMessages: true,
        }, () => {
            let messageGoalsListener = db.collection(ColType.chatDirectMessage)
                .where('viewerSortedId', '==',
                    this.getSortedViewerId([this.props.person.id, teacher.id]))
                .where('messageType', '==', 'goal')
                .orderBy('timeStamp', 'desc')
                .limit(25)
                .onSnapshot({ includeMetadataChanges: true }, (querySnapshot) => {
                    // always want data from the server, never from cache
                    // can cause a double call of this function, one from cache
                    // with old data, and a new one when the server responds.
                    if (querySnapshot.metadata.fromCache) {
                        this.setState({
                            goalMessages: []
                        })
                        return
                    }

                    var goalMessages = []

                    querySnapshot.forEach((doc) => {
                        goalMessages.push(flattenDoc(doc))
                    })

                    goalMessages.reverse()

                    this.setState({
                        goalMessages: goalMessages,
                        loadingMessages: false,
                    }, () => {
                        // scroll to bottom on new message
                        if (this.chatBox) {
                            this.chatBox.scrollTop = this.chatBox.scrollHeight
                        }
                    })
                })

            this.setState({
                messageGoalsListener: messageGoalsListener,
            })
        })
    }

    getGroupMessages = (group) => {
        if (!this.props.person) {
            return
        }

        // already have a listener, return
        if (this.state.messageListener) {
            return
        }

        this.setState({
            loadingMessages: true,
        }, () => {
            let messageListener = db.collection(ColType.chatGroupMessage)
                .where('chatGroupId', '==', group.id)
                .orderBy('timeStamp', 'desc')
                .limit(25)
                .onSnapshot({ includeMetadataChanges: true }, (querySnapshot) => {
                    // always want data from the server, never from cache
                    // can cause a double call of this function, one from cache
                    // with old data, and a new one when the server responds.
                    if (querySnapshot.metadata.fromCache) {
                        this.setState({
                            messages: [],
                        })
                        return
                    }

                    var messages = []

                    querySnapshot.forEach((doc) => {
                        messages.push(flattenDoc(doc))
                    })

                    messages.reverse()

                    this.setState({
                        messages: messages,
                        loadingMessages: false,
                    }, () => {
                        // scroll to bottom on new message
                        if (this.chatBox) {
                            this.chatBox.scrollTop = this.chatBox.scrollHeight
                        }
                    })
                })

            this.setState({
                messageListener: messageListener,
            })
        })
    }

    getGroupMessagesGoals = (group) => {
        if (!this.props.person) {
            return
        }

        // already have a listener, return
        if (this.state.messageGoalsListener) {
            return
        }

        this.setState({
            loadingMessages: true,
        }, () => {
            let messageGoalsListener = db.collection(ColType.chatGroupMessage)
                .where('chatGroupId', '==', group.id)
                .where('messageType', '==', 'goal')
                .orderBy('timeStamp', 'desc')
                .limit(25)
                .onSnapshot({ includeMetadataChanges: true }, (querySnapshot) => {
                    // always want data from the server, never from cache
                    // can cause a double call of this function, one from cache
                    // with old data, and a new one when the server responds.
                    if (querySnapshot.metadata.fromCache) {
                        this.setState({
                            goalMessages: [],
                        })
                        return
                    }

                    var goalMessages = []

                    querySnapshot.forEach((doc) => {
                        goalMessages.push(flattenDoc(doc))
                    })

                    goalMessages.reverse()

                    this.setState({
                        goalMessages: goalMessages,
                        loadingMessages: false,
                    }, () => {
                        // scroll to bottom on new message
                        if (this.chatBox) {
                            this.chatBox.scrollTop = this.chatBox.scrollHeight
                        }
                    })
                })

            this.setState({
                messageGoalsListener: messageGoalsListener,
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // store message before clearing fields
                let message = values.message

                // clear field immediately
                this.props.form.resetFields()

                if (this.state.selectedGroup) {
                    db.collection(ColType.chatGroupMessage)
                        .add({
                            messageType: "message",
                            message: message,
                            authorId: this.props.person.id,
                            authorFirstName: this.props.person.firstName,
                            authorLastName: this.props.person.lastName,
                            chatGroupId: this.state.selectedGroup.id,
                            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                        .then(() => {

                        })
                        .catch((e) => {

                        })
                }
                else if (this.state.selectedTeacher) {
                    let viewerIds = [this.props.person.id, this.state.selectedTeacher.id]

                    db.collection(ColType.chatDirectMessage)
                        .add({
                            messageType: "message",
                            viewerSortedId: this.getSortedViewerId(viewerIds),
                            viewerIds: viewerIds,
                            message: message,
                            authorId: this.props.person.id,
                            authorFirstName: this.props.person.firstName,
                            authorLastName: this.props.person.lastName,
                            receiverId: this.state.selectedTeacher.id,
                            receiverFirstName: this.state.selectedTeacher.firstName,
                            receiverLastName: this.state.selectedTeacher.lastName,
                            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                        .then(() => {

                        })
                        .catch((e) => {

                        })
                } else {
                    console.log("unexpected path reached.")
                }
            }
        })
    }

    deleteMessage = (messageId) => {
        if (!messageId) {
            this.setState({
                deletingMessage: true,
            })
            return
        }

        this.setState({
            deletingMessage: true,
        })

        if (this.state.selectedGroup) {
            db.collection(ColType.chatGroupMessage)
                .doc(messageId)
                .delete()
                .then(() => {
                    successMessage("Message deleted successfully.")
                    this.setState({
                        deletingMessage: false,
                        deletingMessageId: null,
                        deleteMessageModalVisible: false
                    })
                })
                .catch((e) => {
                    errorMessage("Something went wrong, try again or email support@dotit.app")
                    this.setState({
                        deletingMessage: false,
                    })
                })
        } else if (this.state.selectedTeacher) {
            db.collection(ColType.chatDirectMessage)
                .doc(messageId)
                .delete()
                .then(() => {
                    successMessage("Message deleted successfully.")
                    this.setState({
                        deletingMessage: false,
                        deletingMessageId: null,
                        deleteMessageModalVisible: false
                    })
                })
                .catch((e) => {
                    errorMessage("Something went wrong, try again or email support@dotit.app")
                    this.setState({
                        deletingMessage: false,
                    })
                })
        }
    }

    onUploadingGoalStudentChange = (studentId) => {
        let studentGoals = []
        if (this.props.IEPGoals) {
            this.props.IEPGoals.map((iep, idx) => {
                if (iep.studentId !== studentId) {
                    return false
                }

                return studentGoals.push(iep)
            })
        }

        // clear previous selected goals when a new student is selected
        this.setState({
            uploadingGoalsSelectedStudentId: studentId,
            uploadingStudentGoals: studentGoals,
            uploadingGoalIds: [],
        })
    }

    onUploadingGoalsChange = (checkedValues) => {
        this.setState({
            uploadingGoalIds: checkedValues
        })
    }

    uploadGoals = () => {
        if (!(this.state.uploadingGoalsSelectedStudentId &&
            this.state.uploadingGoalIds.length > 0)) {
            return
        }

        this.setState({
            uploadingGoals: true,
        })

        var promises = []

        this.state.uploadingGoalIds.map((iepId, idx) => {
            if (!(this.props.IEPDict &&
                this.props.IEPDict.hasOwnProperty(iepId))) {
                return false
            }

            let IEPGoal = this.props.IEPDict[iepId]

            return promises.push(new Promise((resolve, reject) => {
                if (this.state.selectedGroup) {
                    db.collection(ColType.chatGroupMessage)
                        .add({
                            messageType: "goal",
                            iep: IEPGoal,
                            message: "",
                            authorId: this.props.person.id,
                            authorFirstName: this.props.person.firstName,
                            authorLastName: this.props.person.lastName,
                            chatGroupId: this.state.selectedGroup.id,
                            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                        .then(() => {
                            resolve()
                        })
                        .catch((e) => {
                            reject(e)
                        })
                }
                else if (this.state.selectedTeacher) {
                    let viewerIds = [this.props.person.id, this.state.selectedTeacher.id]

                    db.collection(ColType.chatDirectMessage)
                        .add({
                            messageType: "goal",
                            iep: IEPGoal,
                            viewerSortedId: this.getSortedViewerId(viewerIds),
                            viewerIds: viewerIds,
                            message: "",
                            authorId: this.props.person.id,
                            authorFirstName: this.props.person.firstName,
                            authorLastName: this.props.person.lastName,
                            receiverId: this.state.selectedTeacher.id,
                            receiverFirstName: this.state.selectedTeacher.firstName,
                            receiverLastName: this.state.selectedTeacher.lastName,
                            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                        .then(() => {
                            resolve()
                        })
                        .catch((e) => {
                            reject(e)
                        })
                } else {
                    console.log("unexpected path reached.")
                    resolve()
                }
            }))
        })

        Promise.all(promises)
            .then(() => {
                successMessage("Goal(s) uploaded successfully.")
                this.setState({
                    uploadGoalModalVisible: false,
                    uploadingGoals: false,
                    uploadingGoalsSelectedStudentId: null,
                    uploadingStudentGoals: [],
                    uploadingGoalIds: [],
                })
            })
            .catch((e) => {
                errorMessage("Something went wrong, try again or email support@dotit.app")
                this.setState({
                    uploadingGoals: false,
                })
            })
    }

    onMessageTabChange = (tab) => {
        this.setState({
            activeMessageTab: tab,
        }, () => {
            // scroll to bottom on new message
            if (this.chatBox) {
                this.chatBox.scrollTop = this.chatBox.scrollHeight
            }
        })

        this.maybeStartListeners(tab)
    }

    maybeStartListeners = (tab) => {
        if (tab === 'allMessages') {
            if (this.state.selectedTeacher) {
                this.getDirectMessages(this.state.selectedTeacher)
            } else if (this.state.selectedGroup) {
                this.getGroupMessages(this.state.selectedGroup)
            }
        }
        else if (tab === 'uploadedGoals') {
            if (this.state.selectedTeacher) {
                this.getDirectMessagesGoals(this.state.selectedTeacher)
            } else if (this.state.selectedGroup) {
                this.getGroupMessagesGoals(this.state.selectedGroup)
            }
        }
    }

    deleteGroup = (group) => {
        this.setState({
            deletingGroup: true,
        })

        db.collection(ColType.chatGroup)
            .doc(group.id)
            .update({
                // don't actually delete in case a group needs
                // to be recovered later
                isDeleted: true,
            })
            .then(() => {
                // clear listeners
                this.clearListeners()

                this.setState({
                    deleteGroupModalVisible: false,
                    deletingGroup: false,
                    // group is not selected anymore
                    selectedGroup: null,
                })
                successMessage("Team deleted successfully.")
            })
            .catch((e) => {
                this.setState({
                    deletingGroup: false,
                })
                errorMessage("Something went wrong, try again or contact support@dotit.app")
            })
    }

    setViewingIEP = (iep) => {
        this.setState({
            viewingIEP: iep,
            viewingIEPVisible: true,
        }, () => {
            // always get a fresh copy of the measurements
            db.collection(ColType.measurements)
                .where("iepId", "==", iep.id)
                .where('schoolId', '==', iep.schoolId)
                .get()
                .then((querySnapshot) => {
                    let measurements = []
                    querySnapshot.forEach((doc) => {
                        measurements.push(flattenDoc(doc))
                    })
                    let viewingIEPMeasurementsDict = this.state.viewingIEPMeasurementsDict
                    viewingIEPMeasurementsDict[iep.id] = measurements
                    this.setState({
                        viewingIEPMeasurementsDict: viewingIEPMeasurementsDict,
                    })
                })
        })
    }

    personnelListHasNotification = (personnelList) => {
        if (!(this.props.person && this.props.chatNotificationDict)) {
            return false
        }
        for (let person of personnelList) {
            if (this.props.person &&
                this.props.person.id === person.id) {
                continue
            }
            let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
            let hasNotification = this.props.chatNotificationDict &&
                this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                this.props.chatNotificationDict[sortedViewerId] &&
                (!this.state.selectedTeacher ||
                    (this.state.selectedTeacher &&
                        this.state.selectedTeacher.id !== person.id))
            if (hasNotification) {
                return true
            }
        }

        return false
    }

    schoolHasNotification = (personnelDict) => {
        if (!(this.props.person && this.props.chatNotificationDict)) {
            return false
        }
        return this.personnelListHasNotification(personnelDict.teachers) ||
            this.personnelListHasNotification(personnelDict.instructionalFacilitators) ||
            this.personnelListHasNotification(personnelDict.schoolAdmins)
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Layout className="content layout-header-mt-less mb-0">
                    <Layout>
                        <Content className="layout-content-no-mt">
                            <div className="sub-menu-width m-lr-auto">
                                <div className="flex">
                                    <Modal
                                        visible={this.state.deleteMessageModalVisible}
                                        title={null}
                                        footer={null}
                                        onCancel={(e) => this.setState({
                                            deleteMessageModalVisible: false,
                                        })}
                                    >
                                        <div className="font-24">Do you want to permanently delete this message?</div>
                                        <div className="flex mt-2">
                                            <Button
                                                disabled={this.state.deletingMessage}
                                                onClick={() => {
                                                    this.setState({
                                                        deleteMessageModalVisible: false,
                                                    })
                                                }}
                                                size="large"
                                                className="mr-1 font-20 ml-auto"
                                            >
                                                No
                                            </Button>
                                            <Button size="large" type="primary" className="font-20"
                                                disabled={this.state.deletingMessage}
                                                onClick={() => {
                                                    this.deleteMessage(this.state.deletingMessageId)
                                                }}>
                                                {this.state.deletingMessage ? "Deleting..." : "Yes"}
                                            </Button>
                                        </div>
                                    </Modal>

                                    <Modal
                                        visible={this.state.uploadGoalModalVisible}
                                        title={null}
                                        footer={null}
                                        onCancel={(e) => this.setState({
                                            uploadGoalModalVisible: false,
                                            uploadingGoalsSelectedStudentId: null,
                                            uploadingStudentGoals: [],
                                            uploadingGoalIds: [],
                                        })}
                                    >
                                        <div className="font-24">Upload goals</div>
                                        <div className="mt-2">
                                            <div className="font-20 mb-1">Step 1: Select student</div>
                                            <Select
                                                className="w-100"
                                                size="large"
                                                value={this.state.uploadingGoalsSelectedStudentId}
                                                onChange={this.onUploadingGoalStudentChange}
                                            >
                                                {this.props.students &&
                                                    this.props.students.map((student, idx) => {
                                                        return <Option key={student.id} value={student.id}>
                                                            <PersonAvatar
                                                                size={'default'}
                                                                person={student}
                                                            />
                                                        </Option>
                                                    })}
                                            </Select>
                                        </div>
                                        {this.state.uploadingGoalsSelectedStudentId ?
                                            <div className="mt-2">
                                                <div className="font-20 mb-1">Step 2: Select goals</div>
                                                <Checkbox.Group
                                                    values={this.state.uploadingGoalIds}
                                                    onChange={this.onUploadingGoalsChange}
                                                >
                                                    {this.state.uploadingStudentGoals.length === 0 ?
                                                        <div className="font-20">This student currently has no goals.</div>
                                                        : ''}
                                                    {this.state.uploadingStudentGoals.map((iep, idx) => {
                                                        return <Checkbox
                                                            value={iep.id}
                                                            className="block ml-0 font-20 font-black"
                                                            key={'checkbox-iep-' + iep.id}
                                                            size="large"
                                                        >
                                                            <span className="pl-1 font-20">
                                                                {capitalizeFirstChar(iep.iep.standardDescription)}
                                                            </span>
                                                        </Checkbox>
                                                    })}
                                                </Checkbox.Group>
                                            </div>
                                            : ''}

                                        <div className="flex mt-4">
                                            <Button
                                                disabled={this.state.uploadingGoals}
                                                onClick={() => {
                                                    this.setState({
                                                        uploadGoalModalVisible: false,
                                                        uploadingGoalsSelectedStudentId: null,
                                                        uploadingStudentGoals: [],
                                                        uploadingGoalIds: [],
                                                    })
                                                }}
                                                size="large"
                                                className="mr-1 font-20 ml-auto"
                                            >
                                                Cancel
                                            </Button>
                                            <Button size="large" type="primary" className="font-20"
                                                disabled={this.state.uploadingGoals ||
                                                    !(this.state.uploadingGoalsSelectedStudentId &&
                                                        this.state.uploadingGoalIds.length > 0)}
                                                onClick={this.uploadGoals}>
                                                {this.state.uploadingGoals ? "Uploading goals..." : "Upload goals"}
                                            </Button>
                                        </div>
                                    </Modal>

                                    <Modal
                                        title={"Print " + (this.state.viewingIEP ?
                                            ((this.state.viewingIEP.iep.hasOwnProperty("isMTSS") &&
                                                this.state.viewingIEP.iep.isMTSS) ?
                                                this.state.viewingIEP.iep.iep.tier + " Goal" : "IEP Goal") : '')}
                                        visible={this.state.viewingIEPVisible}
                                        footer={null}
                                        width={750}
                                        bodyStyle={{ padding: '0px' }}
                                        onCancel={() => {
                                            this.setState({
                                                viewingIEPVisible: false,
                                            })
                                        }}
                                    >
                                        {this.state.viewingIEP ?
                                            <div>
                                                <div className="font-28 font-bold pl-3 pt-3">
                                                    <div className="pr-3 flex flex-v-center">
                                                        <ReactToPrint
                                                            trigger={() =>
                                                                <Button
                                                                    size={'large'}
                                                                    className="ant-btn-primary"
                                                                >
                                                                    <Icon type="printer" className="font-bold" />
                                                                    <span>Print</span>
                                                                </Button>
                                                            }
                                                            content={() => this.content}
                                                        />
                                                    </div>
                                                </div>
                                                <div ref={el => (this.content = el)} className="p-3">
                                                    <div>
                                                        <PersonAvatar
                                                            person={this.state.viewingIEP.student}
                                                            size={'large'}
                                                            containerClassName="font-25 mt-6px mb-1"
                                                            avatarClassName="font-25 font-bold"
                                                            personClassName="font-bold font-black"
                                                        />
                                                        <div>
                                                            <h1 className="font-bold mb-0">
                                                                {this.state.viewingIEP.iep.mainSubject}
                                                            </h1>
                                                            <h2 className="mb-2 font-bold">
                                                                {capitalizeFirstChar(this.state.viewingIEP.iep.standardDescription)}
                                                            </h2>
                                                            <div className="mb-3">

                                                                <h2 className="font-bold font-24 mb-1">
                                                                    {(this.state.viewingIEP.hasOwnProperty("isMTSS") && this.state.viewingIEP.isMTSS) ?
                                                                        this.state.viewingIEP.iep.tier + " Goal" : "IEP Goal"}
                                                                </h2>
                                                                <h3 className="mb-4 font-20">
                                                                    {this.state.viewingIEP.iep.iepParagraph}
                                                                </h3>
                                                                <PlanView iep={this.state.viewingIEP} student={this.state.viewingIEP.student} />
                                                                <UIChart
                                                                    allowMeasurementAdding={false}
                                                                    iep={this.state.viewingIEP}
                                                                    measurements={
                                                                        this.state.viewingIEPMeasurementsDict.hasOwnProperty(this.state.viewingIEP.id) ?
                                                                            this.state.viewingIEPMeasurementsDict[this.state.viewingIEP.id] : []
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                    </Modal>

                                    <Modal
                                        visible={this.state.deleteGroupModalVisible}
                                        title={null}
                                        footer={null}
                                        onCancel={(e) => this.setState({
                                            deleteGroupModalVisible: false,
                                        })}
                                    >
                                        <div className="font-24">Do you want to permanently delete this team?</div>
                                        <div className="flex mt-2">
                                            <Button
                                                disabled={this.state.deletingGroup}
                                                onClick={() => {
                                                    this.setState({
                                                        deleteGroupModalVisible: false,
                                                    })
                                                }}
                                                size="large"
                                                className="mr-1 font-20 ml-auto"
                                            >
                                                No
                                            </Button>
                                            <Button size="large" type="primary" className="font-20"
                                                disabled={this.state.deletingGroup}
                                                onClick={() => {
                                                    this.deleteGroup(this.state.selectedGroup)
                                                }}>
                                                {this.state.deletingGroup ? "Deleting..." : "Yes"}
                                            </Button>
                                        </div>
                                    </Modal>

                                    <div
                                        style={{
                                            overflow: 'auto', overflowX: 'hidden',
                                            left: 0, borderRight: '1px solid #f1f1f1',
                                            background: '#fff', borderTop: '1px solid #f1f1f1',
                                            width: '340px', height: 'calc(100vh - 82px)'
                                        }}
                                        className="inline-block pt-2"
                                    >
                                        <Collapse defaultActiveKey={['chat-sidemenu-1', 'chat-sidemenu-2']}
                                            className="no-content-padding collapse-vlg collapse-no-border" bordered={false} ghost>
                                            <Panel header="Teams" key="chat-sidemenu-1">
                                                <div className="pl-2 pr-2">
                                                    <div className="p-1 mb-1">
                                                        <AddGroupForm
                                                            teacher={this.props.person}
                                                            schoolPersonnelDict={this.props.schoolPersonnelDict}
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        {this.state.chatGroups.map((chatGroup, idx) => {
                                                            // don't show notifications for the selected group, it will be unwritten
                                                            // quickly, avoids potential flicker of the notification dot
                                                            let hasNotification = this.props.chatNotificationDict &&
                                                                this.props.chatNotificationDict.hasOwnProperty(chatGroup.id) &&
                                                                this.props.chatNotificationDict[chatGroup.id] &&
                                                                (!this.state.selectedGroup ||
                                                                    (this.state.selectedGroup &&
                                                                        this.state.selectedGroup.id !== chatGroup.id))
                                                            return <div
                                                                key={chatGroup.id}
                                                                className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer font-20 font-black relative" +
                                                                    (this.state.selectedGroup &&
                                                                        this.state.selectedGroup.id === chatGroup.id ? " side-menu-item-active" : "")}
                                                                onClick={(e) => {
                                                                    this.selectGroup(chatGroup)
                                                                }}
                                                            >
                                                                <span className={"text-muted" + (hasNotification ? " font-black font-bold" : "")}>
                                                                    {chatGroup.groupName}
                                                                </span>

                                                                {hasNotification ?
                                                                    <div className="absolute-tr chat-active-dot" style={{ top: '25px', right: '12px' }}>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </Panel>
                                            <Panel header="Messages" key="chat-sidemenu-2">
                                                <div>
                                                    {this.props.personRole === 'teacher' || this.props.personRole === 'schoolAdmin' ?
                                                        <div>
                                                            {Object.keys(this.props.schoolPersonnelDict).map((schoolId, idx) => {
                                                                let schoolPersonnel = this.props.schoolPersonnelDict[schoolId]
                                                                return <div className="pl-2 pr-2" key={'teachers-school-' + schoolId}>
                                                                    <div className='font-16 font-bold mb-1 mt-1 font-black'>Teachers</div>
                                                                    {schoolPersonnel.teachers.map((person, idx) => {
                                                                        if (isReadOnlyTeacherId(person.id)) {
                                                                            return false
                                                                        }
                                                                        if (this.props.person &&
                                                                            this.props.person.id === person.id) {
                                                                            return false
                                                                        }
                                                                        // don't show notifications for the selected group, it will be unwritten
                                                                        // quickly, avoids potential flicker of the notification dot
                                                                        let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
                                                                        let hasNotification = this.props.chatNotificationDict &&
                                                                            this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                                                                            this.props.chatNotificationDict[sortedViewerId] &&
                                                                            (!this.state.selectedTeacher ||
                                                                                (this.state.selectedTeacher &&
                                                                                    this.state.selectedTeacher.id !== person.id))
                                                                        return <div key={'chat-message-' + person.id}
                                                                            className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer relative" +
                                                                                (this.state.selectedTeacher &&
                                                                                    this.state.selectedTeacher.id === person.id ? " side-menu-item-active" : "")}
                                                                            onClick={(e) => this.selectTeacher(person)}
                                                                        >
                                                                            <PersonAvatar
                                                                                person={person}
                                                                                size={'large'}
                                                                                avatarColor={'#fff1b8'}
                                                                                avatarTextColor={'#613400'}
                                                                                containerClassName="font-20"
                                                                                avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                                personClassName={"font-20 text-muted" + (hasNotification ? " font-black font-bold" : "")}
                                                                            />
                                                                            {hasNotification ?
                                                                                <div className="absolute-tr chat-active-dot" style={{ top: '35px', right: '12px' }}>
                                                                                </div>
                                                                                : ''}
                                                                        </div>
                                                                    })}
                                                                    <div className='font-16 font-bold font-black mt-2 mb-1'>Instructional and School Administrators</div>
                                                                    {schoolPersonnel.instructionalFacilitators.map((person, idx) => {
                                                                        if (isReadOnlyTeacherId(person.id)) {
                                                                            return false
                                                                        }
                                                                        if (this.props.person &&
                                                                            this.props.person.id === person.id) {
                                                                            return false
                                                                        }
                                                                        // don't show notifications for the selected group, it will be unwritten
                                                                        // quickly, avoids potential flicker of the notification dot
                                                                        let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
                                                                        let hasNotification = this.props.chatNotificationDict &&
                                                                            this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                                                                            this.props.chatNotificationDict[sortedViewerId] &&
                                                                            (!this.state.selectedTeacher ||
                                                                                (this.state.selectedTeacher &&
                                                                                    this.state.selectedTeacher.id !== person.id))
                                                                        return <div key={'chat-message-' + person.id}
                                                                            className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer relative" +
                                                                                (this.state.selectedTeacher &&
                                                                                    this.state.selectedTeacher.id === person.id ? " side-menu-item-active" : "")}
                                                                            onClick={(e) => this.selectTeacher(person)}
                                                                        >
                                                                            <PersonAvatar
                                                                                person={person}
                                                                                size={'large'}
                                                                                avatarColor={'#fff1b8'}
                                                                                avatarTextColor={'#613400'}
                                                                                containerClassName="font-20"
                                                                                avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                                personClassName={"font-20 text-muted" + (hasNotification ? " font-black font-bold" : "")}
                                                                            />
                                                                            {hasNotification ?
                                                                                <div className="absolute-tr chat-active-dot" style={{ top: '35px', right: '12px' }}>
                                                                                </div>
                                                                                : ''}
                                                                        </div>
                                                                    })}
                                                                    {schoolPersonnel.schoolAdmins.map((person, idx) => {
                                                                        if (isReadOnlyTeacherId(person.id)) {
                                                                            return false
                                                                        }
                                                                        if (this.props.person &&
                                                                            this.props.person.id === person.id) {
                                                                            return false
                                                                        }
                                                                        // don't show notifications for the selected group, it will be unwritten
                                                                        // quickly, avoids potential flicker of the notification dot
                                                                        let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
                                                                        let hasNotification = this.props.chatNotificationDict &&
                                                                            this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                                                                            this.props.chatNotificationDict[sortedViewerId] &&
                                                                            (!this.state.selectedTeacher ||
                                                                                (this.state.selectedTeacher &&
                                                                                    this.state.selectedTeacher.id !== person.id))
                                                                        return <div key={'chat-message-' + person.id}
                                                                            className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer relative" +
                                                                                (this.state.selectedTeacher &&
                                                                                    this.state.selectedTeacher.id === person.id ? " side-menu-item-active" : "")}
                                                                            onClick={(e) => this.selectTeacher(person)}
                                                                        >
                                                                            <PersonAvatar
                                                                                person={person}
                                                                                size={'large'}
                                                                                avatarColor={'#fff1b8'}
                                                                                avatarTextColor={'#613400'}
                                                                                containerClassName="font-20"
                                                                                avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                                personClassName={"font-20 text-muted" + (hasNotification ? " font-black font-bold" : "")}
                                                                            />
                                                                            {hasNotification ?
                                                                                <div className="absolute-tr chat-active-dot" style={{ top: '35px', right: '12px' }}>
                                                                                </div>
                                                                                : ''}

                                                                        </div>
                                                                    })}
                                                                </div>
                                                            })}
                                                        </div>
                                                        :
                                                        <div>
                                                            {Object.keys(this.props.schoolPersonnelDict).length > 0 ?
                                                                <Collapse defaultActiveKey={Object.keys(this.props.schoolPersonnelDict).length > 1 ? [] : Object.keys(this.props.schoolPersonnelDict)}
                                                                    className="no-content-padding collapse-vlg collapse-no-border pl-2 collapse-panel-w-100" bordered={false} ghost>
                                                                    {Object.keys(this.props.schoolPersonnelDict).map((schoolId, idx) => {
                                                                        let schoolPersonnel = this.props.schoolPersonnelDict[schoolId]
                                                                        let schoolHasNotification = this.schoolHasNotification(schoolPersonnel)
                                                                        return <Panel header={<div className='flex flex-v-center w-100' key={'school-panel-coaches-school-' + schoolId}>
                                                                            <div className={schoolHasNotification ? 'font-bold' : ''}>{schoolPersonnel.school.schoolName}</div>
                                                                            {schoolHasNotification ?
                                                                                <div className="ml-1 chat-active-dot">
                                                                                </div>
                                                                                : ''}
                                                                        </div>} key={schoolId}>
                                                                            <div className="pl-2 pr-2">
                                                                                <div className='font-16 font-bold mb-1 mt-1 font-black'>Teachers</div>
                                                                                {schoolPersonnel.teachers.map((person, idx) => {
                                                                                    if (isReadOnlyTeacherId(person.id)) {
                                                                                        return false
                                                                                    }
                                                                                    if (this.props.person &&
                                                                                        this.props.person.id === person.id) {
                                                                                        return false
                                                                                    }
                                                                                    // don't show notifications for the selected group, it will be unwritten
                                                                                    // quickly, avoids potential flicker of the notification dot
                                                                                    let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
                                                                                    let hasNotification = this.props.chatNotificationDict &&
                                                                                        this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                                                                                        this.props.chatNotificationDict[sortedViewerId] &&
                                                                                        (!this.state.selectedTeacher ||
                                                                                            (this.state.selectedTeacher &&
                                                                                                this.state.selectedTeacher.id !== person.id))
                                                                                    return <div key={'chat-message-' + person.id}
                                                                                        className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer relative" +
                                                                                            (this.state.selectedTeacher &&
                                                                                                this.state.selectedTeacher.id === person.id ? " side-menu-item-active" : "")}
                                                                                        onClick={(e) => this.selectTeacher(person)}
                                                                                    >
                                                                                        <PersonAvatar
                                                                                            person={person}
                                                                                            size={'large'}
                                                                                            avatarColor={'#fff1b8'}
                                                                                            avatarTextColor={'#613400'}
                                                                                            containerClassName="font-20"
                                                                                            avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                                            personClassName={"font-20 text-muted" + (hasNotification ? " font-black font-bold" : "")}
                                                                                        />
                                                                                        {hasNotification ?
                                                                                            <div className="absolute-tr chat-active-dot" style={{ top: '35px', right: '12px' }}>
                                                                                            </div>
                                                                                            : ''}
                                                                                    </div>
                                                                                })}
                                                                                <div className='font-16 font-bold font-black mt-2 mb-1'>Instructional and School Administrators</div>
                                                                                {schoolPersonnel.instructionalFacilitators.map((person, idx) => {
                                                                                    if (isReadOnlyTeacherId(person.id)) {
                                                                                        return false
                                                                                    }
                                                                                    if (this.props.person &&
                                                                                        this.props.person.id === person.id) {
                                                                                        return false
                                                                                    }
                                                                                    // don't show notifications for the selected group, it will be unwritten
                                                                                    // quickly, avoids potential flicker of the notification dot
                                                                                    let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
                                                                                    let hasNotification = this.props.chatNotificationDict &&
                                                                                        this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                                                                                        this.props.chatNotificationDict[sortedViewerId] &&
                                                                                        (!this.state.selectedTeacher ||
                                                                                            (this.state.selectedTeacher &&
                                                                                                this.state.selectedTeacher.id !== person.id))
                                                                                    return <div key={'chat-message-' + person.id}
                                                                                        className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer relative" +
                                                                                            (this.state.selectedTeacher &&
                                                                                                this.state.selectedTeacher.id === person.id ? " side-menu-item-active" : "")}
                                                                                        onClick={(e) => this.selectTeacher(person)}
                                                                                    >
                                                                                        <PersonAvatar
                                                                                            person={person}
                                                                                            size={'large'}
                                                                                            avatarColor={'#fff1b8'}
                                                                                            avatarTextColor={'#613400'}
                                                                                            containerClassName="font-20"
                                                                                            avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                                            personClassName={"font-20 text-muted" + (hasNotification ? " font-black font-bold" : "")}
                                                                                        />
                                                                                        {hasNotification ?
                                                                                            <div className="absolute-tr chat-active-dot" style={{ top: '35px', right: '12px' }}>
                                                                                            </div>
                                                                                            : ''}
                                                                                    </div>
                                                                                })}
                                                                                {schoolPersonnel.schoolAdmins.map((person, idx) => {
                                                                                    if (isReadOnlyTeacherId(person.id)) {
                                                                                        return false
                                                                                    }
                                                                                    if (this.props.person &&
                                                                                        this.props.person.id === person.id) {
                                                                                        return false
                                                                                    }
                                                                                    // don't show notifications for the selected group, it will be unwritten
                                                                                    // quickly, avoids potential flicker of the notification dot
                                                                                    let sortedViewerId = this.getSortedViewerId([this.props.person.id, person.id])
                                                                                    let hasNotification = this.props.chatNotificationDict &&
                                                                                        this.props.chatNotificationDict.hasOwnProperty(sortedViewerId) &&
                                                                                        this.props.chatNotificationDict[sortedViewerId] &&
                                                                                        (!this.state.selectedTeacher ||
                                                                                            (this.state.selectedTeacher &&
                                                                                                this.state.selectedTeacher.id !== person.id))
                                                                                    return <div key={'chat-message-' + person.id}
                                                                                        className={"pl-1 pr-1 pt-2 pb-2 mb-1 br-20 ant-shadow ant-shadow-more-hover cursor-pointer relative" +
                                                                                            (this.state.selectedTeacher &&
                                                                                                this.state.selectedTeacher.id === person.id ? " side-menu-item-active" : "")}
                                                                                        onClick={(e) => this.selectTeacher(person)}
                                                                                    >
                                                                                        <PersonAvatar
                                                                                            person={person}
                                                                                            size={'large'}
                                                                                            avatarColor={'#fff1b8'}
                                                                                            avatarTextColor={'#613400'}
                                                                                            containerClassName="font-20"
                                                                                            avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                                            personClassName={"font-20 text-muted" + (hasNotification ? " font-black font-bold" : "")}
                                                                                        />
                                                                                        {hasNotification ?
                                                                                            <div className="absolute-tr chat-active-dot" style={{ top: '35px', right: '12px' }}>
                                                                                            </div>
                                                                                            : ''}

                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </Panel>
                                                                    })}
                                                                </Collapse> : ''}
                                                        </div>}

                                                </div>
                                            </Panel>
                                        </Collapse>

                                    </div>
                                    <div style={
                                        { overflowX: 'initial', width: 'calc(100% - 340px)', height: 'calc(100vh - 83px)', }}
                                        className="inline-block ml-3"
                                    >
                                        {!(this.state.selectedTeacher || this.state.selectedGroup) ?
                                            <div className="font-20 mt-50">
                                                {/* 
                                                <div className="text-center">
                                                    <img src="/chatselect.png" alt="background-img" />
                                                </div>
                                                */}

                                                <div className='text-center'>
                                                    <img src="/teams-banner.png" alt="banner" className='w-50' />
                                                </div>
                                                <div className="font-24 mt-2 text-center">
                                                    Welcome to Dot It Teams! You can start by selecting a team or colleague in the menu on the left.
                                                </div>


                                            </div>
                                            : ''}
                                        {this.state.selectedTeacher || this.state.selectedGroup ?
                                            <div className="w-100 h-100">
                                                <div className="w-100" style={{ minHeight: '115px' }}>
                                                    <div className="flex w-100 pl-2 pr-2 pt-2">
                                                        <div className="font-24 mb-1">
                                                            {this.state.selectedGroup ?
                                                                this.state.selectedGroup.groupName :
                                                                <PersonAvatar
                                                                    person={this.state.selectedTeacher}
                                                                    size={'large'}
                                                                    avatarColor={'#fff1b8'}
                                                                    avatarTextColor={'#613400'}
                                                                    containerClassName="font-20"
                                                                    avatarClassName="font-20 font-bold mr-1 avatar-vlg"
                                                                    personClassName={"font-20"}
                                                                />
                                                            }
                                                        </div>
                                                        {this.props.person &&
                                                            this.state.selectedGroup &&
                                                            this.state.selectedGroup.groupCreatedById === this.props.person.id ?
                                                            <div className="ml-auto">
                                                                <EditGroupForm
                                                                    teacher={this.props.person}
                                                                    schoolPersonnelDict={this.props.schoolPersonnelDict}
                                                                    group={this.state.selectedGroup}
                                                                />
                                                                <Tooltip
                                                                    placement="bottomRight"
                                                                    title="Delete team"
                                                                >
                                                                    <Button
                                                                        shape="circle"
                                                                        icon="delete"
                                                                        size="large"
                                                                        className="font-24"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                deleteGroupModalVisible: true,
                                                                            })
                                                                        }} />
                                                                </Tooltip>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <Tabs
                                                        size="large"
                                                        defaultActiveKey="allMessages"
                                                        animated={false}
                                                        value={this.state.activeMessageTab}
                                                        onChange={this.onMessageTabChange}
                                                    >
                                                        <TabPane tab={"All messages"} key={"allMessages"}>
                                                        </TabPane>
                                                        <TabPane tab={"Uploaded goals"} key={"uploadedGoals"}>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                                <div
                                                    style={{ height: 'calc(100% - (115px + 183px + 15px))', background: '#fafafa' }}
                                                    className={"pt-2 pb-2 overflow-y-auto"}
                                                    ref={(el) => { this.chatBox = el }}
                                                >
                                                    {this.state.loadingMessages ?
                                                        <div className={"flex flex-h-center" +
                                                            " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                                            <div>
                                                                <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            {this.state.activeMessageTab === 'allMessages' &&
                                                                this.state.messages.length === 0 ?
                                                                <div className="font-20 p-2">
                                                                    <Empty
                                                                        containerClassName="text-center"
                                                                        width={55}
                                                                        height={55}
                                                                        description="There are currently no messages."
                                                                        image={'/empty/chat.png'}
                                                                    />
                                                                </div>
                                                                : ''}

                                                            {this.state.activeMessageTab === 'uploadedGoals' &&
                                                                this.state.goalMessages.length === 0 ?
                                                                <div className="font-20 p-2">
                                                                    <Empty
                                                                        containerClassName="text-center"
                                                                        width={55}
                                                                        height={55}
                                                                        description="There are currently no uploaded goals."
                                                                        image={'/empty/goals.png'}
                                                                    />
                                                                </div>
                                                                : ''}

                                                            {(this.state.activeMessageTab === 'allMessages' ?
                                                                this.state.messages :
                                                                this.state.goalMessages).map((message, idx) => {
                                                                    let authoredByMe = false
                                                                    if (message.hasOwnProperty('authorId') && this.props.person) {
                                                                        authoredByMe = message.authorId === this.props.person.id
                                                                    }
                                                                    return <div key={message.id} className="pl-2 pr-2 pt-2 pb-2 parent-hover relative">
                                                                        <div className="flex">
                                                                            {!authoredByMe ?
                                                                                <div className="inline-block">
                                                                                    <Avatar size="large" className={"mr-1 font-20 font-bold avatar-vlg font-black"}
                                                                                        style={{ backgroundColor: "#fff1b8", color: "#613400" }}>
                                                                                        <span style={{ color: '#613400' }}>{getInitials({
                                                                                            firstName: message.authorFirstName,
                                                                                            lastName: message.authorLastName,
                                                                                        })}
                                                                                        </span>
                                                                                    </Avatar>
                                                                                </div> : ''}
                                                                            <div className={"inline-block font-20" + (authoredByMe ? "  ml-auto" : "")}>

                                                                                <div className="p-2 br-20 border background-fff inline-block">
                                                                                    <div>
                                                                                        <span className="font-bold mr-1">
                                                                                            {message.authorFirstName + " " + message.authorLastName}
                                                                                        </span>
                                                                                        <span className="text-muted font-16">
                                                                                            {message.timeStamp ?
                                                                                                moment.utc(message.timeStamp.seconds * 1000).format("MM/DD/YYYY") :
                                                                                                ''}
                                                                                        </span>
                                                                                    </div>
                                                                                    {message.hasOwnProperty('messageType') && message.messageType === 'goal' ?
                                                                                        <div>
                                                                                            <div className="mb-1 font-20">Shared a goal</div>
                                                                                            <div className={"br-4 mb-1 background-fff overflow-hidden border font-black font-16 ant-shadow-more-hover parent-hover relative cursor-pointer"}
                                                                                                onClick={() => {
                                                                                                    this.setViewingIEP(message.iep)
                                                                                                }}>
                                                                                                <div>
                                                                                                    <div className={"pt-1 pb-1 pl-2 pr-2 background-cyan font-white font-bold font-20"}>
                                                                                                        {message.iep.hasOwnProperty("isMTSS") && message.iep.isMTSS ?
                                                                                                            message.iep.iep.tier === "Tier 2" ?
                                                                                                                "Tier 2 Goal" :
                                                                                                                "Tier 3 Goal" :
                                                                                                            "IEP Goal"}
                                                                                                    </div>
                                                                                                    <div className="p-3">
                                                                                                        <div className="mb-1 font-24 font-weight-500">{message.iep.iep.mainSubject}</div>
                                                                                                        <div className="font-20">{capitalizeFirstChar(message.iep.iep.standardDescription)}</div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        message.message}
                                                                                </div>
                                                                            </div>

                                                                            {authoredByMe ?
                                                                                <div className="inline-block">
                                                                                    <Avatar size="large" className={"ml-1 font-20 font-bold avatar-vlg font-black"}
                                                                                        style={{ backgroundColor: "#b5f5ec" }}>
                                                                                        <span style={{ color: "#002329" }}>
                                                                                            {getInitials({
                                                                                                firstName: message.authorFirstName,
                                                                                                lastName: message.authorLastName,
                                                                                            })}
                                                                                        </span>
                                                                                    </Avatar>
                                                                                </div> : ''}
                                                                        </div>

                                                                        {this.props.person &&
                                                                            message.authorId === this.props.person.id ?
                                                                            <div className="absolute-tr show-on-parent-hover" style={{ right: '16px', top: '16px' }}>
                                                                                <Tooltip
                                                                                    placement="topRight"
                                                                                    title="Delete message"
                                                                                >
                                                                                    <Button icon="delete" shape="circle" className="font-24" size="large"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                deletingMessageId: message.id,
                                                                                                deleteMessageModalVisible: true,
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                            : ''}

                                                                    </div>
                                                                })}
                                                        </div>}
                                                </div>
                                                <div className="w-100 pt-2 pb-2 border-top" style={{ maxHeight: '183px' }}>
                                                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                                                        <div>
                                                            <div className="relative w-100">
                                                                <FormItem {...formItemLayout} label={null} className="mb-2">
                                                                    {getFieldDecorator('message', {
                                                                        rules: [{ required: true, message: 'Message is required.' }],
                                                                    })(
                                                                        <TextArea rows={2} className="no-resize mr-2 mb-1 font-20" placeholder="Start typing a message..." />
                                                                    )}
                                                                </FormItem>
                                                            </div>
                                                        </div>


                                                        <div className="flex w-100">
                                                            <FormItem className="mb-0 ml-auto">
                                                                <div className='flex flex-v-center'>
                                                                    {this.props.personRole === 'teacher' ?
                                                                        <Button size="large"
                                                                            className="flex flex-v-center flex-h-center font-20 button--secondary mr-2 ml-auto"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    uploadGoalModalVisible: true,
                                                                                })
                                                                            }}>
                                                                            <span>
                                                                                Upload goals
                                                                            </span>
                                                                        </Button> : ''}
                                                                    <Button htmlType="submit"
                                                                        size="large"
                                                                        className="flex flex-v-center flex-h-center font-20 button--main">
                                                                        Send message
                                                                    </Button>
                                                                </div>
                                                            </FormItem>
                                                        </div>

                                                    </Form>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default Form.create()(ChatHome)