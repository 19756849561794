import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import { db, firebase } from '../firebase/Firebase'
import { CLEVER_CLIENT_ID, CLEVER_REDIRECT_URI } from '.././Util'
import CustomFooter from './CustomFooter'
//import ColType from '.././Types'
//import { Icon, Form, Input, Button, Spin } from 'antd'

/*
const successMessage = (description) => {
    message.success(description)
}
*/

class SignedOutStudentClever extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="pt-4 pb-70 relative" style={{ minHeight: '100%' }}>
                <div className='absolute-tl w-100 h-100'>
                </div>

                <div className='relative'>
                    <div className="text-center mb-3">
                        <a href="https://dotit.app/">
                            <img src='/dotitlogo.png' alt='logo' height='64' />
                        </a>
                    </div>
                    <div className="p-4 w-500 m-lr-auto br-20 ant-shadow background-fff">
                        <div className="block w-100 m-lr-auto mb-1 text-center">
                            <img src="/backpack.png" alt="backpack-icon" height="300" />
                        </div>
                        <div>
                            <div className="mb-3 text-center pt-3 font-30 font-bold">You are logged out</div>

                            <div className='text-center mt-2'>
                                <div className='font-20 font-bold text-left pl-2 mb-1'>Log back in with Clever</div>
                                <a href={`https://clever.com/oauth/authorize?response_type=code&client_id=${CLEVER_CLIENT_ID}&redirect_uri=${CLEVER_REDIRECT_URI}`}>
                                    <img src="https://files.readme.io/efb3f5d-LogInWithClever2x.png" alt="Clever log in button" class="" align="" caption="" height="auto" width="auto" loading="lazy" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <CustomFooter
                    theme='dark'
                />
            </div>
        )
    }
}

export default SignedOutStudentClever