import React, { Component } from 'react'
import { db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc } from '../Util'
import '../styles/GlobalStyle.css'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { Row, Col, message } from 'antd'

const loadingMessage = (description) => {
  message.destroy()
  message.loading(description, 0)
}

const errorMessage = (description) => {
  message.destroy()
  message.error(description)
}

var stripe = null
var elements = null

const loadStripeScript = (callback) => {
  var existingScript = document.getElementById('stripe')
  if (existingScript) {
    existingScript.parentElement.removeChild(existingScript)
  }

  const script = document.createElement('script')
  script.src = 'https://js.stripe.com/v3'  // URL for the third-party library being loaded.
  script.id = 'stripe' // e.g., googleMaps or stripe
  document.body.appendChild(script)

  script.onload = () => {
    if (callback) callback()
  }
}

class RenewPaidAccount extends Component {
  state = {
    user: null,
    userUID: null,
    teacherId: null,
    person: null,
    accessLevel: null,
    loginMounted: false,
    height: 0, 
    width: 0,
    submitting: false,
    noUserData: false,
    paymentSuccess: false,
  }

  randomString = (length) => {
    var charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var i
    var result = ""
    if(window.crypto && window.crypto.getRandomValues)
    {
        var values = new Uint32Array(length)
        window.crypto.getRandomValues(values)
        for(i=0; i<length; i++)
        {
            result += charset[values[i] % charset.length]
        }
        return result
    } else {
        alert("This browser does not have crypto and cannot create random passwords.")
        return ''
    }
  }


  stripeLoadedCallback = () => {
    console.log("stripe callback", window.hasOwnProperty('Stripe'))
    if (!window.hasOwnProperty('Stripe') || window.hasOwnProperty('Stripe') == null) {
      console.log("Could not load stripe.")
      return
    }

    stripe = window['Stripe']('pk_live_fpirAV4hKWwrzwlnZpWuRAhZ00x43lE5Ec')
    elements = stripe.elements()

    // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '16px',
        color: "#32325d",
      }
    }

    // Create an instance of the card Element.
    var card = elements.create('card', {style: style})

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element')

    card.addEventListener('change', ((event) => {
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    }))

    var form = document.getElementById('payment-form')
    form.addEventListener('submit', ((event) => {
      event.preventDefault()
      if (this.state.noUserData) {
        errorMessage("A problem occurred. Please contact support@dotit.app")
        return
      }

      this.setState({
        submitting: true,
      })
      stripe.createToken(card).then((result) => {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
          this.setState({
            submitting: false,
          })
        } else {
          this.setState({
            submitting: true,
          })
          loadingMessage("Processing. Please wait...")
          // Send the token to your server.
          // stripeTokenHandler(result.token)
          console.log("Send token", result.token)
          
          // Will write back to the added doc on status update from server
          db.collection(ColType.paymentStatus)
            .add({
              status: 'Pending',
            })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id)
              var statusListener = db.collection(ColType.paymentStatus)
                .doc(docRef.id)
                .onSnapshot((doc) => {
                  var statusInfo = flattenDoc(doc)
                  console.log("Received status update", statusInfo)
                  if (statusInfo.status === "Success") {
                    // unsub listener
                    statusListener()
                    this.setState({
                      submitting: false,
                      paymentSuccess: true,
                    })
                    message.destroy()
                    console.log("Payment success")
                  }
                  else if (statusInfo.status === "Error") {
                    // unsub listener
                    statusListener()
                    this.setState({
                      submitting: false,
                    })
                    message.destroy()
                    console.log("Payment error")
                    errorMessage("A payment error occurred. Try again or contact support@dotit.app")
                  }
                })
              
              // add paymentInfo
              db.collection(ColType.renewPaymentInfo)
                .add({
                  paymentStatusRefId: docRef.id,
                  userUID: this.state.userUID,
                  teacherId: this.state.teacherId,
                  stripeToken: result.token
                })
                .then((docRef) => {

                })
                .catch((e) => {
                  console.log("paymentinfo e", e)
                  errorMessage("An error occurred. Please try again.")
                  this.setState({
                    submitting: false,
                  })
                })
          })
          .catch((error) => {
              console.error("payment status e", error)
              errorMessage("An error occurred. Please try again.")
              this.setState({
                submitting: false,
              })
          })
        }
      })
    }))
  }

  componentDidMount() {
    console.log("renew subscription mount", this.props)
    if (!this.props.location.state.userUID || 
        !this.props.location.state.teacherId) {
            this.setState({
                noUserData: true,
            })
        }
    this.setState({
        userUID: this.props.location.state.userUID,
        teacherId: this.props.location.state.teacherId
    })
    loadStripeScript(this.stripeLoadedCallback)
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  };

  scrollToPreviewVideo() {
    var previewContainerEl = document.getElementById('preview-video-container')
    var y = previewContainerEl.getBoundingClientRect().top + window.scrollY
    y -= 60
    window.scroll({
      top: y,
      behavior: 'smooth'
    })
  }

  render() {
    return (
     
      <div className="pb-70 h-100 relative">
      <Helmet>
       <meta charSet="utf-8" />
       <title>Renew subscription</title>
       <meta name="description" 
                 content={"dot it is special education software that " +
                   "helps you draft IEPs, schedule services, monitor " +
                   "standards based goals and allocate resources wisely."} 
           />
           <meta name="keywords" content={"dot it,dotit,dotitapp," +
                   "dot it special education,dot it Special Education,dot it IEP Goals," +
                   "dot it IEPS,dot it IEP," +
                   "dot it IEPs,dot it standards,dot it standard,dot it standard based," +
                   "dot it standard based IEP," +
                   "dotit special education,Special Education,IEP Goals,IEPS,IEP," +
                   "dotit IEPs,standards,standard,standard based,standard based IEP," +
                   "standard based IEP goals,software,iep software,iep goal software," +
                   "iep calendar,progress monitoring,iep progress monitoring,iep charts," +
                   "iep reports,print iep reports,printable iep reports,district reports," +
                   "district summary,school reports,school summary"} 
           />
       </Helmet>

       <div className="pt-50 pb-4 h-100">

<div>
  <Link to="/">
    <img src='/dotit-iep-logo.png' alt='logo' height='84'  
    style={{ marginLeft:'7%'}}/>
  </Link>
</div>
<div className="pt-3 pb-4 h-100">






<div className="pt-3 max-w-1200 m-lr-auto">
            {this.state.paymentSuccess ?
                <div className="max-w-500 m-lr-auto">
                    <div className="font-30"> 
                    Your subscription has been renewed.
                  </div>
                  <div className="mt-2">
                    <Link className={"ant-btn btn-cl ant-btn-primary " +
              "font-20 flex flex-v-center flex-h-center " +
              "ant-shadow mt-4 font-bold"}
                    to={"/teacher/home/" + this.state.teacherId}>
                        <span className="font-bold">Go back to my home page</span>
                    </Link>
                  </div>
                </div>
                :
                    <Row gutter={128}>
                    <Col 
                  xs={{ span: 24, offset: 0 }} 
                  sm={{ span: 24, offset: 0 }} 
                  md={{ span: 24, offset: 0 }} 
                  lg={{ span: 24, offset: 0 }} 
                  xl={{ span: 12, offset: 0 }} 
                >
                 <div className="">
                 <div className="font-30"> 
                    Your subscription has expired
                  </div>
                  <div className="mt-2 font-24">
                    Would you like to buy another subscription for a year?
                  </div>
                  <div className="mt-1">
              <a href={"https://firebasestorage.googleapis.com/v0/b/" +
          "education-9d7f3.appspot.com/o/Terms%20of%20Service%20" +
          "-%20dot%20it.pdf?alt=media&token=f949f25a-2c24-4bae-a" +
          "59f-f5c71e48b743"}
            target={"_blank"}
          >
            <span>By renewing your account you agree to our terms</span>
          </a>
              </div>
                  
  
                </div>
                </Col>
                <Col 
                  xs={{ span: 24, offset: 0 }} 
                  sm={{ span: 24, offset: 0 }} 
                  md={{ span: 24, offset: 0 }} 
                  lg={{ span: 24, offset: 0 }} 
                  xl={{ span: 12, offset: 0 }} 
                >
                  <div className="mt-1">
                      {this.state.noUserData ?
                       <div className="font-30"> 
                         An error occurred. Please go to the home page
                         and sign out. Then sign back in. If this does
                         not fix the problem please email support@dotit.app
                       </div>
                      :
                      <div>
                  <div className="font-30"> 
                    Payment Information
                  </div>
              <form id="payment-form" className="pt-4">

                <div className="pb-2 mt-1">
                  <Row gutter={16}>
                    <Col span={24}>
                  <div className="form-row ">
                <label htmlFor="card-element" className="font-16">
                  Credit or debit card:
                </label>
                <div id="card-element" className="background-fff p-1">
                </div>

                <div id="card-errors" role="alert"></div>
                </div>
              
                  </Col>
                  <Col span={0}>
                  
                  </Col>
                  </Row>
                </div>



              
            <div>
            <button 
              className={"ant-btn btn-cl ant-btn-primary " +
              "font-20 flex flex-v-center flex-h-center " +
              "ant-shadow mt-4 float-right"}
              disabled={this.state.submitting}
            >
              <span className="font-bold">
              {'Buy subscription ($299)'}
               </span>
            </button>

              </div>
            </form>
            </div>
            }
            </div>
            </Col>
        </Row> }

            </div>




      




            </div>
            </div>
            </div>




     
    )
  }
}

export default RenewPaidAccount