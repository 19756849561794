import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import ChangeFolder from './ChangeFolder'
import { Row, Col, Icon, Button } from 'antd'
//import { db } from '../../../firebase/Firebase'
//import ColType from '../../../Types'
import { FOLDER_CURRENT, FOLDER_ARCHIVED, FOLDER_TRASHBIN, FOLDER_PERMANENTLY_DELETED, isReadOnlyTeacherId, FOLDER_TRANSFER, gradeToGradeLevel, capitalizeFirstChar } from '../../Util'
import TransferGoals from './TransferGoals'

class StudentNavigation extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        let studentIdx = -1
        let prevStudentIdx = 0
        let nextStudentIdx = 0
        if (this.props.teacherBaseProps.students && this.props.student) {
            for (let i = 0; i < this.props.teacherBaseProps.students.length; i++) {
                if (this.props.teacherBaseProps.students[i].id === this.props.student.id) {
                    studentIdx = i
                    break
                }
            }
            if (studentIdx !== -1) {
                prevStudentIdx = studentIdx - 1
                nextStudentIdx = studentIdx + 1
                if (prevStudentIdx < 0) {
                    prevStudentIdx = 0
                }
                if (nextStudentIdx > this.props.teacherBaseProps.students.length - 1) {
                    nextStudentIdx = this.props.teacherBaseProps.students.length - 1
                }
            }
        }

        let studentNavigationTitle = ''
        let studentNavigationLink = ''

        if (this.props.folderFrom === FOLDER_CURRENT) {
            studentNavigationTitle = 'Students'
            studentNavigationLink = '/teacher/home/'
        } else if (this.props.folderFrom === FOLDER_ARCHIVED) {
            studentNavigationTitle = 'Archived'
            studentNavigationLink = '/teacher/archived/students/'
        } else if (this.props.folderFrom === FOLDER_TRANSFER) {
            studentNavigationTitle = 'Transfers'
            studentNavigationLink = '/teacher/transfers/students/'
        } else if (this.props.folderFrom === FOLDER_TRASHBIN) {
            studentNavigationTitle = 'Recycle bin'
            studentNavigationLink = '/teacher/deleted/students/'
        } else {
            console.error('StudentNavigation.js: folderFrom and folderTo are not set correctly', this.props.folderFrom, this.props.folderTo)
        }

        return (
            <div>
                {this.props.teacherBaseProps &&
                    this.props.teacherBaseProps.teacher &&
                    this.props.student ?
                    <div className="br-4">
                        <div className="w-100 flex flex-v-center mb-2 font-16 font-bold">
                            <Link to={studentNavigationLink + this.props.teacherBaseProps.teacher.id}>
                                {studentNavigationTitle}
                            </Link>
                            <Icon type="right" className="ml-1 mr-1 font-14" />
                            <span className='ellipsis' style={{ maxWidth: '250px' }}>
                                {this.props.student.firstName + " " + this.props.student.lastName}
                            </span>
                        </div>

                        <div className='br-20 overflow-hidden'>

                            <div className='sub-menu-width m-lr-auto br-4 relative pl-3 pr-3'
                                style={{ height: '248px', }}>

                                <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#531dab' }}>
                                </div>

                                <div className="absolute-tl w-100 h-100 image-box-mask">
                                </div>

                                <div className="flex flex-v-center w-100 h-100 font-fff">
                                    <Row className=''>
                                        {/*
                                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                            {studentIdx !== -1 ?
                                                <div className={"float-left flex flex-v-center" +
                                                    (studentIdx === 0 ? " invisible" : "")}>
                                                    <Link to={studentLink + this.props.teacherBaseProps.teacher.id +
                                                        "?student=" + this.props.teacherBaseProps.students[prevStudentIdx].id}
                                                        disabled={studentIdx === 0}
                                                        className="ant-btn ant-btn-circle circle-icon-btn-lg">
                                                        <Icon type="arrow-left" />
                                                    </Link>
                                                    <span className="font-20 font-bold ml-2">Previous student</span>
                                                </div>
                                                : ''}
                                        </Col>
                                        */}
                                        <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                                            <div className="w-100 pl-2">
                                                <div className='w-100 font-38 font-bold'>
                                                    {this.props.student.firstName + " " + this.props.student.lastName}
                                                </div>
                                                <div className='w-100 font-24 font-bold'>
                                                    {gradeToGradeLevel(this.props.student.grade) !== 'kindergarten' ?
                                                        gradeToGradeLevel(this.props.student.grade) + ' grade' :
                                                        capitalizeFirstChar(gradeToGradeLevel(this.props.student.grade))}
                                                </div>
                                            </div>
                                        </Col>
                                        {/*
                                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                            {studentIdx !== -1 ?
                                                <div className={"float-right flex flex-v-center" +
                                                    (studentIdx === this.props.teacherBaseProps.students.length - 1 ? " invisible" : "")}>
                                                    <span className="font-20 font-bold mr-2">Next student</span>
                                                    <Link to={studentLink + this.props.teacherBaseProps.teacher.id +
                                                        "?student=" + this.props.teacherBaseProps.students[nextStudentIdx].id}
                                                        className="ant-btn ant-btn-circle circle-icon-btn-lg">
                                                        <Icon type="arrow-right" />
                                                    </Link>
                                                </div>
                                                : ''}
                                        </Col>*/}

                                        {/*
                                        <Col span={24}>
                                            <div className="mt-3 pt-2 mb-3 pb-2 button-row">
                                                <div className='inline-flex'>
                                                    {this.props.folderFrom === FOLDER_CURRENT ?
                                                        <Button
                                                            size="large"
                                                            className="mr-1 ant-btn ant-btn-lg font-bold pl-2 pr-2 paper-btn font-cyan-hover"
                                                            disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                this.props.history.push(
                                                                    {
                                                                        pathname: '/teacher/edit-student/' +
                                                                            this.props.teacherBaseProps.teacher.id + '?student=' +
                                                                            this.props.student.id
                                                                    }
                                                                )
                                                            }}>
                                                            <Icon type="edit" />
                                                            <span>Edit student</span>
                                                        </Button>
                                                        : ''}
                                                    <ChangeFolder
                                                        history={this.props.history}
                                                        onMoveFromCurrent={this.props.onMoveFromCurrent}
                                                        onBeforeMove={this.props.onBeforeMove}
                                                        onAllGoalsMoved={this.props.onAllGoalsMoved}
                                                        teacherBaseProps={this.props.teacherBaseProps}
                                                        teacherId={this.props.teacherBaseProps.teacher.id}
                                                        student={this.props.teacherBaseProps.studentDict[this.props.student.id]}
                                                        totalIEPGoals={
                                                            this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.studentIEPDict &&
                                                                this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.student.id)
                                                                ?
                                                                this.props.teacherBaseProps.studentIEPDict[this.props.student.id]
                                                                :
                                                                []
                                                        }
                                                        IEPGoals={
                                                            this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.studentIEPDict &&
                                                                this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.student.id)
                                                                ?
                                                                this.props.teacherBaseProps.studentIEPDict[this.props.student.id]
                                                                :
                                                                []
                                                        }
                                                        folderFrom={this.props.folderFrom}
                                                        folderTo={this.props.folderTo}
                                                        isNotStudent={false}
                                                        changeStudentFolderToo={true}
                                                    />
                                                    {this.props.folderFrom === FOLDER_CURRENT &&
                                                        this.props.student &&
                                                        this.props.teacherBaseProps.studentDict &&
                                                        this.props.teacherBaseProps.studentDict.hasOwnProperty(this.props.student.id) ?
                                                        <TransferGoals
                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                            transferMode={'student'}
                                                            transferStudent={this.props.teacherBaseProps.studentDict[this.props.student.id]}
                                                        /> : ''}
                                                    {this.props.folderFrom !== FOLDER_TRASHBIN ?
                                                        <ChangeFolder
                                                            history={this.props.history}
                                                            onMoveFromCurrent={this.props.onMoveFromCurrent}
                                                            onBeforeMove={this.props.onBeforeMove}
                                                            onAllGoalsMoved={this.props.onAllGoalsMoved}
                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                            teacherId={this.props.teacherBaseProps.teacher.id}
                                                            student={this.props.teacherBaseProps.studentDict[this.props.student.id]}
                                                            totalIEPGoals={
                                                                this.props.teacherBaseProps &&
                                                                    this.props.teacherBaseProps.studentIEPDict &&
                                                                    this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.student.id)
                                                                    ?
                                                                    this.props.teacherBaseProps.studentIEPDict[this.props.student.id]
                                                                    :
                                                                    []
                                                            }
                                                            IEPGoals={
                                                                this.props.teacherBaseProps &&
                                                                    this.props.teacherBaseProps.studentIEPDict &&
                                                                    this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.student.id)
                                                                    ?
                                                                    this.props.teacherBaseProps.studentIEPDict[this.props.student.id]
                                                                    :
                                                                    []
                                                            }
                                                            folderFrom={this.props.folderFrom}
                                                            folderTo={FOLDER_TRASHBIN}
                                                            isNotStudent={false}
                                                            changeStudentFolderToo={true}
                                                        /> : ''}
                                                    {this.props.folderFrom === FOLDER_TRASHBIN ?
                                                        <ChangeFolder
                                                            history={this.props.history}
                                                            onMoveFromCurrent={this.props.onMoveFromCurrent}
                                                            onBeforeMove={this.props.onBeforeMove}
                                                            onAllGoalsMoved={this.props.onAllGoalsMoved}
                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                            teacherId={this.props.teacherBaseProps.teacher.id}
                                                            student={this.props.teacherBaseProps.studentDict[this.props.student.id]}
                                                            totalIEPGoals={
                                                                this.props.teacherBaseProps &&
                                                                    this.props.teacherBaseProps.studentIEPDict &&
                                                                    this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.student.id)
                                                                    ?
                                                                    this.props.teacherBaseProps.studentIEPDict[this.props.student.id]
                                                                    :
                                                                    []
                                                            }
                                                            IEPGoals={
                                                                this.props.teacherBaseProps &&
                                                                    this.props.teacherBaseProps.studentIEPDict &&
                                                                    this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.student.id)
                                                                    ?
                                                                    this.props.teacherBaseProps.studentIEPDict[this.props.student.id]
                                                                    :
                                                                    []
                                                            }
                                                            folderFrom={this.props.folderFrom}
                                                            folderTo={FOLDER_PERMANENTLY_DELETED}
                                                            isNotStudent={false}
                                                            changeStudentFolderToo={true}
                                                        /> : ''}
                                                </div>
                                            </div>


                                                        </Col>*/}
                                    </Row>


                                </div>



                            </div>
                        </div>
                    </div> : ''}
            </div>
        )
    }
}

export default StudentNavigation
