import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {  db } from '../firebase/Firebase'
import CustomFooter from './CustomFooter'
import ColType from '.././Types'
import { flattenDoc } from '../Util'
import '../styles/GlobalStyle.css'
import { Row, Col, message, Layout } from 'antd'
const { Content } = Layout

const loadingMessage = (description) => {
  message.destroy()
  message.loading(description, 0)
}

const errorMessage = (description) => {
  message.destroy()
  message.error(description)
}


var stripe = null
var elements = null

const loadStripeScript = (callback) => {
  var existingScript = document.getElementById('stripe')
  if (existingScript) {
    existingScript.parentElement.removeChild(existingScript)
  }

  const script = document.createElement('script')
  script.src = 'https://js.stripe.com/v3'  // URL for the third-party library being loaded.
  script.id = 'stripe' // e.g., googleMaps or stripe
  document.body.appendChild(script)

  script.onload = () => {
    if (callback) callback()
  }
}

class CreatePaidAccount extends Component {
  state = {
    user: null,
    person: null,
    accessLevel: null,
    loginMounted: false,
    height: 0, 
    width: 0,
    submitting: false,
    email: '',
    paymentSuccess: false,
  }

  stripeLoadedCallback = () => {
    console.log("stripe callback", window.hasOwnProperty('Stripe'))
    if (!window.hasOwnProperty('Stripe') || window.hasOwnProperty('Stripe') == null) {
      console.log("Could not load stripe.")
      return
    }

    stripe = window['Stripe']('pk_live_51Gym1tLLUfpGHcUul2H8owgPXzHDmmaioI1zCWF' +
                              'OVYg78MI8e7JXKjJBSckeWPV05fFPR8ULE4kCQwRBEtOHTBO' +
                              'm00wVMWYl5B')
    elements = stripe.elements()

    // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '18px',
        color: "#32325d"
      }
    }

    // Create an instance of the card Element.
    var card = elements.create('card', {style: style})

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element')

    card.addEventListener('change', ((event) => {
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    }))

    var form = document.getElementById('payment-form')
    form.addEventListener('submit', ((event) => {
      event.preventDefault()
      if (!(this.props.teacherBaseProps.user &&
            this.props.teacherBaseProps.user.hasOwnProperty("uid") &&
            this.props.teacherBaseProps.user.uid)) {
        errorMessage("Could not load user, please contact support.")
        return
      }
      // prevent double clicks
      if (this.state.submitting) {
        return
      }
      this.setState({
        submitting: true,
      })
      stripe.createToken(card).then((result) => {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
          this.setState({
            submitting: false,
          })
        } else {
          loadingMessage("Processing. Please wait...")
          // Send the token to your server.
          // stripeTokenHandler(result.token)
          console.log("Send token", result.token)
          var userObj = {
            firstName: this.props.teacherBaseProps.user.firstName,
            lastName: this.props.teacherBaseProps.user.lastName,
            uid: this.props.teacherBaseProps.user.uid,
            emailAddress: this.props.teacherBaseProps.user.emailAddress,
          }
          if (this.props.teacherBaseProps.user.hasOwnProperty("schoolDistrict") &&
              this.props.teacherBaseProps.user.schoolDistrict) {
            userObj["schoolDistrict"] = this.props.teacherBaseProps.user.schoolDistrict
          }
          // Will write back to the added doc on status update from server
          db.collection(ColType.paymentStatus)
            .add({
              status: 'Pending',
              // copy user just to be safe
              user: userObj,
              stripeToken: result.token,
            })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id)
              var statusListener = db.collection(ColType.paymentStatus)
                .doc(docRef.id)
                .onSnapshot((doc) => {
                  var statusInfo = flattenDoc(doc)
                  console.log("Received status update", statusInfo)
                  if (statusInfo.status === "Success") {
                    // unsub listener
                    statusListener()
                    console.log("Payment success")
                    message.destroy()
                    this.setState({
                      paymentSuccess: true,
                      submitting: false,
                    })
                  }
                  else if (statusInfo.status === "Error") {
                    // unsub listener
                    statusListener()
                    this.setState({
                      submitting: false,
                    })
                    message.destroy()
                    console.log("Payment error")
                    errorMessage("A payment error occurred. Try again or contact support@dotit.app")
                  }
                })
          })
          .catch((error) => {
              console.error("payment status e", error)
              errorMessage("An error occurred. Please try again.")
              this.setState({
                submitting: false,
              })
          })
        }
      })
    }))
  }

  componentDidMount() {
    loadStripeScript(this.stripeLoadedCallback)
    document.title = 'Upgrade Account - dot it'
  }

  render() {
    console.log("teacher base props", this.props.teacherBaseProps)
    return (
      <div>
         <Layout className="content layout-header-mt">
          <Layout>
            <Content className="">
            <div>
            {this.state.paymentSuccess ?
            <div className="confetti-bkg"> 
            <div className="pt-40">
        <div className="g-col p-4 w-500 m-lr-auto br-15 ant-shadow">
                    <div className="font-24 mb-1"> 
                    Thank you for subscribing to Dot It. 
                    </div>
                    <div className="font-18 mb-2">
                    You now have access to all of Dot It's features.
                  </div>
                  {this.props.teacherBaseProps.teacher ?
                  <Link to={"/teacher/class/" + 
                    this.props.teacherBaseProps.teacher.id}
                    className={"w-100 br-4 pt-2 pb-2 mb-2 up-hover " +
                    "shadow-hover flex-center ant-btn font-18 " +
                    "ant-btn-primary font-bold-important flex-h-center"}>
                      Go Back To My Students Page
                  </Link>
                  : ''}
                  </div>
                  </div>
                  </div>
                :
                <div className="mt-40">
                <div className="g-col p-4 w-500 m-lr-auto br-15">
                <div className="font-24 mb-1"> 
                    Purchase Your Dot It <br/>Yearly Subscription for $279
                  </div>
                  <div className="font-18 mb-3"> 
                  Get unlimited access to draft 
                  compliant IEPs / Tier Plans in minutes with present 
                  levels, impact statements, 
                  standards-based goals, instructional accommodations, and 
                  justification/removal statements. Dot It will form your groups 
                  and schedule service times. Also, Dot It keeps all your 
                  documentation in one place so you can progress monitor each 
                  goal and upload authentic student work. 
                  </div>
              <form id="payment-form" className="">

                <div className="pb-2 mt-1">
                  <Row gutter={16}>
                    <Col span={24}>
                  <div className="form-row">
                <label htmlFor="card-element" className="font-18">
                  Credit or debit card:
                </label>
                <div id="card-element" className="background-fff p-2 mt-1 br-4"
                style={{border: "1px solid #b9d7d6"}}>
                </div>

                <div id="card-errors" role="alert"></div>
              </div>
              
                  </Col>
                  <Col span={0}>
                  
                  </Col>
                  </Row>
                </div>
            <div className="w-100 inline-block">
            <button 
              className={"ant-btn btn-cl ant-btn-primary " +
              "font-18 flex flex-v-center flex-h-center " +
              "ant-shadow mt-2 float-right"}
              disabled={this.state.submitting}
            >
              <span className="font-bold">
              {'Buy subscription for $279'}
               </span>
            </button>

              </div>
            </form>
            </div>
            </div>
            }
            </div>
            </Content>
            </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default CreatePaidAccount