import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import Empty from '../customcomponents/Empty'
import '../styles/GlobalStyle.css'
import { ResponsiveBar } from '@nivo/bar'
//import ResizableBox from "../customcomponents/ResizableBox"
import { Row, Col } from 'antd'
import { formatNumberWithCommas } from '../Util'

/*
let ResponsivePie = null
if (typeof window !== "undefined") {
    // browser code
    let ResponsivePieImport = require('@nivo/pie')
    ResponsivePie = ResponsivePieImport.ResponsivePie
}
*/

class DistrictStats extends Component {
    state = {

    }

    // Do fetch here
    componentDidMount() {

    }

    render() {
        var grades = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].reverse()
        var raceOrEthnicities = ["Native American or Alaska Native", "Asian", "Black or African American", "Native Hawaiian or Other Pacific Islander", "White", "Hispanic or Latino"].sort()
        var reTierDataColors = []
        var tiers = []
        var IEPThinkingChallenges = []
        var MTSSThinkingChallenges = []
        var subjects = []
        var studentsByGradeAndTier = []
        var studentsByGradeAndTierKeys = []
        var studentsByGradeAndTierDataColors = []
        var numStudentsByGradeAndTier = 0

        var goalSubjectsByRaceAndEthnicity = []
        var goalSubjectsByRaceAndEthnicityKeys = []

        // unused var complaint, add back later if needed
        //var numGoalsByGrade = 0
        var raceData = []
        var raceKeys = []
        var numRaceData = 0

        console.log(reTierDataColors, goalSubjectsByRaceAndEthnicityKeys, raceKeys, numRaceData)

        let supportData = []
        let studentChallengeData = []
        if (this.props.districtStats) {
            tiers = [
                {
                    "id": "Tier 2",
                    "label": "Tier 2",
                    "value": this.props.districtStats.numStudentsTier2,
                    "color": "rgb(241, 225, 91)"
                },
                {
                    "id": "Tier 3",
                    "label": "Tier 3",
                    "value": this.props.districtStats.numStudentsTier3,
                    "color": "rgb(244, 117, 96)"
                },
                {
                    "id": "Special Education",
                    "label": "Special Education",
                    "value": this.props.districtStats.numStudentsIEP,
                    "color": "rgb(97, 205, 187)"
                },
            ]

            tiers.sort((a, b) => {
                return a.value - b.value
            })


            IEPThinkingChallenges = [
                {
                    "id": "Fluid Reasoning / VSPI",
                    "label": "Fluid Reasoning / VSPI",
                    "value": this.props.districtStats.numStudentsFRI,
                    "color": "#1890ff"
                },
                {
                    "id": "Processing Speed",
                    "label": "Processing Speed",
                    "value": this.props.districtStats.numStudentsPSI,
                    "color": "#52c41a"
                },
                {
                    "id": "Working Memory",
                    "label": "Working Memory",
                    "value": this.props.districtStats.numStudentsWMI,
                    "color": "#722ed1"
                },
                {
                    "id": "Verbal Comprehension",
                    "label": "Verbal Comprehension",
                    "value": this.props.districtStats.numStudentsVCI,
                    "color": "#eb2f96"
                },
            ]

            IEPThinkingChallenges.sort((a, b) => {
                return a.value - b.value
            })

            MTSSThinkingChallenges = [
                {
                    "id": "Information",
                    "label": "Information",
                    "value": this.props.districtStats.numStudentsInformation,
                    "color": "#1890ff"
                },
                {
                    "id": "Vocabulary",
                    "label": "Vocabulary",
                    "value": this.props.districtStats.numStudentsVocabulary,
                    "color": "#52c41a"
                },
                {
                    "id": "Problem Solving",
                    "label": "Problem Solving",
                    "value": this.props.districtStats.numStudentsProblemSolving,
                    "color": "#722ed1"
                },
                {
                    "id": "Efficiency",
                    "label": "Efficiency",
                    "value": this.props.districtStats.numStudentsEfficiency,
                    "color": "#eb2f96"
                },
                {
                    "id": "ELL",
                    "label": "ELL",
                    "value": this.props.districtStats.numStudentsELL,
                    "color": "#fa541c"
                },
            ]

            MTSSThinkingChallenges.sort((a, b) => {
                return a.value - b.value
            })

            subjects = [
                {
                    "id": "Writing",
                    "label": "Writing",
                    "value": this.props.districtStats.numGoalsWR,
                    "color": "#69c0ff"
                },
                {
                    "id": "Reading: Literature",
                    "label": "Reading Comprehension in Literature",
                    "value": this.props.districtStats.numGoalsRL,
                    "color": "#b37feb"
                },
                {
                    "id": "Reading: Informational Text",
                    "label": "Reading Comprehension in Informational Text",
                    "value": this.props.districtStats.numGoalsRI,
                    "color": "#95de64"
                },
                {
                    "id": "Reading: Foundational Skills",
                    "label": "Reading Foundations",
                    "value": this.props.districtStats.numGoalsRF,
                    "color": "#ff85c0"
                },
                {
                    "id": "Math",
                    "label": "Math",
                    "value": this.props.districtStats.numGoalsMath,
                    "color": "#ff9c6e"
                },
                {
                    "id": "Social Emotional Learning",
                    "label": "Social Emotional Learning",
                    "value": this.props.districtStats.numGoalsSEL,
                    "color": "#85a5ff"
                },
            ]

            subjects.sort((a, b) => {
                return a.value - b.value
            })

            studentsByGradeAndTierKeys = ["IEP Goals", "Tier 2 Goals", "Tier 3 Goals"]
            studentsByGradeAndTierDataColors = ["rgb(97, 205, 187)", "rgb(241, 225, 91)", "rgb(244, 117, 96)"]

            grades.reverse().map((grade, idx) => {
                var numStudents = this.props.districtStats["numStudents" + grade]
                var numStudentsIEP = this.props.districtStats["numStudents" + grade + "IEP"]
                var numStudentsTier2 = this.props.districtStats["numStudents" + grade + "Tier2"]
                var numStudentsTier3 = this.props.districtStats["numStudents" + grade + "Tier3"]
                if (numStudents > 0 || numStudentsIEP > 0 || numStudentsTier2 > 0 ||
                    numStudentsTier3 > 0) {
                    numStudentsByGradeAndTier += 1
                }
                var obj = {
                    "grade": grade,
                    "IEP Goals": numStudentsIEP,
                    "IEP GoalsColor": "rgb(97, 205, 187)",
                    "Tier 2 Goals": numStudentsTier2,
                    "Tier 2 GoalsColor": "rgb(241, 225, 91)",
                    "Tier 3 Goals": numStudentsTier3,
                    "Tier 3 GoalsColor": "rgb(244, 117, 96)",
                }
                studentsByGradeAndTier.push(obj)
                return false
            })

            raceKeys = ["IEP Students", "Tier 2 Students", "Tier 3 Students"]
            reTierDataColors = ["rgb(97, 205, 187)", "rgb(241, 225, 91)", "rgb(244, 117, 96)"]

            raceOrEthnicities.map((race) => {
                var numGoals = this.props.districtStats["numStudents" + race]
                var numGoalsIEP = this.props.districtStats["numStudents" + race + "IEP"]
                var numGoalsTier2 = this.props.districtStats["numStudents" + race + "Tier2"]
                var numGoalsTier3 = this.props.districtStats["numStudents" + race + "Tier3"]
                if (numGoals > 0 || numGoalsIEP > 0 || numGoalsTier2 > 0 ||
                    numGoalsTier3 > 0) {
                    numRaceData += 1
                }
                var obj = {
                    "race and ethnicity": race,
                    "IEP Students": numGoalsIEP,
                    "IEP StudentsColor": "rgb(97, 205, 187)",
                    "Tier 2 Students": numGoalsTier2,
                    "Tier 2 StudentsColor": "rgb(241, 225, 91)",
                    "Tier 3 Students": numGoalsTier3,
                    "Tier 3 StudentsColor": "rgb(244, 117, 96)",
                }
                raceData.push(obj)
                return false
            })

            goalSubjectsByRaceAndEthnicityKeys = ['Writing', 'Reading: Literature',
                'Reading: Informational Text', 'Reading: Foundational Skills', 'Math', 'Social Emotional Learning']

            raceOrEthnicities.map((race) => {
                var numGoals = this.props.districtStats["numStudents" + race]
                var numGoalsWR = this.props.districtStats["numGoalsWR" + race]
                var numGoalsRL = this.props.districtStats["numGoalsRL" + race]
                var numGoalsRI = this.props.districtStats["numGoalsRI" + race]
                var numGoalsRF = this.props.districtStats["numGoalsRF" + race]
                var numGoalsMath = this.props.districtStats["numGoalsMath" + race]
                var numGoalsSEL = this.props.districtStats["numGoalsSEL" + race]

                if (numGoals > 0 || numGoalsWR > 0 || numGoalsRL > 0 ||
                    numGoalsRI > 0 || numGoalsRF > 0 || numGoalsMath > 0 ||
                    numGoalsSEL > 0) {
                    numRaceData += 1
                }
                var obj = {
                    "id": race,
                    "race and ethnicity": race,
                    "Writing": numGoalsWR,
                    "Reading: Literature": numGoalsRL,
                    "Reading: Informational Text": numGoalsRI,
                    "Reading: Foundational Skills": numGoalsRF,
                    "Math": numGoalsMath,
                    "Social Emotional Learning": numGoalsSEL,
                    "WRColor": "#87e8de",
                    "RLColor": "#87e8de",
                    "RIColor": "#87e8de",
                    "RFColor": "#87e8de",
                    "MathColor": "#87e8de",
                    "SELColor": "#87e8de",
                }
                goalSubjectsByRaceAndEthnicity.push(obj)
                return false
            })

            // only show non-zero data
            if (this.props.districtStats.numGoalsIEPLowSupport > 0) {
                supportData.push({
                    "id": "IEP Low Support",
                    "label": "IEP Low Support",
                    "value": this.props.districtStats.numGoalsIEPLowSupport,
                    "color": "rgb(151, 227, 213)"
                })
            }
            if (this.props.districtStats.numGoalsIEPModerateSupport > 0) {
                supportData.push({
                    "id": "IEP Moderate Support",
                    "label": "IEP Moderate Support",
                    "value": this.props.districtStats.numGoalsIEPModerateSupport,
                    "color": "rgb(232, 168, 56)"
                })
            }
            if (this.props.districtStats.numGoalsIEPHighSupport > 0) {
                supportData.push({
                    "id": "IEP High Support",
                    "label": "IEP High Support",
                    "value": this.props.districtStats.numGoalsIEPHighSupport,
                    "color": "rgb(232, 193, 160)"
                })
            }
            if (this.props.districtStats.numGoalsTier2 > 0) {
                supportData.push({
                    "id": "Tier 2",
                    "label": "Tier 2",
                    "value": this.props.districtStats.numGoalsTier2,
                    "color": "rgb(241, 225, 91)"
                })
            }
            if (this.props.districtStats.numGoalsTier3 > 0) {
                supportData.push({
                    "id": "Tier 3",
                    "label": "Tier 3",
                    "value": this.props.districtStats.numGoalsTier3,
                    "color": "rgb(244, 117, 96)"
                })
            }

            // only show non-zero data
            if (this.props.districtStats.numGoalsWMI > 0) {
                studentChallengeData.push(
                    {
                        "id": "Working Memory",
                        "label": "Working Memory",
                        "value": this.props.districtStats.numGoalsWMI,
                        "color": "hsl(254, 70%, 50%)"
                    }
                )
            }
            if (this.props.districtStats.numGoalsPSI > 0) {
                studentChallengeData.push(
                    {
                        "id": "Processing Speed",
                        "label": "Processing Speed",
                        "value": this.props.districtStats.numGoalsPSI,
                        "color": "hsl(100, 70%, 50%)"
                    }
                )
            }
            if (this.props.districtStats.numGoalsFRI > 0) {
                studentChallengeData.push(
                    {
                        "id": "Fluid Reasoning",
                        "label": "Fluid Reasoning",
                        "value": this.props.districtStats.numGoalsFRI,
                        "color": "hsl(244, 70%, 50%)"
                    }
                )
            }
            if (this.props.districtStats.numGoalsVSI > 0) {
                studentChallengeData.push(
                    {
                        "id": "Visual Spatial Processing",
                        "label": "Visual Spatial Processing",
                        "value": this.props.districtStats.numGoalsVSI,
                        "color": "hsl(108, 70%, 50%)"
                    }
                )
            }
            if (this.props.districtStats.numGoalsVCI > 0) {
                studentChallengeData.push(
                    {
                        "id": "Verbal Comprehension",
                        "label": "Verbal Comprehension",
                        "value": this.props.districtStats.numGoalsVCI,
                        "color": "hsl(61, 70%, 50%)"
                    }
                )
            }
        }

        return (
            <div>
                {this.props.districtStats ?
                    <div>
                        <Row gutter={32} type="flex">

                            <Col span={8}>
                                <div className="br-20 mb-4 p-3 br-4 background-color-light-cyan">
                                    <div className="text-center br-20 ant-shadow mb-2 p-2 background-fff">
                                        <img src="/backdrops/icons/10.png" alt="icon" />
                                    </div>
                                    <h1 className="text-center mb-0 font-60">
                                        <div>{formatNumberWithCommas(this.props.districtStats.numStudents)}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Students
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-20 mb-4 p-3 br-4 background-color-light-cyan">
                                    <div className="text-center br-20 ant-shadow mb-2 p-2 background-fff">
                                        <img src="/backdrops/icons/12.png" alt="icon" />
                                    </div>
                                    <h1 className="text-center mb-0 font-60">
                                        <div>{formatNumberWithCommas(this.props.districtStats.numGoals)}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Goals
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-20 mb-4 p-3 br-4 background-color-light-cyan">
                                    <div className="text-center br-20 ant-shadow mb-2 p-2 background-fff">
                                        <img src="/backdrops/icons/11.png" alt="icon" />
                                    </div>
                                    <h1 className="text-center mb-0 font-60">
                                        <div>{formatNumberWithCommas(this.props.districtStats.numTeachers)}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Teachers
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>
                            {/* 
                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numTeachers}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Teachers
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">

                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numStudents}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Students
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Goals
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Scheduled services
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Progress monitoring
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Descriptions of instruction
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Uploaded files
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Team messages
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="br-4 background-fff mb-4 p-3 br-4 ant-shadow-more">
                                    <h1 className="text-center mb-0 font-50">
                                        <div>{this.props.districtStats.numGoals}</div>
                                    </h1>
                                    <h2 className="font-bold text-center font-20">
                                        Team goals shared
                                    </h2>
                                    <div>
                                    </div>
                                </div>
                            </Col>
                            */}

                            <Col span={24} className="mb-2">
                                <div className="br-20 overflow-hidden mb-2 p-3" style={{ background: '#e6fffb' }}>
                                    <h2 className="font-bold pt-1 pb-1 font-30">
                                        Students by tier
                                    </h2>
                                    <div className="p-3 br-20 background-fff ant-shadow">
                                        {this.props.districtStats.numStudentsIEP +
                                            this.props.districtStats.numStudentsTier2 +
                                            this.props.districtStats.numStudentsTier3 === 0 ?
                                            <Empty
                                                containerClassName="mt-4 pt-2 text-center"
                                                width={55}
                                                height={55}
                                            />
                                            :
                                            <div style={{ height: '400px' }}>


                                                <ResponsiveBar
                                                    data={tiers}
                                                    // keys={data.map(i => i.id)}
                                                    indexBy="id"
                                                    margin={{ top: 0, right: 32, bottom: 130, left: 188 }}
                                                    padding={0.15}
                                                    groupMode="grouped"
                                                    layout="horizontal"
                                                    colors={tiers.map(c => c.color)}
                                                    colorBy="index"
                                                    theme={{
                                                        fontSize: 20,
                                                        fontColor: "#fff",
                                                        axis: {
                                                            legend: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[

                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['brighter', '1.6']] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        tickValues: 5,
                                                        legend: 'Students',
                                                        legendPosition: 'middle',
                                                        legendOffset: 50,
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: -40
                                                    }}
                                                    enableGridX={true}
                                                    enableGridY={false}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[]}
                                                    animate={false}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>

                            <Col span={24} className="mb-2">
                                <div className="br-20 overflow-hidden mb-2 p-3" style={{ background: '#fffbe6' }}>
                                    <h2 className="font-bold pt-1 pb-1 font-30">
                                        Students by race and ethnicity, tier
                                    </h2>
                                    <div className="p-3 br-20 background-fff ant-shadow">
                                        {numRaceData === 0 ?
                                            <Empty
                                                containerClassName="mt-4 pt-2 mb-4 pb-2 text-center"
                                                width={55}
                                                height={55}
                                            />
                                            :
                                            <div style={{ height: '550px' }}>
                                                <div>
                                                    <div className="font-20 mb-3">
                                                        {(this.props.districtStats.numStudentsIEP +
                                                            this.props.districtStats.numStudentsTier2 +
                                                            this.props.districtStats.numStudentsTier3) +
                                                            ' / ' + this.props.districtStats.numStudents +
                                                            ' students currently have goals.'}
                                                    </div>
                                                </div>
                                                <ResponsiveBar
                                                    animate={false}
                                                    data={raceData}
                                                    keys={raceKeys}
                                                    indexBy="race and ethnicity"
                                                    margin={{ top: 10, right: 210, bottom: 360, left: 100 }}
                                                    padding={0.3}
                                                    valueScale={{ type: 'linear' }}
                                                    indexScale={{ type: 'band', round: true }}
                                                    colors={reTierDataColors.map(c => {
                                                        return c
                                                    })}
                                                    colorBy={c => {
                                                        return c.id + "Color"
                                                    }}
                                                    groupMode="grouped"
                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        orient: 'bottom',
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 50,
                                                        legend: '',
                                                        legendOffset: 155,
                                                        legendPosition: 'middle'
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        tickValues: 5,
                                                        legend: 'Students',
                                                        legendPosition: 'middle',
                                                        legendOffset: -68
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[
                                                        {
                                                            dataFrom: 'keys',
                                                            anchor: 'bottom-right',
                                                            direction: 'column',
                                                            justify: false,
                                                            translateX: 120,
                                                            translateY: 0,
                                                            itemsSpacing: 2,
                                                            itemWidth: 100,
                                                            itemHeight: 20,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 20,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                    theme={{
                                                        fontSize: 20,
                                                        axis: {
                                                            legend: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            },
                                                            ticks: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>

                            <Col span={24} className="mb-2">
                                <div className="br-20 overflow-hidden mb-2 p-3" style={{ background: '#e6fffb' }}>
                                    <h2 className="font-bold pt-1 pb-1 font-30">
                                        Goals by content area
                                    </h2>
                                    <div className="p-3 br-20 background-fff ant-shadow">
                                        <div style={{ height: '250px' }}>
                                            {this.props.districtStats.numGoalsWR +
                                                this.props.districtStats.numGoalsRL +
                                                this.props.districtStats.numGoalsRI +
                                                this.props.districtStats.numGoalsRF +
                                                this.props.districtStats.numGoalsMath +
                                                this.props.districtStats.numGoalsSEL === 0 ?
                                                <Empty
                                                    containerClassName="mt-4 pt-2 text-center"
                                                    width={55}
                                                    height={55}
                                                />
                                                :
                                                <ResponsiveBar
                                                    data={subjects}
                                                    // keys={data.map(i => i.id)}
                                                    indexBy="id"
                                                    margin={{ top: 0, right: 32, bottom: 80, left: 280 }}
                                                    padding={0.15}
                                                    groupMode="grouped"
                                                    layout="horizontal"
                                                    colors={{ scheme: 'nivo' }}
                                                    colorBy="index"
                                                    theme={{
                                                        fontSize: 20,
                                                        axis: {
                                                            legend: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: '#38bcb2',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: '#eed312',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[

                                                    ]}
                                                    borderColor={{ from: 'color', modifiers: [['brighter', '1.6']] }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        tickValues: 5,
                                                        legend: 'Goals',
                                                        legendPosition: 'middle',
                                                        legendOffset: 50,
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: -40
                                                    }}
                                                    enableGridX={true}
                                                    enableGridY={false}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[]}
                                                    animate={false}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24} className="mb-2">
                                <div className="br-20 overflow-hidden mb-2 p-3" style={{ background: '#fffbe6' }}>
                                    <h2 className="font-bold pt-1 pb-1 font-30">
                                        Goal content areas by race and ethnicity
                                    </h2>
                                    <div className="p-3 br-20 background-fff ant-shadow">
                                        <div style={{ height: '640px' }}>
                                            {this.props.districtStats.numGoalsWR +
                                                this.props.districtStats.numGoalsRL +
                                                this.props.districtStats.numGoalsRI +
                                                this.props.districtStats.numGoalsRF +
                                                this.props.districtStats.numGoalsMath +
                                                this.props.districtStats.numGoalsSEL === 0 ?
                                                <Empty
                                                    containerClassName="mt-4 pt-2 text-center"
                                                    width={55}
                                                    height={55}
                                                />
                                                :
                                                <ResponsiveBar
                                                    data={goalSubjectsByRaceAndEthnicity}
                                                    keys={goalSubjectsByRaceAndEthnicityKeys}
                                                    indexBy="id"
                                                    margin={{ top: 10, right: 260, bottom: 310, left: 80 }}
                                                    padding={0.15}
                                                    groupMode="grouped"
                                                    layout="vertical"
                                                    colors={{ scheme: 'nivo' }}
                                                    axisBottom={{
                                                        orient: 'bottom',
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 50,
                                                        legend: '',
                                                        legendOffset: 155,
                                                        legendPosition: 'middle'
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        tickValues: 5,
                                                        legend: 'Goals',
                                                        legendPosition: 'middle',
                                                        legendOffset: -68
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[
                                                        {
                                                            fontSize: 30,
                                                            dataFrom: 'keys',
                                                            anchor: 'bottom-right',
                                                            direction: 'column',
                                                            justify: false,
                                                            translateX: 120,
                                                            translateY: 0,
                                                            itemsSpacing: 2,
                                                            itemWidth: 100,
                                                            itemHeight: 20,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 20,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                    animate={false}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                    theme={{
                                                        fontSize: 0,
                                                        axis: {
                                                            legend: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            },
                                                            ticks: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            }
                                                        },
                                                        legends: {
                                                            text: {
                                                                fontSize: 16,
                                                            }
                                                        },
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            {/*
                            <Col span={24}>
                                <div>
                                    <div className="font-30 font-bold mb-4 mt-4">
                                        IEPs and tier plans by support level
                                    </div>
                                    {!ResponsivePie || this.props.districtStats.numGoals === 0 ?
                                        <Empty
                                            containerClassName="mt-4 pt-2 text-center"
                                            width={55}
                                            height={55}
                                        />
                                        :
                                        <div style={{ height: '600px' }}>
                                            <ResponsivePie
                                                data={supportData}
                                                theme={{
                                                    fontSize: 20,
                                                    axis: {
                                                        legend: {
                                                            text: {
                                                                fontSize: 20,
                                                            }
                                                        }
                                                    }
                                                }}
                                                colors={supportData.map(c => c.color)}
                                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                innerRadius={0.5}
                                                padAngle={0.7}
                                                cornerRadius={3}
                                                activeOuterRadiusOffset={8}
                                                borderWidth={1}
                                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                arcLinkLabelsSkipAngle={10}
                                                arcLinkLabelsTextColor="#333333"
                                                arcLinkLabelsThickness={2}
                                                arcLinkLabelsColor={{ from: 'color' }}
                                                arcLabelsSkipAngle={10}
                                                arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                                defs={[
                                                    {
                                                        id: 'dots',
                                                        type: 'patternDots',
                                                        background: 'inherit',
                                                        color: 'rgba(255, 255, 255, 0.3)',
                                                        size: 4,
                                                        padding: 1,
                                                        stagger: true
                                                    },
                                                    {
                                                        id: 'lines',
                                                        type: 'patternLines',
                                                        background: 'inherit',
                                                        color: 'rgba(255, 255, 255, 0.3)',
                                                        rotation: -45,
                                                        lineWidth: 6,
                                                        spacing: 10
                                                    }
                                                ]}
                                                fill={[
                                                    {
                                                        match: {
                                                            id: 'ruby'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'c'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'go'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'python'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'scala'
                                                        },
                                                        id: 'lines'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'lisp'
                                                        },
                                                        id: 'lines'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'elixir'
                                                        },
                                                        id: 'lines'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'javascript'
                                                        },
                                                        id: 'lines'
                                                    }
                                                ]}

                                            /></div>}
                                </div>
                            </Col>*/}


                            {/*
                            <Col span={24}>
                                <div className="br-20 overflow-hidden mb-2 p-3" style={{ background: '#fffbe6' }}>
                                    <h2 className="font-bold pt-1 pb-1 font-30">
                                        Goals by student challenge
                                    </h2>
                                    <div className="p-3 br-20 background-fff ant-shadow">

                                        {!ResponsivePie || this.props.districtStats.numGoals === 0 ?
                                            <Empty
                                                containerClassName="mt-4 pt-2 text-center"
                                                width={55}
                                                height={55}
                                            />
                                            :
                                            <div style={{ height: '400px' }}>
                                                <ResponsivePie
                                                    data={studentChallengeData}
                                                    theme={{
                                                        fontSize: 20,
                                                        axis: {
                                                            legend: {
                                                                text: {
                                                                    fontSize: 20,
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                    innerRadius={0.75}
                                                    padAngle={0.7}
                                                    cornerRadius={3}
                                                    activeOuterRadiusOffset={8}
                                                    borderWidth={1}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                    arcLinkLabelsSkipAngle={10}
                                                    arcLinkLabelsTextColor="#333333"
                                                    arcLinkLabelsThickness={2}
                                                    arcLinkLabelsColor={{ from: 'color' }}
                                                    arcLabelsSkipAngle={10}
                                                    arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background: 'inherit',
                                                            color: 'rgba(255, 255, 255, 0.3)',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background: 'inherit',
                                                            color: 'rgba(255, 255, 255, 0.3)',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10
                                                        }
                                                    ]}
                                                    fill={[
                                                        {
                                                            match: {
                                                                id: 'ruby'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'c'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'go'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'python'
                                                            },
                                                            id: 'dots'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'scala'
                                                            },
                                                            id: 'lines'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'lisp'
                                                            },
                                                            id: 'lines'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'elixir'
                                                            },
                                                            id: 'lines'
                                                        },
                                                        {
                                                            match: {
                                                                id: 'javascript'
                                                            },
                                                            id: 'lines'
                                                        }
                                                    ]}

                                                /></div>}
                                    </div>
                                </div>
                            </Col>
                            */}

                            <Col span={24} className="mb-2">
                                <div className="br-20 overflow-hidden mb-2 p-3" style={{ background: '#e6fffb' }}>
                                    <h2 className="font-bold pt-1 pb-1 font-30">
                                        Students by grade and tier
                                    </h2>
                                    <div className="p-3 br-20 background-fff ant-shadow">
                                        <div>
                                            {numStudentsByGradeAndTier === 0 ?
                                                <Empty
                                                    containerClassName="mt-4 pt-2 mb-4 pb-2 text-center"
                                                    width={55}
                                                    height={55}
                                                />
                                                :
                                                <div style={{ height: '400px' }}>

                                                    <ResponsiveBar
                                                        data={studentsByGradeAndTier}
                                                        keys={studentsByGradeAndTierKeys}
                                                        indexBy="grade"
                                                        margin={{ top: 10, right: 180, bottom: 130, left: 100 }}
                                                        padding={0.3}
                                                        valueScale={{ type: 'linear' }}
                                                        indexScale={{ type: 'band', round: true }}
                                                        colors={studentsByGradeAndTierDataColors.map(c => {
                                                            return c
                                                        })}
                                                        colorBy={c => {
                                                            return c.id + "Color"
                                                        }}
                                                        groupMode="grouped"
                                                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                        axisTop={null}
                                                        axisRight={null}
                                                        axisBottom={{
                                                            orient: 'bottom',
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: 'Grade',
                                                            legendPosition: 'middle',
                                                            legendOffset: 50,
                                                        }}
                                                        axisLeft={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            tickValues: 5,
                                                            legend: 'Students',
                                                            legendPosition: 'middle',
                                                            legendOffset: -68
                                                        }}
                                                        labelSkipWidth={12}
                                                        labelSkipHeight={12}
                                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                        legends={[
                                                            {
                                                                dataFrom: 'keys',
                                                                anchor: 'bottom-right',
                                                                direction: 'column',
                                                                justify: false,
                                                                translateX: 120,
                                                                translateY: 0,
                                                                itemsSpacing: 2,
                                                                itemWidth: 100,
                                                                itemHeight: 20,
                                                                itemDirection: 'left-to-right',
                                                                itemOpacity: 0.85,
                                                                symbolSize: 20,
                                                                effects: [
                                                                    {
                                                                        on: 'hover',
                                                                        style: {
                                                                            itemOpacity: 1
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        animate={false}
                                                        motionStiffness={90}
                                                        motionDamping={15}
                                                        theme={{
                                                            fontSize: 20,
                                                            axis: {
                                                                legend: {
                                                                    text: {
                                                                        fontSize: 20,
                                                                    }
                                                                },
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>




                        </Row>
                    </div>
                    :
                    <Empty
                        containerClassName="mt-4 pt-2 text-center"
                        width={55}
                        height={55}
                    />}
            </div>
        )
    }
}

export default DistrictStats