import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc, linkAfterLogin } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Input, Layout, Button, Icon, Switch, Select } from 'antd'
//import { Document } from '../teacher/edvizv2/Edviz'
const Sider = Layout.Sider
const Content = Layout.Content

const TEST_DISTRICT_ID = '64bec570267c8bfeea81f9ea'

class SuperAdminDistrictSettings extends Component {
    state = {
        districtId: '5410a3828725bd2f0d00100d',
        district: null,
        districtSettings: {
            commonAssessmentsLive: {},
            benchmarksLive: {},
            canReview: {},
            canActivelyReview: {},
        },
        endWeeks: [2, 3, 6, 9, 12, 15, 18, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
        districtSettingsListener: null,
        districtSettingsDocExists: false,
        superAdminDistrictSettings: {
            canRegen: {},
        },
        superAdminDistrictSettingsListener: null,
        superAdminDistrictSettingsDocExists: false,
        cleverId: '',
        additionalCode: '',
    }

    componentDidMount() {

    }

    getDistrictSettings = () => {
        if (!this.state.districtId) {
            return
        }

        db.collection(ColType.district)
            .doc(this.state.districtId)
            .get()
            .then((doc) => {
                let district = flattenDoc(doc)
                this.setState({ district: district })
            })

        console.log('districtId', this.state.districtId)

        if (this.state.districtSettingsListener) {
            this.state.districtSettingsListener()
        }
        let districtSettingsListener = db.collection(ColType.districtSettings)
            .doc(this.state.districtId)
            .onSnapshot({ includeMetadataChanges: true }, (doc) => {
                // if from cache return, only want server updates
                if (doc.metadata.fromCache) {
                    return
                }
                if (doc.exists) {
                    let districtSettings = flattenDoc(doc)
                    // add missing keys to districtSettings
                    Object.keys(this.state.districtSettings).forEach((key) => {
                        if (!districtSettings.hasOwnProperty(key)) {
                            districtSettings[key] = {}
                        }
                    })
                    console.log('districtSettings listener', districtSettings)
                    this.setState({
                        districtSettings: districtSettings,
                    })
                } else {
                    console.log('no district settings, creating initial document')
                    db.collection(ColType.districtSettings)
                        .doc(this.state.districtId)
                        .set(this.state.districtSettings, { merge: true })
                        .then(() => {
                            console.log('Initial Document successfully updated!')
                        })
                        .catch((error) => {
                            console.error('Initial Error updating document: ', error)
                        })
                }
            })

        this.setState({
            districtSettingsListener: districtSettingsListener,
        })

        if (this.state.superAdminDistrictSettingsListener) {
            this.state.superAdminDistrictSettingsListener()
        }
        let superAdminDistrictSettingsListener = db.collection(ColType.superAdminDistrictSettings)
            .doc(this.state.districtId)
            .onSnapshot({ includeMetadataChanges: true }, (doc) => {
                // if from cache return, only want server updates
                if (doc.metadata.fromCache) {
                    return
                }
                if (doc.exists) {
                    let superAdminDistrictSettings = flattenDoc(doc)
                    // add missing keys to districtSettings
                    Object.keys(this.state.superAdminDistrictSettings).forEach((key) => {
                        if (!superAdminDistrictSettings.hasOwnProperty(key)) {
                            superAdminDistrictSettings[key] = {}
                        }
                    })
                    console.log('superAdminDistrictSettings listener', superAdminDistrictSettings)
                    this.setState({
                        superAdminDistrictSettings: superAdminDistrictSettings,
                    })
                } else {
                    console.log('no superAdminDistrictSettings, creating initial document')
                    db.collection(ColType.superAdminDistrictSettings)
                        .doc(this.state.districtId)
                        .set(this.state.superAdminDistrictSettings, { merge: true })
                        .then(() => {
                            console.log('Initial Document successfully updated!')
                        })
                        .catch((error) => {
                            console.error('Initial Error updating document: ', error)
                        })
                }
            })
        this.setState({
            superAdminDistrictSettingsListener: superAdminDistrictSettingsListener,
        })
    }

    componentWillUnmount() {
        if (this.state.districtSettingsListener) {
            this.state.districtSettingsListener()
        }
        if (this.state.superAdminDistrictSettingsListener) {
            this.state.superAdminDistrictSettingsListener()
        }
    }

    districtSettingsCommonAssessmentLiveChange = (endWeek, checked) => {
        if (!this.state.district) {
            return
        }

        let districtSettings = this.state.districtSettings
        if (checked) {
            districtSettings.commonAssessmentsLive[endWeek] = checked
        } else {
            delete districtSettings.commonAssessmentsLive[endWeek]
        }

        console.log(endWeek, checked, districtSettings)

        db.collection(ColType.districtSettings)
            .doc(this.state.districtId)
            .update(districtSettings)
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
            })
    }

    districtSettingsCanReviewChange = (endWeek, checked) => {
        if (!this.state.district) {
            return
        }

        let districtSettings = this.state.districtSettings
        if (!districtSettings.hasOwnProperty('canReview')) {
            districtSettings.canReview = {}
        }
        if (checked) {
            districtSettings.canReview[endWeek] = checked
        } else {
            delete districtSettings.canReview[endWeek]
        }

        console.log(endWeek, checked, districtSettings)

        db.collection(ColType.districtSettings)
            .doc(this.state.districtId)
            .update(districtSettings)
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
            })
    }

    districtSettingsCanActivelyReviewChange = (endWeek, checked) => {
        if (!this.state.district) {
            return
        }

        let districtSettings = this.state.districtSettings
        if (!districtSettings.hasOwnProperty('canActivelyReview')) {
            districtSettings.canActivelyReview = {}
        }
        if (checked) {
            districtSettings.canActivelyReview[endWeek] = checked
        } else {
            delete districtSettings.canActivelyReview[endWeek]
        }

        console.log(endWeek, checked, districtSettings)

        db.collection(ColType.districtSettings)
            .doc(this.state.districtId)
            .update(districtSettings)
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
            })
    }

    setPacingGuideYears = (values) => {
        if (!this.state.district) {
            return
        }
        console.log('values', values);

        let districtSettings = this.state.districtSettings
        districtSettings.pacingGuideYears = values

        db.collection(ColType.districtSettings)
            .doc(this.state.districtId)
            .update(districtSettings)
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
            })
    }

    onEditPacingGuideCheckboxChange = (checked) => {
        if (!this.state.district) {
            return
        }

        let districtSettings = this.state.districtSettings
        districtSettings.teachersCanEditPacingGuide = checked

        db.collection(ColType.districtSettings)
            .doc(this.state.districtId)
            .update(districtSettings)
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
            })
    }

    superAdminCanRegenChange = (endWeek, checked) => {
        if (!this.state.district) {
            return
        }
        let superAdminDistrictSettings = this.state.superAdminDistrictSettings
        if (!superAdminDistrictSettings.hasOwnProperty('canRegen')) {
            superAdminDistrictSettings.canRegen = {}
        }
        if (checked) {
            superAdminDistrictSettings.canRegen[endWeek] = checked
        }
        else {
            delete superAdminDistrictSettings.canRegen[endWeek]
        }
        console.log(endWeek, checked, superAdminDistrictSettings)
        db.collection(ColType.superAdminDistrictSettings)
            .doc(this.state.districtId)
            .update(superAdminDistrictSettings)
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
            })
    }


    signInUsingCleverId = () => {
        if (!this.state.cleverId) {
            return
        }

        console.log('cleverId', this.state.cleverId)

        db.collection(ColType.users)
            .doc(this.state.cleverId)
            .get()
            .then((doc) => {
                var userInfo = flattenDoc(doc)
                if (userInfo.accessLevel === 'superAdmin') {
                    window.location.href = '/super-admin/add-initial-admin'
                    return
                }
                else {
                    db.collection(userInfo.accessLevel)
                        .doc(userInfo.refId)
                        .get()
                        .then((doc) => {
                            var userJobInfo = flattenDoc(doc)
                            linkAfterLogin(userInfo, userJobInfo)
                        })
                }
            }).catch((error) => {
                console.log('get user info error', error)
                this.setState({
                    submitting: false,
                })
            })
        /*
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            let headers = new Headers()
            headers.append('Authorization', 'Bearer ' + idToken)
            headers.append('Origin', 'https://my.dotit.app')
            headers.append('Content-Type', 'application/json')
            headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

            fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/clever-create-token-from-id-v2", {
                method: "POST",
                body: JSON.stringify({
                    cleverId: this.state.cleverId,
                    additionalCode: this.state.additionalCode,
                }),
                mode: 'cors',
                headers: headers,
            })
                .then(res => res.json())
                .then(data => {
                    console.log('data', data)
                    firebase.auth().signInWithCustomToken(data.token)
                        .then((userObj) => {
                            const user = userObj.user
                            console.log('user sign in', user)
                            db.collection(ColType.users)
                                .doc(user.uid)
                                .get()
                                .then((doc) => {
                                    var userInfo = flattenDoc(doc)
                                    console.log('userInfo', userInfo)
                                    if (userInfo.accessLevel === 'superAdmin') {
                                        window.location.href = '/super-admin/add-initial-admin'
                                        return
                                    }
                                    else {
                                        db.collection(userInfo.accessLevel)
                                            .doc(userInfo.refId)
                                            .get()
                                            .then((doc) => {
                                                var userJobInfo = flattenDoc(doc)
                                                if (userInfo.accessLevel === "teachers" && this.state.cleverState) {
                                                    // pass cleverState to add-students-from-clever, this means the clever sign
                                                    // in was initiated from add-students-from-clever
                                                    window.location.href = this.state.cleverState
                                                } else {
                                                    linkAfterLogin(userInfo, userJobInfo)
                                                }
                                            })
                                    }
                                }).catch((error) => {
                                    console.log('get user info error', error)
                                    this.setState({
                                        submitting: false,
                                    })
                                })
                        })
                        .catch((error) => {
                            console.log('student sign in error', error)
                        })
                })
                .catch(error => {
                    console.log('error', error)
                })
        })
        */
    }

    render() {
        return (
            <div>
                <SuperAdminHeader menuKey={'district-settings'} />
                <div>
                    <Sider
                        width={270}
                        style={{
                            overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 64px)',
                            position: 'fixed',
                            left: 0, borderRight: '1px solid #f1f1f1',
                            background: '#fff',
                        }}>
                        <div style={{ marginTop: 64 }}>
                            <div className='flex flex-v-center pl-2 pr-2'>
                                <Input placeholder='District Clever Id' size='large'
                                    value={this.state.districtId}
                                    onChange={(e) => {
                                        this.setState({ districtId: e.target.value })
                                    }}>
                                </Input>
                                <Button size='large' type='primary'
                                    className='br-50 ml-05 flex flex-v-center flex-h-center'
                                    style={{ width: 40, height: 40 }}
                                    onClick={(e) => {
                                        this.getDistrictSettings()
                                    }}
                                >
                                    <Icon type='search' />
                                </Button>
                            </div>
                            {this.state.district ?
                                <div className='mt-2 pl-2 pr-2 font-16'>
                                    <div className='font-bold'>
                                        {this.state.district.district}</div>
                                    <div className='mb-05'>District Settings</div>
                                </div> : ''}
                        </div>
                    </Sider>
                    <Layout className="content" style={{ marginLeft: 270, marginTop: "32px", overflowX: 'initial' }}>
                        <Content
                            className={"layout-content background-fff p-3 pt-0 br-4 print-remove-padding print-remove-margin"}
                        >
                            <div className='w-1024 m-lr-auto mt-4'>
                                <div className='p-3 ant-shadow br-4 mb-4'>
                                    <div className='font-20 mb-2 font-bold'>Impersonate Clever User</div>
                                    <Input
                                        placeholder='cleverId...'
                                        value={this.state.cleverId}
                                        size='large'
                                        onChange={(e) => {
                                            this.setState({ cleverId: e.target.value })
                                        }} />
                                    <Input placeholder='additional code...' size='large'
                                        className='mt-2'
                                        value={this.state.additionalCode}
                                        onChange={(e) => {
                                            this.setState({ additionalCode: e.target.value })
                                        }}>
                                    </Input>
                                    <div className='flex w-100 mt-2'>
                                        <Button type='primary' size='large'
                                            className='ml-auto'
                                            onClick={(e) => {
                                                this.signInUsingCleverId()
                                            }}>Sign in</Button>
                                    </div>
                                </div>
                                {!this.state.district ?
                                    <div className='font-20'>No district selected</div> :

                                    <div>
                                        <div>
                                            <div className='font-24 font-bold mt-4'>District Pacing Guide Years Settings</div>
                                            <Select size='large' style={{ width: '100%' }}
                                                className='mb-4 pr-4'
                                                mode='multiple'
                                                value={this.state.districtSettings.pacingGuideYears ?
                                                    this.state.districtSettings.pacingGuideYears : []
                                                }
                                                onChange={(e) => {
                                                    this.setPacingGuideYears(e)
                                                }}>
                                                <Select.Option value='2023'>2023-2024</Select.Option>
                                                <Select.Option value='2024'>2024-2025</Select.Option>
                                                <Select.Option value='2025'>2025-2026</Select.Option>
                                                <Select.Option value='2026'>2026-2027</Select.Option>
                                                <Select.Option value='2027'>2027-2028</Select.Option>
                                                <Select.Option value='2028'>2028-2029</Select.Option>
                                                <Select.Option value='2029'>2029-2030</Select.Option>
                                                <Select.Option value='2030'>2030-2031</Select.Option>
                                            </Select>
                                        </div>

                                        <div className='mb-50'>
                                            <div className='font-24 font-bold mt-4'>Teachers can edit pacing guide</div>
                                            <Switch size={'large'}
                                                checked={this.state.districtSettings.teachersCanEditPacingGuide}
                                                onChange={(checked) => {
                                                    this.onEditPacingGuideCheckboxChange(checked)
                                                }}
                                            >
                                                <span className="pl-1">Teachers Can Edit Pacing Guide</span>
                                            </Switch>
                                        </div>

                                        <div className='flex w-100'>
                                            <div className='w-50'>
                                                <div className='font-24 font-bold'>
                                                    District Settings
                                                </div>

                                                <div className='font-24 font-bold'>Common Assessments</div>
                                                {this.state.endWeeks.map((endWeek, idx) => {
                                                    return <div className='flex flex-v-center w-100'>
                                                        <div className='font-bold font-20 mr-2'>Week {endWeek} Live:</div>
                                                        <Switch
                                                            size='large'
                                                            onChange={(checked) => {
                                                                this.districtSettingsCommonAssessmentLiveChange(endWeek, checked)
                                                            }}
                                                            checked={this.state.districtSettings.commonAssessmentsLive.hasOwnProperty(endWeek) &&
                                                                this.state.districtSettings.commonAssessmentsLive[endWeek]} />
                                                    </div>
                                                })}

                                                <div className='font-24 font-bold mt-4'>Can View</div>
                                                {this.state.endWeeks.map((endWeek, idx) => {
                                                    return <div className='flex flex-v-center w-100'>
                                                        <div className='font-bold font-20 mr-2'>Week {endWeek} Live:</div>
                                                        <Switch
                                                            size='large'
                                                            onChange={(checked) => {
                                                                this.districtSettingsCanReviewChange(endWeek, checked)
                                                            }}
                                                            checked={this.state.districtSettings.canReview.hasOwnProperty(endWeek) &&
                                                                this.state.districtSettings.canReview[endWeek]} />
                                                    </div>
                                                })}

                                                <div className='font-24 font-bold mt-4'>Can Actively Review Questions with Comments</div>
                                                {this.state.endWeeks.map((endWeek, idx) => {
                                                    return <div className='flex flex-v-center w-100'>
                                                        <div className='font-bold font-20 mr-2'>Week {endWeek} Live:</div>
                                                        <Switch
                                                            size='large'
                                                            onChange={(checked) => {
                                                                this.districtSettingsCanActivelyReviewChange(endWeek, checked)
                                                            }}
                                                            checked={this.state.districtSettings.canActivelyReview.hasOwnProperty(endWeek) &&
                                                                this.state.districtSettings.canActivelyReview[endWeek]} />
                                                    </div>
                                                })}
                                            </div>
                                            <div className='w-50'>
                                                <div className='font-24 font-bold'>
                                                    Super Admin District Settings
                                                </div>
                                                <div className='font-24 font-bold mt-4'>Can Regenerate Questions</div>
                                                {this.state.endWeeks.map((endWeek, idx) => {
                                                    return <div className='flex flex-v-center w-100'>
                                                        <div className='font-bold font-20 mr-2'>Week {endWeek} Live:</div>
                                                        <Switch
                                                            size='large'
                                                            onChange={(checked) => {
                                                                this.superAdminCanRegenChange(endWeek, checked)
                                                            }}
                                                            checked={this.state.superAdminDistrictSettings.canRegen.hasOwnProperty(endWeek) &&
                                                                this.state.superAdminDistrictSettings.canRegen[endWeek]} />
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Content>
                    </Layout>
                </div>
            </div>
        )
    }
}

export default SuperAdminDistrictSettings