import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
import CoachingNotesForm from './CoachingNotesForm'
import Empty from '../customcomponents/Empty'
import { flattenDoc, getStateStandardNamesDict, getUUIDV4, getStandardsColType, getStandardSettings, shuffleArray, shuffleArrayWithGroups, manualCheckAssessment, manualCheckQuestionDoc, getQuestionStatusId } from '../Util'
import { Col, Popconfirm, Row, notification, Button, Tooltip, Layout, Menu, Icon, Select, Input, Switch, Modal } from 'antd'
import CoachingNotesUpdateForm from './CoachingNotesUpdateForm'
import DocumentDelegator from '../edviz/EdvizDelegator'
import QuizCreatorForm from './QuizCreatorForm'
import PersonAvatar from '../customcomponents/PersonAvatar'
//import { Layout, Row, Col, Select, Icon, Tabs, Menu } from 'antd'
const moment = require('moment')
const Sider = Layout.Sider
const Content = Layout.Content

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}


class QuizCreator extends Component {
    state = {
        standards: [],
        standardsDict: {},
        startWeek: 1,
        endWeek: 3,
        grade: 3,
        standardDocsDict: {},
        questionsNumbers: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
        numQuestions: 5,
        weekNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
            11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36
        ],
        startWeek: 1,
        endWeek: 3,
        quizzes: [],
        quizzesDict: {},
        quizzesGroupedByWeek: {},
        passages: [],
        passagesDict: {},
        selectedQuiz: null,
        quizListener: null,
        totalWeeks: 37,
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        standardNamesDict: {},
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        pacingGuide: null,
        standard: '',
        editingTitle: false,
        questionReviews: [],
        questionUUIDReviews: {},
        reviewsMounted: false,
        numQuestionsToAdd: 1,
        missingPassageModalVisible: false,
        missingPassageForStandardInfo: null,
        questionStatusDict: {},
        standardsSelectMode: 'pacingGuide',
        standardBreakdown: {},
    }

    async componentDidMount() {
        let stateObj = {
            standardNamesDict: getStateStandardNamesDict(this.props.state)
        }
        try {
            const standardSettingsDict = await getStandardSettings()
            if (!standardSettingsDict.hasOwnProperty(this.props.state)) {
                console.log('Could not load standard settings for state', this.props.state)
            } else {
                const standardSettings = standardSettingsDict[this.props.state]
                stateObj.standardSettings = standardSettings
                console.log(standardSettingsDict, standardSettings)
            }
        }
        catch (e) {
            console.log('Could not load standard settings', e)
        }
        this.setState(stateObj, () => {
            this.getQuizzes()
        })
    }

    componentWillUnmount() {
        if (this.state.quizListener) {
            this.state.quizListener()
        }
    }

    hideMissingPassageModal = () => {
        this.setState({
            missingPassageModalVisible: false,
        })
    }

    showMissingPassageModal = () => {
        this.setState({
            missingPassageModalVisible: true,
        })
    }

    async createQuiz() {
        // does not create a DocumentDelegator, just a reference to one that
        // can be created with set. Does get a docId though so we can
        // set uuid below.
        const docRef = db.collection(ColType.quizzes).doc()
        // get docId just so we can set uuid below to it
        const docId = docRef.id

        const quiz = {
            assessmentTitle: 'Quiz',
            assessmentCategory: 'quiz',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            districtId: this.props.districtId,
            docs: JSON.stringify([]),
            endWeek: this.state.endWeek,
            numQuestions: 0,
            standardCategory: 'ELA',
            standardName: '3rd Grade',
            standards: [],
            startWeek: this.state.startWeek,
            // uuid and id are always the same, including in the previous
            // version. Instead of uuidv4 we are using firestore ids
            uuid: docId,
            live: false,
            canEdit: false,
            queryId: this.props.districtId,
        }

        docRef
            .set(quiz)
            .then(() => {
                successMessage('Assessment created successfully')
                let createdQuiz = quiz
                createdQuiz.docs = JSON.parse(createdQuiz.docs)
                createdQuiz.id = docRef.id
                this.setState({
                    selectedQuiz: createdQuiz,
                }, async () => {
                    await this.getStandards()
                    this.getQuestionBankPassages()
                })
            })
            .catch((e) => {
                console.log('error', e)
                errorMessage('Error creating assessment. Please try again.')
            })
    }

    deleteQuiz(quiz) {
        if (quiz === null) {
            return
        }
        if (quiz.hasOwnProperty('live') && quiz.live) {
            return
        }
        db.collection(ColType.quizzes)
            .doc(quiz.id)
            .delete()
            .then(() => {
                successMessage('Assessment deleted successfully')
                this.setState({
                    selectedQuiz: null,
                })
            })
            .catch((e) => {
                console.log('error', e)
                errorMessage('Error deleting assessment. Please try again.')
            })
    }

    async getQuizzes() {
        if (this.state.quizListener) {
            this.state.quizListener()
        }
        this.setState({
            quizListener: null,
        })

        const quizListener = db.collection(ColType.quizzes)
            .where('districtId', '==', this.props.districtId)
            .where('queryId', '==', this.props.districtId)
            .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot) => {
                let quizzes = []
                let quizzesDict = {}
                let quizzesGroupedByWeek = {}
                querySnapshot.forEach((doc) => {
                    const quiz = flattenDoc(doc)
                    quiz.docs = JSON.parse(quiz.docs)
                    quizzes.push(quiz)
                    quizzesDict[quiz.id] = quiz

                    if (quiz.id !== quiz.uuid) {
                        console.error('Quiz id does not match uuid')
                        /*db.collection(ColType.quizzes)
                            .doc(quiz.id)
                            .update({
                                uuid: quiz.id,
                            })*/
                    }
                    const key = quiz.startWeek + '-' + quiz.endWeek
                    if (!quizzesGroupedByWeek.hasOwnProperty(key)) {
                        quizzesGroupedByWeek[key] = []
                    }
                    quizzesGroupedByWeek[key].push(quiz)
                })

                // sort by assessmentTitle
                Object.keys(quizzesGroupedByWeek).forEach((key) => {
                    quizzesGroupedByWeek[key].sort((a, b) => {
                        if (a.assessmentTitle < b.assessmentTitle) {
                            return -1
                        }
                        if (a.assessmentTitle > b.assessmentTitle) {
                            return 1
                        }
                        return 0
                    })
                })

                this.setState({
                    quizzes: quizzes,
                    quizzesDict: quizzesDict,
                    quizzesGroupedByWeek: quizzesGroupedByWeek,
                })
            })
        this.setState({
            quizListener: quizListener,
        })
    }

    async getStandards() {
        const standardsPromise = new Promise((resolve, reject) => {
            db.collection(ColType.standards)
                .where('standardName', '==', this.state.selectedQuiz.standardName)
                .where('standardCategory', '==', this.state.selectedQuiz.standardCategory)
                .where('state', '==', this.props.state)
                .where('year', '==', this.state.standardSettings[this.state.selectedQuiz.standardCategory])
                .orderBy('sortOrder')
                .get()
                .then((querySnapshot) => {
                    let standards = []
                    let standardsDict = {}
                    let grade = '3'
                    querySnapshot.forEach((doc) => {
                        let standard = flattenDoc(doc)
                        standards.push(standard)
                        standardsDict[standard.standard] = standard

                        if (standard.hasOwnProperty('grade')) {
                            grade = standard.grade
                        }
                    })
                    this.setState({
                        standards: standards,
                        standardsDict: standardsDict,
                        grade: grade,
                    }, () => {
                        resolve()
                    })
                })
                .catch((e) => {
                    console.log('error', e)
                    reject()
                })
        })

        const pacingGuidePromise = new Promise((resolve, reject) => {
            db.collection(ColType.pacingGuides)
                .where('standardName', '==', this.state.selectedQuiz.standardName)
                .where('standardCategory', '==', this.state.selectedQuiz.standardCategory)
                .where('districtId', '==', this.props.districtId)
                .limit(20)
                .get()
                .then((querySnapshot) => {
                    console.log('pacing guide docs len', querySnapshot.docs.length)
                    if (querySnapshot.empty) {
                        this.setState({
                            pacingGuide: null,
                        })
                        resolve()
                        return
                    }
                    let pacingGuide = flattenDoc(querySnapshot.docs[0])
                    let maxScheduled = 0
                    // find pacingGuide that has data in it (might be more than one
                    // pacingGuide here)
                    querySnapshot.forEach((doc) => {
                        let pacingGuideInternal = flattenDoc(doc)
                        let totalScheduled = 0
                        for (let i = 1; i < this.state.totalWeeks; i++) {
                            if (pacingGuideInternal.weeks.hasOwnProperty(i) &&
                                pacingGuideInternal.weeks[i].length > 0) {
                                totalScheduled += pacingGuideInternal.weeks[i].length
                            }
                        }
                        if (totalScheduled > maxScheduled) {
                            maxScheduled = totalScheduled
                            pacingGuide = pacingGuideInternal
                        }
                    })
                    this.setState({
                        pacingGuide: pacingGuide,
                    }, () => {
                        resolve()
                    })
                })
                .catch((e) => {
                    console.log('error', e)
                    reject()
                })
        })

        try {
            await Promise.all([standardsPromise, pacingGuidePromise])
        } catch (e) {
            console.log(e)
        }

        // get these from the pacing guide later
        /*return [
            //"8.EEI.4.a",
            //"8.EEI.4.b",
            //"8.EEI.4.c",
            'RL.8.a.1.a',
            'RL.8.a.1.b',
            'RL.11.2',
        ]*/
    }

    async getQuestionBankPassages() {
        try {
            const querySnapshot = await db.collection(ColType.questionBankPassages)
                .where('metadata.grade', '==', '' + this.state.grade)
                .get()

            let questionBankPassages = []
            let passagesDict = {}
            querySnapshot.forEach((doc) => {
                const questionBankPassage = flattenDoc(doc)
                questionBankPassage.components = JSON.parse(questionBankPassage.components)
                questionBankPassages.push(questionBankPassage)
                passagesDict[questionBankPassage.id] = questionBankPassage
            })

            questionBankPassages.sort((a, b) => {
                return Date.parse(b.metadata.createdAt) - Date.parse(a.metadata.createdAt)
            })

            this.setState({
                passages: questionBankPassages,
                passagesDict: passagesDict,
            })

            return questionBankPassages
        } catch (e) {
            console.log('error', e)
            return []
        }
    }

    async getQuestionDataForStandard(standard) {
        // if we already have the question data for this standard, return
        // add this back later
        /*if (this.state.standardDocsDict.hasOwnProperty(standard)) {
            return new Promise((resolve, reject) => {
                resolve()
            })
        }*/
        return new Promise((resolve, reject) => {
            db.collection(ColType.questionBank)
                .where('metadata.status', '==', 'reviewed')
                .where('metadata.standard.standard', '==', standard)
                .where('metadata.standard.standardCategory', '==', '' + this.state.selectedQuiz.standardCategory)
                .where('metadata.standard.standardName', '==', '' + this.state.selectedQuiz.standardName)
                .where('metadata.standard.state', '==', '' + this.props.state)
                .get()
                .then(async (querySnapshot) => {
                    let questionDocs = [];
                    querySnapshot.forEach((doc) => {
                        const questionDoc = flattenDoc(doc);
                        questionDoc.components = JSON.parse(questionDoc.components);
                        questionDocs.push(questionDoc);
                    });

                    // get question status for each question
                    let questionStatusPromises = [];
                    questionDocs.forEach((questionDoc) => {
                        // if we keep the above commented out early resolve() code,
                        // do this fetch in there everytime for those questions.
                        questionStatusPromises.push(new Promise((resolve, reject) => {
                            db.collection(ColType.questionStatus)
                                .doc(getQuestionStatusId(this.props.districtId, questionDoc.metadata.uuid))
                                .get()
                                .then((doc) => {
                                    let questionStatusDict = this.state.questionStatusDict;
                                    // doc doesn't exist, remove from questionStatusDict if it exists there
                                    if (!doc.exists) {
                                        if (questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                                            delete questionStatusDict[questionDoc.metadata.uuid];
                                        }
                                    } else {
                                        const questionStatus = flattenDoc(doc);
                                        // inactive, remove from questionStatusDict if it exists there
                                        if (questionStatus.status === 'inactive') {
                                            if (questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                                                delete questionStatusDict[questionDoc.metadata.uuid];
                                            }
                                        }
                                        // active or replaced, add to questionStatusDict. If it is
                                        // in the questionStatusDict, it cannot be used 
                                        else {
                                            questionStatusDict[questionDoc.metadata.uuid] = questionStatus;
                                        }
                                    }
                                    this.setState({
                                        questionStatusDict: questionStatusDict,
                                    }, () => {
                                        resolve();
                                    })
                                })
                                .catch((e) => {
                                    //console.log('error', e)
                                    // can happen if the questionStatus doc doesn't exist
                                    resolve()
                                })
                        }))
                    })

                    try {
                        await Promise.all(questionStatusPromises)
                    } catch (e) {
                        // fall through if questionStatusPromises fails,
                        // failure can happen if the questionStatus doc doesn't exist
                    }

                    let standardNames = [];
                    questionDocs.forEach((questionDoc) => {
                        standardNames.push(questionDoc.metadata.standard.standard);
                    });

                    let standardDocsDict = this.state.standardDocsDict;
                    standardDocsDict[standard] = questionDocs;
                    this.setState({
                        standardDocsDict: standardDocsDict,
                    }, () => {
                        resolve()
                    })
                })
                .catch((e) => {
                    console.log('error', e)
                    reject(e)
                })
        })
    }

    onNumQuestionsChange = (e) => {
        console.log('num questions', e)
        this.setState({
            numQuestions: e,
        })
    }

    updateQuiz = (updateObj) => {
        return new Promise((resolve, reject) => {
            if (!this.state.selectedQuiz) {
                reject('No quiz selected')
                return
            }

            const quiz = this.state.selectedQuiz
            db.collection(ColType.quizzes)
                .doc(quiz.id)
                .update(updateObj)
                .then(() => {
                    //successMessage('Quiz updated')
                    resolve()
                })
                .catch((e) => {
                    console.log('error', e)
                    errorMessage('Error updating the last edit made. Please try again.')
                    reject()
                })
        })
    }

    onStandardCategoryChange = (e) => {
        let quiz = this.state.selectedQuiz
        let updateObj = {
            standardCategory: e,
        }
        quiz.standardCategory = e
        if (!this.state.gradeDictAcrossCategories.hasOwnProperty(this.state.selectedQuiz.standardName)) {
            updateObj.standardName = 'Kindergarten'
            quiz.standardName = 'Kindergarten'
        }
        this.updateQuiz(updateObj)
        this.setState({
            selectedQuiz: quiz
        }, () => {
            this.getStandards()
        })
    }

    onStandardNameChange = (e) => {
        let quiz = this.state.selectedQuiz
        quiz.standardName = e
        this.updateQuiz({
            standardName: e,
        })
        this.setState({
            selectedQuiz: quiz
        }, async () => {
            await this.getStandards()
            this.getQuestionBankPassages()
        })
    }

    quizContainsQuestionDoc = (quiz, questionDoc) => {
        for (let i = 0; i < quiz.docs.length; i++) {
            if (quiz.docs[i].id === questionDoc.id) {
                return true
            }
        }
        return false
    }

    addQuestion = async () => {
        for (let questionNumber = 0; questionNumber < this.state.numQuestionsToAdd; questionNumber++) {
            try {
                await this.getQuestionDataForStandard(this.state.standard)
            } catch (e) {
                console.log('error', e)
                errorMessage('Error adding question. Please try again.')
                return
            }
            // after getQuestionDataForStandard is done, standardDocsDict will be populated with
            // this.state.standard
            if (!this.state.standardDocsDict.hasOwnProperty(this.state.standard)) {
                errorMessage('Error adding question. Questions may not be available for this standard yet.')
                return
            }

            const quiz = this.state.selectedQuiz
            const questionDocs = this.state.standardDocsDict[this.state.standard]
            console.log('questionDocs', questionDocs)
            let selectedQuestionDoc = null
            for (let i = 0; i < questionDocs.length; i++) {
                const questionDoc = questionDocs[i]
                // question has already been used in a quiz (maybe not this one), continue
                if (this.state.questionStatusDict &&
                    this.state.questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                    continue
                }
                // quiz already has this question, continue
                if (this.quizContainsQuestionDoc(quiz, questionDoc)) {
                    continue
                }
                selectedQuestionDoc = questionDoc
                break
            }

            if (!selectedQuestionDoc) {
                errorMessage('Error adding question. There are no more questions available for this standard.')
                return
            }

            quiz.docs.push(selectedQuestionDoc)
            quiz.standards = this.getStandardsInQuiz()

            this.updateQuiz({
                docs: JSON.stringify(quiz.docs),
                standards: quiz.standards,
                numQuestions: quiz.docs.length,
            })
            this.setState({
                selectedQuiz: quiz,
            }, () => {
                this.getStandardQuestionBreakdown()
            })

            this.setQuestionStatus(this.props.districtId, selectedQuestionDoc.metadata.uuid, 'active')
            successMessage('Question added successfully')
        }
    }


    setQuestionStatus(districtId, questionUUID, status) {
        console.log('setting question status', districtId, questionUUID, status)
        db.collection(ColType.questionStatus)
            .doc(getQuestionStatusId(districtId, questionUUID))
            .set({
                districtId: districtId,
                questionUUID: questionUUID,
                status: status,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('question status added')
            })
            .catch((e) => {
                console.log('error', e)
            })
    }

    replaceQuestionAtIdx = async (idx) => {
        const quiz = this.state.selectedQuiz
        if (idx < 0 || idx >= quiz.docs.length) {
            errorMessage('Error replacing question. Please try again.')
            return
        }
        const prevQuestionDoc = quiz.docs[idx]
        const standard = quiz.docs[idx].metadata.standard.standard

        try {
            await this.getQuestionDataForStandard(standard)
        } catch (e) {
            console.log('error', e)
            errorMessage('Error adding question. Please try again.')
            return
        }
        // after getQuestionDataForStandard is done, standardDocsDict will be populated with
        // this.state.standard
        if (!this.state.standardDocsDict.hasOwnProperty(standard)) {
            errorMessage('Error adding question. Questions may not be available for this standard yet.')
            return
        }

        const questionDocs = this.state.standardDocsDict[standard]
        let selectedQuestionDoc = null
        for (let i = 0; i < questionDocs.length; i++) {
            const questionDoc = questionDocs[i]
            // question has already been used in a quiz (maybe not this one), continue
            if (this.state.questionStatusDict &&
                this.state.questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                continue
            }
            // quiz already has this question, continue
            if (this.quizContainsQuestionDoc(quiz, questionDoc)) {
                continue
            }
            selectedQuestionDoc = questionDoc
            break
        }

        if (!selectedQuestionDoc) {
            errorMessage('Error replacing question. There are no more questions available for this standard.')
            return
        }

        quiz.docs[idx] = selectedQuestionDoc
        quiz.standards = this.getStandardsInQuiz()

        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })


        this.setQuestionStatus(this.props.districtId, prevQuestionDoc.metadata.uuid, 'inactive')
        this.setQuestionStatus(this.props.districtId, selectedQuestionDoc.metadata.uuid, 'active')
        successMessage('Question replaced successfully with a new question for the same standard')
    }

    replaceQuestionAtIdxWithNewStandard = async (idx, standard) => {
        const quiz = this.state.selectedQuiz
        if (idx < 0 || idx >= quiz.docs.length) {
            errorMessage('Error replacing question. Please try again.')
            return
        }

        try {
            await this.getQuestionDataForStandard(standard)
        } catch (e) {
            console.log('error', e)
            errorMessage('Error adding question. Please try again.')
            return
        }
        // after getQuestionDataForStandard is done, standardDocsDict will be populated with
        // this.state.standard
        if (!this.state.standardDocsDict.hasOwnProperty(standard)) {
            errorMessage('Error adding question. Questions may not be available for this standard yet.')
            return
        }

        const prevQuestionDoc = quiz.docs[idx]

        const questionDocs = this.state.standardDocsDict[standard]
        let selectedQuestionDoc = null
        for (let i = 0; i < questionDocs.length; i++) {
            const questionDoc = questionDocs[i]
            // question has already been used in a quiz (maybe not this one), continue
            if (this.state.questionStatusDict &&
                this.state.questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                continue
            }
            // quiz already has this question, continue
            if (this.quizContainsQuestionDoc(quiz, questionDoc)) {
                continue
            }
            selectedQuestionDoc = questionDoc
            break
        }

        if (!selectedQuestionDoc) {
            errorMessage('Error replacing question. There are no more questions available for this standard.')
            return
        }

        quiz.docs[idx] = selectedQuestionDoc
        quiz.standards = this.getStandardsInQuiz()

        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })

        this.setQuestionStatus(this.props.districtId, prevQuestionDoc.metadata.uuid, 'inactive')
        this.setQuestionStatus(this.props.districtId, selectedQuestionDoc.metadata.uuid, 'active')
        successMessage('Question replaced successfully with a new question from ' + standard)
    }

    removeQuestionAtIdx = (idx) => {
        const quiz = this.state.selectedQuiz
        const prevQuestionDoc = quiz.docs[idx]
        quiz.docs.splice(idx, 1)
        quiz.standards = this.getStandardsInQuiz()

        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })

        this.setQuestionStatus(this.props.districtId, prevQuestionDoc.metadata.uuid, 'inactive')
        successMessage('Question removed successfully')
    }

    onStandardChange = (standard) => {
        this.setState({
            standard: standard,
        })
    }

    getQuestionReviews = () => {
        db.collection(ColType.questionReview)
            .where('districtId', '==', this.props.districtId)
            .where('assessmentUUID', '==', this.state.selectedQuiz.uuid)
            .orderBy('timeStamp', 'asc')
            .get()
            .then((querySnapshot) => {
                let questionReviews = []
                let questionUUIDReviews = {}
                querySnapshot.forEach((doc) => {
                    let questionReview = flattenDoc(doc)
                    questionReviews.push(questionReview)
                    if (!questionUUIDReviews.hasOwnProperty(questionReview.questionUUID)) {
                        questionUUIDReviews[questionReview.questionUUID] = []
                    }
                    questionUUIDReviews[questionReview.questionUUID].push(questionReview)
                })
                //console.log('questionReviews', questionReviews)
                this.setState({
                    questionReviews: questionReviews,
                    questionUUIDReviews: questionUUIDReviews,
                    reviewsMounted: true,
                })
            })
    }

    selectQuiz = (quizKey) => {
        const quiz = this.state.quizzesDict &&
            this.state.quizzesDict.hasOwnProperty(quizKey) &&
            this.state.quizzesDict[quizKey] ?
            this.state.quizzesDict[quizKey] : null

        this.setState({
            selectedQuiz: quiz,
        }, async () => {
            await this.getStandards()
            this.getQuestionBankPassages()
            this.getStandardQuestionBreakdown()
            this.getQuestionReviews()

            // fix dok levels if necessary
            if (this.quizHasStringDOKLevels()) {
                this.makeAllDOKLevelsNumbers()
            }
        })
    }

    allQuestionsHaveBeenReviewed = () => {
        if (!this.state.selectedQuiz) {
            return false
        }
        for (let i = 0; i < this.state.selectedQuiz.docs.length; i++) {
            const questionDoc = this.state.selectedQuiz.docs[i]
            if (!questionDoc.metadata.hasOwnProperty('status') ||
                questionDoc.metadata.status !== 'reviewed') {
                return false
            }
        }
        return true
    }

    allQuestionsAreValid = () => {
        if (!this.state.selectedQuiz) {
            return false
        }
        return manualCheckAssessment(this.state.selectedQuiz)
    }

    onPassageChange = async (questionDocIdx, passageId) => {
        let quiz = this.state.selectedQuiz
        if (questionDocIdx < 0 || questionDocIdx >= quiz.docs.length) {
            errorMessage('Error replacing question. Please try again.')
            return
        }
        let questionDoc = quiz.docs[questionDocIdx]
        // this question is already using this passage
        if (questionDoc.metadata.hasOwnProperty('groupUuid') &&
            questionDoc.metadata.groupUuid === passageId) {
            console.log('already using this passage', passageId)
            return
        }
        const prevQuestionDoc = quiz.docs[questionDocIdx]
        const standard = questionDoc.metadata.standard.standard

        try {
            await this.getQuestionDataForStandard(standard)
        } catch (e) {
            console.log('error', e)
            errorMessage('Error adding question. Please try again.')
            return
        }
        // after getQuestionDataForStandard is done, standardDocsDict will be populated with
        // this.state.standard
        if (!this.state.standardDocsDict.hasOwnProperty(standard)) {
            errorMessage('Error adding question. Questions may not be available for this standard yet.')
            return
        }

        const questionDocs = this.state.standardDocsDict[standard]
        let questionDocWithPassage = null
        for (let i = 0; i < questionDocs.length; i++) {
            const questionDoc = questionDocs[i]
            // question has already been used in a quiz (maybe not this one), continue
            if (this.state.questionStatusDict &&
                this.state.questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                continue
            }
            if (this.quizContainsQuestionDoc(quiz, questionDoc)) {
                continue
            }
            if (questionDoc.metadata.hasOwnProperty('groupUuid') &&
                questionDoc.metadata.groupUuid === passageId) {
                questionDocWithPassage = questionDoc
                break
            }
        }
        if (!questionDocWithPassage) {
            errorMessage('Error replacing passage. There may be no questions available for this passage.')
            return
        }

        quiz.docs[questionDocIdx] = questionDocWithPassage
        quiz.standards = this.getStandardsInQuiz()

        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })

        this.setQuestionStatus(this.props.districtId, prevQuestionDoc.metadata.uuid, 'inactive')
        this.setQuestionStatus(this.props.districtId, questionDocWithPassage.metadata.uuid, 'active')
        successMessage('Passage replaced successfully')
    }

    pacingGuideHasStandardInWeeks = (standard) => {
        if (!this.state.pacingGuide) {
            return false
        }

        for (let i = this.state.selectedQuiz.startWeek; i <= this.state.selectedQuiz.endWeek - 1; i++) {
            const week = this.state.pacingGuide.weeks[i]
            if (week.find((standardObj) => {
                return standardObj.standard === standard
            })) {
                return true
            }
        }

        return false
    }

    getNumberOfDOKQuestions = (dokLevel) => {
        let numDOKQuestions = 0
        for (let i = 0; i < this.state.selectedQuiz.docs.length; i++) {
            const questionDoc = this.state.selectedQuiz.docs[i]
            if (typeof questionDoc.metadata.dokLevel === 'string') {
                return 'DOK Level has type of string, expected int'
            }
            if (questionDoc.metadata.dokLevel === dokLevel) {
                numDOKQuestions += 1
            }
        }
        return numDOKQuestions
    }

    quizHasStringDOKLevels = () => {
        for (let i = 0; i < this.state.selectedQuiz.docs.length; i++) {
            const questionDoc = this.state.selectedQuiz.docs[i]
            if (typeof questionDoc.metadata.dokLevel === 'string') {
                return true
            }
        }
        return false
    }

    makeAllDOKLevelsNumbers = () => {
        let quiz = this.state.selectedQuiz
        for (let i = 0; i < quiz.docs.length; i++) {
            const questionDoc = quiz.docs[i]
            if (typeof questionDoc.metadata.dokLevel === 'string') {
                questionDoc.metadata.dokLevel = parseInt(questionDoc.metadata.dokLevel, 10)
            }
        }
        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
        })
        this.setState({
            selectedQuiz: quiz,
        })
    }

    changeDOKLevel = async (idx, dokLevel) => {
        const quiz = this.state.selectedQuiz
        if (idx < 0 || idx >= quiz.docs.length) {
            errorMessage('Error replacing question. Please try again.')
            return
        }

        const prevQuestionDoc = quiz.docs[idx]
        const standard = quiz.docs[idx].metadata.standard.standard

        try {
            await this.getQuestionDataForStandard(standard)
        } catch (e) {
            console.log('error', e)
            errorMessage('Error adding question. Please try again.')
            return
        }
        // after getQuestionDataForStandard is done, standardDocsDict will be populated with
        // this.state.standard
        if (!this.state.standardDocsDict.hasOwnProperty(standard)) {
            errorMessage('Error adding question. Questions for this DOK level may not be available for this standard yet.')
            return
        }

        const questionDocs = this.state.standardDocsDict[standard]
        let selectedQuestionDoc = null
        for (let i = 0; i < questionDocs.length; i++) {
            const questionDoc = questionDocs[i]
            // DOK level check
            if (questionDoc.metadata.dokLevel !== dokLevel) {
                continue
            }
            // question has already been used in a quiz (maybe not this one), continue
            if (this.state.questionStatusDict &&
                this.state.questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                continue
            }
            // quiz already has this question, continue
            if (this.quizContainsQuestionDoc(quiz, questionDoc)) {
                continue
            }
            selectedQuestionDoc = questionDoc
            break
        }

        if (!selectedQuestionDoc) {
            errorMessage('Error replacing question. There are no more questions available for this standard.')
            return
        }

        quiz.docs[idx] = selectedQuestionDoc
        quiz.standards = this.getStandardsInQuiz()

        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })

        this.setQuestionStatus(this.props.districtId, prevQuestionDoc.metadata.uuid, 'inactive')
        this.setQuestionStatus(this.props.districtId, selectedQuestionDoc.metadata.uuid, 'active')
        successMessage('Question DOK level chaned successfully')
    }

    getStandardsInQuiz = () => {
        let quiz = this.state.selectedQuiz
        let standards = []
        let standardsDict = {}
        quiz.docs.map((questionDoc) => {
            if (standardsDict.hasOwnProperty(questionDoc.metadata.standard.standard)) {
                return false
            }
            standards.push(questionDoc.metadata.standard)
            standardsDict[questionDoc.metadata.standard.standard] = questionDoc.metadata.standard
            return false
        })
        return standards
    }

    shuffleQuiz = () => {
        let quiz = this.state.selectedQuiz
        quiz.docs = shuffleArrayWithGroups(quiz.docs)
        quiz.standards = this.getStandardsInQuiz()
        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })
        successMessage('Quiz shuffled successfully')
    }

    permanentlyRemoveQuestionAtIdx = (idx) => {
        const quiz = this.state.selectedQuiz
        const prevQuestionDoc = quiz.docs[idx]
        quiz.docs.splice(idx, 1)
        quiz.standards = this.getStandardsInQuiz()

        this.updateQuiz({
            docs: JSON.stringify(quiz.docs),
            standards: quiz.standards,
            numQuestions: quiz.docs.length,
        })
        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
        })

        this.setQuestionStatus(this.props.districtId, prevQuestionDoc.metadata.uuid, 'deleted')
        successMessage('Question removed successfully')
    }

    getStandardQuestionBreakdown = () => {
        let standardBreakdown = {}
        this.state.selectedQuiz.docs.forEach((questionDoc) => {
            const standard = questionDoc.metadata.standard.standard
            if (!standardBreakdown.hasOwnProperty(standard)) {
                standardBreakdown[standard] = 0
            }
            standardBreakdown[standard] += 1
        })

        this.setState({
            standardBreakdown: standardBreakdown,
        })

        return standardBreakdown
    }

    render() {
        console.log(this.state)
        return (
            <div>
                <div>
                    <Layout>
                        <Layout>
                            <Content className='layout-header-mt overflow-visible-important' style={{ marginTop: '85px' }}>
                                <Sider
                                    width={270}
                                    style={{
                                        overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 83px)',
                                        position: 'fixed',
                                        left: 0, borderRight: '1px solid #f1f1f1',
                                        background: '#fff',
                                        zIndex: 10,
                                    }}>
                                    <div className='pl-2 pr-2 font-2 mt-2'>
                                        <Button type='primary' size='large'
                                            className='flex flex-v-center mb-2'
                                            onClick={() => {
                                                this.createQuiz()
                                            }}>
                                            <Icon type='plus' className='mr-1 font-20' />
                                            <div className='font-20'>Create Assessment</div>
                                        </Button>
                                    </div>

                                    <Menu selectedKeys={this.state.menuKey} mode='inline' onSelect={(e) => {
                                        console.log('selected', e.key)
                                        this.setState({
                                            menuKey: e.key,
                                        })
                                        this.selectQuiz(e.key)
                                    }}>
                                        {this.state.quizzesGroupedByWeek &&
                                            Object.keys(this.state.quizzesGroupedByWeek).sort((a, b) => {
                                                const aSplit = a.split('-')
                                                const bSplit = b.split('-')
                                                let aEndWeek = aSplit[0]
                                                let bEndWeek = bSplit[0]
                                                if (aSplit.length > 1) {
                                                    aEndWeek = aSplit[1]
                                                }
                                                if (bSplit.length > 1) {
                                                    bEndWeek = bSplit[1]
                                                }
                                                return parseInt(bEndWeek, 10) - parseInt(aEndWeek, 10)
                                            }).map((quizzes) => {
                                                return <Menu.SubMenu title={<div className='font-16'>
                                                    {quizzes.split('-')[0] === quizzes.split('-')[1] ?
                                                        'Week ' + quizzes.split('-')[0] :
                                                        'Week ' + quizzes.split('-')[0] + ' - ' + quizzes.split('-')[1]}
                                                </div>}>
                                                    {this.state.quizzesGroupedByWeek[quizzes].map((quiz) => {
                                                        return <Menu.Item key={quiz.id}>
                                                            <div className='flex flex-v-center'>
                                                                <div className='font-16'>
                                                                    {quiz.assessmentTitle}
                                                                </div>
                                                                {/*
                                                            <div className='font-14 text-muted ml-auto'>
                                                                {quiz.createdAt &&
                                                                    quiz.createdAt.hasOwnProperty('seconds') &&
                                                                    quiz.createdAt.seconds &&
                                                                    moment.utc(quiz.createdAt.seconds * 1000).format('MM/DD/YYYY')}
                                                            </div>
                                                                */}
                                                            </div>
                                                        </Menu.Item>
                                                    })}
                                                </Menu.SubMenu>
                                            })}
                                    </Menu>
                                </Sider>

                                <Content style={{ marginLeft: 270, marginRight: 270, marginTop: "24px", overflowX: 'initial' }}>
                                    <div className='pl-4 pr-4'>
                                        <div className='w-680 m-lr-auto'>
                                            {!this.state.selectedQuiz ?
                                                <div>
                                                    <Empty
                                                        containerClassName="mt-50 text-center"
                                                        width={55}
                                                        height={55}
                                                        image={'/empty/goals.png'}
                                                        description={'There is currently no assessment selected. Please select or create an assessment.'}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <div className=''>
                                                        <div className={'font-24 font-bold' +
                                                            (!this.state.editingTitle ? ' ellipsis' : '')}
                                                            style={{ maxWidth: '430px' }}
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            disabled={
                                                                this.state.selectedQuiz &&
                                                                this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                this.state.selectedQuiz.live
                                                            }
                                                            onFocus={(e) => {
                                                                if (this.state.selectedQuiz &&
                                                                    this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                    this.state.selectedQuiz.live) {
                                                                    return
                                                                }
                                                                this.setState({
                                                                    editingTitle: true,
                                                                })
                                                            }}
                                                            onBlur={(e) => {
                                                                if (this.state.selectedQuiz &&
                                                                    this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                    this.state.selectedQuiz.live) {
                                                                    return
                                                                }
                                                                this.updateQuiz({
                                                                    assessmentTitle: e.target.innerHTML,
                                                                })
                                                                let selectedQuiz = this.state.selectedQuiz
                                                                selectedQuiz.assessmentTitle = e.target.innerHTML
                                                                this.setState({
                                                                    editingTitle: false,
                                                                    selectedQuiz: selectedQuiz,
                                                                })
                                                            }}>
                                                            {this.state.selectedQuiz.assessmentTitle}
                                                        </div>
                                                        <div className='flex w-100 mt-2'>
                                                            <div className='ml-auto'>
                                                                <div className='flex flex-v-center'>
                                                                    <div className='font-bold font-16 mr-1'>Can View:</div>
                                                                    <Switch size='large' className='mr-4' checked={this.state.selectedQuiz &&
                                                                        this.state.selectedQuiz.hasOwnProperty('canView') ?
                                                                        this.state.selectedQuiz.canView : false}
                                                                        onChange={(e) => {
                                                                            let selectedQuiz = this.state.selectedQuiz
                                                                            selectedQuiz.canView = e
                                                                            this.setState({
                                                                                selectedQuiz: selectedQuiz,
                                                                            })
                                                                            this.updateQuiz({
                                                                                canView: e,
                                                                            })
                                                                        }}
                                                                    />
                                                                    <div className='font-bold font-16 mr-1'>Can Edit:</div>
                                                                    <Switch size='large' className='mr-4' checked={this.state.selectedQuiz &&
                                                                        this.state.selectedQuiz.hasOwnProperty('canEdit') ?
                                                                        this.state.selectedQuiz.canEdit : false}
                                                                        onChange={(e) => {
                                                                            let selectedQuiz = this.state.selectedQuiz
                                                                            selectedQuiz.canEdit = e
                                                                            this.setState({
                                                                                selectedQuiz: selectedQuiz,
                                                                            })
                                                                            this.updateQuiz({
                                                                                canEdit: e,
                                                                            })
                                                                        }}
                                                                    />
                                                                    <div className='font-bold font-16 mr-1'>Live:</div>
                                                                    <Switch size='large' className='mr-4' checked={this.state.selectedQuiz &&
                                                                        this.state.selectedQuiz.hasOwnProperty('live') ?
                                                                        this.state.selectedQuiz.live : false}
                                                                        onChange={(e) => {
                                                                            let selectedQuiz = this.state.selectedQuiz
                                                                            selectedQuiz.live = e
                                                                            this.setState({
                                                                                selectedQuiz: selectedQuiz,
                                                                            })
                                                                            this.updateQuiz({
                                                                                live: e,
                                                                            })
                                                                        }}
                                                                    />
                                                                    <Popconfirm title='Are you sure you want to delete this assessment?'
                                                                        disabled={this.state.selectedQuiz &&
                                                                            this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                            this.state.selectedQuiz.live}
                                                                        onConfirm={(e) => {
                                                                            if (this.state.selectedQuiz &&
                                                                                this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                                this.state.selectedQuiz.live) {
                                                                                return
                                                                            }
                                                                            this.deleteQuiz(this.state.selectedQuiz)
                                                                        }}
                                                                        okText='Yes' cancelText='No'>
                                                                        <Button icon='delete' size='large' className='lg-icon-btn'
                                                                            disabled={this.state.selectedQuiz &&
                                                                                this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                                this.state.selectedQuiz.live}>
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='font-20'>{this.state.selectedQuiz.docs.length} questions</div>
                                                    <div className='flex w-100'>
                                                        <div>
                                                            <div className='font 20'>DOK 1 questions: {this.getNumberOfDOKQuestions(1)}</div>
                                                            <div className='font 20'>DOK 2 questions: {this.getNumberOfDOKQuestions(2)}</div>
                                                            <div className='font 20'>DOK 3 questions: {this.getNumberOfDOKQuestions(3)}</div>
                                                        </div>
                                                        <div className='ml-auto'>
                                                            {Object.keys(this.state.standardBreakdown).map((standard) => {
                                                                return <div className='font 20'>{standard}: {this.state.standardBreakdown[standard]}</div>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='flex w-100'>
                                                        {this.allQuestionsHaveBeenReviewed() ?
                                                            <div className='font-16 font-bold text-success mb-1'>
                                                                <Icon type='check' className='mr-1' />All questions have been reviewed by Dot It.</div>
                                                            :
                                                            <div className='font-16 font-bold text-danger mb-1'>
                                                                <Icon type='warning' className='mr-1' />Not all questions have been reviewed by Dot It, this is not an issue if this was created with a previous version as there was a different review process in place.</div>}
                                                    </div>
                                                    <div className='flex w-100'>
                                                        {this.allQuestionsAreValid() ?
                                                            <div className='font-16 font-bold text-success mb-1'>
                                                                <Icon type='check' className='mr-1' />All questions have been validated.</div>
                                                            :
                                                            <div className='font-16 font-bold text-danger mb-1'>
                                                                <Icon type='warning' className='mr-1' />Not all questions are valid (ids are not 0, 1, 2, 3), etc.</div>}
                                                    </div>
                                                    <div className='font-16 text-muted-lighter'>
                                                        {this.state.selectedQuiz.createdAt &&
                                                            this.state.selectedQuiz.createdAt.hasOwnProperty('seconds') &&
                                                            this.state.selectedQuiz.createdAt.seconds &&
                                                            moment.utc(this.state.selectedQuiz.createdAt.seconds * 1000).format('MM/DD/YYYY')}
                                                    </div>
                                                    {this.state.questionReviews.length > 0 ?
                                                        <div className='font-20'>{this.state.questionReviews.length} reviews</div>
                                                        : ''}

                                                    {this.state.selectedQuiz.docs.length === 0 ?
                                                        <div>
                                                            <Empty
                                                                containerClassName="mt-50 text-center"
                                                                width={55}
                                                                height={55}
                                                                image={'/empty/goals.png'}
                                                                description={'This assessment currently has no questions. Add a question by using the menu to the right.'}
                                                            />
                                                        </div> : ''}

                                                    {this.state.selectedQuiz.docs.map((questionDoc, questionDocIdx) => {
                                                        return <div className='flex w-100'>
                                                            <div className='mt-2 font-black font-20 border ant-shadow br-4 p-3 mb-50'
                                                                key={'assessment-idx-' + questionDocIdx}>
                                                                {!manualCheckQuestionDoc(questionDoc) ?
                                                                    <div className='font-16 text-danger mb-1 font-bold'>
                                                                        <Icon type='warning' className='mr-1' />This question is not valid (ids are not 0, 1, 2, 3), etc.</div>
                                                                    : ''}
                                                                <div className='mb-3 flex flex-v-center w-100'>
                                                                    <Button type='primary' size='large' className='flex flex-v-center'
                                                                        disabled={this.state.selectedQuiz &&
                                                                            this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                            this.state.selectedQuiz.live}
                                                                        onClick={(e) => {
                                                                            this.replaceQuestionAtIdx(questionDocIdx)
                                                                        }}>
                                                                        <Icon type='sync' className='mr-1 font-20' />
                                                                        <div className='font-20'>New question</div>
                                                                    </Button>
                                                                    <div className='ml-4'>
                                                                        <div className='font-16'>Select another standard:</div>
                                                                        <div className='w-100 flex'>
                                                                            <Select value={questionDoc.metadata.standard.standard}
                                                                                onChange={(e) => {
                                                                                    this.replaceQuestionAtIdxWithNewStandard(questionDocIdx, e)
                                                                                }}
                                                                                disabled={this.state.selectedQuiz &&
                                                                                    this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                                    this.state.selectedQuiz.live}
                                                                                className='ml-auto' size='large' style={{ width: '234px' }}>
                                                                                {this.state.standards.map((standard) => {
                                                                                    if (this.state.standardsSelectMode === 'pacingGuide' &&
                                                                                        this.state.pacingGuide &&
                                                                                        !this.pacingGuideHasStandardInWeeks(standard.standard)) {
                                                                                        return false
                                                                                    }
                                                                                    return <Select.Option value={standard.standard}>
                                                                                        {standard.standard + (standard.isPriority ? ' (*Priority Standard)' : '')}
                                                                                    </Select.Option>
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='ml-auto'>
                                                                        <Popconfirm title='Are you sure you want to permanently delete this question from the question bank?'
                                                                            onConfirm={(e) => {
                                                                                this.permanentlyRemoveQuestionAtIdx(questionDocIdx)
                                                                            }}
                                                                            disabled={this.state.selectedQuiz &&
                                                                                this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                                this.state.selectedQuiz.live}
                                                                            okText='Yes' cancelText='No'>
                                                                            <Button className='mr-1' type='danger' icon='close'
                                                                                size='large'
                                                                                disabled={
                                                                                    this.state.selectedQuiz &&
                                                                                    this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                                    this.state.selectedQuiz.live
                                                                                }
                                                                            />
                                                                        </Popconfirm>
                                                                        <Button icon='close' size='large' disabled={this.state.selectedQuiz &&
                                                                            this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                            this.state.selectedQuiz.live}
                                                                            onClick={(e) => {
                                                                                this.removeQuestionAtIdx(questionDocIdx)
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                                <div className='font-30 mb-1 flex flex-v-center'>
                                                                    <div>Question {questionDocIdx + 1} - (DOK {questionDoc.metadata.dokLevel})</div>
                                                                    <div className='ml-auto'>
                                                                        <Select size='large' value={questionDoc.metadata.dokLevel} onChange={(e) => {
                                                                            this.changeDOKLevel(questionDocIdx, e)
                                                                        }}>
                                                                            <Select.Option value={1}>DOK 1</Select.Option>
                                                                            <Select.Option value={2}>DOK 2</Select.Option>
                                                                            <Select.Option value={3}>DOK 3</Select.Option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                {questionDoc.metadata.hasOwnProperty('groupUuid') &&
                                                                    questionDoc.metadata.groupUuid ?
                                                                    <div>
                                                                        <div className='mb-2'>
                                                                            <div className='font-16'>Select passage:</div>
                                                                            <Select onChange={(e) => {
                                                                                this.onPassageChange(questionDocIdx, e)
                                                                            }} size='large' value={questionDoc.metadata.groupUuid}>
                                                                                {this.state.passages.map((passage) => {
                                                                                    return <Select.Option value={passage.id}>
                                                                                        {passage.components[0].content}
                                                                                    </Select.Option>
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                        <DocumentDelegator
                                                                            document={questionDoc}
                                                                            assessment={this.state.selectedQuiz}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <DocumentDelegator
                                                                        document={questionDoc}
                                                                        assessment={this.state.selectedQuiz}
                                                                    /*additionalData={{
                                                                    showAllReasons: true,
                                                                }}*/
                                                                    />}

                                                                <div className='mt-4'>
                                                                    <div className='font-16 font-bold'>
                                                                        This question is for the standard: {questionDoc.metadata.standard.standard}
                                                                    </div>
                                                                    {questionDoc.metadata.standard.hasOwnProperty('isPriority') &&
                                                                        questionDoc.metadata.standard.isPriority ?
                                                                        <div className='font-16'>*Priority Standard</div>
                                                                        : ''}
                                                                    <div className='mt-1 font-16'>{questionDoc.metadata.standard.title}</div>
                                                                </div>
                                                            </div>
                                                            {this.state.questionUUIDReviews &&
                                                                this.state.questionUUIDReviews.hasOwnProperty(questionDoc.metadata.uuid) &&
                                                                this.state.questionUUIDReviews[questionDoc.metadata.uuid] &&
                                                                this.state.questionUUIDReviews[questionDoc.metadata.uuid].length > 0 ?
                                                                <div>
                                                                    <div className='pl-0 pr-0'>
                                                                        {this.state.questionUUIDReviews[questionDoc.metadata.uuid].length + ' reviews'}
                                                                    </div>
                                                                    <div>
                                                                        {this.state.questionUUIDReviews[questionDoc.metadata.uuid].map((questionReview, questionReviewIdx) => {
                                                                            return <div className='mb-1 font-black font-20 border ant-shadow br-4 p-2'>

                                                                                {questionReview.hasOwnProperty('anonymous') &&
                                                                                    !questionReview.anonymous &&
                                                                                    questionReview.person ?
                                                                                    <Tooltip
                                                                                        title={questionReview.person.firstName + ' ' + questionReview.person.lastName}
                                                                                        placement='topLeft'
                                                                                    >
                                                                                        <div>
                                                                                            <PersonAvatar
                                                                                                person={questionReview.person}
                                                                                                avatarColor={'#d3adf7'}
                                                                                                avatarTextColor={'#000'}
                                                                                                containerClassName="font-16 mb-1"
                                                                                                avatarClassName="font-16 font-bold mr-1"
                                                                                                personClassName="font-black font-16 ellipsis max-w-110"
                                                                                            />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                    : ''}
                                                                                <div className='font-16 font-bold'>Question issues</div>
                                                                                <ul className='pl-3'>
                                                                                    {questionReview.reviewChoices.map((reviewChoice, reviewChocieIdx) => {
                                                                                        return <li className='font-16' key={questionDoc.metadata.uuid + '-' + reviewChocieIdx}>{reviewChoice}</li>
                                                                                    })}
                                                                                </ul>
                                                                                {questionReview.otherReviewMessage !== '' ?
                                                                                    <div>
                                                                                        <div className='font-16 font-bold'>Other review information</div>
                                                                                        <div className='font-16'>{questionReview.otherReviewMessage}</div>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>

                                                                        })}
                                                                    </div>                                                                                                </div>


                                                                : ''}
                                                        </div>
                                                    })}
                                                </div>}
                                        </div>
                                        <div className='mt-50'>
                                            <CustomFooter />
                                        </div>
                                    </div>
                                </Content>

                                <Sider
                                    width={270}
                                    style={{
                                        overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 85px)',
                                        position: 'fixed',
                                        right: 0, top: 85, borderLeft: '1px solid #f1f1f1',
                                        background: '#fff',
                                        zIndex: 10,
                                    }}>

                                    {!this.state.selectedQuiz ?
                                        <div></div> :
                                        <div className='p-2 font-20'>
                                            {/*<div className='font-bold mb-1 mt-2'>Title:</div>
                                                    <Input value={this.state.selectedQuiz &&
                                                        this.state.selectedQuiz.hasOwnProperty('assessmentTitle') ?
                                                        this.state.selectedQuiz.assessmentTitle : undefined} placeholder='Title...'
                                                        size='large'
                                                        onChange={(e) => {
                                                            let selectedQuiz = this.state.selectedQuiz
                                                            selectedQuiz.assessmentTitle = e.target.value
                                                            this.setState({
                                                                selectedQuiz: selectedQuiz,
                                                            })
                                                        }}
                                                        onBlur={(e) => {
                                                            this.updateQuiz({
                                                                assessmentTitle: e.target.value,
                                                            })
                                                        }} />*/}


                                            <div>
                                                <div className='font-bold mb-1'>Subject:</div>
                                                <Select onChange={this.onStandardCategoryChange}
                                                    value={this.state.selectedQuiz && this.state.selectedQuiz.hasOwnProperty('standardCategory') ?
                                                        this.state.selectedQuiz.standardCategory : undefined}
                                                    size='large' className='w-100'
                                                    disabled={this.state.selectedQuiz &&
                                                        this.state.selectedQuiz.hasOwnProperty('live') &&
                                                        this.state.selectedQuiz.live}>
                                                    {this.state.standardCategories.map((category, ind) => {
                                                        return <Select.Option key={'category-' + ind} value={category}>{category}</Select.Option>
                                                    })}
                                                </Select>
                                            </div>

                                            <div>
                                                <div className='font-bold mb-1 mt-2'>Grade/Class:</div>
                                                <Select onChange={this.onStandardNameChange}
                                                    value={this.state.selectedQuiz && this.state.selectedQuiz.hasOwnProperty('standardName') ?
                                                        this.state.selectedQuiz.standardName : undefined}
                                                    size='large'
                                                    disabled={this.state.selectedQuiz &&
                                                        this.state.selectedQuiz.hasOwnProperty('live') &&
                                                        this.state.selectedQuiz.live}
                                                    className='w-100'>
                                                    {this.state.standardNamesDict &&
                                                        this.state.standardNamesDict.hasOwnProperty(this.state.selectedQuiz.standardCategory) &&
                                                        this.state.standardNamesDict[this.state.selectedQuiz.standardCategory].map((standardName, ind) => {
                                                            return <Select.Option key={'grade-' + ind} value={standardName}>{standardName}</Select.Option>
                                                        })}
                                                </Select>
                                            </div>

                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <div>
                                                        <div className='font-bold mb-1 mt-2'>From week:</div>
                                                        <Select value={this.state.selectedQuiz && this.state.selectedQuiz.hasOwnProperty('startWeek') ?
                                                            this.state.selectedQuiz.startWeek : undefined}
                                                            className='w-100'
                                                            size='large'
                                                            disabled={
                                                                this.state.selectedQuiz &&
                                                                this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                this.state.selectedQuiz.live
                                                            }
                                                            onChange={(e) => {
                                                                let quiz = this.state.selectedQuiz
                                                                quiz.startWeek = e
                                                                this.setState({
                                                                    selectedQuiz: quiz,
                                                                })
                                                                this.updateQuiz({
                                                                    startWeek: e,
                                                                })
                                                            }}>
                                                            {this.state.weekNumbers.map((week) => {
                                                                return <Select.Option value={week}>{week}</Select.Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div>
                                                        <div className='font-bold mb-1 mt-2'>To week:</div>
                                                        <Select value={this.state.selectedQuiz && this.state.selectedQuiz.hasOwnProperty('endWeek') ?
                                                            this.state.selectedQuiz.endWeek : undefined}
                                                            className='w-100'
                                                            size='large'
                                                            disabled={this.state.selectedQuiz &&
                                                                this.state.selectedQuiz.hasOwnProperty('live') &&
                                                                this.state.selectedQuiz.live}
                                                            onChange={(e) => {
                                                                let quiz = this.state.selectedQuiz
                                                                quiz.endWeek = e
                                                                this.setState({
                                                                    selectedQuiz: quiz,
                                                                })
                                                                this.updateQuiz({
                                                                    endWeek: e,
                                                                })
                                                            }}>
                                                            {this.state.weekNumbers.map((week) => {
                                                                return <Select.Option value={week}>{week}</Select.Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Col>
                                            </Row>


                                            <div>
                                                <div className='font-bold mb-1 mt-2'>Standards:</div>
                                                <Select value={this.state.standard ? this.state.standard : undefined}
                                                    className='w-100'
                                                    placeholder='Select standard...'
                                                    size='large'
                                                    disabled={
                                                        this.state.selectedQuiz &&
                                                        this.state.selectedQuiz.hasOwnProperty('live') &&
                                                        this.state.selectedQuiz.live
                                                    }
                                                    onChange={(e) => {
                                                        this.onStandardChange(e)
                                                    }}>
                                                    {this.state.standards.map((standard) => {
                                                        if (this.state.standardsSelectMode === 'pacingGuide' &&
                                                            this.state.pacingGuide &&
                                                            !this.pacingGuideHasStandardInWeeks(standard.standard)) {
                                                            return false
                                                        }
                                                        return <Select.Option value={standard.standard}>
                                                            {standard.standard + (standard.isPriority ? ' (*Priority Standard)' : '')}
                                                        </Select.Option>
                                                    })}
                                                </Select>
                                            </div>

                                            <div>
                                                <div className='font-bold mb-1 mt-2'>Num questions to add:</div>
                                                <Select value={this.state.numQuestionsToAdd} size='large'
                                                    className='w-100' onChange={(e) => {
                                                        this.setState({
                                                            numQuestionsToAdd: e,
                                                        })
                                                    }}>
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
                                                        11, 12, 13, 14, 15, 16, 17, 18,
                                                        19, 20].map((num) => {
                                                            return <Select.Option value={num}>{num}</Select.Option>
                                                        })}
                                                </Select>
                                            </div>

                                            <div>
                                                <div className='font-bold mb-1 mt-2'>Add question:</div>
                                                <Button type='primary' size='large'
                                                    disabled={this.state.standard === '' || (
                                                        this.state.selectedQuiz &&
                                                        this.state.selectedQuiz.hasOwnProperty('live') &&
                                                        this.state.selectedQuiz.live
                                                    )}
                                                    onClick={this.addQuestion}>
                                                    <Icon type='plus' />
                                                    Add Question
                                                </Button>
                                            </div>

                                            <div className='pt-3 mt-3 border-top'>
                                                <div className='font-bold mb-1'>Shuffle questions:</div>
                                                <Button type='primary' size='large'
                                                    disabled={this.state.selectedQuiz &&
                                                        this.state.selectedQuiz.hasOwnProperty('live') &&
                                                        this.state.selectedQuiz.live}
                                                    onClick={this.shuffleQuiz}>
                                                    <Icon type='sync' />
                                                    Shuffle
                                                </Button>
                                            </div>

                                            {/*<div className='font-bold mb-1 mt-2'>Questions:</div>
                                                <Select value={this.state.numQuestions}
                                                    className='w-100'
                                                    size='large'
                                                    onChange={(e) => {
                                                        this.onNumQuestionsChange(e)
                                                    }}>
                                                    {this.state.questionsNumbers.map((question) => {
                                                        return <Select.Option value={question}>{question}</Select.Option>
                                                    })}
                                                </Select>*/}


                                        </div>}

                                </Sider>

                                {/*
                                <Modal
                                    title='Missing passage for this standard'
                                    visible={this.state.missingPassageModalVisible}
                                    onOk={this.hideMissingPassageModal}
                                    onCancel={this.hideMissingPassageModal}
                                    footer={[
                                        <Button key='cancel' onClick={this.hideMissingPassageModal}>
                                            Cancel
                                        </Button>,
                                        <Button key='ok' type='primary' onClick={this.hideMissingPassageModal}>
                                            Confirm
                                        </Button>,
                                    ]}
                                >
                                    <div className='font-16'>We currently do not have any questions for the passage {this.state.missingPassageForStandardInfo.passage_name} for standard {this.state.missingPassageForStandardInfo.standard}. Would you like us to pick a different passage for this standard?</div>
                                </Modal>
                                */}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </div>
        )
    }
}

export default QuizCreator