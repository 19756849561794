import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import SchoolAdminHeader from '../login/SchoolAdminHeader'
import SchoolAdminHome from './SchoolAdminHome'
import SchoolAdminSchoolSummary from './SchoolAdminSchoolSummary'
import SchoolAdminAddPerson from './SchoolAdminAddPerson'
import SchoolAdminSchoolView from './SchoolAdminSchoolView'
import SchoolAdminTeacherView from './SchoolAdminTeacherView'
import SchoolAdminStudentView from './SchoolAdminStudentView'
import SchoolAdminChatHome from './SchoolAdminChatHome'
import PacingGuide from '../teacher/pacingguide/PacingGuide'
//import VideoContainer from '../video/VideoContainer'
import {
  flattenDoc, getURLSplitArray, getEmptySchoolOrDistrictSummary,
  decompress, addArrays, summaryIndex, doesChatNotificationDictHaveNotifications
} from '../Util'
import ColType from '../Types'
import { Layout } from 'antd'
import WorkshopObservationToolBase from '../admin/WorkshopObservationToolBase'

const PacingGuideComponent = (props, state) => {
  return <PacingGuide teacherBaseProps={state} {...props} />
}

const SchoolAdminChatHomeComponent = (props, state) => {
  return <SchoolAdminChatHome schoolAdminBaseProps={state} {...props} />
}

const SchoolAdminHomeComponent = (props, state) => {
  return <SchoolAdminHome schoolAdminBaseProps={state} {...props} />
}

const SchoolAdminSchoolSummaryComponent = (props, state) => {
  return <SchoolAdminSchoolSummary schoolAdminBaseProps={state} {...props} />
}

const SchoolAdminAddPersonComponent = (props, state) => {
  return <SchoolAdminAddPerson schoolAdminBaseProps={state} {...props} />
}

const SchoolAdminSchoolViewComponent = (props, state) => {
  return <SchoolAdminSchoolView schoolAdminBaseProps={state} {...props} />
}

const SchoolAdminTeacherViewComponent = (props, state) => {
  return <SchoolAdminTeacherView schoolAdminBaseProps={state} {...props} />
}

const SchoolAdminStudentViewComponent = (props, state) => {
  return <SchoolAdminStudentView schoolAdminBaseProps={state} {...props} />
}

const WorkshopObservationToolBaseComponent = (props, state) => {
  let districtId = ''
  let schoolId = ''
  let authorId = ''
  if (state.district &&
    state.school &&
    state.schoolAdmin) {
    districtId = state.district.id
    schoolId = state.school.id
    authorId = state.schoolAdmin.id
  }

  return <WorkshopObservationToolBase schoolAdminBaseProps={state} {...props}
    districtId={districtId}
    schoolId={schoolId}
    authorId={authorId}
    authorType={ColType.schoolAdmin}
  />
}

class SchoolAdminBase extends Component {
  state = {
    schoolAdminId: '',
    schoolAdmin: null,
    districtId: '',
    district: null,
    school: null,
    schoolAdministrators: null,
    schoolTeachers: [],
    schoolTeachersDict: {},
    schoolSummary: null,
    students: [],
    studentDict: {},
    iepGoals: [],
    iepDict: null,
    schools: [],
    schoolsDict: {},
    schoolTypes: null,
    schoolStats: null,
    records: [],
    loadingData: true,
    pathId: '',
    headerKey: '',
    grade: 'all',
    subject: 'all',
    raceOrEthnicity: 'all',
    grades: ['all', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    subjects: ['all', 'Writing', 'Reading Comprehension in Literature',
      'Reading Comprehension in Informational Text', 'Reading Foundations',
      'Math', 'Social Emotional Learning'],
    racesOrEthnicities: ['all', 'Asian', 'Black or African American',
      'Hispanic or Latino',
      'Native American or Alaska Native',
      'Native Hawaiian or Other Pacific Islander',
      'White'],
    supportLevels: ['h', 'm', 'l', 'total', 'totalWithMeasurements', 'onTrack'],
    setCurrentlyViewingChatIdFunc: null,
    chatNotificationDict: {},
    hasChatNotifications: false,
    authListener: null,
    districtListener: null,
    chatNotificationListener: null,
  }

  // Do fetch here
  componentDidMount() {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]
    var schoolAdminId = split[split.length - 1]

    this.setState({
      setCurrentlyViewingChatIdFunc: this.setCurrentlyViewingChatId
    })

    let authListener = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        if (this.state.user &&
          this.state.user.hasOwnProperty('districtId') &&
          this.state.user.districtId === 'vYTvkDwoLx19NkA6dJO8') {
          this.props.history.push(
            {
              pathname: '/beta/sign-in/'
            }
          )
        } else {
          // No user is signed in.
          this.props.history.push(
            {
              pathname: '/sign-in/'
            }
          )
        }
      }
      else {
        db.collection(ColType.users)
          .doc(user.uid)
          .get()
          .then((doc) => {
            var userObj = doc.data()
            userObj.id = doc.id
            userObj.uid = user.uid
            userObj.signedIn = true
            this.setState({ user: userObj }, () => {
              if (this.state.user &&
                this.state.user.hasOwnProperty('isLocked') &&
                this.state.user.isLocked) {
                this.props.history.push(
                  {
                    pathname: '/account-is-locked'
                  }
                )
              }
            })
          })
      }
    })
    this.setState({ authListener: authListener })

    db.collection(ColType.schoolAdmin)
      .doc(schoolAdminId)
      .get()
      .then((doc) => {
        var schoolAdmin = flattenDoc(doc)
        this.setState({
          pathId: pathId,
          schoolAdminId: schoolAdminId,
          schoolAdmin: schoolAdmin,
          districtId: schoolAdmin.districtId,
          schoolId: schoolAdmin.schoolId,
        }, () => {
          db.collection(ColType.schoolStats)
            .doc(this.state.schoolAdmin.schoolId)
            .get()
            .then((doc) => {
              if (!doc.exists) {
                return false
              }
              var schoolStats = flattenDoc(doc)

              this.setState({
                schoolStats: schoolStats,
              })
            })

          db.collection(ColType.schoolSummary)
            .where('districtId', '==', this.state.schoolAdmin.districtId)
            .where('schoolId', '==', this.state.schoolAdmin.schoolId)
            .orderBy('shardIndex')
            .get()
            .then((querySnapshot) => {
              var schoolSummary = getEmptySchoolOrDistrictSummary()

              querySnapshot.forEach((doc) => {
                var schoolShard = flattenDoc(doc)
                schoolShard.summary = decompress(schoolShard.summary)
                schoolSummary = addArrays(schoolSummary, schoolShard.summary.summary)
              })

              this.state.grades.map((grade, idx) => {
                var subject = 'all'
                var allOnTrackIdx = summaryIndex(grade,
                  subject, 'all', 'onTrack')
                var allTotalWithMeasurementsIdx = summaryIndex(grade,
                  subject, 'all', 'totalWithMeasurements')
                var onTrackCount = 0
                var totalWithMeasurementsCount = 0

                this.state.racesOrEthnicities.map((race, idx) => {
                  if (idx === 0) return false
                  var totalWithMeasurements =
                    schoolSummary[summaryIndex(grade,
                      subject, race, 'totalWithMeasurements')]
                  if (totalWithMeasurements < 0) totalWithMeasurements = 0
                  var onTrack = schoolSummary[summaryIndex(grade,
                    subject, race, 'onTrack')]
                  if (onTrack < 0) onTrack = 0
                  onTrackCount += onTrack
                  totalWithMeasurementsCount += Math.max(onTrack, totalWithMeasurements)
                  return false
                })

                if (onTrackCount > totalWithMeasurementsCount)
                  onTrackCount = totalWithMeasurementsCount
                schoolSummary[allOnTrackIdx] = onTrackCount
                schoolSummary[allTotalWithMeasurementsIdx] = totalWithMeasurementsCount
                return false
              })

              this.setState({
                schoolSummary: schoolSummary,
                loadingData: false,
              })
            })

          let districtListener = db.collection(ColType.district)
            .doc(this.state.schoolAdmin.districtId)
            .onSnapshot((doc) => {
              var district = flattenDoc(doc)
              this.setState({
                district: district,
              })
            })
          if (this.state.districtListener) {
            this.state.districtListener()
          }
          this.setState({ districtListener: districtListener })

          db.collection(ColType.school)
            .doc(this.state.schoolAdmin.schoolId)
            .get()
            .then((doc) => {
              var school = flattenDoc(doc)
              this.setState({
                school: school,
              })
            })

          db.collection(ColType.school)
            .where('districtId', '==', this.state.schoolAdmin.districtId)
            .get()
            .then((querySnapshot) => {
              var schools = []
              var schoolsDict = {}

              querySnapshot.forEach((doc) => {
                var school = flattenDoc(doc)
                schools.push(school)
                schoolsDict[school.id] = school
              })

              this.setState({
                schools: schools,
                schoolsDict: schoolsDict,
              })
            })

          db.collection(ColType.schoolAdmin)
            .where('districtId', '==', this.state.schoolAdmin.districtId)
            .where('schoolId', '==', this.state.schoolAdmin.schoolId)
            .get()
            .then((querySnapshot) => {
              var schoolAdministrators = []

              querySnapshot.forEach((doc) => {
                var schoolAdministrator = flattenDoc(doc)
                schoolAdministrators.push(schoolAdministrator)
              })

              this.setState({
                schoolAdministrators: schoolAdministrators
              })
            })

          db.collection(ColType.teacher)
            .where('districtId', '==', this.state.schoolAdmin.districtId)
            .where('schoolId', '==', this.state.schoolAdmin.schoolId)
            .get()
            .then((querySnapshot) => {
              var schoolTeachers = []
              var schoolTeachersDict = {}

              querySnapshot.forEach((doc) => {
                var teacher = flattenDoc(doc)
                schoolTeachers.push(teacher)
                schoolTeachersDict[teacher.id] = teacher
              })

              this.setState({
                schoolTeachers: schoolTeachers,
                schoolTeachersDict: schoolTeachersDict,
              })
            })

          db.collection(ColType.student)
            .where('districtId', '==', this.state.schoolAdmin.districtId)
            .where('schoolId', '==', this.state.schoolAdmin.schoolId)
            .get()
            .then((querySnapshot) => {
              var students = []
              var studentDict = {}

              querySnapshot.forEach((doc) => {
                var student = flattenDoc(doc)
                students.push(student)
                studentDict[student.id] = student
              })

              students.sort((a, b) => {
                if (a.grade === 'K' && b.grade === 'K') {
                  if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                    return 1
                  } else {
                    return -1
                  }
                }
                if (a.grade === 'K' && b.grade !== 'K') return -1
                if (a.grade !== 'K' && b.grade === 'K') return 1
                if (a.grade === b.grade) {
                  if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                    return 1
                  } else {
                    return -1
                  }
                }
                var aGrade = parseInt(a.grade, 10)
                var bGrade = parseInt(b.grade, 10)
                if (aGrade > bGrade) return 1
                if (bGrade > aGrade) return -1
                return 0
              })

              this.setState({
                students: students,
                studentDict: studentDict,
              })
            })

          db.collection(ColType.iep)
            .where('districtId', '==', this.state.schoolAdmin.districtId)
            .where('schoolId', '==', this.state.schoolAdmin.schoolId)
            .get()
            .then((querySnapshot) => {
              var iepGoals = []
              var iepDict = {}

              querySnapshot.forEach((doc) => {
                var iepGoal = flattenDoc(doc)
                iepGoals.push(iepGoal)
                iepDict[iepGoal.id] = iepGoal
              })

              iepGoals.sort((a, b) => {
                var aTier = a.iep.tier
                var bTier = b.iep.tier
                if (aTier === bTier) return 0
                else if (aTier === 'Tier 1') return 1
                else return -1
              })

              this.setState({
                iepGoals: iepGoals,
                iepDict: iepDict,
              })
            })

        })
      })

    var chatNotificationListener = db.collection(ColType.chatNotification)
      .doc(schoolAdminId)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          return
        }
        let chatNotificationDict = flattenDoc(doc)

        this.setState({
          chatNotificationDict: chatNotificationDict,
          hasChatNotifications: doesChatNotificationDictHaveNotifications(chatNotificationDict, this.state.currentlyViewingChatId)
        })
      })

    this.setState({
      chatNotificationListener: chatNotificationListener,
    })
  }

  componentWillUnmount() {
    if (this.state.authListener) {
      this.state.authListener()
    }
    if (this.state.districtListener) {
      this.state.districtListener()
    }
    if (this.state.chatNotificationListener) {
      this.state.chatNotificationListener()
    }
  }

  componentWillReceiveProps(props, newProps) {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]

    this.setState({
      pathId: pathId,
    })
  }

  render() {
    const { match: { url } } = this.props

    return (
      <Layout>
        <SchoolAdminHeader
          person={this.state.schoolAdmin}
          selectedKey={this.state.pathId}
          history={this.props.history}
          schoolAdminBaseProps={this.state}
        >
        </SchoolAdminHeader>

        {/*<VideoContainer />*/}

        <Route
          path={`${url}/pacing-guide/`}
          render={props => PacingGuideComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-admin-home/*`}
          render={props => SchoolAdminHomeComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-summary/*`}
          render={props => SchoolAdminSchoolSummaryComponent(props, this.state)}
        />
        <Route
          path={`${url}/add-person/*`}
          render={props => SchoolAdminAddPersonComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-admin-school/*`}
          render={props => SchoolAdminSchoolViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-admin-teacher/*`}
          render={props => SchoolAdminTeacherViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-admin-student/*`}
          render={props => SchoolAdminStudentViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-admin-workshop-observation-tool/`}
          render={props => WorkshopObservationToolBaseComponent(props, this.state)}
        />
        <Route
          path={`${url}/teams/*`}
          render={props =>
            SchoolAdminChatHomeComponent(props, this.state)}
        />
      </Layout>
    )
  }
}

export default SchoolAdminBase