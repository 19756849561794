import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import Empty from '../../customcomponents/Empty'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import { flattenDoc, getIDFromURL } from '../../Util'
import ColType from '../../Types'
import { Layout, Icon, Button, Input, notification, Popconfirm, Select, Radio } from 'antd'
import { db, firebase } from '../../firebase/Firebase'
import TextArea from 'antd/lib/input/TextArea'
const { Content } = Layout
var moment = require('moment')

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class TeacherPLC extends Component {
    state = {
        howWillWeTeachItDifferently: '',
        grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        subjectAreas: ['ELA', 'Math', 'Science', 'Social Studies'],
        whyAreWeNotOnPace: '',
        plcs: [],
        plcsDict: {},
        plcListener: null,
        dataRetrieved: false,
        selectedPLC: null,
    }
    // Do fetch here
    componentDidMount() {
        document.title = 'PLC - Dot It'
        this.getData()
    }

    componentWillUnmount() {
        if (this.state.plcListener) {
            this.state.plcListener()
        }
    }

    getData = () => {
        const teacherId = getIDFromURL(window.location)
        if (!teacherId) {
            return
        }

        let plcListener = db.collection(ColType.plc)
            .where('teacherId', '==', teacherId)
            .orderBy('timeStamp', 'desc')
            .onSnapshot((querySnapshot) => {
                let plcs = []
                let plcsDict = {}
                querySnapshot.forEach((doc) => {
                    const plc = flattenDoc(doc)
                    plcs.push(plc)
                    plcsDict[plc.id] = plc
                    plc.edit = !plc.initialSubmit
                })
                let stateObj = {
                    plcs: plcs,
                    plcsDict: plcsDict,
                    dataRetrieved: true
                };
                if (!this.state.selectedPLC && plcs.length > 0) {
                    stateObj.selectedPLC = plcs[0]
                    stateObj.howWillWeTeachItDifferently = plcs[0].howWillWeTeachItDifferently
                }
                this.setState(stateObj)
            })

        this.setState({
            plcListener: plcListener
        })
    }

    setAreWeOnPace = (onPace) => {
        if (!this.state.selectedPLC) {
            return
        }
        let nextStep = 'Are we on pace set'
        let selectedPLC = this.state.selectedPLC
        selectedPLC.onPace = onPace
        selectedPLC.step = nextStep
        this.setState({
            selectedPLC: selectedPLC
        });
    }

    setWhyNotOnPace = (reason) => {
        if (!this.state.selectedPLC) {
            return
        }
        let nextStep = 'Why not reason selected'
        let selectedPLC = this.state.selectedPLC
        selectedPLC.reason = reason
        selectedPLC.step = nextStep
        this.setState({
            selectedPLC: selectedPLC
        });
    }

    setDoWeNeedToReteach = (reteach) => {
        if (!this.state.selectedPLC) {
            return
        }
        let nextStep = 'How will we teach it differently'
        if (!reteach) {
            nextStep = 'Evaluate exemplars'
        }
        let selectedPLC = this.state.selectedPLC
        selectedPLC.reteach = reteach
        selectedPLC.step = nextStep
        this.setState({
            selectedPLC: selectedPLC
        });
    }

    setHowWillWeTeachItDifferently = () => {
        if (!this.state.selectedPLC) {
            return
        }
        let nextStep = 'Evaluate exemplars'
        let selectedPLC = this.state.selectedPLC
        selectedPLC.howWillWeTeachItDifferently = this.state.howWillWeTeachItDifferently
        selectedPLC.step = nextStep
        this.setState({
            selectedPLC: selectedPLC,
        })
    }

    setGrade = (grade) => {
        if (!this.state.selectedPLC) {
            return
        }
        let selectedPLC = this.state.selectedPLC
        let step = ''
        if (selectedPLC.subjectArea) {
            step = 'Are we on pace'
        }
        selectedPLC.grade = grade
        selectedPLC.step = step
        this.setState({
            selectedPLC: selectedPLC
        })
    }

    setSubjectArea = (subjectArea) => {
        if (!this.state.selectedPLC) {
            return
        }
        let selectedPLC = this.state.selectedPLC
        let step = ''
        if (selectedPLC.grade) {
            step = 'Are we on pace'
        }
        selectedPLC.subjectArea = subjectArea
        selectedPLC.step = step
        this.setState({
            selectedPLC: selectedPLC
        });
    }

    updatePLC = (plc) => {
        plc.edit = false
        plc.initialSubmit = true
        this.setState({
            selectedPLC: plc
        })
        let plcCopy = Object.assign({}, plc)
        delete plcCopy.id
        delete plcCopy.timeStamp

        db.collection(ColType.plc)
            .doc(plc.id)
            .update(plcCopy)
            .then(() => {
                console.log('PLC updated successfully.')
            })
            .catch((error) => {
                console.log('Error updating PLC. Please try again.')
            })
    }

    createPLC = () => {
        if (!(this.props.teacherBaseProps && this.props.teacherBaseProps.teacher)) {
            return
        }

        db.collection(ColType.plc)
            .add({
                teacherId: this.props.teacherBaseProps.teacher.id,
                step: '',
                onPace: '',
                grade: '',
                subjectArea: '',
                whyAreWeNotOnPace: '',
                studentsHaveMastered: '',
                studentsNeedToWorkOn: '',
                resourcesToReteach: '',
                extendLearning: '',
                initialSubmit: false,
                edit: true,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((docRef) => {
                if (this.state.plcsDict &&
                    this.state.plcsDict.hasOwnProperty(docRef.id)) {
                    this.setState({
                        selectedPLC: this.state.plcsDict[docRef.id]
                    })
                }
            })
    }

    deletePLC = (plcId) => {
        db.collection(ColType.plc)
            .doc(plcId)
            .delete()
            .then(() => {
                this.setState({
                    selectedPLC: null
                })

                successMessage('PLC deleted successfully.')
            })
            .catch((error) => {
                errorMessage('Error deleting PLC. Please try again.')
            })
    }

    render() {
        const rightMenuWidth = 250;
        // remove do we need to reteach
        // after are we on pace yes/no
        // If no, then "Why are we not on pace?"
        // Always:
        /*
            According to our assessments, our students have mastered:
            According to our assessments, our students still need to work on:
            The resources we will use to reteach are:
            To extend learning for students who are proficient, we will:
        */
        // get rid of next steps
        return (
            <div className="background-fff-important">
                <Layout className="content layout-header-mt background-fff-important min-w-1024">
                    <Layout className="background-fff-important">
                        <Content className="layout-content">

                            <div className="sub-menu-width m-lr-auto font-20" style={{ width: '1024px' }}>
                                <div className='flex w-100'>
                                    <div style={{ width: 'calc(100% - ' + (rightMenuWidth - 1) + 'px' }}>
                                        {this.props.teacherBaseProps.teacher ?
                                            <div className='w-100'>
                                                {!this.state.selectedPLC ?
                                                    <div className='w-100'>
                                                        <div className='text-center'>
                                                            <h2>Create or select a PLC.</h2>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='w-100'>
                                                        <div className='flex mb-2'>
                                                            <div>
                                                                <div className='font-30'>PLC Meeting Notes</div>
                                                                <div className='font-24 text-muted'>
                                                                    {this.state.selectedPLC.timeStamp &&
                                                                        this.state.selectedPLC.timeStamp.seconds ?
                                                                        moment(this.state.selectedPLC.timeStamp.seconds * 1000).format('MM/DD/YYYY')
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                            <div className='ml-auto'>
                                                                <Popconfirm title="Are you sure you want to delete this PLC?"
                                                                    onConfirm={(e) => {
                                                                        this.deletePLC(this.state.selectedPLC.id)
                                                                    }}
                                                                    onCancel={(e) => { }}
                                                                    okText="Yes"
                                                                >
                                                                    <Button className='lg-btn'>
                                                                        <div className='flex flex-v-center'>
                                                                            <Icon type='delete' className='mr-1' />
                                                                            <span>Delete PLC Meeting Notes</span>
                                                                        </div>
                                                                    </Button>
                                                                </Popconfirm>
                                                            </div>
                                                        </div>

                                                        {!this.state.selectedPLC.edit ?
                                                            <div>
                                                                <div className='mb-2 br-4 ant-shadow border p-3 relative'>
                                                                    <div className='flex w-100 mb-2'>
                                                                        <div>
                                                                            <div className='font-bold'>Grade:</div>
                                                                            <div>{this.state.selectedPLC.grade}</div>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            <Button className='lg-btn flex flex-v-center'
                                                                                onClick={(e) => {
                                                                                    let plc = this.state.selectedPLC
                                                                                    plc.edit = true
                                                                                    this.setState({
                                                                                        selectedPLC: plc
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <Icon type='edit' className='mr-1' />
                                                                                <div>
                                                                                    Edit
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <div className='font-bold'>Subject area:</div>
                                                                        <div>{this.state.selectedPLC.subjectArea}</div>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <div className='font-bold'>Are we on pace?</div>
                                                                        <div>{this.state.selectedPLC.onPace ? 'Yes' : 'No'}</div>
                                                                    </div>
                                                                    {!this.state.selectedPLC.onPace ?
                                                                        <div className='mb-2'>
                                                                            <div className='font-bold'>Why are we not on pace?</div>
                                                                            <div>{this.state.selectedPLC.whyAreWeNotOnPace ?
                                                                                this.state.selectedPLC.whyAreWeNotOnPace : 'N/A'}</div>
                                                                        </div>
                                                                        : ''}
                                                                    <div className='mb-2'>
                                                                        <div className='font-bold'>According to our assessments, our students have mastered:</div>
                                                                        <div>{this.state.selectedPLC.studentsHaveMastered ?
                                                                            this.state.selectedPLC.studentsHaveMastered : 'N/A'}</div>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <div className='font-bold'>According to our assessments, our students still need to work on:</div>
                                                                        <div>{this.state.selectedPLC.studentsNeedToWorkOn ?
                                                                            this.state.selectedPLC.studentsNeedToWorkOn : 'N/A'}</div>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <div className='font-bold'>The resources we will use to reteach are:</div>
                                                                        <div>{this.state.selectedPLC.resourcesToReteach ?
                                                                            this.state.selectedPLC.resourcesToReteach : 'N/A'}</div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='font-bold'>To extend learning for students who are proficient, we will:</div>
                                                                        <div>{this.state.selectedPLC.extendLearning ?
                                                                            this.state.selectedPLC.extendLearning : 'N/A'}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div>
                                                                    <div className='mb-2 flex w-100'>
                                                                        <div className='mr-1'>
                                                                            <div className='font-bold mb-05'>Grade</div>
                                                                            <Select
                                                                                size='large'
                                                                                value={this.state.selectedPLC.grade === '' ? undefined : this.state.selectedPLC.grade}
                                                                                placeholder='Select grade...'
                                                                                onChange={(value) => {
                                                                                    this.setGrade(value)
                                                                                }}
                                                                                style={{ width: 120 }}>
                                                                                {this.state.grades.map((grade, idx) => {
                                                                                    return <Select.Option value={grade}>{grade}</Select.Option>
                                                                                })}
                                                                            </Select>
                                                                        </div>

                                                                        <div>
                                                                            <div className='font-bold mb-05'>Subject area</div>
                                                                            <Select
                                                                                size='large'
                                                                                value={this.state.selectedPLC.subjectArea === '' ? undefined : this.state.selectedPLC.subjectArea}
                                                                                placeholder='Select subject area...'
                                                                                onChange={(value) => {
                                                                                    this.setSubjectArea(value)
                                                                                }}
                                                                                style={{ width: 120 }}>
                                                                                {this.state.subjectAreas.map((subjectArea, idx) => {
                                                                                    return <Select.Option value={subjectArea}>{subjectArea}</Select.Option>
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='mb-2'>
                                                                    <div className='mb-05 font-bold'>Are we on pace?</div>
                                                                    <div className='flex flex-v-center'>
                                                                        <Radio.Group
                                                                            size='large'
                                                                            value={this.state.selectedPLC.onPace === '' ? undefined : this.state.selectedPLC.onPace}
                                                                            onChange={(e) => {
                                                                                this.setAreWeOnPace(e.target.value)
                                                                            }}
                                                                        >
                                                                            <Radio.Button value={false}>No</Radio.Button>
                                                                            <Radio.Button value={true}>Yes</Radio.Button>
                                                                        </Radio.Group>
                                                                    </div>
                                                                </div>
                                                                <div>


                                                                    {this.state.selectedPLC.onPace !== '' && !this.state.selectedPLC.onPace ?
                                                                        <div className='mb-2'>
                                                                            <div className='font-bold mb-05'>Why are we not on pace?</div>
                                                                            <TextArea rows={4}
                                                                                value={this.state.selectedPLC.whyAreWeNotOnPace}
                                                                                onChange={(e) => {
                                                                                    let plc = this.state.selectedPLC
                                                                                    plc.whyAreWeNotOnPace = e.target.value
                                                                                    this.setState({
                                                                                        selectedPLC: plc
                                                                                    })
                                                                                }}
                                                                                placeholder='Write here...'
                                                                            />
                                                                        </div>
                                                                        : ''}

                                                                    <div className='mb-2'>
                                                                        <div className='font-bold mb-05'>According to our assessments, our students have mastered:</div>
                                                                        <TextArea rows={4}
                                                                            value={this.state.selectedPLC.studentsHaveMastered}
                                                                            onChange={(e) => {
                                                                                let plc = this.state.selectedPLC
                                                                                plc.studentsHaveMastered = e.target.value
                                                                                this.setState({
                                                                                    selectedPLC: plc
                                                                                })
                                                                            }}
                                                                            placeholder='Write here...'
                                                                        />
                                                                    </div>

                                                                    <div className='mb-2'>
                                                                        <div className='font-bold mb-05'>According to our assessments, our students still need to work on:</div>
                                                                        <TextArea rows={4}
                                                                            value={this.state.selectedPLC.studentsNeedToWorkOn}
                                                                            onChange={(e) => {
                                                                                let plc = this.state.selectedPLC
                                                                                plc.studentsNeedToWorkOn = e.target.value
                                                                                this.setState({
                                                                                    selectedPLC: plc
                                                                                })
                                                                            }}
                                                                            placeholder='Write here...'
                                                                        />
                                                                    </div>

                                                                    <div className='mb-2'>
                                                                        <div className='font-bold mb-05'>The resources we will use to reteach are:</div>
                                                                        <TextArea rows={4}
                                                                            value={this.state.selectedPLC.resourcesToReteach}
                                                                            onChange={(e) => {
                                                                                let plc = this.state.selectedPLC
                                                                                plc.resourcesToReteach = e.target.value
                                                                                this.setState({
                                                                                    selectedPLC: plc
                                                                                })
                                                                            }}
                                                                            placeholder='Write here...'
                                                                        />
                                                                    </div>

                                                                    <div className='mb-2'>
                                                                        <div className='font-bold mb-05'>To extend learning for students who are proficient, we will:</div>
                                                                        <TextArea rows={4}
                                                                            value={this.state.selectedPLC.extendLearning}
                                                                            onChange={(e) => {
                                                                                let plc = this.state.selectedPLC
                                                                                plc.extendLearning = e.target.value
                                                                                this.setState({
                                                                                    selectedPLC: plc
                                                                                })
                                                                            }}
                                                                            placeholder='Write here...'
                                                                        />
                                                                    </div>

                                                                    <div className='flex flex-v-center w-100 mt-2'>
                                                                        <Button className='ml-auto lg-btn'
                                                                            type='primary'
                                                                            onClick={(e) => {
                                                                                this.updatePLC(this.state.selectedPLC)
                                                                            }}>
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                    </div>}
                                            </div>

                                            : ''}
                                    </div>
                                    <div
                                        className='pl-3 ml-3 border-left-lighter ml-auto'
                                        style={{ width: '250px' }}>
                                        <Button
                                            type='primary'
                                            className='lg-btn'
                                            onClick={(e) => {
                                                this.createPLC()
                                            }}
                                        >
                                            <Icon type='plus' />
                                            Create PLC
                                        </Button>
                                        <div className='mt-2 pt-2 border-top-lighter'>
                                            {this.state.dataRetrieved &&
                                                this.state.plcs.length === 0 ?
                                                <div className="text-center w-100">
                                                    <Empty
                                                        containerClassName="mt-4 pt-2 text-center font-16"
                                                        width={55}
                                                        height={55}
                                                        description={'You currently have no PLCs.'}
                                                        image={'/empty/students.png'}
                                                    />
                                                </div>
                                                : ''}
                                            {this.state.dataRetrieved &&
                                                this.state.plcs.map((plc, idx) => {
                                                    return <div key={plc.id}>
                                                        <div className={"p-1 br-20 background-fff cursor-pointer mb-1 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center" +
                                                            (this.state.selectedPLC && this.state.selectedPLC.id === plc.id ? ' side-menu-item-active' : '')}
                                                            onClick={(e) => {
                                                                this.setState({
                                                                    selectedPLC: plc
                                                                })
                                                            }}
                                                        >
                                                            <div className='w-100'>
                                                                <div>PLC Meeting Notes</div>
                                                                <div className='text-muted font-16'>
                                                                    {plc.timeStamp && plc.timeStamp.seconds ? moment(plc.timeStamp.seconds * 1000).format('MM/DD/YYYY') : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default TeacherPLC