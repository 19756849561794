import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
//import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Form, Input, Button, Icon, Modal, notification, Row, Col, Checkbox } from 'antd'
import { getStateStandardNamesDict } from '../Util'
import QuizCreator from './QuizCreator'
const CheckboxGroup = Checkbox.Group
const FormItem = Form.Item
const TextArea = Input.TextArea

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class QuizCreatorForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        standards: [],
        standardsDict: {},
        startWeek: 1,
        endWeek: 3,
        grade: 3,
        standardDocsDict: {},
        questionsNumbers: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
        numQuestions: 5,
        weekNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
            11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36
        ],
        startWeek: 1,
        endWeek: 3,
        quizzes: [],
        quizzesDict: {},
        quizzesGroupedByWeek: {},
        passages: [],
        standardCategory: 'ELA',
        standardName: '3rd Grade',
        selectedQuiz: null,
        quizListener: null,
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        standardNamesDict: {},
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        grades: [
            'Kindergarten',
            '1st Grade',
            '2nd Grade',
            '3rd Grade',
            '4th Grade',
            '5th Grade',
            '6th Grade',
            '7th Grade',
            '8th Grade',
        ],
        pacingGuide: null,
        standard: '',
        step: 0,
        selectedStandardCategories: [],
        selectedStandardNamesDict: {},
        standardCategoryStep: 0,
    }

    componentDidMount() {
        this.setState({
            standardNamesDict: getStateStandardNamesDict(this.props.state)
        })
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleOk = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values)

                this.setState({
                    submitting: true,
                })
            }
        })
    }

    onStandardCategoryChange = (checkedValues) => {
        this.setState({
            selectedStandardCategories: checkedValues,
        })
    }

    onStandardNameChange = (standardCategory, checkedValues) => {
        if (!standardCategory) {
            return
        }
        const selectedStandardNamesDict = this.state.selectedStandardNamesDict
        selectedStandardNamesDict[standardCategory] = checkedValues
        this.setState({
            selectedStandardNamesDict: selectedStandardNamesDict,
        })
    }

    getCurrentStandardCategoryStep = () => {
        if (!this.state.selectedStandardCategories) {
            return ''
        }
        if (this.state.standardCategoryStep < 0 || this.state.standardCategoryStep >= this.state.selectedStandardCategories.length) {
            return ''
        }
        return this.state.selectedStandardCategories[this.state.standardCategoryStep]
    }

    render() {
        console.log(this.state)
        return (
            <div className='ml-auto'>
                <Button type="primary" className="lg-btn" onClick={this.showModal}>
                    <Icon type="plus" /> Add assessment
                </Button>
                <Modal
                    title="Add Assessments"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    {this.state.step === 0 ?
                        <div>
                            <CheckboxGroup onChange={this.onStandardCategoryChange} className='font-16'>
                                <Checkbox size='large' className='ml-0 flex flex-v-center' value='all'><div className='pl-1 font-black font-normal'>All Subjects</div></Checkbox>
                                {this.state.standardCategories.map((category) => {
                                    return <Checkbox size='large' className='ml-0 flex flex-v-center' value={category}><div className='pl-1 font-black font-normal'>{category}</div></Checkbox>
                                })}
                            </CheckboxGroup>

                            <div className='flex w-100'>
                                <Button
                                    type="primary"
                                    className="lg-btn ml-auto"
                                    disabled={this.state.selectedStandardCategories.length === 0}
                                    onClick={(e) => {
                                        this.setState({
                                            step: 1,
                                        })
                                    }}
                                >
                                    <span>Continue</span>
                                </Button>
                            </div>
                        </div> : ''}

                    {this.state.step === 1 ?
                        <div>
                            <CheckboxGroup values={this.state.selectedStandardNamesDict.hasOwnProperty(this.getCurrentStandardCategoryStep()) ?
                                this.state.selectedStandardNamesDict[this.getCurrentStandardCategoryStep()] : []}
                                onChange={(e) => this.onStandardNameChange(this.getCurrentStandardCategoryStep(), e)} className='font-16'>
                                <Checkbox size='large' className='ml-0 flex flex-v-center' value='all'><div className='pl-1 font-black font-normal'>All Subjects</div></Checkbox>
                                {this.state.standardNamesDict[this.getCurrentStandardCategoryStep()].map((standardName) => {
                                    return <Checkbox size='large' className='ml-0 flex flex-v-center' value={standardName}>
                                        <div className='pl-1 font-black font-normal'>{standardName}</div></Checkbox>
                                })
                                }
                            </CheckboxGroup>

                            <div className='flex w-100'>
                                <Button
                                    type="primary"
                                    className="lg-btn ml-auto"
                                    disabled={!this.state.selectedStandardNamesDict.hasOwnProperty(this.getCurrentStandardCategoryStep()) ||
                                        (this.state.selectedStandardNamesDict.hasOwnProperty(this.getCurrentStandardCategoryStep()) &&
                                            this.state.selectedStandardNamesDict[this.getCurrentStandardCategoryStep()].length === 0)}
                                    onClick={(e) => {
                                        let standardCategoryStep = this.state.standardCategoryStep + 1
                                        let step = this.state.step
                                        if (standardCategoryStep === this.state.selectedStandardCategories.length) {
                                            step = 2
                                        }
                                        this.setState({
                                            standardCategoryStep: standardCategoryStep,
                                            step: step
                                        })
                                    }}
                                >
                                    <span>Continue</span>
                                </Button>
                            </div>
                        </div> : ''}
                </Modal>
            </div>
        )
    }
}

export default QuizCreatorForm