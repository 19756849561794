import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import IEPDrafts from './IEPDrafts'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import {
  flattenDoc, getIDFromURL, getTotalPoints,
  getQueryStringParam, createMTSSGoalText, awardsIncGoals, FOLDER_CURRENT,
  createSELPresentLevelTextMTSS, createMathPresentLevelTextMTSS,
  createELAPresentLevelTextMTSS, getSpecialEducationRemovalStatement
} from '../.././Util'
import { Layout, Icon, Button, Form, DatePicker } from 'antd'
import DraftStepContainer from '../addiep/DraftStepContainer'
const { Content } = Layout
const FormItem = Form.Item

/*const errorMessage = (description) => {
  message.error(description)
}*/

class TeacherAddIEPGoalConfirm extends Component {
  state = {
    teacher: null,
    iep: null,
    iepParagraph: '',
    totalPoints: null,
    students: [],
    school: null,
    completionDateString: '',
    iepDraft: null,
    tabKey: '1',
    stepNum: 6,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var draftId = getQueryStringParam('draft')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.pepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          var draft = flattenDoc(doc)
          this.setState({
            iepDraft: draft,
            iepSelections: draft.iepSelectionsNoCount,
            ieps: draft.ieps,
            iepDraftMounted: true,
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)
        this.setState({
          teacher: teacher,
        })

        db.collection(ColType.school)
          .doc(teacher.schoolId)
          .get()
          .then((doc) => {
            var school = flattenDoc(doc)

            this.setState({
              school: school,
            })
          })
      })

    db.collection(ColType.student)
      .doc(studentId)
      .get()
      .then((doc) => {
        var student = flattenDoc(doc)

        this.setState({
          student: student,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  progressMonitoringChange = (newValue, iep, index) => {
    // set the number in the progress monitoring to the new value
    iep.progressMonitoring[index].num = newValue
    iep.totalPoints = getTotalPoints(iep)
    iep.iepParagraph = createMTSSGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  onCompletionDateChange = (date, dateString) => {
    var ieps = this.state.ieps
    ieps.map((iep, index) => {
      iep.completionDate = dateString
      iep.iepParagraph = createMTSSGoalText(iep, this.state.student, iep.numProgressMonitoringScores)
      return false
    })

    this.setState({
      ieps: ieps,
      completionDateString: dateString
    })
  }

  handleSubmit = (e) => {
    if (!(this.props.teacherBaseProps &&
      this.props.teacherBaseProps.studentDict)) {
      console.log("Error: students not loaded")
      return
    }

    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err && this.state.completionDateString) {
        console.log("Received values of form:", values)

        this.setState({
          isSubmitting: true,
        }, () => {
          let iepPromises = []

          // delete the draft
          db.collection(ColType.pepDrafts).doc(this.state.draftId).delete()

          // if there are no difficulty and interest in the goal,
          // it is from the new version, add these fields back
          // in for backwards compatibility
          let addDifficultyAndInterest = false
          if (this.state.ieps.length > 0) {
            let iep = this.state.ieps[0]
            if (!(iep.hasOwnProperty('mayHaveTroubleWith') &&
              iep.mayHaveTroubleWith &&
              iep.hasOwnProperty('mayHaveTroubleWithDescription') &&
              iep.mayHaveTroubleWithDescription &&
              iep.hasOwnProperty('studentInterest') &&
              iep.studentInterest)) {
              addDifficultyAndInterest = true
            }
          }

          // add accommodations and possibly removalStatement to student
          this.state.iepDraft.studentId.map((studentId, index) => {
            if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
              return false
            }

            let student = this.props.teacherBaseProps.studentDict[studentId]
            var updatedStudentObj = {}
            // get removal statement for student
            let removalStatement = getSpecialEducationRemovalStatement(student, this.state.iepDraft, '')

            // accommodations are always the same for all students based on what
            // was selected in the draft
            if (this.state.iepDraft.hasOwnProperty('accommodations')) {
              updatedStudentObj.accommodations = this.state.iepDraft.accommodations
            }
            if (this.state.iepDraft.hasOwnProperty('removalStatement')) {
              updatedStudentObj.removalStatement = removalStatement
            }

            // update student
            db.collection(ColType.student).doc(studentId).update(updatedStudentObj)

            // add all ieps in batch
            this.state.ieps.map((iep, index) => {
              // for the calendar
              iep.service.servicedIn = "General Education"
              // create the goal text for this student
              iep.iepParagraph = createMTSSGoalText(iep, student, iep.numProgressMonitoringScores)

              // create the present level for this student
              let strength = iep.presentLevel.strength
              if (iep.mainSubject === 'Social Emotional Learning') {
                iep.presentLevel.presentLevelParagraph =
                  createSELPresentLevelTextMTSS(iep, iep.presentLevel, student, strength)
              }
              else if (iep.mainSubject === 'Math') {
                iep.presentLevel.presentLevelParagraph =
                  createMathPresentLevelTextMTSS(iep, iep.presentLevel, student, strength)
              }
              else {
                iep.presentLevel.presentLevelParagraph =
                  createELAPresentLevelTextMTSS(iep, iep.presentLevel, student, strength)
              }

              if (addDifficultyAndInterest) {
                if (student.hasOwnProperty('mayHaveTroubleWith') &&
                  student.mayHaveTroubleWith &&
                  student.hasOwnProperty('mayHaveTroubleWithDescription') &&
                  student.mayHaveTroubleWithDescription &&
                  student.hasOwnProperty('studentInterest') &&
                  student.studentInterest) {
                  iep.mayHaveTroubleWith = student.mayHaveTroubleWith
                  iep.mayHaveTroubleWithDescription = student.mayHaveTroubleWithDescription
                  iep.studentInterest = student.studentInterest
                }
              }

              // student at the time of creation (used in functions)
              return iepPromises.push(db.collection(ColType.iep).add({
                folder: FOLDER_CURRENT,
                accommodations: this.state.iepDraft.accommodations,
                removalStatement: removalStatement,
                isMTSS: true,
                iep: iep,
                student: student,
                studentId: studentId,
                teacherId: this.state.teacher.id,
                schoolId: this.state.teacher.schoolId,
                districtId: this.state.teacher.districtId,
                onTrack: false,
                totalMeasurements: 0,
                latestMeasurementTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
              }))
            })

            return false
          })


          Promise.all(iepPromises).then(() => {
            console.log("Batch added successfully")

            // inc goal awards last
            awardsIncGoals(this.state.teacher, this.state.ieps.length)

            this.setState({
              isSubmitting: false,
            })

            this.props.history.push(
              {
                pathname: '/teacher/student/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId
              }
            )
          }).catch((error) => {
            console.log("Error adding batch: ", error)
            this.setState({
              isSubmitting: false,
            })
          })
        })
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return <div>
      <Layout className="content layout-header-mt">
        <Layout>
          <Content className="min-w-1120">
            <div>

              <DraftStepContainer
                teacherBaseProps={this.props.teacherBaseProps}
                dataLoaded={this.state.iepDraftMounted && this.state.student && this.state.teacher}
                isMTSS={true}
              >
                <div>
                  {this.state.student && this.state.teacher ?
                    <div>

                      <div className="w-800 m-lr-auto background-fff br-15">
                        <div className='p-4 bkg-purple br-15'>
                          <div className='flex flex-v-center w-100'>
                            <div>
                              {this.props.teacherBaseProps &&
                                this.props.teacherBaseProps.studentDict &&
                                this.props.teacherBaseProps.studentDict
                                  .hasOwnProperty(this.state.studentId)
                                ?
                                <div className="flex flex-v-center mb-0 font-24">
                                  <div>
                                    <div className='font-20 font-fff'>
                                      Tier Plan Draft
                                    </div>
                                    <h1 className="font-40 mb-0 font-fff font-bold">
                                      Review Date
                                    </h1>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <img src="/draft-icons/8.png" alt='draft-iep' width={250} className='ml-auto' />
                          </div>
                        </div>
                        <div className='p-4'>
                          <div>
                            <Form onSubmit={this.handleSubmit}>

                              <h2 className="font-20">
                                {this.state.iepDraft.ieps.length === 1 ?
                                  'When is the review date for this goal?' :
                                  'When is the review date for these goals?'}
                              </h2>
                              <div className="w-500">
                                <FormItem
                                  label={'Review date'}>
                                  {getFieldDecorator('completionDate', {
                                    rules: [{
                                      required: true,
                                      message: 'Select a review date.'
                                    }],
                                  })(
                                    <DatePicker
                                      size={'large'}
                                      onChange={this.onCompletionDateChange}
                                    />
                                  )}
                                </FormItem>
                              </div>
                              <FormItem className="mb-0">
                                <div className="pt-4 flex flex-v-center">
                                  {this.state.teacherId && this.state.studentId && this.state.draftId ?
                                    <Link
                                      to={'/teacher/mtss-add-goal-accommodations/' +
                                        this.state.teacherId + '?student=' +
                                        this.state.studentId +
                                        '&draft=' + this.state.draftId}
                                      className="font-18"
                                    >
                                      <span className="left-hover-parent text-cyan">
                                        <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                        <span>Previous Step</span>
                                      </span>
                                    </Link>
                                    : ''}
                                  <Button
                                    className="lg-btn ml-auto"
                                    size={'large'}
                                    type={'primary'}
                                    htmlType="submit"
                                    disabled={this.state.isSubmitting}
                                  >
                                    <Icon
                                      type="plus" />
                                    {!this.state.isSubmitting ?
                                      'Add these goals' :
                                      'Adding goals...'}
                                  </Button>
                                </div>
                              </FormItem>

                            </Form>
                          </div>


                        </div>
                      </div>
                    </div>
                    : ''}

                </div>
              </DraftStepContainer>
            </div>

          </Content>
        </Layout>
      </Layout>
    </div>
  }
}

export default Form.create()(TeacherAddIEPGoalConfirm)
