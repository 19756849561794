import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../../styles/GlobalStyle.css'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import { db } from '../../../firebase/Firebase'
//import ColType from '../../../Types'
import { capitalizeFirstChar, getQueryStringParam, FOLDER_CURRENT, FOLDER_ARCHIVED, FOLDER_TRASHBIN, getIconFromMainSubject, getTierCircleColor } from '../../../Util'
import { Layout, Row, Col, Spin } from 'antd'
import StudentNavigation from '../StudentNavigation'
import Empty from '../../../customcomponents/Empty'
import StandardNumber from '../../../customcomponents/StandardNumber'
const Content = Layout.Content

class ArchivedStudentView extends Component {
    state = {
        studentId: '',
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData = () => {
        let studentId = getQueryStringParam('student')
        this.setState({
            studentId: studentId,
        })
        // get the data for the student (will only get it once in
        // archivedBase)
        if (this.props.archivedBaseProps.hasOwnProperty('getIEPSForStudentFunc')) {
            this.props.archivedBaseProps.getIEPSForStudentFunc(studentId)
        }
    }
    render() {
        let dataLoaded = this.props.archivedBaseProps.archivedStudentsLoaded &&
            this.state.studentId &&
            this.props.archivedBaseProps.studentIEPLoading.hasOwnProperty(this.state.studentId) &&
            this.props.archivedBaseProps.studentIEPLoading[this.state.studentId] === false
        let alreadyMovedAction = ''
        let alreadyMovedDescription = ''
        if (this.props.archivedBaseProps.baseFolder === FOLDER_ARCHIVED) {
            alreadyMovedAction = 'Back to archived students'
            alreadyMovedDescription = 'Check to see if this student or plan was moved to your caseload or deleted.'
        } else if (this.props.archivedBaseProps.baseFolder === FOLDER_TRASHBIN) {
            alreadyMovedAction = 'Back to recycle bin'
            alreadyMovedDescription = 'Check to see if this student or plan was moved to your caseload or archived.'
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                <div>
                                    {this.props.archivedBaseProps.moveInProcess ?
                                        <div className={"flex flex-h-center" +
                                            " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                            <div>
                                                <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                            </div>
                                        </div>
                                        :
                                        this.props.teacherBaseProps.teacher &&
                                            this.state.studentId &&
                                            this.props.archivedBaseProps.studentDict.hasOwnProperty(this.state.studentId) &&
                                            this.props.archivedBaseProps.studentIEPDict.hasOwnProperty(this.state.studentId) ?
                                            <div>
                                                <StudentNavigation
                                                    history={this.props.history}
                                                    teacherBaseProps={this.props.archivedBaseProps}
                                                    onMoveFromCurrent={null}
                                                    onBeforeMove={this.props.archivedBaseProps.onBeforeMoveFunc}
                                                    onAllGoalsMoved={this.props.archivedBaseProps.removeStudentFunc}
                                                    studentId={this.state.studentId}
                                                    student={this.props.archivedBaseProps.studentDict[this.state.studentId]}
                                                    folderFrom={this.props.archivedBaseProps.baseFolder}
                                                    folderTo={FOLDER_CURRENT}
                                                />
                                                {!dataLoaded ?
                                                    <div className={"flex flex-h-center" +
                                                        " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                                        <div>
                                                            <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                                        </div>
                                                    </div>
                                                    : ''}
                                                <Row gutter={32} type="flex" className='mt-3'>
                                                    {dataLoaded && this.props.archivedBaseProps.studentIEPDict[this.state.studentId].length === 0 ?
                                                        <div className="w-100">
                                                            <Empty
                                                                containerClassName="text-center"
                                                                width={55}
                                                                height={55}
                                                                description="There are currently no plans"
                                                                image={'/empty/goals.png'}
                                                            />
                                                        </div>
                                                        : ''}

                                                    {dataLoaded && this.props.archivedBaseProps.studentIEPDict[this.state.studentId].map((iep, idx) => {
                                                        if (iep.studentId !== this.state.studentId) {
                                                            return false
                                                        }

                                                        return <Col xs={24} sm={24} md={24} lg={24} xl={24} key={iep.id}>
                                                            <Link to={"/teacher/" + this.props.archivedBaseProps.urlRoute + "/student-plan/" + this.props.teacherBaseProps.teacher.id + "?student=" + iep.studentId + "&plan=" + iep.id}>
                                                                <div className={"br-20 mb-3 background-fff overflow-hidden font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}
                                                                >

                                                                    <div className="flex flex-v-center p-3">
                                                                        <div className="br-50 flex flex-h-center flex-v-center mr-4" style={{ background: getTierCircleColor(iep), minWidth: '80px', width: '80px', height: '80px' }}>
                                                                            <img
                                                                                src={getIconFromMainSubject(iep.iep.mainSubject, iep.iep.hasOwnProperty('tier') ? iep.iep.tier : '')}
                                                                                alt="icon"
                                                                                width={40}
                                                                            />
                                                                        </div>

                                                                        <div>
                                                                            <div className="mb-1 font-24 font-bold">{iep.iep.mainSubject}</div>
                                                                            <div className="font-20">
                                                                                <span className='pl-1 pr-1 pt-05 pb-05 mr-1 br-100' style={{ backgroundColor: '#e8e8e8' }}>
                                                                                    <StandardNumber
                                                                                        district={this.props.teacherBaseProps.district}
                                                                                        iep={iep.iep}
                                                                                        className='mr-0'
                                                                                    />
                                                                                </span>
                                                                                <span>
                                                                                    {capitalizeFirstChar(iep.iep.standardDescription)}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </div>
                                            :
                                            dataLoaded ?
                                                <div>
                                                    <div className="font-30 font-bold">
                                                        No student or IEP selected
                                                    </div>
                                                    <div className="font-20 mb-2">
                                                        {alreadyMovedDescription}
                                                    </div>
                                                    <Link className="font-20" to={"/teacher/" + this.props.archivedBaseProps.urlRoute + "/students/" + this.state.teacherId}>
                                                        {alreadyMovedAction}
                                                    </Link>
                                                </div>
                                                : ''
                                    }
                                </div>

                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default ArchivedStudentView