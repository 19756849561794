import React from 'react'

class LessonPlan extends React.Component {
    renderSections = () => {
        const sectionDisplayObj = {
            'closure': 'Closure',
            'independent_practice': 'Independent Practice',
            'instruction': 'Instruction',
            'introduction': 'Introduction',
            'materials': 'Materials',
            'learning_objectives': 'Learning Objectives',
            'small_group_practice': 'Small Group Practice',
            'standard': 'Standard',
        }
        const sections = ['learning_objectives', 'materials', 'introduction', 'instruction',
            'small_group_practice', 'independent_practice', 'closure']
        return sections.map((sectionName) => {
            if (sectionName === 'type' || sectionName === 'standard') {
                return false
            }
            if (!sectionDisplayObj.hasOwnProperty(sectionName)) {
                return false
            }

            let content = this.props[sectionName]

            if (Array.isArray(content)) {
                content = (
                    <ul>
                        {content.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                )
            }

            return (
                <div key={sectionName}>
                    <div className='mt-3 mb-05 font-24 font-bold'>{sectionDisplayObj[sectionName]}</div>
                    <div className='font-20'>{content}</div>
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                {this.renderSections()}
            </div>
        )
    }
}

export default LessonPlan
