import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ColType from '../Types'
//import { Layout, Icon, Button, Checkbox, Table } from 'antd'
import ChatHome from '../teacher/chat/ChatHome'
import { flattenDoc } from '../Util'

class InstructionalFacilitatorChatHome extends Component {
    state = {
        schoolPersonnelDict: {},
        retrievedTeachers: false,
    }

    componentDidMount() {
        this.getTeachers()
    }

    componentWillReceiveProps(nextProps) {
        this.getTeachers()
    }

    getTeachers = () => {
        if (!(this.props.instructionalFacilitatorBaseProps &&
            this.props.instructionalFacilitatorBaseProps.instructionalFacilitator &&
            this.props.instructionalFacilitatorBaseProps.schools)) {
            return
        }

        if (this.state.retrievedTeachers) {
            return
        }

        this.setState({
            retrievedTeachers: true,
        })

        this.props.instructionalFacilitatorBaseProps.schools.map((school) => {
            let schoolPersonnelLocalDict = { 'school': school, 'teachers': [], 'instructionalFacilitators': [], 'schoolAdmins': [] }
            let schoolPersonnelDict = this.state.schoolPersonnelDict
            schoolPersonnelDict[school.id] = schoolPersonnelLocalDict
            this.setState({
                schoolPersonnelDict: schoolPersonnelDict,
            }, () => {
                db.collection(ColType.teacher)
                    .where('schoolId', '==', school.id)
                    .get()
                    .then((querySnapshot) => {
                        let teachers = []
                        querySnapshot.forEach((doc) => {
                            let teacher = flattenDoc(doc)
                            teachers.push(teacher)
                        })
                        let schoolPersonnelDict = this.state.schoolPersonnelDict
                        schoolPersonnelDict[school.id]['teachers'] = schoolPersonnelDict[school.id]['teachers'].concat(teachers)
                        this.setState({
                            schoolPersonnelDict: schoolPersonnelDict,
                        })
                    })

                db.collection(ColType.instructionalFacilitator)
                    .where('schoolIds', 'array-contains', school.id)
                    .where('districtId', '==', school.districtId)
                    .get()
                    .then((querySnapshot) => {
                        let instructionalFacilitators = []
                        querySnapshot.forEach((doc) => {
                            let instructionalFacilitator = flattenDoc(doc)
                            instructionalFacilitators.push(instructionalFacilitator)
                        })
                        let schoolPersonnelDict = this.state.schoolPersonnelDict
                        schoolPersonnelDict[school.id]['instructionalFacilitators'] = schoolPersonnelDict[school.id]['instructionalFacilitators'].concat(instructionalFacilitators)
                        this.setState({
                            schoolPersonnelDict: schoolPersonnelDict,
                        })
                    })

                db.collection(ColType.schoolAdmin)
                    .where('schoolId', '==', school.id)
                    .get()
                    .then((querySnapshot) => {
                        let schoolAdmins = []
                        querySnapshot.forEach((doc) => {
                            let schoolAdmin = flattenDoc(doc)
                            schoolAdmins.push(schoolAdmin)
                        })
                        let schoolPersonnelDict = this.state.schoolPersonnelDict
                        schoolPersonnelDict[school.id]['schoolAdmins'] = schoolPersonnelDict[school.id]['schoolAdmins'].concat(schoolAdmins)
                        this.setState({
                            schoolPersonnelDict: schoolPersonnelDict,
                        })
                    })
            })

            return false
        })

    }

    render() {
        return (
            <div>
                <ChatHome
                    personRole={'instructionalFacilitator'}
                    person={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator}
                    schoolPersonnelDict={this.state.schoolPersonnelDict}
                    students={[]}
                    IEPGoals={[]}
                    IEPDict={{}}
                    schoolId={''}
                    chatNotificationDict={this.props.instructionalFacilitatorBaseProps.chatNotificationDict}
                    setCurrentlyViewingChatIdFunc={this.props.instructionalFacilitatorBaseProps.setCurrentlyViewingChatIdFunc}
                />
            </div>
        )
    }
}

export default InstructionalFacilitatorChatHome
