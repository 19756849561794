import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase } from '../firebase/Firebase'

class SandboxTrialEnded extends Component {
  state = {

  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Sandbox trial ended'
    // sign them out here to stop an endless loop if they click
    // on home page. They will keep getting here and won't be
    // able to sign in to a different account.
    this.signOut()
  }

  signOut = () => {
    firebase.auth().signOut().then(() => {
      console.log('Signed Out')
    }, function (error) {
      console.log('Sign Out Error', error)
    })
  }

  render() {
    return (
      <div className="pt-70 background-light-cyan-gradient h-100">
          <div className="block w-100 m-lr-auto mb-4 pb-1 text-center">
          <Link to="/">
            <img src='/dotit-iep-logo.png' alt='logo' height='64' />
          </Link>
        </div>
        <div className="g-col large-form-padding w-600 m-lr-auto br-15">
          <div className="mb-2 text-center pb-2 font-24 font-bold">
            Your Dot It IEP trial has ended.</div>
          <div className="font-18">
            If you are enjoying Dot It IEP, ask your principal
            or district leaders to contact us at:
            <a href="https://dotit.app/contact-us" 
          className="text-cyan ml-1 font-18 font-bold">
            dotit.app/contact-us
          </a>.
          </div>
      </div>
      </div>
    )
  }
}

export default SandboxTrialEnded