import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import { getIDFromURL } from '../../Util'
import { Layout, Row, Col } from 'antd'
const { Content } = Layout

class TeacherHelpDesk extends Component {
    state = {

    }

    componentDidMount() {
        document.title = 'Help Desk - Dot It'

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                <h1 className="font-bold mb-0 font-30">Getting Started with Dot It</h1>
                                <h2 className="mb-4">Accelerate your ability to draft amazing goals.</h2>
                                {this.state.teacherId ?
                                    <Row gutter={48} className="ml-0 mt-3">
                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/teacher/help-desk-getting-started/' + this.state.teacherId}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk2.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Getting started with Dot It
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk3.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting an IEP Goal
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <h1 className="font-bold mb-0 mt-4 font-30">Learn</h1>
                                        <h2 className="mb-4">Achieve your teaching goals with Dot It's latest articles and resources.</h2>


                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col span={8}
                                            className="pl-0">
                                            <Link
                                                to={'/'}
                                            >
                                                <div className={"br-4 mb-3 background-fff overflow-hidden border font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}>
                                                    <div className="w-100">
                                                        <img src="/helpdesk4.png" alt="get started with dot it" className="w-100" />
                                                    </div>
                                                    <h2 className="mt-2 text-center font-20">
                                                        Drafting a Tier Plan
                                                    </h2>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row> : ''}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter teacherId={this.state.teacherId} />
            </div>
        )
    }
}

export default TeacherHelpDesk
