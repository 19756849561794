import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import Empty from '../../customcomponents/Empty'
import { getIDFromURL } from '../.././Util'
import { Layout, Row, Col } from 'antd'
const { Content } = Layout

class TeacherStudentUploadSelect extends Component {
    state = {
        teacherId: '',
        teachers: [],
        students: [],
        school: null,
        IEPGoals: [],
        studentIEPStatus: [],
        profileMode: 1,
        componentMounted: false,
        studentsWithGoalsPercent: 0,
        goalSubjects: {
            'Writing': 0,
            'Reading Comprehension in Literature': 0,
            'Reading Comprehension in Informational Text': 0,
            'Reading Foundations': 0,
            'Math': 0,
            'Social Emotional Learning': 0,
            'total': 0,
        },
        support: {
            low: 0,
            medium: 0,
            high: 0,
            tier2: 0,
            tier3: 0,
        },
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }


    componentWillReceiveProps(nextProps) {
        // if has data and students.length > 0 (no divide by zeroes)
        if (nextProps.teacherBaseProps.studentDict &&
            nextProps.teacherBaseProps.studentRequiresAction &&
            nextProps.teacherBaseProps.IEPGoals &&
            nextProps.teacherBaseProps.students &&
            nextProps.teacherBaseProps.students.length > 0) {
            var studentsWithGoals = 0
            nextProps.teacherBaseProps.students.map((student, idx) => {
                if (nextProps.teacherBaseProps.studentRequiresAction
                    .hasOwnProperty(student.id)) {
                    studentsWithGoals += 1
                }
                return false
            })
            var pct = 0
            if (studentsWithGoals > 0) {
                pct = Math.floor((studentsWithGoals.toFixed(2) /
                    nextProps.teacherBaseProps.students.length.toFixed(2)) * 100)
            }

            var goalSubjects = this.state.goalSubjects
            var support = this.state.support
            support.low = 0
            support.medium = 0
            support.high = 0
            support.tier2 = 0
            support.tier3 = 0
            nextProps.teacherBaseProps.IEPGoals.map((IEPGoal, idx) => {
                if (goalSubjects.hasOwnProperty(IEPGoal.iep.mainSubject)) {
                    goalSubjects[IEPGoal.iep.mainSubject] += 1
                }
                goalSubjects.total += 1
                if (IEPGoal.hasOwnProperty("isMTSS") && IEPGoal.isMTSS) {
                    if (IEPGoal.iep.tier === "Tier 2") {
                        support.tier2 += 1
                    } else if (IEPGoal.iep.tier === "Tier 3") {
                        support.tier3 += 1
                    }
                }
                else if (IEPGoal.iep.level === '70 and below') {
                    support.high += 1
                }
                else if (IEPGoal.iep.level === '70-79') {
                    support.medium += 1
                }
                else if (IEPGoal.iep.level === '80 and above') {
                    support.low += 1
                }
                return false
            })

            this.setState({
                studentsWithGoalsPercent: pct,
                goalSubjects: goalSubjects,
                support: support,
            })
        }
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Reports - Dot It'

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })

        // if has data and students.length > 0 (no divide by zeroes)
        if (this.props.teacherBaseProps.studentDict &&
            this.props.teacherBaseProps.studentRequiresAction &&
            this.props.teacherBaseProps.IEPGoals &&
            this.props.teacherBaseProps.students &&
            this.props.teacherBaseProps.students.length > 0) {
            var studentsWithGoals = 0
            this.props.teacherBaseProps.students.map((student, idx) => {
                if (this.props.teacherBaseProps.studentRequiresAction
                    .hasOwnProperty(student.id)) {
                    studentsWithGoals += 1
                }
                return false
            })
            var pct = 0
            if (studentsWithGoals > 0) {
                pct = Math.floor((studentsWithGoals.toFixed(2) /
                    this.props.teacherBaseProps.students.length.toFixed(2)) * 100)
            }

            var goalSubjects = this.state.goalSubjects
            var support = this.state.support
            support.low = 0
            support.medium = 0
            support.high = 0
            support.tier2 = 0
            support.tier3 = 0
            this.props.teacherBaseProps.IEPGoals.map((IEPGoal, idx) => {
                if (goalSubjects.hasOwnProperty(IEPGoal.iep.mainSubject)) {
                    goalSubjects[IEPGoal.iep.mainSubject] += 1
                }
                goalSubjects.total += 1
                if (IEPGoal.hasOwnProperty("isMTSS") && IEPGoal.isMTSS) {
                    if (IEPGoal.iep.tier === "Tier 2") {
                        support.tier2 += 1
                    } else if (IEPGoal.iep.tier === "Tier 3") {
                        support.tier3 += 1
                    }
                }
                else if (IEPGoal.iep.level === '70 and below') {
                    support.high += 1
                }
                else if (IEPGoal.iep.level === '70-79') {
                    support.medium += 1
                }
                else if (IEPGoal.iep.level === '80 and above') {
                    support.low += 1
                }
                return false
            })

            this.setState({
                studentsWithGoalsPercent: pct,
                goalSubjects: goalSubjects,
                support: support,
            })
        }
    }

    render() {
        var sandboxNumGoalsRemaining = 10
        if (this.props.teacherBaseProps.teacherStats &&
            this.props.teacherBaseProps.teacherStats.hasOwnProperty("numGoals") &&
            this.props.teacherBaseProps.teacherStats.numGoals) {
            sandboxNumGoalsRemaining = 10 - this.props.teacherBaseProps.teacherStats.numGoals
            if (sandboxNumGoalsRemaining < 0) {
                sandboxNumGoalsRemaining = 0
            }
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                {this.state.teacherId ?
                                    <div>
                                        <div className="mb-4 flex flex-v-center">
                                            <h1 className="font-bold text-center font-60 bakerie-font">Uploads</h1>
                                            <img src="/uploads.png" alt="uploads" className="ml-auto" />
                                        </div>

                                        <Row gutter={48} className="ml-0" type="flex">
                                            {this.props.teacherBaseProps &&
                                                this.props.teacherBaseProps.students &&
                                                this.props.teacherBaseProps.students.length === 0 ?
                                                <div className="text-center w-100">
                                                    <Empty
                                                        containerClassName="mt-4 pt-2 text-center font-16"
                                                        width={55}
                                                        height={55}
                                                        description={'You currently have no students.'}
                                                    />
                                                </div>
                                                : ''}
                                            {this.props.teacherBaseProps &&
                                                this.props.teacherBaseProps.students &&
                                                this.props.teacherBaseProps.students.map((student, idx) => {
                                                    return <Col span={24} className="pl-0" key={student.id}>
                                                        <Link to={"/teacher/student-uploads/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}>
                                                            <div className="p-3 br-4 border background-fff mb-3 ant-shadow ant-shadow-more-hover">
                                                                <PersonAvatar
                                                                    person={student}
                                                                    size={'large'}
                                                                    containerClassName="font-20"
                                                                    avatarClassName="font-20 font-bold mr-2"
                                                                    personClassName="font-black font-24"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                })}
                                        </Row>




                                    </div>
                                    : ''}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default TeacherStudentUploadSelect
