import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { Button, Form, Input } from 'antd'
const FormItem = Form.Item

class AdminChangeDefaultPasswordForm extends Component {
  state = {
    teacherId: '',
    teacher: [],
    student: null,
    submitting: false,
  }

  componentDidMount() {

  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          submitting: true,
        }, () => {
          console.log('Received values of form: ', values)
          this.props.onSuccess(values.password)
        
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 30 },
        sm: { span: 17 },
      },
    }

    return (
      <Form onSubmit={this.handleSubmit} className="login-form text-align-left w-500">
        
        <FormItem {...formItemLayout} label="New Password">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Required field.' }],
          })(
            <Input placeholder="Password..." />
          )}
        </FormItem>

        <FormItem className="mb-0">
          <Button
            type="primary"
            size={'large'}
            htmlType="submit"
            className="login-form-button text-align-center float-right"
            disabled={this.state.submitting}
          >
            Change Password
          </Button>
        </FormItem>
      </Form>

    )
  }
}

export default Form.create()(AdminChangeDefaultPasswordForm)
