import React, { Component } from 'react'
import '../styles/GlobalStyle.css'

class TermsOfService extends Component {
  state = {

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="w-750 m-lr-auto p-4">
        <h1 className="text-center font-bold">dot it Trial License Agreement</h1>
        <h3 className="mb-3">CAREFULLY READ THE TERMS AND CONDITIONS OF 
          THIS TRIAL LICENSE AGREEMENT (“AGREEMENT”) BEFORE 
          ACCESSING OR USING OUR “DOT IT” APPLICATION (“APPLICATION”). 
          THIS AGREEMENT CONTAINS IMPORTANT TERMS AND CONDITIONS ABOUT 
          YOUR USE OF THE APPLICATION AND OUR OBLIGATIONS WITH RESPECT 
          TO THE APPLICATION.  IF YOU AGREE TO THESE TERMS AND CONDITIONS, 
          CLICK THE “I AGREE” BUTTON.  IF YOU DO NOT AGREE TO THESE TERMS 
          AND CONDITIONS, CLICK THE “I DECLINE” BUTTON. 
        </h3>
        <h3 className="mb-2">The parties agree as follows:</h3>
        <h2 className="mb-2"><span className="mr-4">1.</span>
            <span className="font-bold mr-2">Trial License.</span>
          Smart Learning Systems, LLC (“we” or “us”) 
          hereby grants to the organization identified when signing up 
          for this trial license (“you”), and you accept, a limited, 
          non-exclusive, non-transferable license to access and use the 
          Application during the term of this Agreement, only as made 
          available by us to you and as authorized in this Agreement. 
          Access to the Application will only be provided via the Internet, 
          and you are responsible for obtaining and maintaining the necessary 
          Internet access and web browser.
        </h2>
        <h2 className="mb-2"><span className="mr-4">2.</span>
            <span className="font-bold mr-2">Qualification for Free Trial.</span>
            You represent and warrant to us that (a) all of the information 
            you have provided to us about your organization is true, and (b) 
            the individual accepting this Agreement has the right, power, 
            and authority to enter into this Agreement on your behalf and 
            to bind you to its terms. 
        </h2>
        <h2 className="mb-2"><span className="mr-4">3.</span>
            <span className="font-bold mr-2">Use.</span>
            You may only use the Application for your internal 
            business purposes. You must comply with all 
            applicable laws when using the Application. You must 
            not exceed the scope of your authorized use. You acknowledge 
            that some portions of the Application may be off-limits 
            to you until you have purchased a full license. Use of 
            the Application is void where prohibited.
        </h2>
        <h2 className="mb-2"><span className="mr-4">4.</span>
            <span className="font-bold mr-2">Restrictions.</span>
            You agree that you will not (a) use the 
            Application except as set forth in this Agreement, 
            (b) violate any applicable law in connection with 
            your use of the Application or any data obtained, 
            processed, or transmitted via the Application 
            (“Data”), (c) transmit via the Application any 
            unlawful, threatening, libelous, defamatory, 
            obscene, or pornographic material, or any material 
            that would violate any law or the intellectual property 
            or other proprietary rights of others, (d) copy, modify, 
            translate, adapt, or create derivative works based on, 
            the Application or any part of it, (e) use the 
            Application to provide service bureau or other access 
            or use of the Application to third parties, (f) reverse 
            engineer, disassemble, decompile, decode, or otherwise 
            attempt to derive or gain access to the source code of 
            the Application or any part thereof, (g) store or place 
            the Application on any web page or transmit the 
            Application electronically via the Internet, (h) 
            export data from the Application except as expressly 
            authorized by us, (i) sell, distribute, loan, assign, 
            sublicense, pledge, lease, rent, share or otherwise 
            transfer the Application, or any portion or copy of 
            the Application, or your rights and obligations under 
            this Agreement, (j) show the Application to anyone else 
            without our prior written approval, or (k) remove or alter 
            any trademark, trade name, product name, logo, copyright, 
            patent, or other proprietary notice, legend, symbol, or 
            label in the Application.
        </h2>
        <h2 className="mb-2"><span className="mr-4">5.</span>
            <span className="font-bold mr-2">Intellectual Property.</span>
            You acknowledge that the Application is provided under 
            license, and not sold, to you. You do not acquire any 
            ownership interest in the Application under this Agreement, 
            or any other rights to the Application other than the right 
            to use the Application in accordance with the license granted 
            under this Agreement, subject to all its terms, conditions, 
            and restrictions. We reserve and shall retain our entire right, 
            title, and interest in and to the Application and all 
            intellectual property rights arising out of or relating to 
            the Application, subject to the license expressly granted 
            to you in this Agreement. The Application contains trade 
            secret and proprietary information owned by and is protected 
            by copyright and other applicable laws.  You shall safeguard 
            the Application from infringement, misappropriation, theft, 
            misuse, or unauthorized access.
        </h2>
        <h2 className="mb-2"><span className="mr-4">6.</span>
            <span className="font-bold mr-2">Confidentiality of Application.</span>
            You agree to keep strictly confidential (a) the Application, 
            and (b) any documentation or materials you obtain in 
            connection with your use of the Application.  You agree 
            to use your best efforts to prevent any unauthorized 
            disclosure of the Application, or any part thereof or 
            any documentation or materials related thereto, from 
            begin disclosed to any third party, with particular 
            care being given to refrain from disclosing any such 
            information to our competitors.
        </h2>
        <h2 className="mb-2"><span className="mr-4">7.</span>
            <span className="font-bold mr-2">Suggestions.</span>
            You agree that any information, ideas, suggestions, or 
            materials that you or individuals acting on your behalf 
            send to us, including feedback, data, questions, 
            comments, product or service ideas, know-how, or 
            suggestions (collectively, “<span className="font-bold">Suggestions</span>”), 
            will not 
            be considered confidential or proprietary.  Further, 
            you agree that we will have the unrestricted and perpetual 
            right to use, reproduce, and disclose such Suggestions, 
            in any manner and for any purpose (including without 
            limitation to improve the Application and to develop, 
            market, and offer other products or services that 
            incorporate or otherwise embody the Suggestions), 
            without providing any notice, compensation, or 
            attribution to you.  
        </h2>
        <h2 className="mb-2"><span className="mr-4">8.</span>
            <span className="font-bold mr-2">Technical Support.</span>
            We may, but are not required to, make help documentation 
            available to you online.  Further technical support may 
            be available for an additional charge at our then-current 
            hourly rates.
        </h2>
        <h2 className="mb-2"><span className="mr-4">9.</span>
            <span className="font-bold mr-2">Term; Termination.</span>
            This Agreement is effective upon the date your clicking the 
            “I AGREE” button, as described above, and shall continue 
            for a ninety (90) day period, and this Agreement shall 
            automatically terminate at the end of that period. 
            We may terminate this Agreement for any reason at any time.  
            In addition, this Agreement shall be terminated automatically 
            if you fail to comply with any of the terms and conditions of 
            this Agreement.  Upon any termination of this Agreement, you 
            agree to cease all use of the Application immediately.  
            Notwithstanding the foregoing, 
            <strong> Sections 4, 5, 6, 7, 9, 10, 11, 14, 17, 18, 21, 23, and 24 </strong>
            shall survive the termination of this Agreement.
        </h2>
        <h2 className="mb-2"><span className="mr-4">10.</span>
            <span className="font-bold mr-2">No Warranty.</span>
            WE LICENSE THE APPLICATION "AS IS" AND “AS AVAILABLE,” 
            WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, 
            AND DISCLAIM ALL IMPLIED AND EXPRESS WARRANTIES 
            WITH REGARD TO THE APPLICATION, INCLUDING ANY IMPLIED 
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A 
            PARTICULAR PURPOSE, NON-INFRINGEMENT, AND TITLE, 
            OR ANY WARRANTY CONCERNING THE QUALITY, FUNCTIONALITY, 
            AVAILABILITY, OPERABILITY, ACCURACY, EFFORT, USE, 
            OR PERFORMANCE OF THE APPLICATION.  YOU EXPRESSLY 
            ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE APPLICATION 
            IS AT YOUR SOLE RISK AND THAT YOU ASSUME THE ENTIRE 
            RISK AS TO POTENTIAL LOSS OR DISCLOSURE OF DATA OR 
            SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT.  
            SHOULD THE APPLICATION PROVE DEFECTIVE, YOU ASSUME THE 
            ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR 
            CORRECTION. WE HAVE NO OBLIGATION UNDER THIS AGREEMENT 
            TO CORRECT ANY BUGS, DEFECTS, OR ERRORS IN THE APPLICATION, 
            RECOVER ANY LOST DATA, OR TO OTHERWISE SUPPORT OR 
            MAINTAIN THE APPLICATION DURING THE TERM OF THIS AGREEMENT.  
        </h2>
        <h2 className="mb-2"><span className="mr-4">11.</span>
            <span className="font-bold mr-2">Limitation of Liability.</span>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, 
            IN NO EVENT WILL (A) WE BE LIABLE TO YOU OR ANYONE 
            ELSE FOR ANY CONSEQUENTIAL, EXEMPLARY, SPECIAL, OR 
            INCIDENTAL DAMAGES, INCLUDING ANY DAMAGES FOR LOST 
            DATA OR LOST PROFITS, ARISING FROM OR RELATED TO 
            THE APPLICATION OR THE DATA OR YOUR USE OF THE 
            APPLICATION OR THE DATA, EVEN IF WE KNEW OR 
            SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, 
            AND (B) OUR TOTAL CUMULATIVE LIABILITY ARISING FROM 
            OR RELATED TO THE APPLICATION OR THE DATA OR YOUR USE 
            OF THE APPLICATION OR THE DATA, WHETHER IN CONTRACT OR 
            TORT OR OTHERWISE, EXCEED ONE HUNDRED DOLLARS ($100). 
            IN NO EVENT WILL WE BE LIABLE FOR ANY CLAIM 
            YOU BRING AGAINST US MORE THAN ONE YEAR AFTER 
            THE CAUSE OF ACTION AROSE OR SHOULD HAVE BEEN DISCOVERED.
        </h2>
        <h2 className="mb-2"><span className="mr-4">12.</span>
            <span className="font-bold mr-2">Basis of the Bargain; Indemnity.</span>
            The disclaimers and limitations of liability set forth above are 
            fundamental elements of the basis of the agreement between you 
            and us.  We would not be able to provide the Application as 
            is without such limitations.   
        </h2>
        <h2 className="mb-2"><span className="mr-4">13.</span>
            <span className="font-bold mr-2">Links.</span>
            The Application may contain links to third-party websites or 
            resources. You acknowledge and agree that we are not 
            responsible or liable for: (a) the availability or 
            accuracy of such websites or resources; or (b) the content, 
            products, or services on or available from such websites or 
            resources. Links to such websites or resources do not 
            imply any endorsement by us of such websites or resources 
            or the content, products, or services available from such 
            websites or resources. You acknowledge sole responsibility 
            for and assume all risk arising from your use of any such 
            websites or resources.
        </h2>
        <h2 className="mb-2"><span className="mr-4">14.</span>
            <span className="font-bold mr-2">Indemnification.</span>
            You agree to defend, indemnify, and hold us harmless from and 
            against any claims, demands, actions, or proceedings, and 
            all resulting losses, liability, damage, settlements, 
            governmental fines, costs, and expenses 
            (including reasonably attorney’s fees) arising from 
            your negligence, gross negligence, willful misconduct, 
            or breach of this Agreement.
        </h2>
        <h2 className="mb-2"><span className="mr-4">15.</span>
            <span className="font-bold mr-2">Trademarks.</span>
            “Smart Learning Systems,” “dot it” and other trademarks 
            contained in the Application are our trademarks or 
            service marks. This Agreement does not authorize you 
            to use our name, trademarks or service marks.
        </h2>
        <h2 className="mb-2"><span className="mr-4">16.</span>
            <span className="font-bold mr-2">Account Information.</span>
            You are responsible for maintaining the confidentiality of all 
            user ids and passwords necessary for accessing the Application, 
            and for restricting access to your employees only.  
            You will not permit access to the Application through 
            a single identification or password code made available 
            to multiple users on a network. You agree to accept 
            responsibility for all activities that occur under 
            your account or through the use of your user ids and 
            passwords.  We reserve the right to refuse service, 
            terminate accounts, remove, or edit content in our sole 
            discretion.
        </h2>
        <h2 className="mb-2"><span className="mr-4">17.</span>
            <span className="font-bold mr-2">Government; Export.</span>
            If the Application is provided to the U.S. government, 
            it is provided only with restricted and limited rights.  
            The Application is commercial computer software, as such 
            term is defined in 48 C.F.R. § 2.101. Accordingly, if you 
            are the US government or any employee or contractor therefor, 
            you shall receive only those rights with respect to 
            the Application as are granted to all other end users 
            under license, in accordance with (a) 
            48 C.F.R. § 227.7201 through 48 C.F.R. § 227.7204, with 
            respect to the Department of Defense and their contractors, 
            or (b) 48 C.F.R. §12.212, with respect to all other US government 
            licensees and their contractors.  The Application may be 
            subject to US export control laws, including the US Export 
            Administration Act and its associated regulations. 
            You shall not, directly or indirectly, export, re-export or 
            release the Application to, or make the Application accessible 
            from, any jurisdiction or country to which export, re-export, or 
            release is prohibited by law, rule, or regulation. 
        </h2>
        <h2 className="mb-2"><span className="mr-4">18.</span>
            <span className="font-bold mr-2">Governing Law; Jurisdiction.</span>
            This Agreement and any dispute or controversy arising out of 
            or relating to it shall in all respects be governed by and 
            construed in accordance with the laws of the State of 
            North Carolina, excluding its conflicts of laws principles.  
            You consent to the jurisdiction and venue of the federal and 
            state courts in Mecklenburg County, North Carolina for 
            resolution of any disputes arising out of this Agreement.  
            The Uniform Computer Information Transactions Act does not 
            apply to this Agreement.
        </h2>
        <h2 className="mb-2"><span className="mr-4">19.</span>
            <span className="font-bold mr-2">Notice.</span>
            Any notice you are required or permitted to give us 
            under the Agreement will be effective if sent by FedEx 
            to the following address: 9105 Yellow Pine Court, Waxhaw, NC 28173.
        </h2>
        <h2 className="mb-2"><span className="mr-4">20.</span>
            <span className="font-bold mr-2">Assignment.</span>
            You shall not assign or otherwise transfer any of your 
            rights, or delegate or otherwise transfer any of 
            your obligations or performance, under this Agreement, in 
            each case whether voluntarily, involuntarily, by 
            operation of law or otherwise, without our prior 
            written consent, which we may give or withhold in our 
            sole discretion. Any purported assignment, delegation or 
            transfer in violation of this section is void.  We may 
            freely assign or otherwise transfer all or any of our rights, or 
            delegate or otherwise transfer all or any of our obligations 
            or performance, under this Agreement without your consent.  
            This Agreement is binding upon and inures to the benefit of 
            the parties hereto and their respective permitted 
            successors and assigns.
        </h2>
        <h2 className="mb-2"><span className="mr-4">21.</span>
            <span className="font-bold mr-2">Audit Rights.</span>
            We shall have the right to monitor and audit your use 
            of the Application and compliance with this Agreement 
            from time to time in our sole discretion.  You agree to 
            permit us to have access to your account for the 
            purpose of conducting such audits.
        </h2>
        <h2 className="mb-2"><span className="mr-4">22.</span>
            <span className="font-bold mr-2">Force Majeure.</span>
            We shall not be liable for any problems, delays or failure 
            to perform any obligations due to causes beyond our control, 
            including without limitation, acts of God or 
            public enemy, fire, floods, storms, epidemic or quarantine restrictions, 
            earthquakes, riots or civil commotion, strikes, war, and 
            restraints of government, freight or other embargoes, weather 
            conditions or any failures by our licensors, subcontractors or suppliers.
        </h2>
        <h2 className="mb-2"><span className="mr-4">23.</span>
            <span className="font-bold mr-2">Attorneys’ Fees.</span>
            If any litigation or arbitration is necessary to enforce 
            the terms of this Agreement, the prevailing party will be 
            entitled to receive reimbursement from the other party 
            of all attorneys' fees and costs incurred by the prevailing party.
        </h2>
        <h2 className="mb-2"><span className="mr-4">24.</span>
            <span className="font-bold mr-2">General.</span>
            This Agreement is the entire agreement between you and us 
            relating to the Application, and it supersedes all prior 
            or contemporaneous oral or written communications, proposals, and 
            representations with respect to its subject matter.  You consent 
            to receive communications from us electronically. You agree 
            that all agreements, notices, disclosures, and other communications 
            that we provide to you electronically satisfy any legal 
            requirement that such communications be in writing.  Any 
            modification of this Agreement requires a writing 
            signed by both parties.  If any provision of this Agreement 
            is held to be unenforceable for any reason, such provision 
            shall be reformed only to the extent necessary to make it 
            enforceable, and such decision shall not affect the enforceability of 
            such provision under other circumstances, or of the remaining 
            provisions hereof under all circumstances.  The parties are 
            independent contractors.  No waiver by us of any breach of any 
            term or provision of this Agreement shall be construed to 
            be a waiver of any preceding or succeeding breach of the 
            same or any other term or provision hereof. 
        </h2>
      </div>
    )
  }
}

export default TermsOfService