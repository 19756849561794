import React from 'react'
import { Route } from 'react-router-dom'
import Home from './home/Home'
//import StartFreeTrial from './home/StartFreeTrial'
//import RequestQuote from './home/RequestQuote'
import EmailUnsub from './home/EmailUnsub'
import StudentBase from './student/StudentBase'
import RenderTestComponent from './home/RenderTestComponent'
import TermsOfService from './login/TermsOfService'
import SignIn from './login/SignIn'
import SignInLegacy from './login/SignInLegacy'
import SignOut from './login/SignOut'
import SignInClever from './login/SignInClever'
import DemoSignInClever from './login/DemoSignInClever'
import DemoSignInWithToken from './login/DemoSignInWithToken'
import SignedOutStudentClever from './login/SignedOutStudentClever'
import ForgotPassword from './login/ForgotPassword'
import EmailNotVerified from './login/EmailNotVerified'
import SetupAccount from './login/SetupAccount'
import UserManagement from './manageusers/UserManagement'
import VerifyEmail from './manageusers/VerifyEmail'
import AddInitialAdmin from './superadmin/AddInitialAdmin'
import SuperAdminQueries from './superadmin/SuperAdminQueries'
import SuperAdminIsSandbox from './superadmin/SuperAdminIsDistrictSandbox'
import SuperAdminSupportChat from './superadmin/SuperAdminSupportChat'
import SuperAdminContactUs from './superadmin/SuperAdminContactUs'
import SuperAdminGoalCount from './superadmin/SuperAdminGoalCount'
import SuperAdminTeacherVisibility from './superadmin/SuperAdminTeacherVisibility'
import SuperAdminDeletePerson from './superadmin/SuperAdminDeletePerson'
import SuperAdminHideAndLockPerson from './superadmin/SuperAdminHideAndLockPerson'
import SuperAdminGoals from './superadmin/SuperAdminGoals'
import SuperAdminLockPacingGuide from './superadmin/LockPacingGuide'
import SuperAdminPacingGuideFeedback from './superadmin/SuperAdminPacingGuideFeedback'
import SuperAdminQuizReview from './superadmin/SuperAdminQuizReview'
import SuperAdminDistrictSettings from './superadmin/SuperAdminDistrictSettings'
import SuperAdminQuestionReview from './superadmin/SuperAdminQuestionReview'
import SuperAdminTestReview from './superadmin/SuperAdminTestReview'
import ChangeDistrictSandboxStatus from './superadmin/ChangeDistrictSandboxStatus'
import AdminBase from './admin/AdminBase'
import AdminAddPerson from './admin/AdminAddPerson'
//import AdminDistrictSummary from './admin/AdminDistrictSummary'
import AdminIEPGoals from './admin/AdminIEPGoals'
import AdminSchedules from './admin/AdminSchedules'
import InstructionalFacilitatorBase from './instructionalfacilitator/InstructionalFacilitatorBase'
//import TeacherClassStatus from './teacher/TeacherClassStatus'
import TeacherIEPGoals from './teacher/TeacherIEPGoals'
// teacher progress monitoring
//import ProgressMonitoring from './teacher/progressmonitoring/ProgressMonitoring'
//import Scheduler from './teacher/scheduler/Scheduler'
// school admin
import SchoolAdminBase from './schooladmin/SchoolAdminBase'
// teacher add iep
import TeacherAddIEPGoalStart from './teacher/addiep/TeacherAddIEPGoalStart'
/*import TeacherAddIEPGoalStudent from './teacher/addiep/TeacherAddIEPGoalStudent'
import TeacherAddIEPGoalInformation from './teacher/addiep/TeacherAddIEPGoalInformation'
import TeacherAddIEPGoalAccommodations from './teacher/addiep/TeacherAddIEPGoalAccommodations'
import TeacherAddIEPGoalSelect from './teacher/addiep/TeacherAddIEPGoalSelect'
import TeacherAddIEPGoalModify from './teacher/addiep/TeacherAddIEPGoalModify'
import TeacherAddIEPGoalProgressMonitoring 
from './teacher/addiep/TeacherAddIEPGoalProgressMonitoring'
import TeacherAddIEPGoalService from './teacher/addiep/TeacherAddIEPGoalService'
import TeacherAddIEPGoalConfirm from './teacher/addiep/TeacherAddIEPGoalConfirm'*/
// teacher add student
import TeacherAddStudentContinue from './teacher/addstudent/TeacherAddStudentContinue'
// teacher student
//import TeacherStudent from './teacher/student/TeacherStudent'
// teacher reports
//import TeacherReports from './teacher/reports/TeacherReports'
//import StudentReport from './teacher/reports/StudentReport'
// teacher home
//import TeacherHome from './teacher/TeacherHome'
// teacher base
import TeacherBase from './teacher/TeacherBase'
// payments
//import CreateSandboxAccount from './login/CreateSandboxAccount'
import SandboxTrialEnded from './login/SandboxTrialEnded'
//import MeetingScheduler from './home/MeetingScheduler'
//import CreatePaidAccount from './login/CreatePaidAccount'
import RenewPaidAccount from './login/RenewPaidAccount'
import LockDistrict from './superadmin/LockDistrict'
import AccountIsLocked from './login/AccountIsLocked'

import 'antd/dist/antd.css'  // or 'antd/dist/antd.less'
import "core-js/stable"
import "regenerator-runtime/runtime"

class App extends React.Component {
  state = {

  }

  componentDidMount() {

  }

  render() {
    return (
      <div>
        <Route exact={true} path="/" component={Home} />
        {/*<Route path='/schedule-a-meeting' component={MeetingScheduler} />*/}
        <Route path="/terms-of-service-free-trial" component={TermsOfService} />
        {/*<Route path="/free-trial" component={CreateSandboxAccount} />*/}
        <Route path="/sandbox-trial-ended" component={SandboxTrialEnded} />
        {/*<Route path="/subscription" component={CreatePaidAccount} />*/}
        <Route path="/renew-subscription" component={RenewPaidAccount} />
        {/*<Route path="/request-quote" component={RequestQuote} />*/}
        <Route path="/unsub" component={EmailUnsub} />
        <Route path="/account-is-locked" component={AccountIsLocked} />
        <Route path="/user/management" component={UserManagement} />
        <Route path="/user/verify-email" component={VerifyEmail} />
        <Route path="/super-admin/add-initial-admin" component={AddInitialAdmin} />
        <Route path="/super-admin/search" component={SuperAdminQueries} />
        <Route path="/super-admin/is-sandbox" component={SuperAdminIsSandbox} />
        <Route path="/super-admin/lock-district" component={LockDistrict} />
        <Route path="/super-admin/support-chat" component={SuperAdminSupportChat} />
        <Route path="/super-admin/contact-us" component={SuperAdminContactUs} />
        <Route path="/super-admin/goal-count" component={SuperAdminGoalCount} />
        <Route path="/super-admin/change-sandbox-status" component={ChangeDistrictSandboxStatus} />
        <Route path="/super-admin/hide-teacher" component={SuperAdminTeacherVisibility} />
        <Route path="/super-admin/hide-and-lock-person" component={SuperAdminHideAndLockPerson} />
        <Route path="/super-admin/delete-person" component={SuperAdminDeletePerson} />
        <Route path="/super-admin/goals" component={SuperAdminGoals} />
        <Route path="/super-admin/lock-pacing-guide" component={SuperAdminLockPacingGuide} />
        <Route path="/super-admin/pacing-guide-feedback" component={SuperAdminPacingGuideFeedback} />
        <Route path="/super-admin/quiz-review" component={SuperAdminQuizReview} />
        <Route path="/super-admin/district-settings" component={SuperAdminDistrictSettings} />
        <Route path="/super-admin/question-bank-review" component={SuperAdminQuestionReview} />
        <Route path="/super-admin/test-review" component={SuperAdminTestReview} />
        <Route path="/admin/" component={AdminBase} />
        {/**<Route path="/admin/district" component={AdminDistrictSummary} />*/}
        <Route path="/admin/iep-goals" component={AdminIEPGoals} />
        <Route path="/admin/schedules" component={AdminSchedules} />
        <Route path="/admin/add-person" component={AdminAddPerson} />
        <Route path="/instructional-facilitator/" component={InstructionalFacilitatorBase} />
        <Route path="/school-admin/" component={SchoolAdminBase} />
        <Route path="/teacher" component={TeacherBase} />
        <Route path="/student" component={StudentBase} />
        {/**<Route path="/teacher/home/*" component={TeacherHome} />*/}
        <Route path="/teacher/iep-goals/*" component={TeacherIEPGoals} />
        {/**<Route path="/teacher/class/*" component={TeacherClassStatus} />*/}
        {/**<Route path='/teacher/student/*' component={TeacherStudent} />*/}
        {/**<Route path="/teacher/progress-monitoring/*" 
         component={ProgressMonitoring} />*/}
        {/**<Route path="/teacher/schedule/*" component={Scheduler} />*/}
        {/**<Route path="/teacher/reports/*" component={TeacherReports} />*/}
        {/**<Route path="/teacher/student-report/*"  component={StudentReport} />*/}
        <Route path="/teacher/add-goal-start/*" component={TeacherAddIEPGoalStart} />
        {/*<Route path="/teacher/add-goal-student/*" component={TeacherAddIEPGoalStudent} />
        <Route path="/teacher/add-goal-information/*" component={TeacherAddIEPGoalInformation} />
        <Route path="/teacher/add-goal-accommodations/*" 
        component={TeacherAddIEPGoalAccommodations} />
        <Route path="/teacher/add-goal-select/*" component={TeacherAddIEPGoalSelect} />
        <Route path="/teacher/add-goal-modify/*" component={TeacherAddIEPGoalModify} />
        <Route 
          path="/teacher/add-goal-progress-monitoring/*" 
          component={TeacherAddIEPGoalProgressMonitoring} 
        />
        <Route path="/teacher/add-goal-service/*" component={TeacherAddIEPGoalService} />
        <Route path="/teacher/add-goal-confirm/*" component={TeacherAddIEPGoalConfirm} />*/}
        <Route path="/teacher/add-student-continue" component={TeacherAddStudentContinue} />
        <Route path="/sign-in" component={SignIn} />
        <Route path='/sign-in-legacy' component={SignInLegacy} />
        <Route path="/sign-in-clever" component={SignInClever} />
        <Route path='/demo-sign-in' component={DemoSignInClever} />
        <Route path='/demo' component={DemoSignInWithToken} />
        <Route path='/signed-out-student-clever' component={SignedOutStudentClever} />
        <Route path="/sign-out-and-redirect" component={SignOut} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/email-not-yet-verified" component={EmailNotVerified} />
        <Route path="/setup-account" component={SetupAccount} />
        <Route path="/render-component" component={RenderTestComponent} />
      </div>
    )
  }
}

export default App
