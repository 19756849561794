import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import VideoContainer from '../video/VideoContainer'
import { firebase } from '../firebase/Firebase'
import { getInitials, isSandboxNoSub, isReadOnlyTeacherId } from '.././Util'
import { Layout, Icon, Menu, Avatar, Popover, message, Tooltip, Modal } from 'antd'
const { Header } = Layout
//const moment = require('moment')

const errorMessage = (description) => {
    message.error(description)
}

class TeacherHeaderForms extends Component {
    state = {
        newPopoverVisible: false,
        accountPopoverVisible: false,
        searchValue: '',
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    componentDidMount() {

    }

    componentWillReceiveProps(newProps) {
        // on url change set popovers visible to false
        this.setState({
            newPopoverVisible: false,
            accountPopoverVisible: false,
        })
    }

    newVisibleChange = (visible) => {
        this.setState({ newPopoverVisible: visible })
    }

    accountVisibleChange = (visible) => {
        this.setState({ accountPopoverVisible: visible })
    }

    signOut = () => {
        firebase.auth().signOut().then(function () {
            console.log('Signed Out')
            //sending users to the sign in page after signing out for now.
            window.location.href = "/sign-in"
        }, function (error) {
            console.error('Sign Out Error', error)
            errorMessage("There was an error signing you out. Please try again.")
        })
    }

    onChange = (value) => {
        console.log(`selected ${value}`)
    }

    onSelect = (value, option) => {
        console.log('on select', value, option)
        console.log('/teacher/student/' +
            this.props.person.id + '?student=' +
            option.key)

        this.props.history.push(
            {
                pathname: '/teacher/student/' +
                    this.props.person.id + '?student=' +
                    option.key
            }
        )
    }

    render() {
        return (
            <div>
                <Header
                    className={(this.props.hasOwnProperty("positionNotFixed") &&
                        this.props.positionNotFixed ? '' :
                        'position-fixed') +
                        (this.props.readOnly ? ' t-80' : '')}
                    style={{
                        padding: 0,
                        textAlign: 'left',
                        width: '100%',
                        zIndex: 2,
                        borderBottom: '1px solid #f1f1f1'
                    }}
                >
                    {this.props.person != null &&
                        Object.keys(this.props.person).length !== 0 &&
                        this.props.person.constructor === Object ?
                        <div>
                            <Modal
                                title="Upgrade Account"
                                visible={this.state.visible}
                                width={500}
                                footer={null}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                            >
                                <div className="font-20">
                                    <div className="mb-2">
                                        This feature is available with an upgraded account.
                                    </div>
                                    {isSandboxNoSub(this.props.teacherBaseProps) ?
                                        <Link to={"/teacher/subscribe/" +
                                            this.props.teacherBaseProps.teacher.id}
                                            onClick={this.hideModal}
                                            className={"ant-btn ant-btn-primary ant-btn-lg " +
                                                "font-bold-important font-18 w-100"}
                                        >
                                            Upgrade My Account
                                        </Link>
                                        : ''}
                                </div>
                            </Modal>
                            <Menu
                                selectedKeys={[this.props.selectedKey]}
                                mode="horizontal"
                                className="font-16 no-border-bottom flex flex-row"
                                style={{ lineHeight: '80px' }}
                            >
                                {this.props.readOnly ?
                                    <Menu.Item
                                        key="teacher-view-only"
                                        className="inline-flex flex-v-center menu-no-bb"
                                        style={{ marginRight: 48 }}
                                    >
                                        <Tooltip title="This information can be viewed only."
                                            placement="bottomLeft" mouseEnterDelay={.75}>
                                            <div>View only</div>
                                        </Tooltip>
                                    </Menu.Item>
                                    : ''}

                                {!this.props.readOnly ?
                                    <Menu.Item key="teacher-logo" className="inline-flex flex-v-center menu-no-bb p-0 ml-20 mr-3">
                                        {isReadOnlyTeacherId(this.props.person.id) ?
                                            <a href="https://dotit.app/">
                                                <img src='/dotitlogo.png' alt='logo' height='40' />
                                            </a>
                                            :
                                            <Link to={'/teacher/california-form/' + this.props.person.id}>
                                                <img src='/dotitlogo.png' alt='logo' height='40' />
                                            </Link>
                                        }
                                    </Menu.Item>
                                    : ''}


                                {!isReadOnlyTeacherId(this.props.person.id) ?
                                    <Menu.Item key="contact-us" className="inline-flex flex-center menu-no-bb p-0 cursor-default ml-auto" style={{ marginRight: '12px' }}>
                                        <div className="h-100 flex flex-v-center">
                                            <Link
                                                to={"/teacher/help/" + this.props.person.id}
                                                className="ant-btn ant-btn-lg font-bold pl-2 pr-2 inline-block-important">
                                                <span className="font-bold font-20">Need help?</span>
                                            </Link>
                                        </div>
                                    </Menu.Item>
                                    : ''}


                                {!isReadOnlyTeacherId(this.props.person.id) ?
                                    <Menu.Item key="teacher-sign-out"
                                        className="inline-flex flex-v-center menu-no-bb p-0 cursor-default">
                                        <Popover
                                            visible={this.state.accountPopoverVisible}
                                            onVisibleChange={this.accountVisibleChange}
                                            placement="bottomLeft"
                                            title={
                                                <div className="pt-1 pb-1">
                                                    <Avatar
                                                        size="large"
                                                        className="inline-block mr-8 avatar-vlg"
                                                        style={{ backgroundColor: '#d3adf7' }}
                                                        containerClassName="font-30 mt-05 no-text-highlight"
                                                        avatarClassName="font-30 font-bold"
                                                        personClassName="font-40 font-bold mt-1"
                                                    >
                                                        <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                                                    </Avatar>
                                                    <span className="font-20 font-bold vertical-align-middle">
                                                        {this.props.person.firstName + " " + this.props.person.lastName}
                                                    </span>
                                                    {this.props.user &&
                                                        this.props.user.hasOwnProperty("isSandbox") &&
                                                        this.props.user.isSandbox &&
                                                        this.props.user.hasOwnProperty("subscriptionDaysLeft") &&
                                                        this.props.user.subscriptionDaysLeft ?
                                                        <div className="mt-2">
                                                            {(this.props.user.subscriptionDaysLeft < 0 ?
                                                                0 :
                                                                this.props.user.subscriptionDaysLeft > 365 ?
                                                                    365 :
                                                                    this.props.user.subscriptionDaysLeft) +
                                                                " days remaining in subscription."}
                                                        </div>
                                                        : ''}

                                                </div>
                                            } content={
                                                <div className="p-0 border-right-none negate-popover-padding">
                                                    <div className="ant-menu-item p-0" onClick={this.signOut}>
                                                        <span className="font-16 p-2 block font-bold">
                                                            <span className="va-minus-1 font-20">Sign out</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            } trigger="click">
                                            <span className="h-80px inline-flex flex-v-center cursor-pointer mr-20">
                                                <span className="inline-block-important">
                                                    <Avatar
                                                        size="large"
                                                        style={{ backgroundColor: '#d3adf7' }}
                                                        className="avatar-vlg"
                                                        containerClassName="font-30 mt-05 no-text-highlight"
                                                        avatarClassName="font-30 font-bold"
                                                        personClassName="font-40 font-bold mt-1"
                                                    >
                                                        <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                                                    </Avatar>
                                                </span>
                                            </span>
                                        </Popover>
                                    </Menu.Item> :
                                    <div className="inline-flex flex-v-center">
                                        <div
                                            className="inline-flex flex-v-center menu-no-bb pr-20">
                                            <Popover
                                                visible={this.state.accountPopoverVisible}
                                                onVisibleChange={this.accountVisibleChange}
                                                placement="bottomLeft"
                                                title={
                                                    <div className="pt-1 pb-1">
                                                        <Avatar
                                                            size="large"
                                                            className="inline-block mr-8"
                                                            style={{ backgroundColor: '#d3adf7' }}
                                                        >
                                                            <span className="font-bold font-black">T</span>
                                                        </Avatar>
                                                        <span className="font-16 font-bold vertical-align-middle">
                                                            Example Teacher
                                                        </span>
                                                    </div>
                                                } content={
                                                    <div className="p-0 border-right-none negate-popover-padding">
                                                        <a href="https://dotit.app/" className="ant-menu-item p-0">
                                                            <span className="font-16 p-2 block font-bold">
                                                                <Icon type="arrow-left"
                                                                    className="mr-3 font-24 va-middle" />
                                                                <span className="va-minus-1">Back to home page</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                } trigger="click">
                                                <span className="h-80px inline-flex flex-v-center cursor-pointer">
                                                    <Avatar
                                                        size="large"
                                                        style={{ backgroundColor: '#d3adf7' }}
                                                    >
                                                        <span className="font-bold font-black">T</span>
                                                    </Avatar>
                                                </span>
                                            </Popover>
                                        </div>
                                    </div>
                                }

                            </Menu>
                        </div> : ''}
                </Header>
            </div>
        )
    }
}

export default TeacherHeaderForms