import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../../Types'
import Empty from '../.././customcomponents/Empty'
import { leastSquares, isGoalOnTrack, getFormattedProgressMonitoringText } from '../../Util'
import { notification, Icon, Button } from 'antd'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { Spin } from 'antd'
var moment = require('moment')

const successMessage = (description) => {
  notification.open({
    type: 'success',
    message: description,
    placement: 'bottomRight'
  })
}

const errorMessage = (description) => {
  notification.open({
    type: 'error',
    message: description,
    placement: 'bottomRight'
  })
}

const CustomLayer = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }, index) => {
    return <g>
      <path
        key={id}
        d={lineGenerator(
          data.map((d, i) => {
            return {
              x: xScale(d.data.x),
              y: yScale(d.data.y)
            }
          })
        )}
        fill="none"
        stroke={color}
        style={
          id === 'aimline'
            ? {
              // simulate line will dash stroke when index is even
              strokeDasharray: "7, 7",
              strokeWidth: 5,
            }
            : {
              // simulate line with solid stroke
              strokeWidth: 3
            }
        }
      />
      {data.map((d, i) => {
        let radius = 4
        if (id === 'aimline') {
          if (data.length > 1) {
            if (i !== 0 && i !== data.length - 1) {
              radius = 0
            }
          }
        }
        if (id === 'measurements') {
          radius = 4
        }
        return <circle
          transform={"translate(" + d.position.x + ", " + d.position.y + ")"}
          d={{
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }}
          r={radius}
          strokeWidth={radius}
          stroke={color}
          color={color}
          fill={color} />
      })}
    </g>
  })
}

class UIChart extends Component {
  state = {
    deletingMeasurements: {},
  }

  componentDidMount() {

  }

  componentWillReceiveProps() {

  }

  removeMeasurement = (measurement) => {
    // disabled doesn't work on the button because it is created dynamically
    // so this will prevent double clicks
    if (this.state.deletingMeasurements.hasOwnProperty(measurement.id)) {
      console.log("Already deleting measurement with id "
        + measurement.id + ". Returning early.")
      return
    }
    var deletingMeasurements = this.state.deletingMeasurements
    deletingMeasurements[measurement.id] = true
    this.setState({
      deletingMeasurements: deletingMeasurements,
    })
    var newMeasurements = this.props.measurements.filter(m => m.id !== measurement.id)
    console.log('old len', this.props.measurements.length, 'new len', newMeasurements.length)

    var totalMeasurements = newMeasurements.length
    var onTrack = isGoalOnTrack(this.props.iep.iep, newMeasurements)

    db.collection(ColType.measurements)
      .doc(measurement.id)
      .delete().then(() => {
        console.log("Document successfully deleted!")
        notification.destroy() // destroy previous notifications
        var updateObj = {
          totalMeasurements: totalMeasurements,
          onTrack: onTrack,
        }
        if (totalMeasurements.length > 0) {
          updateObj.latestMeasurementTimeStamp =
            newMeasurements[newMeasurements.length - 1].timeStamp
        }

        // update iep goal
        db.collection(ColType.iep)
          .doc(this.props.iep.id)
          .update(updateObj)

        var deletingMeasurements = this.state.deletingMeasurements
        delete deletingMeasurements[measurement.id]
        this.setState({
          deletingMeasurements: deletingMeasurements
        })
        successMessage("The measurement was successfully removed.") // show success message
      }).catch((error) => {
        console.error("Error removing document: ", error)
        notification.destroy() // destroy previous notifications
        errorMessage("The measurement could not be removed.") // show error message
      })
  }

  openNotification = (measurement) => {
    if (!this.props.allowMeasurementAdding) return
    notification.destroy() // destroy previous notifications

    const key = measurement.timeStamp
    const btn = (
      <Button
        type="primary"
        className="font-bold"
        size="large"
        onClick={() => this.removeMeasurement(measurement)}
      >
        <Icon type="delete" />Remove this measurement
      </Button>)

    notification.open({
      duration: 0,
      message: 'Remove this measurement',
      description: React.createElement('div',
        { id: 'removeMeasurementNotification', className: "text-align-left" }, '',
        React.createElement('div', { className: 'font-bold inline-block mt-3' }, 'Measurement value: '),
        React.createElement('span', {}, ' ' + measurement.measurement + '%'),
        React.createElement('div', {}, ''),
        React.createElement('div', { className: 'font-bold inline-block' }, 'Date added: '),
        React.createElement('span', {}, ' ' +
          moment.utc(measurement.timeStamp.seconds * 1000).format("MM/DD/YYYY")),
        React.createElement('div',
          { className: 'mt-2 mb-2' }, 'Once removed, this measurement cannot be recovered.')),
      icon: <Icon type="delete" className="font-bold" />,
      btn,
      key,
    })
  }

  render() {
    let progressMonitoringQuestions = getFormattedProgressMonitoringText(this.props.iep)

    /*var width = this.props.hasOwnProperty("containerWidth") && this.props.containerWidth ? this.props.containerWidth : 500
    var height = 400
    var top = 5,
        right = 50,
        left = 25,
        bottom = 100*/

    var data = [{
      seriesName: 'measurement', x: '0',
      y: this.props.iep.iep.baselineAccuracyLevel, index: 0,
      timeStamp: this.props.iep.timeStamp
    }]
    var goalData = [{
      seriesName: 'goal', x: '0',
      y: this.props.iep.iep.targetAccuracyLevel, index: 0,
      timeStamp: this.props.iep.timeStamp
    }]
    var xData = [0]
    var yData = [parseInt(this.props.iep.iep.baselineAccuracyLevel, 10)]
    var chartData = [
      {
        "id": "aimline",
        "color": "#52c41a",
        "data": []
      },
      {
        "id": "measurements",
        "color": "#1890ff",
        "data": []
      }]
    var questionData = []
    var questionDataKeys = []
    var questionDataColors = ["#ff85c0", "#b37feb", "#95de64", "#69c0ff", "#2f54eb", "#fa8c16", "#2f54eb"]
    // bimonthly measurements with a goal end time of one year means 2 * 12 or 24 measurements for a goal
    // can be more than that but that is the default.
    let aimlineMeasurementLen = 13
    //let slope = (this.props.iep.iep.targetAccuracyLevel - this.props.iep.iep.baselineAccuracyLevel) / (aimlineMeasurementLen + 1)
    let startMoment = moment.utc(this.props.iep.timeStamp.seconds * 1000)
    let completionMoment = moment.utc(this.props.iep.iep.completionDate)
    // if for some reason the start date is after the completion date,
    // make the start date one year before the completion date
    if (startMoment.isAfter(completionMoment)) {
      startMoment = completionMoment.clone().subtract(1, 'years')
    }
    let daysDiff = Math.abs(completionMoment.diff(startMoment, 'days'))
    let daysInterval = daysDiff / (aimlineMeasurementLen + 1)
    let slopeDays = (this.props.iep.iep.targetAccuracyLevel - this.props.iep.iep.baselineAccuracyLevel) / daysDiff
    let timestampCount = {}

    // add baseline
    let startTimeStamp = null
    if (this.props.iep.hasOwnProperty('timeStamp') && this.props.iep.timeStamp &&
      this.props.iep.timeStamp.hasOwnProperty('seconds') && this.props.iep.timeStamp.seconds) {
      startTimeStamp = startMoment.clone()
      chartData[0].data.push({
        x: startTimeStamp.format("MM/DD/YYYY"),
        y: parseInt(this.props.iep.iep.baselineAccuracyLevel, 10),
        originalIdx: -1,
        momentEpoch: startTimeStamp.valueOf(),
        dateString: startTimeStamp.format("MM/DD/YYYY"),
        isBaselinePoint: true,
        isFinalAimlinePoint: false,
      })

      for (let i = 0; i < aimlineMeasurementLen; i++) {
        let startTimeStampLocal = startMoment.clone()
        let weekTimeStamp = startTimeStampLocal.add(daysInterval * (i + 1), 'days')
        let startDaysDiff = weekTimeStamp.diff(startMoment, 'days')
        let aimlineValue = Math.round(parseInt(this.props.iep.iep.baselineAccuracyLevel, 10) + (startDaysDiff * slopeDays))
        if (aimlineValue > this.props.iep.iep.targetAccuracyLevel) {
          aimlineValue = this.props.iep.iep.targetAccuracyLevel
        }
        chartData[0].data.push({
          x: weekTimeStamp.format("MM/DD/YYYY"),
          y: aimlineValue,
          originalIdx: -1,
          momentEpoch: weekTimeStamp.valueOf(),
          dateString: weekTimeStamp.format("MM/DD/YYYY"),
          isBaselinePoint: false,
          isFinalAimlinePoint: false,
        })
      }

      let completionDateMoment = moment.utc(this.props.iep.iep.completionDate)
      chartData[0].data.push({
        x: completionDateMoment.format("MM/DD/YYYY"),
        y: parseInt(this.props.iep.iep.targetAccuracyLevel, 10),
        originalIdx: -1,
        momentEpoch: completionDateMoment.valueOf(),
        dateString: completionDateMoment.format("MM/DD/YYYY"),
        isBaselinePoint: false,
        isFinalAimlinePoint: true,
      })
    }
    this.props.measurements.map((item, index) => {
      if (!item.timeStamp) return false
      xData.push(index + 1)
      yData.push(parseInt(item.measurement, 10))
      data.push({
        seriesName: 'measurement',
        x: '' + (index + 1),
        y: item.measurement,
        timeStamp: item.timeStamp,
        measurement: item,
        index: index + 1,
      })
      goalData.push({
        seriesName: 'goal',
        x: '' + (index + 1),
        y: this.props.iep.iep.targetAccuracyLevel,
        timeStamp: item.timeStamp,
        measurement: item,
        index: index + 1,
      })
      // make sure there are no duplicate timestamps
      let timestampStr = moment.utc(item.timeStamp.seconds * 1000).format("MM/DD/YYYY")
      if (timestampCount.hasOwnProperty(timestampStr)) {
        timestampCount[timestampStr] += 1
      } else {
        timestampCount[timestampStr] = 1
      }
      if (timestampCount[timestampStr] === 1) {
        let timestampMoment = moment.utc(item.timeStamp.seconds * 1000)

        chartData[1].data.push({
          x: timestampMoment.format("MM/DD/YYYY"),
          y: parseInt(item.measurement, 10),
          originalIdx: index,
          momentEpoch: timestampMoment.valueOf(),
          dateString: timestampMoment.format("MM/DD/YYYY"),
          isBaselinePoint: false,
          isFinalAimlinePoint: false,
        })
      }
      var questionObj = {}
      questionObj.date = moment.utc(item.timeStamp.seconds * 1000).format("MM/DD/YYYY")
      item.progressMonitoringResult.map((pm, questionIndex) => {
        var key = "Performance criterion " + (questionIndex + 1) + ": " + progressMonitoringQuestions[questionIndex] + "."
        var color = "#1890ff"
        if (questionIndex <= questionDataColors.length - 1) {
          color = questionDataColors[questionIndex]
        }
        questionObj[key] = pm.num
        questionObj[key + "Color"] = color
        // only want one copy of the keys in this arr as they are the same
        // in every loop, so only push them into key arr if the index is 0
        // index is in the outer loop
        if (index === 0) {
          questionDataKeys.push(key)
        }
        return false
      })
      questionData.push(questionObj)
      /*chartData[1].data.push({
        x: '' + (index + 1), 
        y: 80, 
      })*/
      return false
    })

    questionDataKeys.reverse()

    var leastSquaresRes = leastSquares(xData, yData)
    var leastSquaresData = ['temporaryValue']
    xData.map((d, i) => {
      var measurement = i === 0 ? 0 : this.props.measurements[i - 1]
      /*chartData[2].data.push({
        x: '' + (i + 1), 
        y: (i)*leastSquaresRes[0] + leastSquaresRes[1],
      })*/
      return leastSquaresData.push({
        seriesName: 'aimLine',
        x: '' + (i),
        y: (i) * leastSquaresRes[0] + leastSquaresRes[1],
        measurement: measurement,
        index: i
      })
    })

    // measurements
    chartData[1].data.sort((a, b) => a.momentEpoch - b.momentEpoch)
    chartData[0].data.sort((a, b) => a.momentEpoch - b.momentEpoch)

    return (
      <div>
        {this.props.loadingNewData ?
          <div className={"flex flex-h-center" +
            " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
            <div>
              <Spin size="large" className="ant-spin-vlg cyan-spin" />
            </div>
          </div> :
          <div>
            <div>
              <div>
                <div className='page-break-inside-avoid'>
                  <h2 className="font-bold mb-2 font-20">
                    Progress Monitoring
                  </h2>
                  <div className="mt-3 ml-4 pl-3 font-black font-normal">
                    <span className="font-bold mr-05">Baseline Score:</span>
                    <span className="mr-1">{this.props.iep.iep.baselineAccuracyLevel}%</span>
                    <span className="font-bold mr-05">Target Score:</span>
                    <span className="mr-2">{this.props.iep.iep.targetAccuracyLevel}%</span>
                    {/*parseInt(data[data.length - 1].y, 10) >= parseInt(this.props.iep.iep.targetAccuracyLevel, 10) ?
                      <span className="font-bold font-green">Goal has been met!</span>
                      :
                      <span>Goal is {parseInt(this.props.iep.iep.targetAccuracyLevel, 10) - parseInt(data[data.length - 1].y, 10)}% away from being met</span>
                    */}
                  </div>
                  <div style={{ height: '350px' }}>
                    <ResponsiveLine
                      data={chartData}
                      //slice={'x'}
                      layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'points', CustomLayer, 'slices', 'mesh', 'legends']}
                      margin={{ top: 28, right: 60, bottom: 120, left: 60 }}
                      xScale={{
                        type: "time",
                        format: "%m/%d/%Y",
                      }}
                      xFormat="time:%m/%d/%Y"
                      yScale={{ type: 'linear', min: 0, max: 100, stacked: false, reverse: false }}
                      yFormat=" >-.2f"
                      colors={chartData.map(c => c.color)}
                      colorBy="index"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'bottom',
                        format: '%m/%d/%Y',
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 12,
                        tickRotation: 70,
                        legend: 'Date',
                        legendOffset: 105,
                        legendPosition: 'middle',
                      }}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: [0, 20, 40, 60, 80, 100],
                        tickRotation: 0,
                        legend: 'Score',
                        legendOffset: -40,
                        legendPosition: 'middle',
                      }}
                      pointSize={0}
                      pointColor={{ theme: 'background' }}
                      pointBorderWidth={5}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      onClick={(point, e) => {
                        if (point.data.serieId === 'aimline') {
                          return
                        }
                        if (point.data.originalIdx === -1) {
                          return
                        }
                        let idx = point.data.originalIdx

                        if (this.props.measurements &&
                          this.props.measurements.length > 0 &&
                          idx >= 0 &&
                          idx <= this.props.measurements.length - 1) {
                          this.openNotification(this.props.measurements[idx])
                        }
                      }}
                      tooltip={(input) => {
                        let tooltipTitle = 'Score:'
                        let y = input.point.data.y
                        /* index 0 is measurement 0 (baseline), index 1
                           is aimline 0 (baseline), should show score
                           instead of the aimline value, otherwise it will
                           show the aimline as it is rendered above the
                           score. */
                        if (input.point.serieId === 'aimline') {
                          if (this.props.measurements && input.point.data.isBaselinePoint) {
                            tooltipTitle = 'Baseline:'
                          } else if (this.props.measurements && input.point.data.isFinalAimlinePoint) {
                            tooltipTitle = 'Target:'
                          } else {
                            tooltipTitle = 'Aimline:'
                          }
                        }
                        return (
                          <div className="background-fff ant-shadow-more p-1">
                            <span className="font-bold mr-05">Date:</span>
                            <span>{input.point.data.dateString}</span>
                            <div>
                              <span className="font-bold mr-05">{tooltipTitle}</span>
                              <span>{y}%</span>
                            </div>
                          </div>
                        )
                      }}
                      theme={{
                        fontSize: 14,
                        axis: {
                          legend: {
                            text: {
                              fontSize: 14,
                            }
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='page-break-inside-avoid'>
                  <h2 className="font-bold mt-3 mb-2 font-24">Goal progress by performance criterion</h2>
                  {data.length === 1 ?
                    <div>
                      <Empty
                        containerClassName="mt-4 pt-2 text-center"
                        width={55}
                        height={55}
                        description={'No measurements to display.'}
                        image={'/empty/progress.png'}
                      />
                    </div>
                    :
                    <div style={{ height: '470px' }}>

                      <ResponsiveBar
                        data={questionData}
                        keys={questionDataKeys}
                        indexBy="date"
                        margin={{ top: 50, right: 60, bottom: 240, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={questionDataColors.map(c => {
                          return c
                        })}
                        colorBy={c => {
                          return c.id + "Color"
                        }}
                        defs={[
                          {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#1890ff',
                            size: 4,
                            padding: 1,
                            stagger: true
                          },
                          {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#52c41a',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                          },
                          {
                            id: 'squares',
                            type: 'patternSquares',
                            background: 'inherit',
                            color: '#722ed1',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                          },
                          {
                            id: 'linesHorizontal',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eb2f96',
                            rotation: 180,
                            lineWidth: 6,
                            spacing: 10
                          },
                        ]}
                        fill={[
                          {
                            match: {
                              id: 'Performance criterion 2'
                            },
                            id: 'lines'
                          },
                          {
                            match: {
                              id: 'Performance criterion 4'
                            },
                            id: 'linesHorizontal'
                          }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          orient: 'bottom',
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 70,
                          legend: 'Date',
                          legendOffset: 105,
                          legendPosition: 'middle'
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          tickValues: 5,
                          legend: 'Score by performance criterion',
                          legendPosition: 'middle',
                          legendOffset: -40
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                          {
                            dataFrom: 'keys',
                            anchor: 'bottom-left',
                            direction: 'column',
                            justify: false,
                            translateX: -20,
                            translateY: 240,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 22,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemOpacity: 1
                                }
                              }
                            ]
                          }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        theme={{
                          fontSize: 14,
                          axis: {
                            legend: {
                              text: {
                                fontSize: 14,
                              }
                            }
                          },
                          legends: {
                            text: {
                              fontSize: 18,
                              color: "#000",
                            }
                          }
                        }}
                      />
                    </div>}
                </div>
                {/*
      <div className="mt-3 font-normal">
        {this.props.measurements.length > 0 && 
         this.props.measurements[0].progressMonitoringResult.map((pm, questionIndex) => {
          return <div className="font-16 font-black" key={this.props.iep.id + " " + questionIndex}>
            <span className="mr-05">Skill {questionIndex + 1}:</span>
            <span className="mr-1">{progressMonitoringQuestions[questionIndex]}.</span>
          </div>
        })
        }
      </div>
      */}
              </div>
            </div>
          </div>}
      </div>
    )
  }
}

export default UIChart

