import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
import ColType from '../.././Types'
import { flattenDoc, getIDFromURL, getQueryStringParam, capitalizeFirstChar, getStrengths } from '../.././Util'
import { Layout, Button, Select, Form, Radio } from 'antd'
//import CustomFooter from '../../ login / CustomFooter'
import DraftStepContainer from './DraftStepContainer'
const { Content } = Layout
const Option = Select.Option
const FormItem = Form.Item
const RadioGroup = Radio.Group

class TeacherAddIEPGoalStudent extends Component {
  state = {
    teacherId: null,
    studentId: null,
    teacher: null,
    selectedStudent: null,
    students: [],
    studentDict: {},
    iepDrafts: [],
    tabKey: '1',
    iepDraft: null,
    iepDraftMounted: false,
    selectedSubjects: [],
    studentStrengths: getStrengths(),
    selectedMenuKey: 'currentDraft',
  }

  componentDidMount() {
    document.title = 'Draft IEP - Dot It'
    var teacherId = getIDFromURL(window.location)
    var draftId = getQueryStringParam('draft')
    var studentId = getQueryStringParam('student')
    var iepDraft = this.props.history.location.state
    console.log("mount",
      this.props.teacherBaseProps, teacherId, iepDraft)

    window.scrollTo(0, 0)

    this.setState({
      teacherId: teacherId,
      draftId: draftId,
      tabKey: '1',
    })

    // reload of page will not set the student initially because teacherBaseProps doens't have the studentDict yet.
    // I think this is fine because its rare and because I want to guarantee the student exists.
    if (!draftId && studentId && this.props.teacherBaseProps.studentDict && this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
      this.props.form.setFieldsValue({
        studentId: studentId,
      })

      this.setState({
        selectedStudent: this.props.teacherBaseProps.studentDict[studentId],
      })
    }

    if (draftId) {
      db.collection(ColType.iepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          this.setState({
            iepDraft: flattenDoc(doc),
            iepDraftMounted: true,
          }, () => {
            iepDraft = this.state.iepDraft
            if (iepDraft &&
              !(Object.keys(iepDraft).length === 0 &&
                iepDraft.constructor === Object)) {
              this.setState({
                selectedSubjects: iepDraft.subject,
              }, () => {
                var formObj = {
                  studentId: iepDraft.studentId,
                  subject: iepDraft.subject,
                  category: iepDraft.category,
                  level: iepDraft.selectedLevel,
                }
                Object.keys(iepDraft).map((key, idx) => {
                  if (key.indexOf("strength-") !== -1) {
                    formObj[key] = iepDraft[key]
                  }
                  return false
                })
                this.props.form.setFieldsValue(formObj)
              })
            }
          })
        })
    } else {
      this.setState({
        iepDraftMounted: true,
      })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)

        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        students.sort((a, b) => {
          if (a.grade === 'K' && b.grade === 'K') {
            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
              return 1
            } else {
              return -1
            }
          }
          if (a.grade === 'K' && b.grade !== 'K') return -1
          if (a.grade !== 'K' && b.grade === 'K') return 1
          if (a.grade === b.grade) {
            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
              return 1
            } else {
              return -1
            }
          }
          var aGrade = parseInt(a.grade, 10)
          var bGrade = parseInt(b.grade, 10)
          if (aGrade > bGrade) return 1
          if (bGrade > aGrade) return -1
          return 0
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  // add a new event to the teacher's events
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // only used for clicking back on this step to set the form value
        values.selectedLevel = values.level
        var belowSeventy = '70 and below'
        var belowEighty = '70-79'
        var aboveEighty = '80 and above'
        if (values.level < 70) values.level = belowSeventy
        else if (values.level < 80) values.level = belowEighty
        else values.level = aboveEighty

        values.studentId = values.studentId
        values.teacherId = this.state.teacher.id
        values.schoolId = this.state.teacher.schoolId
        values.districtId = this.state.teacher.districtId
        values.timeStamp = firebase.firestore.FieldValue.serverTimestamp()
        values.step = { stepNum: 1, path: 'information' }

        if (this.state.draftId) {
          db.collection(ColType.iepDrafts)
            .doc(this.state.draftId)
            .set(values)
            .then(() => {
              console.log("Document set with ID: ", this.state.draftId)
              this.props.history.push(
                {
                  pathname: '/teacher/add-goal-information/' +
                    this.state.teacherId + '?student=' +
                    values.studentId +
                    '&draft=' + this.state.draftId,
                  state: values, // pass state to confirm page
                }
              )
            })
            .catch((error) => {
              console.log(error)
            })
        }
        else {
          db.collection(ColType.iepDrafts)
            .add(values)
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id)
              this.props.history.push(
                {
                  pathname: '/teacher/add-goal-information/' +
                    this.state.teacherId + '?student=' +
                    values.studentId +
                    '&draft=' + docRef.id,
                  state: values, // pass state to confirm page
                }
              )
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  studentChange = (value, option) => {
    var student = this.state.studentDict &&
      this.state.studentDict.hasOwnProperty(value) ?
      this.state.studentDict[value] :
      null

    // remove reading foundations if the grade is >= 5
    var selectedSubjects = this.state.selectedSubjects
    for (var i = 0; i < selectedSubjects.length; i++) {
      var selectedSubject = selectedSubjects[i]
      if (selectedSubject === "Reading Foundations") {
        selectedSubjects.splice(i, 1)
        break
      }
    }
    this.setState({
      selectedStudent: student,
      selectedSubjects: selectedSubjects,
    })
  }

  subjectChange = (values) => {
    this.setState({
      selectedSubjects: values,
    })
  }

  draftTabOnChange = (key) => {
    this.setState({ selectedMenuKey: key })
  }

  menuClick = (e) => {
    this.setState({
      selectedMenuKey: e.key,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemBlockLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    let strengthKey = "K2"
    if (this.state.selectedStudent) {
      if (this.state.selectedStudent.grade >= 3 && this.state.selectedStudent.grade <= 5) {
        strengthKey = "35"
      }
      else if (this.state.selectedStudent.grade >= 6 && this.state.selectedStudent.grade <= 8) {
        strengthKey = "68"
      }
      else if (this.state.selectedStudent.grade >= 9 && this.state.selectedStudent.grade <= 12) {
        strengthKey = "912"
      }
    }


    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                {/* 
                <div className="text-center">
                  <img src="/draft-plan/3.png" alt="steps" />
                </div>
                */}
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={true}
                >
                  <div>
                    <div className="w-800 m-lr-auto background-fff br-15">
                      <div className='p-4 bkg-purple br-15'>
                        <div className='flex flex-v-center w-100'>
                          <div>
                            <h1 className="font-bold mb-0 font-40 font-fff">Draft an IEP</h1>
                            <h2 className="mb-3 font-24 font-fff">Student Information</h2>
                          </div>
                          <img src="/draft-icons/1.png" alt='draft-iep' width={250} className='ml-auto' />
                        </div>
                      </div>

                      <div className='p-4'>
                        <Form onSubmit={this.handleSubmit}
                          className="login-form text-align-left">
                          <FormItem {...formItemBlockLayout}
                            label={<div>
                              <div className='font-20 font-bold'>
                                Student
                              </div>
                              <div className='font-16 font-normal'>
                                To draft an IEP, select a student below.
                              </div>
                            </div>} className="block-label">
                            {getFieldDecorator('studentId', {
                              rules: [{ required: true, message: 'Select a student.' }],
                            })(
                              <Select
                                showSearch
                                className="ant-select-very-large"
                                placeholder="Select student"
                                onChange={this.studentChange}
                              >
                                {this.props.teacherBaseProps.students &&
                                  this.props.teacherBaseProps.students.map((student, index) => {
                                    return <Option value={student.id} key={"student-" + index}>
                                      <PersonAvatar
                                        size={'default'}
                                        person={student}
                                      />
                                    </Option>
                                  })
                                }
                              </Select>
                            )}
                          </FormItem>

                          {!this.state.selectedStudent ||
                            (this.state.selectedStudent &&
                              (this.state.selectedStudent.grade <= 5 ||
                                this.state.selectedStudent.grade === 'K' ||
                                this.state.selectedStudent.grade.toLowerCase() === 'kindergarten')) ?
                            <FormItem {...formItemBlockLayout}
                              label={<div>
                                <div className='font-20 font-bold'>
                                  Content areas
                                </div>
                                <div className='font-16 font-normal'>
                                  Select all content areas that apply. A goal will be generated for each content area selected.
                                </div>
                              </div>}
                              className="block-label">
                              {getFieldDecorator('subject', {
                                rules: [{
                                  required: true,
                                  message: 'Select all content areas that apply.'
                                }],
                              })(
                                <Select
                                  mode="multiple"
                                  size={'large'}
                                  placeholder="Select all content areas that apply"
                                  onChange={this.subjectChange}
                                >
                                  <Option value={'Reading Comprehension in Literature'}>
                                    Reading Comprehension in Literature
                                  </Option>
                                  <Option value={'Reading Comprehension in Informational Text'}>
                                    Reading Comprehension in Informational Text
                                  </Option>
                                  <Option value={'Reading Foundations'}>
                                    Reading Foundational Skills
                                  </Option>
                                  <Option value={'Math'}>
                                    Math
                                  </Option>
                                  <Option value={'Writing'}>
                                    Writing
                                  </Option>
                                  <Option value={'Social Emotional Learning'}>
                                    Social Emotional Learning
                                  </Option>
                                </Select>
                              )}
                            </FormItem>
                            :
                            <FormItem {...formItemBlockLayout}
                              label={<div>
                                <div className='font-20 font-bold'>
                                  Content areas
                                </div>
                                <div className='font-16 font-normal'>
                                  Select all content areas that apply. A goal will be generated for each content area selected.
                                </div>
                              </div>}
                              className="block-label">
                              {getFieldDecorator('subject', {
                                rules: [{
                                  required: true,
                                  message: 'Select all content areas that apply.'
                                }],
                              })(
                                <Select
                                  mode="multiple"
                                  size={'large'}
                                  placeholder="Select all content areas that apply"
                                  onChange={this.subjectChange}
                                >
                                  <Option value={'Reading Comprehension in Literature'}>
                                    Reading Comprehension in Literature
                                  </Option>
                                  <Option value={'Reading Comprehension in Informational Text'}>
                                    Reading Comprehension in Informational Text
                                  </Option>
                                  <Option value={'Math'}>
                                    Math
                                  </Option>
                                  <Option value={'Writing'}>
                                    Writing
                                  </Option>
                                  <Option value={'Social Emotional Learning'}>
                                    Social Emotional Learning
                                  </Option>
                                </Select>
                              )}
                            </FormItem>
                          }


                          <FormItem {...formItemBlockLayout} label={<div>
                            <div className='font-20 font-bold'>
                              Impact
                            </div>
                            <div className='font-16 font-normal'>
                              Based on the student's psychological evaluation, access to the general curriculum is impacted most by weaknesses in this impact category.
                            </div>
                          </div>}
                            className="block-label">
                            {getFieldDecorator('category', {
                              rules: [{ required: true, message: 'Select greatest impact.' }],
                            })(
                              <Select
                                size={'large'}
                                placeholder="Select impact">
                                <Option value={'WMI'}>
                                  Working Memory
                                </Option>
                                <Option value={'PSI'}>
                                  Processing Speed
                                </Option>
                                <Option value={'FRI'}>
                                  Fluid Reasoning
                                </Option>
                                <Option value={'VSI'}>
                                  Visual Spatial Processing
                                </Option>
                                <Option value={'VCI'}>
                                  Verbal Comprehension
                                </Option>
                              </Select>
                            )}
                          </FormItem>

                          <FormItem {...formItemBlockLayout}
                            label={"On this index, the student scored"}
                            className="block-label">
                            {getFieldDecorator('level', {
                              rules: [{ required: true, message: 'Select score' }],
                            })(
                              <RadioGroup>
                                <Radio className="block mt-05" value={69}>
                                  <span class='font-16 va-minus-3'>
                                    Extremely Low (69 or below)
                                  </span>
                                </Radio>
                                <Radio className="block mt-05" value={79}>
                                  <span class='font-16 va-minus-3'>
                                    Very Low (70-79)
                                  </span>
                                </Radio>
                                <Radio className="block mt-05" value={89}>
                                  <span class='font-16 va-minus-3'>
                                    Low Average (80-89)
                                  </span>
                                </Radio>
                                <Radio className="block mt-05" value={109}>
                                  <span class='font-16 va-minus-3'>
                                    Average (90-109)
                                  </span>
                                </Radio>
                                <Radio className="block mt-05" value={119}>
                                  <span class='font-16 va-minus-3'>
                                    High Average (110-119)
                                  </span>
                                </Radio>
                                <Radio className="block mt-05" value={129}>
                                  <span class='font-16 va-minus-3'>
                                    Very High (120-129)
                                  </span>
                                </Radio>
                              </RadioGroup>
                            )}
                          </FormItem>

                          {this.state.selectedSubjects.length > 0 ?
                            <div className="font-30 mb-2 font-black">
                              Student strengths by content area
                            </div>
                            : ''}
                          {this.state.selectedSubjects.map((subject, idx) => {
                            return <FormItem {...formItemBlockLayout} label={<div>
                              <div className='font-20 font-bold'>
                                {subject}
                              </div>
                              <div className='font-16 font-normal'>
                                Choose a strength the student has in {subject}.
                              </div>
                            </div>}
                              className="block-label">
                              {getFieldDecorator('strength-' + subject, {
                                rules: [{ required: true, message: 'Select greatest impact.' }],
                              })(
                                <Select
                                  size={'large'}
                                  placeholder="Select strength..."
                                  key={'strength-select-' + subject}>
                                  {this.state.studentStrengths[strengthKey][subject].map((strength, idx) => {
                                    return <Option value={strength} key={'strength-option-' + subject + '-' + strength + '-' + idx}>
                                      {capitalizeFirstChar(strength)}
                                    </Option>
                                  })}
                                </Select>
                              )}
                            </FormItem>
                          })}

                          <FormItem className="mb-0">
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="lg-btn float-right"
                            >
                              Continue
                            </Button>
                          </FormItem>
                        </Form>
                      </div>
                    </div>
                  </div>
                </DraftStepContainer >

              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalStudent)