import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { Layout } from 'antd'
const { Content } = Layout

class AccountIsLocked extends Component {
  state = {
    teacherId: '',
    teachers: [],
    students: [],
    school: null,
    IEPGoals: [],
    studentIEPStatus: [],
    profileMode: 1,
    componentMounted: false,
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Account is locked.'
  }

  render() {
    console.log(this.props)
    return (
      <div className="home-page">
        <div>
          <Layout className="content layout-header-mt">

            <Layout>
              <Content className="layout-content">
                <div className="w-500 m-lr-auto">
                  <a href="https://dotit.app/" className='mb-2 inline-block'>
                    <img src='/dot-it-logo.svg' alt='logo' height='48' />
                  </a>
                  <div className="font-30 mb-2">{"Your account is locked because " +
                    "your district or school discontinued Dot It."}</div>
                  <div className="font-24">{"Your data is still safe and will " +
                    "be available when your district or school continues with Dot It."}</div>
                  <div className="font-24">{"If you enjoyed Dot It, please speak with " +
                    "your principal or district adminstrator about purchasing Dot It."}</div>
                  <div className="mt-4 font-24">Thank you!</div>
                  <a href="https://dotit.app/" className="ant-btn ant-btn-primary ant-btn-lg mt-2">
                    <span className="font-bold font-18">
                      Take me back to the home page</span></a>
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    )
  }
}

export default AccountIsLocked