import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import EditNoteForm from './EditNoteForm'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import PersonAvatar from '../../customcomponents/PersonAvatar'
var moment = require('moment')

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class SubjectNote extends Component {
    state = {
        submitting: false,
    }

    componentDidMount() {

    }

    deleteNote = () => {
        this.setState({
            submitting: false,
        }, () => {
            let collectionName = ColType.elaNotes
            if (this.props.subject === 'math') {
                collectionName = ColType.mathNotes
            }
            db.collection(collectionName)
                .doc(this.props.note.id)
                .delete()
                .then(() => {
                    console.log('Document updated')
                    successMessage('Note removed successfully.')
                })
                .catch((error) => {
                    console.error('Error adding document: ', error)
                    errorMessage("Something went wrong when removing the note.")
                    this.setState({
                        submitting: true,
                    })
                })
        })
    }

    render() {
        let collectionName = ''
        if (this.props.subject === 'math') {
            collectionName = ColType.mathNotes
        } else if (this.props.subject === 'ela') {
            collectionName = ColType.elaNotes
        }
        console.log(this.props.subject, collectionName)
        return (
            <div className="pt-2 pb-2 font-16 relative">
                {this.props.note && this.props.note.timeStamp ?
                    <div>
                        <div className={"absolute-tr p-2" +
                            (this.props.allowDelete ? '' : ' display-none')}>
                            <EditNoteForm
                                note={this.props.note}
                                collectionName={collectionName}
                            />
                            <Tooltip title="Delete note">
                                <Popconfirm
                                    title="Are you sure to delete this note?"
                                    onConfirm={this.deleteNote}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button shape="circle" icon="close" size={'large'}
                                        disabled={this.state.submitting}
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </div>
                        <div>
                            <div className="flex flex-v-center font-16">
                                {this.props.note.hasOwnProperty('originalTeacher') &&
                                    this.props.note.originalTeacher ?
                                    this.props.note.originalTeacher :
                                    this.props.teacher ?
                                        <PersonAvatar
                                            person={this.props.note.hasOwnProperty('originalTeacher') &&
                                                this.props.note.originalTeacher ?
                                                this.props.note.originalTeacher :
                                                this.props.teacher}
                                            size={'large'}
                                            avatarColor={'#d3adf7'}
                                            containerClassName="font-20 mt-6px no-text-highlight"
                                            avatarClassName="font-20 font-bold avatar-vlg"
                                            personClassName="font-bold"
                                        />
                                        :
                                        <div className='font-20'>Teacher not found</div>}
                                <div className="text-muted ml-1 mr-1 mt-1">
                                    -
                                </div>
                                <div className="text-muted mt-1 font-20">
                                    {moment.utc(this.props.note.timeStamp.seconds * 1000).format("MM/DD/YYYY")}
                                </div>
                            </div>
                            <div className="mb-1 font-20" style={{ marginLeft: '58px' }}>
                                {this.props.note.message}
                            </div>
                        </div>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default SubjectNote
