import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import SuperAdminHeader from './SuperAdminHeader'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import ColType from '.././Types'
import { Icon, Form, Input, Button } from 'antd'
const FormItem = Form.Item

// This page can only be accessed by us, it is how you add admins that then can add other users.
class SuperAdminGoalCount extends Component {
  state = {
    districtInfo: [],
    teachers: [],
  }

  componentDidMount() {

  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        db.collection(ColType.district)
          .where('district', '==', values.districtName)
          .get()
          .then((querySnapshot) => {
            var districts = []
            querySnapshot.forEach((doc) => {
              var district = flattenDoc(doc)
              districts.push(district)
            })
            
            console.log("found districts", districts)

            var districtInfo = []

            districts.map((district, i) => {
              return db.collection(ColType.admin)
                .where('districtId', '==', district.id)
                .limit(1)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    var admin = flattenDoc(doc)
                    let districtObj = {
                      district: district,
                      admin: admin,
                    }
                    districtInfo.push(districtObj)

                    console.log("found admin in district", admin)
                    if (this.props.hasOwnProperty("onDistrictFound")) {
                      this.props.onDistrictFound(districtObj)
                    }
                    this.setState({
                      districtInfo: districtInfo,
                    })

                    db.collection(ColType.teacher)
                        .where('districtId', '==', district.id)
                        .get()
                        .then((querySnapshot) => {
                            var teachers = []
                            querySnapshot.forEach((doc) => {
                              var teacher = flattenDoc(doc)
                              teacher.numGoals = 0
                              teachers.push(teacher)
                            })

                            this.setState({
                                teachers: teachers,
                            })

                            teachers.map((teacher, idx) => {
                                db.collection(ColType.iep)
                                  .where("teacherId", "==", teacher.id)
                                  .get()
                                  .then((querySnapshot) => {
                                    var goals = []
                                    querySnapshot.forEach((doc) => {
                                      var goal = flattenDoc(doc)
                                      goals.push(goal)
                                    })
                                    
                                    teacher.numGoals = goals.length

                                    this.setState({
                                        teachers: teachers,
                                    })
                                })

                                return false
                            })
                        })
                  })
                })
            })
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 25 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 25 },
        sm: { span: 17 },
      },
    }

    var teachers = [...this.state.teachers]
    teachers.sort((a, b) => {
        if (!a.hasOwnProperty("numGoals")) {
            return 0
        }
        else if (!b.hasOwnProperty("numGoals")) {
            return 0
        }
        return b.numGoals - a.numGoals
    })

    return (
      <div>
      <SuperAdminHeader menuKey={'goal-count'} />
      <div className="mt-100 p-4">
        <div className="font-30 font-bold">
            Find out which teachers have goals in a district.
        </div>
        <div className="font-24 font-bold mb-3">Search for a district</div>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem {...formItemLayout} label="District name">
            {getFieldDecorator('districtName', {
              rules: [{ required: true, message: 'Please input a district name.' }],
            })(
              <Input
                size={"large"}
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="District name..." />
            )}
          </FormItem>
          <FormItem className="mb-0">
            <Button
              size={"large"}
              type="primary"
              htmlType="submit"
              className="login-form-button text-align-center w-100"
            >
              Search for a district
            </Button>
          </FormItem>
        </Form>

        <div className="mt-3 pt-3 border-top">
          <h2>Search Results</h2>
          {this.state.districtInfo.length === 0 ?
          <h3>No results</h3> :
          ''}
          {this.state.districtInfo.map((districtInfo, i) => {
            return <div
              className="p-2 font-16 border br-4 w-100 inline-block mb-1"
            >
              <div className="font-bold mb-05">
                District: {districtInfo.district.district}
              </div>
              {teachers.map((teacher, idx) => {
                return <div>
                    {teacher.firstName + " " + teacher.lastName +
                    " (" + teacher.emailAddress + "): " + teacher.numGoals}
                </div>
              })}
            </div>
          })}
        </div>
      </div>
      </div>
    )
  }
}

export default Form.create()(SuperAdminGoalCount)