import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'


class FormSteps extends Component {
    state = {
        stepsInfo: [
            {
                linkTo: '/teacher/california-form-student/',
                header: 'Student Information',
            },
            {
                linkTo: '/teacher/california-form-step-one/',
                header: 'Student Strengths, Interests, Preferences, and Learning Needs',
            },
            {
                linkTo: '/teacher/california-form-step-two/',
                header: 'Vision for the Future and Pathway to a High School Diploma',
            },
        ]
    }

    render() {
        return (
            <div>
                <div>
                    <div className='font-20 font-bold mb-2'>Quick Actions</div>
                    <Link
                        to={'/teacher/california-form/' + this.props.teacherId}
                        className={'p-3 mb-3 block br-15 font-black font-16 ant-shadow ant-shadow-more-hover flex flex-v-center'}
                    >
                        <img alt='icon' src='/california/home.png' width={75} height={75} className='mr-1' />
                        <div className='font-bold'>Back to plans</div>
                    </Link>
                </div>
                <div className='font-20 font-bold mb-2'>IEP Summary</div>
                {this.props.teacherId &&
                    this.props.draft &&
                    this.state.stepsInfo.map((stepInfo, index) => {
                        let completedStep = this.props.draft.step > index
                        //let currentStep = this.props.draft.step === index
                        let disabledStep = this.props.draft.step < index
                        return <Link
                            to={stepInfo.linkTo + this.props.teacherId + '?draft=' + this.props.draft.id}
                            className={'p-3 mb-2 block br-15 font-16 ant-shadow ant-shadow-more-hover flex flex-v-center'}
                            style={completedStep ?
                                { border: '1px solid #006d75', background: '#e6fffb', color: '#000' } :
                                disabledStep ?
                                    { border: '1px solid transparent', color: '#888' } :
                                    { border: '1px solid transparent', color: '#000' }
                            }
                            disabled={disabledStep}
                        >
                            <div>
                                {completedStep ?
                                    <img alt='icon' src='/california/check.png' width={75} height={75} className='mr-1' /> :
                                    <img alt='icon' src={'/california/' + (index + 1) + '.png'} width={75} height={75} className='mr-1' />
                                }
                            </div>
                            <div>
                                {completedStep ?
                                    <div alt='icon' className='font-bold mb-05' style={{ color: '#006d75' }}>{'Completed'}</div> :
                                    <div alt='icon' className='font-bold mb-05'>{'Step ' + (index + 1)}</div>
                                }
                                <div>{stepInfo.header}</div>
                            </div>
                        </Link>
                    })}
            </div>
        )
    }
}

export default FormSteps