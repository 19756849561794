import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import Empty from '../customcomponents/Empty'
import CustomFooter from '../login/CustomFooter'
import { Layout, Row, Col } from 'antd'
const { Content } = Layout

class InstructionalFacilitatorSchools extends Component {
  state = {

  }

  // Do fetch here
  componentDidMount() {
    document.title = 'School Summaries - dotit'
  }

  render() {
    console.log(this.props)
    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content">
              <div className="w-1024 m-lr-auto">
                <h1 className="flex flex-v-center mb-3 pb-3 border-bottom font-bold">
                  <img src='/school-icon.svg' 
                  className='mr-2'
                  width={50} height={50} 
                  alt='schools'/>
                  <span className="mt-1">Choose a school</span>
               </h1>
               <Row gutter={128} className="ml-0">
                <Col span={6} className="pl-0">
                  <h2 className="text-center mb-2 font-bold">Elementary Schools</h2>
                  {this.props.instructionalFacilitatorBaseProps.schoolTypes ?
                  this.props.instructionalFacilitatorBaseProps
                  .schoolTypes.elementarySchool.length === 0 ?
                  <Empty 
                            containerClassName="mt-4 pt-2 text-center"
                            width={55}
                            height={55}
                          />
                  :
                  this.props.instructionalFacilitatorBaseProps
                  .schoolTypes.elementarySchool.map((school, index) => {
                    return <div className="pl-0" key={school.id}>
                       <Link 
                          to={'/instructional-facilitator/school-summary/' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitatorId +
                          '?district=' + this.props.instructionalFacilitatorBaseProps
                          .instructionalFacilitator.districtId + 
                          '&school=' + school.id}
                          className={"w-100 br-4 h-150 up-hover" +
                          " shadow-hover mb-2 ant-btn" +
                          " ant-btn relative parent-hover"}
                        >
                          <div className="w-100 h-100 flex flex-center">
                            <div className="w-100 font-18 white-space-prewrap">
                              {school.schoolName}
                            </div>
                          </div>
                        </Link>
                    </div>
                  })
                  : ''}
                </Col>
                <Col span={6} className="pl-0">
                  <h2 className="text-center mb-2 font-bold">K-8 Schools</h2>
                  {this.props.instructionalFacilitatorBaseProps.schoolTypes ?
                  this.props.instructionalFacilitatorBaseProps.schoolTypes.k8School.length === 0 ?
                  <Empty 
                            containerClassName="mt-4 pt-2 text-center"
                            width={55}
                            height={55}
                          />
                  :
                  this.props.instructionalFacilitatorBaseProps
                  .schoolTypes.k8School.map((school, index) => {
                    return <div className="pl-0" key={school.id}>
                       <Link 
                          to={'/instructional-facilitator/school-summary/' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitatorId +
                          '?district=' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitator.districtId + 
                          '&school=' + school.id}
                          className={"w-100 br-4 h-150 up-hover" +
                          " shadow-hover mb-2 ant-btn" +
                          " ant-btn relative parent-hover"}
                        >
                          <div className="w-100 h-100 flex flex-center">
                            <div className="w-100 font-18 white-space-prewrap">
                              {school.schoolName}
                            </div>
                          </div>
                        </Link>
                    </div>
                  })
                  : ''}
                </Col>
                <Col span={6} className="pl-0">
                <h2 className="text-center mb-2 font-bold">Middle Schools</h2>
                  {this.props.instructionalFacilitatorBaseProps.schoolTypes ?
                  this.props.instructionalFacilitatorBaseProps
                  .schoolTypes.middleSchool.length === 0 ?
                  <Empty 
                  containerClassName="mt-4 pt-2 text-center"
                  width={55}
                  height={55}
                />
                  :
                  this.props.instructionalFacilitatorBaseProps
                  .schoolTypes.middleSchool.map((school, index) => {
                    return <div className="pl-0 max-w-100" key={school.id}>
                       <Link 
                          to={'/instructional-facilitator/school-summary/' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitatorId +
                          '?district=' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitator.districtId + 
                          '&school=' + school.id}
                          className={"w-100 max-w-100 br-4 h-150 up-hover" +
                          " shadow-hover mb-2 ant-btn" +
                          " ant-btn relative parent-hover"}
                        >
                          <div className="w-100 h-100 flex flex-center flex-wrap">
                            <div className="w-100 font-18 white-space-prewrap">
                              {school.schoolName}
                            </div>
                          </div>
                        </Link>
                    </div>
                  })
                  : ''}
                </Col>
                <Col span={6} className="pl-0">
                <h2 className="text-center mb-2 font-bold">High Schools</h2>
                  {this.props.instructionalFacilitatorBaseProps.schoolTypes ?
                  this.props.instructionalFacilitatorBaseProps.schoolTypes.highSchool.length === 0 ?
                  <Empty 
                  containerClassName="mt-4 pt-2 text-center"
                  width={55}
                  height={55}
                />
                  :
                  this.props.instructionalFacilitatorBaseProps
                  .schoolTypes.highSchool.map((school, index) => {
                    return <div className="pl-0" key={school.id}>
                       <Link 
                          to={'/instructional-facilitator/school-summary/' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitatorId +
                          '?district=' + this.props
                          .instructionalFacilitatorBaseProps.instructionalFacilitator.districtId + 
                          '&school=' + school.id}
                          className={"w-100 br-4 h-150 up-hover" +
                          " shadow-hover mb-2 ant-btn" +
                          " ant-btn relative parent-hover"}
                        >
                          <div className="w-100 h-100 flex flex-center flex-wrap">
                            <div className="w-100 font-18 white-space-prewrap">
                              {school.schoolName}
                            </div>
                          </div>
                        </Link>
                    </div>
                  })
                  : ''}
                </Col>
               </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default InstructionalFacilitatorSchools