import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import Empty from '../customcomponents/Empty'
import CustomFooter from '../login/CustomFooter'
//import ColType from '../Types'
import { Layout } from 'antd'
const { Content } = Layout

class AdminSchoolsSelect extends Component {
    state = {

    }

    // Do fetch here
    componentDidMount() {
        document.title = "Schools - Dot It"
        window.scrollTo(0, 0)
    }

    componentWillReceiveProps(props, newProps) {

    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content>
                            <div className="sub-menu-width m-lr-auto pt-3 pb-3">
                                <h1 className="font-bold font-30">Schools</h1>
                                {this.props.adminBaseProps &&
                                    this.props.adminBaseProps.schools &&
                                    this.props.adminBaseProps.schools.length === 0 ?
                                    <Empty
                                        containerClassName="mt-4 pt-2 text-center font-16"
                                        width={55}
                                        height={55}
                                        description={'No data'}
                                    /> : ''}
                                {this.props.adminBaseProps &&
                                    this.props.adminBaseProps.schoolTypes &&
                                    Object.keys(this.props.adminBaseProps.schoolTypes).map((schoolType, idx) => {
                                        /*var schoolTypeStr = ""
                                        if (schoolType === "elementarySchool") {
                                            schoolTypeStr = "Elementary Schools"
                                        } else if (schoolType === "k8School") {
                                            schoolTypeStr = "K8 Schools"
                                        } else if (schoolType === "middleSchool") {
                                            schoolTypeStr = "Middle Schools"
                                        } else if (schoolType === "highSchool") {
                                            schoolTypeStr = "High Schools"
                                        }*/
                                        return <div id={schoolType}>
                                            {this.props.adminBaseProps.schoolTypes[schoolType].map((school, idx) => {
                                                return <div id={school.id} key={school.id}>
                                                    <Link to={"/admin/admin-school-view/" + this.props.adminBaseProps.admin.id + "?school=" + school.id}>
                                                        <div className="p-3 br-4 font-20 font-bold font-black border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center">
                                                            {school.schoolName}
                                                        </div>
                                                    </Link>
                                                </div>
                                            })}
                                        </div>
                                    })}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default AdminSchoolsSelect