export default {
  users: 'users',
  admin: 'admins',
  teacher: 'teachers',
  instructionalFacilitator: 'instructionalFacilitators',
  schoolAdmin: 'schoolAdmins',
  student: 'students',
  district: 'districts',
  iep: 'iep',
  iepDrafts: 'iepDrafts',
  iepInfo: 'iepinfo',
  iepStandards: 'versionfiveIEPStandards',
  pep: 'pep',
  pepDrafts: 'pepDrafts',
  pepInfo: 'pepinfo',
  pepStandards: 'versionfourPEPStandards',
  school: 'schools',
  calendarEvents: 'calendarEvents',
  calendarBlocks: 'calendarBlocks',
  measurements: 'measurements',
  notes: 'notes',
  elaNotes: 'elaNotes',
  mathNotes: 'mathNotes',
  time: 'time',
  schoolSummary: 'schoolSummary',
  districtSummary: 'districtSummary',
  schoolStats: 'schoolStats',
  districtStats: 'districtStats',
  requestQuote: 'requestQuote',
  sandboxAccounts: 'sandboxAccounts',
  supportDesk: 'supportDesk',
  supportDeskActive: 'supportDeskActive',
  meetingTimes: 'meetingTimes',
  meetingTimesFullInformation: 'meetingTimesFullInformation',
  mailChimp: 'mailChimp',
  meetingEvents: 'meetingEvents',
  uploadedfiles: 'uploadedFiles',
  records: 'records',
  paymentStatus: 'paymentStatus',
  paymentInfo: 'paymentInfo',
  renewPaymentInfo: 'renewPaymentInfo',
  teacherStats: 'teacherStats',
  defaultCodes: 'defaultCodes',
  accountSetup: 'accountSetup',
  contactUs: 'contactUs',
  transferStudent: 'transferStudent',
  chatDirectMessage: 'chatDirectMessages',
  chatGroup: 'chatGroups',
  chatGroupMessage: 'chatGroupMessages',
  chatNotification: 'chatNotifications',
  teacherTransfers: 'teacherTransfers',
  studentTransfers: 'studentTransfers',
  iepTransfers: 'iepTransfers',
  archivedStudents: 'archivedStudents',
  deletedStudents: 'deletedStudents',
  permanentlyDeletedStudents: 'permanentlyDeletedStudents',
  coachesNotes: 'coachesNotes',
  generalNotifications: 'generalNotifications',
  californiaForm: 'californiaForm',
  schoolReport: 'schoolReport',
  schoolProgressReport: 'schoolProgressReport',
  schoolPlanningReport: 'schoolPlanningReport',
  schoolPracticeReport: 'schoolPracticeReport',
  SAMScore: 'SAMScore',
  cleverUsers: 'cleverUsers',
  cleverStudents: 'cleverStudents',
  cleverStudentsViewOnly: 'cleverStudentsViewOnly',
  cleverAssessments: 'cleverAssessments',
  cleverStudentAssessments: 'cleverStudentAssessments',
  cleverQuizGrades: 'cleverQuizGrades',
  pacingGuideResourceUploads: 'pacingGuideResourceUploads',
  scStandards: 'scStandardsV2',
  kentuckyStandards: 'kentuckyStandardsV3',
  pacingGuides: 'pacingGuides',
  pacingGuideUploadedFiles: 'pacingGuideUploadedFiles',
  pacingGuideFeedback: 'pacingGuideFeedback',
  weeklyPlanner: 'weeklyPlanner',
  lessonPlans: 'lessonPlans',
  lessonPlanSC: 'lessonPlanSC2023',
  assessmentSC: 'assessmentSC2023',
  workshopObservationTool: 'workshopObservationTool',
  sections: 'sections',
  assessmentStatistics: 'assessmentStatistics',
  assessmentStatReports: 'assessmentStatReports',
  entireAssessmentStatReports: 'entireAssessmentStatReports',
  districtSettings: 'districtSettings',
  questionReview: 'questionReview',
  superAdminDistrictSettings: 'superAdminDistrictSettings',
  weeklyPlannerUploadedFiles: 'weeklyPlannerUploadedFiles',
  coachingReports: 'coachingReports',
  questionBank: 'questionBank',
  questionBankPassages: 'questionBankPassages',
  quizzes: 'quizzes',
  standards: 'standards',
  standardSettings: 'standardSettings',
  questionStatus: 'questionStatus',
  standardYears: 'standardYears',
  futurePacingGuides: 'futurePacingGuides',
  plc: 'plc',
  userSettings: 'userSettings',
  professionalLearningReport: 'professionalLearningReport',
  consultationReport: 'consultationReport'
}