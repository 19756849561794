import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import { Layout, Icon, Row, Col } from 'antd'
const { Content } = Layout

class TeacherReports extends Component {
  state = {

  }

  componentWillReceiveProps(nextProps) {

  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Reports - dot it'
  }

  render() {
    return (
      <div>
        <Layout className="content layout-header-mt">
        <Layout>
            <Content className="layout-content">
              <div className="sub-menu-width m-lr-auto">
                {this.props.teacherBaseProps.teacher ?
                <div>
                  <div className="mb-3 pb-2 border-bottom ml-2 mr-2">
                  <div>
                <h1 className="flex flex-v-center mb-0 ">
                 <Icon type="solution" className="mr-2 font-30" />
                 <div>
                  <span>Know your students every step of the way.</span>
                 </div>
               </h1>
               </div>

               </div>
                  <Row>
                    <Col span={24}>
                    <Link 
                      to={"/teacher/reports/" + this.props.teacherBaseProps.teacher.id}
                      className={"h-170 m-2 up-hover text-left p-3" +
                       " shadow-hover ant-btn ant-btn-outlined font-black"}
                    >
                        <div className="font-24 w-100 font-bold mb-2">
                          <span>My Reports</span>
                        </div>
                        <div className="font-18 white-space-normal ">
                          View printable reports of student information.
                        </div>
                    </Link>
                    </Col>
                    {/*<Col span={12}>
                    <Link 
                      to={"/teacher/records/" + this.props.teacherBaseProps.teacher.id}
                      className={"h-170 m-2 up-hover text-left p-3" +
                      " shadow-hover ant-btn ant-btn-outlined font-black"}
                    >
                        <div className="font-24 w-100 font-bold mb-2">
                          <span>My Records</span>
                        </div>
                        <div className="font-18 white-space-normal ">
                          View student records that have been shared with you.
                        </div>
                    </Link>
                </Col>*/}
                  </Row>
                </div>
                     
                    
                : ''}
              </div>
            </Content>
            </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default TeacherReports