import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { flattenDoc, isReadOnlyTeacherId } from '../../Util'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../../Types'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import { Button, Modal, Select, Icon, message } from 'antd'
const Option = Select.Option

const loadingMessage = (description) => {
    message.destroy()
    message.loading(description, 0)
}

const errorMessage = (description) => {
    message.destroy()
    message.error(description)
}

const successMessage = (description) => {
    message.destroy()
    message.success(description)
}

class TransferGoals extends Component {
    state = {
        transferStudentModalVisible: false,
        transferStudentModalTimeout: null,
        transferDropdownVisible: {},
        transferStudentModalCloseTimeout: null,
        transferringStudents: {},
        transferSchool: null,
        transferTeacher: null,
        transferTeachersDict: {},
        transferTeachers: [],
        submitting: false,
    }

    componentDidMount() {

    }

    show = () => {
        this.setState({
            transferStudentModalVisible: true,
        })
    }

    transferStudentSchoolSelect = (schoolId) => {
        if (!(this.props.teacherBaseProps.schoolsDict &&
            this.props.teacherBaseProps.schoolsDict.hasOwnProperty(schoolId))) {
            return
        }

        var school = this.props.teacherBaseProps.schoolsDict[schoolId]

        this.setState({
            transferTeacher: null,
            transferSchool: school,
        })

        db.collection(ColType.teacher)
            .where('schoolId', '==', school.id)
            .where('districtId', '==', school.districtId)
            .get()
            .then((querySnapshot) => {
                var transferTeachers = []
                var transferTeachersDict = {}

                querySnapshot.forEach((doc) => {
                    var teacher = flattenDoc(doc)
                    transferTeachers.push(teacher)
                    transferTeachersDict[teacher.id] = teacher
                })

                this.setState({
                    transferTeachers: transferTeachers,
                    transferTeachersDict: transferTeachersDict,
                })
            })
    }

    transferStudentTeacherSelect = (teacherId) => {
        if (!(this.state.transferTeachersDict &&
            this.state.transferTeachersDict.hasOwnProperty(teacherId))) {
            return
        }

        var teacher = this.state.transferTeachersDict[teacherId]

        this.setState({
            transferTeacher: teacher,
        })
    }

    render() {
        return (
            <div onClick={(e) => {
                // must do this so that the modal does not trigger a link click
                // which will cause a redirect
                e.preventDefault()
                e.stopPropagation()
            }}>
                <Button
                    size="large"
                    className="ml-1 mr-1 ant-btn ant-btn-lg font-bold pl-2 pr-2 paper-btn font-cyan-hover"
                    disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher ?
                        this.props.teacherBaseProps.teacher.id :
                        '')}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.show()
                    }}>
                    <Icon type="file-sync" />
                    <span>Transfer student</span>
                </Button>
                <Modal
                    title={null}
                    visible={this.state.transferStudentModalVisible}
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            transferStudentModalVisible: false,
                            transferStudentModalCloseTimeout: setTimeout(() => {
                                this.setState({
                                    transferStudent: null,
                                    transferSchool: null,
                                    transferTeacher: null,
                                })
                            }, 100)
                        })
                    }}
                >
                    {this.props.hasOwnProperty('transferStudent') && this.props.transferStudent ?
                        <PersonAvatar
                            person={this.props.transferStudent}
                            size={'large'}
                            containerClassName="font-20 mt-4"
                            avatarClassName="font-20 font-bold"
                            personClassName="font-bold"
                        /> : ''}
                    <div className="font-24 font-bold mb-2 mt-2">Transfer Student</div>
                    <div className="mb-4 font-20">Transferring a student will send a copy of all of that student's data
                        to another teacher. You will still keep your version of this student after
                        the transfer.
                    </div>
                    <div className="font-20 mb-2 mt-2">Step 1: Select a school.</div>
                    <Select
                        className="w-100"
                        onChange={this.transferStudentSchoolSelect}
                        placeholder={"Select school..."}
                        size={'large'}
                        value={this.state.transferSchool ? this.state.transferSchool.id : []}
                    >
                        {this.props.teacherBaseProps.schools &&
                            this.props.teacherBaseProps.schools.map((school, idx) => {
                                return <Option key={school.id} value={school.id}>{school.schoolName}</Option>
                            })}
                    </Select>
                    <div className="font-20 mb-2 mt-2">Step 2: Select a teacher.</div>
                    <Select
                        className="w-100"
                        onChange={this.transferStudentTeacherSelect}
                        placeholder={"Select teacher..."}
                        disabled={!this.state.transferSchool}
                        size={'large'}
                        value={this.state.transferTeacher ? this.state.transferTeacher.id : []}
                    >
                        {this.state.transferTeachers &&
                            this.state.transferTeachers.map((teacher, idx) => {
                                return <Option key={teacher.id} value={teacher.id}>{teacher.firstName + " " + teacher.lastName}</Option>
                            })}
                    </Select>
                    <div className="flex flex-v-center mt-4">
                        <Button className="ant-btn lg-btn ml-auto mr-2"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                                this.setState({
                                    transferStudentModalVisible: false,
                                    transferStudentModalCloseTimeout: setTimeout(() => {
                                        this.setState({
                                            transferStudent: null,
                                            transferSchool: null,
                                            transferTeacher: null,
                                        })
                                    }, 100)
                                })
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="ant-btn ant-btn-primary lg-btn"
                            disabled={this.state.submitting || !this.state.transferSchool || !this.state.transferTeacher || !this.state.transferStudentModalVisible}
                            onClick={(e) => {
                                let IEPGoals = []

                                if (this.props.transferMode === 'student') {
                                    if (!(this.props.hasOwnProperty('transferStudent') && this.props.transferStudent)) {
                                        return
                                    }
                                    if (!this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.transferStudent.id)) {
                                        return
                                    }
                                    if (this.props.teacherBaseProps.studentIEPDict[this.props.transferStudent.id].length === 0) {
                                        return
                                    }

                                    IEPGoals = this.props.teacherBaseProps.studentIEPDict[this.props.transferStudent.id]
                                } else if (this.props.transferMode === 'single-goal') {
                                    IEPGoals = [this.props.transferGoal]
                                } else {
                                    console.log('Transfer goals unexpected transfer mode', this.props.transferMode)
                                    return
                                }

                                if (IEPGoals.length === 0) {
                                    console.log('Transfer goals, length is 0.')
                                    return
                                }

                                console.log({
                                    'IEPGoals': IEPGoals,
                                    'teacherId': this.props.teacherBaseProps.teacher.id,
                                    'transferToTeacherId': this.state.transferTeacher.id,
                                })

                                this.setState({
                                    submitting: true,
                                })

                                loadingMessage('Transferring student, please wait...')

                                /* forceRefresh of token by passing true to getIdToken */
                                firebase.auth().currentUser.getIdToken(true).then((idToken) => {
                                    let headers = new Headers()

                                    headers.append('Authorization', 'Bearer ' + idToken)
                                    headers.append('Origin', 'https://my.dotit.app')
                                    headers.append('Content-Type', 'application/json')
                                    headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

                                    fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/transfer-student", {
                                        method: "POST",
                                        body: JSON.stringify({
                                            'IEPGoals': IEPGoals,
                                            'teacherId': this.props.teacherBaseProps.teacher.id,
                                            'transferToTeacherId': this.state.transferTeacher.id,
                                        }),
                                        mode: 'cors',
                                        headers: headers,
                                    })
                                        .then(res => res.json())
                                        .then(data => {
                                            console.log(data)
                                            if (data.status === 'success') {
                                                successMessage('Student transferred successfully.')
                                                this.setState({
                                                    submitting: false,
                                                    transferStudentModalVisible: false,
                                                })
                                            } else {
                                                errorMessage('Could not transfer student, please try again or contact support by clicking the "Need help?" button.')
                                                this.setState({
                                                    submitting: false,
                                                })
                                            }
                                        })
                                        .catch(e => {
                                            console.log("request error", e)
                                            errorMessage('Could not transfer student, please try again or contact support by clicking the "Need help?" button.')
                                            this.setState({
                                                submitting: false,
                                            })
                                        })
                                }).catch((error) => {
                                    console.log("get token error", error)
                                    errorMessage('Could not transfer student, please try again or contact support by clicking the "Need help?" button.')
                                    this.setState({
                                        submitting: false,
                                    })
                                })
                            }}
                        >
                            {this.state.submitting ? " Transferring student..." : "Transfer student"}
                        </Button>
                    </div>
                </Modal>


            </div>
        )
    }
}

export default TransferGoals