import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import Empty from '../.././customcomponents/Empty'
import SubjectNote from './SubjectNote'
import { flattenDoc, isReadOnlyTeacherId } from '../.././Util'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Spin } from 'antd'
import AddSubjectNoteForm from './AddSubjectNoteForm'

class SubjectNoteDisplay extends Component {
    state = {
        notes: [],
        noteListener: null,
        iepId: '',
        studentId: '',
        loadingNewData: false,
        loadingTeachersDict: {},
        teacherDict: {},
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.student && nextProps.IEPId) {
            var currentIEPId = this.state.iepId
            var currentStudentId = this.state.studentId
            var teacherId = nextProps.teacherId
            var studentId = nextProps.studentId
            var iepId = nextProps.IEPId

            this.setState({
                teacherId: teacherId,
                studentId: studentId,
                iepId: iepId,
            }, () => {
                if (currentStudentId !== studentId || iepId !== currentIEPId) {
                    this.subscribeNoteListener()
                }
            })
        }
    }

    componentDidMount() {
        if (this.props.student && this.props.IEPId) {
            var teacherId = this.props.teacherId
            var studentId = this.props.studentId
            var iepId = this.props.IEPId

            this.setState({
                teacherId: teacherId,
                studentId: studentId,
                iepId: iepId,
            }, () => {
                this.subscribeNoteListener()
            })
        }
    }

    componentWillUnmount() {
        if (this.state.noteListener) {
            this.state.noteListener()
        }
    }

    getTeacherData = (teacherId) => {
        if (!teacherId) {
            return
        }
        if (this.state.loadingTeachersDict.hasOwnProperty(teacherId) &&
            this.state.loadingTeachersDict[teacherId]) {
            return
        }
        if (this.state.teacherDict.hasOwnProperty(teacherId)) {
            return
        }

        let loadingTeachersDict = this.state.loadingTeachersDict
        loadingTeachersDict[teacherId] = true
        this.setState({
            loadingTeachersDict: loadingTeachersDict,
        }, () => {
            db.collection(ColType.teacher)
                .doc(teacherId)
                .get()
                .then((doc) => {
                    var teacher = flattenDoc(doc)
                    var teacherDict = this.state.teacherDict
                    teacherDict[teacherId] = teacher
                    this.setState({
                        teacherDict: teacherDict,
                    })
                })
        })
    }

    subscribeNoteListener() {
        this.setState({
            loadingNewData: true,
        })
        // unsubscribe noteListener if its not null
        if (this.state.noteListener) {
            this.state.noteListener()
        }

        if (isReadOnlyTeacherId(this.state.teacherId)) {
            var notes = []
            if (this.props.teacherBaseProps.readOnlyIEPNoteDict &&
                this.props.teacherBaseProps.readOnlyIEPNoteDict.hasOwnProperty(this.state.iepId)) {
                notes = this.props.teacherBaseProps.readOnlyIEPNoteDict[this.state.iepId]
            }
            this.setState({
                notes: [],
            }, () => {
                this.setState({
                    notes: notes,
                    loadingNewData: false,
                })
            })
        } else {
            let collectionName = ColType.elaNotes
            if (this.props.subject === 'math') {
                collectionName = ColType.mathNotes
            }
            if (this.props.hasOwnProperty('isAdmin') &&
                this.props.isAdmin) {
                var noteListener = db.collection(collectionName)
                    //.where('teacherId', '==', this.state.teacherId)
                    .where('studentId', '==', this.state.studentId)
                    //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                    .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                    .where('iepId', '==', this.state.iepId)
                    .orderBy('timeStamp', 'desc')
                    .limit(25)
                    .onSnapshot((querySnapshot) => {
                        var notes = []
                        querySnapshot.forEach((doc) => {
                            const note = flattenDoc(doc)
                            this.getTeacherData(note.teacherId)
                            notes.push(note)
                        })

                        // add to read only data so that the read only display can use the data
                        /*if (this.props.teacherBaseProps.addToReadOnlyIEPNoteDictFunc) {
                            this.props.teacherBaseProps.addToReadOnlyIEPNoteDictFunc(this.state.iepId, notes)
                        }*/

                        this.setState({
                            notes: notes,
                            loadingNewData: false,
                        })
                    })

                this.setState({
                    noteListener: noteListener,
                })
            } else {
                var noteListener = db.collection(collectionName)
                    .where('teacherId', '==', this.state.teacherId)
                    .where('studentId', '==', this.state.studentId)
                    //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                    .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                    .where('iepId', '==', this.state.iepId)
                    .orderBy('timeStamp', 'desc')
                    .limit(25)
                    .onSnapshot((querySnapshot) => {
                        var notes = []
                        querySnapshot.forEach((doc) => {
                            notes.push(flattenDoc(doc))
                        })

                        // add to read only data so that the read only display can use the data
                        /*if (this.props.teacherBaseProps.addToReadOnlyIEPNoteDictFunc) {
                            this.props.teacherBaseProps.addToReadOnlyIEPNoteDictFunc(this.state.iepId, notes)
                        }*/

                        this.setState({
                            notes: notes,
                            loadingNewData: false,
                        })
                    })

                this.setState({
                    noteListener: noteListener,
                })
            }
        }
    }

    render() {
        return (
            <div className="mt-3 relative">
                <div className="background-fff br-4">
                    <div>
                        {this.props.teacherBaseProps.teacher &&
                            this.props.student &&
                            this.props.IEPId ?
                            <div>
                                <div>
                                    {!this.props.hasOwnProperty('isAdmin') ||
                                        (this.props.hasOwnProperty('isAdmin') && !this.props.isAdmin) ?
                                        <AddSubjectNoteForm
                                            key={this.props.teacherBaseProps.teacher.id}
                                            teacher={this.props.teacherBaseProps.teacher}
                                            student={this.props.student}
                                            IEPId={this.props.IEPId}
                                            subject={this.props.subject}
                                        />
                                        :
                                        <div className='mr-2'>
                                            <h2 className="font-bold mb-0 font-24">
                                                {this.props.subject &&
                                                    this.props.subject === 'ela' ?
                                                    'ELA Notes - Description of Instruction' :
                                                    'Math Notes - Description of Instruction'}
                                            </h2>
                                            <h3 className="font-20 mb-2">
                                                Document lesson progression, student strengths, and instructional decisions.
                                            </h3>
                                        </div>}
                                </div>
                                {this.state.loadingNewData ?
                                    <div className={"flex flex-h-center" +
                                        " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                        <div>
                                            <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                        </div>
                                    </div> :
                                    <div>
                                        {this.state.notes.length === 0 ?
                                            <div className="mt-2 font-16">
                                                <Empty
                                                    containerClassName="mt-4 pt-2 text-center"
                                                    width={55}
                                                    height={55}
                                                    image={'/empty/notes.png'}
                                                    description={(this.props.hasOwnProperty('isAdmin') && this.props.isAdmin) ?
                                                        'There are currently no notes to display.' :
                                                        this.props.subject &&
                                                            this.props.subject === 'ela' ?
                                                            'No ELA notes to display. Add a note by clicking "Add note" button above.' :
                                                            'No math notes to display. Add a note by clicking "Add note" button above.'}
                                                />
                                            </div>
                                            : ''}
                                        {this.state.notes.map((note, index) => {
                                            let teacher = this.props.teacherBaseProps.teacher
                                            if (this.props.hasOwnProperty('isAdmin') &&
                                                this.props.isAdmin) {
                                                if (this.state.teacherDict.hasOwnProperty(note.teacherId) &&
                                                    this.state.teacherDict[note.teacherId]) {
                                                    teacher = this.state.teacherDict[note.teacherId]
                                                } else {
                                                    teacher = null
                                                }
                                            }
                                            return <div key={note.id}>
                                                <SubjectNote
                                                    teacher={teacher}
                                                    note={note}
                                                    allowDelete={this.props.hasOwnProperty('isAdmin') && !this.props.isAdmin}
                                                    subject={this.props.subject}
                                                />
                                            </div>
                                        })
                                        }
                                    </div>}
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
        )
    }
}

export default SubjectNoteDisplay
