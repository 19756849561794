import React from 'react'

function RightTriangleAssessment({ A, B, C, a, b, c, alpha, beta, pre_text, post_text, answers }) {
    const trianglePoints = `${a},0 ${a},${b} 0,0`
    const answersList = answers.map((answer, index) => <li key={index}>{answer}</li>)

    return (
        <div style={{ border: '1px solid black', margin: '10px', padding: '20px' }}>
            <p>{pre_text}</p>
            <svg height="210" width="500">
                <polygon points={trianglePoints} style={{ fill: 'transparent', stroke: 'black', strokeWidth: 1 }} />
                <text x="0" y="15" fill="black">{C}</text>
                <text x={a} y="15" fill="black">{B}</text>
                <text x="15" y={b} fill="black">{A}</text>
                <text x={a / 2} y="15" fill="black">{a}</text>
                <text x={a} y={b / 2} fill="black">{c}</text>
                <text x="15" y={b / 2} fill="black">{b}</text>
            </svg>
            <p>{post_text}</p>
            <ul>{answersList}</ul>
        </div>
    )
}

export default RightTriangleAssessment
