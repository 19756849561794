import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { firebase } from '../firebase/Firebase'
import { message } from 'antd'

const errorMessage = (description) => {
    message.destroy()
    message.error(description)
}

// only used if someone gets stuck in a strange login state
class SignOut extends Component {
    state = {
    }

    componentDidMount() {
        firebase.auth().signOut().then(function () {
            console.log('Signed Out')
            //sending users to the sign in page after signing out for now.
            window.location.href = "/sign-in"
        }, function (error) {
            console.error('Sign Out Error', error)
            errorMessage("There was an error signing you out. Please try again.")
        })
    }

    render() {
        return (
            <div className="font-18 font-bold p-4">
                Signing out...
            </div>
        )
    }
}

export default SignOut
