import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../admin/SubjectHistogram'
//import SupportPieChartTiers from '../admin/SupportPieChartTiers'
//import VideoContainer from '../video/VideoContainer'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import Empty from '../../customcomponents/Empty'
import { getIDFromURL } from '../.././Util'
import { Layout, Row, Col } from 'antd'
//import TransferGoals from './student/TransferGoals'
const { Content } = Layout

class TierOneHome extends Component {
    state = {
        teacherId: '',
    }

    componentWillReceiveProps(nextProps) {
        document.title = 'Plans - Dot It'

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Plans - Dot It'

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <div>

                        <div className="sub-menu-width m-lr-auto mt-4">
                            <div className='br-20 overflow-hidden'>
                                <div className='sub-menu-width m-lr-auto br-4 relative pl-3 pr-3 pt-3 relative'
                                    style={{ height: '300px', }}>

                                    <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#08979c' }}>
                                    </div>
                                    <div className="absolute-tl w-100 h-100 image-box-mask">
                                    </div>
                                    <div className='absolute-tl w-100 h-100'>
                                        <div className='sub-menu-width m-lr-auto h-100'>
                                            <div className='flex flex-v-center font-40 font-fff h-100 p-4'>
                                                <div>
                                                    <div className=''>
                                                        Conference notes
                                                    </div>
                                                    <div className='font-20'>
                                                        Enter at least 1 conference note per student per quarter
                                                    </div>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img src='/draft-iep-1.png' alt='tier-one-icon' width={270} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Layout className="mt-3">
                        <Content className="mt-0">

                            <div className="sub-menu-width m-lr-auto">
                                {this.state.teacherId ?
                                    <div>


                                        {this.props.teacherBaseProps &&
                                            this.props.teacherBaseProps.students &&
                                            this.props.teacherBaseProps.students.length === 0 ?
                                            <Empty
                                                containerClassName="mt-4 pt-2 text-center font-16"
                                                width={55}
                                                height={55}
                                                description={'You currently have no students.'}
                                                image={'/empty/students.png'}
                                            /> : ''}



                                        <Row gutter={48} className="ml-0" type="flex">
                                            {this.props.teacherBaseProps &&
                                                this.props.teacherBaseProps.students &&
                                                this.props.teacherBaseProps.students.map((student, idx) => {
                                                    return <Col span={24} className="pl-0" key={student.id}>
                                                        <Link to={"/teacher/tier-one-plan/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}>
                                                            <div className={"p-3 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center"}>
                                                                <PersonAvatar
                                                                    person={student}
                                                                    size={'large'}
                                                                    containerClassName="font-20"
                                                                    avatarClassName="font-20 font-bold mr-2"
                                                                    personClassName="font-black font-24 ellipsis"
                                                                    personStyle={{ maxWidth: '400px' }}
                                                                />
                                                                {/*
                                                                <div className="ml-auto font-24">
                                                                    <div className="font-tier-1">Tier 1</div>
                                                                </div>
                                                                */}
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                })}
                                        </Row>

                                    </div>
                                    : ''}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter teacherId={this.state.teacherId} />
            </div>
        )
    }
}

export default TierOneHome
