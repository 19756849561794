import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import { flattenDoc, FOLDER_CURRENT, getQueryStringParam } from '../Util'
import { Layout, Row, Col, Select, Icon } from 'antd'
import PersonAvatar from '../customcomponents/PersonAvatar'
import Scheduler from '../teacher/scheduler/Scheduler'
import StudentSummary from '../admin/StudentSummary'
const { Content } = Layout
const moment = require('moment')

class SchoolAdminTeacherView extends Component {
    state = {
        schoolId: '',
        teacherId: '',
        teacher: '',
        students: null,
        studentDict: null,
        IEPGoals: null,
        IEPDict: null,
        calendarEvents: null,
        calendarDict: null,
        events: null,
        loadedData: false,
        readOnly: true,
        currentTeacherTab: 'teachers',
    }

    // Do fetch here
    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(props, newProps) {
        this.getData()
    }

    getData() {
        if (this.state.loadedData) {
            return
        }

        if (!this.props.schoolAdminBaseProps.schoolAdmin) {
            return
        }

        var teacherId = getQueryStringParam('t')

        this.setState({
            schoolId: this.props.schoolAdminBaseProps.schoolAdmin.schoolId,
            teacherId: teacherId,
        })

        if (teacherId && this.props.schoolAdminBaseProps.schoolAdmin) {
            this.setState({
                loadedData: true,
            })

            db.collection(ColType.teacher)
                .doc(teacherId)
                .get()
                .then((doc) => {
                    var teacher = flattenDoc(doc)

                    this.setState({
                        teacher: teacher,
                    })

                    db.collection(ColType.iep)
                        .where('teacherId', '==', teacherId)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        .orderBy('timeStamp', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            var IEPGoals = []
                            var IEPDict = {}

                            querySnapshot.forEach((doc) => {
                                var iep = flattenDoc(doc)
                                IEPGoals.push(iep)
                                IEPDict[iep.id] = iep
                            })

                            this.setState({
                                IEPGoals: IEPGoals,
                                IEPDict: IEPDict,
                            })
                        })

                    db.collection(ColType.calendarEvents)
                        .where('teacherId', '==', teacherId)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        .orderBy('startTime')
                        .orderBy('index')
                        .orderBy('duration', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            var calendarEvents = []
                            var calendarDict = {}
                            var events = []
                            var idx = 0

                            querySnapshot.forEach((doc) => {
                                if (!doc.exists) return
                                var event = flattenDoc(doc)
                                // store the doc for deletes
                                event.doc = doc
                                calendarEvents.push(event)
                                var start = moment.utc(event.startTime.seconds * 1000)
                                var end = new Date(event.endTime)
                                //start = new Date(2015, 5, parseInt(event.day, 10),
                                //  start.getHours(), start.getMinutes(), 0)
                                end = new Date(2015, 5, parseInt(event.day, 10),
                                    end.getHours(), end.getMinutes(), 0)
                                event.start = start
                                event.end = end

                                events.push(event)
                                calendarDict[idx.toString()] = {
                                    'flattenedDoc': event,
                                    'unFlattenedDoc': doc.data()
                                }
                            })

                            this.setState({
                                calendarEvents: calendarEvents,
                                calendarDict: calendarDict,
                                events: events,
                            })
                        })
                })

            db.collection(ColType.student)
                .where('districtId', '==', this.props.schoolAdminBaseProps.schoolAdmin.districtId)
                .where('schoolId', '==', this.props.schoolAdminBaseProps.schoolAdmin.schoolId)
                .where('teacherId', '==', teacherId)
                .where('folder', '==', FOLDER_CURRENT)
                .get()
                .then((querySnapshot) => {
                    var students = []
                    var studentDict = {}

                    querySnapshot.forEach((doc) => {
                        var student = flattenDoc(doc)
                        students.push(student)
                        studentDict[student.id] = student
                    })

                    students.sort((a, b) => {
                        var levelDifference = 0
                        if (a.grade === 'K' && b.grade === 'K') {
                            if (levelDifference !== 0) return levelDifference
                            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                return 1
                            } else {
                                return -1
                            }
                        }
                        if (a.grade === 'K' && b.grade !== 'K') return -1
                        if (a.grade !== 'K' && b.grade === 'K') return 1
                        if (a.grade === b.grade) {
                            if (levelDifference !== 0) return levelDifference
                            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                return 1
                            } else {
                                return -1
                            }
                        }
                        var aGrade = parseInt(a.grade, 10)
                        var bGrade = parseInt(b.grade, 10)
                        if (aGrade > bGrade) return 1
                        if (bGrade > aGrade) return -1
                        return 0
                    })

                    this.setState({
                        students: students,
                        studentDict: studentDict,
                    })
                })
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Layout className="content relative layout-header-mt">
                        <Layout>
                            <Content>
                                <div>
                                    {this.state.schoolId &&
                                        this.state.teacher &&
                                        this.props.schoolAdminBaseProps.schoolAdmin &&
                                        this.props.schoolAdminBaseProps.district
                                        ?
                                        <div>
                                            <div className="sub-menu-width-vlg m-lr-auto mt-3">
                                                <div className='flex'>
                                                    <div style={{ width: '1024px', maxWidth: '1024px' }} className='mr-2 relative'>
                                                        <div>
                                                            <div className="w-100 flex flex-v-center mb-2 font-16 font-bold">
                                                                <Link to={'/school-admin/school-admin-home/' + this.props.schoolAdminBaseProps.schoolAdmin.id}>
                                                                    {this.props.schoolAdminBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId) ?
                                                                        this.props.schoolAdminBaseProps.schoolsDict[this.state.schoolId].schoolName : ''}
                                                                </Link>
                                                                <Icon type="right" className="ml-1 mr-1 font-14" />
                                                                <span>
                                                                    {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='relative'>
                                                            <img src="/teacher-background.png" alt="background-img" className="w-100 object-fit-cover absolute-tl br-20 w-100" />
                                                        </div>
                                                        <Row>
                                                            <Col span={24}>
                                                                <div className="font-bold mb-50 pb-2 font-40 flex font-fff ml-4"
                                                                    style={{ marginTop: '150px' }}>
                                                                    {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div>
                                                            <Scheduler
                                                                teacherBaseProps={this.state}
                                                                hideSideNavbar={true}
                                                                admin={this.props.schoolAdminBaseProps.schoolAdmin}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='ml-2 w-100'>
                                                        <h1 className='font-24 font-bold mb-1'>Students</h1>
                                                        {this.state.students ?
                                                            <Select
                                                                showSearch
                                                                placeholder="Search for a student"
                                                                className='w-100 mb-2'
                                                                optionFilterProp="children"
                                                                onSelect={(studentId) => {
                                                                    if (this.props.schoolAdminBaseProps.schoolAdmin && this.state.schoolId && this.state.teacherId && studentId) {
                                                                        this.props.history.push({
                                                                            pathname: "/school-admin/school-admin-student/" + this.props.schoolAdminBaseProps.schoolAdmin.id + '?t=' + this.state.teacherId + '&st=' + studentId
                                                                        })
                                                                    }
                                                                }}
                                                                size="large"
                                                                filterOption={(input, option) => {
                                                                    let person = option.props.children.props.person
                                                                    let fullName = person.firstName + ' ' + person.lastName
                                                                    return fullName.toLowerCase().includes(input.toLowerCase())
                                                                }}
                                                            >
                                                                {this.state.students &&
                                                                    this.state.students.map((student, idx) => {
                                                                        return <Select.Option value={student.id} key={'search-' + student.id}>
                                                                            <PersonAvatar
                                                                                person={student}
                                                                                containerClassName="font-16"
                                                                                avatarClassName="font-16 font-bold mr-2"
                                                                                personClassName="font-black font-16"
                                                                            />
                                                                        </Select.Option>
                                                                    })}
                                                            </Select>
                                                            : ''}

                                                        {this.state.students && this.state.students.length === 0 ?
                                                            <div className='font-16 font-bold mb-1'>No students</div>
                                                            : ''}
                                                        {this.state.students &&
                                                            this.state.students.map((student, idx) => {
                                                                return <Link to={"/school-admin/school-admin-student/" + this.props.schoolAdminBaseProps.schoolAdmin.id + '?t=' + this.state.teacherId + '&st=' + student.id}
                                                                    id={student.id} key={student.id}>
                                                                    <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative w-100 font-16"}>
                                                                        <PersonAvatar
                                                                            person={student}
                                                                            size={'large'}
                                                                            containerClassName="font-20"
                                                                            avatarClassName="font-20 font-bold mr-2"
                                                                            personClassName="font-black font-20"
                                                                        />
                                                                        <StudentSummary student={student} />
                                                                    </div>
                                                                </Link>
                                                            })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        : ''}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <CustomFooter />
            </div>
        )
    }
}

export default SchoolAdminTeacherView