import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import IEPDrafts from './IEPDrafts'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import {
  flattenDoc, getIDFromURL,
  getQueryStringParam, capitalizeFirstChar, getSpecialEducationRemovalStatement,
  getMathSpecialEducationRemovalStatement
} from '../.././Util'
import { Layout, Button, Icon, Select, Form, Input } from 'antd'
import DraftStepContainer from '../addiep/DraftStepContainer'
import StandardNumber from '../../customcomponents/StandardNumber'
const { Content } = Layout
const Option = Select.Option
const FormItem = Form.Item
const TextArea = Input.TextArea

class TeacherAddIEPGoalService extends Component {
  state = {
    teacher: null,
    student: null,
    iepDraft: null,
    students: [],
    serviceLocation: '',
    tabKey: '1',
    iepDraftMounted: false,
    stepNum: 4,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var draftId = getQueryStringParam('draft')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.pepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          var draft = flattenDoc(doc)
          this.setState({
            iepDraft: draft,
            iepSelections: draft.iepSelectionsNoCount,
            ieps: draft.ieps,
            iepDraftMounted: true,
          }, () => {
            var iepDraft = this.state.iepDraft
            var hasServiceProperty = false
            var serviceObj = {}
            iepDraft.ieps.map((iep, index) => {
              if (iep.hasOwnProperty('service')) {
                hasServiceProperty = true
                serviceObj[iep.id + '-servicedIn'] = iep.service.servicedIn
                serviceObj[iep.id + '-serviceDuration'] = iep.service.serviceDuration
                serviceObj[iep.id + '-days'] = iep.service.days
                serviceObj[iep.id + '-provider'] = iep.service.provider
                serviceObj[iep.id + '-curriculum'] = iep.service.curriculum
              }
              return false
            })

            if (hasServiceProperty) {
              if (this.state.iepDraft.step.stepNum > this.state.stepNum)
                this.props.form.setFieldsValue(serviceObj)
            }
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)

        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .doc(studentId)
      .get()
      .then((doc) => {
        var student = flattenDoc(doc)

        this.setState({
          student: student,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  // add a new event to the teacher's events
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // convert date to string to pass along, if the user 
        // navigates back here it will be converted to a moment
        //values.startTime = moment.utc(values.startTime._d).format("")
        //values.endTime = moment.utc(values.endTime._d).format("")
        var newState = this.state.iepDraft
        this.state.ieps.map((iep, index) => {
          var service = {}
          service.serviceDuration = values[iep.id + '-serviceDuration']
          service.days = values[iep.id + '-days']
          service.provider = values[iep.id + '-provider']
          service.curriculum = values[iep.id + '-curriculum']
          iep.service = service

          // its always the same regardless of iep
          if (values[iep.id + '-servicedIn'] === 'Special Education') {
            newState.removalStatement =
              getSpecialEducationRemovalStatement(this.state.student,
                newState.category, newState.level)
            if (iep.mainSubject === "Math") {
              newState.removalStatement = getMathSpecialEducationRemovalStatement(this.state.student,
                newState.category, newState.level)
            }
          }

          return false
        })

        newState.ieps = this.state.ieps
        newState.step = { stepNum: this.state.stepNum + 1, path: 'accommodations' }

        db.collection(ColType.pepDrafts)
          .doc(this.state.draftId)
          .set(newState, { merge: true })
          .then(() => {
            this.props.history.push(
              {
                pathname: '/teacher/mtss-add-goal-accommodations/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId +
                  '&draft=' + this.state.draftId,
                state: newState, // pass state to confirm page
              }
            )
          })
      }
    })
  }

  serviceLocationChange = (value, options) => {
    this.setState({
      serviceLocation: value,
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                {/* 
      <div className="text-center">
        <img src="/draft-plan/3.png" alt="steps" />
      </div>
      */}
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={this.state.iepDraftMounted && this.state.draftId}
                  isMTSS={true}
                >
                  <div>
                    {this.state.draftId && this.state.iepDraftMounted ?
                      <div>

                        <div className="w-800 m-lr-auto background-fff br-15">
                          <div className='p-4 bkg-purple br-15'>
                            <div className='flex flex-v-center w-100'>
                              <div>
                                {this.props.teacherBaseProps &&
                                  this.props.teacherBaseProps.studentDict &&
                                  this.props.teacherBaseProps.studentDict
                                    .hasOwnProperty(this.state.studentId)
                                  ?
                                  <div className="flex flex-v-center mb-0 font-24">
                                    <div>
                                      <div className='font-20 font-fff'>
                                        Tier Plan Draft
                                      </div>
                                      <h1 className="font-40 mb-0 font-fff font-bold">
                                        Resources
                                      </h1>
                                    </div>
                                  </div>
                                  : ''}
                              </div>
                              <img src="/draft-icons/6.png" alt='draft-iep' width={250} className='ml-auto' />
                            </div>
                          </div>
                          <div className='p-4'>
                            <div>
                              <Form onSubmit={this.handleSubmit}>
                                {this.state.iepDraft.subject.map((subject, subjectIndex) => {
                                  var ieps = this.state.ieps.filter(iep => iep.mainSubject === subject)
                                  return <div className="mb-3" key={subject + '-' + subjectIndex}>
                                    <h2 className="mb-3 pb-1 border-bottom font-30">
                                      {subject}</h2>
                                    {ieps.map((iep, index) => {
                                      return <div className="mb-2 font-16"
                                        key={'iep-row-' + subjectIndex + index}
                                      >
                                        <h3 className="font-bold font-20 mb-2">
                                          <StandardNumber
                                            district={this.props.teacherBaseProps.district}
                                            iep={iep}
                                            className='mr-0'
                                            containerClassName={'pl-2 pr-2 pt-1 pb-1 mr-1 br-100 font-20'}
                                            containerStyle={{ backgroundColor: '#e8e8e8' }}
                                          />
                                          <span>{capitalizeFirstChar(iep.standardDescription)}</span></h3>
                                        <div className="mb-3 font-20 font-black">
                                          {iep.iepParagraph}
                                        </div>
                                        <FormItem {...formItemLayout}
                                          label={'Intervention time'} className="block-label">
                                          {getFieldDecorator(iep.id + '-serviceDuration', {
                                            rules: [{
                                              required: true,
                                              message: 'Select intervention time in minutes.'
                                            }],
                                          })(
                                            <Select
                                              size={'large'}
                                              style={{ width: '100%' }}
                                              placeholder="Select minutes"
                                            >
                                              <Option value={15} key="15 Minutes">15 Minutes</Option>
                                              <Option value={30} key="30 Minutes">30 Minutes</Option>
                                              <Option value={45} key="45 Minutes">45 Minutes</Option>
                                              <Option value={60} key="60 Minutes">60 Minutes</Option>
                                              <Option value={90} key="90 Minutes">90 Minutes</Option>
                                            </Select>
                                          )}
                                        </FormItem>
                                        {this.state.iepDraft.tier === 'Tier 2' ?
                                          <FormItem {...formItemLayout}
                                            label={'Intervention days per week'} className="block-label">
                                            {getFieldDecorator(iep.id + '-days', {
                                              rules: [{
                                                required: true,
                                                message: 'Select intervention days per week'
                                              }],
                                            })(
                                              <Select
                                                size={'large'}
                                                style={{ width: '100%' }}
                                                placeholder="Select days"
                                              >
                                                <Option value={1} key="1 day">1 day</Option>
                                                <Option value={2} key="2 days">2 days</Option>
                                                <Option value={3} key="3 days">3 days</Option>
                                              </Select>

                                            )}
                                          </FormItem>
                                          : ''}
                                        {this.state.iepDraft.tier === 'Tier 3' ?
                                          <FormItem {...formItemLayout}
                                            label={'Intervention days per week'} className="block-label">
                                            {getFieldDecorator(iep.id + '-days', {
                                              rules: [{
                                                required: true,
                                                message: 'Select intervention days per week'
                                              }],
                                            })(
                                              <Select
                                                size={'large'}
                                                style={{ width: '100%' }}
                                                placeholder="Select days"
                                              >
                                                <Option value={3} key="3 days">3 days</Option>
                                                <Option value={4} key="4 days">4 days</Option>
                                                <Option value={5} key="5 days">5 days</Option>
                                              </Select>
                                            )}
                                          </FormItem>
                                          : ''}
                                        <FormItem {...formItemLayout}
                                          label={'Provider'} className="block-label">
                                          {getFieldDecorator(iep.id + '-provider', {
                                            rules: [{
                                              required: true,
                                              message: 'Select provider'
                                            }],
                                          })(
                                            <Select
                                              size={'large'}
                                              style={{ width: '100%' }}
                                              placeholder="Select provider"
                                            >
                                              <Option value={'Teacher'} key="Teacher">Teacher</Option>
                                              <Option value={"Interventionist"} key="Interventionist">
                                                Interventionist
                                              </Option>
                                              <Option value={"School Psychologist"}
                                                key="School Psychologist">
                                                School Psychologist
                                              </Option>
                                              <Option value={"Other"} key="Other">Other</Option>
                                            </Select>
                                          )}
                                        </FormItem>
                                        <FormItem {...formItemLayout}
                                          label={<div>
                                            <div className='font-20 font-bold'>
                                              Description of instruction
                                            </div>
                                            <div className='font-16 font-normal'>
                                              {"Write a brief description of what you would use to " +
                                                "teach this goal (e.g., instructional materials, curriculum " +
                                                "program)"}
                                            </div>
                                          </div>}
                                          className="block-label">
                                          {getFieldDecorator(iep.id + '-curriculum', {
                                            rules: [{
                                              required: true,
                                              message: 'Add instructional resources'
                                            }],
                                          })(
                                            <TextArea autosize={{ minRows: 2, maxRows: 6 }}
                                              placeholder="Instructional resources..." />
                                          )}
                                        </FormItem>

                                      </div>
                                    })}
                                  </div>
                                })
                                }
                                {this.state.serviceLocation === 'Special Education' ?
                                  ''
                                  :
                                  ''
                                }

                                <div className="mb-0 flex flex-v-center">
                                  {this.state.teacherId && this.state.studentId && this.state.draftId ?
                                    <Link
                                      to={'/teacher/mtss-add-goal-progress-monitoring/' +
                                        this.state.teacherId + '?student=' +
                                        this.state.studentId +
                                        '&draft=' + this.state.draftId}
                                      className="font-18"
                                    >
                                      <span className="left-hover-parent text-cyan">
                                        <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                        <span>Previous Step</span>
                                      </span>
                                    </Link>
                                    : ''}
                                  <Button
                                    type="primary"
                                    size={'large'}
                                    htmlType="submit"
                                    className="lg-btn ml-auto"
                                  >
                                    Continue
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>

                      </div>
                      : ''}

                  </div>
                </DraftStepContainer>
              </div>

            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalService)
