import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import ColType from '.././Types'
import CustomFooter from '../login/CustomFooter'
import HomeHeader from '../login/HomeHeader'
import { Layout } from 'antd'
const Content = Layout.Content

export default class Home extends Component {
  state = {
    user: null,
    person: null,
    accessLevel: null,
    loginMounted: false,
  }

  componentDidMount() {
    document.title = "Unsubscribe from emails"

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        db.collection(ColType.users)
        .doc(user.uid)
        .get()
        .then((doc) => { 
          var userObj = doc.data()
          userObj.id = doc.id
          userObj.uid = user.uid
          userObj.signedIn = true
          console.log(userObj.accessLevel)
          db.collection(userObj.accessLevel)
            .doc(userObj.refId)
            .get()
            .then((doc) => {
              var person = flattenDoc(doc)
              this.setState({
                person: person,
                accessLevel: userObj.accessLevel,
                loginMounted: true,
              })
            })
          })
      } 
      else {
        // No user is signed in.
        this.setState({
          loginMounted: true,
        })
      }
    })
  }

  render() {
    return (
      <div className="home-page">
      <Layout>
        <HomeHeader 
          person={this.state.person} 
          accessLevel={this.state.accessLevel}
          loginMounted={this.state.loginMounted} 
        />
      </Layout>
      <div className="overflow-hidden">
        <Layout className="content layout-header-mt pt-2">
            <Layout>
              <Content className="layout-content">
            {/** Main block */}
            <div className={"home-main-block w-100 max-w-1200 m-lr-auto " +
            "pl-3 pr-3 mt-100 pt-2 text-center relative overflow-hidden"}>
              <h1 className="font-30">You have unsubscribed from emails.</h1>
            </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div>
      </div>
    )
  }
}