import React, { Component } from 'react'
import { Route } from 'react-router-dom'
//import StudentReport from './reports/StudentReport'
import '../styles/GlobalStyle.css'
import StudentAssessments from './StudentAssessments'
import StudentCompletedAssessments from './StudentCompletedAssessments'
import StudentAssessment from './StudentAssessment'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, getCurrentTimestamp, getQueryStringParam, getYearStart, isTestingTimeRangeExpired } from '../Util'
import ColType from '../Types'
import { Layout } from 'antd'
import StudentHeader from '../login/StudentHeader'
const moment = require('moment')

const StudentAssessmentsComponent = (props, state) => {
    return <StudentAssessments studentBaseProps={state} {...props} />
}

const StudentCompletedAssessmentsComponent = (props, state) => {
    return <StudentCompletedAssessments studentBaseProps={state} {...props} />
}

const StudentAssessmentComponent = (props, state) => {
    return <StudentAssessment studentBaseProps={state} {...props} />
}

class StudentBase extends Component {
    state = {
        assessments: null,
        assessmentsDict: null,
        retrieved: false,
        listeners: [],
        user: null,
        authListener: null,
        serverMoment: null,
        inTestingTimeRange: true,
        nextCheckTimeMilliseconds: 1,
        serverMomentTimeout: null,
        numAssessments: -1,
        district: null,
        demoToken: null,
    }

    componentDidMount() {
        let demoToken = getQueryStringParam('dt')
        let authListener = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                //console.log('user', user)
                this.setState({
                    user: user
                }, () => {
                    this.getData(user)
                })
            } else {
                if (this.state.demoToken) {
                    this.props.history.push(
                        {
                            pathname: '/demo',
                            search: '?dt=' + this.state.demoToken
                        }
                    )
                } else if (this.state.district &&
                    this.state.district.hasOwnProperty('id') &&
                    (this.state.district.id === '64bec570267c8bfeea81f9ea' ||
                        this.state.district.id === '64e3cb7826043cd12a0f829e')) {
                    this.props.history.push(
                        {
                            pathname: '/demo-sign-in'
                        }
                    )
                } else {
                    // not sure where to go here yet
                    window.location.href = '/sign-in'
                }
            }
        })

        this.setState({
            demoToken: demoToken,
            authListener: authListener
        })
    }

    getData = (user) => {
        // user must be defined before calling this function
        if (!user) {
            return
        }
        if (this.state.retrieved) {
            return
        }

        this.setState({ retrieved: true })

        db.collection(ColType.users).doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                let userValue = flattenDoc(doc)
                userValue.uid = user.uid
                //console.log('here', userValue)
                this.setState({
                    user: userValue
                })

                db.collection(ColType.district)
                    .doc(userValue.districtId)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            let district = flattenDoc(doc)
                            //console.log('district', district)
                            this.setState({
                                district: district
                            }, () => {
                                this.getServerTime()
                            })
                        } else {
                            console.log('District not found')
                        }
                    })
            } else {
                console.log('user not found')
            }
        }).catch((error) => {
            console.log('Error getting document:', error)
        })

        // console.log(user.uid, ColType.cleverStudentAssessments)

        const yearStart = getYearStart()
        let assessmentListener = db.collection(ColType.cleverStudentAssessments)
            .where('studentId', '==', user.uid)
            .where('finalGrade.graded', '==', false)
            .where('status', '==', 'current')
            .where('timeStamp', '>=', yearStart)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let assessments = []
                let assessmentsDict = {}

                querySnapshot.forEach((doc) => {
                    let assessment = flattenDoc(doc)
                    assessment.quiz.docs = JSON.parse(assessment.quiz.docs)
                    assessments.push(assessment)
                    assessmentsDict[doc.id] = assessment
                })

                assessments.sort((a, b) => b.quiz.questionIdx - a.quiz.questionIdx)
                // sort by assessmentCategory, benchmark > common_assessment > quiz
                assessments.sort((a, b) => {
                    if (a.quiz.assessmentCategory === 'benchmark' && b.quiz.assessmentCategory !== 'benchmark') {
                        return -1
                    } else if (a.quiz.assessmentCategory !== 'benchmark' && b.quiz.assessmentCategory === 'benchmark') {
                        return 1
                    } else if (a.quiz.assessmentCategory === 'common_assessment' && b.quiz.assessmentCategory !== 'common_assessment') {
                        return -1
                    } else if (a.quiz.assessmentCategory !== 'common_assessment' && b.quiz.assessmentCategory === 'common_assessment') {
                        return 1
                    } else {
                        return 0
                    }
                })

                //console.log(assessments)

                this.setState({
                    assessments: assessments,
                    assessmentsDict: assessmentsDict
                }, () => {
                    this.getNumOpenAssessments()
                })
            })

        let completedAssessmentListener = db.collection(ColType.cleverStudentAssessments)
            .where('studentId', '==', user.uid)
            .where('finalGrade.graded', '==', true)
            .where('status', '==', 'current')
            .where('timeStamp', '>=', yearStart)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let completedAssessments = []
                let completedAssessmentsDict = {}

                querySnapshot.forEach((doc) => {
                    let completedAssessment = flattenDoc(doc)
                    completedAssessment.quiz.docs = JSON.parse(completedAssessment.quiz.docs)
                    completedAssessments.push(completedAssessment)
                    completedAssessmentsDict[doc.id] = completedAssessment
                })

                //console.log('completed', completedAssessments)

                this.setState({
                    completedAssessments: completedAssessments,
                    completedAssessmentsDict: completedAssessmentsDict
                })
            })


        this.setState({
            listeners: [assessmentListener, completedAssessmentListener]
        })
    }

    getNumOpenAssessments = () => {
        if (!(this.state.assessments && this.state.serverMoment)) {
            return
        }
        // get number of assessments that are not past the default close
        // time of 1 week
        let numAssessments = 0
        this.state.assessments.map((assessment) => {
            if (!isTestingTimeRangeExpired(assessment, this.state.serverMoment)) {
                numAssessments += 1
            }

            return false
        })
        this.setState({
            numAssessments: numAssessments
        })
    }

    componentWillUnmount() {
        this.state.listeners.map((listener) => {
            if (listener) {
                listener()
            }

            return false
        })

        if (this.state.authListener) {
            this.state.authListener()
        }

        if (this.state.clientTimestampInterval) {
            clearInterval(this.state.clientTimestampInterval)
        }
    }

    componentWillUpdate(nextProps, nextState) {
        return true
    }

    getServerTime = () => {
        if (!(this.state.user && this.state.district)) {
            return
        }
        if (this.state.serverMomentTimeout) {
            clearTimeout(this.state.serverMomentTimeout)
        }

        //console.log('getServerTime', this.state.nextCheckTimeMilliseconds)

        // timezone adjusted using district.state
        // calls setTimeout with the milliseconds to the next time to check.
        // if 8-3:30, then check at 3:30, if after 3:30, then check at 8:00 the next day
        // if after 3:30 on friday to sunday at 11:59 pm, then check at 8:00 on monday
        // if the user signs in again, then the timeout is cleared and reset and it
        // starts over again.
        let serverMomentTimeout = setTimeout(() => {
            getCurrentTimestamp(this.state.user.uid, this.state.district.state)
                .then((pServerMoment) => {
                    const serverMoment = pServerMoment.clone()
                    const nextCheckObj = this.checkTimeAndCalculateNextCheck(serverMoment)
                    const inTestingTimeRange = nextCheckObj.inTestingTimeRange
                    const nextCheckTimeMilliseconds = nextCheckObj.nextCheckTimeMilliseconds
                    //console.log(nextCheckTimeMilliseconds, inTestingTimeRange)
                    this.setState({
                        serverMoment: serverMoment,
                        inTestingTimeRange: inTestingTimeRange,
                        nextCheckTimeMilliseconds: nextCheckTimeMilliseconds
                    }, () => {
                        this.getNumOpenAssessments()
                        this.getServerTime()
                    })
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }, this.state.nextCheckTimeMilliseconds)

        this.setState({
            serverMomentTimeout: serverMomentTimeout
        })
    }

    checkTimeAndCalculateNextCheck = (serverTime) => {
        if (!serverTime) {
            console.log('checkTimeAndCalculateNextCheck: serverMoment not set');
            return {
                inTestingTimeRange: true,
                nextCheckTimeMilliseconds: 10000
            };
        }

        // Get the day of the week: Sunday = 0, Monday = 1, ..., Saturday = 6
        const dayOfWeek = serverTime.day();

        // Create target time objects for 7:30 AM and 4:00 PM
        const startTime = serverTime.clone().hour(7).minute(28).second(0);
        const endTime = serverTime.clone().hour(17).minute(10).second(0);

        let nextCheckTime;
        let inTestingTimeRange = false;

        if (dayOfWeek === 0 || dayOfWeek === 6) {
            // It's a weekend
            nextCheckTime = serverTime.clone().day(1).hour(8).minute(0).second(0); // Next Monday at 8:00 AM
        } else if (dayOfWeek === 5 && serverTime.isAfter(endTime)) {
            // It's a Friday after 3:30 PM
            nextCheckTime = serverTime.clone().day(8).hour(8).minute(0).second(0); // Next Monday at 8:00 AM
        } else if (serverTime.isBefore(startTime)) {
            // Before 8:00 AM, so next check is at 8:00 AM
            nextCheckTime = startTime;
        } else if (serverTime.isAfter(endTime)) {
            // After 3:30 PM, so next check is at 8:00 AM the next day
            nextCheckTime = startTime.clone().add(1, 'days');
        } else {
            // Between 8:00 AM and 3:30 PM, so next check is at 3:30 PM
            nextCheckTime = endTime;
            inTestingTimeRange = true;
        }

        //console.log(nextCheckTime.format('YYYY-MM-DD HH:mm:ss'), serverTime.format('YYYY-MM-DD HH:mm:ss'));

        // Calculate the difference in milliseconds
        const nextCheckTimeMilliseconds = nextCheckTime.diff(serverTime);

        return {
            inTestingTimeRange: inTestingTimeRange,
            nextCheckTimeMilliseconds: nextCheckTimeMilliseconds
        };
    };

    render() {
        const { match: { url } } = this.props

        return (
            <Layout>
                <StudentHeader
                    person={this.state.user ?
                        {
                            firstName: this.state.user.firstName ? this.state.user.firstName : '',
                            lastName: this.state.user.lastName ? this.state.user.lastName : '',
                        } : null}
                    district={this.state.district}
                />

                <Route path={`${url}/quizzes`} render={props => StudentAssessmentsComponent(props, this.state)} />
                <Route path={`${url}/completed-quizzes`} render={props => StudentCompletedAssessmentsComponent(props, this.state)} />
                <Route path={`${url}/quiz`} render={props => StudentAssessmentComponent(props, this.state)} />
            </Layout>
        )
    }
}

export default StudentBase