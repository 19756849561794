import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import Empty from '../customcomponents/Empty'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { Layout } from 'antd'
import { flattenDoc } from '../Util'
import { db } from '../firebase/Firebase'
const { Content } = Layout

class SchoolAdminSchoolView extends Component {
    state = {
        teachers: null,
    }

    // Do fetch here
    componentDidMount() {
        document.title = "Teachers - Dot It"
        this.getData()
    }

    componentWillReceiveProps(props, newProps) {
        this.getData()
    }

    getData() {
        if (this.props.schoolAdminBaseProps.school && this.props.schoolAdminBaseProps.schoolAdmin) {
            db.collection(ColType.teacher)
                .where('districtId', '==', this.props.schoolAdminBaseProps.schoolAdmin.districtId)
                .where('schoolId', '==', this.props.schoolAdminBaseProps.school.id)
                .get()
                .then((querySnapshot) => {
                    var teachers = []

                    querySnapshot.forEach((doc) => {
                        var teacher = flattenDoc(doc)
                        teachers.push(teacher)
                    })

                    teachers.sort((a, b) => {
                        if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                            return 1
                        } else {
                            return -1
                        }
                    })

                    this.setState({
                        teachers: teachers,
                    })
                })
        }
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content>
                            {this.props.schoolAdminBaseProps &&
                                this.props.schoolAdminBaseProps.schoolAdmin &&
                                this.props.schoolAdminBaseProps.school
                                ?
                                <div className="sub-menu-width m-lr-auto pt-3 pb-3">
                                    <div className="flex flex-v-center mb-2">
                                        <h2 className="font-bold mb-0">{this.props.schoolAdminBaseProps.school.schoolName}</h2>
                                    </div>
                                    <h1 className="font-bold font-30">Teachers</h1>
                                    {this.state.teachers && this.state.teachers.length === 0 ?
                                        <Empty
                                            containerClassName="mt-4 pt-2 text-center font-16"
                                            width={55}
                                            height={55}
                                            description={'No data'}
                                        /> : ''}
                                    {this.state.teachers &&
                                        this.state.teachers.map((teacher, idx) => {
                                            return <div key={teacher.id}>
                                                <Link to={"/school-admin/school-admin-teacher/" + this.props.schoolAdminBaseProps.schoolAdmin.id + "?teacher=" + teacher.id}>
                                                    <div className="p-3 br-4 font-20 font-bold font-black border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center">
                                                        {teacher.firstName + " " + teacher.lastName}
                                                    </div>
                                                </Link>
                                            </div>
                                        })}
                                </div> : ''}
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default SchoolAdminSchoolView