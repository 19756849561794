import React from 'react'

class FillInTheBlanks extends React.Component {
    render() {
        const { title, instructions, sentences } = this.props

        const processedSentences = sentences.map(sentence =>
            sentence = sentence.replaceAll('<blank>', '<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>')
        )

        return (
            <div style={{ margin: 'auto' }}>
                <div className='font-24 font-bold mb-2'>{title}</div>

                <div className='font-20 mb-1'><b>Directions:</b> {instructions}</div>

                <ol className='font-20'>
                    {processedSentences.map((sentence, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: sentence }} />
                    ))}
                </ol>
            </div>
        )
    }
}

export default FillInTheBlanks
