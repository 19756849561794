import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { getIDFromURL, getQueryStringParam, getStudentMayHaveTroubleWith } from '../.././Util'
import { Button, Modal, Icon } from 'antd'
import ReactToPrint from "react-to-print"
import PersonAvatar from '../../customcomponents/PersonAvatar'
import AccommodationView from './AccommodationView'
var moment = require('moment')

// Using this to add students until the step form for adding students is done.
class MTSSTierPlanTemplate extends Component {
  state = {
    teacherId: '',
    teacher: [],
    student: null,
    submitting: false,
    isPrinting: false,
    mayHaveTroubleWith: getStudentMayHaveTroubleWith(),
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  setIsPrinting = (e) => {
    this.setState({
      isPrinting: true,
    })
  }


  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      isPrinting: false,
    })
  }

  render() {
    let mayHaveTroubleWith = ''
    if (this.props.student && this.props.student.hasOwnProperty('mayHaveTroubleWith') && this.props.student.mayHaveTroubleWith) {
      mayHaveTroubleWith = this.props.student.mayHaveTroubleWith
    }
    // fallback for older versions
    else if (this.props.iep &&
      this.props.iep.hasOwnProperty('iep') &&
      this.props.iep.iep &&
      this.props.iep.iep.hasOwnProperty('mayHaveTroubleWith') &&
      this.props.iep.iep.mayHaveTroubleWith) {
      mayHaveTroubleWith = this.props.iep.iep.mayHaveTroubleWith
    }

    return (
      <div>
        {this.props.iep && this.props.student && this.props.teacherBaseProps ?
          <div>
            <Button
              size={"large"}
              className="ant-btn ant-btn-lg font-bold pl-2 pr-2 paper-btn"
              onClick={this.showModal}
            >
              <Icon type="printer" />
              <span>Print MTSS Plan</span>
            </Button>
            <Modal
              title="MTSS Tier Plan"
              visible={this.state.visible}
              footer={null}
              width={700}
              bodyStyle={{ padding: '0px' }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <div className="font-28 font-bold pl-3 pt-3">
                <div>
                  <ReactToPrint
                    trigger={() =>
                      <Button
                        size={'large'}
                        className="ant-btn-primary"
                      >
                        <Icon type="printer" className="mr-1" />Print MTSS Tier Plan
                      </Button>
                    }
                    content={() => this.content}
                  />
                </div>
              </div>
              <div ref={el => (this.content = el)}>
                <div className="p-3">
                  <div className="flex flex-v-center mb-2">
                    <span className="font-30 font-bold">
                      MTSS Tier Plan
                    </span>
                  </div>
                  <div>
                    <div className="">
                      <PersonAvatar person={this.props.student}
                        size={'large'}
                        containerClassName="font-24"
                        avatarClassName="font-24 font-bold"
                        personClassName="font-bold"
                      />
                      <h2 className="font-bold mb-05 mt-3">
                        Problem Analysis/Hypothesis
                      </h2>
                      <h3>
                        {mayHaveTroubleWith ?
                          this.props.student.firstName + ' has difficulty ' +
                          (this.state.mayHaveTroubleWith.hasOwnProperty(this.props.iep.iep.mayHaveTroubleWith) ?
                            this.state.mayHaveTroubleWith[this.props.iep.iep.mayHaveTroubleWith] : '') + '.'
                          : 'Not specified.'}
                      </h3>
                      <h2 className="font-bold mb-05 mt-3">
                        Problem Statement
                      </h2>
                      <h3>
                        {this.props.iep ?
                          this.props.student.firstName + ' needs to improve in ' + this.props.iep.iep.impactStatement :
                          ''}
                      </h3>
                      <h2 className="font-bold mb-05 mt-3">
                        Baseline/Benchmark Data
                      </h2>
                      <h3 className="mb-3">
                        {this.props.iep.iep.presentLevel.presentLevelParagraph}
                      </h3>
                      <h2 className="font-bold mb-05 mt-3">
                        Plan Development and Implementation
                      </h2>
                      <h3 className="mb-3">
                        {this.props.iep ?
                          this.props.iep.iep.iepParagraph :
                          ''}
                      </h3>
                      {this.props.iep.iep.hasOwnProperty('service') &&
                        this.props.iep.iep.service &&
                        this.props.iep.iep.service.hasOwnProperty('curriculum') &&
                        this.props.iep.iep.service.curriculum ?
                        <div>
                          <h2 className="font-bold mb-05 mt-3">
                            Curriculum
                          </h2>
                          <h3 className="mb-3">
                            {this.props.iep.iep.service.curriculum}
                          </h3>
                        </div>
                        : ''}
                      <h2 className="font-bold mb-05 mt-3">
                        Implementation Specifics
                      </h2>
                      <h3 className="mb-3">
                        {this.props.iep ?
                          <div>
                            <div>{'Provider: ' +
                              (this.props.iep.iep.service.hasOwnProperty('provider') &&
                                this.props.iep.iep.service.provider ?
                                this.props.iep.iep.service.provider :
                                '')}
                            </div>
                            <div>{'Service Information: ' +
                              this.props.iep.iep.service.serviceDuration + ' minutes, ' +
                              this.props.iep.iep.service.days + ' days per week'}
                            </div>
                          </div>
                          :
                          ''}
                      </h3>
                      <div>
                        <h2 className="font-bold mt-3">
                          Connection Strategies
                        </h2>
                        <AccommodationView iep={this.props.iep} student={this.props.student} />
                      </div>
                    </div>
                    <div className="mt-2 page-break-inside-avoid">

                      <h2 className="font-bold mb-05 mt-3">
                        Review of Results of Progressing Monitoring/Intervention Data
                      </h2>
                      <h3 className="mb-3">
                        {this.props.iep ?
                          <div>
                            <div>
                              {'On track: ' + (this.props.iep.onTrack ? 'Yes' : 'No')}
                            </div>
                            <div>
                              {'Progress monitoring points: ' + (
                                this.props.iep.hasOwnProperty('totalMeasurements') &&
                                  this.props.iep.totalMeasurements ?
                                  this.props.iep.totalMeasurements : '0')}
                            </div>
                          </div>
                          :
                          ''}


                        <div>Date of Decision: {moment.utc().format("MM/DD/YYYY")}</div>
                        <div>Goal Status: {(this.props.iep.hasOwnProperty('isContinued')
                          && this.props.iep.isContinued ? 'Continuing' :
                          (!this.props.iep.hasOwnProperty('isArchived') ||
                            !this.props.iep.isArchived) ? 'Goal has not been met yet.' :
                            'Goal is met.')}
                        </div>
                      </h3>


                    </div>



                  </div>
                </div>
              </div>
            </Modal>
          </div>
          : ''}
      </div>
    )
  }
}

export default MTSSTierPlanTemplate
