import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '../Util'
import ColType from '../Types'
import { ResponsiveBar } from '@nivo/bar'
import { Button, Icon, Tabs } from 'antd'
import ReactToPrint from "react-to-print"
import Empty from '../customcomponents/Empty';
let moment = require('moment')
var html2pdf = null
if (typeof window !== 'undefined') {
    html2pdf = require('html2pdf.js')
}

class DistrictSAMScores extends Component {
    state = {
        SAMScoreDict: {},
        SAMScoreByYearDict: {},
        retrieved: false,
        SAMScoresMounted: false,
        downloading: false,
        categoryData: [],
        categoryDataByYearDict: {},
        years: [],
        categoryMetrics: [
            [
                { header: false, title: "1. The principal is actively involved", score: 0 },
                { header: false, title: "2. A leadership team is established", score: 0 },
                { header: false, title: "3. The leadership team actively engages in ongoing professional development", score: 0 },
                { header: false, title: "4. A strategic plan for MTSS implementation is developed", score: 0 },
                { header: false, title: "5. The leadership team is actively facilitating implementation", score: 0 },
            ],
            [
                { header: false, title: "6. The critical elements of MTSS are defined and understood", score: 0 },
                { header: false, title: "7. Professional development and coaching provided to staff", score: 0 },
                { header: false, title: "8. The leadership team facilitates PD on data-based problem-solving", score: 0 },
                { header: false, title: "9. The leadership team facilitates PD on multi-tiered instruction and intervention", score: 0 },
                { header: false, title: "10. Coaching is used to support MTSS implementation", score: 0 },
                { header: false, title: "11. Schedules provide adequate time for training and coaching", score: 0 },
                { header: false, title: "12. Schedules provide adequate time to administer assessments", score: 0 },
                { header: false, title: "13. Schedules provide adequate time for multiple tiers of instruction/interventions", score: 0 },
                { header: false, title: "14. Schedules provide adequate time for data-based problem-solving", score: 0 },
                { header: false, title: "15. Processes, procedures, and decision-rules are established for DBPS", score: 0 },
                { header: false, title: "16. Resources to support MTSS implementation are identified and allocated", score: 0 },
            ],
            [
                { header: false, title: "17. Staff have consensus and engage in MTSS Implementation", score: 0 },
                { header: false, title: "18. Staff are provided data on MTSS fidelity and student outcomes", score: 0 },
                { header: false, title: "19. The infrastructure exists to support family and community engagement", score: 0 },
                { header: false, title: "20. Educators actively engage families in MTSS", score: 0 },
            ],
            [
                { header: false, title: "21. DBPS for student outcomes occurs across content areas, grade levels, and tiers", score: 0 },
                { header: false, title: "22. Across tiers, data used to identify “gap” between expected and current outcomes", score: 0 },
                { header: false, title: "23. Data are used to identify reasons why students are not meeting expectations", score: 0 },
                { header: false, title: "24. Plans based on verified reasons why students are not meeting expectations", score: 0 },
                { header: false, title: "25. Student progress specific to academic or behavior goals are monitored", score: 0 },
                { header: false, title: "26. Data-based problem solving is part of a student's full and individual evaluation", score: 0 },
                { header: false, title: "27. Data are used to address performance across diverse group", score: 0 },
                { header: false, title: "28. Resources for implementation of MTSS are addressed through data-based problem-solving", score: 0 },
            ],
            [
                { header: false, title: "29. Instruction at all tiers is accessible and responsive for all students", score: 0 },
                { header: false, title: "30. Tier 1 academic practices clearly identify learning standards", score: 0 },
                { header: false, title: "31. Tier 1 behavior practices identify school-wide expectations", score: 0 },
                { header: false, title: "32. Tier 2 academic practices include common student needs, are linked to Tier 1", score: 0 },
                { header: false, title: "33. Tier 2 behavior practices include common student needs, are linked to Tier 1", score: 0 },
                { header: false, title: "34. Tier 3 academic practices are based on students' needs, aligned with Tier 1 and Tier 2", score: 0 },
                { header: false, title: "35. Tier 3 behavior practices are based on students' needs, aligned with Tier 1 and Tier 2", score: 0 },
            ],
            [
                { header: false, title: "36. Staff understand and have access to data sources", score: 0 },
                { header: false, title: "37. Data systems enable educators to engage in data-based problem solving for equity", score: 0 },
                { header: false, title: "38. Policies and procedures for decision-making are established", score: 0 },
                { header: false, title: "39. Effective data tools are used appropriately and independently by staff", score: 0 },
                { header: false, title: "40. Data sources are used to evaluate the fidelity and impact", score: 0 },
                { header: false, title: "41. Available resources are allocated effectively", score: 0 },
                { header: false, title: "42. Data sources are monitored for consistency and accuracy", score: 0 },
            ]
        ],
    }

    componentDidMount() {
        this.getSAMScores()
    }

    componentWillUnmount() {
        this.getSAMScores()
    }

    getSAMScores = () => {
        if (!this.props.schools) {
            return
        }
        if (!this.props.districtId) {
            return
        }
        if (this.state.retrieved) {
            return
        }

        this.setState({ retrieved: true })

        this.props.schools.map((school) => {
            // get most recent SAMScore (limit 1)
            db.collection(ColType.SAMScore)
                .where("schoolId", "==", school.id)
                .where("districtId", "==", this.props.districtId)
                .where("deleted", "==", false)
                .orderBy('timeStamp', 'desc')
                //.limit(1)
                .get()
                .then((querySnapshot) => {
                    let SAMScores = [];
                    let years = [];
                    querySnapshot.forEach((doc) => {
                        let SAMScore = flattenDoc(doc);
                        if (SAMScore.timeStamp && SAMScore.timeStamp.seconds) {
                            // Convert seconds to moment.js date object
                            SAMScore.timeStamp = moment.utc(SAMScore.timeStamp.seconds * 1000);
                            SAMScore.timeStampStr = SAMScore.timeStamp.format('MM/DD/YYYY');

                            // Determine the grouping year based on the cutoff date
                            let year = SAMScore.timeStamp.year();
                            let cutoffDate = moment.utc(`${year}-06-30`);
                            if (SAMScore.timeStamp > cutoffDate) {
                                year++;  // If the date is after June 30, count it in the next year
                            }

                            // Adding a 'year' property to the SAMScore object
                            SAMScore.year = year;

                            SAMScores.push(SAMScore);
                            years.push(year);
                        }
                    });

                    // Now group by year
                    let groupedByYear = SAMScores.reduce((acc, score) => {
                        if (!acc[score.year]) {
                            acc[score.year] = [];
                        }
                        acc[score.year].push(score);
                        return acc;
                    }, {});

                    // SAMScore can be null if there are no SAMScores
                    let SAMScore = null
                    if (SAMScores.length > 0) {
                        SAMScore = SAMScores[0]
                    }
                    let SAMScoreDict = this.state.SAMScoreDict
                    SAMScoreDict[school.id] = SAMScore
                    let SAMScoreByYearDict = this.state.SAMScoreByYearDict
                    for (let year in groupedByYear) {
                        if (!SAMScoreByYearDict.hasOwnProperty(year)) {
                            SAMScoreByYearDict[year] = {}
                        }
                        let SAMScore = null
                        if (groupedByYear[year].length > 0) {
                            SAMScore = groupedByYear[year][0]
                        }
                        SAMScoreByYearDict[year][school.id] = SAMScore
                    }
                    let stateYears = this.state.years
                    years.map((year) => {
                        if (!stateYears.includes(year)) {
                            stateYears.push(year)
                        }
                        return false
                    })
                    stateYears.sort((a, b) => b - a)
                    this.setState({
                        SAMScoreDict: SAMScoreDict,
                        SAMScoreByYearDict: SAMScoreByYearDict,
                        years: stateYears,
                    })

                    // if all SAMScores have been retrieved, set SAMScoresMounted to true
                    if (Object.keys(this.state.SAMScoreDict).length === this.props.schools.length) {
                        this.setState({ SAMScoresMounted: true })

                        // get chart data
                        for (let year in SAMScoreByYearDict) {
                            this.getChartData(this.props.schools, SAMScoreByYearDict, year)
                        }
                    }
                })

            return false
        })
    }

    getCategoryData = (schools, SAMScoreByYearDict, categoryKey, year) => {
        let categoryData = []
        let categoryDataDescending = []
        let numScores = 0
        schools.map((school, idx) => {
            let value = 0
            if (!SAMScoreByYearDict.hasOwnProperty(year)) {
                return false
            }
            if (!SAMScoreByYearDict[year].hasOwnProperty(school.id)) {
                return false
            }
            // value may be null if there are no SAMScores
            let schoolSAMDict = SAMScoreByYearDict[year][school.id]
            if (schoolSAMDict &&
                schoolSAMDict.hasOwnProperty(categoryKey)) {
                value = schoolSAMDict[categoryKey]
            }
            if (value > 0) {
                numScores += 1
            }

            categoryData.push({
                "id": school.schoolName,
                "label": school.schoolName,
                "value": value,
                "color": "rgb(97, 205, 187)",
            })
            categoryDataDescending.push({
                "id": school.schoolName,
                "label": school.schoolName,
                "value": value,
                "color": "rgb(97, 205, 187)",
            })

            return false
        })
        categoryData.sort((a, b) => {
            return a.value - b.value
        })
        categoryDataDescending.sort((a, b) => {
            return b.value - a.value
        })
        let averageScore = this.getAverageScore(categoryData.map((data) => data.value), numScores)
        return { 'categoryData': categoryData, 'categoryDataDescending': categoryDataDescending, 'averageScore': averageScore, 'numScores': numScores }
    }

    getAverageScore = (scores, numScores) => {
        let totalScore = 0
        for (let i = 0; i < scores.length; i++) {
            let score = scores[i]
            // default value is 0
            if (score > 0) {
                totalScore += score
            }
        }
        if (numScores === 0) {
            return 0
        }
        return Math.round(totalScore / numScores)
    }

    getChartData = (schools, SAMScoreByYearDict, year) => {
        let categoryDataByYearDict = this.state.categoryDataByYearDict
        categoryDataByYearDict[year] = [
            this.getCategoryData(schools, SAMScoreByYearDict, "category1Pct", year),
            this.getCategoryData(schools, SAMScoreByYearDict, "category2Pct", year),
            this.getCategoryData(schools, SAMScoreByYearDict, "category3Pct", year),
            this.getCategoryData(schools, SAMScoreByYearDict, "category4Pct", year),
            this.getCategoryData(schools, SAMScoreByYearDict, "category5Pct", year),
            this.getCategoryData(schools, SAMScoreByYearDict, "category6Pct", year),
        ]
        this.setState({
            categoryData: [
                this.getCategoryData(schools, SAMScoreByYearDict, "category1Pct", year),
                this.getCategoryData(schools, SAMScoreByYearDict, "category2Pct", year),
                this.getCategoryData(schools, SAMScoreByYearDict, "category3Pct", year),
                this.getCategoryData(schools, SAMScoreByYearDict, "category4Pct", year),
                this.getCategoryData(schools, SAMScoreByYearDict, "category5Pct", year),
                this.getCategoryData(schools, SAMScoreByYearDict, "category6Pct", year),
            ],
            categoryDataByYearDict: categoryDataByYearDict,
        })
    }

    render() {
        return (
            <div>
                {this.state.SAMScoresMounted ?
                    <div>
                        {Object.keys(this.state.SAMScoreByYearDict).length === 0 ?
                            <div>
                                <Empty
                                    containerClassName="mt-50 mb-50 text-center"
                                    width={55}
                                    height={55}
                                    image={'/empty/goals.png'}
                                    description={'There is currently no data.'}
                                />
                            </div>
                            :
                            <Tabs defaultActiveKey="1" className='mb-2' tabPosition='left' size='large'>
                                {this.state.years.map((year, yearIdx) => {
                                    return <Tabs.TabPane tab={(year - 1) + '-' + year} key={yearIdx + 1}>

                                        <ReactToPrint
                                            trigger={() =>
                                                <Button
                                                    size={'large'}
                                                    type="default"
                                                    className="font-20 flex flex-v-center ml-auto"
                                                    style={{
                                                        padding: '16px',
                                                        height: '44px'
                                                    }}
                                                    disabled={this.state.downloading}
                                                >
                                                    <Icon type="download" className="mr-1" />
                                                    {this.state.downloading ? 'Downloading...' : 'Download'}
                                                </Button>
                                            }
                                            content={() => this['contentContainer']}
                                            onBeforeGetContent={async () => {
                                                return new Promise((resolve, reject) => {
                                                    this.setState({
                                                        downloading: true,
                                                    }, () => {
                                                        resolve()
                                                    })
                                                })
                                            }}
                                            print={async (printIframe) => {
                                                if (!html2pdf) {
                                                    this.setState({
                                                        downloading: false,
                                                    })
                                                    return new Promise((resolve, reject) => { resolve() })
                                                }

                                                const document = printIframe.contentDocument
                                                if (document) {
                                                    const html = document.getElementsByTagName('html')[0]
                                                    await html2pdf().from(html).set({
                                                        image: { type: 'png', quality: 1.0 },
                                                        html2canvas: { scale: 1.0 },
                                                        margin: 12,
                                                        pagebreak: { mode: ['css'], avoid: '.page-break-inside-avoid-not-important' },
                                                    }).save('SAMReport.pdf')
                                                }

                                                this.setState({
                                                    downloading: false,
                                                })


                                                return new Promise((resolve, reject) => { resolve() })
                                            }}
                                        />

                                        <div ref={el => (this['contentContainer'] = el)}>
                                            <div className='font-24 font-bold mb-2 flex w-100'>
                                                <div>SAM Reports Summary</div>
                                            </div>
                                            {this.state.categoryDataByYearDict.hasOwnProperty(year) &&
                                                this.state.categoryDataByYearDict[year].map((categoryData, idx) => {
                                                    let header = '1. Leadership Domain (Items 1-5)'
                                                    if (idx === 1) {
                                                        header = '2. Building the Capacity/Infrastructure for Implementation Domain (Items 6-16)'
                                                    } else if (idx === 2) {
                                                        header = '3. Communication and Collaboration Domain (Items 17-20)'
                                                    } else if (idx === 3) {
                                                        header = '4. Data-Based Problem-Solving Domain (Items 21-28)'
                                                    } else if (idx === 4) {
                                                        header = '5. Three Tiered Instructional /Intervention Model Domain (Items 29-35)'
                                                    } else if (idx === 5) {
                                                        header = '6. Data-Evaluations Domain (Items 36-42)'
                                                    }
                                                    return <div key={'container-' + idx}>
                                                        <div className={'font-20 mb-1 flex w-100 p-2 br-4 page-break-inside-avoid-not-important'}
                                                            style={{ background: 'rgb(181, 245, 236)' }}>
                                                            <div>
                                                                <div className='font-bold'>{header}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='font-20'>
                                                                <div className='font-bold'>Items</div>
                                                                {this.state.categoryMetrics[idx].map((metric, idx) => {
                                                                    return <div className='page-break-inside-avoid-not-important' key={'metric-title-' + idx}>{metric.title}</div>
                                                                })}
                                                            </div>
                                                        </div>
                                                        {!this.state.downloading ?
                                                            <div className='font-20 mt-2 mb-1'>
                                                                {categoryData.numScores > 0 ?
                                                                    <div>{'District average score: ' + categoryData.averageScore}%</div>
                                                                    :
                                                                    <div>District average score: N/A</div>
                                                                }
                                                            </div> : ''}
                                                        {this.state.downloading ?
                                                            <div className='font-20 mb-3'>
                                                                <div className='flex w-100 flex-v-center mt-2 page-break-inside-avoid-not-important'>
                                                                    <div className='font-bold'>{'District and Schools'}</div>
                                                                    <div className='font-bold ml-auto'>Reported Value</div>
                                                                </div>
                                                                <div className='flex w-100 flex-v-center mb-2 pb-2 border-bottom mt-1 pt-2 border-top page-break-inside-avoid-not-important'>
                                                                    <div>{this.props.district ? this.props.district.district : ''}:</div>
                                                                    <div className='ml-auto'>{categoryData.numScores > 0 ?
                                                                        <div>{categoryData.averageScore}%</div>
                                                                        :
                                                                        <div>N/A</div>}
                                                                    </div>
                                                                </div>
                                                                {categoryData.categoryDataDescending.map((data, idx) => {
                                                                    return <div key={'category-descending-' + idx} className='flex w-100 flex-v-center mb-2 pb-2 border-bottom page-break-inside-avoid-not-important'>
                                                                        <div>{data.label}:</div>
                                                                        <div className='ml-auto'>
                                                                            {data.value === 0 ? 'N/A' : data.value + '%'}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                            : ''}

                                                        {!this.state.downloading ?
                                                            <div style={{ height: '440px' }}>
                                                                <ResponsiveBar
                                                                    data={categoryData.categoryData}
                                                                    // keys={data.map(i => i.id)}
                                                                    indexBy="id"
                                                                    margin={{ top: 0, right: 32, bottom: 130, left: 330 }}
                                                                    padding={0.15}
                                                                    groupMode="grouped"
                                                                    layout="horizontal"
                                                                    colors={categoryData.categoryData.map(c => c.color)}
                                                                    colorBy="index"
                                                                    labelFormat={(value) => value + '%'}
                                                                    tooltipFormat={(value) => value + '%'}
                                                                    theme={{
                                                                        fontSize: 20,
                                                                        fontColor: "#fff",
                                                                        axis: {
                                                                            legend: {
                                                                                text: {
                                                                                    fontSize: 20,
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                    defs={[
                                                                        {
                                                                            id: 'dots',
                                                                            type: 'patternDots',
                                                                            background: 'inherit',
                                                                            color: '#38bcb2',
                                                                            size: 4,
                                                                            padding: 1,
                                                                            stagger: true
                                                                        },
                                                                        {
                                                                            id: 'lines',
                                                                            type: 'patternLines',
                                                                            background: 'inherit',
                                                                            color: '#eed312',
                                                                            rotation: -45,
                                                                            lineWidth: 6,
                                                                            spacing: 10
                                                                        }
                                                                    ]}
                                                                    fill={[

                                                                    ]}
                                                                    borderColor={{ from: 'color', modifiers: [['brighter', '1.6']] }}
                                                                    axisTop={null}
                                                                    axisRight={null}
                                                                    axisBottom={{
                                                                        tickSize: 5,
                                                                        tickPadding: 5,
                                                                        tickRotation: 0,
                                                                        tickValues: [0, 20, 40, 60, 80, 100],
                                                                        format: (value) => value + '%',
                                                                        legend: 'Reported scores',
                                                                        legendPosition: 'middle',
                                                                        legendOffset: 50,
                                                                    }}
                                                                    axisLeft={{
                                                                        tickSize: 5,
                                                                        tickPadding: 5,
                                                                        tickRotation: 0,
                                                                        legend: '',
                                                                        legendPosition: 'middle',
                                                                        legendOffset: -40
                                                                    }}
                                                                    minValue={0}
                                                                    maxValue={100}
                                                                    enableGridX={true}
                                                                    enableGridY={false}
                                                                    labelSkipWidth={12}
                                                                    labelSkipHeight={12}
                                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                    legends={[]}
                                                                    animate={false}
                                                                    motionStiffness={90}
                                                                    motionDamping={15}
                                                                />
                                                            </div> : ''}
                                                    </div>
                                                })}
                                        </div>
                                    </Tabs.TabPane>
                                })}
                            </Tabs>}
                    </div> : ''}
            </div>
        )
    }
}

export default DistrictSAMScores