import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { flattenDoc, getIDFromURL } from '../../Util'
//import { Layout, Icon, Button, Checkbox, Table } from 'antd'
import ChatHome from './ChatHome'

class TeacherChatHome extends Component {
    state = {
        teacherId: '',
        schoolPersonnelDict: {},
        retrievedTeachers: false,
    }

    // Do fetch here
    componentDidMount() {
        var teacherId = getIDFromURL(window.location)
        this.setState({
            teacherId: teacherId,
        })

        this.getTeachers()
    }

    componentWillReceiveProps(nextProps) {
        this.getTeachers()
    }

    getTeachers = () => {
        if (!(this.props.teacherBaseProps.teacher &&
            this.props.teacherBaseProps.school)) {
            return
        }
        if (this.state.retrievedTeachers) {
            return
        }

        this.setState({
            retrievedTeachers: true,
        })

        let schoolPersonnelLocalDict = { 'school': this.props.teacherBaseProps.school, 'teachers': [], 'instructionalFacilitators': [], 'schoolAdmins': [] }
        let schoolPersonnelDict = this.state.schoolPersonnelDict
        schoolPersonnelDict[this.props.teacherBaseProps.school.id] = schoolPersonnelLocalDict
        this.setState({
            schoolPersonnelDict: schoolPersonnelDict,
        }, () => {
            db.collection(ColType.teacher)
                .where('schoolId', '==', this.props.teacherBaseProps.school.id)
                .get()
                .then((querySnapshot) => {
                    let teachers = []
                    querySnapshot.forEach((doc) => {
                        let teacher = flattenDoc(doc)
                        teachers.push(teacher)
                    })
                    let schoolPersonnelDict = this.state.schoolPersonnelDict
                    schoolPersonnelDict[this.props.teacherBaseProps.school.id]['teachers'] = schoolPersonnelDict[this.props.teacherBaseProps.school.id]['teachers'].concat(teachers)
                    this.setState({
                        schoolPersonnelDict: schoolPersonnelDict,
                    })
                })

            db.collection(ColType.instructionalFacilitator)
                .where('schoolIds', 'array-contains', this.props.teacherBaseProps.school.id)
                .where('districtId', '==', this.props.teacherBaseProps.school.districtId)
                .get()
                .then((querySnapshot) => {
                    let instructionalFacilitators = []
                    querySnapshot.forEach((doc) => {
                        let instructionalFacilitator = flattenDoc(doc)
                        instructionalFacilitators.push(instructionalFacilitator)
                    })
                    let schoolPersonnelDict = this.state.schoolPersonnelDict
                    schoolPersonnelDict[this.props.teacherBaseProps.school.id]['instructionalFacilitators'] = schoolPersonnelDict[this.props.teacherBaseProps.school.id]['instructionalFacilitators'].concat(instructionalFacilitators)
                    this.setState({
                        schoolPersonnelDict: schoolPersonnelDict,
                    })
                })

            db.collection(ColType.schoolAdmin)
                .where('schoolId', '==', this.props.teacherBaseProps.school.id)
                .get()
                .then((querySnapshot) => {
                    let schoolAdmins = []
                    querySnapshot.forEach((doc) => {
                        let schoolAdmin = flattenDoc(doc)
                        schoolAdmins.push(schoolAdmin)
                    })
                    let schoolPersonnelDict = this.state.schoolPersonnelDict
                    schoolPersonnelDict[this.props.teacherBaseProps.school.id]['schoolAdmins'] = schoolPersonnelDict[this.props.teacherBaseProps.school.id]['schoolAdmins'].concat(schoolAdmins)
                    this.setState({
                        schoolPersonnelDict: schoolPersonnelDict,
                    })
                })
        })
    }

    render() {
        return (
            <div>
                <ChatHome
                    personRole={'teacher'}
                    person={this.props.teacherBaseProps.teacher}
                    students={this.props.teacherBaseProps.students}
                    schoolPersonnelDict={this.state.schoolPersonnelDict}
                    IEPGoals={this.props.teacherBaseProps.IEPGoals}
                    IEPDict={this.props.teacherBaseProps.IEPDict}
                    schoolId={this.props.teacherBaseProps.teacher ?
                        this.props.teacherBaseProps.teacher.schoolId : ''}
                    chatNotificationDict={this.props.teacherBaseProps.chatNotificationDict}
                    setCurrentlyViewingChatIdFunc={this.props.teacherBaseProps.setCurrentlyViewingChatIdFunc}
                />
            </div>
        )
    }
}

export default TeacherChatHome
