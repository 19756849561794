import React from 'react'
import FillInTheBlanks from './FillInTheBlanks'
import Standard from './Standard'
import LessonPlan from './LessonPlan'
import TierOneDifferentiation from './TierOneDifferentiation'
import Assessment from './Assessment'
import Text from './Text'
import MatchingGame from './MatchingGame'
import FlashCards from './FlashCards'
import ClozePassage from './ClozePassage'
import Plot2dAssessment from './Plot2dAssessment'
import TableAssessment from './TableAssessment'
import EquationSystemAssessment from './EquationSystemAssessment'
import RightTriangleAssessment from './RightTriangleAssessment'
import WordProblemAssessment from './WordProblemAssessment'
import PassageAssessment from './PassageAssessment'
import RectangleAssessment from './RectangleAssessment'
import IntNumberLineAssessment from './IntNumberLineAssessment'
/*
import SquareAssessment from './SquareAssessment'
*/

const componentMapping = {
    "FillInTheBlanks": FillInTheBlanks,
    "Standard": Standard,
    "LessonPlan": LessonPlan,
    "TierOneDifferentiation": TierOneDifferentiation,
    "Assessment": Assessment,
    "Text": Text,
    "MatchingGame": MatchingGame,
    "FlashCards": FlashCards,
    "ClozePassage": ClozePassage,
    "Plot2dAssessment": Plot2dAssessment,
    "TableAssessment": TableAssessment,
    "EquationSystemAssessment": EquationSystemAssessment,
    "RightTriangleAssessment": RightTriangleAssessment,
    "WordProblemAssessment": WordProblemAssessment,
    "PassageAssessment": PassageAssessment,
    "RectangleAssessment": RectangleAssessment,
    "IntNumberLineAssessment": IntNumberLineAssessment,
    /*
   "SquareAssessment": SquareAssessment,
    */
}

class ComponentFactory extends React.Component {
    renderComponent = (componentProps) => {
        const ComponentClass = componentMapping[componentProps.type]

        if (!ComponentClass) {
            console.error(`Unknown component type: ${componentProps.type}`)
            return <div></div>
        }

        return React.createElement(ComponentClass, componentProps)
    }

    render() {
        return (
            <div className={this.props.hasOwnProperty('containerClassName') ? this.props.containerClassName : ''}>
                {this.renderComponent(this.props.component)}
            </div>
        )
    }
}

export default ComponentFactory
