import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import CoachingNotesForm from './CoachingNotesForm'
import Empty from '../customcomponents/Empty'
import { flattenDoc } from '../Util'
import { Col, Popconfirm, Row, notification, Button, Tooltip, Icon } from 'antd'
import CoachingNotesUpdateForm from './CoachingNotesUpdateForm'
import PersonAvatar from '../customcomponents/PersonAvatar'
import ReactToPrint from "react-to-print"
//import { Layout, Row, Col, Select, Icon, Tabs, Menu } from 'antd'
const moment = require('moment')

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}


class CoachingNotes extends Component {
    state = {
        coachingReports: null,
        selectedReport: null,
        coachingReportsListener: null,
        retrieved: false,
        formData: [
            {
                label: 'Title',
                fieldDecorator: 'reportTitle',
                placeholder: '',
            },
            {
                label: 'Description',
                fieldDecorator: 'reportDescription',
                placeholder: '',
            },
            {
                label: 'Goal',
                fieldDecorator: 'reportReflection',
                placeholder: '',
            },
            {
                label: "Pre-conference notes",
                fieldDecorator: 'adminIncentive',
                placeholder: '',
            },
            {
                label: "Co-teaching notes",
                fieldDecorator: 'adminChangeEnvironment',
                placeholder: '',
            },
            {
                label: "Post-conference notes",
                fieldDecorator: 'adminProgress',
                placeholder: '',
            },
            {
                label: 'Reflection',
                fieldDecorator: 'adminNextSteps',
                placeholder: '',
            },
            {
                label: "Decisions",
                fieldDecorator: 'coachesSupport',
                placeholder: '',
            }
        ]
    }

    // Do fetch here
    componentDidMount() {
        this.getData()
    }

    componentWillUnmount() {
        if (this.state.coachingReportsListener) {
            this.state.coachingReportsListener()
        }
    }

    getData() {
        if (this.state.retrieved) {
            return
        }

        this.setState({
            retrieved: false,
        })

        if (this.state.coachingReportsListener) {
            this.state.coachingReportsListener()
        }

        let coachingReportsListener = db.collection(ColType.coachingReports)
            //.where('authorId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id)
            .where('districtId', '==', this.props.districtId)
            .where('schoolId', '==', this.props.teacher.schoolId)
            .where('teacherId', '==', this.props.teacherId)
            .orderBy('timeStamp', 'desc')
            .onSnapshot((querySnapshot) => {
                let coachingReports = []
                querySnapshot.forEach((doc) => {
                    coachingReports.push(flattenDoc(doc))
                })

                let selectedReport = this.state.selectedReport
                let containsSelectedReport = false
                if (selectedReport) {
                    containsSelectedReport = coachingReports.filter((coachingReport) => {
                        if (!selectedReport) {
                            return false
                        }
                        return coachingReport.id === selectedReport.id
                    }).length > 0
                }

                // default/delete cases, want to reset selectedReport to a default value
                if (!selectedReport && coachingReports.length > 0) {
                    selectedReport = coachingReports[0]
                } else if (selectedReport && coachingReports.length > 0 && !containsSelectedReport) {
                    selectedReport = coachingReports[0]
                } else if (selectedReport && coachingReports.length === 0) {
                    selectedReport = null
                }

                // add case
                if (this.state.coachingReports && coachingReports.length > 0 &&
                    coachingReports.length > this.state.coachingReports.length) {
                    if (coachingReports[0].authorId === this.props.authorId) {
                        selectedReport = coachingReports[0]
                    }
                }

                // same selected report, but updates to timestamp
                if (selectedReport && coachingReports.length > 0 && containsSelectedReport) {
                    let values = coachingReports.filter((coachingReport) => {
                        if (!selectedReport) {
                            return false
                        }
                        return coachingReport.id === selectedReport.id
                    })
                    if (values.length > 0) {
                        selectedReport = values[0]
                    }
                }

                console.log('coaching reports', coachingReports)

                this.setState({
                    coachingReports: coachingReports,
                    selectedReport: selectedReport,
                })
            })

        this.setState({
            coachingReportsListener: coachingReportsListener,
        })
    }

    deleteReport = (report) => {
        if (!report) {
            return
        }

        db.collection(ColType.coachingReports)
            .doc(report.id)
            .delete()
            .then(() => {
                successMessage('Report deleted successfully.')
            })
            .catch((error) => {
                console.error("Error deleting document: ", error)
                errorMessage('Error deleting report, please try again.')
            })
    }

    render() {
        return (
            <div>
                <div className='flex flex-v-center w-100 mt-2 mb-2'>
                    <div>
                        <div className='font-24 font-bold mb-2'>Coaching Reports</div>
                    </div>
                    <CoachingNotesForm
                        author={this.props.author}
                        authorId={this.props.authorId}
                        teacherId={this.props.teacherId}
                        teacher={this.props.teacher}
                        districtId={this.props.districtId}
                    />
                </div>

                <div>
                    {this.state.coachingReports &&
                        this.state.coachingReports.length === 0 ?
                        <div>
                            <Empty
                                containerClassName="mt-50 text-center"
                                width={55}
                                height={55}
                                image={'/empty/goals.png'}
                                description={'There are currently no coaching reports.'}
                            />
                        </div>
                        : ''}

                    {this.state.coachingReports &&
                        this.state.coachingReports.length > 0 ?
                        <div className='flex w-100 mt-4 font-20'>
                            {this.state.selectedReport ?
                                <div className='p-3 border br-15 w-100'>
                                    <div className='w-100 flex flex-v-center'>
                                        <div className='font-24 font-bold'>
                                            Coaching Report
                                        </div>
                                        <div className='ml-auto'>
                                            <div className='flex'>
                                                <ReactToPrint
                                                    trigger={() =>
                                                        <Tooltip title='Print report'>
                                                            <Button icon='printer' className='lg-circle-btn mr-1'>
                                                            </Button>
                                                        </Tooltip>
                                                    }
                                                    content={() => this.componentRef}
                                                />

                                                {this.state.selectedReport.hasOwnProperty('authorId') &&
                                                    this.state.selectedReport.authorId === this.props.authorId ?
                                                    <div className='flex'>
                                                        <CoachingNotesUpdateForm
                                                            author={this.props.author}
                                                            authorId={this.props.authorId}
                                                            teacherId={this.props.teacherId}
                                                            districtId={this.props.districtId}
                                                            teacher={this.props.teacher}
                                                            report={this.state.selectedReport}
                                                        />

                                                        <Popconfirm
                                                            title="Are you sure you want to delete this report?"
                                                            onConfirm={() => {
                                                                this.deleteReport(this.state.selectedReport)
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Tooltip title='Delete report'>
                                                                <Button icon='delete' className='lg-circle-btn ml-1'>
                                                                </Button>
                                                            </Tooltip>
                                                        </Popconfirm>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={el => (this.componentRef = el)}>
                                        <div className='print-show mb-3'>
                                            <div className='flex flex-v-center mb-05'>
                                                <div className='font-30 font-bold'>
                                                    Coaching Report
                                                </div>
                                                <div className='ml-1 mr-1 font-20'>
                                                    -
                                                </div>
                                                <div className='font-20'>
                                                    {this.state.selectedReport &&
                                                        this.state.selectedReport.hasOwnProperty('timeStamp') &&
                                                        this.state.selectedReport.timeStamp &&
                                                        this.state.selectedReport.timeStamp.hasOwnProperty('seconds') &&
                                                        this.state.selectedReport.timeStamp.seconds ?
                                                        <div>{moment.utc(this.state.selectedReport.timeStamp.seconds * 1000).format('MM/DD/YYYY')}</div>
                                                        : ''}
                                                </div>
                                            </div>
                                            {this.props.teacher ?
                                                <PersonAvatar
                                                    person={this.props.teacher}
                                                    avatarColor={'#d3adf7'}
                                                    avatarTextColor={'#000'}
                                                    size={'large'}
                                                    containerClassName="font-20"
                                                    avatarClassName="font-20 font-bold mr-2"
                                                    personClassName="font-black font-24 ellipsis"
                                                />
                                                : ''}
                                        </div>
                                        <div className='font-20 w-100'>
                                            <div className='font-20 mb-2 print-hide'>
                                                {this.state.selectedReport &&
                                                    this.state.selectedReport.hasOwnProperty('timeStamp') &&
                                                    this.state.selectedReport.timeStamp &&
                                                    this.state.selectedReport.timeStamp.hasOwnProperty('seconds') &&
                                                    this.state.selectedReport.timeStamp.seconds ?
                                                    <div>{moment.utc(this.state.selectedReport.timeStamp.seconds * 1000).format('MM/DD/YYYY')}</div>
                                                    : ''}
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Title:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.reportTitle ?
                                                        this.state.selectedReport.reportTitle : 'There is currently no title'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Description:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.reportDescription ?
                                                        this.state.selectedReport.reportDescription : 'There is currently no description'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Goal:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.reportReflection ?
                                                        this.state.selectedReport.reportReflection : 'There is currently no goal'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Pre-conference notes:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.adminIncentive ?
                                                        this.state.selectedReport.adminIncentive : 'There are currently no pre-conference notes'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Co-teaching notes:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.adminChangeEnvironment ?
                                                        this.state.selectedReport.adminChangeEnvironment : 'There are currently no co-teaching notes'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Post-conference notes:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.adminProgress ?
                                                        this.state.selectedReport.adminProgress : 'There are currently no post-conference notes'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Reflection:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.adminNextSteps ?
                                                        this.state.selectedReport.adminNextSteps : 'There is currently no reflection'}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='w-100 page-break-inside-avoid'>
                                                <div className='mb-2'>
                                                    <div className='font-bold print-mb-05'>Decisions:</div>
                                                    <div className='print-p2 print-border print-br-15'>{this.state.selectedReport.coachesSupport ?
                                                        this.state.selectedReport.coachesSupport : 'There are currently no decisions'}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> : ''}
                            <div className='pl-3' style={{
                                minWidth: '287px',
                                width: '287px', maxWidth: ' 287px'
                            }}>
                                <div className='font-20 font-bold'>Coaching Reports</div>
                                <div className='font-16 mb-1'>Reports from other coaches will appear here</div>
                                {this.state.coachingReports.map((coachingReport, index) => {
                                    return <div
                                        className={"cursor-pointer p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative font-20 font-black" +
                                            (this.state.selectedReport && this.state.selectedReport.id === coachingReport.id ? ' side-menu-item-active' : '')}
                                        onClick={() => {
                                            this.setState({
                                                selectedReport: coachingReport,
                                            })
                                        }}
                                    >
                                        <div>
                                            {coachingReport.hasOwnProperty('author') && coachingReport.author ?
                                                <PersonAvatar
                                                    person={coachingReport.author}
                                                    avatarColor={'#d3adf7'}
                                                    avatarTextColor={'#000'}
                                                    size={'large'}
                                                    containerClassName="font-20"
                                                    avatarClassName="font-20 font-bold mr-1"
                                                    personClassName="font-black font-20 ellipsis"
                                                    personStyle={{ maxWidth: '200px' }}
                                                />
                                                : ''}
                                        </div>
                                        <div className='font-16' style={{ marginLeft: '48px' }}>
                                            {coachingReport.hasOwnProperty('timeStamp') &&
                                                coachingReport.timeStamp &&
                                                coachingReport.timeStamp.hasOwnProperty('seconds') &&
                                                coachingReport.timeStamp.seconds ?
                                                <div>{moment.utc(coachingReport.timeStamp.seconds * 1000).format('MM/DD/YYYY')}</div>
                                                : ''}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        : ''}
                </div>
            </div >
        )
    }
}

export default CoachingNotes