import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { isSandboxNoGoalsLeft, isSandboxNoSub } from '.././Util'
import ColType from '.././Types'
import PersonAvatar from '.././customcomponents/PersonAvatar'
import Empty from '.././customcomponents/Empty'
import CustomFooter from '../login/CustomFooter'
import { Layout, Icon,  Row, Col, Button, 
  message, Tabs, Modal } from 'antd'
const { Content } = Layout
const Promise = require("bluebird")
const chunk = require('lodash.chunk')
const TabPane = Tabs.TabPane

const loadingMessage = (description) => {
  message.destroy()
  message.loading(description, 0)
}

const errorMessage = (description) => {
  message.destroy()
  message.error(description)
}

const successMessage = (description) => {
  message.destroy()
  message.success(description)
}

class TeacherClassStatus extends Component {
  state = {
   deletingStudents: {},
   goalLimitModalVisible: false,
   deleteStudentModalVisible: false,
   deleteStudent: null,
   deleteStudentModalCloseTimeout: null,
   selectedRows: [],
   measurementTableColumns: [{
    title: "Student",
    dataIndex: "studentName",
    key: "studentName",
   },
   {
    title: "Grade",
    dataIndex: "grade",
    key: "grade",
   },
   {
    title: "Support",
    dataIndex: "supportLevel",
    key: "supportLevel",
   },
   {
    title: "Goals on Track",
    dataIndex: "goalsOnTrack",
    key: "goalsOnTrack",
   },
   {
    title: "Progress Monitoring",
    dataIndex: "progressMonitoringDue",
    key: "progressMonitoringDue",
   }],
  }

  componentWillReceiveProps(nextProps) {
    console.log(this.props)
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'My Students - Dot It'
    window.scrollTo(0,0)
    console.log(this.props)
  }

  handleGoalLimitModalCancel = (e) => {
    this.setState({
      goalLimitModalVisible: false,
    })
  }

  archiveStudent = (e, studentId) => {
    e.preventDefault()
    e.stopPropagation()
    db.collection(ColType.student)
    .doc(studentId)
    .update({
      isArchived: true
    })
    .then(() => {
      successMessage("Student archived successfully.")
    })
    .catch((e) => {
      errorMessage("An error occurred, try again.")
    })
  }

  unArchiveStudent = (e, studentId) => {
    e.preventDefault()
    e.stopPropagation()
    db.collection(ColType.student)
    .doc(studentId)
    .update({
      isArchived: false
    })
    .then(() => {
      successMessage("Student unarchived successfully.")
    })
    .catch((e) => {
      errorMessage("An error occurred, try again.")
    })
  }

  deleteStudent = (studentId) => {
    //e.preventDefault()
    //e.stopPropagation()
    var deletingStudents = this.state.deletingStudents
    deletingStudents[studentId] = true
    this.setState({
      deletingStudents: deletingStudents
    })
    loadingMessage("Deleting student. Stay on this page, please wait...")

      var promises = []

      db.collection(ColType.iep)
          .where('teacherId', '==', this.props.teacherBaseProps.teacherId)
          .where('studentId', '==', studentId)
          .orderBy('timeStamp', 'desc')
          .get()
          .then((querySnapshot) => {
            var IEPGoals = []
            querySnapshot.forEach((doc) => {
              IEPGoals.push(doc)
            })

            // delete all iep drafts that this student has before mapping over
            // the iep goals (order doesn't matter its just simpler this way)
            promises.push(new Promise((resolve, reject) => {
              db.collection(ColType.iepDrafts)
                .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                .where('studentId', '==', studentId)
                .get()
                .then((querySnapshot) => {
                  var chunkPromises = []
                  var chunkedArray = chunk(querySnapshot.docs, 500)
                  chunkedArray.map((chunk, index) => {
                    return chunkPromises.push(new Promise((resolve, reject) => {
                      var batch = db.batch()
                      chunk.forEach(doc => {
                        if (!doc.exists) return
                        batch.delete(doc.ref)
                      })
      
                      return batch.commit().then(() => {
                        resolve()
                      }).catch((error) => {
                        reject(error)
                      })
                    })
                    )
                  })
      
                  Promise.all(chunkPromises)
                  .then(() => {
                    resolve()
                  })
                  .catch((error) => {
                    reject(error)
                  })
                })
              })
            )

            console.log("iep goals len,", IEPGoals.length)
            IEPGoals.map((iep, index) => {
              var IEPId = iep.id
              promises.push(new Promise.bind({IEPId: IEPId})
                .then(() => {
                  return new Promise((resolve, reject) => {
                    console.log('Calendar events - Promise IEPId', IEPId)
                    db.collection(ColType.calendarEvents)
                    .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                    .get()
                    .then((querySnapshot) => {
                      var chunkPromises = []
                      var chunkedArray = chunk(querySnapshot.docs, 500)
                      chunkedArray.map((chunk, index) => {
                        return chunkPromises.push(new Promise((resolve, reject) => {
                          chunk.forEach(doc => {
                            db.runTransaction((transaction) => {
                            // This code may get re-run multiple times if there are conflicts.
                            return transaction.get(doc.ref).then((doc) => {
                                  if (!doc.exists) {
                                    throw new Error("Document does not exist")
                                  }
                                  var event = doc.data()
                                  // Ignore teacher events when deleting student's calendar events
                                  // If not ignored teacher events will be deleted because they have
                                  // no iep goals in them
                                  if (event.servicedIn === 'Teacher Event') {
                                    // update the transaction without modification because no
                                    // update needs to happen but firestore requires a transactions
                                    // to write and read
                                    transaction.update(doc.ref, event)
                                  }
                                  else {
                                    var ieps = event.ieps
                                    ieps = ieps.filter(iepObj => iepObj.iepId !== IEPId)
        
                                    // if there are no more ieps in the event, delete the event
                                    if (ieps.length === 0) {
                                      transaction.delete(doc.ref)
                                    }
                                    // else update the event with all instances of IEPId removed
                                    else {
                                      var maxDuration = 0
                                      ieps.map((item, index) => {
                                          if (parseInt(item.iepDuration, 10) > maxDuration) {
                                          maxDuration = parseInt(item.iepDuration, 10)
                                        }
                                        return false
                                      })
                                      transaction.update(doc.ref, 
                                        { ieps: ieps, duration: maxDuration }
                                      )
                                    }
                                  }
                            })
                          }).then(() => {
                              console.log("Transaction successfully committed!")
                              resolve()
                          }).catch((error) => {
                              console.log("Transaction failed: ", error)
                              // I don't care about this error, it just means that the document that
                              // was attempted to be read does no exist. Should be fine. Any other
                              // errors should reject the promise though.
                              if (error.message !== "Document does not exist") reject(error)
                          })
                        })
                      })
                      )
                    })

                    // if all transactions went through, then resolve else reject
                    Promise.all(chunkPromises)
                      .then(() => {
                        resolve()
                      })
                      .catch((error) => {
                        reject(error)
                      })
                  })
                })
              }).then(() => {
                return new Promise((resolve, reject) => {
                  console.log('Promise IEPId', IEPId)
                  db.collection(ColType.measurements)
                    .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                    .where('iepId', '==', IEPId)
                    .get()
                    .then((querySnapshot) => {
                      var chunkPromises = []
                      var chunkedArray = chunk(querySnapshot.docs, 500)
                      chunkedArray.map((chunk, index) => {
                        return chunkPromises.push(new Promise((resolve, reject) => {
                          var batch = db.batch()
                          chunk.forEach(doc => {
                            if (!doc.exists) return
                            batch.delete(doc.ref)
                          })
          
                          return batch.commit().then(() => {
                            resolve()
                          }).catch((error) => {
                            reject(error)
                          })
                        })
                        )
                      })
          
                      Promise.all(chunkPromises)
                      .then(() => {
                        resolve()
                      })
                      .catch((error) => {
                        reject(error)
                      })
                    })
                })
              }).then(() => {
                return new Promise((resolve, reject) => {
                  console.log('Promise IEPId', IEPId)
                  db.collection(ColType.notes)
                    .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                    .where('iepId', '==', IEPId)
                    .get()
                    .then((querySnapshot) => {
                      var chunkPromises = []
                      var chunkedArray = chunk(querySnapshot.docs, 500)
                      chunkedArray.map((chunk, index) => {
                        return chunkPromises.push(new Promise((resolve, reject) => {
                          var batch = db.batch()
                          chunk.forEach(doc => {
                            if (!doc.exists) return
                            batch.delete(doc.ref)
                          })
          
                          return batch.commit().then(() => {
                            resolve()
                          }).catch((error) => {
                            reject(error)
                          })
                        })
                        )
                      })
          
                      Promise.all(chunkPromises)
                      .then(() => {
                        resolve()
                      })
                      .catch((error) => {
                        reject(error)
                      })
                    })
                })
              })
            )

            return false
          })

          Promise.all(promises)
          .then(() => {
            if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
              errorMessage("Student to delete could not be found. " +
              "Please contact technical support.")
              return
            }
            // delete iep goals now as all data tied to them have been successfully deleted
              var chunkPromises = []
              var chunkedArray = chunk(IEPGoals, 500)
              chunkedArray.map((chunk, index) => {
                  return chunkPromises.push(new Promise((resolve, reject) => {
                    var batch = db.batch()
  
                    chunk.forEach(doc => {
                      if (!doc.exists) return
                      batch.delete(doc.ref)
                    })
  
                    return batch.commit().then(() => {
                      resolve()
                    }).catch((error) => {
                      reject(error)
                    })
                  })
                )
              })
              
              return Promise.all(chunkPromises)
                .then(() => {
                // delete student as all iep goals tied to the student 
                // have been successfully deleted
                  console.log("All promises succeeded, in student delete")
  
                  // delete student
                  db.collection(ColType.student)
                  .doc(studentId)
                  .delete()
                  .then(() => {
                    var deletingStudents = this.state.deletingStudents
                    delete deletingStudents[studentId]
                    this.setState({
                      deleteStudent: null,
                      deleteStudentModalVisible: false,
                      deletingStudents: deletingStudents,
                      componentMounted: true,
                    })
                    
                    if (Object.keys(deletingStudents).length === 0) {
                      this.setState({
                        selectedRows: [],
                      })
                      successMessage("The student has been successfully deleted.")
                    }
                  })
                  .catch((error) => {
                    errorMessage('Failed to remove student.')
                  })
                })
                .catch((error) => {
                  console.log(error)
                  errorMessage("An error occurred when trying to delete a student.")
                })
            })
            .catch((error) => {
              console.log(error)
              errorMessage("Something went wrong adding the IEP goals, please try again.")
            })
        })
        .catch((error) => {
          console.log(error)
          errorMessage("Something went wrong adding the IEP goals, please try again.")
        })
  }

  checkGoalLimit = (e) => {
    if (isSandboxNoGoalsLeft(this.props.teacherBaseProps) && 
        isSandboxNoSub(this.props.teacherBaseProps)) {
      // stop link from continuing
      e.preventDefault()
      // show goal limit modal
      this.setState({
        goalLimitModalVisible: true,
      })
    }
  }

  render() {
    var unArchivedMeasurementTableData = []
    var archivedMeasurementTableData = []
    var supportTextDict = {}
    this.props.teacherBaseProps.students && 
    this.props.teacherBaseProps.students.map((student, idx) => {
      let supportText = "No plans have been added"
      let goalsOnTrack = "N/A"
      let progressMonitoringDue = "N/A"
      if (this.props.teacherBaseProps.studentsLevel && 
          this.props.teacherBaseProps.studentsLevel.hasOwnProperty(student.id)) {
        var level = this.props.teacherBaseProps.studentsLevel[student.id]
        if (level === "70 and below") {
          supportText = "High Support"
        } else if (level === '70-79') {
          supportText = "Medium Support"
        } else if (level === '80 and above') {
          supportText = "Low Support"
        } else if (level === "Tier 2") {
          supportText = "Tier 2"
        } else if (level === "Tier 3") {
          supportText = "Tier 3"
        } else {
          console.log("level not handled", level)
        }
      }
      if (this.props.teacherBaseProps.studentOnTrack &&
          this.props.teacherBaseProps.studentOnTrack.hasOwnProperty(student.id)) {
        goalsOnTrack = ((this.props.teacherBaseProps
            .studentOnTrack[student.id].onTrack / 
        this.props.teacherBaseProps
            .studentOnTrack[student.id].total) * 100)
            .toFixed(1)
      }
      if (this.props.teacherBaseProps.studentRequiresAction &&
          this.props.teacherBaseProps.studentRequiresAction.hasOwnProperty(student.id) &&
          this.props.teacherBaseProps.studentRequiresAction[student.id].newMeasurementNeeded) {
        progressMonitoringDue = "Needs measurement"
      } else {
        progressMonitoringDue = "Up to date"
      }
      /*else if (this.props.teacherBaseProps.studentRequiresAction &&
        this.props.teacherBaseProps.studentRequiresAction.hasOwnProperty(student.id) &&
        this.props.teacherBaseProps.studentRequiresAction[student.id].completionDateUpcoming) {
        progressMonitoringDue = "Annual review coming up"
      }*/
      supportTextDict[student.id] = {
        supportText: supportText,
        goalsOnTrack: goalsOnTrack,
        progressMonitoringDue: progressMonitoringDue,
      }
      return false
    })

    this.props.teacherBaseProps.students && 
    this.props.teacherBaseProps.students.map((student, idx) => {
      if (!student.hasOwnProperty("isArchived") || 
          (student.hasOwnProperty("isArchived") && !student.isArchived)) {
        let supportText = "None"
        let goalsOnTrack = "N/A"
        let progressMonitoringDue = "N/A"
        if (supportTextDict.hasOwnProperty(student.id)) {
          let supportObj = supportTextDict[student.id]
          supportText = supportObj.supportText
          goalsOnTrack = supportObj.goalsOnTrack
          progressMonitoringDue = supportObj.progressMonitoringDue
        }
        unArchivedMeasurementTableData.push({
          key: student.id,
          student: student,
          studentId: student.id,
          studentName: student.firstName + " " + student.lastName,
          grade: student.grade,
          supportLevel: supportText,
          goalsOnTrack: goalsOnTrack,
          progressMonitoringDue: progressMonitoringDue,
        })
      }
      return false
    })

    this.props.teacherBaseProps.students && 
    this.props.teacherBaseProps.students.map((student, idx) => {
      if (student.hasOwnProperty("isArchived") && student.isArchived) {
        let supportText = "None"
        let goalsOnTrack = "N/A"
        let progressMonitoringDue = "No"
        if (supportTextDict.hasOwnProperty(student.id)) {
          let supportObj = supportTextDict[student.id]
          supportText = supportObj.supportText
          goalsOnTrack = supportObj.goalsOnTrack
          progressMonitoringDue = supportObj.progressMonitoringDue
        }
        archivedMeasurementTableData.push({
          key: student.id,
          student: student,
          studentId: student.id,
          studentName: student.firstName + " " + student.lastName,
          grade: student.grade,
          supportLevel: supportText,
          goalsOnTrack: goalsOnTrack,
          progressMonitoringDue: progressMonitoringDue,
        })
      }
      return false
    })

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content">
              <div className="sub-menu-width m-lr-auto">
                {this.props.teacherBaseProps.teacher ?
                <div>
                  {/*
                   <div className="mb-3 border br-4">
                 
                  <div>
                <h1 className="flex flex-v-center mb-0 font-bold p-3 border-bottom">
                 <Icon type="plus" className="mr-2 font-30" />
                 <div>
                  <span>Add new student or plan</span>
                 </div>
               </h1>
               </div>
               <div className="p-3">
               <Row gutter={48} className="ml-0" type="flex" align="middle">
                  <Col span={8} className="pl-0">                    
                    <Link 
                      to={"/teacher/add-student/" + this.props.teacherBaseProps.teacher.id + "?r=1"}
                      disabled={this.props.teacherBaseProps.readOnly}
                      className={"w-100 br-4 pt-4 pb-4 up-hover" +
                       " shadow-hover ant-btn ant-btn-primary lg-btn relative"}
                    >
                      {this.props.teacherBaseProps && this.props.teacherBaseProps.students && 
                      this.props.teacherBaseProps.students.length === 0 ?
                       <div className={"absolute-tr background-yellow " + 
                      "p-1 pl-2 pr-2 br-4 font-fff font-bold font-20"}
                      style={{top: "-38px", right: "8px"}}>
                         <span>Add Your First Student</span>
                         <Icon type="arrow-down" className="ml-1" />

                      </div>
                      : ''}
                        <div className="w-100 flex flex-h-center font-bold-important">
                          <Icon type="user-add" className="mr-2 font-30"/>
                          <span>Add New Student</span>
                        </div>
                    </Link>
                  </Col>
                  <Col span={8} className="pl-0">
                    <Link 
                      to={"/teacher/add-goal-student/" + this.props.teacherBaseProps.teacher.id}
                      disabled={this.props.teacherBaseProps.readOnly}
                      onClick={this.checkGoalLimit}
                      className={"w-100 br-4 pt-4 pb-4 up-hover" +
                       " shadow-hover ant-btn ant-btn-primary lg-btn font-bold-important"}
                    >
                       {this.props.teacherBaseProps && this.props.teacherBaseProps.students && 
                      this.props.teacherBaseProps.students.length > 0 && 
                      this.props.teacherBaseProps.IEPGoals && 
                      this.props.teacherBaseProps.IEPGoals.length === 0 ?
                       <div className={"absolute-tr background-yellow " + 
                      "p-1 pl-2 pr-2 br-4 font-fff font-bold font-20"}
                      style={{top: "-38px", right: "8px"}}>
                         <span>Draft Your First IEP</span>
                         <Icon type="arrow-down" className="ml-1" />
                      </div>
                      : ''}
                        <div className="w-100 flex flex-h-center">
                          <Icon type="plus" className="mr-2 font-30"/>
                          <span>Draft IEP</span>
                        </div>
                    </Link>

                    <Modal
                      title="Goal Limit Reached"
                      visible={this.state.goalLimitModalVisible}
                      onOk={this.handleGoalLimitModalCancel}
                      onCancel={this.handleGoalLimitModalCancel}
                    >
                      <div>
                          <div className="font-20 font-bold-important mb-2">
                            You Have Reached the Goal Limit
                          </div>
                          <div className="font-18 mb-2">
                            To access more goals either:
                          </div>
                          <Row>
                            <Col span={24}>
                            <Link to={"/teacher/subscribe/" + 
                                    this.props.teacherBaseProps.teacher.id}
                          className={"br-4 pt-4 pb-4 font-20 font-bold-important flex-h-center" +
                          " shadow-hover flex-center ant-btn ant-btn-primary relative"}
                          >
                            Upgrade My Account
                          </Link>
                              </Col>
                          </Row>
                          <Divider className="font-20 font-bold mt-2 mb-2">Or</Divider>
                          <Row>
                            <Col span={24}>
                            <div className="font-18 mb-2">
                              Ask your district or school leaders to purchase Dot It.
                            </div>
                            <div className="font-18 mb-1">
                            Your administrator can contact us 
                            for purchase by either:
                          </div>
                          <div className="ml-2 font-18">
                            1) emailing <span className="text-primary" 
                            style={{marginLeft: "1px"}}>
                              support@dotit.app
                            </span>.
                          </div>
                          <div className="ml-2 font-18">
                            2) going to the <a 
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://dotit.app/contact/">
                              dot it contact us page.</a>
                          </div>
                              </Col>
                          </Row>
                      </div>
                    </Modal>

                   
                  </Col>

                  <Col span={8} className="pl-0">
                    <Link 
                      to={"/teacher/mtss-add-goal-student/" + 
                        this.props.teacherBaseProps.teacher.id}
                      disabled={this.props.teacherBaseProps.readOnly}
                      onClick={this.checkGoalLimit}
                      className={"w-100 br-4 pt-4 pb-4 up-hover" +
                       " shadow-hover flex-center ant-btn ant-btn-primary lg-btn font-bold-important"}
                    >
                       {this.props.teacherBaseProps && this.props.teacherBaseProps.students && 
                      this.props.teacherBaseProps.students.length > 0 && 
                      this.props.teacherBaseProps.IEPGoals && 
                      this.props.teacherBaseProps.IEPGoals.length === 0 ?
                       <div className={"absolute-tr background-yellow " + 
                      "p-1 pl-2 pr-2 br-4 font-fff font-bold font-20"}
                      style={{top: "-38px", right: "8px"}}>
                         <span>Draft Your First Tier Plan</span>
                         <Icon type="arrow-down" className="ml-1" />
                      </div>
                      : ''}
                        <div className="w-100 flex flex-h-center">
                          <Icon type="plus" className="mr-2 font-30"/>
                          <span>Draft Tier Plan</span>
                        </div>
                    </Link>

                    <Modal
                      title="Goal Limit Reached"
                      visible={this.state.goalLimitModalVisible}
                      onOk={this.handleGoalLimitModalCancel}
                      onCancel={this.handleGoalLimitModalCancel}
                    >
                      <div>
                          <div className="font-20 font-bold-important mb-2">
                            You Have Reached the Goal Limit
                          </div>
                          <div className="font-18 mb-2">
                            To access more goals either:
                          </div>
                          <Row>
                            <Col span={24}>
                            <Link to={"/teacher/subscribe/" + 
                                    this.props.teacherBaseProps.teacher.id}
                          className={"br-4 pt-4 pb-4 font-20 font-bold-important flex-h-center" +
                          " shadow-hover flex-center ant-btn ant-btn-primary relative"}
                          >
                            Upgrade My Account
                          </Link>
                              </Col>
                          </Row>
                          <Divider className="font-20 font-bold mt-2 mb-2">Or</Divider>
                          <Row>
                            <Col span={24}>
                            <div className="font-18 mb-2">
                              Ask your district or school leaders to purchase Dot It.
                            </div>
                            <div className="font-18 mb-1">
                            Your administrator can contact us 
                            for purchase by either:
                          </div>
                          <div className="ml-2 font-18">
                            1) emailing <span className="text-primary" 
                            style={{marginLeft: "1px"}}>
                              support@dotit.app
                            </span>.
                          </div>
                          <div className="ml-2 font-18">
                            2) going to the <a 
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://dotit.app/contact/">
                              dot it contact us page.</a>
                          </div>
                              </Col>
                          </Row>
                      </div>
                    </Modal>
                  </Col>
                  </Row>
                  </div>

                  
               </div>*/}
                <Modal
                      title={null}
                      visible={this.state.deleteStudentModalVisible}
                      footer={null}
                      onCancel={(e) => {
                        this.setState({
                          deleteStudentModalVisible: false,
                          deleteStudentModalCloseTimeout: setTimeout(100, () => {
                            this.setState({
                              deleteStudent: null,
                            })
                          })
                        })
                      }}
                    >
                      {this.state.deleteStudent ?
                      <PersonAvatar
                        person={this.state.deleteStudent}
                        size={'large'}
                        containerClassName="font-20 mt-4"
                        avatarClassName="font-20 font-bold background-cyan"
                        personClassName="font-bold"
                      /> : ''}
                      <div className="font-20 mb-2 mt-2 font-bold">Are you sure you want to permanently delete this student?</div>
                      <div className="flex flex-v-center">
                        <Button className="ant-btn lg-btn ml-auto mr-2" 
                        disabled={this.state.deleteStudent && 
                                  this.state.deletingStudents.hasOwnProperty(this.state.deleteStudent.id)}
                        onClick={(e) => {
                              this.setState({
                                deleteStudentModalVisible: false,
                                deleteStudentModalCloseTimeout: setTimeout(100, () => {
                                  this.setState({
                                    deleteStudent: null,
                                  })
                                })
                              })
                          }}
                        >
                          No
                        </Button>
                        <Button className="ant-btn ant-btn-primary lg-btn" 
                         disabled={this.state.deleteStudent && 
                          this.state.deletingStudents.hasOwnProperty(this.state.deleteStudent.id)}
                          onClick={(e) => {
                            if (this.state.deleteStudent) {
                              this.deleteStudent(this.state.deleteStudent.id)
                            }
                          }}
                        >
                          {this.state.deleteStudent && 
                          this.state.deletingStudents.hasOwnProperty(this.state.deleteStudent.id) ?
                        "Deleting..." : "Yes"}
                        </Button>
                      </div>
                    </Modal>
               <div className="br-4 background-fff">
               <h1 className="flex flex-v-center mb-0 font-bold p-3 border-bottom">
                 <Icon type="user" className="mr-2 font-30" />
                 <div>
                  <span>My students</span>
                 </div>
                 <Link 
                      to={"/teacher/add-student/" + this.props.teacherBaseProps.teacher.id + "?r=1"}
                      disabled={this.props.teacherBaseProps.readOnly}
                      className={"br-4 pt-4 pb-4 up-hover ml-auto" +
                       " shadow-hover ant-btn ant-btn-primary lg-btn relative"}
                    >
                      {this.props.teacherBaseProps && this.props.teacherBaseProps.students && 
                      this.props.teacherBaseProps.students.length === 0 ?
                       <div className={"absolute-tr background-yellow " + 
                      "p-1 pl-2 pr-2 br-4 font-fff font-bold font-20"}
                      style={{top: "-38px", right: "8px"}}>
                         <span>Add Your First Student</span>
                         <Icon type="arrow-down" className="ml-1" />

                      </div>
                      : ''}
                        <div className="w-100 flex flex-h-center font-bold-important">
                          <Icon type="user-add" className="mr-2 font-30"/>
                          <span>Add New Student</span>
                        </div>
                    </Link>
               </h1>
               <div className="p-3">
               <Tabs size="large" className="relative" animated={false} onChange={() => {
                 this.setState({
                   selectedRows: [],
                 })
               }}>
                 <TabPane tab={"Current"}  key={"current"} 
                          disabled={Object.keys(this.state.deletingStudents).length > 0}>
                <Row className="p-1 mt-3" type="flex" align="middle" gutter={16}>
                  <Col span={8} className="font-20 font-bold">
                    <div className="flex flex-h-center">Student</div>
                  </Col>
                  <Col span={3} className="font-20 font-bold">
                  <div className="flex flex-h-center">Goals</div>
                  </Col>
                  <Col span={6} className="font-20 font-bold">
                  <div className="flex flex-h-center">Plans on track</div>
                  </Col>
                  <Col span={7} className="font-20 font-bold">
                  <div className="flex flex-h-center">Progress monitoring</div>
                  </Col>
                </Row>
                {unArchivedMeasurementTableData.length === 0 ? 
                   <Empty 
                   containerClassName="mt-4 pt-2 text-center"
                   width={55}
                   height={55}
                   /> : ''}
                {unArchivedMeasurementTableData.map((d, i) => {
                    var wmi = "WMI"
                    var psi = "PSI"
                    var fri = "FRI"
                    var vci = "VCI"
                   var impactText = ""
                   var numGoals = this.props.teacherBaseProps.nonArchivedGoals.filter(e => e.studentId === d.student.id).length
                   if (this.props.teacherBaseProps.studentsHighestTier &&
                    this.props.teacherBaseProps.studentsHighestTier.hasOwnProperty(d.student.id)
                    ) {
                      var highestTier = this.props
                        .teacherBaseProps.studentsHighestTier[d.student.id]
                      if (highestTier === wmi) {
                        //textClassName = " wmi-text"
                        impactText = "Working Memory"
                      }
                      if (highestTier === psi) {
                        //textClassName = " psi-text"
                        impactText = "Processing Speed"
                      }
                      if (highestTier === fri) {
                        //textClassName = " fri-text"
                        impactText = "Fluid Reasoning"
                      }
                      if (highestTier === vci) { 
                        //textClassName = " vci-text"
                        impactText = "Verbal Comprehension"
                      }
                   }
                  let pmBackground = "background-dark-grey"
                  if (d.progressMonitoringDue === "Up to date") {
                    pmBackground = "background-green"
                  } else if (d.progressMonitoringDue === "Needs measurement") {
                    pmBackground = "background-red"
                  }
                  /*let supportBackground = "background-dark-grey"
                  if (d.supportLevel === "Low Support") {
                    supportBackground = "background-light-cyan"
                  }
                  else if (d.supportLevel === "Medium Support") {
                    supportBackground = "background-orange"
                  }
                  else if (d.supportLevel === "High Support") {
                    supportBackground = "background-red"
                  }
                  else if (d.supportLevel === "Tier 2") {
                    supportBackground = "background-orange"
                  }
                  else if (d.supportLevel === "Tier 3") {
                    supportBackground = "background-red"
                  }*/
                  return <div className="p-1 row-grey-cyan-hover cursor-pointer br-4 parent-hover relative" key={d.key}
                    onClick={() => {
                      if (this.props.teacherBaseProps.teacher) {
                      this.props.history.push(
                        {
                          pathname: '/teacher/student/' +
                            this.props.teacherBaseProps.teacher.id + '?student=' +
                            d.student.id + "&pl=true",
                        }
                      )
                    }}}>
                 
                  <Row type="flex" align="middle" gutter={16}>
                  <Col span={8} className="h-100">
                    <div className="background-grey-cyan p-2 br-4">
     
                     <PersonAvatar
                        person={d.student}
                        size={'large'}
                        containerClassName="font-20"
                        avatarClassName="font-20 font-bold background-cyan"
                        personClassName="font-bold"
                     />
                     <div className="font-16" style={{marginLeft: "46px"}}>
                       {impactText}
                     </div>
                    </div>
                  </Col>
                  <Col span={3} className="h-100">
                    <div className={"background-grey-cyan p-2 br-4 font-20 flex flex-v-center font-bold"}
                         style={{height: "72px"}}>
                      <div className="text-center w-100">
                        {numGoals}
                      </div>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="background-grey-cyan p-2 br-4 flex flex-v-center flex-h-center" style={{height: "72px"}}>
                      {d.goalsOnTrack === "N/A" ? 
                      <div className="font-20 font-bold">N/A</div> :
                      <div className="w-100 relative background-grey br-4 overflow-hidden" style={{height: "20px"}}>
                          <div className="absolute-tl background-cyan h-100"
                                style={{width: d.goalsOnTrack + "%"}}>
                          </div>
                      </div>
                      }
                    </div>
                  </Col>
                  <Col span={7} className="h-100">
                    <div className={"background-grey-cyan br-4 flex flex-v-center flex-h-center w-100 " + pmBackground} style={{height: "72px"}}>
                        <div className="font-20 font-fff font-bold">{d.progressMonitoringDue}</div>
                    </div>
                  </Col>
                  </Row>
                  <div className="show-on-parent-hover absolute-tr flex flex-v-center background-grey-cyan h-100 p-2" style={{top: "0px", right: "-8px"}}>
                    <Button className="ant-btn lg-btn mr-1" title="Archive student"
                    onClick={(e) => this.archiveStudent(e, d.student.id)}>
                    <Icon type="book" className="font-30 font-bold"/>
                    </Button>
                    <Button className="ant-btn lg-btn mr-2" title="Delete student"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (this.state.deleteStudentModalCloseTimeout) {
                          clearTimeout(this.state.deleteStudentModalCloseTimeout)
                          this.setState({
                            deleteStudentModalCloseTimeout: null,
                          })
                        }
                        this.setState({
                          deleteStudent: d.student,
                          deleteStudentModalVisible: true,
                        })}}>
                      <Icon type="delete" className="font-30 font-bold" />
                    </Button>
                  </div>
                  </div>
                })}
                </TabPane>
                <TabPane tab="Archived" key="archived" 
                 disabled={Object.keys(this.state.deletingStudents).length > 0}>
                <Row className="p-1 mt-3" type="flex" align="middle" gutter={16}>
                  <Col span={8} className="font-20 font-bold">
                    <div className="flex flex-h-center">Student</div>
                  </Col>
                  <Col span={3} className="font-20 font-bold">
                  <div className="flex flex-h-center">Goals</div>
                  </Col>
                  <Col span={6} className="font-20 font-bold">
                  <div className="flex flex-h-center">Plans on track</div>
                  </Col>
                  <Col span={7} className="font-20 font-bold">
                  <div className="flex flex-h-center">Progress monitoring</div>
                  </Col>
                </Row>
                {archivedMeasurementTableData.length === 0 ? 
                   <Empty 
                   containerClassName="mt-4 pt-2 text-center"
                   width={55}
                   height={55}
                   /> : ''}
                {archivedMeasurementTableData.map((d, i) => {
                    var wmi = "WMI"
                    var psi = "PSI"
                    var fri = "FRI"
                    var vci = "VCI"
                    var impactText = ""
                    var numGoals = this.props.teacherBaseProps.nonArchivedGoals.filter(e => e.studentId === d.student.id).length
                   if (this.props.teacherBaseProps.studentsHighestTier &&
                    this.props.teacherBaseProps.studentsHighestTier.hasOwnProperty(d.student.id)
                    ) {
                      var highestTier = this.props
                        .teacherBaseProps.studentsHighestTier[d.student.id]
                      if (highestTier === wmi) {
                        //textClassName = " wmi-text"
                        impactText = "Working Memory"
                      }
                      if (highestTier === psi) {
                        //textClassName = " psi-text"
                        impactText = "Processing Speed"
                      }
                      if (highestTier === fri) {
                        //textClassName = " fri-text"
                        impactText = "Fluid Reasoning"
                      }
                      if (highestTier === vci) { 
                        //textClassName = " vci-text"
                        impactText = "Verbal Comprehension"
                      }
                   }
                  let pmBackground = "background-dark-grey"
                  if (d.progressMonitoringDue === "Up to date") {
                    pmBackground = "background-green"
                  } else if (d.progressMonitoringDue === "Needs measurement") {
                    pmBackground = "background-red"
                  }
                  /*let supportBackground = "background-dark-grey"
                  if (d.supportLevel === "Low Support") {
                    supportBackground = "background-light-cyan"
                  }
                  else if (d.supportLevel === "Medium Support") {
                    supportBackground = "background-orange"
                  }
                  else if (d.supportLevel === "High Support") {
                    supportBackground = "background-red"
                  }
                  else if (d.supportLevel === "Tier 2") {
                    supportBackground = "background-orange"
                  }
                  else if (d.supportLevel === "Tier 3") {
                    supportBackground = "background-red"
                  }*/
                  return <div className="p-1 row-grey-cyan-hover cursor-pointer br-4 parent-hover relative" key={d.key}
                    onClick={() => {
                      if (this.props.teacherBaseProps.teacher) {
                      this.props.history.push(
                        {
                          pathname: '/teacher/student/' +
                            this.props.teacherBaseProps.teacher.id + '?student=' +
                            d.student.id + "&pl=true",
                        }
                      )
                    }}}>
                 
                  <Row type="flex" align="middle" gutter={16}>
                  <Col span={8} className="h-100">
                    <div className="background-grey-cyan p-2 br-4">
     
                     <PersonAvatar
                        person={d.student}
                        size={'large'}
                        containerClassName="font-20"
                        avatarClassName="font-20 font-bold background-cyan"
                        personClassName="font-bold"
                     />
                     <div className="font-16" style={{marginLeft: "46px"}}>
                       {impactText}
                     </div>
                    </div>
                  </Col>
                  <Col span={3} className="h-100">
                    <div className={"background-grey-cyan p-2 br-4 font-20 flex flex-v-center font-bold"}
                         style={{height: "72px"}}>
                      <div className="text-center w-100">
                        {numGoals}
                      </div>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="background-grey-cyan p-2 br-4 flex flex-v-center flex-h-center" style={{height: "72px"}}>
                      {d.goalsOnTrack === "N/A" ? 
                      <div className="font-20 font-bold">N/A</div> :
                      <div className="w-100 relative background-grey br-4 overflow-hidden" style={{height: "20px"}}>
                          <div className="absolute-tl background-cyan h-100"
                                style={{width: d.goalsOnTrack + "%"}}>
                          </div>
                      </div>
                      }
                    </div>
                  </Col>
                  <Col span={7} className="h-100">
                    <div className={"background-grey-cyan br-4 flex flex-v-center flex-h-center w-100 " + pmBackground} style={{height: "72px"}}>
                        <div className="font-20 font-fff font-bold">{d.progressMonitoringDue}</div>
                    </div>
                  </Col>
                  </Row>
                  <div className="show-on-parent-hover absolute-tr flex flex-v-center background-grey-cyan h-100 p-2" style={{top: "0px", right: "-8px"}}>
                    <Button className="ant-btn lg-btn mr-1" title="Archive student"
                    onClick={(e) => this.unArchiveStudent(e, d.student.id)}>
                    <Icon type="book" className="font-30 font-bold"/>
                    </Button>
                    <Button className="ant-btn lg-btn mr-2" title="Delete student"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (this.state.deleteStudentModalCloseTimeout) {
                          clearTimeout(this.state.deleteStudentModalCloseTimeout)
                          this.setState({
                            deleteStudentModalCloseTimeout: null,
                          })
                        }
                        this.setState({
                          deleteStudent: d.student,
                          deleteStudentModalVisible: true,
                        })}}>
                      <Icon type="delete" className="font-30 font-bold" />
                    </Button>
                  </div>
                  </div>
                })}
                </TabPane>
                </Tabs>
                </div>
                </div>
                </div>
                : ''}
              </div>
            </Content>
            </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default TeacherClassStatus