import React from 'react'

function PassageAssessment({ passage, questions }) {
    const questionItems = questions.map((question, index) => {
        const answers = question.answers.map((answer, i) =>
            <li key={i}>
                <input type="checkbox" id={`answer_${index}_${i}`} name={`answer_${index}_${i}`} value={answer} />
                <label htmlFor={`answer_${index}_${i}`}>{answer}</label>
            </li>
        )

        return (
            <li key={index}>
                <p>{question.text}</p>
                <ul>{answers}</ul>
            </li>
        )
    })

    return (
        <div style={{ border: '1px solid black', margin: '10px', padding: '20px' }}>
            <p>{passage}</p>
            <ol>{questionItems}</ol>
        </div>
    )
}

export default PassageAssessment
