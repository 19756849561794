import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import { getQueryStringParam, flattenDoc, linkAfterLogin } from '../Util'
import ColType from '../Types'
import { Spin } from 'antd'
import CustomFooter from './CustomFooter'

/*
const successMessage = (description) => {
    message.success(description)
}

const errorMessage = (description) => {
    message.error(description)
}
*/

class DemoSignInClever extends Component {
    state = {
        token: '',
        user: null,
        signingInAs: '',
        tokenExpiredError: false,
        tokenRetrieved: false,
    }

    componentDidMount() {
        document.title = "Demo Sign in - Dot It"
        const token = getQueryStringParam('dt')

        let stateObj = { token: token, tokenRetrieved: true }
        if (!token) {
            stateObj.tokenExpiredError = true
        }

        this.setState(stateObj, () => {
            this.cleverSignInWithToken()
        })
    }

    cleverSignInWithToken = () => {
        if (!this.state.token) {
            return
        }

        let headers = new Headers()
        headers.append('Authorization', 'Bearer ' + this.state.token)
        headers.append('Content-Type', 'application/json')

        fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/demo-sign-in-from-token", {
            method: "POST",
            body: JSON.stringify({}),
            headers: headers,
        })
            .then(res => res.json())
            .then(data => {
                if (data.hasOwnProperty('message')) {
                    this.setState({
                        signingInAs: '',
                        tokenExpiredError: true,
                    })
                    return
                }

                firebase.auth().signInWithCustomToken(data.token)
                    .then((userObj) => {
                        const user = userObj.user
                        //console.log('user sign in', user)
                        db.collection(ColType.users)
                            .doc(user.uid)
                            .get()
                            .then((doc) => {
                                var userInfo = flattenDoc(doc)
                                //console.log('userInfo', userInfo)
                                db.collection(userInfo.accessLevel)
                                    .doc(userInfo.refId)
                                    .get()
                                    .then((doc) => {
                                        var userJobInfo = flattenDoc(doc)
                                        linkAfterLogin(userInfo, userJobInfo, '')
                                    })
                            }).catch((error) => {
                                console.log('get user info error', error)
                                this.setState({
                                    signingInAs: '',
                                })
                            })
                    })
                    .catch((error) => {
                        console.log('sign in error', error)
                        this.setState({
                            signingInAs: '',
                        })
                    })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({
                    signingInAs: '',
                })
            })
    }

    render() {
        return (
            <div className="pt-4 pb-70">

                <div className='relative'>
                    <div className="text-center mb-3">
                        <img src='/dotitlogo.png' alt='logo' height='64' />
                    </div>
                    <div className="p-4 w-500 m-lr-auto br-20 ant-shadow background-fff">
                        <div className="block w-100 m-lr-auto mb-1 text-center">
                            <img src="/dot-it-sign-in.png" alt="sign-in" height="300" />
                        </div>
                        {!this.state.tokenRetrieved ||
                            (this.state.tokenRetrieved && !this.state.tokenExpiredError && this.state.token) ?
                            <div className='text-center'>
                                <div className="mb-3 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
                                <Spin size="large" className="ant-spin-vlg" />
                            </div>
                            : ''}
                        {this.state.tokenRetrieved && this.state.tokenExpiredError ?
                            <div className='text-center'>
                                <div className="mb-1 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
                                <div className='pl-1 pr-1'>
                                    <div className='text-left font-20 p-3 br-4 bg-light-red mb-2'>
                                        Unfortunately, this demo sign-in link has expired. If you would like to receive a new demo sign-in link, please don't hesitate to email us at support@dotit.app. We're here to help!
                                    </div>
                                </div>
                            </div> : ''}
                    </div>
                    <CustomFooter />
                </div>
            </div>
        )
    }
}

export default DemoSignInClever