import React, { Component } from 'react'
import '../styles/GlobalStyle.css'

class SupportPieChart extends Component {
  state = {

  }

  // Do fetch here
  componentDidMount() {

  }

  componentWillReceiveProps(props, newProps) {

  }

  render() {
    var lowSupport = parseFloat(this.props.lowSupport)
    var mediumSupport = parseFloat(this.props.mediumSupport)
    var highSupport = parseFloat(this.props.highSupport)
    return (
      <div>

      </div>
    )
  }
}

export default SupportPieChart