import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
import ColType from '../.././Types'
import { flattenDoc, getIDFromURL, getQueryStringParam, getStrengths, capitalizeFirstChar, getStudentMayHaveTroubleWith } from '../.././Util'
import { Layout, Button, Select, Form, Input } from 'antd'
import DraftStepContainer from '../addiep/DraftStepContainer'
const { Content } = Layout
const Option = Select.Option
const FormItem = Form.Item
const { TextArea } = Input

class TeacherAddIEPGoalStudent extends Component {
  state = {
    teacher: null,
    selectedStudent: null,
    selectedSubjects: [],
    students: [],
    studentDict: {},
    iepDrafts: [],
    tabKey: '1',
    iepDraft: null,
    iepDraftMounted: false,
    selectedTier: null,
    studentStrengths: getStrengths(),
    mayHaveTroubleWith: getStudentMayHaveTroubleWith(),
    displayDifficultyAndInterest: false,
    dontDisplayReadingFoundations: false,
  }

  componentDidMount() {
    document.title = 'Draft Tier Plan - Dot It'
    var teacherId = getIDFromURL(window.location)
    var draftId = getQueryStringParam('draft')
    var studentId = getQueryStringParam('student')
    var iepDraft = this.props.history.location.state
    console.log(teacherId, iepDraft)

    console.log("pep draft", ColType.pepDrafts)

    window.scrollTo(0, 0)

    this.setState({
      teacherId: teacherId,
      draftId: draftId,
      tabKey: '1',
    })

    // reload of page will not set the student initially because teacherBaseProps doens't have the studentDict yet.
    // I think this is fine because its rare and because I want to guarantee the student exists.
    if (!draftId && studentId && this.props.teacherBaseProps.studentDict && this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
      this.props.form.setFieldsValue({
        studentId: [studentId],
      })

      this.setState({
        selectedStudent: this.props.teacherBaseProps.studentDict[studentId],
      })

      console.log('here', studentId)
      this.studentChange([studentId], null)
    }

    if (draftId) {
      db.collection(ColType.pepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          let iepDraft = flattenDoc(doc)
          let displayDifficultyAndInterest = false
          if (iepDraft.hasOwnProperty('mayHaveTroubleWith') &&
            iepDraft.hasOwnProperty('studentInterest')) {
            displayDifficultyAndInterest = true
          }
          this.setState({
            iepDraft: iepDraft,
            iepDraftMounted: true,
            displayDifficultyAndInterest: displayDifficultyAndInterest,
          }, () => {
            if (iepDraft &&
              !(Object.keys(iepDraft).length === 0 &&
                iepDraft.constructor === Object)) {
              this.setState({
                selectedSubjects: iepDraft.subject,
                selectedTier: iepDraft.tier,
              }, () => {
                var formObj = {
                  studentId: iepDraft.studentId,
                  subject: iepDraft.subject,
                  tier: iepDraft.tier,
                  studentStrengths: iepDraft.studentStrengths,
                  mayHaveTroubleWith: iepDraft.mayHaveTroubleWith
                }
                if (iepDraft.hasOwnProperty("studentInterest") &&
                  iepDraft.studentInterest) {
                  formObj.studentInterest = iepDraft.studentInterest
                }

                Object.keys(iepDraft).map((key, idx) => {
                  if (key.indexOf("strength-") !== -1) {
                    formObj[key] = iepDraft[key]
                  }
                  return false
                })
                this.props.form.setFieldsValue(formObj)
              })
            }
          })
        })
    } else {
      this.setState({
        iepDraftMounted: true,
      })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)

        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        students.sort((a, b) => {
          if (a.grade === 'K' && b.grade === 'K') {
            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
              return 1
            } else {
              return -1
            }
          }
          if (a.grade === 'K' && b.grade !== 'K') return -1
          if (a.grade !== 'K' && b.grade === 'K') return 1
          if (a.grade === b.grade) {
            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
              return 1
            } else {
              return -1
            }
          }
          var aGrade = parseInt(a.grade, 10)
          var bGrade = parseInt(b.grade, 10)
          if (aGrade > bGrade) return 1
          if (bGrade > aGrade) return -1
          return 0
        })


        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  onMayHaveTroubleWithChange = (value) => {

  }

  // add a new event to the teacher's events
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // only used for clicking back on this step to set the form value
        console.log('Received values of form: ', values)
        // set values.studentId if its tier 3 and there is only
        // a single value, else don't set it
        if (values.hasOwnProperty('studentIdSingleValue')) {
          // a list to conform to tier2
          values.studentId = [values.studentIdSingleValue]
          delete values.studentIdSingleValue
        }
        if (!values.hasOwnProperty('mayHaveTroubleWith')) {
          values.mayHaveTroubleWith = 'problemSolving'
        }
        values.teacherId = this.state.teacher.id
        values.schoolId = this.state.teacher.schoolId
        values.districtId = this.state.teacher.districtId
        values.timeStamp = firebase.firestore.FieldValue.serverTimestamp()
        if (!this.state.mayHaveTroubleWith.hasOwnProperty(values.mayHaveTroubleWith)) {
          // default to problem solving
          values.mayHaveTroubleWithDescription = this.state.mayHaveTroubleWith['problemSolving']
        } else {
          values.mayHaveTroubleWithDescription = this.state.mayHaveTroubleWith[values.mayHaveTroubleWith]
        }

        if (!values.hasOwnProperty('studentInterest')) {
          values.studentInterest = ''
        }

        values.step = { stepNum: 1, path: 'information' }

        if (!values.studentInterest) {
          delete values.studentInterest
        }

        console.log('values', values)

        if (values.studentId.length === 0) {
          console.error("student list is empty")
          return
        }

        if (this.state.draftId) {
          db.collection(ColType.pepDrafts)
            .doc(this.state.draftId)
            .set(values)
            .then(() => {
              console.log("Document set with ID: ", this.state.draftId)
              this.props.history.push(
                {
                  pathname: '/teacher/mtss-add-goal-information/' +
                    this.state.teacherId + '?student=' +
                    values.studentId[0] +
                    '&draft=' + this.state.draftId,
                  state: values, // pass state to confirm page
                }
              )
            })
            .catch((error) => {
              console.log(error)
            })
        }
        else {
          db.collection(ColType.pepDrafts)
            .add(values)
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id)
              this.props.history.push(
                {
                  pathname: '/teacher/mtss-add-goal-information/' +
                    this.state.teacherId + '?student=' +
                    values.studentId[0] +
                    '&draft=' + docRef.id,
                  state: values, // pass state to confirm page
                }
              )
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  studentChange = (studentIds, option) => {
    let dontDisplayReadingFoundations = false
    studentIds.map((studentId, index) => {
      // value has already been set, no need to continue iterating
      if (dontDisplayReadingFoundations) {
        return false
      }
      if (!this.props.teacherBaseProps.studentDict) {
        return false
      }
      if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
        return false
      }
      if (!this.props.teacherBaseProps.studentDict[studentId]) {
        return false
      }
      let grade = this.props.teacherBaseProps.studentDict[studentId].grade
      if (grade === 'K' || grade.toLowerCase() === 'kindergarten') {
        return false
      }
      grade = parseInt(grade, 10)
      if (!dontDisplayReadingFoundations && grade > 5) {
        dontDisplayReadingFoundations = true
      }
      return false
    })
    // remove "Reading Foundations" from this.state.selectedSubjects
    // if all students are below grade 5
    if (dontDisplayReadingFoundations) {
      var selectedSubjects = this.state.selectedSubjects
      var index = selectedSubjects.indexOf("Reading Foundations")
      if (index !== -1) {
        selectedSubjects.splice(index, 1)
      }
      this.setState({
        selectedSubjects: selectedSubjects,
      })
    }
    let student = null
    if (studentIds.length > 0) {
      let selectedStudentId = studentIds[0]
      if (this.props.teacherBaseProps.studentDict.hasOwnProperty(selectedStudentId) &&
        this.props.teacherBaseProps.studentDict[selectedStudentId]) {
        student = this.props.teacherBaseProps.studentDict[selectedStudentId]
      }
    }

    let displayDifficultyAndInterest = false
    // if any students were created in the previous version,
    // make sure that fields are displayed
    studentIds.map((studentId, index) => {
      // already set no need to continue iterating
      if (displayDifficultyAndInterest) {
        return false
      }
      if (!this.props.teacherBaseProps.studentDict) {
        return false
      }
      if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
        return false
      }
      if (!this.props.teacherBaseProps.studentDict[studentId]) {
        return false
      }
      let student = this.props.teacherBaseProps.studentDict[studentId]
      if (!(student.hasOwnProperty('mayHaveTroubleWith') &&
        student.mayHaveTroubleWith &&
        student.hasOwnProperty('mayHaveTroubleWithDescription') &&
        student.mayHaveTroubleWithDescription &&
        student.hasOwnProperty('studentInterest') &&
        student.studentInterest)) {
        displayDifficultyAndInterest = true
      }

      return false
    })

    console.log(displayDifficultyAndInterest, studentIds, this.state)

    this.setState({
      selectedStudent: student,
      dontDisplayReadingFoundations: dontDisplayReadingFoundations,
      displayDifficultyAndInterest: displayDifficultyAndInterest,
    })
  }

  tierChange = (value) => {
    this.setState({
      selectedTier: value
    })
  }

  getMayHaveTroubleWithDescription = (value) => {
    if (value === 'information') {
      return "Recalling facts and remembering information."
    }
    else if (value === 'vocabulary') {
      return "Lack of background knowledge and " +
        "weak vocabulary."
    }
    else if (value === 'problemSolving') {
      return "Independent problem solving."
    }
    else if (value === 'efficiency') {
      return "Working quickly and efficiently to " +
        "complete assignments."
    }
    else if (value === 'ell') {
      return "Difficulty comprehending lessons due to " +
        "language acquisition."
    }
    else {
      return "Not specified."
    }
  }

  subjectChange = (values) => {
    this.setState({
      selectedSubjects: values,
    })
  }


  render() {
    const { getFieldDecorator } = this.props.form

    const formItemBlockLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    let strengthKey = "K2"
    if (this.state.selectedStudent) {
      if (this.state.selectedStudent.grade >= 3 && this.state.selectedStudent.grade <= 5) {
        strengthKey = "35"
      }
      else if (this.state.selectedStudent.grade >= 6 && this.state.selectedStudent.grade <= 8) {
        strengthKey = "68"
      }
      else if (this.state.selectedStudent.grade >= 9 && this.state.selectedStudent.grade <= 12) {
        strengthKey = "912"
      }
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                {/* 
                <div className="text-center">
                  <img src="/draft-plan/3.png" alt="steps" />
                </div>
                */}
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={true}
                  isMTSS={true}
                >
                  <div>

                    <div className="w-800 m-lr-auto background-fff br-15">
                      <div className='p-4 bkg-purple br-15'>
                        <div className='flex flex-v-center w-100'>
                          <div>
                            <h1 className="font-bold mb-0 font-40 font-fff">Draft a Tier Plan</h1>
                            <h2 className="mb-3 font-24 font-fff">Student Information</h2>
                          </div>
                          <img src="/draft-icons/1.png" alt='draft-iep' width={250} className='ml-auto' />
                        </div>
                      </div>

                      <div className='p-4'>
                        <Form onSubmit={this.handleSubmit}
                          className="login-form text-align-left">
                          <div>
                            <FormItem {...formItemBlockLayout} label={<div>
                              <div className='font-20 font-bold'>
                                Tier
                              </div>
                              <div className='font-16 font-normal'>
                                Select a tier for this plan.
                              </div>
                            </div>}
                              className="block-label">
                              {getFieldDecorator('tier', {
                                rules: [{ required: true, message: 'Select tier' }],
                              })(
                                <Select
                                  size={'large'}
                                  placeholder="Select tier"
                                  onChange={this.tierChange}>
                                  {/** <Option value={'Tier 1'}>
                                      Tier 1
                                </Option>*/}
                                  <Option value={'Tier 2'}>
                                    Tier 2
                                  </Option>
                                  <Option value={'Tier 3'}>
                                    Tier 3
                                  </Option>
                                </Select>
                              )}
                            </FormItem>

                            {this.state.selectedTier && this.state.selectedTier === 'Tier 2' ?
                              <FormItem {...formItemBlockLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Students
                                  </div>
                                  <div className='font-16 font-normal'>
                                    To draft a tier plan, select students below.
                                  </div>
                                </div>} className="block-label">
                                {getFieldDecorator('studentId', {
                                  rules: [{ required: true, message: 'Select students.' }],
                                })(
                                  <Select
                                    mode='multiple'
                                    size='large'
                                    showSearch={true}
                                    className="ant-select-very-large-multiple"
                                    placeholder="Select student"
                                    onChange={this.studentChange}
                                  >
                                    {this.props.teacherBaseProps.students &&
                                      this.props.teacherBaseProps.students.map((student, index) => {
                                        return <Option value={student.id} key={"student-" + index}>
                                          <PersonAvatar
                                            size={'default'}
                                            person={student}
                                          />
                                        </Option>
                                      })
                                    }
                                  </Select>
                                )}
                              </FormItem>
                              :
                              <FormItem {...formItemBlockLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Students
                                  </div>
                                  <div className='font-16 font-normal'>
                                    To draft a tier plan, select students below.
                                  </div>
                                </div>} className="block-label">
                                {getFieldDecorator('studentIdSingleValue', {
                                  rules: [{ required: true, message: 'Select students.' }],
                                })(
                                  <Select
                                    size='large'
                                    showSearch={true}
                                    className="ant-select-very-large-multiple"
                                    placeholder="Select student"
                                    onChange={(studentId, option) => {
                                      // pass in a list of len 1 to make it conform to tier 2
                                      this.studentChange([studentId], option)
                                    }}
                                  >
                                    {this.props.teacherBaseProps.students &&
                                      this.props.teacherBaseProps.students.map((student, index) => {
                                        return <Option value={student.id} key={"student-" + index}>
                                          <PersonAvatar
                                            size={'default'}
                                            person={student}
                                          />
                                        </Option>
                                      })
                                    }
                                  </Select>
                                )}
                              </FormItem>}
                          </div>

                          {!this.state.selectedStudent ||
                            (this.state.selectedStudent && !this.state.dontDisplayReadingFoundations) ?
                            <FormItem {...formItemBlockLayout}
                              label={<div>
                                <div className='font-20 font-bold'>
                                  Content areas
                                </div>
                                <div className='font-16 font-normal'>
                                  Select all content areas that apply. A goal will be generated for each content area selected.
                                </div>
                              </div>}
                              className="block-label">
                              {getFieldDecorator('subject', {
                                rules: [{
                                  required: true,
                                  message: 'Select all content areas that apply.'
                                }],
                              })(
                                <Select
                                  mode="multiple"
                                  size={'large'}
                                  placeholder="Select all content areas that apply"
                                  onChange={this.subjectChange}
                                >
                                  <Option value={'Reading Comprehension in Literature'}>
                                    Reading Comprehension in Literature
                                  </Option>
                                  <Option value={'Reading Comprehension in Informational Text'}>
                                    Reading Comprehension in Informational Text
                                  </Option>
                                  <Option value={'Reading Foundations'}>
                                    Reading Foundational Skills
                                  </Option>
                                  <Option value={'Math'}>
                                    Math
                                  </Option>
                                  <Option value={'Social Emotional Learning'}>
                                    Social Emotional Learning
                                  </Option>
                                </Select>
                              )}
                            </FormItem>
                            :
                            <FormItem {...formItemBlockLayout}
                              label={<div>
                                <div className='font-20 font-bold'>
                                  Student
                                </div>
                                <div className='font-16 font-normal'>
                                  Select all content areas that apply. A goal will be generated for each content area selected.
                                </div>
                              </div>}
                              className="block-label">
                              {getFieldDecorator('subject', {
                                rules: [{
                                  required: true,
                                  message: 'Select all content areas that apply.'
                                }],
                              })(
                                <Select
                                  mode="multiple"
                                  size={'large'}
                                  placeholder="Select all content areas that apply"
                                  onChange={this.subjectChange}
                                >
                                  <Option value={'Reading Comprehension in Literature'}>
                                    Reading Comprehension in Literature
                                  </Option>
                                  <Option value={'Reading Comprehension in Informational Text'}>
                                    Reading Comprehension in Informational Text
                                  </Option>
                                  <Option value={'Math'}>
                                    Math
                                  </Option>
                                  <Option value={'Social Emotional Learning'}>
                                    Social Emotional Learning
                                  </Option>
                                </Select>
                              )}
                            </FormItem>
                          }

                          {this.state.selectedTier && this.state.selectedTier === 'Tier 3' ?
                            <div>
                              <FormItem {...formItemBlockLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Students' difficulties
                                  </div>
                                  <div className='font-16 font-normal'>
                                    Based on teacher observation, select the option that best fits the students' difficulties.
                                  </div>
                                </div>}
                                className="block-label">
                                {getFieldDecorator('mayHaveTroubleWith', {
                                  rules: [{ required: true, message: 'Select one' }],
                                })(
                                  <Select
                                    size={'large'}
                                    placeholder="Select...">
                                    <Option value={'information'}>
                                      {this.state.mayHaveTroubleWith.hasOwnProperty('information') ?
                                        capitalizeFirstChar(this.state.mayHaveTroubleWith['information']) : ''}
                                    </Option>
                                    <Option value={'vocabulary'}>
                                      {this.state.mayHaveTroubleWith.hasOwnProperty('vocabulary') ?
                                        capitalizeFirstChar(this.state.mayHaveTroubleWith['vocabulary']) : ''}
                                    </Option>
                                    <Option value={'problemSolving'}>
                                      {this.state.mayHaveTroubleWith.hasOwnProperty('problemSolving') ?
                                        capitalizeFirstChar(this.state.mayHaveTroubleWith['problemSolving']) : ''}
                                    </Option>
                                    <Option value={'efficiency'}>
                                      {this.state.mayHaveTroubleWith.hasOwnProperty('efficiency') ?
                                        capitalizeFirstChar(this.state.mayHaveTroubleWith['efficiency']) : ''}
                                    </Option>
                                    <Option value={'ell'}>
                                      {this.state.mayHaveTroubleWith.hasOwnProperty('ell') ?
                                        capitalizeFirstChar(this.state.mayHaveTroubleWith['ell']) : ''}
                                    </Option>

                                  </Select>
                                )}
                              </FormItem>


                              <FormItem {...formItemBlockLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Students' interests
                                  </div>
                                  <div className='font-16 font-normal'>
                                    Based on an interview with the students, write a short description of what the students are interested in.
                                  </div>
                                </div>}
                                className="block-label">
                                {getFieldDecorator('studentInterest', {
                                  rules: [{
                                    required: true,
                                    message: 'Required'
                                  }],
                                })(
                                  <TextArea rows={4} />
                                )}
                              </FormItem>
                            </div> : ''}

                          {/*this.state.selectedTier &&
                            this.state.selectedTier === "Tier 3" ?
                            <FormItem {...formItemBlockLayout}
                              label={<div>
                                <div className='font-20 font-bold'>
                                  Parent shared
                                </div>
                                <div className='font-16 font-normal'>
                                  Write a short description of any pertinent information that the parent has shared.
                                </div>
                              </div>}
                              className="block-label">
                              {getFieldDecorator('parentShared', {
                                rules: [{
                                  required: false,
                                  message: 'Required'
                                }],
                              })(
                                <TextArea rows={4} />
                              )}
                            </FormItem>
                              : ''*/}

                          {this.state.selectedSubjects.length > 0 ?
                            <div className="font-30 mb-2 font-black">
                              Student strengths by content area
                            </div>
                            : ''}
                          {this.state.selectedSubjects.map((subject, idx) => {
                            return <FormItem {...formItemBlockLayout} label={<div>
                              <div className='font-20 font-bold'>
                                {subject}
                              </div>
                              <div className='font-16 font-normal'>
                                Choose a strength these students have in {subject}.
                              </div>
                            </div>}
                              className="block-label"
                              key={'strength-select-' + subject}>
                              {getFieldDecorator('strength-' + subject, {
                                rules: [{ required: true, message: 'Select greatest impact.' }],
                              })(
                                <Select
                                  size={'large'}
                                  placeholder="Select strength...">
                                  {this.state.studentStrengths[strengthKey][subject].map((strength, idx) => {
                                    return <Option value={strength} key={'strength-option-' + subject + '-' + strength}>
                                      {capitalizeFirstChar(strength)}
                                    </Option>
                                  })}
                                </Select>
                              )}
                            </FormItem>
                          })}


                          <FormItem className="mb-0">
                            <Button
                              type="primary"
                              size={'large'}
                              htmlType="submit"
                              className="lg-btn float-right"
                            >
                              Continue
                            </Button>
                          </FormItem>
                        </Form>
                      </div>
                    </div>
                  </div>
                </DraftStepContainer >

              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalStudent)