import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import ColType from '../Types'
import { firebase, db } from '../firebase/Firebase'
//import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Form, Input, Button, Icon, Modal, notification, Tooltip, Row, Col } from 'antd'
const FormItem = Form.Item
const TextArea = Input.TextArea

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class CoachingNotesUpdateForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        report: null,
        formData: [
            {
                label: 'Title',
                fieldDecorator: 'reportTitle',
                placeholder: '',
            },
            {
                label: 'Description',
                fieldDecorator: 'reportDescription',
                placeholder: '',
            },
            {
                label: 'Goal',
                fieldDecorator: 'reportReflection',
                placeholder: '',
            },
            {
                label: "Pre-conference notes",
                fieldDecorator: 'adminIncentive',
                placeholder: '',
            },
            {
                label: "Co-teaching notes",
                fieldDecorator: 'adminChangeEnvironment',
                placeholder: '',
            },
            {
                label: "Post-conference notes",
                fieldDecorator: 'adminProgress',
                placeholder: '',
            },
            {
                label: 'Reflection',
                fieldDecorator: 'adminNextSteps',
                placeholder: '',
            },
            {
                label: "Decisions",
                fieldDecorator: 'coachesSupport',
                placeholder: '',
            }
        ]
    }

    componentDidMount() {
        this.prefillForm(this.props.report)
    }

    componentWillReceiveProps(nextProps) {
        this.prefillForm(nextProps.report)
    }

    prefillForm = (report) => {
        if (!report) {
            return
        }

        let currentReportId = ''
        let reportId = ''
        if (this.state.report && this.state.report.hasOwnProperty('id')) {
            currentReportId = this.state.report.id
        }
        if (report && report.hasOwnProperty('id')) {
            reportId = report.id
        }

        if (currentReportId !== reportId) {
            this.setState({
                report: report,
            }, () => {
                let formObj = {}
                for (let formVal of this.state.formData) {
                    if (!report.hasOwnProperty(formVal.fieldDecorator)) {
                        continue
                    } else {
                        formObj[formVal.fieldDecorator] = report[formVal.fieldDecorator]
                    }
                }
                this.props.form.setFieldsValue(formObj)
            })
        }
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleOk = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!(this.props.report && this.props.districtId && this.props.author && this.props.authorId && this.props.teacherId)) {
            console.error('missing props in report form', this.props)
            return
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values)

                this.setState({
                    submitting: true,
                })

                Object.keys(values).forEach((key) => {
                    if (!values[key]) {
                        values[key] = ''
                    }
                })

                values.updatedAt = firebase.firestore.FieldValue.serverTimestamp()

                db.collection(ColType.coachingReports)
                    .doc(this.props.report.id)
                    .update(values)
                    .then(() => {
                        successMessage('Report edited successfully.')
                        this.setState({
                            submitting: false,
                        })
                        this.handleCancel()
                    })
                    .catch((error) => {
                        console.error("Error updating document: ", error)
                        errorMessage('Error editing report, please try again.')
                        this.setState({
                            submitting: false,
                        })
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Tooltip title='Edit report'>
                    <Button icon='edit' className='lg-circle-btn' onClick={this.showModal}>
                    </Button>
                </Tooltip>
                <Modal
                    title="Edit coaching report"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        <Row gutter={32}>
                            {this.state.formData.map((item, index) => {
                                return <Col span={item['label'] === 'Title' ||
                                    item['label'] === 'Description' ? 24 : 12}>
                                    <FormItem {...formItemBlockLayout} label={item.label + '::'}>
                                        {getFieldDecorator(item.fieldDecorator, {
                                            // remove required, all optional
                                            rules: [{ required: false, message: 'Required.' }],
                                        })(
                                            item['label'] === 'Title' ?
                                                <Input size={"large"} placeholder={item.placeholder} /> :
                                                <TextArea rows={4} size={"large"} placeholder={item.placeholder} />
                                        )}
                                    </FormItem>
                                </Col>
                            })}
                        </Row>

                        <FormItem className="mb-0">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="lg-btn float-right"
                                disabled={this.state.submitting}
                            >
                                {this.state.submitting ?
                                    <span><Icon type="loading" className="mr-1" /> Editing...</span> :
                                    <span><Icon type="edit" /> Edit</span>}
                            </Button>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(CoachingNotesUpdateForm)