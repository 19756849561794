import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
//import ReactToPrint from "react-to-print"
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
//import { flattenDoc } from '../../Util'
import ColType from '../../Types'
import { Modal, Button, Icon, Input, notification, Form, Checkbox } from 'antd'
//import Empty from '../../customcomponents/Empty'
//var moment = require('moment')
const FormItem = Form.Item
const TextArea = Input.TextArea

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}


class PacingGuideFeedback extends Component {
    state = {
        submitting: false,
        modalVisible: false,
    }

    componentDidMount() {

    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)
                    let formObj = {
                        standard: this.props.standard,
                        doc: this.props.doc,
                        resourceName: this.props.resourceName,
                    }
                    if (values.hasOwnProperty('feedback') && values.feedback) {
                        formObj.feedback = values.feedback
                    }
                    if (!values.anonymous && this.props.person) {
                        formObj.person = this.props.person
                    }
                    let addedFormObj = {
                        content: JSON.stringify(formObj),
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    db.collection(ColType.pacingGuideFeedback)
                        .add(addedFormObj)
                        .then((docRef) => {
                            console.log("Document written with ID: ", docRef.id)
                            successMessage('Thank you for your feedback!')
                            this.setState({
                                submitting: false,
                                modalVisible: false,
                            }, () => {
                                this.props.form.setFieldsValue({
                                    feedback: '',
                                    anonymous: true
                                })
                            })
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error)
                            errorMessage('Error adding feedback. Please try again.')
                            this.setState({
                                submitting: false,
                            })
                        })

                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Button type="primary" className="lg-btn ml-auto" onClick={this.showModal}
                    disabled={this.props.hasOwnProperty('disableFeedback') && this.props.disableFeedback}>
                    <Icon type="flag" /> Feedback
                </Button>
                <Modal
                    title="Feedback for the Dot It team"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    {this.props.standard &&
                        this.props.resourceName &&
                        this.props.doc ?
                        <div>
                            <div className='font-20 font-bold'>I'd like to submit feedback for:</div>
                            <div className='font-20 mb-2'>
                                <p className='mb-0'><b>Subject:</b> {this.props.standard.standardCategory}</p>
                                <p className='mb-0'><b>Category:</b> {this.props.standard.category}</p>
                                <p className='mb-0'><b>Grade:</b> {this.props.standard.grade}</p>
                                <p className='mb-0'><b>Standard:</b> {this.props.standard.standard}</p>
                                <div className='mt-1 pt-1 border-top font-20'>
                                    <div className='mb-2'><b>Resource:</b> {this.props.resourceName}</div>

                                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                                        <FormItem {...formItemLayout} label="Feedback (Optional)">
                                            {getFieldDecorator('feedback', {
                                                rules: [{ required: false, message: 'Required.' }],
                                            })(
                                                <TextArea
                                                    placeholder="Feedback..."
                                                    autoSize={{ minRows: 5 }}
                                                />
                                            )}
                                        </FormItem>
                                        <FormItem {...formItemLayout} label="">
                                            {getFieldDecorator('anonymous', {
                                                initialValue: true,
                                                valuePropName: "checked",
                                                rules: [{ required: true, message: 'Required.' }],
                                            })(
                                                <Checkbox initialValue={true}><span className='pl-1 font-black'>I'd like to remain anonymous</span></Checkbox>
                                            )}
                                        </FormItem>
                                        <div className="flex flex-v-center">
                                            <Button
                                                size={'large'}
                                                disabled={this.state.submitting}
                                                onClick={this.handleCancel}
                                                className="ml-auto mr-1"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="primary"
                                                size={'large'}
                                                onClick={this.handleSubmit}
                                                disabled={this.state.submitting}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>


                        </div> : ''}
                </Modal>
            </div>
        )
    }
}

export default Form.create()(PacingGuideFeedback)