import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { getIDFromURL } from '../../Util'

class TeacherHelpDeskSideMenu extends Component {
    state = {
        teacherId: null,
        currentPage: '',
    }

    setCurrentPage = () => {
        let url = [window.location.protocol, '//', window.location.host, window.location.pathname].join('')
        let split = url.split('/')
        let currentPage = ''
        if (split.length > 4) {
            currentPage = split[4]
        }

        this.setState({
            currentPage: currentPage,
        })
    }

    componentDidMount() {
        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })

        this.setCurrentPage()
    }

    componentWillReceiveProps() {
        this.setCurrentPage()
    }

    render() {
        return (
            <div>
                {this.props.course === "Getting started with Dot It" ?
                    <div>
                        <h2 className="mb-2 font-bold">Getting started with Dot It</h2>

                        <Link
                            to={'/teacher/help-desk-getting-started/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-getting-started' ? " selected-cyan" : '')}>
                                Getting to know your home page
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-adding-a-student/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-adding-a-student' ? " selected-cyan" : '')}>
                                Adding a student
                            </div>
                        </Link>
                        <h2 className="mb-2 mt-2 font-bold">Next Courses</h2>

                        <Link
                            to={'/teacher/help-desk-add-iep-student/' + this.state.teacherId}
                        >
                            <div className={"br-4 font-18 background-fff font-black border ant-shadow ant-shadow-more-hover"}>
                                <img src="/helpdesk4.png" alt="helpdesk" className="w-100" />
                                <div className="p-2">
                                    <div className="font-14 font-bold mb-1">Course</div>
                                    <div>
                                        Drafting an IEP Goal
                                    </div>
                                </div>
                            </div >
                        </Link >
                        <Link
                            to={'/teacher/help-desk-add-iep-student/' + this.state.teacherId}
                        >
                            <div className={"br-4 mt-2 font-18 background-fff font-black border ant-shadow ant-shadow-more-hover"}>
                                <img src="/helpdesk5.png" alt="helpdesk" className="w-100" />
                                <div className="p-2">
                                    <div className="font-14 font-bold mb-1">Course</div>
                                    <div>
                                        Drafting a Tier Plan
                                    </div>
                                </div>
                            </div >
                        </Link >
                    </div>
                    : ''}

                {this.props.course === "Drafting an IEP Goal" ?
                    <div>
                        <h2 className="mb-2 font-bold">Drafting an IEP Goal</h2>
                        <Link
                            to={'/teacher/help-desk-add-iep-student/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-student' ? " selected-cyan" : '')}>
                                Choose student and content area
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-information/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-information' ? " selected-cyan" : '')}>
                                Provide student metrics
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-select/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-select' ? " selected-cyan" : '')}>
                                Select goals
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-modify/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-modify' ? " selected-cyan" : '')}>
                                Modify selected goals
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-progress-monitoring/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-progress-monitoring' ? " selected-cyan" : '')}>
                                Progress monitoring
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-service/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-service' ? " selected-cyan" : '')}>
                                Service times
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-accommodations/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-accommodations' ? " selected-cyan" : '')}>
                                Accommodations
                            </div>
                        </Link>
                        <Link
                            to={'/teacher/help-desk-add-iep-confirm/' + this.state.teacherId}
                        >
                            <div className={"br-4 p-2 font-18 background-fff font-black light-cyan-hover" + (this.state.currentPage === 'help-desk-add-iep-confirm' ? " selected-cyan" : '')}>
                                Confirm and add IEP Goal
                            </div>
                        </Link>

                        <h2 className="mb-2 mt-2 font-bold">Next Course</h2>

                        <Link
                            to={'/teacher/help-desk-add-iep-student/' + this.state.teacherId}
                        >
                            <div className={"br-4 mt-2 font-18 background-fff font-black border ant-shadow ant-shadow-more-hover"}>
                                <img src="/helpdesk5.png" alt="helpdesk" className="w-100" />
                                <div className="p-2">
                                    <div className="font-14 font-bold mb-1">Course</div>
                                    <div>
                                        Drafting a Tier Plan
                                    </div>
                                </div>
                            </div >
                        </Link >
                    </div>
                    : ''}
            </div >
        )
    }
}

export default TeacherHelpDeskSideMenu
