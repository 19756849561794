import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
//import { db } from '../../firebase/Firebase'
//import PersonHeader from '../../login/PersonHeader'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import ReactToPrint from "react-to-print"
import { getIDFromURL, getQueryStringParam, getFormattedProgressMonitoringText } from '../../Util'
import { Icon, Button, Row, Col } from 'antd'

class TeacherGroupLessonPlanProp extends Component {
    state = {
        teacherId: null,
        eventId: null,
        componentMounted: false,
    }

    // Do fetch here
    componentDidMount() {
        var teacherId = getIDFromURL(window.location)
        var eventId = getQueryStringParam('calendarevent')

        this.setState({
            teacherId: teacherId,
            eventId: eventId,
            componentMounted: true,
        })
    }

    render() {
        return (
            <div className="background-fff-important">
                {this.state.componentMounted &&
                    this.props.event &&
                    this.props.teacherBaseProps &&
                    this.props.teacherBaseProps.IEPDict &&
                    this.props.teacherBaseProps.studentDict ?
                    <div>
                        <div className='border-top pt-3 mt-3'>
                            <div className="float-right mr-4 mt-1">
                                <ReactToPrint
                                    trigger={() =>
                                        <Button
                                            size={'large'}
                                            type="primary"
                                            className="inline-block mr-2"
                                            style={{ marginTop: "-12px" }}
                                        >
                                            <Icon type="printer" />
                                            Print
                                        </Button>
                                    }
                                    content={() => this.componentRef}
                                />
                            </div>
                        </div>
                        {/** offset the margin of the printable component so that it lines up correctly */}
                        {/** printable component, gave it layout-content and offsetted it above */}
                        <div className="ml-4 mr-4 mt-4 mb-4 print-remove-margin" ref={el => (this.componentRef = el)}>
                            <div>
                                <h1 className="font-bold font-24 print-font-18 mb-2 print-mb-15">
                                    Group Goal Guide
                                </h1>

                                <div>
                                    <Row gutter={8} className="mb-1 print-hide">
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="w-100-print">
                                            <h3 className="font-bold">Condition / Target Behavior</h3>
                                        </Col>
                                        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="w-100-print"><h3 className="font-bold">Performance <div className="inline-block">Criterion 1</div></h3></Col>
                                        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="w-100-print"><h3 className="font-bold">Performance <div className="inline-block">Criterion 2</div></h3></Col>
                                        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="w-100-print"><h3 className="font-bold">Performance <div className="inline-block">Criterion 3</div></h3></Col>
                                        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="w-100-print"><h3 className="font-bold">Performance <div className="inline-block">Criterion 4</div></h3></Col>
                                    </Row>
                                    <div>
                                        {this.props.event && this.props.event.ieps.map((iep, index) => {
                                            if (!this.props.teacherBaseProps.IEPDict) return false
                                            if (!this.props.teacherBaseProps.studentDict) return false
                                            if (!(this.props.teacherBaseProps.IEPDict.hasOwnProperty(iep.iepId)))
                                                return false
                                            var studentId = this.props.teacherBaseProps.IEPDict[iep.iepId].studentId
                                            if (!(this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)))
                                                return false

                                            var student = this.props.teacherBaseProps.studentDict[studentId]
                                            var IEPGoal = this.props.teacherBaseProps.IEPDict[iep.iepId]
                                            let isMTSS = IEPGoal.hasOwnProperty('isMTSS') && IEPGoal.isMTSS
                                            let progressMonitoringQuestions = getFormattedProgressMonitoringText(IEPGoal)
                                            // default 4 to not break existing goals
                                            let numProgressMonitoringScores = 4
                                            if (IEPGoal.hasOwnProperty('iep') &&
                                                IEPGoal.iep &&
                                                IEPGoal.iep.hasOwnProperty('numProgressMonitoringScores') &&
                                                IEPGoal.iep.numProgressMonitoringScores) {
                                                numProgressMonitoringScores = IEPGoal.iep.numProgressMonitoringScores
                                            }
                                            let willStatement = ''
                                            if (IEPGoal.iep.hasOwnProperty('standardCategory')) {
                                                willStatement = IEPGoal.iep.standardCategory.toLowerCase()
                                            }
                                            if (iep.mainSubject === "Reading Comprehension in Literature" ||
                                                IEPGoal.iep.mainSubject === "Reading Comprehension in Informational Text") {
                                                willStatement = 'reading comprehension'
                                            }
                                            return <div key={'event-progress-monitoring-' + this.props.event.id + '-' + index}
                                                className="mb-2 page-break-inside-avoid">
                                                {progressMonitoringQuestions.length === 4 ?
                                                    <Row gutter={8} type='flex'>
                                                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                            <div className="flex flex-v-center w-100 page-break-inside-avoid">
                                                                <div className='p-2 br-15 print-p1' style={{ background: '#efdbff' }}>
                                                                    <div className="font-bold font-16 mb-1 print-show print-font-14 print-mb-05">
                                                                        Condition / Target Behavior
                                                                    </div>
                                                                    <div className='font-16 print-font-14'>{student.firstName + ' ' + student.lastName}</div>
                                                                    <h3 className='mb-0 print-font-14'>
                                                                        {
                                                                            (isMTSS && IEPGoal.iep['given'].length >= 2) ?
                                                                                'By ' +
                                                                                (IEPGoal.iep.completionDate === '<date>,' ? '<date>' : IEPGoal.iep.completionDate) + ', given ' +

                                                                                IEPGoal.iep["given"][0] + " " +

                                                                                student.firstName + ' will ' +

                                                                                IEPGoal.iep['given'][1] + ' by '
                                                                                :
                                                                                IEPGoal.iep['given'].length >= 1 ?
                                                                                    'By ' +
                                                                                    (IEPGoal.iep.completionDate === '<date>,' ? '<date>' : IEPGoal.iep.completionDate) + ', given ' +

                                                                                    IEPGoal.iep["given"][0] + ", " +

                                                                                    student.firstName + ' will improve skills in ' + willStatement + ' by '
                                                                                    :
                                                                                    ''
                                                                        }
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {/*
                                                        <Col xs={24} className='print-show font-bold mb-1 mt-1  print-font-14'>
                                                            Performance Criterions:
                                                        </Col>
                                                        */}
                                                        <Col xs={6} sm={6} md={4} lg={4} xl={4} className=''>
                                                            <div className="w-100">
                                                                <h3 className='mb-0 p-2 br-15 h-100 page-break-inside-avoid print-p1' style={{ background: '#f9f0ff' }}>
                                                                    <div className='flex flex-v-center flex-h-center h-100 print-font-14'>
                                                                        {progressMonitoringQuestions[0]}
                                                                    </div>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                                            <div className="w-100">
                                                                <h3 className='mb-0 p-2 br-15 h-100 page-break-inside-avoid print-p1' style={{ background: '#f9f0ff' }}>
                                                                    <div className='flex flex-v-center flex-h-center h-100 print-font-14'>
                                                                        {progressMonitoringQuestions[1]}
                                                                    </div>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                                            <div className="w-100">
                                                                <h3 className='mb-0 p-2 br-15 h-100 page-break-inside-avoid print-p1' style={{ background: '#f9f0ff' }}>
                                                                    <div className='flex flex-v-center flex-h-center h-100 print-font-14'>
                                                                        {numProgressMonitoringScores >= 3 ? progressMonitoringQuestions[2] : '-'}
                                                                    </div>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                                            <div className="w-100">
                                                                <h3 className='mb-0 p-2 br-15 h-100 page-break-inside-avoid print-p1' style={{ background: '#f9f0ff' }}>
                                                                    <div className='flex flex-v-center flex-h-center h-100 print-font-14'>
                                                                        {numProgressMonitoringScores >= 4 ? progressMonitoringQuestions[3] : '-'}
                                                                    </div>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    : ''}
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>

                                <div className='font-24 font-bold mb-2 mt-4 print-font-18 print-mt-2 print-mb-15'>
                                    Accommodations
                                </div>
                                <Row gutter={24} className="mb-1 print-hide">
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                        <h3 className="font-bold">Student</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                        <h3 className="font-bold">Presentation Strategy</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                        <h3 className="font-bold">Engagement Strategy</h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                        <h3 className="font-bold">Expression Strategy</h3>
                                    </Col>
                                </Row>
                                <div>
                                    {this.props.event && this.props.event.ieps.map((iep, index) => {
                                        if (!this.props.teacherBaseProps.IEPDict) return false
                                        if (!this.props.teacherBaseProps.studentDict) return false
                                        if (!(this.props.teacherBaseProps.IEPDict.hasOwnProperty(iep.iepId)))
                                            return false
                                        var studentId = this.props.teacherBaseProps.IEPDict[iep.iepId].studentId
                                        if (!(this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)))
                                            return false

                                        var student = this.props.teacherBaseProps.studentDict[studentId]
                                        var IEPGoal = this.props.teacherBaseProps.IEPDict[iep.iepId]
                                        return <div key={'event-' + this.props.event.id + '-' + index}
                                            className="mb-2 page-break-inside-avoid">
                                            <Row gutter={8} type="flex">
                                                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                                    <div className='p-2 br-15 h-100 page-break-inside-avoid print-p1 print-font-14' style={{ background: '#fff1b8' }}>
                                                        <div className='flex flex-v-center h-100 print-hide'>
                                                            <PersonAvatar
                                                                person={student}
                                                                size={'large'}
                                                                containerClassName="font-18 print-font-14"
                                                                avatarClassName="font-18 font-bold mr-1 print-font-14"
                                                                personClassName="font-black"
                                                            />
                                                        </div>
                                                        <div className='print-show font-14'>
                                                            {student.firstName + ' ' + student.lastName}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                                    <div className='p-2 br-15 h-100 page-break-inside-avoid print-p1 print-font-14' style={{ background: '#fffbe6' }}>
                                                        <div className="font-bold font-16 mb-2 print-show print-font-14 print-mb-05">
                                                            Presentation Strategy:
                                                        </div>
                                                        <div className='flex flex-v-center flex-h-center h-100 print-font-14 print-display-block'>
                                                            {IEPGoal &&
                                                                IEPGoal.hasOwnProperty("accommodations") &&
                                                                IEPGoal.accommodations.hasOwnProperty('instructionBarrier') ?
                                                                <h3 className="mb-0 print-font-14">
                                                                    {IEPGoal.accommodations.instructionBarrier}
                                                                </h3>
                                                                :
                                                                student &&
                                                                    student.hasOwnProperty("accommodations") &&
                                                                    student.accommodations.hasOwnProperty('instructionBarrier') ?
                                                                    <h3 className="mb-0 print-font-14">
                                                                        {student.accommodations.instructionBarrier}.
                                                                    </h3>
                                                                    : '-'}
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                                    <div className='p-2 br-15 h-100 page-break-inside-avoid print-p1 print-font-14' style={{ background: '#fffbe6' }}>
                                                        <div className="font-bold font-16 mb-2 print-show print-font-14 print-mb-05">
                                                            Engagement Strategy:
                                                        </div>
                                                        <div className='flex flex-v-center flex-h-center h-100 print-font-14 print-display-block'>
                                                            {IEPGoal &&
                                                                IEPGoal.hasOwnProperty("accommodations") &&
                                                                IEPGoal.accommodations.hasOwnProperty('engagementBarrier') ?
                                                                <h3 className="mb-0 print-font-14">
                                                                    {IEPGoal.accommodations.engagementBarrier}.
                                                                </h3>
                                                                :
                                                                student &&
                                                                    student.hasOwnProperty("accommodations") &&
                                                                    student.accommodations.hasOwnProperty('engagementBarrier') ?
                                                                    <h3 className="mb-0 print-font-14">
                                                                        {student.accommodations.engagementBarrier}.
                                                                    </h3>
                                                                    : '-'}
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                                    <div className='p-2 br-15 page-break-inside-avoid print-p1 print-font-14' style={{ background: '#fffbe6' }}>
                                                        <div className="font-bold font-16 mb-2 print-show print-font-14 print-mb-05">
                                                            Expression Strategy:
                                                        </div>
                                                        <div className='flex flex-v-center flex-h-center h-100 print-font-14 print-display-block'>
                                                            {IEPGoal &&
                                                                IEPGoal.hasOwnProperty("accommodations") &&
                                                                IEPGoal.accommodations.hasOwnProperty('organizationBarrier') ?
                                                                <h3 className="mb-0 print-font-14">
                                                                    {IEPGoal.accommodations.organizationBarrier}.
                                                                </h3>
                                                                :
                                                                student &&
                                                                    student.hasOwnProperty("accommodations") &&
                                                                    student.accommodations.hasOwnProperty('organizationBarrier') ?
                                                                    <h3 className="mb-0 print-font-14">
                                                                        {student.accommodations.organizationBarrier}.
                                                                    </h3>
                                                                    : '-'}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    })
                                    }
                                </div>
                            </div>





                        </div>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default TeacherGroupLessonPlanProp