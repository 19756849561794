import React, { Component } from 'react'
import { Route } from 'react-router-dom'
//import { Link } from 'react-router-dom'
import '../../../styles/GlobalStyle.css'
import { db } from '../../../firebase/Firebase'
import ColType from '../../../Types'
import { flattenDoc, FOLDER_TO_COLTYPE_OBJ, getIDFromURL, FOLDER_TRASHBIN, FOLDER_TRANSFER, FOLDER_ARCHIVED } from '../../../Util'
import DirectoryStudentSelect from './DirectoryStudentSelect'
import DirectoryStudent from './DirectoryStudent'
import DirectoryStudentPlan from './DirectoryStudentPlan'

const TeacherArchivedStudentsSelectComponent = (props, archiveBaseProps, state) => {
    return <DirectoryStudentSelect archivedBaseProps={state} teacherBaseProps={archiveBaseProps.teacherBaseProps} {...props} />
}

const TeacherArchivedStudentViewComponent = (props, archiveBaseProps, state) => {
    return <DirectoryStudent archivedBaseProps={state} teacherBaseProps={archiveBaseProps.teacherBaseProps} {...props} />
}

const TeacherArchivedStudentPlanComponent = (props, archiveBaseProps, state) => {
    return <DirectoryStudentPlan archivedBaseProps={state} teacherBaseProps={archiveBaseProps.teacherBaseProps} {...props} />
}

class ArchivedBase extends Component {
    state = {
        dataCallsMade: false,
        teacherId: '',
        teacher: null,
        archivedStudents: [],
        archivedStudentsLoaded: false,
        students: [],
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
        studentIEPDict: {},
        studentIEPListeners: {},
        studentIEPLoading: {},
        moveInProcess: false,
        urlRoute: 'archived',
        getIEPSForStudentFunc: (studentId) => { },
        onBeforeMoveFunc: (studentId) => { },
        removeStudentFunc: (studentId, didError) => { },
        singleGoalMovedFunc: (studentId, didError) => { },
    }

    componentDidMount() {
        let teacherId = getIDFromURL(window.location)

        let urlRoute = 'archived'
        if (this.props.baseFolder === FOLDER_TRASHBIN) {
            urlRoute = 'deleted'
        } else if (this.props.baseFolder === FOLDER_TRANSFER) {
            urlRoute = 'transfers'
        }

        this.setState({
            teacherId: teacherId,
            getIEPSForStudentFunc: this.getIEPSForStudent,
            onBeforeMoveFunc: this.onBeforeMove,
            removeStudentFunc: this.removeStudent,
            singleGoalMovedFunc: this.onSingleGoalMoved,
            baseFolder: this.props.baseFolder,
            urlRoute: urlRoute,
        })
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    componentWillUnmount() {
        Object.keys(this.state.studentIEPListeners).forEach(studentId => {
            this.state.studentIEPListeners[studentId]()
        })
    }

    getColTypeOfBaseFolder = () => {
        if (!FOLDER_TO_COLTYPE_OBJ.hasOwnProperty(this.props.baseFolder)) {
            console.error('Folder map does not contain baseFolder: ' + this.props.baseFolder)
            return
        }
        return FOLDER_TO_COLTYPE_OBJ[this.props.baseFolder]
    }

    getData = () => {
        // don't have necessary data yet
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher &&
            this.props.teacherBaseProps.currentTimeStamp)) {
            return
        }
        // already have made data calls
        if (this.state.dataCallsMade) {
            return
        }

        this.setState({
            dataCallsMade: true,
        }, () => {
            this.setState({
                teacher: this.props.teacherBaseProps.teacher,
                currentTimeStamp: this.props.teacherBaseProps.currentTimeStamp,
            })

            let folderCol = this.getColTypeOfBaseFolder()

            db.collection(folderCol)
                .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                //.where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                .orderBy('latestUpdateTimeStamp', 'desc')
                .limit(100)
                .get()
                .then(snapshot => {
                    // add to archivedStudents for scroll loading
                    let archivedStudents = this.state.archivedStudents
                    if (snapshot.docs.length === 0) {
                        this.setState({
                            archivedStudentsLoaded: true,
                        })
                    }

                    snapshot.forEach(doc => {
                        let archivedStudent = flattenDoc(doc)
                        archivedStudents.push(archivedStudent)
                    })

                    this.setState({
                        archivedStudents: archivedStudents,
                    })

                    archivedStudents.map((archivedStudent) => {
                        let studentId = archivedStudent.studentId
                        //console.log('archivedStudent', archivedStudent)
                        // student needs to be retrieved, may not be in 
                        // teacherBaseProps as the folder may not be FOLDERS_CURRENT.
                        db.collection(ColType.student)
                            .doc(studentId)
                            .get()
                            .then(doc => {
                                if (!doc.exists) {
                                    console.log('student doc does not exist', studentId)
                                }

                                let student = flattenDoc(doc)

                                let students = this.state.students
                                let studentDict = this.state.studentDict
                                students.push(student)
                                studentDict[student.id] = student

                                this.setState({
                                    students: students,
                                    studentDict: studentDict,
                                    archivedStudentsLoaded: true,
                                })
                            })
                        return false
                    })
                })
        })
    }

    getIEPSForStudent = (studentId) => {
        if (!this.props.teacherBaseProps.teacher) {
            return
        }
        // already have the data
        if (this.state.studentIEPDict.hasOwnProperty(studentId)) {
            return
        }
        if (this.state.studentIEPListeners.hasOwnProperty(studentId)) {
            return
        }

        let studentIEPLoading = this.state.studentIEPLoading
        studentIEPLoading[studentId] = true
        this.setState({
            studentIEPLoading: studentIEPLoading,
        })

        // initial set state to set the studentIEPDict for this student
        // to avoid double calls (probably not necessary)
        let studentIEPDict = this.state.studentIEPDict
        studentIEPDict[studentId] = []
        this.setState({
            studentIEPDict: studentIEPDict,
        }, () => {
            // should never occur
            if (this.state.studentIEPListeners.hasOwnProperty(studentId)) {
                this.state.studentIEPListeners[studentId]()
            }

            let studentIEPListener = db.collection(ColType.iep)
                .where('studentId', '==', studentId)
                .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                //.where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                .where('folder', '==', this.props.baseFolder)
                .orderBy('timeStamp', 'desc')
                .onSnapshot(snapshot => {
                    // no longer loading
                    let studentIEPLoading = this.state.studentIEPLoading
                    studentIEPLoading[studentId] = false

                    // add to state's IEP data
                    let IEPGoals = this.state.IEPGoals
                    let IEPDict = this.state.IEPDict
                    let studentIEPDict = this.state.studentIEPDict

                    // clear out old data
                    IEPGoals = IEPGoals.filter(iep => iep.studentId !== studentId)
                    // dictionary filter
                    IEPDict = Object.fromEntries(
                        Object.entries(IEPDict).filter(([key, value]) => value.studentId !== studentId))
                    studentIEPDict[studentId] = []

                    snapshot.forEach(doc => {
                        let IEPGoal = flattenDoc(doc)
                        IEPGoals.push(IEPGoal)
                        IEPDict[IEPGoal.id] = IEPGoal
                        studentIEPDict[studentId].push(IEPGoal)
                    })

                    this.setState({
                        studentIEPLoading: studentIEPLoading,
                        IEPGoals: IEPGoals,
                        IEPDict: IEPDict,
                        studentIEPDict: studentIEPDict,
                    })
                })

            let studentIEPListeners = this.state.studentIEPListeners
            studentIEPListeners[studentId] = studentIEPListener
            this.setState({
                studentIEPListeners: studentIEPListeners,
            })
        })
    }


    getLinkRoute = () => {
        let linkRoute = 'archived'
        if (this.props.baseFolder === FOLDER_ARCHIVED) {
            linkRoute = 'archived'
        } else if (this.props.baseFolder === FOLDER_TRANSFER) {
            linkRoute = 'transfers'
        } else if (this.props.baseFolder === FOLDER_TRASHBIN) {
            linkRoute = 'deleted'
        }
        return linkRoute
    }

    onSingleGoalMoved = (studentId, didError) => {
        this.setState({
            moveInProcess: false,
        }, () => {
            this.props.history.push(
                {
                    pathname: '/teacher/' + this.getLinkRoute() + '/student/' +
                        this.state.teacherId + '?student=' + studentId,
                }
            )
        })
    }

    removeStudent = (studentId, didError) => {
        let linkURL = '/teacher/' + this.getLinkRoute() + '/students/' + this.state.teacherId


        if (didError) {
            this.setState({
                moveInProcess: false,
            }, () => {
                this.props.history.push(
                    {
                        pathname: linkURL
                    }
                )
            })
            return
        }

        let students = this.state.students
        let studentDict = this.state.studentDict
        let studentIEPDict = this.state.studentIEPDict
        students = students.filter(student => student.id !== studentId)
        delete studentDict[studentId]
        delete studentIEPDict[studentId]
        this.setState({
            students: students,
            studentDict: studentDict,
            studentIEPDict: studentIEPDict,
            moveInProcess: false,
        }, () => {
            this.props.history.push(
                {
                    pathname: linkURL
                }
            )
        })
    }

    onBeforeMove = (studentId) => {
        this.setState({
            moveInProcess: true,
        })
    }

    render() {
        const { match: { url } } = this.props

        return (
            <div>
                <Route path={`${url}/students/*`}
                    render={props => TeacherArchivedStudentsSelectComponent(props, this.props, this.state)} />
                <Route path={`${url}/student/*`}
                    render={props => TeacherArchivedStudentViewComponent(props, this.props, this.state)} />
                <Route path={`${url}/student-plan/*`}
                    render={props => TeacherArchivedStudentPlanComponent(props, this.props, this.state)} />
            </div>
        )
    }
}

export default ArchivedBase