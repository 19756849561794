import React from 'react'
import Answers from './Answers'
import MathText from './MathText'


class WordProblemAssessment extends React.Component {
    state = {
    }

    componentDidMount() {
        console.log('Word problem assessment')
    }

    render() {
        return (
            <div>
                <MathText text={this.props.problem_statement} />
                <Answers answers={this.props.answers} />
            </div>
        )
    }
}

export default WordProblemAssessment
