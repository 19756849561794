import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import SuperAdminHeader from './SuperAdminHeader'
import { Row, Col } from 'antd'
import DistrictSearch from './DistrictSearch'
//import AdminSearch from './AdminSearch'

// This page can only be accessed by us, it is how you add admins that then can add other users.
class SuperAdminIsDistrictSandbox extends Component {
  state = {
    isSandbox: false,
  }

  setDistrict = (districtObj) => {
    console.log("set district", districtObj)
    var isSandbox = false
    if (districtObj.user.hasOwnProperty("isSandbox") &&
        districtObj.user.isSandbox) {
       isSandbox = districtObj.user.isSandbox
    }
    console.log("is sandbox?", isSandbox)
    this.setState({
        isSandbox: isSandbox
    })
  }

  componentDidMount() {

  }


  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
      }
    })
  }

  render() {
    return (
      <div>
        <SuperAdminHeader menuKey={'search'} />
        <Row gutter={32} className="mt-150 pl-4 pr-4">
          <Col span={24}>
            <h1 className="mb-0 font-bold">Search</h1>
            <h2 className="mb-4">You can find a district by searching for a district, 
              or by searching for an admin in a district. Must be an exact match!</h2>
          </Col>
          <Col span={8}>
             <DistrictSearch onDistrictFound={this.setDistrict}/>
         </Col>
         <Col span={8}>
           <div>{"Is Sandbox?: " + this.state.isSandbox}</div>
         </Col>
        </Row>
      </div>
    )
  }
}

export default SuperAdminIsDistrictSandbox