import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import VideoPlayer from '../../video/VideoPlayer'
import { getIDFromURL } from '../../Util'
//import { Layout } from 'antd'

class TeacherHelpDeskContent extends Component {
    state = {
        teacherId: null,
    }

    componentDidMount() {
        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    render() {
        let videosrc = this.props.videoURL

        return (
            <div>
                <div className="pl-4 border-left">
                    <div className="br-4 overflow-hidden">
                        <VideoPlayer {...{
                            autoplay: false,
                            controls: true,
                            sources: [{
                                src: (videosrc),
                                type: 'video/mp4'
                            }]
                        }}
                        />
                    </div>
                    <h1 className="font-bold mt-3 mb-3 font-30">{this.props.contentTitle}</h1>
                    <h2>Description</h2>
                </div>
            </div >
        )
    }
}

export default TeacherHelpDeskContent
