import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
//import { getChosenAnswersCorrectData } from '../.././Util'
//import { db } from '../../firebase/Firebase'
//import ColType from '../.././Types'
import { Layout, Row, Col, Icon } from 'antd'
//import { getTestLessonPlan } from './lessonplan'
import ComponentFactory from '../edviz/ComponentFactory'
const { Content } = Layout
//var moment = require('moment')

class PacingGuideTeacherAssessment extends Component {
    state = {
        lessonPlan: null,
        scores: {
            "X8AeqY0sWxR63rCtoqlO": [2, 2, 2, 2],
            "oeAL2R4zMFPmAa0kBHPj": [2, 3, 2, 2],
            "NPOqjRpEkAweKuOYVRuW": [2, 2, 2, 2],
            "YcX9jyq25IvP0gxj93DW": [2, 2, 2, 2],
            "B8M0cxr7p6yeyU76ZW7k": [2, 3, 2, 2],
            "AF44TmS4BBRWc5eZ3ZjF": [2, 3, 2, 2],
            "Rmjjl3gnLJvXvESLBYsM": [2, 2, 3, 2],
            "6oUQTUXW2h6xl9zflySJ": [2, 2, 2, 2],
            "VNGEWWv4dZSiN2NOe2Yj": [2, 3, 2, 2],
            "SDLF6I2fJ0gOXe9Q2Gqi": [2, 2, 3, 2],
            "CJXr5pKoD4IXrZR5Hj4R": [2, 2, 3, 2],
            "Bsbi0szr3bNKQwrC1Mou": [2, 3, 3, 1],
            "c1iAqPYnWLR3pvFidUk1": [2, 3, 3, 1],
            "kOm51jKC0ogwuYmdrzC0": [2, 3, 3, 1],
            "WiT3DVqs1Cpe9HlwJDeW": [2, 3, 3, 1],
            "MEIvxifQPMlFK8eQVySq": [2, 3, 3, 1],
            "CGokERh6NxNGqEtTrZ5n": [2, 3, 3, 1],
        }
    }

    componentDidMount() {

    }

    resultsForDOKLevel = (student, questions, dokLevel) => {
        if (!(student && questions && dokLevel)) {
            return false
        }
        if (questions.length === 0) {
            return false
        }
        const assessmentCompleted = true
        const correctAnswers = [2, 3, 3, 1]
        let chosenAnswers = [2, 3, 3, 1]
        if (this.state.scores.hasOwnProperty(student.id)) {
            chosenAnswers = this.state.scores[student.id]
        }
        let finalGrade = 0
        let highestDOKLevel = 1
        for (let i = 0; i < correctAnswers.length; i++) {
            if (correctAnswers[i] === chosenAnswers[i]) {
                finalGrade += 25
                highestDOKLevel = parseInt(questions[i].dokLevel, 10)
            }
        }
        if (highestDOKLevel !== dokLevel) {
            return false
        }

        return <tr key={'tr-' + student.id}>
            <td>{student.firstName + ' ' + student.lastName}</td>
            <td>
                {assessmentCompleted ?
                    <div className='text-success font-bold'>
                        Completed
                    </div> :
                    <div className='text-blue font-bold'>In progress</div>
                }
            </td>
            <td>
                <div className='flex'>
                    {chosenAnswers.map((answerIdx, index) => {
                        let allCorrect = chosenAnswers[index] === correctAnswers[index]
                        let borderClasses = ' border-right border-top border-bottom'
                        if (index === 0) {
                            borderClasses += ' border-left br-tl-4 br-bl-4'
                        } else if (index === correctAnswers.length - 1) {
                            borderClasses += ' br-tr-4 br-br-4'
                        }
                        return <div className={'p-1' + borderClasses} key={'assessment-' + student.id + index}>
                            {chosenAnswers.length > 0 ?
                                allCorrect ?
                                    <Icon type='check' className='text-success' /> :
                                    <Icon type='close' className='text-danger' /> :
                                '-'}
                        </div>
                    })}
                </div>
            </td>
            <td>{finalGrade + '%'}</td>
        </tr>
    }

    render() {
        let questions = []
        if (this.state.lessonPlan && this.state.lessonPlan.assessment.length > 4) {
            questions = [
                this.state.lessonPlan.assessment[0],
                this.state.lessonPlan.assessment[1],
                this.state.lessonPlan.assessment[4],
                this.state.lessonPlan.assessment[2],
            ]
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout className="mt-0">
                        <Content className="mt-0">

                            <div className="sub-menu-width-larger m-lr-auto mt-4">
                                <div className='relative mb-2'>
                                    <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                        style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                    <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                        <div>
                                            <div className='font-40 font-bold font-fff'>{'ELA.3.AOR.6.1.a Quiz Results'}</div>
                                        </div>
                                    </div>
                                </div>
                                <Row gutter={48}>
                                    <Col span={10}>
                                        <div className='mt-2 pr-3 border-right-light'>
                                            {questions.map((question, questionIndex) => {
                                                if (questionIndex > 3) {
                                                    return false
                                                }
                                                return <div className={questionIndex > 0 ? 'mt-3' : ''} key={questionIndex}>
                                                    <div className='font-20 font-bold'>
                                                        {'Question ' + (questionIndex + 1) + ' (DOK ' + (question.dokLevel) + ')'}
                                                    </div>
                                                    <div className='font-black font-20'>
                                                        <ComponentFactory component={question} />
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </Col>
                                    <Col span={14}>

                                        <table className='w-100 font-20 results-table'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Progress</th>
                                                    <th>Questions {'1-4'}</th>
                                                    <th>Grade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold br-tl-4 br-bl-4'>DOK 1</td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold br-tr-4 br-br-4'></td>
                                                </tr>
                                                {this.props.teacherBaseProps.students &&
                                                    this.props.teacherBaseProps.students.map((student, studentIndex) => {
                                                        return this.resultsForDOKLevel(student, questions, 1)
                                                    })}
                                                <tr>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold br-tl-4 br-bl-4'>DOK 2</td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold br-tr-4 br-br-4'></td>
                                                </tr>
                                                {this.props.teacherBaseProps.students &&
                                                    this.props.teacherBaseProps.students.map((student, studentIndex) => {
                                                        return this.resultsForDOKLevel(student, questions, 2)
                                                    })}
                                                <tr>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold br-tl-4 br-bl-4'>DOK 3</td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold'></td>
                                                    <td className='background-dark-blue pb-1 font-fff font-bold br-tr-4 br-br-4'></td>
                                                </tr>
                                                {this.props.teacherBaseProps.students &&
                                                    this.props.teacherBaseProps.students.map((student, studentIndex) => {
                                                        return this.resultsForDOKLevel(student, questions, 3)
                                                    })}

                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default PacingGuideTeacherAssessment
