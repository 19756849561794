import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
import {
  flattenDoc, getIDFromURL, capitalizeFirstChar, getTotalPoints,
  getQueryStringParam, createIEPGoalText, getTotalPointsBaselineOrTarget,
} from '../.././Util'
import { Layout, Icon, Button, Form, Row, Col, message, Select } from 'antd'
import DraftStepContainer from './DraftStepContainer'
import StandardNumber from '../../customcomponents/StandardNumber'
const { Content } = Layout
const Option = Select.Option
const FormItem = Form.Item

const errorMessage = (description) => {
  message.destroy()
  message.error(description, 10)
}

class TeacherAddIEPGoalProgressMonitoring extends Component {
  state = {
    teacherId: null,
    studentId: null,
    teacher: null,
    iep: null,
    iepParagraph: '',
    totalPoints: null,
    students: [],
    iepDrafts: [],
    iepDraft: null,
    IEPScoreSelections: {},
    tabKey: '1',
    stepNum: 4,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var iepId = getQueryStringParam('iep')
    var draftId = getQueryStringParam('draft')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      iepId: iepId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.iepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          var draft = flattenDoc(doc)
          this.setState({
            iepDraft: draft,
            iepSelections: draft.iepSelectionsNoCount,
            ieps: draft.ieps,
            iepDraftMounted: true,
          }, () => {
            var iepDraft = this.state.iepDraft
            var obj = {}
            var IEPScoreSelections = {}
            iepDraft.ieps.map((iep, index) => {
              if (iep.baselineAccuracyLevel !== '<number>') {
                obj[iep.id + '-baseline'] = iep.baselineAccuracyLevel
              }
              if (iep.targetAccuracyLevel !== '<number>') {
                obj[iep.id + '-target'] = iep.targetAccuracyLevel
              }

              let totalPoints = getTotalPoints(iep)
              let scoreSelections = []
              // if the total points are > 100, just do 0,1,2...100
              // else do sections based on i / totalPoints
              if (totalPoints >= 100) {
                for (let i = 0; i < 101; i++) {
                  scoreSelections.push('' + i)
                }
              } else {
                for (let i = 0; i < totalPoints; i++) {
                  let value = ((i / totalPoints) * 100).toFixed(0)
                  scoreSelections.push(value)
                }
                // make sure there is a score of 80 in the list
                let scoreOfEighty = '80'
                if (!scoreSelections.includes(scoreOfEighty)) {
                  scoreSelections.push(scoreOfEighty)
                  scoreSelections.sort((a, b) => a - b)
                }
                // add 100 as the last score
                scoreSelections.push('100')
              }
              IEPScoreSelections[iep.id] = scoreSelections

              return false
            })

            this.setState({
              IEPScoreSelections: IEPScoreSelections,
            })

            if (Object.keys(obj).length > 0) {
              this.props.form.setFieldsValue(obj)
            }
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)
        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .doc(studentId)
      .get()
      .then((doc) => {
        var student = flattenDoc(doc)

        this.setState({
          student: student,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  progressMonitoringChange = (newValue, iep, index) => {
    //console.log('val = ' + newValue, ', idx = ' + index)
    // set the number in the progress monitoring to the new value
    iep.progressMonitoring[index].num = newValue
    iep.totalPoints = getTotalPoints(iep)
    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  serviceLocationChange = (value, options) => {
    this.setState({
      serviceLocation: value,
    })
  }

  progressMonitoringChange = (newValue, iep, index) => {
    console.log('val = ' + newValue, ', idx = ' + index)
    // set the number in the progress monitoring to the new value
    iep.progressMonitoring[index].num = newValue
    iep.totalPoints = getTotalPoints(iep)
    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  baselineValueChange = (newValue, iep, index) => {
    var totalPoints = newValue
    iep.baselineAccuracyLevel = totalPoints
    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  targetValueChange = (newValue, iep, index) => {
    var totalPoints = newValue
    iep.targetAccuracyLevel = totalPoints
    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  baselineMonitoringChange = (newValue, iep, index) => {
    //console.log('baseline val = ' + newValue, ', idx = ' + index)
    // set the number in the progress monitoring to the new value
    iep.baselineProgressMonitoring[index].num = newValue
    var totalPoints = getTotalPointsBaselineOrTarget(iep.baselineProgressMonitoring)
    iep.baselineAccuracyLevel =
      (totalPoints /
        iep.totalPoints * 100).toFixed(0)
    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    var obj = {
      [iep.id + '-baseline-' + index]: newValue,
      [iep.id + '-baseline']: totalPoints,
    }

    this.props.form.setFieldsValue(obj)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  targetMonitoringChange = (newValue, iep, index) => {
    //console.log('baseline val = ' + newValue, ', idx = ' + index)
    // set the number in the progress monitoring to the new value
    iep.targetProgressMonitoring[index].num = newValue
    var totalPoints = getTotalPointsBaselineOrTarget(iep.targetProgressMonitoring)
    iep.targetAccuracyLevel =
      (totalPoints /
        iep.totalPoints * 100).toFixed(0)
    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    var obj = {
      [iep.id + '-target-' + index]: newValue,
      [iep.id + '-target']: totalPoints,
    }

    this.props.form.setFieldsValue(obj)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  baselineOrTargetChange = (newValue, iep, baselineOrTarget) => {
    //console.log(baselineOrTarget, newValue)

    if (baselineOrTarget === 'baseline') {
      iep.baselineAccuracyLevel = newValue
      this.props.form.setFieldsValue({ [iep.id + '-baseline']: newValue })
    } else {
      iep.targetAccuracyLevel = newValue
      this.props.form.setFieldsValue({ [iep.id + '-target']: newValue })
    }

    iep.iepParagraph = createIEPGoalText(iep, this.state.student, iep.numProgressMonitoringScores)

    this.setState({
      ieps: this.state.ieps,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('received values from form', values)

        for (var i = 0; i < this.state.ieps.length; i++) {
          var iep = this.state.ieps[i]
          if (parseInt(iep.baselineAccuracyLevel, 10) >= parseInt(iep.targetAccuracyLevel, 10)) {
            errorMessage('Baseline accuracy must be less than target accuracy.')
            return
          }
        }

        var newState = this.state.iepDraft
        newState.ieps = this.state.ieps
        newState.step = { stepNum: this.state.stepNum + 1, path: 'service' }

        db.collection(ColType.iepDrafts)
          .doc(this.state.draftId)
          .set(newState, { merge: true })
          .then(() => {
            this.props.history.push(
              {
                pathname: '/teacher/add-goal-service/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId +
                  '&draft=' + this.state.draftId,
                state: newState, // pass state to confirm page
              }
            )
          })
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={this.state.draftId && this.state.iepDraftMounted}
                >
                  <div>
                    {this.state.draftId && this.state.iepDraftMounted ?
                      <div>


                        <div className="w-800 m-lr-auto background-fff br-15">
                          <div className='p-4 bkg-purple br-15'>
                            <div className='flex flex-v-center w-100'>
                              <div>
                                {this.props.teacherBaseProps &&
                                  this.props.teacherBaseProps.studentDict &&
                                  this.props.teacherBaseProps.studentDict
                                    .hasOwnProperty(this.state.studentId)
                                  ?
                                  <div className="flex flex-v-center mb-0 font-24">
                                    <div>
                                      <div className='font-20 font-fff'>
                                        IEP Draft for {this.props.teacherBaseProps
                                          .studentDict[this.state.studentId].firstName + ' ' +
                                          this.props.teacherBaseProps
                                            .studentDict[this.state.studentId].lastName}
                                      </div>
                                      <h1 className="font-40 mb-0 font-fff font-bold">
                                        Baseline and Target Levels
                                      </h1>
                                    </div>
                                  </div>
                                  : ''}
                              </div>
                              <img src="/draft-icons/5.png" alt='draft-iep' width={250} className='ml-auto' />
                            </div>
                          </div>

                          <div className='p-4'>
                            <div>
                              <Form onSubmit={this.handleSubmit}>
                                {this.state.iepDraft.subject.map((subject, subjectIndex) => {
                                  var ieps = this.state.ieps.filter(iep => iep.mainSubject === subject)
                                  return <div className="mb-3" key={'baseline-target-block-' + subjectIndex}>
                                    <h2 className="mb-3 pb-1 border-bottom font-30">
                                      {subject}</h2>
                                    {ieps.map((iep, index) => {
                                      return <Row gutter={64} className="mb-1 font-16"
                                        key={"iep-row-" + iep.id}
                                      >
                                        <Col span={24} className="mb-2">
                                          <h3 className="font-bold font-20 mb-2">
                                            <StandardNumber
                                              district={this.props.teacherBaseProps.district}
                                              iep={iep}
                                              className='mr-0'
                                              containerClassName={'pl-2 pr-2 pt-1 pb-1 mr-1 br-100 font-20'}
                                              containerStyle={{ backgroundColor: '#e8e8e8' }}
                                            />
                                            <span>{capitalizeFirstChar(iep.standardDescription)}</span></h3>
                                          <div className="mb-1 font-20 font-black">
                                            {iep.iepParagraph}
                                          </div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="mb-2">
                                            <FormItem {...formItemLayout} label={<div>
                                              <div className='font-20 font-bold'>
                                                Baseline (%)
                                              </div>
                                              <div className='font-16 font-normal'>
                                                Choose the score that best represents the student's current performance.
                                              </div>
                                            </div>}
                                              className="block-label">
                                              {getFieldDecorator(iep.id + '-baseline', {
                                                rules: [{ required: true, message: 'Required' }],
                                              })(
                                                <Select size={"large"}
                                                  placeholder={"Select baseline score..."}
                                                  onChange={(e) => this.baselineValueChange(e, iep, 0)}
                                                >
                                                  {this.state.IEPScoreSelections.hasOwnProperty(iep.id) &&
                                                    this.state.IEPScoreSelections[iep.id].map((value, idx) => {
                                                      return <Option value={value} key={'iep-baseline-' + iep.id + '-' + idx}>
                                                        {value}%
                                                      </Option>
                                                    })}
                                                </Select>
                                              )}
                                            </FormItem>
                                          </div>

                                        </Col>
                                        <Col span={12}>
                                          <div>
                                            <FormItem {...formItemLayout} label={<div>
                                              <div className='font-20 font-bold'>
                                                Target (%)
                                              </div>
                                              <div className='font-16 font-normal'>
                                                Choose the option that best represents the target score for this goal.
                                              </div>
                                            </div>}
                                              className="block-label">
                                              {getFieldDecorator(iep.id + '-target', {
                                                rules: [{ required: true, message: 'Required' }],
                                              })(
                                                <Select size={"large"}
                                                  placeholder={"Select target score..."}
                                                  onChange={(e) => this.targetValueChange(e, iep, 0)}
                                                >
                                                  {this.state.IEPScoreSelections.hasOwnProperty(iep.id) &&
                                                    this.state.IEPScoreSelections[iep.id].map((value, idx) => {
                                                      return <Option value={value} key={'iep-target-' + iep.id + '-' + idx}>
                                                        {value}%
                                                      </Option>
                                                    })}
                                                </Select>
                                              )}
                                            </FormItem>
                                          </div>
                                        </Col>

                                      </Row>
                                    })}
                                  </div>
                                })
                                }
                                {this.state.serviceLocation === 'Special Education' ?
                                  ''
                                  :
                                  ''
                                }

                                <div className="mb-0 flex flex-v-center">
                                  {this.state.teacherId && this.state.studentId && this.state.draftId ?
                                    <Link
                                      to={'/teacher/add-goal-modify/' +
                                        this.state.teacherId + '?student=' +
                                        this.state.studentId +
                                        '&draft=' + this.state.draftId}
                                      className="font-18"
                                    >
                                      <span className="left-hover-parent text-cyan">
                                        <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                        <span>Previous Step</span>
                                      </span>
                                    </Link>
                                    : ''}
                                  <Button
                                    type="primary"
                                    size={'large'}
                                    htmlType="submit"
                                    className="lg-btn ml-auto"
                                  >
                                    Continue
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>


                      </div>
                      : ''}

                  </div>
                </DraftStepContainer>
              </div>

            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalProgressMonitoring)