import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase } from '../firebase/Firebase'
import { Icon, Form, Input, Button, message } from 'antd'
const FormItem = Form.Item

const errorMessage = (description) => {
  // stays permanently, I'm destroying when form is submitted.
  message.error(description, 0)
}

// This page can only be accessed by us, it is how you add admins that then can add teachers.
class ForgotPassword extends Component {
  state = {
    user: {},
    admin: {},
    sendingEmail: false,
    submitted: false,
  }

  componentDidMount() {
    document.title = "Forgot password - Dot It"

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log("signed in user", user)
      } else {
        console.log("signed out user", user)
        // User is signed out.
        this.setState({
          user: user,
        })
      }
    })
  }


  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      message.destroy() // destroy previous error messages on form submit.
      if (!err) {
        console.log('Received values of form: ', values)

        this.setState({
          sendingEmail: true,
        }, () => {
          firebase.auth().sendPasswordResetEmail(values.email).then(() => {
            // Email sent.
            this.setState({
              sendingEmail: false,
              submitted: true,
            })
          }).catch((error) => {
            // An error happened.
            console.log("error")

            this.setState({
              sendingEmail: false,
            }, () => {
              errorMessage("Something went wrong, please try again. " +
                "If this keeps happening please send us an email.")
            })
          })
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <div className="h-100 pt-4 pb-70 relative" style={{ minHeight: '100%', height: 'auto' }}>
        <img src="/sign-in-banner.png" alt="sign-in" className='absolute-tl w-100 h-100'
          style={{ zIndex: '0', objectFit: 'cover' }} />

        <div className='relative'>
          <div className="text-center mb-3">
            <Link to="/sign-in">
              <img src='/dotitlogowhite.png' alt='logo' height='64' />
            </Link>
          </div>
          <div className="g-col p-4 w-500 m-lr-auto br-15 ant-shadow-more">
            <div className="block w-100 m-lr-auto mb-3 text-center">
              <img src="/questions-icon.png" alt="sign-in" height="300" />
            </div>
            {this.state.submitted ?
              <div>
                <div className="mb-0 text-center font-30 font-bold">Email sent</div>
                <div className="font-18 text-center">An email has been sent to reset your password. Check your spam folder if the email is not appearing in your inbox.</div>
              </div>
              :
              <div>
                <div className="mb-0 text-center font-30 font-bold">Forgot your password?</div>
                <div className="mb-3 font-18 text-center">We'll send you an email so that you can reset it.</div>
                <Form onSubmit={this.handleSubmit}>
                  <FormItem {...formItemLayout} label="Email address">
                    {getFieldDecorator('email', {
                      rules: [{ required: true, message: 'Please input your email address.' }],
                    })(
                      <Input
                        size={"large"}
                        placeholder="Email Address..." />
                    )}
                  </FormItem>
                  <FormItem className="mb-0">
                    <Button
                      disabled={this.state.sendingEmail}
                      size={"large"}
                      type="primary"
                      htmlType="submit"
                      className="full-width-btn mt-05">
                      {!this.state.sendingEmail ?
                        <span>Send me an email</span> :
                        <span><Icon type="loading" className="mr-8" />Sending...</span>
                      }
                    </Button>
                  </FormItem>
                </Form>
              </div>}
          </div>
        </div>
      </div>
    )
  }
}

export default Form.create()(ForgotPassword)