import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import { DragDropContextProvider } from 'react-dnd'
//import HTML5Backend from 'react-dnd-html5-backend'
//import PersonHeader from '../../login/PersonHeader'
import { Store } from './Store'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import CustomFooter from '../../login/CustomFooter'
import Empty from '../.././customcomponents/Empty'
import EventDrag from './EventDrag'
import SchedulerDayDrags from './SchedulerDayDrags'
import SchedulerDayDropOuter from './SchedulerDayDropOuter'
import SchedulerDayDropInner from './SchedulerDayDropInner'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import ReactToPrint from "react-to-print"
import { getIDFromURL } from '../.././Util'
import { Layout, Icon, Menu, message, Button, Modal, Checkbox } from 'antd'
import TeacherGroupLessonPlanProp from '../reports/TeacherGroupLessonPlanProp'
import AddCoachesNoteForm from './AddCoachesNoteForm'
import CoachesNoteDisplay from './CoachesNoteDisplay'
const { Content, Sider } = Layout
const SubMenu = Menu.SubMenu
var moment = require('moment')

class Scheduler extends Component {
  constructor(props) {
    super(props)
    this.coachesNoteHeaderRef = React.createRef()
    this.state = {
      timeStep: 15,       // minutes
      timeRatio: 60 / 15, // hours / minutes
      hours: 10,          // time duration from start to end (7 AM - 5 PM)
      mondayTimes: [],
      events: [],
      IEPScheduled: {},
      timeSlots: Array(12).fill(0),
      dragProps: null,
      isDragging: false,
      isCopyAndPasting: false,
      groupModalVisible: false,
      groupPlanEvent: null,
      storeKey: 'schedule',
      shouldScroll: false,
      onlyShowStudentsWithPlans: false,
    }
  }

  copyPasteMessage = () => {
    message.destroy()
    message.info(<div className="p-4">
      <h2>Calendar event is copied, click on the schedule to paste it.</h2>
      <Button
        size={'large'}
        type={'danger'}
        onClick={() => Store[this.state.storeKey].setIsCopyPasting(false)}
      >
        <Icon type="close" className="font-white" /> All done copy and pasting
      </Button>
    </div>, 0)
  }

  modalCoachesNotesScrollIntoView = () => {
    // called in coachesnotedisplay's onLoad prop
    // wait for ant-modal-wrap to be created if this is the first
    // time the modal has opened
    setTimeout(() => {
      if (!this.state.shouldScroll) {
        return
      }
      let elements = document.getElementsByClassName('ant-modal-wrap')
      if (this.coachesNoteHeaderRef.current && elements && elements.length > 0) {
        // the y value scrolls to the top of the notes header
        let modalContainer = elements[0]
        let yOffset = -10
        const y = this.coachesNoteHeaderRef.current.getBoundingClientRect().top + yOffset

        modalContainer.scrollTo({ top: y, behavior: 'smooth' })

        this.setState({
          shouldScroll: false,
        })
      }
    }, 1)
  }

  handleShowEvent = (componentProps) => {
    let showEvent = componentProps.teacherBaseProps.showEvent
    if (showEvent) {
      if (componentProps.teacherBaseProps.eventDict &&
        componentProps.teacherBaseProps.eventDict[showEvent]) {
        this.setState({
          shouldScroll: true,
        }, () => {
          this.setGroupModalVisibility(true, componentProps.teacherBaseProps.eventDict[showEvent])
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    Store[this.state.storeKey].events = nextProps.teacherBaseProps.events

    this.handleShowEvent(nextProps)
  }

  componentDidMount() {
    document.title = 'Practice - Dot It'
    // preload images
    /**var imgGEDrag = new Image()
    addClass(imgGEDrag, 'display-none')
    imgGEDrag.src = '/ge-drag.png'
    var imgSEDrag = new Image()
    addClass(imgSEDrag, 'display-none')
    imgSEDrag.src = '/se-drag.png'*/

    // populate store values, in the future if you have more than one
    // scheduler at a time pass in the storeKey as props.
    console.log('store', Store)
    console.log('storeKey', this.state.storeKey)
    Store[this.state.storeKey] = {}
    Store[this.state.storeKey].dragProps = null
    Store[this.state.storeKey].dropProps = null
    Store[this.state.storeKey].isCopyAndPasting = false
    Store[this.state.storeKey].setIsCopyPasting = (newValue) => {
      if (newValue) {
        Store[this.state.storeKey].isCopyAndPasting = true
        this.copyPasteMessage()

        setTimeout(() => {
          this.setState({
            isCopyAndPasting: newValue,
          })
        })
      }
      else {
        Store[this.state.storeKey].isCopyAndPasting = false
        Store[this.state.storeKey].dragProps = null
        message.destroy()

        setTimeout(() => {
          this.setState({
            isCopyAndPasting: newValue,
          })
        })
      }
    }
    Store[this.state.storeKey].events = this.props.teacherBaseProps.events

    var mondayStart = moment.utc(new Date(Date.UTC(2015, 5, 1, 7, 30, 0)))
    var tuesdayStart = moment.utc(new Date(Date.UTC(2015, 5, 2, 7, 30, 0)))
    var wednesdayStart = moment.utc(new Date(Date.UTC(2015, 5, 3, 7, 30, 0)))
    var thursdayStart = moment.utc(new Date(Date.UTC(2015, 5, 4, 7, 30, 0)))
    var fridayStart = moment.utc(new Date(Date.UTC(2015, 5, 5, 7, 30, 0)))
    var mondayTimes = [],
      tuesdayTimes = [],
      wednesdayTimes = [],
      thursdayTimes = [],
      fridayTimes = []

    for (var i = 0; i <= this.state.timeRatio * this.state.hours; i++) {
      var newMondayTime = moment.utc(mondayStart)
      var newTuesdayTime = moment.utc(tuesdayStart)
      var newWednesdayTime = moment.utc(wednesdayStart)
      var newThursdayTime = moment.utc(thursdayStart)
      var newFridayTime = moment.utc(fridayStart)

      mondayTimes.push(newMondayTime)
      tuesdayTimes.push(newTuesdayTime)
      wednesdayTimes.push(newWednesdayTime)
      thursdayTimes.push(newThursdayTime)
      fridayTimes.push(newFridayTime)

      mondayStart = mondayStart.add(this.state.timeStep, "minutes")
      tuesdayStart = tuesdayStart.add(this.state.timeStep, "minutes")
      wednesdayStart = wednesdayStart.add(this.state.timeStep, "minutes")
      thursdayStart = thursdayStart.add(this.state.timeStep, "minutes")
      fridayStart = fridayStart.add(this.state.timeStep, "minutes")
    }

    this.setState({
      mondayTimes: mondayTimes,
      tuesdayTimes: tuesdayTimes,
      wednesdayTimes: wednesdayTimes,
      thursdayTimes: thursdayTimes,
      fridayTimes: fridayTimes,
    })

    var teacherId = getIDFromURL(window.location)

    this.setState({
      teacherId: teacherId,
    })

    this.handleShowEvent(this.props)
  }

  setDragProps = (newDragProps) => {
    this.setState({
      dragProps: newDragProps
    })
  }

  getDragProps = () => {
    return this.state.dragProps
  }

  setIsDragging = (isDragging) => {
    setTimeout(() => {
      this.setState({
        isDragging: isDragging,
      })
    })
  }

  setGroupModalVisibility = (visible, event) => {
    if (visible) {
      this.setState({
        groupModalVisible: visible,
        groupPlanEvent: event,
      })
    } else {
      // don't clear event as it will appear when the modal is closing
      // when the modal is visible again a new event will be set and will
      // change the display.
      this.setState({
        groupModalVisible: visible,
      })
    }
  }

  render() {
    let hideSideNavbar = this.props.hasOwnProperty("hideSideNavbar") ||
      (this.props.hasOwnProperty("hideSideNavbar") &&
        this.props.hideSideNavbar)
    return (
      <div>
        <Layout className="layout-header-mt mt-0 min-w-1024">
          <div className="display-none">
            <img alt="preload-ge-drag" src="/teacher-drag-circle.PNG"
              height="0" width="0" className="display-none" />
            <img alt="preload-ge-drag" src="/ge-drag-circle.PNG"
              height="0" width="0" className="display-none" />
            <img alt="preload-de-drag" src="/se-drag-circle.PNG"
              height="0" width="0" className="display-none" />
          </div>
          {this.props.teacherBaseProps.teacher ?
            !hideSideNavbar ?
              <Sider
                width={270}
                style={{
                  overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 82px)',
                  position: 'fixed',
                  left: 0, marginTop: '82px', borderRight: '1px solid #f1f1f1',
                  background: '#fff', borderTop: '1px solid #f1f1f1'
                }}>
                <div>
                  <div>

                    <div className="pb-3 border-bottom">
                      <div className="pb-3 border-bottom">
                        <h3 className="mt-3 mb-0 pl-2 pr-2 font-bold font-18 br-4">
                          Schedule teacher event
                        </h3>
                        <EventDrag
                          dragName={'teacher'}
                          iep={null}
                          readOnly={this.props.teacherBaseProps.readOnly}
                          servicedIn={'Teacher Event'}
                          student={null}
                          teacher={this.props.teacherBaseProps.teacher}
                          schedulerState={this.state}
                          IEPScheduled={this.props.teacherBaseProps.IEPScheduled}
                          dayStart={moment.utc(new Date(
                            Date.UTC(2015, 5, 1, 6, 0, 0))).format()}
                          dayEnd={moment.utc(new Date(
                            Date.UTC(2015, 5, 1, 17, 0, 0))).format()}
                          setDragProps={this.setDragProps}
                          setIsDragging={this.setIsDragging}
                          key={'teacher-drag-event'}
                        />
                      </div>
                      <h3 className="mt-2 mb-12px pl-2 pr-2 font-bold font-18 br-4">
                        Schedule services
                      </h3>
                      <Checkbox className='flex flex-v-center pl-2 pr-2 mb-1'
                        checked={this.state.onlyShowStudentsWithPlans}
                        onChange={(e) => {
                          this.setState({
                            onlyShowStudentsWithPlans: e.target.checked
                          })
                        }}>
                        <span className='font-16 ml-1 font-black'>Show students with plans</span>
                      </Checkbox>
                      {this.props.teacherBaseProps.IEPGoals &&
                        this.props.teacherBaseProps.IEPGoals.length > 0 ?
                        <div className="">

                        </div>
                        :
                        <h2 className="p-2">
                          {<div>
                            <Empty
                              containerClassName="mt-4 pt-2 text-center"
                              width={55}
                              height={55}
                            />
                          </div>
                          }
                        </h2>
                      }
                      <Menu
                        mode="inline"
                        theme='light'
                        className="menu-large border-right-none"
                      >
                        {this.props.teacherBaseProps.nonArchivedGoals &&
                          this.props.teacherBaseProps.nonArchivedGoals.length > 0 &&
                          this.props.teacherBaseProps.grades &&
                          this.props.teacherBaseProps.students.map((student, index) => {
                            var studentIEPGoals =
                              this.props.teacherBaseProps.nonArchivedGoals.filter(e => e.studentId === student.id)
                            let numGoals = 0
                            if (this.props.teacherBaseProps.studentIEPDict &&
                              this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(student.id)) {
                              numGoals = this.props.teacherBaseProps.studentIEPDict[student.id].length
                            }
                            if (this.state.onlyShowStudentsWithPlans && numGoals === 0) {
                              return false
                            }
                            return <SubMenu key={student.id} title={
                              <span className="font-20">
                                <div className="font-16 flex flex-center"
                                  id={'student-avatar-' + student.id}
                                >
                                  <PersonAvatar
                                    person={student}
                                    size={'large'}
                                    containerClassName="font-18"
                                    avatarClassName="font-18 font-bold mr-1"
                                    personClassName="font-bold font-black"
                                  />
                                </div>
                              </span>
                            }
                            >

                              <div className="pl-2 pr-2">
                                {studentIEPGoals.map((iep, index) => {
                                  return <EventDrag
                                    dragName={'iep'}
                                    iep={iep}
                                    readOnly={this.props.teacherBaseProps.readOnly}
                                    servicedIn={iep.iep.service.servicedIn}
                                    student={student}
                                    teacher={this.props.teacherBaseProps.teacher}
                                    schedulerState={this.state}
                                    IEPScheduled={this.props.teacherBaseProps.IEPScheduled}
                                    dayStart={moment.utc(new Date(
                                      Date.UTC(2015, 5, 1, 6, 0, 0))).format()}
                                    dayEnd={moment.utc(new Date(
                                      Date.UTC(2015, 5, 1, 17, 0, 0))).format()}
                                    setDragProps={this.setDragProps}
                                    setIsDragging={this.setIsDragging}
                                    key={'student-iep-goal-' + iep.id}
                                  />
                                })
                                }
                              </div>

                            </SubMenu>
                          })

                        }
                      </Menu>
                    </div>
                  </div>
                  <div className="p-2 inline-block w-100">
                    <h3 className="mb-2 pl-2 pr-2 font-bold font-18 br-4">
                      Print schedule
                    </h3>
                    <ReactToPrint
                      trigger={() =>
                        <Button
                          size={'large'}
                          className="inline-block w-100 up-hover"
                        >
                          <Icon type="printer" />
                          Print
                        </Button>
                      }
                      content={() => this.componentRef}
                    />
                  </div>
                </div>
              </Sider> : '' : ''}
          <Layout style={hideSideNavbar ?
            {} :
            { marginLeft: 270, marginTop: "68px", overflowX: 'initial' }}>
            <Layout className="content">
              <Content
                className={hideSideNavbar ? "" : "layout-content background-fff p-3 pt-0 br-4 print-remove-padding print-remove-margin"}
                ref={el => (this.componentRef = el)}
              >
                {this.props.teacherBaseProps.teacher ?
                  <div>
                    <Modal
                      onCancel={() => this.setGroupModalVisibility(false, null)}
                      visible={this.state.groupModalVisible}
                      footer={null}
                      className="screen-overlay-modal"
                      maskStyle={{ animationDuration: "0s", transitionDuration: "0s" }}
                    >
                      {this.state.groupPlanEvent ?
                        <div>
                          <div className="pl-4 pr-4 pt-4">
                            <div className="font-30 font-bold">
                              {this.state.groupPlanEvent.title}
                            </div>
                            <h2 className={"font-18 font-bold" + (this.state.groupPlanEvent.servicedIn === "Teacher Event" ? " mb-2" : " mb-0")}>
                              <div className="font-18 text-left">
                                {moment.utc(this.state.groupPlanEvent.startTime.seconds * 1000).format("hh:mm A").replace(/^(?:00:)?0?/, '') +
                                  ' - ' + moment.utc(this.state.groupPlanEvent.startTime.seconds * 1000)
                                    .add(this.state.groupPlanEvent.duration, 'minutes')
                                    .format("hh:mm A").replace(/^(?:00:)?0?/, '')
                                }
                              </div>
                            </h2>
                            {this.state.groupPlanEvent.servicedIn !== "Teacher Event" ?
                              <div className="font-18 font-bold mb-2 flex flex-v-center">
                                <div>
                                  <span className="font-black" style={{ marginRight: '6px' }}>Setting:</span>
                                  {this.state.groupPlanEvent.servicedIn === 'General Education' ?
                                    <span className="text-ge">
                                      General Education
                                    </span>
                                    : ''}

                                  {this.state.groupPlanEvent.servicedIn === 'Special Education' ?
                                    <span className="text-se">
                                      Special Education
                                    </span>
                                    : ''}
                                </div>
                              </div> : ''}
                          </div>
                          <TeacherGroupLessonPlanProp
                            teacherBaseProps={this.props.teacherBaseProps}
                            event={this.state.groupPlanEvent}
                          />
                          <div className="pl-4 pr-4 pb-4 mt-3 pt-3 border-top">
                            <div className="font-24 font-bold mb-3" ref={this.coachesNoteHeaderRef}>
                              Notes
                            </div>
                            <AddCoachesNoteForm
                              teacherBaseProps={this.props.teacherBaseProps}
                              event={this.state.groupPlanEvent}
                              admin={this.props.hasOwnProperty('admin') &&
                                this.props.admin ?
                                this.props.admin :
                                null}
                            />
                          </div>
                          <div className="pl-4 pr-4 pb-4 mt-2">
                            <CoachesNoteDisplay
                              teacherBaseProps={this.props.teacherBaseProps}
                              teacher={this.props.teacherBaseProps.teacher}
                              event={this.state.groupPlanEvent}
                              admin={this.props.hasOwnProperty('admin') &&
                                this.props.admin ?
                                this.props.admin :
                                null}
                              allowDelete={true}
                              onLoad={this.modalCoachesNotesScrollIntoView}
                            />
                          </div>
                        </div>
                        : ''}
                    </Modal>

                    <div className={'relative h-100 br-2' +
                      (this.state.isDragging ? ' is-dragging' : '') +
                      (this.state.isCopyAndPasting ? ' isCopyAndPasting' : '')}>
                      <div>
                        <div className="h-50 flex">
                          <div style={{ width: '50px' }}
                            className="h-50 flex-col">
                            <div className="time-gap"></div>
                            {this.state.mondayTimes.map((mondayTime, rowIndex) => {
                              return <div
                                className={"text-center h-50px relative" +
                                  (mondayTime.minutes() === 45 ? ' border-bottom' : '')}
                                key={'timestamp-' + mondayTime.format()}
                                style={{ height: '75px' }}
                              >
                                <span className="absolute-tl">{rowIndex % 2 === 0 ?
                                  mondayTime.format('hh:mm A') : ''}</span>
                              </div>
                            })
                            }
                          </div>
                          <div className="w-100 h-100">
                            <div className="w-20 inline-block">
                              <div className={"h-100px font-18 border-bottom text-center " +
                                "flex flex-v-center flex-h-center"}>
                                <span className="font-bold font-24">Monday</span>
                              </div>
                              <div className="relative">
                                {this.state.mondayTimes &&
                                  this.state.mondayTimes.map((timeValue, rowIndex) => {
                                    return <SchedulerDayDropOuter
                                      timeValue={timeValue}
                                      readOnly={this.props.teacherBaseProps.readOnly}
                                      key={'monday-outer-' + rowIndex}
                                      additionalClasses={(rowIndex === this.state.mondayTimes.length - 1) ||
                                        (rowIndex === 1) ||
                                        (rowIndex > 0 && (rowIndex + 3) % 4 === 0) ? 'border-bottom' : ''}
                                    >
                                      <SchedulerDayDropInner
                                        timeValue={timeValue}
                                        timeSlots={this.state.timeSlots}
                                        readOnly={this.props.teacherBaseProps.readOnly}
                                      />
                                    </SchedulerDayDropOuter>
                                  })
                                }
                                <SchedulerDayDrags
                                  dayString={'Monday'}
                                  dayNumber={1}
                                  readOnly={this.props.teacherBaseProps.readOnly}
                                  timesArray={this.state.mondayTimes}
                                  timeSlots={this.state.timeSlots}
                                  events={this.props.teacherBaseProps.events}
                                  IEPGoals={this.props.teacherBaseProps.IEPGoals}
                                  IEPDict={this.props.teacherBaseProps.IEPDict}
                                  teacher={this.props.teacherBaseProps.teacher}
                                  studentDict={this.props.teacherBaseProps.studentDict}
                                  setDragProps={this.setDragProps}
                                  setIsDragging={this.setIsDragging}
                                  setGroupModalVisibility={this.setGroupModalVisibility}
                                />
                              </div>
                            </div>
                            <div className="w-20 inline-block">
                              <div className={"h-100px font-18 border-bottom text-center " +
                                "flex flex-v-center flex-h-center"}>
                                <span className="font-bold font-24">Tuesday</span>
                              </div>
                              <div className="relative" style={{ background: '#d2d2d2' }}>
                                {this.state.tuesdayTimes &&
                                  this.state.tuesdayTimes.map((timeValue, rowIndex) => {
                                    return <SchedulerDayDropOuter
                                      timeValue={timeValue}
                                      readOnly={this.props.teacherBaseProps.readOnly}
                                      key={'tuesday-outer-' + rowIndex}
                                      additionalClasses={(rowIndex === this.state.mondayTimes.length - 1) ||
                                        (rowIndex === 1) ||
                                        (rowIndex > 0 && (rowIndex + 3) % 4 === 0) ? 'border-bottom' : ''}
                                    >
                                      <SchedulerDayDropInner
                                        timeValue={timeValue}
                                        timeSlots={this.state.timeSlots}
                                        readOnly={this.props.teacherBaseProps.readOnly}
                                      />
                                    </SchedulerDayDropOuter>
                                  })
                                }
                                <SchedulerDayDrags
                                  dayString={'Tuesday'}
                                  dayNumber={2}
                                  readOnly={this.props.teacherBaseProps.readOnly}
                                  timesArray={this.state.tuesdayTimes}
                                  timeSlots={this.state.timeSlots}
                                  events={this.props.teacherBaseProps.events}
                                  IEPGoals={this.props.teacherBaseProps.IEPGoals}
                                  IEPDict={this.props.teacherBaseProps.IEPDict}
                                  teacher={this.props.teacherBaseProps.teacher}
                                  studentDict={this.props.teacherBaseProps.studentDict}
                                  setDragProps={this.setDragProps}
                                  setIsDragging={this.setIsDragging}
                                  setGroupModalVisibility={this.setGroupModalVisibility}
                                />
                              </div>
                            </div>
                            <div className="w-20 inline-block">
                              <div className={"h-100px font-18 border-bottom text-center " +
                                "flex flex-v-center flex-h-center"}>
                                <span className="font-bold font-24">Wednesday</span>
                              </div>
                              <div className="relative">
                                {this.state.wednesdayTimes &&
                                  this.state.wednesdayTimes.map((timeValue, rowIndex) => {
                                    return <SchedulerDayDropOuter
                                      timeValue={timeValue}
                                      readOnly={this.props.teacherBaseProps.readOnly}
                                      key={'wednesday-outer-' + rowIndex}
                                      additionalClasses={(rowIndex === this.state.mondayTimes.length - 1) ||
                                        (rowIndex === 1) ||
                                        (rowIndex > 0 && (rowIndex + 3) % 4 === 0) ? 'border-bottom' : ''}
                                    >
                                      <SchedulerDayDropInner
                                        timeValue={timeValue}
                                        timeSlots={this.state.timeSlots}
                                        readOnly={this.props.teacherBaseProps.readOnly}
                                      />
                                    </SchedulerDayDropOuter>
                                  })
                                }
                                <SchedulerDayDrags
                                  dayString={'Wednesday'}
                                  dayNumber={3}
                                  readOnly={this.props.teacherBaseProps.readOnly}
                                  timesArray={this.state.wednesdayTimes}
                                  timeSlots={this.state.timeSlots}
                                  events={this.props.teacherBaseProps.events}
                                  IEPGoals={this.props.teacherBaseProps.IEPGoals}
                                  IEPDict={this.props.teacherBaseProps.IEPDict}
                                  teacher={this.props.teacherBaseProps.teacher}
                                  studentDict={this.props.teacherBaseProps.studentDict}
                                  setDragProps={this.setDragProps}
                                  setIsDragging={this.setIsDragging}
                                  setGroupModalVisibility={this.setGroupModalVisibility}
                                />
                              </div>
                            </div>
                            <div className="w-20 inline-block">
                              <div className={"h-100px font-18 border-bottom text-center " +
                                "flex flex-v-center flex-h-center"}>
                                <span className="font-bold font-24">Thursday</span>
                              </div>
                              <div className="relative">
                                {this.state.thursdayTimes &&
                                  this.state.thursdayTimes.map((timeValue, rowIndex) => {
                                    return <SchedulerDayDropOuter
                                      timeValue={timeValue}
                                      readOnly={this.props.teacherBaseProps.readOnly}
                                      key={'thursday-outer-' + rowIndex}
                                      additionalClasses={(rowIndex === this.state.mondayTimes.length - 1) ||
                                        (rowIndex === 1) ||
                                        (rowIndex > 0 && (rowIndex + 3) % 4 === 0) ? 'border-bottom' : ''}
                                    >
                                      <SchedulerDayDropInner
                                        timeValue={timeValue}
                                        timeSlots={this.state.timeSlots}
                                        readOnly={this.props.teacherBaseProps.readOnly}
                                      />
                                    </SchedulerDayDropOuter>
                                  })
                                }
                                <SchedulerDayDrags
                                  dayString={'Thursday'}
                                  dayNumber={4}
                                  readOnly={this.props.teacherBaseProps.readOnly}
                                  timesArray={this.state.thursdayTimes}
                                  timeSlots={this.state.timeSlots}
                                  events={this.props.teacherBaseProps.events}
                                  IEPGoals={this.props.teacherBaseProps.IEPGoals}
                                  IEPDict={this.props.teacherBaseProps.IEPDict}
                                  teacher={this.props.teacherBaseProps.teacher}
                                  studentDict={this.props.teacherBaseProps.studentDict}
                                  setDragProps={this.setDragProps}
                                  setIsDragging={this.setIsDragging}
                                  setGroupModalVisibility={this.setGroupModalVisibility}
                                />
                              </div>
                            </div>
                            <div className="w-20 inline-block">
                              <div className={"h-100px font-18 border-bottom text-center " +
                                "flex flex-v-center flex-h-center"}>
                                <span className="font-bold font-24">Friday</span>
                              </div>
                              <div className="relative border-right">
                                {this.state.fridayTimes &&
                                  this.state.fridayTimes.map((timeValue, rowIndex) => {
                                    return <SchedulerDayDropOuter
                                      timeValue={timeValue}
                                      readOnly={this.props.teacherBaseProps.readOnly}
                                      key={'friday-outer-' + rowIndex}
                                      additionalClasses={(rowIndex === this.state.mondayTimes.length - 1) ||
                                        (rowIndex === 1) ||
                                        (rowIndex > 0 && (rowIndex + 3) % 4 === 0) ? 'border-bottom' : ''}
                                    >
                                      <SchedulerDayDropInner
                                        timeValue={timeValue}
                                        timeSlots={this.state.timeSlots}
                                        readOnly={this.props.teacherBaseProps.readOnly}
                                      />
                                    </SchedulerDayDropOuter>
                                  })
                                }
                                <SchedulerDayDrags
                                  dayString={'Friday'}
                                  dayNumber={5}
                                  readOnly={this.props.teacherBaseProps.readOnly}
                                  timesArray={this.state.fridayTimes}
                                  timeSlots={this.state.timeSlots}
                                  events={this.props.teacherBaseProps.events}
                                  IEPGoals={this.props.teacherBaseProps.IEPGoals}
                                  IEPDict={this.props.teacherBaseProps.IEPDict}
                                  teacher={this.props.teacherBaseProps.teacher}
                                  studentDict={this.props.teacherBaseProps.studentDict}
                                  setDragProps={this.setDragProps}
                                  setIsDragging={this.setIsDragging}
                                  setGroupModalVisibility={this.setGroupModalVisibility}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}
              </Content>
            </Layout>
            {/*<CustomFooter />*/}
          </Layout>
        </Layout>
      </div >
    )
  }
}

export default Scheduler
