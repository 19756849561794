import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import DistrictStats from './../admin/DistrictStats'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Layout, Row, Col, message, Select, Tabs } from 'antd'
import PersonAvatar from '../customcomponents/PersonAvatar'
import SchoolReport from '../admin/SchoolReport'
import SchoolUploads from '../admin/SchoolUploads'
import SchoolPlanningReport from '../admin/SchoolPlanningReport'
import SchoolPracticeReport from '../admin/SchoolPracticeReport'
import SchoolProgressReport from '../admin/SchoolProgressReport'
import TeacherSummary from '../admin/TeacherSummary'
import SAMScoring from '../admin/SAMScoring'
const { Content } = Layout
const TabPane = Tabs.TabPane

const errorMessage = (description) => {
  message.error(description)
}

class SchoolAdminHome extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    schoolAdminId: '',
    selectedId: null,
    teacherId: '',
    studentId: '',
    schoolId: '',
    teacher: null,
    student: null,
    teachers: null,
    students: [],
    studentDict: {},
    IEPGoals: [],
    IEPDict: {},
    calendarEvents: null,
    calendarDict: null,
    events: null,
    studentReportData: {},
    teacherTab: 'schedule',
    loadingData: false,
    loadedData: false,
    readOnly: true,
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Dashboard - Dot It'

    let schoolAdminId = getIDFromURL(window.location)
    let schoolId = getQueryStringParam('s')

    this.setState({
      schoolAdminId: schoolAdminId,
      schoolId: schoolId,
    })

    this.getTeachers()
  }

  componentWillReceiveProps(nextProps) {
    this.getTeachers()
  }

  getTeachers = () => {
    if (this.state.loadedData) {
      return
    }

    if (!(this.props.schoolAdminBaseProps &&
      this.props.schoolAdminBaseProps.schoolAdmin &&
      this.props.schoolAdminBaseProps.schoolAdmin.schoolId &&
      this.props.schoolAdminBaseProps.schoolAdmin)) {
      return
    }

    this.setState({
      loadedData: true,
    })

    db.collection(ColType.teacher)
      .where('districtId', '==', this.props.schoolAdminBaseProps.schoolAdmin.districtId)
      .where('schoolId', '==', this.props.schoolAdminBaseProps.schoolAdmin.schoolId)
      .get()
      .then((querySnapshot) => {
        var teachers = []

        querySnapshot.forEach((doc) => {
          var teacher = flattenDoc(doc)
          if (!(teacher.hasOwnProperty('isVisible') && !teacher.isVisible)) {
            teachers.push(teacher)
          }
        })

        teachers.sort((a, b) => {
          if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
            return 1
          } else {
            return -1
          }
        })

        this.setState({
          teachers: teachers,
          loadingData: false,
        })
      })
  }

  newVisibleChange = (visible) => {
    this.setState({ newPopoverVisible: visible })
  }

  accountVisibleChange = (visible) => {
    this.setState({ accountPopoverVisible: visible })
  }

  signOut = () => {
    firebase.auth().signOut().then(function () {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      window.location.href = "/sign-in"
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/admin/school-summary/' +
          this.props.person.id + '?district=' +
          this.props.person.districtId + '&school=' +
          option.key
      }
    )
  }

  onSchoolOrDistrictSelect = (schoolOrDistrict) => {
    if (this.props.schoolAdminBaseProps.district &&
      this.props.schoolAdminBaseProps.district.id === schoolOrDistrict) {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.schoolAdminBaseProps.schoolAdmin.id
        }
      )
    }
    else {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.schoolAdminBaseProps.schoolAdmin.id + '?s=' + schoolOrDistrict
        }
      )
    }
  }

  teacherClick = (teacher) => {
    this.setState({
      teacher: teacher,
      teacherId: teacher.id
    })

    this.getStudents(teacher)

    window.scroll(0, 0)
  }

  studentClick = (student) => {
    this.setState({
      student: student,
      studentId: student.id,
      studentReportData: {
        teacherId: this.state.teacher.id,
        studentId: student.id,
        teacher: this.state.teacher,
        student: student,
        teachers: this.state.teachers,
        students: this.state.students,
        studentDict: this.state.studentDict,
        // goals for this student
        IEPGoals: this.state.IEPGoals.filter((e) => e.studentId === student.id),
        IEPDict: this.state.IEPDict,
      }
    })

    window.scroll(0, 0)
  }

  onSchoolOrDistrictSelect = (schoolOrDistrict) => {
    this.setState({
      selectedOptionId: schoolOrDistrict
    })

    if (this.props.schoolAdminBaseProps.district &&
      this.props.schoolAdminBaseProps.district.id === schoolOrDistrict) {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.schoolAdminBaseProps.schoolAdmin.id
        }
      )
    }
    else {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.schoolAdminBaseProps.schoolAdmin.id + '?s=' + schoolOrDistrict
        }
      )
    }
  }

  render() {
    return (
      <div>
        <div>
          <Layout className="content relative layout-header-mt">
            <Layout>
              <Content>
                <div>
                  {this.state.schoolAdminId &&
                    this.props.schoolAdminBaseProps.schoolAdmin &&
                    this.props.schoolAdminBaseProps.district &&
                    this.props.schoolAdminBaseProps.school
                    ?
                    <div>
                      <div className="sub-menu-width-vlg m-lr-auto mt-3">
                        <div>
                          <Row gutter={32}>
                            <Col span={18}>
                              <div className='relative'>
                                <img src="/school-background.png" alt="background-img" className="w-100 object-fit-cover absolute-tl br-20 w-100" />
                              </div>

                              <Row>
                                <Col span={24}>
                                  <div className="font-bold mb-50 pb-2 font-40 flex font-fff ml-4"
                                    style={{ marginTop: '150px' }}>
                                    <div>{this.props.schoolAdminBaseProps.school.schoolName}</div>
                                  </div>
                                </Col>
                              </Row>

                              <div className="mt-1 pl-2px pr-2px">
                                <Tabs defaultActiveKey="1" size='large' tabPosition='left'>
                                  <TabPane tab="School" key="1">
                                    <div className="mt-1 pl-2px pr-2px">
                                      <DistrictStats
                                        districtStats={this.props.schoolAdminBaseProps.schoolStats}
                                      />
                                    </div>
                                  </TabPane>
                                  <TabPane tab="SAM Reports" key="sam-report">
                                    <div className="pl-2px pr-2px">
                                      <SAMScoring
                                        districtId={this.props.schoolAdminBaseProps.schoolAdmin.districtId}
                                        schoolId={this.props.schoolAdminBaseProps.schoolAdmin.schoolId}
                                        schoolName={this.props.schoolAdminBaseProps.school.schoolName}
                                      />
                                    </div>
                                  </TabPane>
                                  <TabPane tab="Plan Reports" key="plan-reports">
                                    <SchoolPlanningReport
                                      districtId={this.props.schoolAdminBaseProps.schoolAdmin.districtId}
                                      schoolId={this.props.schoolAdminBaseProps.schoolAdmin.schoolId}
                                      person={this.props.schoolAdminBaseProps.schoolAdmin}
                                      accessLevel={ColType.schoolAdmin}
                                    />
                                  </TabPane>
                                  <TabPane tab="Practice Reports" key="practice-reports">
                                    <SchoolPracticeReport
                                      districtId={this.props.schoolAdminBaseProps.schoolAdmin.districtId}
                                      schoolId={this.props.schoolAdminBaseProps.schoolAdmin.schoolId}
                                      person={this.props.schoolAdminBaseProps.schoolAdmin}
                                      accessLevel={ColType.schoolAdmin}
                                    />
                                  </TabPane>
                                  <TabPane tab="Progress Reports" key="progress-reports">
                                    <SchoolProgressReport
                                      districtId={this.props.schoolAdminBaseProps.schoolAdmin.districtId}
                                      schoolId={this.props.schoolAdminBaseProps.schoolAdmin.schoolId}
                                      person={this.props.schoolAdminBaseProps.schoolAdmin}
                                      accessLevel={ColType.schoolAdmin}
                                    />
                                  </TabPane>
                                  <TabPane tab="Full Reports" key="full-reports">
                                    <SchoolReport
                                      districtId={this.props.schoolAdminBaseProps.schoolAdmin.districtId}
                                      schoolId={this.props.schoolAdminBaseProps.schoolAdmin.schoolId}
                                      person={this.props.schoolAdminBaseProps.schoolAdmin}
                                      accessLevel={ColType.schoolAdmin}
                                    />
                                  </TabPane>
                                  <TabPane tab="Uploads" key="school-uploads">
                                    <SchoolUploads
                                      districtId={this.props.schoolAdminBaseProps.schoolAdmin.districtId}
                                      schoolId={this.props.schoolAdminBaseProps.schoolAdmin.schoolId}
                                      person={this.props.schoolAdminBaseProps.schoolAdmin}
                                      accessLevel={ColType.schoolAdmin}
                                    />
                                  </TabPane>
                                </Tabs>
                              </div>
                            </Col>
                            <Col span={6}>

                              <h1 className='font-24 font-bold mb-1'>Teachers</h1>
                              {this.state.teachers ?
                                <Select
                                  showSearch
                                  placeholder="Search for a teacher"
                                  className='w-100 mb-2'
                                  optionFilterProp="children"
                                  onSelect={(teacherId) => {
                                    if (this.props.schoolAdminBaseProps.schoolAdmin && teacherId) {
                                      this.props.history.push({
                                        pathname: '/school-admin/school-admin-teacher/' + this.props.schoolAdminBaseProps.schoolAdmin.id + '?t=' + teacherId
                                      })
                                    }
                                  }}
                                  size="large"
                                  filterOption={(input, option) => {
                                    let person = option.props.children.props.person
                                    let fullName = person.firstName + ' ' + person.lastName
                                    return fullName.toLowerCase().includes(input.toLowerCase())
                                  }}
                                >
                                  {this.state.teachers.map((teacher, idx) => {
                                    return <Select.Option value={teacher.id} key={'search-' + teacher.id}>
                                      <PersonAvatar
                                        person={teacher}
                                        avatarColor={'#d3adf7'}
                                        avatarTextColor={'#000'}
                                        containerClassName="font-16"
                                        avatarClassName="font-16 font-bold mr-2"
                                        personClassName="font-black font-16"
                                      />
                                    </Select.Option>
                                  })}
                                </Select>
                                : ''}

                              {this.state.teachers && this.state.teachers.length === 0 ?
                                <div className='font-16 font-bold mb-1'>No teachers</div>
                                : ''}
                              {this.state.teachers &&
                                this.state.teachers.map((teacher, idx) => {
                                  return <Link to={'/school-admin/school-admin-teacher/' + this.props.schoolAdminBaseProps.schoolAdmin.id + '?t=' + teacher.id}
                                    key={'right-menu-' + teacher.id}>
                                    <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative w-100 font-16"}>
                                      <PersonAvatar
                                        person={teacher}
                                        size={'large'}
                                        avatarColor={'#d3adf7'}
                                        avatarTextColor={'#000'}
                                        containerClassName="font-20"
                                        avatarClassName="font-20 font-bold mr-2"
                                        personClassName="font-black font-20"
                                      />
                                      <TeacherSummary teacher={teacher} />
                                    </div>
                                  </Link>
                                })}
                            </Col>
                          </Row>


                        </div>
                      </div>

                    </div>
                    : ''}
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
        <CustomFooter />
      </div>
    )
  }
}

export default SchoolAdminHome