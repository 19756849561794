import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { Helmet } from "react-helmet"
import { Layout, BackTop, Spin } from 'antd'
import CustomFooter from '../login/CustomFooter'

export default class Home extends Component {
  state = {
    user: null,
    person: null,
    accessLevel: null,
    loginMounted: false,
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log("signed in user", user)
        this.setState({
          user: user,
        }, () => {
          db.collection(ColType.users)
            .doc(user.uid)
            .get()
            .then((doc) => {
              var userInfo = flattenDoc(doc)
              console.log("userinfo", userInfo)
              if (userInfo.accessLevel === 'superAdmin') {
                window.location.href = '/super-admin/add-initial-admin'
                return
              }

              db.collection(userInfo.accessLevel)
                .doc(userInfo.refId)
                .get()
                .then((doc) => {
                  var userJobInfo = flattenDoc(doc)
                  console.log(userInfo, userJobInfo)
                  linkAfterLogin(userInfo, userJobInfo)
                })

            })
        })
      } else {
        console.log("signed out user", user)
        // User is signed out.
        this.props.history.push(
          {
            pathname: '/sign-in/'
          }
        )
      }
    })
  }

  scrollToPreviewVideo() {
    var previewContainerEl = document.getElementById('preview-video-container')
    var y = previewContainerEl.getBoundingClientRect().top + window.scrollY
    y -= 90
    window.scroll({
      top: y,
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dot It</title>
          <meta name="description"
            content={"dot it is special education software that " +
              "helps you draft IEPs, schedule services, monitor " +
              "standards based goals and allocate resources wisely."}
          />
          <meta name="keywords" content={"dot it,dotit,dotitapp," +
            "dot it special education,dot it Special Education,dot it IEP Goals," +
            "dot it IEPS,dot it IEP," +
            "dot it IEPs,dot it standards,dot it standard,dot it standard based," +
            "dot it standard based IEP," +
            "dotit special education,Special Education,IEP Goals,IEPS,IEP," +
            "dotit IEPs,standards,standard,standard based,standard based IEP," +
            "standard based IEP goals,software,iep software,iep goal software," +
            "iep calendar,progress monitoring,iep progress monitoring,iep charts," +
            "iep reports,print iep reports,printable iep reports,district reports," +
            "district summary,school reports,school summary"}
          />
        </Helmet>
        <BackTop visibilityHeight={1500} />


        <Layout>
        </Layout>
        <div>
          <Layout>
            <Layout className="background-fff">
              <div className="home-center text-center">
                <div className="mb-4">
                  <img src='/dotitlogo.png' alt='logo' height='88' />
                </div>
                <div>
                  <Spin size="large" className="ant-spin-vlg" />
                </div>
              </div>

            </Layout>
          </Layout>
        </div>

        <div className='absolute w-100' style={{ bottom: '0px', left: '0px' }}>
          <CustomFooter />
        </div>

      </div>
    )
  }
}