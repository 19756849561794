import React, { Component } from 'react'

class VideoPlayer extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = "//vjs.zencdn.net/7.10.2/video.min.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.HELP_IMPROVE_VIDEOJS = false;

      // instantiate Video.js
      this.player = window.videojs(this.videoNode, this.props, function onPlayerReady() {
        //console.log('onPlayerReady', this)
      });
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }

    // Remove script
    const script = document.querySelector('script[src="//vjs.zencdn.net/7.10.2/video.min.js"]');
    if (script) {
      document.body.removeChild(script);
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player style={this.props.style ? this.props.style : {}}>
          <video width={this.props.hasOwnProperty('width') &&
            this.props.width ?
            this.props.width :
            848} height={495}
            ref={node => this.videoNode = node} className="video-js"></video>
        </div>
      </div>
    )
  }
}

export default VideoPlayer
