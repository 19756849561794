import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { Avatar } from 'antd'
import { getInitials } from '../Util'
//import PersonAvatar from './PersonAvatar'
var moment = require('moment')

class UserTextDisplay extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        let ellipsis = this.props.hasOwnProperty('ellipsis') && this.props.ellipsis
        let styleObj = { marginLeft: '59px', marginTop: '-24px' }
        if (ellipsis) {
            styleObj.maxHeight = '35px'
        }
        return (
            <div>
                <div className="w-100 font-20">
                    <div className="inline-flex">
                        {this.props.iconToShow === 'avatar' ?
                            <Avatar
                                size="large"
                                className="inline-block mr-8 avatar-vlg"
                                style={{ backgroundColor: '#d3adf7' }}
                            >
                                <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                            </Avatar>
                            :
                            this.props.iconToShow === 'coachesNote' ?
                                <img src="/coachs-note-icon.png" title="Coach's note" className="mr-1" width={'50px'} height={'50px'} alt="icon" />
                                : ''}
                        <div className='font-bold'>
                            {this.props.person.firstName + ' ' + this.props.person.lastName}
                        </div>
                        <div className='ml-1 mr-1 text-muted font-16' style={{ marginTop: '5px' }}>-</div>
                        <div className="text-muted font-16" style={{ marginTop: '5px' }}>
                            {this.props.timeStamp && this.props.timeStamp.hasOwnProperty('seconds') &&
                                this.props.timeStamp.seconds ?
                                moment.utc(this.props.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                : ''}
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className={"font-20" + (ellipsis ? " ellipsis" : "")} style={styleObj}>
                            {this.props.message}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserTextDisplay