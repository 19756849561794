import React from 'react'

function RectangleAssessment({ preText, postText, answers, width, height, A, B, C, D }) {
    const answerItems = answers.map((answer, index) =>
        <li key={index}>
            <input type="checkbox" id={`answer_${index}`} name={`answer_${index}`} value={answer} />
            <label htmlFor={`answer_${index}`}>{answer}</label>
        </li>
    )

    return (
        <div style={{ border: '1px solid black', margin: '10px', padding: '20px' }}>
            <p>{preText}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <svg width="200" height="100" xmlns="http://www.w3.org/2000/svg">
                    <rect x="10" y="10" width="180" height="80" stroke="black" fill="transparent" strokeWidth="2" />
                    <text x="90" y="60" fill="black">{width}</text>
                    <text x="0" y="60" fill="black">{A}</text>
                    <text x="200" y="60" fill="black">{B}</text>
                    <text x="0" y="10" fill="black">{D}</text>
                    <text x="200" y="10" fill="black">{C}</text>
                    <text x="5" y="50" fill="black" transform="rotate(-90 5,50)">{height}</text>
                </svg>
            </div>
            <p>{postText}</p>
            <ul>{answerItems}</ul>
        </div>
    )
}

export default RectangleAssessment
