import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '../Util'
import ColType from '.././Types'
import { Icon, message, Button, Modal, Tooltip, Form, Checkbox } from 'antd'
const FormItem = Form.Item

const successMessage = (description) => {
  message.success(description)
}

const errorMessage = (description) => {
  message.error(description)
}

class AdminInstructionalFacilitatorSchoolsModal extends Component {
  state = {
    visible: false,
    submitting: false,
  }

  componentDidMount() {
    if (!this.props.instructionalFacilitator.hasOwnProperty('schoolIds')) {
      return
    }

    this.props.form.setFieldsValue({
      schools: this.props.instructionalFacilitator.schoolIds
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })

    this.props.form.setFieldsValue({
      schools: this.props.instructionalFacilitator.schoolIds
    })
  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        this.setState({
          submitting: true,
        })

        var schoolIds = values.schools

        // update user for security rules reasons
        db.collection(ColType.users)
          .where('refId', '==', this.props.instructionalFacilitator.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              console.log('len', querySnapshot.size)
              var instructionalFacilitatorUser = flattenDoc(doc)
              db.collection(ColType.users)
                .doc(instructionalFacilitatorUser.id)
                .update({
                  'schoolIds': schoolIds,
                })
                .then(() => {
                  this.setState({
                    submitting: false,
                    visible: false,
                  })
                })
                .catch((e) => {
                  console.log(e)
                  errorMessage("Something went wrong, please try again " +
                    "or send an email to support@dotit.app.")
                  this.setState({
                    submitting: false,
                    visible: false,
                  })
                })

            })
          })
          .catch((e) => {
            console.log(e)
            errorMessage("Something went wrong, please try again " +
              "or send an email to support@dotit.app.")
          })

        db.collection(ColType.instructionalFacilitator)
          .doc(this.props.instructionalFacilitator.id)
          .update({
            'schoolIds': schoolIds,
          })
          .then(() => {
            successMessage("The schools the instructional facilitator can access have been updated.")
            this.setState({
              submitting: false,
              visible: false,
            })
          })
          .catch((e) => {
            console.log(e)
            errorMessage("Something went wrong, please try again " +
              "or send an email to support@dotit.app.")
            this.setState({
              submitting: false,
              visible: false,
            })
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 25 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 25 },
        sm: { span: 18 },
      },
    }


    return (
      <div className="inline-block mr-2">
        <Tooltip title="Edit schools">
          <Button className="transparent-btn inline-block text-more-muted font-20"
            onClick={this.showModal}>
            <Icon type="form" />
          </Button>
        </Tooltip>
        <Modal
          title="Edit the schools this instructional facilitator can access"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className="w-100 inline-block">

            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem {...formItemLayout} label="Schools">
                {getFieldDecorator('schools', {
                  rules: [{ required: true, message: 'Please input the school name.' }],
                })(
                  <Checkbox.Group style={{ width: '100%' }}>
                    {this.props.adminBaseProps.schools.map((school, idx) => {
                      return <div className="mb-1" key={'school-checkbox-' + school.id}>
                        <Checkbox value={school.id} size={'large'} className='font-16'>
                          <span className="ml-1">{school.schoolName}</span>
                        </Checkbox>
                      </div>
                    })}
                  </Checkbox.Group>
                )}
              </FormItem>

              <div className="pt-2 mt-2 border-top">
                <Button disabled={this.state.submitting}
                  htmlType="submit"
                  type="primary"
                  size={'large'}
                  className="login-form-button text-align-center float-right"
                >
                  OK
                </Button>
                <Button disabled={this.state.submitting}
                  type="default"
                  size={'large'}
                  className="login-form-button text-align-center float-right mr-1"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

      </div>
    )
  }
}

export default Form.create()(AdminInstructionalFacilitatorSchoolsModal)