import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
//import PersonAvatar from '../customcomponents/PersonAvatar'
//import Empty from '../customcomponents/Empty'
import { db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc } from '.././Util'



class SchoolAdminGroupProgressMonitoring extends Component {
  state = {
    selectedMenuKey: [],
    measurementDict: {},
    calendarEvents: [],
    selectedEvent: null,
    selectedEventTier: null,
  }

  componentDidMount() {
    this.getMeasurementDict()
  }

  componentWillReceiveProps() {
    this.getMeasurementDict()
  }

  getMeasurementDict = () => {
    if (!(this.props.schoolProps &&
      this.props.schoolProps.iepGoals &&
      this.props.schoolProps.schoolId &&
      this.props.schoolProps.iepDict &&
      this.props.schoolProps.districtId)) return
    if (this.state.selectedMenuKey.length === 0) return
    console.log("Entered getMeasurementDict")
    var teacherId = this.state.selectedMenuKey[0]
    var measurementDict = {}

    // get all the teacher's calendar events
    db.collection(ColType.calendarEvents)
      .where('teacherId', '==', teacherId)
      .where('schoolId', '==', this.props.schoolProps.schoolId)
      .where('districtId', '==', this.props.schoolProps.districtId)
      .orderBy('startTime')
      .orderBy('index')
      .orderBy('duration', 'desc')
      .get()
      .then((querySnapshot) => {
        var calendarEvents = []
        var calendarDict = {}

        querySnapshot.forEach((doc) => {
          var event = flattenDoc(doc)
          if (event.servicedIn === 'Teacher Event') return
          calendarEvents.push(event)
          calendarDict[event.id] = event
        })

        this.setState({
          calendarEvents: calendarEvents,
          calendarDict: calendarDict,
          loadingNewData: false,
        })
      })

    var teacheriepGoals = []
    this.props.schoolProps.iepGoals.map((iep, index) => {
      if (iep.teacherId !== teacherId) return false
      return teacheriepGoals.push(iep)
    })

    if (teacheriepGoals.length === 0) {
      console.log("Teacher has no goals")
      this.setState({
        measurementDict: {},
        loadingNewData: false,
      })
    }
    else {
      // loop through all school goals and get the ones for this teacher
      teacheriepGoals.map((iep, index) => {
        if (iep.teacherId !== teacherId) return false

        db.collection(ColType.measurements)
          .where('teacherId', '==', teacherId)
          .where('schoolId', '==', this.props.schoolProps.schoolId)
          .where('districtId', '==', this.props.schoolProps.districtId)
          .where('iepId', '==', iep.id)
          .orderBy('timeStamp')
          .get()
          .then((querySnapshot) => {
            var measurements = []
            //{measurement: parseInt(iep.iep.baselineAccuracyLevel, 10)}
            //]
            querySnapshot.forEach((doc) => {
              measurements.push(flattenDoc(doc))
            })

            measurementDict[iep.id] = measurements

            this.setState({
              measurementDict: measurementDict,
              loadingNewData: false,
            })
          })

        return false
      })
    }
  }

  onSelect = (val) => {
    this.setState({
      selectedEvent: null,
      selectedEventTier: null,
      loadingNewData: true,
    }, () => {
      this.setState({
        selectedMenuKey: [val.key],
        loadingNewData: true,
      }, () => {
        this.getMeasurementDict()
      })
    })
  }

  onGroupSelect = (event, eventTier) => {
    this.setState({
      selectedEvent: event,
      selectedEventTier: eventTier,
    })
  }

  goBackToGroupView = () => {
    this.setState({
      selectedEvent: null,
      selectedEventTier: null,
    })
  }

  render() {
    return <div>

    </div>
  }
}

export default SchoolAdminGroupProgressMonitoring