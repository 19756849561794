import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import SuperAdminHeader from './SuperAdminHeader'
import { secondaryFirebaseRef, db } from '../firebase/Firebase'
import { getAvatarColor, getNumShards, compress, getEmptySchoolOrDistrictSummary, flattenDoc, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { Icon, Select, Form, Input, Button, message, Checkbox } from 'antd'
const FormItem = Form.Item
const Option = Select.Option

const successMessage = (description) => {
  message.success(description)
}

const errorMessage = (description) => {
  message.error(description)
}

class AddInitialAdmin extends Component {
  state = {
    showNotDemo: false,
  }

  componentDidMount() {

  }

  signInUsingCleverId = (cleverId) => {
    if (!cleverId) {
      return
    }

    //console.log('cleverId', cleverId)

    db.collection(ColType.users)
      .doc(cleverId)
      .get()
      .then((doc) => {
        var userInfo = flattenDoc(doc)
        if (userInfo.accessLevel === 'superAdmin') {
          window.location.href = '/super-admin/add-initial-admin'
          return
        }
        else {
          db.collection(userInfo.accessLevel)
            .doc(userInfo.refId)
            .get()
            .then((doc) => {
              var userJobInfo = flattenDoc(doc)
              linkAfterLogin(userInfo, userJobInfo)
            })
        }
      }).catch((error) => {
        //console.log('get user info error', error)
        this.setState({
          submitting: false,
        })
      })
  }

  render() {
    return (
      <div>
        <SuperAdminHeader menuKey={'add-initial-admin'} />
        <div className="mainContainer">
          <div className="w-600 m-lr-auto p-4">
            <h1 className="mt-4 mb-2 font-30">
              Sign In
            </h1>

            <div>
              <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                this.signInUsingCleverId('64bee2bdbd36861021732034')
              }}>
                SC Demo Admin
              </Button>
              <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                this.signInUsingCleverId('64c9bd88ee3bd703dc3f33f7')
              }}>
                SC Demo Coach
              </Button>
              <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                this.signInUsingCleverId('64c9bd88ee3bd703dc3f33ca')
              }}>
                SC Demo Teacher
              </Button>
              <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                this.signInUsingCleverId('64e638af44f2aa047457f954')
              }}>
                KY Demo Admin
              </Button>
              <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                this.signInUsingCleverId('64e638af44f2aa047457f951')
              }
              }>
                KY Demo Coach
              </Button>
              <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                this.signInUsingCleverId('64e638af44f2aa047457f924')
              }
              }>
                KY Demo Teacher
              </Button>
              <div className='mt-4 mb-2'>
                <Checkbox checked={this.state.showNotDemo} onChange={(e) => {
                  this.setState({
                    showNotDemo: e.target.checked
                  })
                }}
                >
                  <span className='pl-1'>Show all</span>
                </Checkbox>
              </div>
              {this.state.showNotDemo ?
                <div>
                  <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                    this.signInUsingCleverId('5fa9eebb9ebc0b25ae1ff6c3')
                  }}>
                    Dillon
                  </Button>
                  <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                    this.signInUsingCleverId('60355bdbb998050cb357e117')
                  }}>
                    Laurens
                  </Button>
                  <Button className='lg-btn w-100 mb-1' onClick={(e) => {
                    this.signInUsingCleverId('5fa9aa4b5c99031aaa67a9e7')
                  }}>
                    Lincoln
                  </Button>
                </div> : ''}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddInitialAdmin