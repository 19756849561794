import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import DistrictStats from './DistrictStats'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Layout, Row, Col, message, Select } from 'antd'
const { Content } = Layout
const Option = Select.Option
const OptGroup = Select.OptGroup
const moment = require('moment')

const errorMessage = (description) => {
    message.error(description)
}

class AdminTeacherPractice extends Component {
    state = {
        newPopoverVisible: false,
        accountPopoverVisible: false,
        adminId: '',
        selectedId: null,
        teacherId: '',
        studentId: '',
        teacher: null,
        student: null,
        teachers: [],
        students: [],
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
        calendarEvents: null,
        calendarDict: null,
        events: null,
        studentReportData: {},
        teacherTab: 'schedule',
        loadingData: false,
        readOnly: true,
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Dashboard - Dot It'

        var adminId = getIDFromURL(window.location)

        this.setState({
            adminId: adminId,
        })
    }

    componentWillReceiveProps(nextProps) {
        let selectedId = getQueryStringParam('s')
        //let teacherId = getQueryStringParam('t')
        let prevSelectedId = this.state.selectedId
        // let prevSelectedTeacherId = this.state.teacherId

        this.setState({
            selectedId: selectedId,
        })

        if (selectedId && prevSelectedId !== selectedId) {
            this.setState({
                teacherId: '',
                studentId: '',
                teacher: null,
                student: null,
                teachers: [],
                students: [],
                studentDict: {},
                IEPGoals: [],
                IEPDict: {},
                calendarEvents: null,
                calendarDict: null,
                events: null,
            })

            this.getTeachers(selectedId)
        }
    }

    getTeachers = (schoolId) => {
        if (schoolId && this.props.adminBaseProps.admin) {
            this.setState({
                loadingData: true,
            }, () => {
                db.collection(ColType.teacher)
                    .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
                    .where('schoolId', '==', schoolId)
                    .get()
                    .then((querySnapshot) => {
                        var teachers = []

                        querySnapshot.forEach((doc) => {
                            var teacher = flattenDoc(doc)
                            if (!(teacher.hasOwnProperty('isVisible') && !teacher.isVisible)) {
                                teachers.push(teacher)
                            }
                        })

                        teachers.sort((a, b) => {
                            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                return 1
                            } else {
                                return -1
                            }
                        })

                        this.setState({
                            teachers: teachers,
                            loadingData: false,
                        })
                    })
            })
        }
    }

    getStudents = (teacher) => {
        if (teacher && this.props.adminBaseProps.admin) {
            let promises = []

            this.setState({
                loadingData: true,
            })

            promises.push(new Promise((resolve, reject) => {
                db.collection(ColType.iep)
                    .where('teacherId', '==', teacher.id)
                    .where('schoolId', '==', teacher.schoolId)
                    .where('districtId', '==', teacher.districtId)
                    .orderBy('timeStamp', 'desc')
                    .get()
                    .then((querySnapshot) => {
                        var IEPGoals = []
                        var IEPDict = {}

                        querySnapshot.forEach((doc) => {
                            var iep = flattenDoc(doc)
                            IEPGoals.push(iep)
                            IEPDict[iep.id] = iep
                        })

                        this.setState({
                            IEPGoals: IEPGoals,
                            IEPDict: IEPDict,
                        })

                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }))

            promises.push(new Promise((resolve, reject) => {
                db.collection(ColType.calendarEvents)
                    .where('teacherId', '==', teacher.id)
                    .where('schoolId', '==', teacher.schoolId)
                    .where('districtId', '==', teacher.districtId)
                    .orderBy('startTime')
                    .orderBy('index')
                    .orderBy('duration', 'desc')
                    .get()
                    .then((querySnapshot) => {
                        var calendarEvents = []
                        var calendarDict = {}
                        var events = []
                        var idx = 0

                        querySnapshot.forEach((doc) => {
                            if (!doc.exists) return
                            var event = flattenDoc(doc)
                            // store the doc for deletes
                            event.doc = doc
                            calendarEvents.push(event)
                            var start = moment.utc(event.startTime.seconds * 1000)
                            var end = new Date(event.endTime)
                            //start = new Date(2015, 5, parseInt(event.day, 10),
                            //  start.getHours(), start.getMinutes(), 0)
                            end = new Date(2015, 5, parseInt(event.day, 10),
                                end.getHours(), end.getMinutes(), 0)
                            event.start = start
                            event.end = end

                            events.push(event)
                            calendarDict[idx.toString()] = {
                                'flattenedDoc': event,
                                'unFlattenedDoc': doc.data()
                            }
                        })

                        this.setState({
                            calendarEvents: calendarEvents,
                            calendarDict: calendarDict,
                            events: events,
                        })

                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }))

            promises.push(new Promise((resolve, reject) => {
                db.collection(ColType.student)
                    .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
                    .where('teacherId', '==', teacher.id)
                    .get()
                    .then((querySnapshot) => {
                        var students = []
                        var studentDict = {}

                        querySnapshot.forEach((doc) => {
                            var student = flattenDoc(doc)
                            students.push(student)
                            studentDict[student.id] = student
                        })

                        students.sort((a, b) => {
                            var levelDifference = 0
                            if (a.grade === 'K' && b.grade === 'K') {
                                if (levelDifference !== 0) return levelDifference
                                if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                    return 1
                                } else {
                                    return -1
                                }
                            }
                            if (a.grade === 'K' && b.grade !== 'K') return -1
                            if (a.grade !== 'K' && b.grade === 'K') return 1
                            if (a.grade === b.grade) {
                                if (levelDifference !== 0) return levelDifference
                                if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                    return 1
                                } else {
                                    return -1
                                }
                            }
                            var aGrade = parseInt(a.grade, 10)
                            var bGrade = parseInt(b.grade, 10)
                            if (aGrade > bGrade) return 1
                            if (bGrade > aGrade) return -1
                            return 0
                        })

                        this.setState({
                            students: students,
                            studentDict: studentDict,
                        })

                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }))

            Promise.all(promises)
                .then(() => {
                    this.setState({
                        loadingData: false,
                    })
                })
                .catch((e) => {
                    this.setState({
                        loadingData: false,
                    })
                })
        }
    }

    getIEPs = (teacher, student) => {
        if (teacher && student && this.props.adminBaseProps.admin) {
            db.collection(ColType.iep)
                .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
                //.where('schoolId', '==', teacher.schoolId)
                .where('teacherId', '==', teacher.id)
                .where('studentId', '==', student.id)
                .get()
                .then((querySnapshot) => {
                    var IEPGoals = []
                    var IEPDict = {}

                    querySnapshot.forEach((doc) => {
                        var iep = flattenDoc(doc)
                        IEPGoals.push(iep)
                        IEPDict[iep.id] = iep
                    })

                    this.setState({
                        IEPGoals: IEPGoals,
                        IEPDict: IEPDict,
                    })
                })
        }
    }

    newVisibleChange = (visible) => {
        this.setState({ newPopoverVisible: visible })
    }

    accountVisibleChange = (visible) => {
        this.setState({ accountPopoverVisible: visible })
    }

    signOut = () => {
        firebase.auth().signOut().then(function () {
            console.log('Signed Out')
            //sending users to the sign in page after signing out for now.
            window.location.href = "/sign-in"
        }, function (error) {
            console.error('Sign Out Error', error)
            errorMessage("There was an error signing you out. Please try again.")
        })
    }

    onChange = (value) => {
        console.log(`selected ${value}`)
    }

    onSelect = (value, option) => {
        console.log('on select', value, option)
        console.log('/teacher/student/' +
            this.props.person.id + '?student=' +
            option.key)

        this.props.history.push(
            {
                pathname: '/admin/school-summary/' +
                    this.props.person.id + '?district=' +
                    this.props.person.districtId + '&school=' +
                    option.key
            }
        )
    }

    onSchoolOrDistrictSelect = (schoolOrDistrict) => {
        if (this.props.adminBaseProps.district &&
            this.props.adminBaseProps.district.id === schoolOrDistrict) {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.adminBaseProps.admin.id
                }
            )
        }
        else {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.adminBaseProps.admin.id + '?s=' + schoolOrDistrict
                }
            )
        }
    }

    teacherClick = (teacher) => {
        this.setState({
            teacher: teacher,
            teacherId: teacher.id
        })

        this.getStudents(teacher)

        window.scroll(0, 0)
    }

    studentClick = (student) => {
        this.setState({
            student: student,
            studentId: student.id,
            studentReportData: {
                teacherId: this.state.teacher.id,
                studentId: student.id,
                teacher: this.state.teacher,
                student: student,
                teachers: this.state.teachers,
                students: this.state.students,
                studentDict: this.state.studentDict,
                // goals for this student
                IEPGoals: this.state.IEPGoals.filter((e) => e.studentId === student.id),
                IEPDict: this.state.IEPDict,
            }
        })

        window.scroll(0, 0)
    }

    onSchoolOrDistrictSelect = (schoolOrDistrict) => {
        this.setState({
            selectedOptionId: schoolOrDistrict
        })

        if (this.props.adminBaseProps.district &&
            this.props.adminBaseProps.district.id === schoolOrDistrict) {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.adminBaseProps.admin.id
                }
            )
        }
        else {
            this.props.history.push(
                {
                    pathname: '/admin/admin-home/' +
                        this.props.adminBaseProps.admin.id + '?s=' + schoolOrDistrict
                }
            )
        }
    }

    render() {
        let selectedId = null
        let selectedStats = null
        let selectedDisplayName = ''
        let districtId = null
        if (this.props.adminBaseProps.district) {
            districtId = this.props.adminBaseProps.district.id
            // default is to select the district if no school is selected
            selectedDisplayName = this.props.adminBaseProps.district.district
            selectedId = this.props.adminBaseProps.district.id

            if (this.state.selectedId) {
                selectedId = this.state.selectedId

                if (selectedId !== this.props.adminBaseProps.district.id &&
                    this.props.adminBaseProps.schoolsDict &&
                    this.props.adminBaseProps.schoolsDict.hasOwnProperty(selectedId)) {
                    selectedDisplayName = this.props.adminBaseProps.schoolsDict[selectedId].schoolName
                }
            }

            if (this.props.adminBaseProps.allStatOptionsDict.hasOwnProperty(selectedId)) {
                selectedStats = this.props.adminBaseProps.allStatOptionsDict[selectedId]
            }
        }
        console.log(districtId)

        return (
            <div>
                <div>
                    <Layout className="content relative">
                        <Layout >
                            <Content>
                                <div>
                                    {this.state.adminId &&
                                        this.props.adminBaseProps.admin &&
                                        this.props.adminBaseProps.district &&
                                        this.props.adminBaseProps.schoolsDict
                                        ?
                                        <div>
                                            <div className='admin-banner'>
                                            </div>
                                            <div className="sub-menu-width-lg m-lr-auto mt-3  pl-2 pr-2">
                                                <div style={{ marginTop: '-300px' }}>
                                                    <div className="font-30 font-fff font-bold text-center mb-4">
                                                        District and School Data
                                                    </div>
                                                </div>
                                                {this.props.adminBaseProps.district &&
                                                    this.props.adminBaseProps.schoolsDict ?
                                                    <div className="w-100 mb-100">
                                                        <div className="font-24 mb-1 font-fff">
                                                            Select your district or a specific school to view the latest progress
                                                        </div>
                                                        <div className="font-black">
                                                            <Select
                                                                size={'large'}
                                                                placeholder="Select..."
                                                                className="ml-auto font-20 w-100 select-custom-vlg"
                                                                style={{ minWidth: '500px' }}
                                                                value={this.props.adminBaseProps.allStatOptionsDict.hasOwnProperty(this.state.selectedOptionId) ?
                                                                    this.state.selectedOptionId : this.props.adminBaseProps.district.id}
                                                                onChange={this.onSchoolOrDistrictSelect}
                                                            >
                                                                <OptGroup label="District" className="font-20">
                                                                    <Option value={this.props.adminBaseProps.district.id} className="font-20">
                                                                        {this.props.adminBaseProps.district.district}
                                                                    </Option>
                                                                </OptGroup>
                                                                <OptGroup label="Schools" className="font-20">
                                                                    {Object.keys(this.props.adminBaseProps.allStatOptionsDict).map((key, idx) => {
                                                                        if (key === this.props.adminBaseProps.district.id) {
                                                                            return false
                                                                        }
                                                                        return <Option value={key} key={key} className="font-20">
                                                                            {this.props.adminBaseProps.schoolsDict[key].schoolName}
                                                                        </Option>
                                                                    })}
                                                                </OptGroup>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    : ''}

                                                <div class="relative">

                                                    <img src="/admin-banner.png" alt="background-img" className="w-100 object-fit-cover absolute-tl br-20 w-100" />

                                                    <Row>
                                                        <Col span={24}>
                                                            <div className="font-bold mb-50 pb-2 font-40 flex font-fff ml-4"
                                                                style={{ marginTop: '150px' }}>
                                                                <div>{selectedDisplayName}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div>
                                                    </div>



                                                    <div className="mt-4 pl-2px pr-2px">
                                                        <DistrictStats
                                                            districtStats={this.props.adminBaseProps.clearPrevStats ? null : selectedStats}
                                                        />
                                                    </div>

                                                    {/*
                              <div className="mt-4 pl-2px pr-2px">
                                {this.state.loadingData ?
                                  <div className={"flex flex-h-center" +
                                    " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                    <div>
                                      <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                    </div>
                                  </div> :
                                  <div>
                                    <div>
                                      {!this.state.selectedId ?
                                        <div>
                                          <div className="font-24 font-bold mb-2">
                                            Select a school
                                          </div>
                                          {!this.props.adminBaseProps.schools ||
                                            (this.props.adminBaseProps.schools &&
                                              this.props.adminBaseProps.schools.length === 0) ?
                                            <Empty
                                              containerClassName="mt-4 pt-2 text-center"
                                              width={55}
                                              height={55}
                                            /> : ''
                                          }
                                          {this.props.adminBaseProps.schools &&
                                            this.props.adminBaseProps.schools.map((school, idx) => {
                                              return <div key={school.id} onClick={(e) => {
                                                this.onSchoolOrDistrictSelect(school.id)
                                              }}>
                                                <div>
                                                  <div className="p-3 br-20 font-20 font-bold font-black background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center cursor-pointer">
                                                    {school.schoolName}
                                                  </div>
                                                </div>
                                              </div>
                                            })}
                                        </div>
                                        : ''}
                                    </div>


                                    <div>
                                      {this.state.selectedId &&
                                        this.props.adminBaseProps.district &&
                                        selectedId &&
                                        selectedId !== this.props.adminBaseProps.district.id ?
                                        <div>
                                          {!this.state.teacher ?
                                            <div className='pl-05 pr-05'>
                                              {this.state.teachers &&
                                                this.state.teachers.length === 0 ?
                                                <EmptyComponent
                                                  containerClassName="mt-4 pt-2 text-center font-16"
                                                  width={55}
                                                  height={55}
                                                  description={'No data.'}
                                                />
                                                : ''}
                                              {this.state.teachers &&
                                                this.state.teachers.length > 0 ?
                                                <div className="font-24 font-bold mb-2">
                                                  Select a teacher to view their plans
                                                </div>
                                                : ''}
                                              {this.state.teachers &&
                                                this.state.teachers.map((teacher, idx) => {
                                                  return <div
                                                    id={teacher.id} key={teacher.id}
                                                    onClick={(e) => {
                                                      this.teacherClick(teacher)
                                                    }}>
                                                    <div>
                                                      <div className="p-3 br-20 font-18 font-bold font-black background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center cursor-pointer">
                                                        <PersonAvatar
                                                          person={teacher}
                                                          avatarColor={'#d3adf7'}
                                                          size={'large'}
                                                          containerClassName="font-20"
                                                          avatarClassName="font-20 font-bold mr-2 avatar-vlg"
                                                          personClassName="font-bold font-black"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                })}
                                            </div> : ''}

                                          {this.state.teacher && !this.state.student ?
                                            <div>
                                              <div className="flex flex-v-center mb-3 font-16">
                                                <h2 className="font-bold mb-0 link-blue font-16"
                                                  onClick={(e) => this.setState({
                                                    teacher: null,
                                                  })}>Teachers</h2>
                                                <Icon
                                                  type="right"
                                                  className="ml-1 mr-1 font-16"
                                                />
                                                <div>
                                                  <h2 className="font-bold mb-0 font-16">{this.state.teacher.firstName + " " + this.state.teacher.lastName}</h2>
                                                </div>
                                              </div>

                                              <div className="text-center mb-4 pb-1">
                                                <Row type="flex" align="middle">
                                                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                              
                                                  </Col>
                                                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                    <PersonAvatar
                                                      person={this.state.teacher}
                                                      avatarColor={'#d3adf7'}
                                                      size={'large'}
                                                      containerClassName="font-30 no-text-highlight"
                                                      avatarClassName="font-20 font-bold avatar-vlg"
                                                      personClassName="font-30 font-bold"
                                                    />
                                                  </Col>
                                                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                                  
                                                  </Col>
                                                </Row>
                                              </div>

                                              <Tabs
                                                className="tabs-vlg"
                                                animated={false}
                                                activeKey={this.state.teacherTab}
                                                onChange={(tab) => {
                                                  this.setState({
                                                    teacherTab: tab,
                                                  })
                                                }}
                                              >
                                                <Tabs.TabPane key="schedule" tab="Schedule">
                                                  <div className="mt-3 pl-2px pr-2px">
                                                    <Scheduler
                                                      teacherBaseProps={this.state}
                                                      hideSideNavbar={true}
                                                      admin={this.props.adminBaseProps.admin}
                                                    />
                                                  </div>
                                                </Tabs.TabPane>
                                                <Tabs.TabPane key="students" tab="Students">
                                                  <div className="mt-3 pl-2px pr-2px">
                                                    {this.state.students &&
                                                      this.state.students.length === 0 ?
                                                      <EmptyComponent
                                                        containerClassName="mt-4 pt-2 text-center font-16"
                                                        width={55}
                                                        height={55}
                                                        description={'No data.'}
                                                      />
                                                      : ''}
                                                    {this.state.students &&
                                                      this.state.students.length > 0 ?
                                                      <div className="font-24 font-bold mb-2">
                                                        Select a student to view their plans
                                                      </div>
                                                      : ''}
                                                    {this.state.students &&
                                                      this.state.students.map((student, idx) => {
                                                        return <div id={student.id} key={student.id}
                                                          onClick={(e) => {
                                                            this.studentClick(student)
                                                          }}>
                                                          <div>
                                                            <div className="p-3 br-20 font-18 font-bold font-black background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center cursor-pointer">
                                                              <PersonAvatar
                                                                person={student}
                                                                size={'large'}
                                                                containerClassName="font-20"
                                                                avatarClassName="font-20 font-bold mr-2"
                                                                personClassName="font-bold font-black"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      })}
                                                  </div>
                                                </Tabs.TabPane>
                                              </Tabs>
                                            </div> : ''}

                                          {this.state.teacher && this.state.student ?
                                            <div>
                                              <div className="flex flex-v-center mb-3">
                                                <h2 className="font-bold mb-0 link-blue font-16"
                                                  onClick={(e) => this.setState({
                                                    teacher: null,
                                                    student: null,
                                                  })}>Teachers</h2>
                                                <Icon
                                                  type="right"
                                                  className="ml-1 mr-1 font-16"
                                                />
                                                <div onClick={(e) => this.setState({
                                                  student: null,
                                                })}>
                                                  <h2 className="font-bold link-blue mb-0 cursor-pointer font-16">{this.state.teacher.firstName + " " + this.state.teacher.lastName}</h2>
                                                </div>
                                                <Icon
                                                  type="right"
                                                  className="ml-1 mr-1 font-16"
                                                />
                                                <h2 className="font-bold mb-0 font-16">{this.state.student.firstName + " " + this.state.student.lastName}</h2>
                                              </div>

                                              <div className="text-center mb-4 pb-1">
                                                <Row type="flex" align="middle">
                                                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                                
                                                  </Col>
                                                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                    <PersonAvatar
                                                      person={this.state.student}
                                                      size={'large'}
                                                      containerClassName="font-30 no-text-highlight"
                                                      avatarClassName="font-20 font-bold avatar-vlg"
                                                      personClassName="font-30 font-bold"
                                                    />
                                                  </Col>
                                                  <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                               
                                                  </Col>
                                                </Row>
                                              </div>

                                              <TeacherStudentReport
                                                teacherBaseProps={this.state.studentReportData}
                                                showStudentNav={false}
                                                showFooter={false}
                                                noMarginTop={true}
                                              />
                                            </div>
                                            : ''}

                                        </div> : ''}
                                    </div>
                                  </div>}
                              </div>
                            */}
                                                </div>
                                            </div>

                                        </div>
                                        : ''}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <CustomFooter />
            </div>
        )
    }
}

export default AdminTeacherPractice