import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../../styles/GlobalStyle.css'
import PersonAvatar from '../../../customcomponents/PersonAvatar'
import Empty from '../../../customcomponents/Empty'
import { Layout, Spin } from 'antd'
import { FOLDER_ARCHIVED, FOLDER_TRANSFER, FOLDER_TRASHBIN } from '../../../Util'
//import { db } from '../../../firebase/Firebase'
//import ColType from '../../../Types'
//import { FOLDER_CURRENT } from '../../../Util'
const Content = Layout.Content

class ArchivedStudentSelect extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        let dataLoaded = this.props.archivedBaseProps.archivedStudentsLoaded &&
            this.props.teacherBaseProps.teacher
        let bannerImgSrc = '/archive-banner.png'
        let header = 'Select student to view archived plans'
        let emptyText = ''
        if (this.props.archivedBaseProps.baseFolder === FOLDER_ARCHIVED) {
            bannerImgSrc = '/archive-banner.png'
            header = 'Select student to view archived plans'
            emptyText = 'No archived students'
        } else if (this.props.archivedBaseProps.baseFolder === FOLDER_TRASHBIN) {
            bannerImgSrc = '/delete-student-banner.png'
            header = 'Select student to view deleted plans'
            emptyText = 'Recycle bin is empty'
        } else if (this.props.archivedBaseProps.baseFolder === FOLDER_TRANSFER) {
            bannerImgSrc = '/transfer-student-banner.png'
            header = 'Select student to view transferred plans'
            emptyText = 'No transferred students'
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                <img src={bannerImgSrc} alt="banner" className='w-100 mb-3 br-20' />
                                <h1 class="font-bold mb-3 font-30">{header}</h1>

                                {!dataLoaded ?
                                    <div className={"flex flex-h-center" +
                                        " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                        <div>
                                            <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {this.props.archivedBaseProps.students.length === 0 ?
                                            <div className="w-100">
                                                <Empty
                                                    containerClassName="text-center"
                                                    width={55}
                                                    height={55}
                                                    description={emptyText}
                                                    image={'/empty/students.png'}
                                                />
                                            </div>
                                            : ''}
                                        {this.props.archivedBaseProps.students.map((student, idx) => {
                                            return <Link
                                                to={"/teacher/" + this.props.archivedBaseProps.urlRoute + "/student/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}
                                                key={student.id}
                                            >
                                                <div className={"p-3 br-20 border background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center"}>
                                                    <PersonAvatar
                                                        person={student}
                                                        size={'large'}
                                                        containerClassName="font-20"
                                                        avatarClassName="font-20 font-bold mr-2"
                                                        personClassName="font-black font-24"
                                                    />
                                                    {this.props.archivedBaseProps.baseFolder === FOLDER_TRANSFER ?
                                                        <div className='ml-auto font-black font-16'>
                                                            <div>{'Transferred from: ' + (student.originalTeacher.firstName + ' ' + student.originalTeacher.lastName) +
                                                                ', ' + student.originalTeacherSchool.schoolName}</div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </Link>
                                        })}
                                    </div>
                                }
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default ArchivedStudentSelect