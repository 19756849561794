import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { getIDFromURL } from '../.././Util'
import { Layout, Icon, Row, Col } from 'antd'
import CustomFooter from '../../login/CustomFooter'
const { Content } = Layout

class TeacherAddIEPGoalStart extends Component {
  state = {
    teacherId: '',
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    console.log(teacherId)

    this.setState({
      teacherId: teacherId,
    })
  }

  render() {
    return (
      <div>
         <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content">
              <div className="sub-menu-width m-lr-auto">
              {this.state.teacherId ?
              <Row gutter={48} className="ml-0">
                  <Col span={12} className="pl-0">
                    <div className="font-24 font-bold mb-2 text-center">
                      Draft an IEP:
                    </div>
                    <Link 
                      to={"/teacher/add-goal-student/" + this.state.teacherId}
                      className={"w-100 br-4 h-150 up-hover" +
                       " shadow-hover flex-center mb-2 ant-btn ant-btn-primary"}
                    >
                        <div className="font-16 w-100 flex flex-h-center font-bold-important">
                          <Icon type="plus" className="mr-2 font-30"/>
                          <span className="font-18">Draft IEP</span>
                        </div>
                    </Link>
                  </Col>

                  <Col span={12} className="pl-0">
                  <div className="font-24 font-bold mb-2 text-center">
                      Draft an MTSS Tier Plan:
                    </div>
                    <Link 
                      to={"/teacher/mtss-add-goal-student/" + this.state.teacherId}
                      className={"w-100 br-4 h-150 up-hover" +
                       " shadow-hover flex-center mb-2 ant-btn ant-btn-primary"}
                    >
                        <div className="font-16 w-100 flex flex-h-center font-bold-important">
                          <Icon type="plus" className="mr-2 font-30"/>
                          <span className="font-18">Draft MTSS Tier Plan</span>
                        </div>
                    </Link>
                  </Col>
                </Row>
              : ''}
              </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default TeacherAddIEPGoalStart