import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import IEPDrafts from './IEPDrafts'
import {
  flattenDoc, getIDFromURL, getQueryStringParam
} from '../.././Util'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import { Layout, Button, Icon, Select, Form } from 'antd'
import DraftStepContainer from '../addiep/DraftStepContainer'
const { Content } = Layout
const Option = Select.Option
const FormItem = Form.Item

class TeacherAddIEPGoalAccommodation extends Component {
  state = {
    teacher: null,
    student: null,
    iepDraft: null,
    students: [],
    studentDict: {},
    iepDrafts: [],
    presentLevels: {},
    selectedObj: {
      instructionBarrier: null,
      engagementBarrier: null,
      organizationBarrier: null,
      information: {
        beforeTheLesson: [
          "No presentation strategies needed",
          'Create consistent daily class routines',
          'Break the lesson into smaller chunks',
          'Listen to an audio version while reading or instead of reading',
          'Provide a hook related to personal interests for lengthy assignments',
          'Talk slowly when giving oral directions',
          'Give simple written directions',
        ],
        duringTheLesson: [
          "No engagement strategies needed",
          'Provide frequent checks for understanding',
          'Give extra verbal response time for answering questions',
          'Provide familiar or high-interest text',
          'Give time for skimming and scanning',
          'Give ample time for error correction',
          'Provide visual cues',
          'Use of a highlighter or sticky notes',
          'Supply notes or lesson outlines'
        ],
        afterTheLesson: [
          "No expression strategies needed",
          'Provide alternatives for lengthy handwritten assignments',
          'Show an exemplar before the start of the task',
          'Shorten assignments',
          'Give access to tools that support processing (calculator/spell check)',
          'Provide extended time to complete assignments',
          'Provide a rubric about how the task will be graded'
        ],
      },
      vocabulary: {
        beforeTheLesson: [
          "No presentation strategies needed",
          'Preview the lesson',
          'Connect new concepts to prior knowledge',
          'Use visuals to support information like anchor charts, diagrams, or pictures',
          'Give brief, concise directions',
          'Provide graphic organizers',
          'Repeat instructions',
          'Give picture prompts',
        ],
        duringTheLesson: [
          "No engagement strategies needed",
          "Chunk information",
          'Paraphrase information',
          'Provide immediate positive feedback for effort and persistence',
          'Minimize threats and distractions',
          'Provide multiple opportunities for practice in short sessions',
          'Create safe environment for asking for help',
          'Give visual cues to maintain attention',
          'Ask student to restate the goals',
          'Highlight relevant information',
        ],
        afterTheLesson: [
          "No expression strategies needed",
          'Provide fill in the blank graphic organizers',
          'Supply checklists and guides',
          'Provide opportunities for repeating tasks',
          'Use specific routines and procedures',
          'Create consistent daily class routines',
          'Provide choices for expressing understanding',
          'Provide visuals or manipulatives to accompany instruction',
          'Allow access to tools that reduce memory load (calculator/spell check)',
          'Provide sentence starters',
          'Provide models and examples',
        ],
      },
      problemSolving: {
        beforeTheLesson: [
          "No presentation strategies needed",
          'Preteach new vocabulary',
          'Highlight new vocabulary and major concepts',
          'Connect new concepts to prior knowledge',
          'Provide visuals or graphics to support new concepts',
          'Proximity seating',
          'Incorporate interests when teaching new concepts',
          'Use of graphic organizers to teach new concepts',
        ],
        duringTheLesson: [
          "No engagement strategies needed",
          'Incorporate interests when teaching new concepts',
          'Frequently check in with student to ensure comprehension',
          'Provide a reward system',
          'Provide a fill in the blank outline and/or cloze notes',
          'Paraphrase what has been taught periodically during lesson'
        ],
        afterTheLesson: [
          "No expression strategies needed",
          'Provide a concept map',
          'Provide a word bank',
          'Use anchor charts or word walls',
          'Offer options to written assignments',
          'Provide sentence starters'
        ],
      },
      efficiency: {
        beforeTheLesson: [
          "No presentation strategies needed",
          'Provide clear and concise oral instructions',
          'Provide time for asking questions for clarification',
          'Pair oral instructions with visual/concrete manipulatives',
          'Check for level of understanding',
        ],
        duringTheLesson: [
          "No engagement strategies needed",
          'Build in time to summarize the important information from each lesson',
          'Provide uncluttered handouts with few or no nonessential images',
          'Provide a highlighter for key information while reading',
          'Clearly space words and problems on a page',
        ],
        afterTheLesson: [
          "No expression strategies needed",
          'Provide copies of notes',
          'Use assistive technology',
          'Offer a choice of assessments/assignments',
          'Provide a lot of space for answers'
        ],
      },
      ell: {
        beforeTheLesson: [
          "No presentation strategies needed",
          'Provide teacher think alouds when teaching new concepts',
          'Supply an outline of lessons',
          'Use numerous examples to explain new concepts',
          'Preview of tasks and expectations',
          'Provide procedural checklists',
          'Clearly state goals and objectives'
        ],
        duringTheLesson: [
          "No engagement strategies needed",
          'Use graphic organizers to provide a summary of information',
          'Give analogies that are relatable',
          'Provide time for student to explain their work',
          'Designate a peer buddy to collaborate on task',
          'Ask clear and concise questions'
        ],
        afterTheLesson: [
          "No expression strategies needed",
          'Provide time to verbalize thought process',
          'Show exemplars',
          'Provide manipluatives',
          'Highlighting relevant information',
          'Post anchor charts',
          'Post concept maps'
        ],
      },
    },
    barriersSelected: 0,
    requiredBarrierNum: 0,
    tabKey: '1',
    stepNum: 5,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var draftId = getQueryStringParam('draft')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.pepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          var iepDraft = flattenDoc(doc)
          var requiredBarrierNum = 0
          if (iepDraft.tier === 'Tier 1') requiredBarrierNum = 1
          else if (iepDraft.tier === 'Tier 2') requiredBarrierNum = 2
          else if (iepDraft.tier === 'Tier 3') requiredBarrierNum = 3
          if (iepDraft.hasOwnProperty('accommodations')) {
            var selectedObj = this.state.selectedObj
            var barriersSelected = 0
            selectedObj['instructionBarrier'] = iepDraft.accommodations['instructionBarrier']
            selectedObj['engagementBarrier'] = iepDraft.accommodations['engagementBarrier']
            selectedObj['organizationBarrier'] = iepDraft.accommodations['organizationBarrier']
            if (selectedObj['instructionBarrier'] !== null) {
              barriersSelected += 1
            }
            if (selectedObj['engagementBarrier'] !== null) {
              barriersSelected += 1
            }
            if (selectedObj['organizationBarrier'] !== null) {
              barriersSelected += 1
            }
            this.setState({
              selectedObj,
              barriersSelected,
            })
          }

          this.setState({
            iepDraft: iepDraft,
            requiredBarrierNum: requiredBarrierNum,
            iepDraftMounted: true,
          }, () => {
            var iepDraft = this.state.iepDraft
            if (iepDraft) {
              this.props.form.setFieldsValue(iepDraft.accommodations)
            }
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)

        db.collection(ColType.student)
          .doc(studentId)
          .get()
          .then((doc) => {
            var student = flattenDoc(doc)
            this.setState({
              teacher: teacher,
              student: student,
            })
          })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  // add a new event to the teacher's events
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values from form', values)

        // add accommodations to each iep
        var newState = this.state.iepDraft
        newState.ieps.map((iep, index) => {
          iep.accommodations = values
          return false
        })
        newState.accommodations = values
        newState.step = { stepNum: this.state.stepNum + 1, path: 'confirm' }

        db.collection(ColType.pepDrafts)
          .doc(this.state.draftId)
          .set(newState, { merge: true })
          .then(() => {
            this.props.history.push(
              {
                pathname: '/teacher/mtss-add-goal-confirm/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId +
                  '&draft=' + this.state.draftId,
                state: newState, // pass state to confirm page
              }
            )
          })
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  selectChange = (value, option, sectionKey) => {
    var selectedObj = this.state.selectedObj
    selectedObj[sectionKey] = value

    var barriersSelected = 0
    if (selectedObj['instructionBarrier']) {
      barriersSelected += 1
    }
    if (selectedObj['engagementBarrier']) {
      barriersSelected += 1
    }
    if (selectedObj['organizationBarrier']) {
      barriersSelected += 1
    }

    this.setState({
      barriersSelected: barriersSelected,
      selectedObj: selectedObj,
    })
  }

  unselectBarrier = (e, sectionKey) => {
    var selectedObj = this.state.selectedObj
    selectedObj[sectionKey] = null

    var barriersSelected = 0
    if (selectedObj['instructionBarrier'] !== null) {
      barriersSelected += 1
    }
    if (selectedObj['engagementBarrier'] !== null) {
      barriersSelected += 1
    }
    if (selectedObj['organizationBarrier'] !== null) {
      barriersSelected += 1
    }

    this.props.form.setFieldsValue({ [sectionKey]: null })

    this.setState({
      barriersSelected: barriersSelected,
      selectedObj: selectedObj,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    let mayHaveTroubleWith = null
    if (this.state.iepDraft &&
      this.state.iepDraft.hasOwnProperty("mayHaveTroubleWith") &&
      this.state.iepDraft.mayHaveTroubleWith) {
      mayHaveTroubleWith = this.state.iepDraft.mayHaveTroubleWith
    }
    else if (this.state.iepDraft &&
      this.state.iepDraft.studentId &&
      this.state.iepDraft.studentId.length > 0) {
      let studentId = this.state.iepDraft.studentId[0]
      if (this.state.studentDict.hasOwnProperty(studentId)) {
        let student = this.state.studentDict[studentId]
        if (student.hasOwnProperty("mayHaveTroubleWith") && student.mayHaveTroubleWith) {
          mayHaveTroubleWith = student.mayHaveTroubleWith
        }
      }
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                {/* 
    <div className="text-center">
      <img src="/draft-plan/3.png" alt="steps" />
    </div>
    */}
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={this.state.iepDraftMounted}
                  isMTSS={true}
                >
                  <div>
                    {this.state.iepDraftMounted ?
                      <div>
                        <div className="w-800 m-lr-auto background-fff br-15">
                          <div className='p-4 bkg-purple br-15'>
                            <div className='flex flex-v-center w-100'>
                              <div>
                                {this.props.teacherBaseProps &&
                                  this.props.teacherBaseProps.studentDict &&
                                  this.props.teacherBaseProps.studentDict
                                    .hasOwnProperty(this.state.studentId)
                                  ?
                                  <div className="flex flex-v-center mb-0 font-24">
                                    <div>
                                      <div className='font-20 font-fff'>
                                        Tier Plan Draft
                                      </div>
                                      <h1 className="font-40 mb-0 font-fff font-bold">
                                        Connection Strategies
                                      </h1>
                                    </div>
                                  </div>
                                  : ''}
                              </div>
                              <img src="/draft-icons/7.png" alt='draft-tier-plan' width={250} className='ml-auto' />
                            </div>
                          </div>
                          <div className='p-4'>
                            <h3 className="mb-4 font-20">
                              Select at least <strong>one</strong> classroom support strategy to
                              remove barriers and to help the selected students
                              access the general education curriculum. Choose the first option in
                              the list if no strategy for that category is needed.
                            </h3>
                            <Form onSubmit={this.handleSubmit}
                              className="block-label-form">


                              <FormItem {...formItemLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Presentation Strategies
                                  </div>
                                  <div className='font-16 font-normal'>
                                    Strategies to represent content in multiple ways to enhance understanding. Universal Design for Learning calls it multiple means of representation or the "what" of learning.
                                  </div>
                                </div>}
                              >
                                {getFieldDecorator('instructionBarrier', {
                                  rules: [{ required: true, message: 'Select a strategy.' }],
                                })(
                                  <Select
                                    size={'large'}
                                    placeholder="Select..."
                                    allowClear={true}
                                    onChange={
                                      (value, option) =>
                                        this.selectChange(value, option, 'instructionBarrier')
                                    }
                                  >
                                    {this.state.iepDraft &&
                                      mayHaveTroubleWith &&
                                      this.state.selectedObj
                                        .hasOwnProperty(mayHaveTroubleWith) &&
                                      this.state.selectedObj[mayHaveTroubleWith]
                                        .beforeTheLesson
                                        .map((instructionValue, index) => {
                                          return <Option
                                            value={instructionValue}
                                            key={instructionValue}
                                          >
                                            {instructionValue}
                                          </Option>
                                        })
                                    }
                                  </Select>
                                )}
                              </FormItem>

                              <FormItem {...formItemLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Engagement Strategies
                                  </div>
                                  <div className='font-16 font-normal'>
                                    Strategies to increase student motivation and excitement about learning. Universal Design for Learning calls it multiple means of engagement or the "why" of learning.
                                  </div>
                                </div>}>
                                {getFieldDecorator('engagementBarrier', {
                                  rules: [{ required: true, message: 'Select a strategy.' }],
                                })(
                                  <Select
                                    size={'large'}
                                    placeholder="Select..."
                                    allowClear={true}
                                    onChange={
                                      (value, option) =>
                                        this.selectChange(value, option, 'engagementBarrier')
                                    }
                                  >
                                    {this.state.iepDraft &&
                                      mayHaveTroubleWith &&
                                      this.state.selectedObj
                                        .hasOwnProperty(mayHaveTroubleWith) &&
                                      this.state.selectedObj[mayHaveTroubleWith]
                                        .duringTheLesson
                                        .map((engagementValue, index) => {
                                          return <Option
                                            value={engagementValue}
                                            key={engagementValue}
                                          >
                                            {engagementValue}
                                          </Option>
                                        })
                                    }
                                  </Select>
                                )}
                              </FormItem>

                              <FormItem {...formItemLayout}
                                label={<div>
                                  <div className='font-20 font-bold'>
                                    Expression Strategies
                                  </div>
                                  <div className='font-16 font-normal'>
                                    Strategies to provide different ways for students to express what they learn. Universal Design for Learning calls it multiple means of expression or the "how" of learning.
                                  </div>
                                </div>}>
                                {getFieldDecorator('organizationBarrier', {
                                  rules: [{ required: true, message: 'Select a strategy.' }],
                                })(
                                  <Select
                                    size={'large'}
                                    placeholder="Select..."
                                    allowClear={true}
                                    onChange={
                                      (value, option) =>
                                        this.selectChange(value, option, 'organizationBarrier')
                                    }
                                  >
                                    {this.state.iepDraft &&
                                      mayHaveTroubleWith &&
                                      this.state.selectedObj
                                        .hasOwnProperty(mayHaveTroubleWith) &&
                                      this.state.selectedObj[mayHaveTroubleWith]
                                        .afterTheLesson
                                        .map((organizationValue, index) => {
                                          return <Option
                                            value={organizationValue}
                                            key={organizationValue}
                                          >
                                            {organizationValue}
                                          </Option>
                                        })
                                    }
                                  </Select>
                                )}
                              </FormItem>

                              <div className="mb-0 mt-4 pt-2 flex flex-v-center">
                                <Link
                                  to={'/teacher/mtss-add-goal-service/' +
                                    this.state.teacherId + '?student=' +
                                    this.state.studentId +
                                    '&draft=' + this.state.draftId}
                                  className="font-18"
                                >
                                  <span className="left-hover-parent text-cyan">
                                    <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                    <span>Previous Step</span>
                                  </span>
                                </Link>
                                <Button
                                  type="primary"
                                  size={'large'}
                                  htmlType="submit"
                                  className="lg-btn ml-auto"
                                >
                                  Continue
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                      : ''}

                  </div>
                </DraftStepContainer>
              </div>

            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalAccommodation)