import { Icon } from 'antd'
import React from 'react'
import MathText from './MathText'

class Answers extends React.Component {
    render() {
        return (
            <div className='mt-2'>
                {this.props.answers &&
                    this.props.answers.map((choice, choiceIndex) => {
                        let choiceLetter = 'A'
                        if (choiceIndex === 1) {
                            choiceLetter = 'B'
                        } else if (choiceIndex === 2) {
                            choiceLetter = 'C'
                        } else if (choiceIndex === 3) {
                            choiceLetter = 'D'
                        }
                        return <div className='mb-1 font-20' key={choiceIndex}>
                            <div className={'p-2 border br-4' + (choice.correct ?
                                ' border-success bg-light-green' : '')}>
                                <div className='flex w-100'>
                                    <div className='mr-2'>
                                        <span className='mr-05'>{choiceLetter}.</span>
                                        <MathText text={choice.text} />
                                    </div>
                                    <div className='ml-auto'>
                                        {choice.correct ?
                                            <Icon type='check' className='text-success' />
                                            : ''}
                                    </div>
                                </div>

                                {choice.correct ?
                                    <div className='font-20 mt-2 pt-2 border-top'>
                                        <div className='font-bold'>{'Why this answer is ' + (choice.correct ? ' correct' : 'incorrect') + ':'}</div>
                                        <div>
                                            <MathText text={choice.reason} />
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    })}
            </div>
        )
    }
}

export default Answers