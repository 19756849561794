import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { getIDFromURL } from '../Util'
import InstructionalFacilitatorSchoolView from './InstructionalFacilitatorSchoolView'

class InstructionalFacilitatorHome extends Component {
  state = {
    instructionalFacilitatorId: '',
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Dashboard - Dot It'

    var instructionalFacilitatorId = getIDFromURL(window.location)

    this.setState({
      instructionalFacilitatorId: instructionalFacilitatorId,
    })
  }

  render() {
    return (
      <div>
        {this.state.instructionalFacilitatorId &&
          this.props.instructionalFacilitatorBaseProps.schools
          ?
          <div>
            <InstructionalFacilitatorSchoolView
              instructionalFacilitatorBaseProps={this.props.instructionalFacilitatorBaseProps}
              history={this.props.history}
              schoolId={this.props.instructionalFacilitatorBaseProps.schools.length > 0 ?
                this.props.instructionalFacilitatorBaseProps.schools[0].id : null}
            />
          </div>
          : ''}
      </div>
    )
  }
}

export default InstructionalFacilitatorHome