import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { Button, message } from 'antd'

const successMessage = (description) => {
    message.success(description)
}

const errorMessage = (description) => {
  // stays permanently, I'm destroying when form is submitted.
    message.error(description)
}

// This page can only be accessed by us, it is how you add admins that then can add teachers.
class EmailNotVerified extends Component {
  state = {
    user: null,
    admin: {},
    sendingEmail: false,
    verificationInterval: null
  }

  componentDidMount() {
    document.title = "Verify Email | dot it"
    var user = firebase.auth().currentUser
    if (!user) {
        // User must be signed in to wait on a verification
        // email (they will be)
        this.props.history.push(
            {
                pathname: '/sign-in/'
            }
        )
    }
    else {
        db.collection(ColType.users)
        .doc(user.uid)
        .get()
        .then((doc) => {
          var user = flattenDoc(doc)
          console.log("user", user)
          this.setState({
              user: user,
          })
        })

        let verificationInterval = setInterval(() => {
            this.getVerificationStatus()
        }, 500)
        this.setState({
            verificationInterval: verificationInterval
        })
    }
  }

  componentWillUnmount() {
    this.clearVerificationInterval()
  }

  clearVerificationInterval() {
    if (this.state.verificationInterval) {
        clearInterval(this.state.verificationInterval)
    }
  }

  getVerificationStatus() {
    // reload refreshes the current user, so the emailVerified status will
    // be up to date once they have verified
    firebase.auth().currentUser.reload()
    var user = firebase.auth().currentUser
    if (!user) {
        // User must be signed in to wait on a verification
        // email (they will be)
        this.props.history.push(
            {
                pathname: '/sign-in/'
            }
        )
    }
    else {
        //console.log("getVerificationStatus", user, user.emailVerified)
        if (user.emailVerified) {
            // clear the verification interval
            this.clearVerificationInterval()

            // redirect user to their account
            db.collection(ColType.users)
            .doc(user.uid)
            .get()
            .then((doc) => {
              var userInfo = flattenDoc(doc)
              console.log("userinfo", userInfo)
              if (userInfo.accessLevel === 'superAdmin') {
                window.location.href = '/super-admin/add-initial-admin'
                return
              }

              db.collection(userInfo.accessLevel)
                .doc(userInfo.refId)
                .get()
                .then((doc) => {
                  var userJobInfo = flattenDoc(doc)
                  console.log(userInfo, userJobInfo)
                  linkAfterLogin(userInfo, userJobInfo)
                })
              
            })
        }
    }
  }

  resendVerificationEmail = () => {
    if (!this.state.user) {
        console.log("cannot send verification email because " +
        "the user is null should be impossible.")
        errorMessage('Could not send verification email.')
        return
    }
    firebase.auth().currentUser.reload()
    var user = firebase.auth().currentUser
    if (user) {
        console.log("resend verification email", user)
        var actionCodeSettings = {
            url: 'https://iep.dotit.app/teacher/home/' + this.state.user.refId
        }
        user.sendEmailVerification(actionCodeSettings).then(() => {
            // Email sent.
            successMessage('Verification email sent.')
        }).catch((error) => {
          errorMessage('Could not send verification email. If we just sent you a ' +
          'verification email, please wait and try again later.')
          console.log(error)
        })
    }
  }

  render() {
    return (
        <div className="pt-70 pb-70">

         <div className="block w-100 m-lr-auto mb-4 pb-1 text-center">
          <Link to="/">
            <img src='/dot-it-logo.png' alt='logo' height='64' />
          </Link>
        </div>

        <div className="g-col large-form-padding w-500 m-lr-auto br-15">
            <div className="mb-2 text-center pb-2 font-24 font-bold">
              Verify Email
            </div>
            <div className="font-18 mb-4">
                We have sent you a verification email. Click the link in the
                email to verify your account.
            </div>
            <div className="font-18 font-bold mb-2">
                Didn't receive a verification email?
            </div>
            <Button type="primary" size="large" 
            className="font-18 font-bold-important"
            onClick={this.resendVerificationEmail}>
                Resend Verification Email
            </Button>
        </div>
        <div className="font-15 w-500 m-lr-auto text-center mt-2">
            If you are experiencing any issues please email support@dotit.app.
        </div>
      </div>
    )
  }
}

export default EmailNotVerified