import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import IEPDrafts from './IEPDrafts'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import DraftStepContainer from './DraftStepContainer'
import {
  flattenDoc, getIDFromURL,
  getQueryStringParam, capitalizeFirstChar,
} from '../.././Util'
import { Layout, Icon, Form, Button, Checkbox } from 'antd'
import StandardNumber from '../../customcomponents/StandardNumber'
const { Content } = Layout
const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group
const Promise = require("bluebird")

class TeacherAddIEPGoalSelect extends Component {
  state = {
    teacherId: null,
    studentId: null,
    teacher: null,
    student: null,
    iepDict: {},
    iepSubjectDict: {},
    iepSelections: {},
    iepSelectionsNoCount: {},
    iepCategories: {},
    iepDraft: null,
    students: [],
    tabKey: '1',
    stepNum: 2,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var draftId = getQueryStringParam('draft')
    console.log(teacherId, studentId)

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.iepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          this.setState({
            iepDraft: flattenDoc(doc),
            iepDraftMounted: true,
          }, () => {
            var iepDraft = this.state.iepDraft
            console.log(iepDraft)

            db.collection(ColType.student)
              .doc(studentId)
              .get()
              .then((doc) => {
                var student = flattenDoc(doc)
                var iepDict = {}
                var iepSubjectDict = {}
                var iepSelections = {}
                var iepSelectionsNoCount = {}
                var standardPromises = []
                var grade = student.grade
                if (grade.toLowerCase() === 'kindergarten') {
                  grade = 'K'
                }

                iepDraft.subject.map((subject, index) => {
                  iepSubjectDict[subject] = []
                  iepSelectionsNoCount[subject] = []
                  if (subject === 'Reading Foundations') {
                    iepSelections[subject] = { curr: 0, max: 3, selections: [] }
                  }
                  else iepSelections[subject] = { curr: 0, max: 2, selections: [] }
                  // if social emotional learning, get all categories
                  /*if (subject === "Social Emotional Learning") {
                    return standardPromises.push(db.collection(ColType.iepStandards)
                      .where('grade', '==', grade)
                      .where('mainSubject', '==', subject)
                      .where('level', '==', iepDraft.level)
                      .orderBy('standard', 'asc')
                      .get()
                    )
                  }
                  // else just get the category selected 
                  else {
                    return standardPromises.push(db.collection(ColType.iepStandards)
                      .where('grade', '==', grade)
                      .where('mainSubject', '==', subject)
                      .where('category', '==', iepDraft.category)
                      .where('level', '==', iepDraft.level)
                      .orderBy('standard', 'asc')
                      .get()
                    )
                  }*/
                  return standardPromises.push(db.collection(ColType.iepStandards)
                    .where('grade', '==', grade)
                    .where('mainSubject', '==', subject)
                    .where('category', '==', iepDraft.category)
                    .where('level', '==', iepDraft.level)
                    .orderBy('standard', 'asc')
                    .get()
                  )
                })

                Promise.all(standardPromises)
                  .then((querySnapshots) => {
                    var iepCategories = {}
                    querySnapshots.map((querySnapshot, index) => {
                      return querySnapshot.forEach((doc) => {
                        var iep = flattenDoc(doc)
                        iep.mainSubject = iep.mainSubject.trim()
                        iep.subject = iep.subject.trim()
                        iepSubjectDict[iep.mainSubject].push(iep)
                        iepDict[iep.id] = iep
                        if (!iepCategories.hasOwnProperty(iep.mainSubject)) {
                          iepCategories[iep.mainSubject] = {}
                        }
                        console.log(iep)
                        iepCategories[iep.mainSubject][iep.standardCategory] = true
                      })
                    })

                    this.setState({
                      student: student,
                      iepDict: iepDict,
                      iepSubjectDict: iepSubjectDict,
                      iepSelections: iepSelections,
                      iepCategories: iepCategories,
                    }, () => {
                      if (iepDraft.iepSelectionsNoCount &&
                        !(Object.keys(iepDraft.iepSelectionsNoCount).length === 0 &&
                          iepDraft.iepSelectionsNoCount.constructor === Object)) {
                        if (this.state.iepDraft.step.stepNum > this.state.stepNum)
                          this.props.form.setFieldsValue(iepDraft.iepSelectionsNoCount)
                      }
                    })
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              })
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)
        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  checkBoxGroupChange = (checkedValue, subject) => {
    //console.log(checkedValue, subject)
    var iepSelections = this.state.iepSelections
    var iepSelectionsNoCount = this.state.iepSelectionsNoCount
    //console.log(iepSelections[subject].curr, iepSelections[subject].max,
    //  iepSelections[subject].selections)
    //if (checkedValue > iepSelections[subject].max) return
    iepSelections[subject].curr = checkedValue.length
    iepSelections[subject].selections = checkedValue
    iepSelectionsNoCount[subject] = checkedValue

    this.setState({
      iepSelections: iepSelections,
      iepSelectionsNoCount: iepSelectionsNoCount,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('received values from form', values)

        var newState = this.state.iepDraft
        newState.iepSelectionsNoCount = values
        var iepStandards = []
        var iepStandardsSubjectDict = {}
        newState.subject.map((subject, index) => {
          return newState.iepSelectionsNoCount[subject].map((iepId, index) => {
            iepStandardsSubjectDict[this.state.iepDict[iepId].standard] =
              this.state.iepDict[iepId].mainSubject
            return iepStandards.push(this.state.iepDict[iepId].standard)
          })
        })

        newState.iepStandards = iepStandards
        newState.iepStandardsSubjectDict = iepStandardsSubjectDict
        newState.step = { stepNum: this.state.stepNum + 1, path: 'modify' }

        db.collection(ColType.iepDrafts)
          .doc(this.state.draftId)
          .set(newState, { merge: true })
          .then(() => {
            this.props.history.push(
              {
                pathname: '/teacher/add-goal-modify/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId +
                  '&draft=' + this.state.draftId,
                state: newState, // pass state to confirm page
              }
            )
          })
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 30 },
        sm: { span: 24 },
      },
    }

    return <div>
      <Layout className="content layout-header-mt">
        <Layout>
          <Content className="min-w-1120">
            <div>
              {/* 
            <div className="text-center">
              <img src="/draft-plan/3.png" alt="steps" />
            </div>
            */}
              <DraftStepContainer
                teacherBaseProps={this.props.teacherBaseProps}
                dataLoaded={this.state.iepDraftMounted && this.state.teacher && this.state.student && this.state.draftId}
              >
                <div>
                  {this.state.iepDraftMounted && this.state.teacher && this.state.student && this.state.draftId ?
                    <div>

                      <div className="w-800 m-lr-auto background-fff br-15">
                        <div className='p-4 bkg-purple br-15'>
                          <div className='flex flex-v-center w-100'>
                            <div>
                              {this.props.teacherBaseProps &&
                                this.props.teacherBaseProps.studentDict &&
                                this.props.teacherBaseProps.studentDict
                                  .hasOwnProperty(this.state.studentId)
                                ?
                                <div className="flex flex-v-center mb-0 font-24">
                                  <div>
                                    <div className='font-20 font-fff'>
                                      IEP Draft for {this.props.teacherBaseProps
                                        .studentDict[this.state.studentId].firstName + ' ' +
                                        this.props.teacherBaseProps
                                          .studentDict[this.state.studentId].lastName}
                                    </div>
                                    <h1 className="font-40 mb-0 font-fff font-bold">
                                      Standards
                                    </h1>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <img src="/draft-icons/3.png" alt='draft-iep' width={250} className='ml-auto' />
                          </div>
                        </div>

                        <div className='p-4'>

                          <div>
                            {this.state.student ?
                              <h3 className="mb-4 font-20">Based on teacher observation within the past six weeks,
                                select the knowledge and skills in which
                                {" " + this.state.student.firstName + " "}
                                needs to improve for each content area.</h3>
                              : ''}

                            <Form onSubmit={this.handleSubmit} className="block-label-form">

                              {Object.keys(this.state.iepSubjectDict).map((subject, index) => {
                                return <div key={'form-item-' + subject}>
                                  <h2 className="font-bold mb-0">
                                    <span className="mr-3 font-30 font-normal">{subject}</span>
                                  </h2>
                                  <h3 className="mb-0 font-20">
                                    <span>{'Each choice will become an IEP goal.'}</span>
                                  </h3>
                                  <FormItem {...formItemLayout} label={null}
                                    className="block-label font-20">
                                    {getFieldDecorator(subject, {
                                      rules: [{ required: true, message: 'Required' }],
                                    })(
                                      <CheckboxGroup
                                        onChange={(checkedValue) =>
                                          this.checkBoxGroupChange(checkedValue, subject)}
                                      >
                                        {this.state.iepCategories.hasOwnProperty(subject) &&
                                          Object.keys(this.state.iepCategories[subject]).map((standardCategory, index) => {
                                            return <div key={"iep-math-" + standardCategory}>
                                              <div className="font-20 mb-2 mt-2 font-bold">
                                                {standardCategory}
                                              </div>
                                              {this.state.iepSubjectDict[subject].map((iep, index) => {
                                                if (iep.standardCategory !== standardCategory) {
                                                  return false
                                                }
                                                return <div className="font-18 mt-1 flex flex-v-center"
                                                  key={'iep-checkbox-row-' + iep.id}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      value={iep.id}
                                                      className="block ml-0 font-16 font-black"
                                                      key={'checkbox-iep-' + iep.id}
                                                    >
                                                      <span className="pl-1 font-normal">
                                                        <StandardNumber
                                                          district={this.props.teacherBaseProps.district}
                                                          iep={iep}
                                                          className='mr-0'
                                                          containerClassName={'pl-1 pr-1 mr-05 br-100 font-14 va-2-important'}
                                                          containerStyle={{ backgroundColor: '#e8e8e8' }}
                                                        />
                                                        <span>
                                                          {capitalizeFirstChar(iep.standardDescription)}
                                                        </span>
                                                      </span>
                                                    </Checkbox>
                                                  </div>
                                                </div>
                                              })
                                              }
                                            </div>
                                          })
                                        }
                                      </CheckboxGroup>
                                    )}
                                  </FormItem>
                                </div>
                              })
                              }

                              <div className="mb-0 mt-4 pt-2 flex flex-v-center">
                                <Link
                                  to={'/teacher/add-goal-information/' +
                                    this.state.teacherId + '?student=' +
                                    this.state.studentId +
                                    '&draft=' + this.state.draftId}
                                  className="font-18"
                                >
                                  <span className="left-hover-parent text-cyan">
                                    <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                    <span>Previous Step</span>
                                  </span>
                                </Link>
                                <Button
                                  type="primary"
                                  size={'large'}
                                  htmlType="submit"
                                  className="lg-btn ml-auto"
                                >
                                  Continue
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ''}

                </div>
              </DraftStepContainer>
            </div>

          </Content>
        </Layout>
      </Layout>
    </div>
  }
}

export default Form.create()(TeacherAddIEPGoalSelect)