import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { firebase } from '../../firebase/Firebase'
import '../../styles/GlobalStyle.css'
import {
    getStudentMayHaveTroubleWith, getIDFromURL, cleverIsAuthed, cleverGetStudents
} from '../.././Util'
import { Layout, Icon } from 'antd'
import CustomFooter from '../../login/CustomFooter'
//import AddStudentCSV from './AddStudentCSV'
const { Content } = Layout

// Using this to add students until the step form for adding students is done.
class TeacherAddStudentHome extends Component {
    state = {
        teacherId: '',
        teacher: [],
        students: [],
        mayHaveTroubleWith: getStudentMayHaveTroubleWith(),
        redirectToStudentPage: false,
        submitting: false,
    }

    componentDidMount() {
        document.title = "Add Student - Dot It"

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    syncStudentsClever = () => {
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            let headers = new Headers()

            headers.append('Authorization', 'Bearer ' + idToken)
            headers.append('Origin', 'https://my.dotit.app')
            headers.append('Content-Type', 'application/json')
            headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

            fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/clever-teacher-sync-students", {
                method: "POST",
                body: JSON.stringify({

                }),
                mode: 'cors',
                headers: headers,
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                })
                .catch(err => {
                    console.log(err)
                })
        })
    }

    addStudentsClever = () => {
        cleverIsAuthed()
            .then((res) => {
                console.log(res)
                if (res.message === 'reauthenticating with clever') {
                    // not sure how to handle this yet
                } else if (res.message === 'valid') {
                    // is authed with clever, can make clever api calls
                    cleverGetStudents()
                        .then((res) => {
                            console.log(res)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="w-550 m-lr-auto br-15 background-fff">
                                <div className='p-4 bkg-purple br-15'>
                                    <div className='flex flex-v-center w-100'>
                                        <div>
                                            <h1 className="font-bold mb-0 font-40 font-fff">Add student</h1>
                                        </div>
                                        <img src='/add-buttons/add-student.png' alt='add-student' width={220} className='ml-auto' />
                                    </div>
                                </div>
                                <div className='p-4'>
                                    <div className='text-left font-20 p-3 br-4 info-alert-bg mb-2 flex w-100'>
                                        <div>
                                            We are currently integrating with Clever. Once this is completed, your students
                                            will be synced from Clever automatically. Your students
                                            from last year will be automatically archived. Because of this,
                                            we recommend that you do not add students manually and instead wait for the
                                            Clever integration to be completed. If you have any questions, please email
                                            britt@dotit.app.
                                        </div>
                                    </div>
                                    {/* 
                                    <div>
                                        <div className='font-20 mb-1'>Add students from Clever (recommended)</div>
                                        <div>
                                            <Link to={"/teacher/add-students-from-clever/" + this.state.teacherId} type='primary' size='large'
                                                className='ant-btn ant-btn-primary lg-btn inline-flex'>
                                                <Icon type="user-add" className="mr-1 font-30" />
                                                Add students from Clever
                                            </Link>
                                        </div>
                                    </div>
                                    */}
                                    <div>
                                        <div className='font-20 mb-1'>Add a student</div>
                                        <Link to={"/teacher/add-student/" + this.state.teacherId} type='primary' size='large'
                                            className='ant-btn ant-btn-primary lg-btn inline-flex'>
                                            <Icon type="user-add" className="mr-1 font-30" />
                                            Add student
                                        </Link>
                                    </div>
                                    <div>
                                        <div className='font-20 mb-1 mt-4'>Add one or more students from csv file</div>
                                        <Link to={"/teacher/add-student-from-csv/" + this.state.teacherId} type='primary' size='large'
                                            className='ant-btn ant-btn-primary lg-btn inline-flex'>
                                            <Icon type="file" className="mr-1 font-30" />
                                            Add student from csv
                                        </Link>
                                    </div>
                                    {/*
                                    <div>
                                        <div className='font-20 mb-1 mt-4'>Sync students from Clever</div>
                                        <Button type='primary' size='large'
                                            className='ant-btn ant-btn-primary lg-btn inline-flex'
                                            onClick={this.syncStudentsClever}>
                                            <Icon type="sync" className="mr-1 font-30" />
                                            Sync students from Clever
                                        </Button>
                                    </div>*/}
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default TeacherAddStudentHome