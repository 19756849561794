import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Button, Form, Input, notification, Radio } from 'antd'
const FormItem = Form.Item
const { TextArea } = Input
const RadioGroup = Radio.Group

const successMessage = (description) => {
  notification.open({
    type: 'success',
    message: description,
    placement: 'bottomRight'
  })
}

const errorMessage = (description) => {
  notification.open({
    type: 'error',
    message: description,
    placement: 'bottomRight'
  })
}

// Using this to add students until the step form for adding students is done.
class EditTitleForm extends Component {
  state = {
    teacherId: '',
    teacher: [],
    student: null,
    submitting: false,
  }

  componentDidMount() {
    this.props.form.setFieldsValue({
      title: this.props.event.title,
      duration: this.props.event.duration,
    })
  }

  // add a new event to the teacher's events
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          submitting: true,
        }, () => {
          console.log('Received values of form: ', values)

          db.collection(ColType.calendarEvents)
            .doc(this.props.event.id)
            .update({
              title: values.title,
              duration: values.duration,
            })
            .then(() => {
              console.log('Document updated')
              successMessage('Edits made successfully.')
              if (this.props.onEditSuccessful) this.props.onEditSuccessful()
              this.setState({
                submitting: false
              })
            })
            .catch((error) => {
              console.error('Error adding document: ', error)
              this.setState({
                submitting: false
              })
              errorMessage("Something went wrong when trying to edit the group.")
            })
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <Form
        onSubmit={this.handleSubmit} className="block-label-form">
        <FormItem {...formItemLayout} label="Group name">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: 'Group name is required.' }],
          })(
            <TextArea
              placeholder="Group name..."
              autosize={{ minRows: 2 }}
            />

          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Duration">
          {getFieldDecorator('duration', {
            rules: [{ required: true, message: 'Duration is required.' }],
          })(
            <RadioGroup>
              <Radio value={5} disabled={this.props.expandIntoWrongBlock(15)}>
                5 minutes</Radio>
              <Radio className="ml-1" value={10} disabled={this.props.expandIntoWrongBlock(15)}>
                10 minutes</Radio>
              <Radio value={15} disabled={this.props.expandIntoWrongBlock(15)}>
                15 minutes</Radio>
              <Radio value={20} disabled={this.props.expandIntoWrongBlock(30)}>
                20 minutes</Radio>
              <Radio value={30} disabled={this.props.expandIntoWrongBlock(30)}>
                30 minutes</Radio>
              <Radio value={45} disabled={this.props.expandIntoWrongBlock(45)}>
                45 minutes</Radio>
              <Radio value={50} disabled={this.props.expandIntoWrongBlock(45)}>
                50 minutes</Radio>
              <Radio value={60} disabled={this.props.expandIntoWrongBlock(60)}>
                60 minutes</Radio>
              <Radio value={90} disabled={this.props.expandIntoWrongBlock(90)}>
                90 minutes</Radio>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem className="mb-0">
          <Button
            type="primary"
            className="float-right"
            size={'large'}
            htmlType="submit"
            disabled={this.state.submitting}
          >
            OK
          </Button>
        </FormItem>
      </Form>

    )
  }
}

export default Form.create()(EditTitleForm)
