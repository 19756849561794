import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { Empty } from 'antd'

class EmptyComponent extends Component {
  state = {

  }

  componentDidMount() {

  }

  render() {
    var containerClassName = ''
    var width = 32
    var height = 32
    var description = 'No data'
    if (this.props.hasOwnProperty('containerClassName')) {
      containerClassName = this.props.containerClassName
    }
    if (this.props.hasOwnProperty('width')) {
      width = this.props.width
    }
    if (this.props.hasOwnProperty('height')) {
      height = this.props.height
    }
    if (this.props.hasOwnProperty('description')) {
      description = this.props.description
    }

    return (
      <div
        className={containerClassName}
      >
        <Empty
          containerClassName="mt-50 text-center"
          width={width}
          height={height}
          description={description}
          image={this.props.image ? this.props.image : Empty.PRESENTED_IMAGE_DEFAULT}
        />
      </div>
    )
  }
}

export default EmptyComponent