import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { Icon, Tooltip } from 'antd'

class StudentSummary extends Component {
    render() {
        return (
            <div>
                {/*this.props.student &&
                    this.props.student.hasOwnProperty('numGoals') &&
                    this.props.student.hasOwnProperty('numTierOneNotes') &&
                    this.props.student.hasOwnProperty('numProgressMonitoring') ?
                    <div className='flex flex-v-center w-100 text-muted-darker font-16 mt-1'>
                        <div className='mr-2'>
                            <Tooltip title={this.props.student.firstName + ' currently has ' + this.props.student.numGoals + " goal" + (this.props.student.numGoals !== 1 ? 's' : '')}>
                                <div className='flex'>
                                    <div><Icon type='solution' className='mr-1' /></div>
                                    <div style={{ minWidth: '10px' }}>{this.props.student.numGoals}</div>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='mr-2'>
                            <Tooltip title={this.props.student.firstName + ' currently has ' + this.props.student.numTierOneNotes + " tier one note" + (this.props.student.numTierOneNotes !== 1 ? 's' : '')}>
                                <div className='flex'>
                                    <div><Icon type='file-text' className='mr-1' /></div>
                                    <div style={{ minWidth: '10px' }}>{this.props.student.numTierOneNotes}</div>
                                </div>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={this.props.student.firstName + ' currently has ' + this.props.student.numProgressMonitoring + ' progress monitoring measurement' + (this.props.student.numProgressMonitoring !== 1 ? 's' : '')}>
                                <div className='flex'>
                                    <div><Icon type='line-chart' className='mr-1' /></div>
                                    <div style={{ minWidth: '10px' }}>{this.props.student.numProgressMonitoring}</div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
        : ''*/}
            </div>
        )
    }
}

export default StudentSummary