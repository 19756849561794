import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import ColType from '.././Types'
import { Icon, Form, Input, Button, Checkbox, message, Col } from 'antd'
const FormItem = Form.Item

class StateStandardYearForm extends Component {
    state = {
        states: ['SC', 'KY']
    }

    componentDidMount() {
        db.collection(ColType.standardYears)
            .doc('all')
            .get()
            .then((doc) => {
                if (!doc.exists) {
                    console.log('doc doesnt exist')
                    return
                }
                var standardYears = flattenDoc(doc)
                console.log(standardYears)
                this.props.form.setFieldsValue(standardYears)
            })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)

                db.collection(ColType.standardYears)
                    .doc('all')
                    .set(values)
                    .then(() => {
                        message.success('State standard years updated.')
                    })
                    .catch((error) => {
                        console.log(error)
                        message.error('Error updating state standard years: ' + error)
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 6 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 18 },
            },
        }

        const blockItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div className='p-3 border br-4'>
                <div className="font-24 font-bold mb-3">State standard years</div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <FormItem {...blockItemLayout} label={<div className='text-left'>
                        Current academic year (Pacing guide will query this)
                    </div>
                    }>
                        {getFieldDecorator('currentYear', {
                            rules: [{ required: true, message: 'required.' }],
                        })(
                            <Input
                                size={"large"}
                                placeholder="Current year..." />
                        )}
                    </FormItem>
                    {this.state.states.map((state, index) => {
                        return <div key={'state-standards-' + state}>
                            <div className='font-20 mb-1'>{state}</div>
                            <FormItem {...formItemLayout} label="ELA">
                                {getFieldDecorator(state + 'ELA', {
                                    rules: [{ required: true, message: 'required.' }],
                                })(
                                    <Input
                                        size={"large"}
                                        placeholder="ELA..." />
                                )}
                            </FormItem>
                            <FormItem {...formItemLayout} label="Math">
                                {getFieldDecorator(state + 'Math', {
                                    rules: [{ required: true, message: 'required.' }],
                                })(
                                    <Input
                                        size={"large"}
                                        placeholder="Math..." />
                                )}
                            </FormItem>
                            <FormItem {...formItemLayout} label="Science">
                                {getFieldDecorator(state + 'Science', {
                                    rules: [{ required: true, message: 'required.' }],
                                })(
                                    <Input
                                        size={"large"}
                                        placeholder="Science..." />
                                )}
                            </FormItem>
                            <FormItem {...formItemLayout} label="Social Studies">
                                {getFieldDecorator(state + 'Social Studies', {
                                    rules: [{ required: true, message: 'required.' }],
                                })(
                                    <Input
                                        size={"large"}
                                        placeholder="Social Studies..." />
                                )}
                            </FormItem>
                        </div>
                    })}

                    <FormItem className="mb-0">
                        <Button
                            size={"large"}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button text-align-center w-100"
                        >
                            Update state standard years
                        </Button>
                    </FormItem>
                </Form>
            </div>
        )
    }
}

export default Form.create()(StateStandardYearForm)