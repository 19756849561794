import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { getIDFromURL, getQueryStringParam } from '../Util'
import InstructionalFacilitatorSchoolView from './InstructionalFacilitatorSchoolView'

class InstructionalFacilitatorSchool extends Component {
    state = {
        instructionalFacilitatorId: '',
        selectedId: ''
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Dashboard - Dot It'

        let instructionalFacilitatorId = getIDFromURL(window.location)
        let selectedId = getQueryStringParam('s')


        this.setState({
            instructionalFacilitatorId: instructionalFacilitatorId,
            selectedId: selectedId,
        })
    }

    componentWillReceiveProps(nextProps) {
        let selectedId = getQueryStringParam('s')

        this.setState({
            selectedId: selectedId,
        })
    }

    render() {
        console.log('sch', this.props, this.state)
        return (
            <div>
                {this.state.instructionalFacilitatorId &&
                    this.props.instructionalFacilitatorBaseProps.schools &&
                    this.state.selectedId
                    ?
                    <div>
                        <InstructionalFacilitatorSchoolView
                            instructionalFacilitatorBaseProps={this.props.instructionalFacilitatorBaseProps}
                            history={this.props.history}
                            schoolId={this.state.selectedId}
                        />
                    </div>
                    : ''}
            </div>
        )
    }
}

export default InstructionalFacilitatorSchool