import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import { Store } from './Store'
//import EditModal from './EditModal'
import EditTitleForm from './EditTitleForm'
import ColType from '../.././Types'
import { notification, Popover, Icon, Button, Tooltip, Popconfirm, Tabs } from 'antd'
const TabPane = Tabs.TabPane
var moment = require('moment')

const successMessage = (description) => {
  notification.open({
    type: 'success',
    message: description,
    placement: 'bottomRight'
  })
}

const errorMessage = (description) => {
  notification.open({
    type: 'error',
    message: description,
    placement: 'bottomRight'
  })
}

class BlockDragAndDrop extends Component {
  state = {
    el: null,
    isOver: false,
    isValidDrop: true,
    invalidDropMessage: '',
    isDragging: false,
    popoverVisible: false,
    errorPopoverVisible: false,
    popoverTimeout: setTimeout(() => { }),
    initialDoNotcloseCalendarPopover: false,
    calendarPopoverCloseClicked: false,
    calendarPopoverVisible: false,
    isCopied: false,
    componentMounted: false,
  }

  handleClickOutside = (event) => {
    // get the element of this inputnumber component instance
    var el = this.popoverRef.current.getPopupDomNode()

    // if null return
    if (el === null) {
      return
    }

    let containsTarget = el.contains(event.target)

    // the first click to show the popover will be necessarily outside
    // of the popover, so it will be closed immediately unless we
    // return the first time it was set
    if (this.state.initialDoNotcloseCalendarPopover) {
      this.setState({
        initialDoNotcloseCalendarPopover: false,
      })
      return
    }
    if (this.state.calendarPopoverCloseClicked) {
      this.setState({
        calendarPopoverCloseClicked: false,
        calendarPopoverVisible: false,
      })
      return
    }

    // hide immediately instead of fading out
    if (!containsTarget) {
      el.classList.add('ant-popover-hidden')
    }

    this.setState({
      calendarPopoverVisible: containsTarget
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  componentDidMount() {
    this.popoverRef = React.createRef()

    document.addEventListener('click', this.handleClickOutside)

    if (this.props.event) {
      var duration = this.props.event.duration// in minutes
      if (duration < 15) {
        duration = 15
      }
      if (duration === 20) {
        duration = 30
      }
      if (duration === 50) {
        duration = 45
      }
      var dayStart = moment.utc(this.props.dayStart)
      var dayEnd = moment.utc(this.props.dayEnd)
      var dayDuration = 615// dayEnd.diff(dayStart, 'minutes')
      var eventStart = moment.utc(this.props.event.start)
      var eventEnd = moment.utc(eventStart).add(duration, 'minutes')
      var eventStartDiff = eventStart.diff(dayStart, 'minutes')
      var eventStartTop = eventStartDiff / dayDuration * 100

      this.setState({
        dayStart: dayStart,
        dayEnd: dayEnd,
        eventStart: eventStart,
        eventEnd: eventEnd,
        eventTopPercentage: eventStartTop,
        durationRatio: duration / dayDuration * 100,
        componentMounted: true
      })
    } else {
      this.setState({
        componentMounted: true,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.event) {
      var duration = nextProps.event.duration// in minutes
      if (duration < 15) {
        duration = 15
      }
      if (duration === 20) {
        duration = 30
      }
      if (duration === 50) {
        duration = 45
      }
      var dayStart = moment.utc(nextProps.dayStart)
      var dayEnd = moment.utc(nextProps.dayEnd)
      var dayDuration = 615// dayEnd.diff(dayStart, 'minutes')
      var eventStart = moment.utc(nextProps.event.start)
      var eventEnd = moment.utc(eventStart).add(duration, 'minutes')
      var eventStartDiff = eventStart.diff(dayStart, 'minutes')
      var eventStartTop = eventStartDiff / dayDuration * 100

      this.setState({
        dayStart: dayStart,
        dayEnd: dayEnd,
        eventStart: eventStart,
        eventEnd: eventEnd,
        eventTopPercentage: eventStartTop,
        durationRatio: duration / dayDuration * 100,
        componentMounted: true
      })
    }
  }

  handlePopoverVisibleChange = (visible) => {
    this.setState({ popoverVisible: visible })
  }

  closePopover = () => {
    this.setState({
      popoverVisible: false,
    })
  }

  deleteEvent = () => {
    db.collection(ColType.calendarEvents)
      .doc(this.props.event.id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!")
        successMessage('Group deleted successfully.')
      }).catch((error) => {
        console.error("Error removing document: ", error)
        errorMessage('Something went wrong deleting the group.')
      })
  }

  expandIntoWrongBlock = (duration) => {
    var dropMoment = moment.utc(this.props.event.start)
    var dragEndMoment = moment.utc(this.props.event.start).add(duration, 'minutes')
    var events = Store['schedule'].events

    for (var i = 0; i < events.length; i++) {
      var event = events[i]
      var start = moment.utc(event.start)

      // event starts before another event
      if (dropMoment.isBefore(start) &&
        // event ends in between another event
        dragEndMoment.isAfter(start)) {
        // event would breach an event of a different service type
        return true
        /**if (event.servicedIn !== servicedIn) {
          return true
        }
        // event would breach an event of the same service type
        else {
          // should they merge?
        }*/
      }
    }

    return false
  }

  drop = (e) => {
    if (this.props.readOnly) {
      return
    }
    e.preventDefault()
    clearTimeout(this.state.popoverTimeout)

    var dragProps = Store['schedule'].dragProps
    var dragDuration = Store['schedule'].dragProps.dragName === 'block' ?
      parseInt(Store['schedule'].dragProps.event.duration, 10) :
      Store['schedule'].dragProps.iep !== null ?
        parseInt(Store['schedule'].dragProps.iep.iep.service.serviceDuration, 10) :
        15 // student drop defaults to 15 min
    var dropDuration = dragDuration > parseInt(this.props.event.duration, 10) ?
      dragDuration :
      parseInt(this.props.event.duration, 10)
    var ieps = this.props.event.ieps
    var studentsGeneral = this.props.event.studentsGeneral

    // title
    var dragTitle = dragProps.dragName !== 'block' ? 'No title' : dragProps.event.title
    var dropTitle = this.props.event.title
    var combinedTitle = dropTitle
    if (dragTitle === "No title" && dropTitle === "No title") combinedTitle = "No title"
    else if (dragTitle !== "No title" && dropTitle === "No title") combinedTitle = dragTitle
    else if (dragTitle === "No title" && dropTitle !== "No title") combinedTitle = dropTitle
    else combinedTitle = dragTitle + ' / ' + dropTitle

    if (!this.validDrop(true)) return

    if (dragProps.dragName !== 'block' && dragProps.dragName !== 'teacher') {
      // remove drag iep from the old block
      // This was for when you could drag a goal in a black to another block.
      /**if (dragProps.event) {
        var dragIEPS = dragProps.event.ieps
        var idIndex = dragIEPS.indexOf(dragProps.iep.id)
        var iepIndex = dragProps.ieps.indexOf(dragProps.iep)
        if (idIndex > -1) {
          dragIEPS.splice(idIndex, 1)
          dragProps.ieps.splice(iepIndex, 1)
        } else {
          errorMessage('Something went wrong.')
          return
        }

        // if there are still ieps in the block update it
        if (dragIEPS.length > 0) {
          var maxDuration = 0
          dragProps.ieps.map((item, index) => {
            console.log(parseInt(item.iep.service.serviceDuration, 10))
            if (parseInt(item.iep.service.serviceDuration, 10) > maxDuration) {
              maxDuration = parseInt(item.iep.service.serviceDuration, 10)
            }
            return false
          })

          db.collection(ColType.calendarEvents)
            .doc(dragProps.event.id)
            .update({
              duration: maxDuration,
              ieps: dragIEPS,
            })
            .then(() => {

            })
        }
        // else remove the block
        else {
          db.collection(ColType.calendarEvents)
            .doc(dragProps.event.id)
            .delete()
        }
      }*/

      if (dragProps.iep !== null) {
        // add drag iep to the new block
        ieps.push({
          iepId: dragProps.iep.id,
          iepDuration: dragProps.iep.iep.service.serviceDuration
        })
      } else {
        studentsGeneral.push(dragProps.student.id)
      }


      db.collection(ColType.calendarEvents)
        .doc(this.props.event.id)
        .update({
          duration: dropDuration,
          ieps: ieps,
          studentsGeneral: studentsGeneral,
          title: combinedTitle,
        })
        .then(() => {
          if (dragProps.dragName !== 'block') {
            if (dragProps.iep !== null) successMessage('The plan was added to the group.')
            else successMessage('The student was successfully added to the group')
          }
        })
    }

    this.setState({
      isOver: false,
      invalidDropMessage: '',
      isValidDrop: true,
      errorPopoverVisible: false,
    })
  }

  validDrop = (didDrop) => {
    if (this.props.readOnly) {
      return false
    }
    var dragProps = Store['schedule'].dragProps
    var dragDuration = Store['schedule'].dragProps.dragName === 'block' ?
      parseInt(Store['schedule'].dragProps.event.duration, 10) :
      Store['schedule'].dragProps.iep !== null ?
        parseInt(Store['schedule'].dragProps.iep.iep.service.serviceDuration, 10) :
        15 // student drop defaults to 15 min
    var servicedIn = Store['schedule'].dragProps.dragName === 'block' ?
      Store['schedule'].dragProps.event.servicedIn :
      Store['schedule'].dragProps.iep !== null ?
        Store['schedule'].dragProps.iep.iep.service.servicedIn :
        Store['schedule'].dragProps.servicedIn
    //var otherServiceCategory = servicedIn === 'General Education' ?
    //  'Special Education' : 'General Education'


    if (servicedIn !== this.props.event.servicedIn) {
      var invalidDropMessage = 'This group is for plans serviced in ' +
        this.props.event.servicedIn.toLowerCase() + '.'
      if (this.props.event.servicedIn === "Teacher Event") {
        invalidDropMessage = 'Cannot add plans to a teacher event.'
      }
      if (didDrop) {
        this.dragEnd() // clear drop blocks isOver state
        if (dragProps.dragName !== 'block') errorMessage(invalidDropMessage)
      } else {
        this.setState({ invalidDropMessage: invalidDropMessage })
      }
      return false
    }
    if (this.expandIntoWrongBlock(dragDuration)) {
      //invalidDropMessage = 'This would cause the group to overlap into a ' +
      //  otherServiceCategory.toLowerCase() + ' group.'
      invalidDropMessage = 'This would cause the group to overlap with another group.'
      if (didDrop) {
        this.dragEnd() // clear drop blocks isOver state
        if (dragProps.dragName !== 'block') errorMessage(invalidDropMessage)
      } else {
        this.setState({ invalidDropMessage: invalidDropMessage })
      }
      return false
    }

    return true
  }

  dragStart = (e) => {
    if (this.props.readOnly) {
      return
    }
    console.log(this.props)

    Store['schedule'].dragProps = this.props
    this.props.setIsDragging(true)
    e.dataTransfer.setData('text', 'foo') // firefox needs this to do drag and drop
    //var el = document.getElementById('event-container-' + this.props.event.id)
    var el = document.createElement("img")
    el.src = this.props.servicedIn === "Teacher Event" ? "/teacher-drag-circle.PNG" :
      this.props.servicedIn === "General Education" ?
        "/ge-drag-circle.PNG" :
        "/se-drag-circle.PNG"
    e.dataTransfer.setDragImage(el,
      25, 0)
    setTimeout(() => this.setState({
      isDragging: true,
    })
    )
  }

  dragEnd = (e) => {
    if (this.props.readOnly) {
      return
    }
    console.log('drag end')
    clearTimeout(this.state.popoverTimeout)
    this.props.setIsDragging(false)
    setTimeout(() => this.setState({
      isDragging: false,
      invalidDropMessage: '',
      isValidDrop: true,
      isOver: false,
      errorPopoverVisible: false,
    })
    )
  }

  dragOver = (e) => {
    if (this.props.readOnly) {
      return
    }
    e.preventDefault()
    if (this.state.isOver) return
    var isValidDrop = Store['schedule'].dragProps.hasOwnProperty('dragName') &&
      Store['schedule'].dragProps.dragName !== 'block' &&
      Store['schedule'].dragProps.dragName !== 'teacher' ?
      this.validDrop(false) :
      false

    if (!isValidDrop &&
      Store['schedule'].dragProps.hasOwnProperty('dragName') &&
      Store['schedule'].dragProps.dragName !== 'block' &&
      Store['schedule'].dragProps.dragName !== 'teacher') {
      this.setState({
        popoverTimeout: setTimeout(() => {
          this.setState({
            errorPopoverVisible: true,
          })
        }, 500)
      })
    }

    this.setState({
      isValidDrop: isValidDrop,
      isOver: true
    })
  }

  dragExit = (e) => {
    e.preventDefault()
    if (!this.state.isOver) return
    clearTimeout(this.state.popoverTimeout)

    this.setState({
      errorPopoverVisible: false,
      isValidDrop: false,
      isOver: false,
    })
  }

  copyEvent = (e) => {
    Store['schedule'].dragProps = this.props
    Store['schedule'].setIsCopyPasting(true)
  }

  render() {
    return (
      <Popover
        mouseEnterDelay={.5}
        content={<div className="w-230px">
          {this.state.invalidDropMessage}
        </div>}
        title={
          <span>
            <Icon type="warning" className="mr-1 text-warning" />
            <span>Cannot drop at this location</span>
          </span>
        }
        visible={this.state.errorPopoverVisible}
      >
        <div draggable={this.props.readOnly ? false : true}
          onDragStart={this.props.readOnly ? (e) => { } : this.dragStart}
          onDragEnd={this.props.readOnly ? (e) => { } : this.dragEnd}
          onDrop={this.props.readOnly ? (e) => { } : this.drop}
          onDragOver={this.props.readOnly ? (e) => { } : this.dragOver}
          onDragLeave={this.props.readOnly ? (e) => { } : this.dragExit}
          className={'w-100' +
            (this.state.isDragging ? ' pointer-events-none opacity-50' : '')
          }
          style={this.state.componentMounted ?
            {
              position: 'absolute',
              top: 'calc(' + this.state.eventTopPercentage + '%' +
                (this.props.event.index > 5 ? ' + 47px)' : ')'),
              left: 100 / 6 * (this.props.event.index % 6) + '%',
              //height: '47px',
              // for height that spans multiple rows use this
              height: 'calc(' + this.state.durationRatio + '%' +
                (this.props.event.index > 5 ? ' - 47px)' : ')'),
            }
            : {}}
        >
          <Popover
            content={
              <div className="w-500 p-2 relative">
                <div className="absolute-tr pt-1">
                  {!this.props.readOnly ?
                    <span>
                      <Tooltip
                        title="Copy Calendar Event"
                        placement="bottom"
                      >
                        <Button className="transparent-btn mr-1" shape="circle"
                          onClick={(e) => {
                            // the event will bubble up to the document click handler, and will
                            // do the appropriate action for a close click
                            this.setState({
                              calendarPopoverCloseClicked: true,
                            })

                            this.copyEvent(e)
                          }}
                        >
                          <Icon type="copy" className="font-20" />
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title="Delete"
                        placement="bottom"
                      >
                        <Popconfirm title="Do you want to delete this event?"
                          onConfirm={this.deleteEvent} onCancel={() => {
                            this.setState({
                              initialDoNotcloseCalendarPopover: true,
                            })
                          }} okText="Yes" cancelText="No"
                          placement="bottomRight">
                          <Button className="transparent-btn mr-3" shape="circle">
                            <Icon type="delete" className="font-20" />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </span>
                    : ''}

                  <Button className="transparent-btn" shape="circle" onClick={(e) => {
                    // the event will bubble up to the document click handler, and will
                    // do the appropriate action for a close click
                    this.setState({
                      calendarPopoverCloseClicked: true,
                    })
                  }}>
                    <Icon type="close" className="font-20" />
                  </Button>
                </div>
                <h1 className="font-24 mb-0 font-bold">
                  {this.props.event.title}
                </h1>
                <h2 className={"font-20" + (this.props.servicedIn === "Teacher Event" ? " mb-2" : " mb-0")}>
                  <div className="font-20 text-left">
                    {moment.utc(this.props.event.startTime.seconds * 1000).format("hh:mm A").replace(/^(?:00:)?0?/, '') +
                      ' - ' + moment.utc(this.props.event.startTime.seconds * 1000)
                        .add(this.props.event.duration, 'minutes')
                        .format("hh:mm A").replace(/^(?:00:)?0?/, '')
                    }
                  </div>
                </h2>
                {this.props.servicedIn !== "Teacher Event" ?
                  <div>
                    <div className="font-20 font-bold mb-2 flex flex-v-center">
                      <div>
                        <span className="font-black font-normal" style={{ marginRight: '6px' }}>Setting:</span>
                        {this.props.servicedIn === 'General Education' ?
                          <span className="text-ge">
                            General Education
                          </span>
                          : ''}

                        {this.props.servicedIn === 'Special Education' ?
                          <span className="text-se">
                            Special Education
                          </span>
                          : ''}
                      </div>
                    </div>
                    <Button onClick={(e) => {
                      this.setState({
                        calendarPopoverCloseClicked: true,
                      })
                      this.props.setGroupModalVisibility(true, this.props.event)
                    }}
                      type="primary"
                      className="mb-2 font-16 flex flex-v-center">
                      <Icon type="team" className="font-18 font-bold" />
                      Group Goal Guide
                    </Button>
                  </div>
                  : ''}

                {this.props.readOnly && this.props.servicedIn === 'Teacher Event' ?
                  <h2>{this.props.event.title}</h2>
                  :

                  <Tabs
                    defaultActiveKey={
                      this.props.servicedIn !== 'Teacher Event' ? '1' : '2'
                    }
                    animated={false}>
                    {this.props.servicedIn !== 'Teacher Event' ?
                      <TabPane tab="Students / Plans" key="1">
                        <div className={"mt-2"}>
                          <div className="overflow-y-scroll" style={{ maxHeight: '250px' }}>
                            {this.props.children}
                          </div>
                        </div>
                      </TabPane>
                      : ''}
                    {!this.props.readOnly ?
                      <TabPane tab="Edit Event" key="2">
                        <div className={"pt-3 pl-2 pr-2"}>
                          <EditTitleForm
                            teacher={this.props.teacher}
                            event={this.props.event}
                            expandIntoWrongBlock={this.expandIntoWrongBlock}
                            onEditSuccessful={this.hideModal}
                          />
                        </div>
                      </TabPane>
                      : ''}
                  </Tabs>}
              </div>}
            title={null} trigger="click" placement={"left"}
            ref={this.popoverRef}
            visible={this.state.calendarPopoverVisible}>
            <div className={"w-100-minus-3 h-100-minus-3 overflow-hidden ant-shadow relative " +
              "parent-hover" +
              (this.state.isDragging ? ' pointer-events-none opacity-50' : ' cursor-grab') +
              (this.props.readOnly ? ' cursor-pointer' : '') +
              (this.props.event.servicedIn === 'Teacher Event' ? ' border-teacher background-fff' :
                this.props.event.servicedIn === 'General Education' ?
                  ' border-lge background-l-lge' : ' border-lse background-l-lse') +
              (this.state.isOver ?
                (this.state.isValidDrop ? ' border-success' : ' border-error')
                : '')
            }
              style={{ borderRadius: '12px', marginLeft: '4px' }}
              id={'event-container-' + this.props.event.id}
              onClick={(e) => {
                this.setState({
                  initialDoNotcloseCalendarPopover: true,
                  calendarPopoverVisible: true,
                })
              }}
            >
              <div className="w-100 mh-100 overflow-hidden p-1 flex">
                <span className="font-16 ellipsis">{this.props.event.title}</span>
              </div>
              <div className={'w-100 p-05 pl-1 font-12'}
                style={{ position: 'absolute', 'left': '0px', 'bottom': '0px' }}>
                {(this.props.event.servicedIn === 'Teacher Event' ? 'Teacher Event' :
                  this.props.event.servicedIn === 'General Education' ?
                    'General Education' : 'Special Education')}
              </div>
            </div>
          </Popover>
        </div>
      </Popover>
    )
  }
}

export default BlockDragAndDrop