import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import { db } from '../firebase/Firebase'
//import ColType from '../Types'
import CustomFooter from '../login/CustomFooter'
//import SubjectHistogram from '../admin/SubjectHistogram'
//import SupportPieChartTiers from '../admin/SupportPieChartTiers'
import Joyride, { STATUS } from 'react-joyride'
//import VideoContainer from '../video/VideoContainer'
import PersonAvatar from '../customcomponents/PersonAvatar'
import Empty from '../customcomponents/Empty'
import { getIDFromURL, isReadOnlyTeacherId, getLevelColor, addStudentOnboardTeacherStats, draftPlanOnboardTeacherStats } from '.././Util'
import { Layout, Row, Col, Button, Modal, Alert, Checkbox, Select, Tabs, Icon } from 'antd'
//import TransferGoals from './student/TransferGoals'
const { Content } = Layout
const Option = Select.Option

class TeacherHome extends Component {
  state = {
    teacherId: '',
    teachers: [],
    students: [],
    school: null,
    IEPGoals: [],
    deleteDropdownVisible: {},
    deleteStudentModalVisible: false,
    deleteStudentModalCloseTimeout: null,
    deletingStudents: {},
    studentIEPStatus: [],
    profileMode: 1,
    componentMounted: false,
    studentsWithGoalsPercent: 0,
    goalSubjects: {
      'Writing': 0,
      'Reading Comprehension in Literature': 0,
      'Reading Comprehension in Informational Text': 0,
      'Reading Foundations': 0,
      'Math': 0,
      'Social Emotional Learning': 0,
      'total': 0,
    },
    support: {
      low: 0,
      medium: 0,
      high: 0,
      tier2: 0,
      tier3: 0,
    },
    onlyShowStudentsWithPlans: false,
    onlyShowGrade: 'all',
    visible: false,
    onboardModalVisible:
      this.props.teacherBaseProps.hasOwnProperty("onboardAlreadyShown") &&
        this.props.teacherBaseProps.setOnboardAlreadyShown
        ?
        false
        :
        true,
    onboardRun: false,
    onboardAddStudentSteps: [
      {
        placement: 'center',
        target: 'body',
        content:
          <div>
            <img src="/onboard/1v2.png" alt="onboard-home" className="w-100" />
            <div>
              <h1 className="font-bold mb-0 font-24 mt-2">Welcome to Dot It!</h1>
              <h3 className="font-20">Dot It was made to make your life easier. If you ever
                have any questions please don't hesitate to click the "Need help?" button or email
                support@dotit.app.</h3>
            </div>
          </div>,
      }
    ],
    onboardDraftPlanSteps: [
      {
        target: '.onboard-add-iep',
        placement: 'left',
        content: <div>
          <img src="/onboard/4v2.png" alt="onboard-home" className="w-100" />
          <div>
            <h1 className="font-bold mb-0 font-30 mt-2">Draft your first IEP!</h1>
            <h3 className="font-24">If you are a special education teacher, click the "Draft an IEP" to start drafting your first IEP.</h3>
          </div>
        </div>,
        disableBeacon: true,
      }, {
        target: '.onboard-add-tier-plan',
        placement: 'left',
        content: <div>
          <img src="/onboard/5v2.png" alt="onboard-home" className="w-100" />
          <div>
            <h1 className="font-bold mb-0 font-30 mt-2">Draft your first tier plan!</h1>
            <h3 className="font-24">If you are a general education teacher, click the "Draft a tier plan" to start drafting your first tier plan.</h3>
          </div>
        </div>,
        disableBeacon: true,
      },
    ],
    onboardActiveSteps: [],
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.teacherBaseProps.hasOwnProperty("teacherId") &&
      nextProps.teacherBaseProps.teacherId &&
      !isReadOnlyTeacherId(nextProps.teacherBaseProps.teacherId)) {
      this.setState({
        onboardModalVisible: false,
      })
    }

    this.checkIfOnboardNeedsToStart()

    // if has data and students.length > 0 (no divide by zeroes)
    if (nextProps.teacherBaseProps.studentDict &&
      nextProps.teacherBaseProps.studentRequiresAction &&
      nextProps.teacherBaseProps.IEPGoals &&
      nextProps.teacherBaseProps.students &&
      nextProps.teacherBaseProps.students.length > 0) {
      var studentsWithGoals = 0
      nextProps.teacherBaseProps.students.map((student, idx) => {
        if (nextProps.teacherBaseProps.studentRequiresAction
          .hasOwnProperty(student.id)) {
          studentsWithGoals += 1
        }
        return false
      })
      var pct = 0
      if (studentsWithGoals > 0) {
        pct = Math.floor((studentsWithGoals.toFixed(2) /
          nextProps.teacherBaseProps.students.length.toFixed(2)) * 100)
      }

      var goalSubjects = this.state.goalSubjects
      var support = this.state.support
      support.low = 0
      support.medium = 0
      support.high = 0
      support.tier2 = 0
      support.tier3 = 0
      nextProps.teacherBaseProps.IEPGoals.map((IEPGoal, idx) => {
        if (goalSubjects.hasOwnProperty(IEPGoal.iep.mainSubject)) {
          goalSubjects[IEPGoal.iep.mainSubject] += 1
        }
        goalSubjects.total += 1
        if (IEPGoal.hasOwnProperty("isMTSS") && IEPGoal.isMTSS) {
          if (IEPGoal.iep.tier === "Tier 2") {
            support.tier2 += 1
          } else if (IEPGoal.iep.tier === "Tier 3") {
            support.tier3 += 1
          }
        }
        else if (IEPGoal.iep.level === '70 and below') {
          support.high += 1
        }
        else if (IEPGoal.iep.level === '70-79') {
          support.medium += 1
        }
        else if (IEPGoal.iep.level === '80 and above') {
          support.low += 1
        }
        return false
      })

      this.setState({
        studentsWithGoalsPercent: pct,
        goalSubjects: goalSubjects,
        support: support,
      })
    }
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Plans - Dot It'

    var teacherId = getIDFromURL(window.location)

    this.setState({
      teacherId: teacherId,
    })

    this.checkIfOnboardNeedsToStart()

    // if has data and students.length > 0 (no divide by zeroes)
    if (this.props.teacherBaseProps.studentDict &&
      this.props.teacherBaseProps.studentRequiresAction &&
      this.props.teacherBaseProps.IEPGoals &&
      this.props.teacherBaseProps.students &&
      this.props.teacherBaseProps.students.length > 0) {
      var studentsWithGoals = 0
      this.props.teacherBaseProps.students.map((student, idx) => {
        if (this.props.teacherBaseProps.studentRequiresAction
          .hasOwnProperty(student.id)) {
          studentsWithGoals += 1
        }
        return false
      })
      var pct = 0
      if (studentsWithGoals > 0) {
        pct = Math.floor((studentsWithGoals.toFixed(2) /
          this.props.teacherBaseProps.students.length.toFixed(2)) * 100)
      }

      var goalSubjects = this.state.goalSubjects
      var support = this.state.support
      support.low = 0
      support.medium = 0
      support.high = 0
      support.tier2 = 0
      support.tier3 = 0
      this.props.teacherBaseProps.IEPGoals.map((IEPGoal, idx) => {
        if (goalSubjects.hasOwnProperty(IEPGoal.iep.mainSubject)) {
          goalSubjects[IEPGoal.iep.mainSubject] += 1
        }
        goalSubjects.total += 1
        if (IEPGoal.hasOwnProperty("isMTSS") && IEPGoal.isMTSS) {
          if (IEPGoal.iep.tier === "Tier 2") {
            support.tier2 += 1
          } else if (IEPGoal.iep.tier === "Tier 3") {
            support.tier3 += 1
          }
        }
        else if (IEPGoal.iep.level === '70 and below') {
          support.high += 1
        }
        else if (IEPGoal.iep.level === '70-79') {
          support.medium += 1
        }
        else if (IEPGoal.iep.level === '80 and above') {
          support.low += 1
        }
        return false
      })

      this.setState({
        studentsWithGoalsPercent: pct,
        goalSubjects: goalSubjects,
        support: support,
      })
    }
  }

  onDeleteDropdownVisibleChange = (studentId, visible) => {
    var deleteDropdownVisible = this.state.deleteDropdownVisible
    deleteDropdownVisible[studentId] = visible
    this.setState({
      deleteDropdownVisible: deleteDropdownVisible,
    })
  }

  handleJoyrideCallback = (data) => {
    const { status } = data

    if (data.action === 'close') {
      this.setState({
        onboardRun: false,
      })
    }

    if (data.hasOwnProperty('lifecycle') && data.lifecycle === 'complete' && status === STATUS.FINISHED) {
      // onboard is finished
    }
  }

  checkIfOnboardNeedsToStart = () => {
    if (!this.props.teacherBaseProps.teacher) {
      return
    }
    if (isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) {
      return
    }

    if (this.props.teacherBaseProps &&
      this.props.teacherBaseProps.teacherStats &&
      !this.state.onboardRun) {
      // add student onboard hasn't been shown
      if (!this.props.teacherBaseProps.teacherStats.hasOwnProperty("onboardAddStudentShown") ||
        (this.props.teacherBaseProps.teacherStats.hasOwnProperty("onboardAddStudentShown") &&
          !this.props.teacherBaseProps.teacherStats.onboardAddStudentShown)) {
        // no students have ever been added, show initial onboard, checking this as
        // existing users who already have added a student already know how to.
        if (!this.props.teacherBaseProps.teacherStats.hasOwnProperty("numStudents") ||
          (this.props.teacherBaseProps.teacherStats.hasOwnProperty("numStudents") &&
            this.props.teacherBaseProps.teacherStats.numStudents === 0)) {

          // set flag in the teacher stats doc to not show this onboarding event again
          addStudentOnboardTeacherStats(this.props.teacherBaseProps.teacher)

          this.setState({
            onboardRun: true,
            onboardActiveSteps: this.state.onboardAddStudentSteps,
          })
        }
      }
      // draft plan onboard hasn't been shown and onboardAddStudent has been shown
      else if ((this.props.teacherBaseProps.teacherStats.hasOwnProperty("onboardAddStudentShown") &&
        this.props.teacherBaseProps.teacherStats.onboardAddStudentShown) &&
        (!this.props.teacherBaseProps.teacherStats.hasOwnProperty("onboardDraftPlanShown") ||
          (this.props.teacherBaseProps.teacherStats.hasOwnProperty("onboardDraftPlanShown") &&
            !this.props.teacherBaseProps.teacherStats.onboardDraftPlanShown))) {
        // a student must be added before showing the draft plan onboard
        if (this.props.teacherBaseProps.teacherStats.hasOwnProperty("numStudents") &&
          this.props.teacherBaseProps.teacherStats.numStudents > 0) {
          // no plans have been added, show onboard, checking this as existing users
          // who have already drafted a plan know how to.
          // no students have ever been added, show initial onboard, checking this as
          // existing users who already have added a student already know how to.
          if (!this.props.teacherBaseProps.teacherStats.hasOwnProperty("numGoals") ||
            (this.props.teacherBaseProps.teacherStats.hasOwnProperty("numGoals") &&
              this.props.teacherBaseProps.teacherStats.numGoals === 0)) {
            // set flag in the teacher stats doc to not show this onboarding event again
            draftPlanOnboardTeacherStats(this.props.teacherBaseProps.teacher)

            /*
            this.setState({
              onboardRun: true,
              onboardActiveSteps: this.state.onboardDraftPlanSteps,
            })
            */
          }
        }
      }
    }
  }

  getOnlyShowGradeNumGoals = (students, onlyShowGrade) => {
    let onlyShowGradeNumGoals = 0
    if (onlyShowGrade !== 'all' && students) {
      students.map((student, idx) => {
        let numGoals = 0
        if (this.props.teacherBaseProps.studentIEPDict &&
          this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(student.id)) {
          numGoals = this.props.teacherBaseProps.studentIEPDict[student.id].length
        }
        if (this.state.onlyShowStudentsWithPlans && numGoals === 0) {
          return false
        }
        if (onlyShowGrade !== 'all' && student.grade !== onlyShowGrade) {
          return false
        }
        // just += 1 always, as numGoals can be 0 and this is just for
        // displaying an <Empty> component
        onlyShowGradeNumGoals += 1
        return false
      })
    }

    return onlyShowGradeNumGoals
  }

  render() {
    var sandboxNumGoalsRemaining = 10
    if (this.props.teacherBaseProps.teacherStats &&
      this.props.teacherBaseProps.teacherStats.hasOwnProperty("numGoals") &&
      this.props.teacherBaseProps.teacherStats.numGoals) {
      sandboxNumGoalsRemaining = 10 - this.props.teacherBaseProps.teacherStats.numGoals
      if (sandboxNumGoalsRemaining < 0) {
        sandboxNumGoalsRemaining = 0
      }
    }

    return (
      <div>
        <Joyride
          run={this.state.onboardRun}
          steps={this.state.onboardActiveSteps}
          continuous
          showProgress
          showSkipButton
          callback={this.handleJoyrideCallback}
          styles={{
            options: {
              primaryColor: '#13c2c2',
            }
          }}
        />

        <Layout className="content layout-header-mt">
          <div>

            <div className="sub-menu-width m-lr-auto mt-4">
              {/*
              <Alert
                className='mb-3 alert-icon-mt br-4'
                message={<div className='font-24 font-black font-bold'>Benchmark Announcement</div>}
                description={<div className='font-20 font-black'>
                  The Benchmark is now available. To access it, please go to the Pacing Guide and navigate to week 9 for your specific grade and subject.<br /><br />

                  If you have any questions or encounter any issues, please email support@dotit.app.<br /><br />

                  Thank you!
                </div>
                }
                type="info"
                showIcon
              />
              */}

              <div className='br-20 overflow-hidden mb-3'>
                <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                  style={{ height: '280px', }}>

                  <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#333' }}>
                  </div>
                  <div className="absolute-tl w-100 h-100 image-box-mask"
                    style={{ backgroundImage: 'url(/banner-4-new.png)' }}
                  >
                  </div>
                  <div className='absolute-tl w-100 h-100'>
                    <div className='h-100'>
                      <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                        <div className='w-100'>
                          <div className='font-40 font-bold font-fff'>Students</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div >

          <Layout className="mt-0">
            <Content className="mt-0">

              <div className="sub-menu-width m-lr-auto">
                {this.state.teacherId ?
                  <div>
                    <Modal
                      visible={this.state.onboardModalVisible}
                      title={null}
                      footer={null}
                      onCancel={(e) => this.setState({
                        onboardModalVisible: false,
                      })}
                    >
                      <div className="text-center">
                        <img src="/onboard/10.png" alt="onboard-modal" width={320} />

                        <h1 className="font-bold mb-0">Welcome to Dot It!</h1>

                        <h2 className="mb-2">Click on any of the sample students below to see an IEP or a tier plan.</h2>
                        {/*<Button className={"ant-btn ant-btn-lg mr-2 font-20 font-bold"}
                          onClick={(e) => {
                            this.props.teacherBaseProps.setOnboardAlreadyShown(true)
                            this.setState({
                              onboardModalVisible: false,
                            })
                          }
                          }
                        >
                          No
                        </Button>*/}
                        <Button className={"ant-btn ant-btn-lg ant-btn-primary font-20 font-bold"}
                          onClick={(e) => {
                            this.props.teacherBaseProps.setOnboardAlreadyShown(true)
                            this.setState({
                              onboardModalVisible: false,
                            })
                          }}>
                          Ok
                        </Button>
                      </div>
                    </Modal>
                    <Modal
                      title={null}
                      visible={this.state.deleteStudentModalVisible}
                      footer={null}
                      onCancel={(e) => {
                        this.setState({
                          deleteStudentModalVisible: false,
                          deleteStudentModalCloseTimeout: setTimeout(() => {
                            this.setState({
                              deleteStudent: null,
                            })
                          }, 100)
                        })
                      }}
                    >
                      {this.state.deleteStudent ?
                        <PersonAvatar
                          person={this.state.deleteStudent}
                          size={'large'}
                          containerClassName="font-20 mt-4"
                          avatarClassName="font-20 font-bold"
                          personClassName="font-bold"
                        /> : ''}
                      <div className="font-20 mb-2 mt-2 font-bold">Are you sure you want to permanently delete this student?</div>
                      <div className="flex flex-v-center">
                        <Button className="ant-btn lg-btn ml-auto mr-2"
                          disabled={this.state.deleteStudent &&
                            this.state.deletingStudents.hasOwnProperty(this.state.deleteStudent.id)}
                          onClick={(e) => {
                            this.setState({
                              deleteStudentModalVisible: false,
                              deleteStudentModalCloseTimeout: setTimeout(() => {
                                this.setState({
                                  deleteStudent: null,
                                })
                              }, 100)
                            })
                          }}
                        >
                          No
                        </Button>
                        <Button className="ant-btn ant-btn-primary lg-btn"
                          disabled={this.state.deleteStudent &&
                            this.state.deletingStudents.hasOwnProperty(this.state.deleteStudent.id)}
                          onClick={(e) => {
                            if (this.state.deleteStudent) {
                              this.deleteStudent(this.state.deleteStudent.id)
                            }
                          }}
                        >
                          {this.state.deleteStudent &&
                            this.state.deletingStudents.hasOwnProperty(this.state.deleteStudent.id) ?
                            "Deleting..." : "Yes"}
                        </Button>
                      </div>
                    </Modal>

                    {this.props.teacherBaseProps &&
                      this.props.teacherBaseProps.district ?
                      <Row gutter={48} className="ml-0" type="flex" align="middle">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="pl-0">
                          <Link
                            to={"/teacher/tier-one/" + this.state.teacherId}
                            onClick={(e) => {
                              if (!this.props.teacherBaseProps.teacher) {
                                e.preventDefault()
                              } else if (isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) {
                                e.preventDefault()
                              }
                            }}
                          >
                            <div
                              className={"p-2 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black onboard-add-student"}
                            >

                              <div className="br-4 overflow-hidden">
                                <img src="/tier-one-icon.png" alt="tier one icon" height="64" />
                              </div>
                              <h2 className="ml-2 font-24 font-bold">
                                Conference notes
                              </h2>

                              {(this.props.teacherBaseProps.teacher &&
                                isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ?
                                <div>
                                  <div className="disabled-overlay">

                                  </div>

                                </div> :
                                ''}
                            </div>
                          </Link>
                        </Col>


                      </Row> : ''}


                    <div>

                      {this.props.teacherBaseProps &&
                        this.props.teacherBaseProps.sections &&
                        this.props.teacherBaseProps.sections.length > 0 ?
                        <div className='mt-2'>
                          <Tabs tabPosition='left' size='large'>
                            {this.props.teacherBaseProps.sections.map((section, idx) => {
                              return <Tabs.TabPane tab={<div className='ellipsis'
                                style={{ maxWidth: '260px' }}>{section.name}</div>} key={section.id}>
                                <div className='font-20 font-bold mt-1 mb-2'>
                                  <div className='font-24 font-bold mb-05'>{section.name}</div>
                                  <div className='flex flex-v-center'>
                                    <div className='font-16'>
                                      <Checkbox className='flex flex-v-center'
                                        checked={this.state.onlyShowStudentsWithPlans}
                                        onChange={(e) => {
                                          this.setState({
                                            onlyShowStudentsWithPlans: e.target.checked
                                          })
                                        }}>
                                        <span className='font-16 ml-1 font-black'>Only show students with plans</span>
                                      </Checkbox>
                                    </div>
                                    <div className='ml-2'>
                                      <Select value={this.state.onlyShowGrade} style={{ width: '150px' }}
                                        onChange={(e) => {
                                          this.setState({
                                            onlyShowGrade: e
                                          })
                                        }}>
                                        <Option value='all'>Show all grades</Option>
                                        <Option value='K'>K</Option>
                                        <Option value='1'>1</Option>
                                        <Option value='2'>2</Option>
                                        <Option value='3'>3</Option>
                                        <Option value='4'>4</Option>
                                        <Option value='5'>5</Option>
                                        <Option value='6'>6</Option>
                                        <Option value='7'>7</Option>
                                        <Option value='8'>8</Option>
                                        <Option value='9'>9</Option>
                                        <Option value='10'>10</Option>
                                        <Option value='11'>11</Option>
                                        <Option value='12'>12</Option>
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                                {section.hasOwnProperty('studentIds') &&
                                  section.studentIds &&
                                  section.studentIds.map((studentId, idx) => {
                                    if (this.props.teacherBaseProps.studentDict &&
                                      this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
                                      var student = this.props.teacherBaseProps.studentDict[studentId]
                                      let numGoals = 0
                                      if (this.props.teacherBaseProps.studentIEPDict &&
                                        this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(student.id)) {
                                        numGoals = this.props.teacherBaseProps.studentIEPDict[student.id].length
                                      }
                                      if (this.state.onlyShowStudentsWithPlans && numGoals === 0) {
                                        return false
                                      }
                                      if (this.state.onlyShowGrade !== 'all' && student.grade !== this.state.onlyShowGrade) {
                                        return false
                                      }
                                      return <Link to={"/teacher/student/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}>
                                        <div className={"p-3 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center"}>
                                          <PersonAvatar
                                            person={student}
                                            size={'large'}
                                            containerClassName="font-20"
                                            avatarClassName="font-20 font-bold mr-2"
                                            personClassName="font-black font-24 ellipsis"
                                            personStyle={{ maxWidth: '400px' }}
                                          />
                                          <div className="ml-auto font-24">
                                            <div className='flex flev-v-center'>
                                              {this.props.teacherBaseProps.IEPNumTypeDict &&
                                                this.props.teacherBaseProps.IEPNumTypeDict.hasOwnProperty(student.id) ?
                                                this.props.teacherBaseProps.IEPNumTypeDict[student.id].max === 0 ?
                                                  <div className="font-green">Tier 1</div>
                                                  :
                                                  <div className={getLevelColor(this.props.teacherBaseProps.IEPNumTypeDict[student.id].max)}>
                                                    <span>{this.props.teacherBaseProps.IEPNumTypeDict[student.id].max}</span>
                                                  </div>
                                                :
                                                <div className="font-green">Tier 1</div>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    } else {
                                      //console.log('student in section not found', studentId)
                                      return false
                                    }
                                  })}
                              </Tabs.TabPane>
                            })}
                          </Tabs>
                        </div>
                        : ''}

                      {/* no sections, render all students */}
                      {this.props.teacherBaseProps &&
                        this.props.teacherBaseProps.sections &&
                        this.props.teacherBaseProps.sections.length === 0 ?
                        <Row gutter={48} className="ml-0" type="flex">
                          <Col span={24}>
                            <div className='font-20 font-bold mt-1 mb-2 flex flex-v-center'>
                              <div>My students</div>
                              <div className='ml-2 font-16'>
                                <Checkbox className='flex flex-v-center'
                                  checked={this.state.onlyShowStudentsWithPlans}
                                  onChange={(e) => {
                                    this.setState({
                                      onlyShowStudentsWithPlans: e.target.checked
                                    })
                                  }}>
                                  <span className='font-16 ml-1 font-black'>Only show students with plans</span>
                                </Checkbox>
                              </div>
                              <div className='ml-2'>
                                <Select value={this.state.onlyShowGrade} style={{ width: '150px' }}
                                  onChange={(e) => {
                                    this.setState({
                                      onlyShowGrade: e
                                    })
                                  }}>
                                  <Option value='all'>Show all grades</Option>
                                  <Option value='K'>K</Option>
                                  <Option value='1'>1</Option>
                                  <Option value='2'>2</Option>
                                  <Option value='3'>3</Option>
                                  <Option value='4'>4</Option>
                                  <Option value='5'>5</Option>
                                  <Option value='6'>6</Option>
                                  <Option value='7'>7</Option>
                                  <Option value='8'>8</Option>
                                  <Option value='9'>9</Option>
                                  <Option value='10'>10</Option>
                                  <Option value='11'>11</Option>
                                  <Option value='12'>12</Option>
                                </Select>
                              </div>
                            </div>
                            <div className='mb-3'>
                              About 20% of your students should have a tier plan.
                            </div>

                            {this.props.teacherBaseProps &&
                              this.props.teacherBaseProps.students &&
                              this.props.teacherBaseProps.students.length === 0 ?
                              <Empty
                                containerClassName="mt-4 pt-2 text-center font-16"
                                width={55}
                                height={55}
                                description={'You currently have no students.'}
                                image={'/empty/students.png'}
                              /> : ''}

                            {this.state.onlyShowStudentsWithPlans &&
                              this.props.teacherBaseProps &&
                              this.props.teacherBaseProps.students &&
                              this.props.teacherBaseProps.students.length > 0 &&
                              this.props.teacherBaseProps.IEPGoals &&
                              this.props.teacherBaseProps.IEPGoals.length === 0 ?
                              <Empty
                                containerClassName="mt-4 pt-2 text-center font-16"
                                width={55}
                                height={55}
                                description={'You currently have no students with plans.'}
                                image={'/empty/students.png'}
                              />
                              : ''}

                            {this.state.onlyShowGrade !== 'all' &&
                              this.props.teacherBaseProps &&
                              this.props.teacherBaseProps.students &&
                              this.getOnlyShowGradeNumGoals(this.props.teacherBaseProps.students, this.state.onlyShowGrade) === 0 ?
                              <Empty
                                containerClassName="mt-4 pt-2 text-center font-16"
                                width={55}
                                height={55}
                                description={'No students to display that match your filters.'}
                                image={'/empty/students.png'}
                              /> : ''}

                          </Col>
                          {this.props.teacherBaseProps &&
                            this.props.teacherBaseProps.students &&
                            this.props.teacherBaseProps.students.map((student, idx) => {
                              var studentClassName = "onboard-student-" + idx
                              let numGoals = 0
                              if (this.props.teacherBaseProps.studentIEPDict &&
                                this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(student.id)) {
                                numGoals = this.props.teacherBaseProps.studentIEPDict[student.id].length
                              }
                              if (this.state.onlyShowStudentsWithPlans && numGoals === 0) {
                                return false
                              }
                              if (this.state.onlyShowGrade !== 'all' && student.grade !== this.state.onlyShowGrade) {
                                return false
                              }
                              return <Col span={24} className="pl-0" key={student.id}>
                                <Link to={"/teacher/student/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}>
                                  <div className={studentClassName + " p-3 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center"}>
                                    <PersonAvatar
                                      person={student}
                                      size={'large'}
                                      containerClassName="font-20"
                                      avatarClassName="font-20 font-bold mr-2"
                                      personClassName="font-black font-24 ellipsis"
                                      personStyle={{ maxWidth: '400px' }}
                                    />
                                    <div className="ml-auto font-24">
                                      <div className='flex flev-v-center'>
                                        {this.props.teacherBaseProps.IEPNumTypeDict &&
                                          this.props.teacherBaseProps.IEPNumTypeDict.hasOwnProperty(student.id) ?
                                          this.props.teacherBaseProps.IEPNumTypeDict[student.id].max === 0 ?
                                            <div className="font-black">Student has no plans</div>
                                            :
                                            <div className={getLevelColor(this.props.teacherBaseProps.IEPNumTypeDict[student.id].max)}>
                                              <span>{this.props.teacherBaseProps.IEPNumTypeDict[student.id].max}</span>
                                            </div>
                                          :
                                          <div className="font-black">Student has no plans</div>
                                        }

                                        {student.hasOwnProperty('cleverId') ?
                                          <div className='p-1 ml-2 border-left font-16 background-blue br-4 font-fff font-bold'>
                                            Clever
                                          </div>
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                </Link>

                                {/* 
                              <div>
                                <Button onClick={() => {
                                  db.collection(ColType.student).doc(student.id).delete()
                                }}>Delete student (Clever testing</Button>
                              </div>*/}
                              </Col>
                            })
                          }



                        </Row> : ''}
                    </div>


                  </div>
                  : ''}
              </div>
            </Content>
          </Layout>
        </Layout >
        <CustomFooter teacherId={this.state.teacherId} />
      </div >
    )
  }
}

export default TeacherHome
