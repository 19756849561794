import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getMathStandardsKindergarten, getMathUnits, getMathPrebuiltPacingGuideWeeks, getELAK } from './SCMath'
import { Layout, Select, Icon, Input, Row, Col, Collapse, Checkbox, Tabs } from 'antd'
//import { ReadOutline as IconRead } from '@ant-design/icons/lib/outline/ReadOutline'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import PacingGuideDraggableStandard from './PacingGuideDraggableStandard'
import { PDFDownloadLink, Document, Page, View, Text, Font, Image } from '@react-pdf/renderer'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { flattenDoc } from '../../Util'
//import { ReadOutlined, CheckOutlined } from '@ant-design/icons'
import quicksand from '../../fonts/quicksand-medium.ttf'
import quicksandBold from '../../fonts/Quicksand-Bold.ttf'
import WeeklyPlannerUploads from './WeeklyPlannerUploads'
const Sider = Layout.Sider
const Content = Layout.Content

Font.register({
    family: 'Quicksand',
    format: "truetype",
    src: quicksand,
});

Font.register({
    family: 'QuicksandBold',
    format: "truetype",
    src: quicksandBold,
});

class WeeklyPlanner extends Component {
    state = {
        mathStandardsDict: {},
        mathIndividualStandardsDict: {},
        standards: [],
        prevWeek: -1,
        week: 1,
        sideMenuWeek: 1,
        totalWeeks: 37,
        weeks: null,
        weekNums: [],
        prevStandardName: '',
        prevStandardCategory: '',
        standardName: 'Kindergarten',
        standardCategory: 'ELA',
        standardCategories: ['All Subjects', 'ELA', 'Math', 'Social Studies', 'Science'],
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        k5Grades: ['Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade'],
        visible: false,
        assessmentVisible: false,
        lessonPlanStandardNames: ['ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
            'ELA.6.AOR.1.2', 'ELA.C.1', 'ELA.K.F.1.4', '2.ATO.1', '3.ATO.1',
            '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1', 'K.NS.1', '3-ESS2-1',
            'B-LS1-5', 'USG.1.IP'],
        subjects: ['ELA', 'Math', 'Social Studies', 'Science'],
        lessonPlanStandardNamesSubjectDict: {
            'ELA': ['ELA.K.F.1.4', 'ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
                'ELA.6.AOR.1.2', 'ELA.C.1'],
            'Math': ['K.NS.1', '2.ATO.1', '3.ATO.1',
                '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1'],
            'Social Studies': ['USG.1.IP'],
            'Science': ['3-ESS2-1', 'B-LS1-5'],
        },
        selectedStandardName: 'ELA.1.F.3.6',
        notScheduledFilter: false,
        searchFilter: '',
        numWeeks: 37,
        totalWeeks: 37,
        dayNums: [0, 1, 2, 3, 4, 5],
        pacingGuide: null,
        prevSideMenuData: [],
        weeklyPlannerData: {
            week: 1,
            standardName: 'Kindergarten',
            standardCategory: 'ELA',
            days: {
                // 0 is added by listener
                // a list for each day of the week
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
            },
            additionalData: {
                content: '',
                grade: '',
                iCanStatements: '',
                academicVocabulary: '',
            },
        },
        additionalDataInputTimeout: null,
        initializedState: 'not initialized',
        weeklyPlannerListener: null,
        dragging: false,
        scheduledStandardsDict: {},
        tab: 'weekly-planner',
    }

    componentDidMount() {
        let weekNums = []
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weekNums.push(i)
        }
        let tab = this.state.tab
        if (this.props.hasOwnProperty('isAdmin') && this.props.isAdmin) {
            tab = 'uploaded-files'
        }
        this.setState({
            weekNums: weekNums,
            tab: tab,
        })

        this.getInitialWeeklyPlannerData()
    }

    componentWillReceiveProps(nextProps) {
        this.getInitialWeeklyPlannerData()
    }

    componentWillUnmount() {
        if (this.state.weeklyPlannerListener) {
            this.state.weeklyPlannerListener()
        }
        // update the additionalData in case the timeout didn't fire yet
        this.updateAdditionalData()
    }

    getWeeklyPlannerWithMostData = (weeklyPlanners) => {
        if (!weeklyPlanners || weeklyPlanners.length === 0) {
            return null
        }
        let mostData = weeklyPlanners[0]
        let mostScheduled = 0
        for (let weeklyPlanner of weeklyPlanners) {
            let scheduled = 0
            for (let dayNum of Object.keys(weeklyPlanner.days)) {
                if (dayNum === '0') {
                    continue
                }
                scheduled += weeklyPlanner.days[dayNum].length
            }
            if (scheduled > mostScheduled) {
                mostData = weeklyPlanner
                mostScheduled = scheduled
            }
        }
        return mostData
    }

    getInitialWeeklyPlannerData = () => {
        if (this.state.initializedState !== 'not initialized') {
            return
        }
        // all values for all future calls need to be checked for here
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher &&
            this.props.teacherBaseProps.district)) {
            return
        }

        this.setState({
            initializedState: 'loading',
        }, () => {
            const processQuerySnapshot = (querySnapshot) => {
                let standardName = this.state.standardName
                let standardCategory = this.state.standardCategory
                let week = this.state.week
                if (!querySnapshot.empty) {
                    let weeklyPlanners = []
                    querySnapshot.forEach((doc) => {
                        let weeklyPlanner = flattenDoc(doc)
                        weeklyPlanners.push(weeklyPlanner)
                    })
                    let weeklyPlannerData = this.getWeeklyPlannerWithMostData(weeklyPlanners)
                    if (weeklyPlannerData) {
                        standardName = weeklyPlannerData.standardName
                        standardCategory = weeklyPlannerData.standardCategory
                        week = weeklyPlannerData.week
                    }
                }
                this.setState({
                    week: week,
                    sideMenuWeek: week,
                    standardName: standardName,
                    standardCategory: standardCategory,
                    initializedState: 'initialized',
                }, () => {
                    this.getWeeklyPlanner()
                })
            }

            if (this.props.hasOwnProperty('isAdmin') && this.props.isAdmin) {
                // get the most recently worked on weekly planner data to initialize the weekly planner
                db.collection(ColType.weeklyPlanner)
                    .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                    .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                    .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                    .orderBy('updatedAt', 'desc')
                    .limit(20)
                    .get()
                    .then((querySnapshot) => {
                        processQuerySnapshot(querySnapshot)
                    })
            } else {
                // get the most recently worked on weekly planner data to initialize the weekly planner
                db.collection(ColType.weeklyPlanner)
                    //.where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                    //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                    .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                    .orderBy('updatedAt', 'desc')
                    .limit(20)
                    .get()
                    .then((querySnapshot) => {
                        processQuerySnapshot(querySnapshot)
                    })
            }
        })
    }

    getWeeklyPlanner = () => {
        if (this.state.initializedState !== 'initialized') {
            return
        }
        if (this.state.prevWeek === this.state.week) {
            return
        }

        console.log('getWeeklyPlanner week change', this.state.week)

        this.setState({
            prevWeek: this.state.week,
        })

        if (this.state.weeklyPlannerListener) {
            this.state.weeklyPlannerListener()
        }

        this.setState({
            weeklyPlannerListener: null,
        })

        // getWeeklyPlanner called initially and when week changes, save
        // additional info in all cases, this call is ignored for the initial case
        this.updateAdditionalData()

        // cached value to see if the data changed after getData finishes
        let week = this.state.week

        if (this.props.hasOwnProperty('isAdmin') && this.props.isAdmin) {
            db.collection(ColType.weeklyPlanner)
                .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                .where('week', '==', this.state.week)
                .limit(20)
                .get()
                .then((querySnapshot) => {
                    //console.log('query snapshot', querySnapshot.docs.length)

                    if (querySnapshot.empty) {
                        let emptyWeeklyPlannerData = {
                            week: this.state.week,
                            standardName: this.state.standardName,
                            standardCategory: this.state.standardCategory,
                            days: {
                                // a list for each day of the week
                                1: [],
                                2: [],
                                3: [],
                                4: [],
                                5: [],
                            },
                            additionalData: {
                                content: '',
                                grade: '',
                                iCanStatements: '',
                                academicVocabulary: '',
                            },
                        }
                        this.setState({
                            weeklyPlannerData: emptyWeeklyPlannerData,
                        })
                    } else {
                        // data changed, a new listener will be created, return
                        if (week !== this.state.week) {
                            console.log('week changed, returning')
                            return
                        }
                        let weeklyPlanners = []
                        querySnapshot.forEach((doc) => {
                            let weeklyPlanner = flattenDoc(doc)
                            weeklyPlanners.push(weeklyPlanner)
                        })
                        let weeklyPlannerData = this.getWeeklyPlannerWithMostData(weeklyPlanners)
                        if (!weeklyPlannerData || !weeklyPlannerData.hasOwnProperty('additionalData')) {
                            weeklyPlannerData.additionalData = {
                                content: '',
                                grade: '',
                                iCanStatements: '',
                                academicVocabulary: '',
                            }
                        }

                        this.setState({
                            scheduledStandardsDict: this.getScheduledStandardsDict(weeklyPlannerData),
                        })

                        this.addSidemenuDataToWeeklyPlanner(weeklyPlannerData)
                    }
                })
        } else {
            let weeklyPlannerListener = db.collection(ColType.weeklyPlanner)
                //.where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                .where('week', '==', this.state.week)
                .limit(20)
                .onSnapshot({ includeMetadataChanges: true }, (querySnapshot) => {
                    //console.log('querySnapshot', querySnapshot)
                    // if from cache return, only want server updates
                    if (querySnapshot.metadata.fromCache) {
                        return
                    }
                    if (querySnapshot.empty) {
                        console.log('adding week', this.state.week)
                        // will immediately trigger the onSnapshot listener and will
                        // fall through below to the else case
                        db.collection(ColType.weeklyPlanner)
                            .add({
                                districtId: this.props.teacherBaseProps.teacher.districtId,
                                schoolId: this.props.teacherBaseProps.teacher.schoolId,
                                teacherId: this.props.teacherBaseProps.teacher.id,
                                week: this.state.week,
                                days: {
                                    // a list for each day of the week
                                    1: [],
                                    2: [],
                                    3: [],
                                    4: [],
                                    5: [],
                                },
                                // last selected standardName and standardCategory
                                standardName: this.state.standardName,
                                standardCategory: this.state.standardCategory,
                                additionalData: {
                                    content: '',
                                    grade: '',
                                    iCanStatements: '',
                                    academicVocabulary: '',
                                },
                                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                            })
                    } else {
                        console.log('qs', querySnapshot.docs.length)
                        // data changed, a new listener will be created, return
                        if (week !== this.state.week) {
                            return
                        }
                        let weeklyPlanners = []
                        querySnapshot.forEach((doc) => {
                            let weeklyPlanner = flattenDoc(doc)
                            weeklyPlanners.push(weeklyPlanner)
                        })
                        let weeklyPlannerData = this.getWeeklyPlannerWithMostData(weeklyPlanners)
                        if (!weeklyPlannerData || !weeklyPlannerData.hasOwnProperty('additionalData')) {
                            weeklyPlannerData.additionalData = {
                                content: '',
                                grade: '',
                                iCanStatements: '',
                                academicVocabulary: '',
                            }
                        }

                        console.log('weeklyPlannerData', weeklyPlannerData)

                        this.setState({
                            scheduledStandardsDict: this.getScheduledStandardsDict(weeklyPlannerData),
                        })

                        this.addSidemenuDataToWeeklyPlanner(weeklyPlannerData)
                    }
                })

            this.setState({
                weeklyPlannerListener: weeklyPlannerListener,
            })
        }
    }

    getScheduledStandardsDict = (weeklyPlannerData) => {
        if (!weeklyPlannerData) {
            return {}
        }
        let scheduledStandardsDict = {}
        this.state.dayNums.map((dayNum) => {
            if (!weeklyPlannerData.days.hasOwnProperty(dayNum)) {
                return false
            }
            let scheduledStandards = weeklyPlannerData.days[dayNum]
            for (let standard of scheduledStandards) {
                let key = standard.standard
                if (!scheduledStandardsDict.hasOwnProperty(key)) {
                    scheduledStandardsDict[key] = 0
                }
                scheduledStandardsDict[key] += 1
            }
        })
        return scheduledStandardsDict
    }

    getSideMenuData = () => {
        if (!this.state.pacingGuide) {
            return []
        }
        // should always be called from a getData resolve
        let sideMenuData = []
        // if there is no pacingGuide for a standardCategory, it will be an empty dict,
        // so it won't have weeks key
        if (!this.state.pacingGuide.hasOwnProperty('weeks')) {
            return false
        }
        if (this.state.pacingGuide.weeks.hasOwnProperty(this.state.sideMenuWeek)) {
            for (let standard of this.state.pacingGuide.weeks[this.state.sideMenuWeek]) {
                sideMenuData.push(standard)
            }
        }
        return sideMenuData
    }

    standardListsAreEqual = (list1, list2) => {
        if (!list1 || !list2) {
            return false
        }
        if (list1.length !== list2.length) {
            return false
        }
        for (let i = 0; i < list1.length; i++) {
            if (list1[i].standard !== list2[i].standard) {
                return false
            }
        }
        return true
    }

    weeklyPlannersAreEqual = (weeklyPlanner1, weeklyPlanner2) => {
        if (!weeklyPlanner1 || !weeklyPlanner2) {
            return false
        }
        if (!weeklyPlanner1.hasOwnProperty('days') || !weeklyPlanner2.hasOwnProperty('days')) {
            return false
        }
        if (Object.keys(weeklyPlanner1.days).length !== Object.keys(weeklyPlanner2.days).length) {
            return false
        }
        for (let dayNum of Object.keys(weeklyPlanner1.days)) {
            if (!weeklyPlanner2.days.hasOwnProperty(dayNum)) {
                return false
            }
            if (!this.standardListsAreEqual(weeklyPlanner1.days[dayNum], weeklyPlanner2.days[dayNum])) {
                return false
            }
        }
        return true
    }

    addSidemenuDataToWeeklyPlanner = (weeklyPlannerData) => {
        // cached values to see if the data changed after getData finishes
        // as getData sets this.state.pacingGuide needed
        // for this.getSideMenuData
        let standardName = this.state.standardName
        let standardCategory = this.state.standardCategory
        this.getData()
            .then(() => {
                // data changed before getData finished, return
                if (!(standardName === this.state.standardName &&
                    standardCategory === this.state.standardCategory)) {
                    return
                }

                // add side menu standards
                weeklyPlannerData.days[0] = this.getSideMenuData()
                console.log('weeklyPlanner listener', weeklyPlannerData, this.state)
                this.setState({
                    weeklyPlannerData: weeklyPlannerData,
                })
            })
            .catch((err) => {
                console.log('getData err', err)
            })
    }

    getData = () => {
        return new Promise((resolve, reject) => {
            // wait for initialization, should never happen at this point
            if (this.state.initializedState !== 'initialized') {
                reject()
                return
            }
            // these values will be checked in the initialization step
            if (!(this.props.teacherBaseProps &&
                this.props.teacherBaseProps.district)) {
                reject()
                return
            }

            if (this.state.standardName === this.state.prevStandardName &&
                this.state.standardCategory === this.state.prevStandardCategory) {
                console.log('already have pacing guide data, returning')
                resolve()
                return
            }

            this.setState({
                prevStandardName: this.state.standardName,
                prevStandardCategory: this.state.standardCategory,
                pacingGuide: null,
            }, () => {
                // pacing guides are needed to provide the side menu content for a given week
                // they contain all weeks
                db.collection(ColType.pacingGuides)
                    .where('standardName', '==', this.state.standardName)
                    .where('standardCategory', '==', this.state.standardCategory)
                    .where('districtId', '==', this.props.teacherBaseProps.district.id)
                    .get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            this.setState({
                                pacingGuide: null,
                            }, () => {
                                resolve()
                            })
                        } else {
                            let pacingGuide = flattenDoc(querySnapshot.docs[0])
                            // find pacingGuide that has data in it (might be more than one
                            // pacingGuide here)
                            querySnapshot.forEach((doc) => {
                                let pacingGuideInternal = flattenDoc(doc)
                                for (let i = 1; i < this.state.totalWeeks; i++) {
                                    if (pacingGuideInternal.weeks.hasOwnProperty(i) && pacingGuideInternal.weeks[i].length > 0) {
                                        pacingGuide = pacingGuideInternal
                                        break
                                    }
                                }
                            })
                            this.setState({
                                pacingGuide: pacingGuide,
                            }, () => {
                                resolve()
                            })
                        }
                    })
                    .catch((error) => {
                        console.log('Error getting documents: ', error)
                        reject(error)
                    })
            })
        })
    }

    showLessonPlanModal = (standard) => {
        this.setState({
            visible: true,
        })
    }

    getListStyleSider = (isDraggingOver) => {
        return {
            background: isDraggingOver ? 'lightblue' : 'white',
            padding: 8,
            borderRadius: '4px',
        }
    }

    getListStyle = (isDraggingOver, numItems) => {
        return {
            background: isDraggingOver ? 'lightblue' : 'white',
            border: (numItems === 0 ? '1px dashed #d9d9d9' : 'none'),
            padding: 8,
            overflow: 'auto',
            minHeight: '100px',
            marginBottom: '16px',
            borderRadius: '4px',
        }
    }

    getItemStyleSider = (isDragging, draggableStyle) => {
        return Object.assign({
            userSelect: 'none',
            borderRadius: '15px',
            padding: '16px',
            marginRight: '8px',
            marginBottom: '8px',
            boxShadow: 'rgba(100, 100, 111, 0.15) 0px 6px 18px 0px',
            background: isDragging ? 'lightgreen' : 'white',
        }, draggableStyle)
    }

    getItemStyle = (isDragging, draggableStyle) => {
        return Object.assign({
            position: 'relative',
            userSelect: 'none',
            borderRadius: '15px',
            padding: '16px',
            paddingBottom: '74px',
            marginRight: '8px',
            maxWidth: '240px',
            boxShadow: 'rgba(100, 100, 111, 0.15) 0px 6px 18px 0px',
            background: isDragging ? 'lightgreen' : 'white',
            // ...draggableStyle,
        }, draggableStyle)
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd = (result) => {
        const { source, destination } = result
        //console.log(result, source, destination)
        this.setState({
            dragging: false,
        })

        if (!(this.props.pacingGuideBaseProps)) {
            return
        }
        if (this.props.hasOwnProperty('isAdmin') && this.props.isAdmin) {
            return
        }

        // dropped outside the list
        if (!destination) {
            return
        }
        const sInd = +source.droppableId
        const dInd = +destination.droppableId

        console.log(sInd, dInd, source, destination)

        // index 0 is the side menu list (not a week)
        let weeklyPlannerData = this.state.weeklyPlannerData
        //console.log(weeklyPlannerData)
        if (sInd > 0 && sInd === dInd) {
            const items = this.reorder(weeklyPlannerData.days[sInd], source.index, destination.index)
            weeklyPlannerData.days[sInd] = items
        } else {
            const result = this.move(sInd, dInd, weeklyPlannerData.days[sInd], weeklyPlannerData.days[dInd], source, destination)
            weeklyPlannerData.days[sInd] = result[sInd]
            weeklyPlannerData.days[dInd] = result[dInd]
        }

        this.setState({
            weeklyPlannerData: weeklyPlannerData,
        })

        // Make a shallow copy of the object
        let daysToDB = Object.assign({}, weeklyPlannerData.days);
        // Delete the first property (standards list that is
        // added in the onSnapshot listener on each update)
        // save space by not adding to db
        delete daysToDB[0];

        db.collection(ColType.weeklyPlanner)
            .doc(this.state.weeklyPlannerData.id)
            .update({
                days: daysToDB,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('updated weekly planner')
            })
            .catch((error) => {
                console.log(error)
            })
    }

    /**
     * Moves an item from one list to another list.
     */
    move = (sInd, dInd, source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source)
        const destClone = Array.from(destination)
        let removedValue = sourceClone[droppableSource.index]
        // don't remove from side menu
        if (sInd > 0) {
            sourceClone.splice(droppableSource.index, 1)
        }
        if (dInd > 0) {
            destClone.splice(droppableDestination.index, 0, removedValue)
        }

        const result = {}
        result[droppableSource.droppableId] = sourceClone
        result[droppableDestination.droppableId] = destClone

        return result
    }

    onStandardNameChange = (value) => {
        this.setState({ standardName: value }, () => {
            let weeklyPlannerData = this.state.weeklyPlannerData
            this.addSidemenuDataToWeeklyPlanner(weeklyPlannerData)
        })
    }

    onStandardCategoryChange = (value) => {
        this.setState({ standardCategory: value }, () => {
            let weeklyPlannerData = this.state.weeklyPlannerData
            this.addSidemenuDataToWeeklyPlanner(weeklyPlannerData)
        })
    }

    onNotScheduledFilterChange = (e) => {
        this.setState({ notScheduledFilter: e.target.checked })
    }

    getQuarterAndWeekFromNum = (ind) => {
        if (ind === 0) {
            return {
                quarter: 0,
                week: 0,
            }
        }
        let quarter = -1
        if (ind >= 1 && ind < 10) {
            quarter = 1
        } else if (ind >= 10 && ind < 19) {
            quarter = 2
        } else if (ind >= 19 && ind < 28) {
            quarter = 3
        } else if (ind >= 28 && ind < 37) {
            quarter = 4
        }
        let weekNum = ind % 9
        if (weekNum === 0) {
            weekNum = 9
        }

        return {
            quarter: quarter,
            week: weekNum,
        }
    }

    getPDFDocument = () => {
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher)) {
            return
        }

        const stateQuarterAndWeek = this.getQuarterAndWeekFromNum(this.state.week)
        const stateQuarter = stateQuarterAndWeek.quarter
        const stateWeekNum = stateQuarterAndWeek.week

        return (
            <Document style={{ fontFamily: 'Quicksand' }}>
                <Page size="A4" orientation="landscape" style={{ padding: 16 }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', alignItems: 'flex-end', marginBottom: 16 }}>
                        {this.props.teacherBaseProps.districtLogo &&
                            this.props.teacherBaseProps.districtLogo.src ?
                            <Image src={this.props.teacherBaseProps.districtLogo.src}
                                style={{ width: '130px', height: '124px' }} />
                            : <View></View>}
                        <View style={{ marginLeft: 16, paddingBottom: 4 }}>
                            <Text style={{ fontSize: '24px', fontFamily: 'QuicksandBold' }}>Weekly Lesson Plan</Text>
                        </View>
                    </View>
                    {this.state.weeklyPlannerData.hasOwnProperty('additionalData') && this.state.weeklyPlannerData.additionalData ?
                        <View>
                            <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                                <View style={{ width: '25%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Teacher Name:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.props.teacherBaseProps.teacher.firstName + ' ' + this.props.teacherBaseProps.teacher.lastName}</Text>
                                </View>
                                <View style={{ width: '25%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Content:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.content}</Text>
                                </View>
                                <View style={{ width: '25%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Quarter:</Text>
                                    <Text style={{ fontSize: '16px' }}>{stateQuarter}</Text>
                                </View>
                                <View style={{ width: '25%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Week:</Text>
                                    <Text style={{ fontSize: '16px' }}>{stateWeekNum}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                                <View style={{ width: '50%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Content:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('content') &&
                                        this.state.weeklyPlannerData.additionalData.content ?
                                        this.state.weeklyPlannerData.additionalData.content : ''}</Text>
                                </View>
                                <View style={{ width: '50%', padding: 8 }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Materials:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('materials') &&
                                        this.state.weeklyPlannerData.additionalData.materials ?
                                        this.state.weeklyPlannerData.additionalData.materials : ''}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                                <View style={{ width: '50%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>How to help them love it:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('howToHelpThemLoveIt') &&
                                        this.state.weeklyPlannerData.additionalData.howToHelpThemLoveIt ?
                                        this.state.weeklyPlannerData.additionalData.howToHelpThemLoveIt : ''}</Text>
                                </View>
                                <View style={{ width: '50%', padding: 8 }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>How to help them do it:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('howToHelpThemDoIt') &&
                                        this.state.weeklyPlannerData.additionalData.howToHelpThemDoIt ?
                                        this.state.weeklyPlannerData.additionalData.howToHelpThemDoIt : ''}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                                <View style={{ width: '33%', padding: 8, borderRight: '1px solid #000' }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Teacher led group:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('teacherLedGroup') &&
                                        this.state.weeklyPlannerData.additionalData.teacherLedGroup ?
                                        this.state.weeklyPlannerData.additionalData.teacherLedGroup : ''}</Text>
                                </View>
                                <View style={{ width: '33%', padding: 8 }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Group 2:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('groupTwo') &&
                                        this.state.weeklyPlannerData.additionalData.groupTwo ?
                                        this.state.weeklyPlannerData.additionalData.groupTwo : ''}</Text>
                                </View>
                                <View style={{ width: '33%', padding: 8 }}>
                                    <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Group 3:</Text>
                                    <Text style={{ fontSize: '16px' }}>{this.state.weeklyPlannerData.additionalData.hasOwnProperty('groupThree') &&
                                        this.state.weeklyPlannerData.additionalData.groupThree ?
                                        this.state.weeklyPlannerData.additionalData.groupThree : ''}</Text>
                                </View>
                            </View>
                        </View>
                        :
                        <View></View>}
                    <View style={{ flexDirection: 'row', flexWrap: 'no-wrap' }}>
                        {this.state.dayNums.map((dayNum, ind) => {
                            if (dayNum === 0) {
                                return false;
                            }
                            // Adjust day strings for cleaner code
                            const dayStrings = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                            let dayStr = dayStrings[ind - 1];
                            return (
                                <View style={{
                                    width: '20%',
                                    marginRight: ind !== 5 ? 0 : 0,
                                    borderLeft: ind === 1 ? '1px solid #000' : 'none',  // only apply left border to the first View
                                    borderRight: '1px solid #000',
                                    borderBottom: '1px solid #000',
                                }} key={ind}>
                                    <View style={{
                                        padding: 8,
                                        borderTop: '1px solid #000',
                                        borderBottom: '1px solid #000',
                                        marginBottom: 8,
                                    }} fixed>
                                        <Text style={{ fontSize: '24px', fontFamily: 'QuicksandBold' }}>{dayStr}</Text>
                                    </View>
                                    <View style={{

                                    }}>
                                        {this.state.weeklyPlannerData.days[dayNum].map((standard, ind) => {
                                            return <View key={dayNum + '-' + ind} style={{
                                                padding: 8,
                                                paddingTop: ind === 0 ? 0 : 8,
                                            }}>
                                                <Text style={{ fontSize: '16px' }}>{standard.standardName}</Text>
                                                <Text style={{ fontSize: '16px' }}>{standard.standardCategory}</Text>
                                                <Text style={{ fontSize: '16px' }}>{standard.standard}</Text>
                                                {standard.hasOwnProperty('isPriority') && standard.isPriority ?
                                                    <Text style={{ fontSize: '14px' }}>Priority Standard</Text> : <Text></Text>}
                                                <Text style={{ fontSize: '14px' }}>{standard.title}</Text>
                                            </View>
                                        })}
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </Page>
            </Document>
        );
    };

    additionalDataOnChange = (e, additionalDataKey) => {
        if (this.state.initializedState === 'not initialized') {
            return
        }
        let weeklyPlannerData = this.state.weeklyPlannerData
        if (!(weeklyPlannerData.hasOwnProperty('additionalData') &&
            weeklyPlannerData.additionalData)) {
            return
        }

        weeklyPlannerData.additionalData[additionalDataKey] = e.target.value
        this.setState({ weeklyPlannerData: weeklyPlannerData })

        // do a timeout for the db update so it doesn't update on every keystroke
        if (this.state.additionalDataInputTimeout) {
            clearTimeout(this.state.additionalDataInputTimeout)
        }
        let additionalDataInputTimeout = setTimeout(() => {
            this.updateAdditionalData()
        }, 5000)
        this.setState({ additionalDataInputTimeout: additionalDataInputTimeout })
    }

    updateAdditionalData = () => {
        if (this.state.initializedState === 'not initialized') {
            return
        }
        let weeklyPlannerData = this.state.weeklyPlannerData
        // initial call before data is loaded
        if (!weeklyPlannerData.hasOwnProperty('id')) {
            return
        }
        if (!(weeklyPlannerData.hasOwnProperty('additionalData') &&
            weeklyPlannerData.additionalData)) {
            return
        }

        // do a timeout for the db update so it doesn't update on every keystroke
        if (this.state.additionalDataInputTimeout) {
            clearTimeout(this.state.additionalDataInputTimeout)
        }

        db.collection(ColType.weeklyPlanner)
            .doc(weeklyPlannerData.id)
            .update({
                additionalData: weeklyPlannerData.additionalData,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('updated weekly planner additional data')
            })
            .catch((error) => {
                console.log(error)
            })
    }

    areAllSidemenuStandardsScheduled = () => {
        if (!(this.state.scheduledStandardsDict &&
            this.state.weeklyPlannerData &&
            this.state.weeklyPlannerData.hasOwnProperty('days') &&
            this.state.weeklyPlannerData.days &&
            this.state.weeklyPlannerData.days.hasOwnProperty(0))) {
            return false
        }

        const sidmenuStandards = this.state.weeklyPlannerData.days[0]
        for (let standard of sidmenuStandards) {
            if (!this.state.scheduledStandardsDict.hasOwnProperty(standard.standard)) {
                return false
            }
            if (this.state.scheduledStandardsDict[standard.standard] === 0) {
                return false
            }
        }

        return true
    }

    render() {
        let standardDisplayName = ''
        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.standardNamesDict &&
            this.props.pacingGuideBaseProps.standardCategory &&
            this.props.pacingGuideBaseProps.standardName &&
            this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty(this.props.pacingGuideBaseProps.standardCategory + 'DisplayNames') &&
            this.props.pacingGuideBaseProps.standardNamesDict[this.props.pacingGuideBaseProps.standardCategory + 'DisplayNames'].hasOwnProperty(this.props.pacingGuideBaseProps.standardName)) {
            standardDisplayName = this.props.pacingGuideBaseProps.standardNamesDict[this.props.pacingGuideBaseProps.standardCategory + 'DisplayNames'][this.props.pacingGuideBaseProps.standardName]
        }

        const stateQuarterAndWeek = this.getQuarterAndWeekFromNum(this.state.week)
        const stateQuarter = stateQuarterAndWeek.quarter
        const stateWeekNum = stateQuarterAndWeek.week

        return (
            <div>
                {this.props.pacingGuideBaseProps &&
                    this.state.weeklyPlannerData ?
                    <div>
                        {this.props.hasOwnProperty('isAdmin') && this.props.isAdmin ?
                            <div>
                                <div className='flex flex-v-center mb-4'>
                                    <div className='font-20 mr-1'>
                                        Select week:
                                    </div>
                                    <Select
                                        onChange={(value) => {
                                            console.log('weeks change', value)
                                            this.setState({
                                                week: value,
                                                sideMenuWeek: value
                                            }, () => {
                                                this.getWeeklyPlanner()
                                            })
                                        }} value={this.state.week} size='large' style={{ width: '350px' }}>
                                        {this.state.weekNums &&
                                            this.state.weekNums.map((weekValue, ind) => {
                                                if (ind === 0) {
                                                    return false
                                                }
                                                const quarterAndWeek = this.getQuarterAndWeekFromNum(ind)
                                                const quarter = quarterAndWeek.quarter
                                                const weekNum = quarterAndWeek.week
                                                return <Select.Option className='font-16' key={'weeknum-select-option' + ind} value={weekValue}>
                                                    {'Your weekly plan for quarter ' + quarter + ': week ' + weekNum}
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                </div>
                                <Tabs activeKey={this.state.tab} size='large' onChange={(value) => {
                                    this.setState({
                                        tab: value,
                                    })
                                }} animated={false}>
                                    <Tabs.TabPane key='uploaded-files' tab={<div className='font-16'>Uploaded Lesson Plan</div>}>
                                        <div className='mt-3'>
                                            {this.state.initializedState === 'initialized' ?
                                                <WeeklyPlannerUploads
                                                    districtId={this.props.teacherBaseProps.district.id}
                                                    personId={this.props.teacherBaseProps.teacher.id}
                                                    week={this.state.week}
                                                    teacherBaseProps={this.props.teacherBaseProps}
                                                    isAdmin={this.props.hasOwnProperty('isAdmin') && this.props.isAdmin ? true : false}
                                                /> : ''}
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Weekly Planner" key="weekly-planner">
                                        <div className='flex w-100 mt-3'>
                                            {this.state.dayNums &&
                                                this.state.dayNums.map((dayNum, ind) => {
                                                    if (ind === 0) {
                                                        return false
                                                    }
                                                    if (!this.state.weeklyPlannerData.days.hasOwnProperty(dayNum)) {
                                                        return false
                                                    }
                                                    let dayStandards = this.state.weeklyPlannerData.days[dayNum]
                                                    let dayStr = 'Monday'
                                                    if (ind === 2) {
                                                        dayStr = 'Tuesday'
                                                    } else if (ind === 3) {
                                                        dayStr = 'Wednesday'
                                                    } else if (ind === 4) {
                                                        dayStr = 'Thursday'
                                                    } else if (ind === 5) {
                                                        dayStr = 'Friday'
                                                    }

                                                    // every 9 weeks
                                                    let quarter = -1
                                                    if (ind === 1) {
                                                        quarter = 1
                                                    } else if (ind === 10) {
                                                        quarter = 2
                                                    } else if (ind === 19) {
                                                        quarter = 3
                                                    } else if (ind === 28) {
                                                        quarter = 4
                                                    }
                                                    let weekNum = ind % 9
                                                    if (weekNum === 0) {
                                                        weekNum = 9
                                                    }
                                                    return <div key={'dayNum-' + ind} style={{ width: '20%' }} className='pr-2'>
                                                        <div className='font-20 font-bold text-muted'>{dayStr}</div>
                                                        <div>
                                                            <div>
                                                                {dayStandards && dayStandards.length === 0 ?
                                                                    <div className='flex p-2 flex-h-center w-100 h-100 font-16'>There are currenty no standards scheduled for {dayStr}.</div>
                                                                    : ''}
                                                                {dayStandards.map((item, index) => {
                                                                    return <div
                                                                        key={ind + '-' + index}
                                                                        index={index}
                                                                    >
                                                                        <div className='p-3 ant-shadow br-4 mb-1'>
                                                                            <PacingGuideDraggableStandard isWeeklyPlanner={true} item={item} />

                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                        </div>
                                    </Tabs.TabPane>

                                </Tabs>

                            </div>
                            :
                            <DragDropContext onBeforeCapture={() => {
                                this.setState({
                                    dragging: true,
                                })
                            }} onDragEnd={this.onDragEnd}>
                                <Layout className="layout-header-mt mt-0 min-w-1024">
                                    <Sider
                                        width={270}
                                        style={{
                                            overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 82px)',
                                            position: (this.state.dragging ? 'absolute' : 'fixed'),
                                            left: 0, marginTop: '82px', borderRight: '1px solid #f1f1f1',
                                            background: '#fff', borderTop: '1px solid #f1f1f1'
                                        }}>
                                        <div className="font-24 mt-1 p-2 pb-0 mb-1">
                                            <div className='flex flex-v-center font-bold font-orange'>
                                                <Icon type='read' className='font-30 mr-1' />
                                                <div>Pacing Guide</div>
                                            </div>
                                            <div className='font-16'>Below is your district's pacing guide one week at a time. Drag and drop them onto your weekly planner.</div>
                                        </div>
                                        {/*
                                    <div className='pl-2 pr-2 mt-1 mb-1'>
                                        <Input
                                            placeholder="Filter by standard"
                                            allowClear
                                            size='large'
                                            onChange={(e) => {
                                                this.setState({
                                                    searchFilter: e.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                        */}
                                        <div className='pl-2 pr-2'>
                                            <div className='font-16 mb-05 font-bold'>
                                                Content area:</div>
                                            <Select onChange={this.onStandardCategoryChange} value={this.state.standardCategory} size='large' className='w-100 mb-1'>
                                                {this.props.pacingGuideBaseProps.standardCategories.map((category, ind) => {
                                                    return <Select.Option key={'category-' + ind} value={category}>{category}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='font-16 mb-05 font-bold'>Grade or subject:</div>
                                            <Select onChange={this.onStandardNameChange} value={this.state.standardName} size='large' className='w-100 mb-1'>
                                                {this.props.pacingGuideBaseProps.standardNamesDict &&
                                                    this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty(this.state.standardCategory) &&
                                                    this.props.pacingGuideBaseProps.standardNamesDict[this.state.standardCategory].map((standardName, ind) => {
                                                        return <Select.Option key={'grade-' + ind} value={standardName}>{standardName}</Select.Option>
                                                    })}
                                            </Select>
                                            <div className='font-16 mb-05 font-bold'>Pacing guide quarter, week:</div>
                                            <Select onChange={(value) => {
                                                console.log('side menu weeks change', value)
                                                this.setState({ sideMenuWeek: value }, () => {
                                                    this.addSidemenuDataToWeeklyPlanner(this.state.weeklyPlannerData)
                                                })
                                            }} value={this.state.sideMenuWeek} size='large' className='w-100 mb-1'>
                                                {this.props.pacingGuideBaseProps.weekNums &&
                                                    this.props.pacingGuideBaseProps.weekNums.map((weekValue, ind) => {
                                                        if (ind === 0) {
                                                            return false
                                                        }
                                                        const quarterAndWeek = this.getQuarterAndWeekFromNum(ind)
                                                        const quarter = quarterAndWeek.quarter
                                                        const weekNum = quarterAndWeek.week
                                                        return <Select.Option key={'weeknum-sidemenu-select-option' + ind} value={weekValue}>
                                                            {'Pacing guide Q: ' + quarter + ', W: ' + weekNum}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>

                                            <div className='mt-1'>
                                                <Checkbox checked={this.state.notScheduledFilter} size='large'
                                                    onChange={this.onNotScheduledFilterChange}>
                                                    <span className='pl-1 font-16 va-minus-2 font-black'>Filter not scheduled</span>
                                                </Checkbox>
                                            </div>
                                        </div>
                                        {this.state.notScheduledFilter &&
                                            this.areAllSidemenuStandardsScheduled() ?
                                            <div className='p-2 font-20 text-success'>
                                                <Icon type='check' className='mr-1' />
                                                All standards are scheduled!
                                            </div>
                                            : ''}
                                        {this.state.weeklyPlannerData.days.hasOwnProperty(0) &&
                                            this.state.weeklyPlannerData.days[0].length === 0 ?
                                            <div className='p-2 font-20'>
                                                There are currently no standards scheduled on the pacing guide for this week.
                                            </div> : ''}
                                        {this.state.weeklyPlannerData.days.hasOwnProperty(0) ?
                                            // weeks[0] is all standard for the side menu, weeks is a dictionary
                                            <Droppable key={'side-menu-item-' + 0} droppableId={`${0}`}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        key={'droppable-div-' + 0}
                                                        ref={provided.innerRef}
                                                        style={this.getListStyleSider(snapshot.isDraggingOver)}
                                                        {...provided.droppableProps}
                                                    >
                                                        {this.state.weeklyPlannerData.days[0].map((item, index) => {
                                                            if (this.state.scheduledStandardsDict.hasOwnProperty(item.standard) &&
                                                                this.state.notScheduledFilter) {
                                                                return false
                                                            }
                                                            if (this.state.searchFilter !== '' && !item.standard.toLowerCase().includes(this.state.searchFilter.toLowerCase())) {
                                                                return false
                                                            }
                                                            return <Draggable
                                                                key={0 + '-' + index}
                                                                draggableId={0 + '-' + index}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={this.getItemStyleSider(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        <PacingGuideDraggableStandard
                                                                            item={item}
                                                                            scheduledNum={this.state.scheduledStandardsDict &&
                                                                                this.state.scheduledStandardsDict.hasOwnProperty(item.standard) ?
                                                                                this.state.scheduledStandardsDict[item.standard] : 0}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable> : ''}
                                    </Sider>
                                    <Layout className="content">
                                        <Content
                                            style={{
                                                marginLeft: 270, marginTop: "98px", overflowX: 'initial'
                                            }}
                                            className='p-2 pl-4 pr-4'
                                        >
                                            <Tabs activeKey={this.state.tab} size='large' onChange={(value) => {
                                                this.setState({
                                                    tab: value,
                                                })
                                            }} animated={false}>
                                                <Tabs.TabPane tab="Weekly Planner" key="weekly-planner">
                                                    <div className='mt-3'>
                                                        <div className='relative mb-3'>
                                                            <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                                                style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                                            <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                                                <div>
                                                                    <div className='font-40 font-bold font-fff'>Weekly Planner</div>
                                                                    <div className='font-24 font-bold font-fff'>
                                                                        <Select onChange={(value) => {
                                                                            console.log('weeks change', value)
                                                                            this.setState({
                                                                                week: value,
                                                                                sideMenuWeek: value
                                                                            }, () => {
                                                                                this.getWeeklyPlanner()
                                                                            })
                                                                        }} value={this.state.week} size='large' style={{ width: '350px' }}>
                                                                            {this.props.pacingGuideBaseProps.weekNums &&
                                                                                this.props.pacingGuideBaseProps.weekNums.map((weekValue, ind) => {
                                                                                    if (ind === 0) {
                                                                                        return false
                                                                                    }
                                                                                    const quarterAndWeek = this.getQuarterAndWeekFromNum(ind)
                                                                                    const quarter = quarterAndWeek.quarter
                                                                                    const weekNum = quarterAndWeek.week
                                                                                    return <Select.Option className='font-16' key={'weeknum-select-option' + ind} value={weekValue}>
                                                                                        {'Your weekly plan for quarter ' + quarter + ': week ' + weekNum}
                                                                                    </Select.Option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                </div>

                                                                <div className='ml-auto'>
                                                                    <PDFDownloadLink document={<this.getPDFDocument />}
                                                                        fileName={stateQuarter > 0 && stateWeekNum > 0 ?
                                                                            'WeeklyPlanner-Quarter-' + stateQuarter + '-Week-' + stateWeekNum + '.pdf' :
                                                                            'WeeklyPlanner.pdf'}
                                                                        className='ant-btn ant-btn-primary lg-btn ant-shadow'>
                                                                        {({ blob, url, loading, error }) => {
                                                                            return <div className='flex flex-v-center'>
                                                                                <Icon type='file-pdf' className='font-20 mr-1' />
                                                                                <div>{'Download as a PDF'}</div>
                                                                            </div>
                                                                        }}
                                                                    </PDFDownloadLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Collapse className='mb-3'>
                                                            <Collapse.Panel bordered={false} header={<div className='font-20'>
                                                                More information</div>} key="i-can-statements-panel">
                                                                <div className='font-20 font-black p-2'>
                                                                    <div className='font-20 mb-2'>This information will be displayed in the downloadable PDF.</div>
                                                                    <Row gutter={32} className='mb-2'>
                                                                        <Col span={12}>
                                                                            <div>Content:</div>
                                                                            <Input value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('content') ?
                                                                                this.state.weeklyPlannerData.additionalData.content : ''}
                                                                                size='large' className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'content')
                                                                                }}></Input>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <div>Materials:</div>
                                                                            <Input value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('materials') ?
                                                                                this.state.weeklyPlannerData.additionalData.materials : ''}
                                                                                size='large' className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'materials')
                                                                                }}></Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={32}>
                                                                        <Col span={12}>
                                                                            <div>How to help them love it:</div>
                                                                            <Input.TextArea value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('howToHelpThemLoveIt') ?
                                                                                this.state.weeklyPlannerData.additionalData.howToHelpThemLoveIt : ''}
                                                                                rows={5}
                                                                                className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'howToHelpThemLoveIt')
                                                                                }}></Input.TextArea>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <div>How to help them do it:</div>
                                                                            <Input.TextArea value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('howToHelpThemDoIt') ?
                                                                                this.state.weeklyPlannerData.additionalData.howToHelpThemDoIt : ''}
                                                                                rows={5}
                                                                                className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'howToHelpThemDoIt')
                                                                                }}></Input.TextArea>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={32} className='mt-2'>
                                                                        <Col span={8}>
                                                                            <div>Teacher led group:</div>
                                                                            <Input.TextArea value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('teacherLedGroup') ?
                                                                                this.state.weeklyPlannerData.additionalData.teacherLedGroup : ''}
                                                                                rows={5}
                                                                                className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'teacherLedGroup')
                                                                                }}></Input.TextArea>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <div>Group 2:</div>
                                                                            <Input.TextArea value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('groupTwo') ?
                                                                                this.state.weeklyPlannerData.additionalData.groupTwo : ''}
                                                                                rows={5}
                                                                                className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'groupTwo')
                                                                                }}></Input.TextArea>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <div>Group 3:</div>
                                                                            <Input.TextArea value={this.state.weeklyPlannerData &&
                                                                                this.state.weeklyPlannerData.hasOwnProperty('additionalData') &&
                                                                                this.state.weeklyPlannerData.additionalData &&
                                                                                this.state.weeklyPlannerData.additionalData.hasOwnProperty('groupThree') ?
                                                                                this.state.weeklyPlannerData.additionalData.groupThree : ''}
                                                                                rows={5}
                                                                                className='font-16' onChange={(e) => {
                                                                                    this.additionalDataOnChange(e, 'groupThree')
                                                                                }}></Input.TextArea>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Collapse.Panel>
                                                        </Collapse>
                                                        <div className='flex w-100'>
                                                            {this.state.dayNums &&
                                                                this.state.dayNums.map((dayNum, ind) => {
                                                                    if (ind === 0) {
                                                                        return false
                                                                    }
                                                                    if (!this.state.weeklyPlannerData.days.hasOwnProperty(dayNum)) {
                                                                        return false
                                                                    }
                                                                    let dayStandards = this.state.weeklyPlannerData.days[dayNum]
                                                                    let dayStr = 'Monday'
                                                                    if (ind === 2) {
                                                                        dayStr = 'Tuesday'
                                                                    } else if (ind === 3) {
                                                                        dayStr = 'Wednesday'
                                                                    } else if (ind === 4) {
                                                                        dayStr = 'Thursday'
                                                                    } else if (ind === 5) {
                                                                        dayStr = 'Friday'
                                                                    }

                                                                    // every 9 weeks
                                                                    let quarter = -1
                                                                    if (ind === 1) {
                                                                        quarter = 1
                                                                    } else if (ind === 10) {
                                                                        quarter = 2
                                                                    } else if (ind === 19) {
                                                                        quarter = 3
                                                                    } else if (ind === 28) {
                                                                        quarter = 4
                                                                    }
                                                                    let weekNum = ind % 9
                                                                    if (weekNum === 0) {
                                                                        weekNum = 9
                                                                    }
                                                                    return <div key={'dayNum-' + ind} style={{ width: '20%' }} className='pr-1'>
                                                                        <div className='font-20 font-bold text-muted'>{dayStr}</div>
                                                                        <Droppable key={ind} droppableId={`${ind}`} direction="vertical">
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    key={'droppable-div-' + ind}
                                                                                    ref={provided.innerRef}
                                                                                    style={this.getListStyle(snapshot.isDraggingOver, dayStandards.length)}
                                                                                    {...provided.droppableProps}
                                                                                >
                                                                                    {dayStandards && dayStandards.length === 0 ?
                                                                                        <div className='flex p-2 flex-h-center w-100 h-100 font-16'>Drop standards here to start a schedule for {dayStr}.</div>
                                                                                        : ''}
                                                                                    {dayStandards.map((item, index) => {
                                                                                        const lessonPlanQueryString = '?standardName=' + item.standardName + '&standard=' + item.standard + '&grade=' + item.grade + '&category=' + item.standardCategory + '&weeklyPlanner=true'
                                                                                        return <Draggable
                                                                                            key={ind + '-' + index}
                                                                                            draggableId={ind + '-' + index}
                                                                                            index={index}
                                                                                        >
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={this.getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}
                                                                                                >
                                                                                                    <PacingGuideDraggableStandard isWeeklyPlanner={true} item={item} />
                                                                                                    <Link to={this.props.teacherBaseProps &&
                                                                                                        this.props.teacherBaseProps.teacher ?
                                                                                                        '/teacher/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.teacher.id + lessonPlanQueryString
                                                                                                        :
                                                                                                        this.props.teacherBaseProps &&
                                                                                                            this.props.teacherBaseProps.admin ?
                                                                                                            '/admin/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.admin.id + lessonPlanQueryString
                                                                                                            :
                                                                                                            this.props.teacherBaseProps &&
                                                                                                                this.props.teacherBaseProps.instructionalFacilitator ?
                                                                                                                '/instructional-facilitator/pacing-guide/lesson-plan/' + lessonPlanQueryString
                                                                                                                :
                                                                                                                this.props.teacherBaseProps &&
                                                                                                                    this.props.teacherBaseProps.schoolAdmin ?
                                                                                                                    '/school-admin/pacing-guide/lesson-plan/' + lessonPlanQueryString
                                                                                                                    :
                                                                                                                    ''}
                                                                                                        size='large'
                                                                                                        style={{ position: 'absolute', bottom: '16px', left: '16px', width: 'calc(100% - 32px)' }}
                                                                                                        className='ant-btn ant-btn-lg ant-btn-primary mt-2 flex flex-v-center flex-h-center font-16'>
                                                                                                        <Icon type='solution' className='mr-05 font-20' />
                                                                                                        <span>View Lesson</span>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    })}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </div>
                                                                })}
                                                        </div>
                                                    </div>
                                                </Tabs.TabPane>
                                                <Tabs.TabPane tab={<div className='font-16'>Upload Lesson Plan</div>}>
                                                    {this.props.teacherBaseProps &&
                                                        this.props.teacherBaseProps.district &&
                                                        this.props.teacherBaseProps.teacher ?
                                                        <div className='mt-3'>
                                                            <Select onChange={(value) => {
                                                                console.log('weeks change', value)
                                                                this.setState({
                                                                    week: value,
                                                                    sideMenuWeek: value
                                                                }, () => {
                                                                    this.getWeeklyPlanner()
                                                                })
                                                            }} value={this.state.week} size='large' className='mb-1' style={{ width: '450px' }}>
                                                                {this.props.pacingGuideBaseProps.weekNums &&
                                                                    this.props.pacingGuideBaseProps.weekNums.map((weekValue, ind) => {
                                                                        if (ind === 0) {
                                                                            return false
                                                                        }
                                                                        const quarterAndWeek = this.getQuarterAndWeekFromNum(ind)
                                                                        const quarter = quarterAndWeek.quarter
                                                                        const weekNum = quarterAndWeek.week
                                                                        return <Select.Option className='font-16' key={'weeknum-select-option' + ind} value={weekValue}>
                                                                            {'Your lesson plan and resources for quarter ' + quarter + ': week ' + weekNum}
                                                                        </Select.Option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <WeeklyPlannerUploads
                                                                districtId={this.props.teacherBaseProps.district.id}
                                                                personId={this.props.teacherBaseProps.teacher.id}
                                                                week={this.state.week}
                                                                teacherBaseProps={this.props.teacherBaseProps}
                                                            />
                                                        </div> : ''}
                                                </Tabs.TabPane>
                                            </Tabs>
                                        </Content>
                                    </Layout>
                                </Layout>
                            </DragDropContext>}
                    </div>
                    : ''}
            </div>
        )
    }
}

export default WeeklyPlanner