import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { Button, Form, Input, Icon } from 'antd'
const FormItem = Form.Item
const TextArea = Input.TextArea

// Using this to add students until the step form for adding students is done.
class StudentUploadStepOne extends Component {
    state = {
        submitting: false,
    }

    // add a new event to the teacher's events
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)

                    if (!values.uploadDescription) {
                        values.uploadDescription = 'No description'
                    }
                    if (this.props.onSubmitSuccess &&
                        this.props.onSubmitSuccess) {
                        this.props.onSubmitSuccess(values.uploadTitle, values.uploadDescription)
                        this.props.form.resetFields()
                    }
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <Form onSubmit={this.handleSubmit} className="block-label-form">
                <FormItem {...formItemLayout} label="Title">
                    {getFieldDecorator('uploadTitle', {
                        rules: [{ required: true, message: 'Title is required.' }],
                    })(
                        <Input size={'large'} placeholder="Title..." />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Description">
                    {getFieldDecorator('uploadDescription', {
                        rules: [{ required: false, message: '' }],
                    })(
                        <TextArea rows={4} size={'large'} placeholder="Description..." />
                    )}
                </FormItem>

                <FormItem className="mb-0">
                    <Button
                        type="primary"
                        size={'large'}
                        htmlType="submit"
                        className="ant-btn ant-btn-primary lg-btn text-align-center float-right font-bold"
                    >
                        <Icon type="plus" />
                        <span>Add</span>
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

export default Form.create()(StudentUploadStepOne)
