import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import ColType from '.././Types'
import { Icon, message, Tooltip, Button } from 'antd'

const errorMessage = (description) => {
  message.error(description)
}

class SuperAdminHeader extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    searchValue: '',
    authListener: null,
  }

  componentDidMount() {
    let authListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        db.collection(ColType.users)
          .doc(user.uid)
          .get()
          .then((doc) => {
            var userObj = doc.data()
            userObj.id = doc.id
            userObj.uid = user.uid
            userObj.signedIn = true
            //console.log(userObj, userObj.accessLevel)
            if (userObj.accessLevel !== 'superAdmin') {
              window.location.href = '/'
              return
            }
          })
      }
      else {
        // No user is signed in.
        window.location.href = '/'
      }
    })
    this.setState({ authListener: authListener })
  }

  componentWillUnmount() {
    if (this.state.authListener) {
      this.state.authListener()
    }
  }

  signOut = () => {
    firebase.auth().signOut().then(function () {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      window.location.href = "/sign-in"
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  render() {
    //console.log(this.props.menuKey)
    return (
      <div>
        <div
          style={{
            padding: 0,
            textAlign: 'left',
            position: 'fixed',
            width: '100%',
            zIndex: 2,
            top: 0,
          }}
        >
          <div
            className={"font-14 background-fff pl-2 pr-2 " +
              "flex flex-row border-bottom-none ant-header-shadow"}
            style={{ lineHeight: '64px' }}
          >
            <div key="add-initial-admin"
              className="font-500 inline-flex flex-center mr-3">
              <Tooltip title="Add a new district" placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/add-initial-admin/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'add-initial-admin' ?
                      ' font-bold-important' : '')}
                >
                  Find District
                </Link>
              </Tooltip>
            </div>
            <div key="test-review"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Test Review"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/test-review/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'test-review' ?
                      ' font-bold-important' : '')}
                >
                  Assessment Review
                </Link>
              </Tooltip>
            </div>
            {/*
            <div key="search"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Search for data (districts)"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/search/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'search' ?
                      ' font-bold-important' : '')}
                >
                  Search for data
                </Link>
              </Tooltip>
            </div>
          */}
            {/*
              <div key="is-sandbox" 
              className="font-500 inline-flex flex-center flex-v-center mr-3">
                <Tooltip title="Search for data (districts)" 
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/is-sandbox/'}
                  className={"font-black" + 
                  (this.props.menuKey && this.props.menuKey === 'search' ? 
                  ' font-bold-important' : '')}
                  >
                   Is District Sandbox?
                </Link>
                </Tooltip>
              </div>
               */}
            {/*
            <div key="goal-count"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Search for data (districts)"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/goal-count/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'goal-count' ?
                      ' font-bold-important' : '')}
                >
                  Who has Goals
                </Link>
              </Tooltip>
            </div>
              */}
            {/*
            <div key="lock-district"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock / Unlock district access"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/lock-district/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'lock-district' ?
                      ' font-bold-important' : '')}
                >
                  Lock / Unlock district
                </Link>
              </Tooltip>
              </div>*/}
            {/*
            <div key="hide-teacher"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock / Unlock district access"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/hide-teacher/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'hide-teacher' ?
                      ' font-bold-important' : '')}
                >
                  Hide Teacher
                </Link>
              </Tooltip>
            </div>*/}
            {/*
            <div key="hide-and-lock-person"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock / Unlock district access"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/hide-and-lock-person/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'hide-and-lock-person' ?
                      ' font-bold-important' : '')}
                >
                  Hide and Lock Person
                </Link>
              </Tooltip>
            </div>*/}
            {/*
            <div key="goals"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Look at goals"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/goals/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'goals' ?
                      ' font-bold-important' : '')}
                >
                  Goals
                </Link>
              </Tooltip>
            </div>
          
            <div key="pacing-guide-feedback"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock/Unlock Pacing Guide"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/pacing-guide-feedback/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'pacing-guide-feedback' ?
                      ' font-bold-important' : '')}
                >
                  Pacing Guide Feedback
                </Link>
              </Tooltip>
            </div>
          */}
            {/*
            <div key="quiz-review"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock/Unlock Pacing Guide"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/quiz-review/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'quiz-review' ?
                      ' font-bold-important' : '')}
                >
                  Quiz Review
                </Link>
              </Tooltip>
            </div>*/}
            {/*
            <div key="question-bank-review"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Question Bank Review"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/question-bank-review/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'question-bank-review' ?
                      ' font-bold-important' : '')}
                >
                  Question Bank Review
                </Link>
              </Tooltip>
            </div>
          */}
            {/*<div key="district-settings"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="District Settings"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/district-settings/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'district-settings' ?
                      ' font-bold-important' : '')}
                >
                  District Settings
                </Link>
              </Tooltip>
            </div>*/}
            {/*
            <div key="lock-pacing-guide"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock/Unlock Pacing Guide"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/lock-pacing-guide/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'lock-pacing-guide' ?
                      ' font-bold-important' : '')}
                >
                  Lock/Unlock Pacing Guide
                </Link>
              </Tooltip>
            </div>*/}
            {/* 
            <div key="delete-person"
              className="font-500 inline-flex flex-center flex-v-center mr-3">
              <Tooltip title="Lock / Unlock district access"
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/delete-person/'}
                  className={"font-black" +
                    (this.props.menuKey && this.props.menuKey === 'delete-person' ?
                      ' font-bold-important' : '')}
                >
                  Delete Person (Do not use unless you are Britt!!)
                </Link>
              </Tooltip>
            </div>
            */}

            {/*
              <div key="change-sandbox-status" 
              className="font-500 inline-flex flex-center flex-v-center mr-3">
                <Tooltip title="Lock / Unlock district access" 
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/change-sandbox-status/'}
                  className={"font-black" + 
                  (this.props.menuKey && this.props.menuKey === 'change-sandbox-status' ? 
                  ' font-bold-important' : '')}
                  >
                   Change Sandbox Status
                </Link>
                </Tooltip>
              </div>
              <div key="support-chat" 
              className="font-500 inline-flex flex-center flex-v-center mr-3">
                <Tooltip title="Respond to support chat" 
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/support-chat/'}
                  className={"font-black" + 
                  (this.props.menuKey && this.props.menuKey === 'support-chat' ? 
                  ' font-bold-important' : '')}
                  >
                   Support Chat
                </Link>
                </Tooltip>
              </div>
              <div key="contact-us" 
              className="font-500 inline-flex flex-center flex-v-center">
                <Tooltip title="Contact us from take a look" 
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/super-admin/contact-us/'}
                  className={"font-black" + 
                  (this.props.menuKey && this.props.menuKey === 'contact-us' ? 
                  ' font-bold-important' : '')}
                  >
                    Contact Us
                </Link>
                </Tooltip>
              </div>
              */}


            <div key="teacher-sign-out" className="ml-auto">
              <Button type="primary" size={'large'}
                className="lg-btn mt-1"
                onClick={this.signOut}>

                Sign Out
              </Button>
            </div>

          </div>

        </div>
      </div>
    )
  }
}

export default SuperAdminHeader