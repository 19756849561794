import React from 'react'
import Answers from './Answers'
import MathText from './MathText'

function TableAssessment(props) {
    const { table_header, table_data, pre_text, post_text, answers, title } = props

    return (
        <div>
            {title && <div className='font-24 font-bold mb-2'><MathText text={title} /></div>}
            <div className='font-20 mb-1'><MathText text={pre_text} /></div>
            <table className='bordered-table font-20'>
                <thead>
                    <tr>
                        {table_header.map((header, index) => (
                            <th key={index}><MathText text={header} /></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {table_data.map((row, index) => (
                        <tr key={index}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}><MathText text={cell} /></td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='font-20 mt-2'><MathText text={post_text} /></div>

            <Answers answers={answers} />
        </div>
    )
}

export default TableAssessment
