import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { getCategoryImpact, getSpecialEducationRemovalStatement } from '../../Util'
//import { firebase, db, firebaseStorage } from '../../firebase/Firebase'
//import MTSSLessonTemplate from './MTSSLessonTemplate'
import AccommodationView from './AccommodationView'
//import ColType from '../../Types'
import Keywords from './Keywords'
import CopyText from './CopyText'


class PlanView extends Component {
  state = {

  }

  componentDidMount() {

  }

  render() {
    var IEP = null
    if (this.props.iep) {
      IEP = this.props.iep
    }
    var isMTSSPlan = false
    if (IEP && IEP.hasOwnProperty("isMTSS") && IEP.isMTSS) {
      isMTSSPlan = true
    }

    return (
      <div>
        {IEP && this.props.student ?
          <div>
            <h3 className="mb-4 mt-4 font-20">
              <div className='flex flex-v-center mb-1'>
                <h2 className="font-bold mb-0 font-24 mr-2">
                  Present Level
                </h2>
                {this.props.hasOwnProperty('showCopyButtons') && this.props.showCopyButtons ?
                  <CopyText text={IEP.iep.presentLevel.presentLevelParagraph} />
                  : ''}
              </div>
              <Keywords paragraph={IEP.iep.presentLevel.presentLevelParagraph} />
            </h3>

            <h3 className="mb-4 font-20">
              <div className='flex flex-v-center mb-1'>
                <h2 className="font-bold mb-0 font-24 mr-2">
                  {isMTSSPlan ? "Problem Statement" : "Impact Statement"}
                </h2>

                {this.props.hasOwnProperty('showCopyButtons') && this.props.showCopyButtons ?
                  isMTSSPlan ?
                    <CopyText text={this.props.student.firstName + ' needs to improve in ' + IEP.iep.impactStatement} />
                    :
                    <CopyText text={this.props.student.firstName + "'s disability " + IEP.iep.impactDegree + ' ' + getCategoryImpact(IEP.iep) + '. ' +
                      this.props.student.firstName + ' has difficulty with ' + IEP.iep.impactStatement} />
                  : ''}
              </div>
              {isMTSSPlan ?
                <Keywords paragraph={this.props.student.firstName + ' needs to improve in ' + IEP.iep.impactStatement} />
                :
                <Keywords paragraph={this.props.student.firstName + "'s disability " + IEP.iep.impactDegree + ' ' + getCategoryImpact(IEP.iep) + '. ' +
                  this.props.student.firstName + ' has difficulty with ' + IEP.iep.impactStatement}
                />
              }
            </h3>
            <h2 className="font-bold font-24 mb-1">
              {isMTSSPlan ? "Connection Strategies" : "Accommodations"}
            </h2>
            <AccommodationView iep={IEP} student={this.props.student} />

            {
              !isMTSSPlan ?
                <div>
                  <h2 className="font-bold font-24 mb-1">Removal Statement</h2>
                  {IEP &&
                    IEP.iep.hasOwnProperty('removalStatement') &&
                    IEP.iep.removalStatement ?
                    <div>
                      <h3 className="font-20 mb-4">
                        {IEP.iep.mainSubject === "Math" ?
                          <div>
                            {this.props.hasOwnProperty("showCopyButtons") && this.props.showCopyButtons ?
                              <div className="mb-2">
                                <CopyText text={IEP.iep.removalStatement} />
                              </div>
                              : ''}
                            <Keywords paragraph={IEP.iep.removalStatement} />
                          </div>
                          :
                          (() => {
                            let removalStatement = getSpecialEducationRemovalStatement(this.props.student, IEP.iep.category, IEP.iep.level)
                            return <div>
                              {this.props.hasOwnProperty("showCopyButtons") && this.props.showCopyButtons ?
                                <div className="mb-2">
                                  <CopyText text={removalStatement} />
                                </div>
                                : ''}
                              <Keywords paragraph={removalStatement} />
                            </div>
                          })()
                        }
                      </h3>
                    </div>
                    :
                    <h3 className="font-20 mb-4">
                      N/A (Removal statement only needed for special education settings)
                    </h3>}
                </div>
                : ''
            }

            {
              isMTSSPlan &&
                ((this.props.student.hasOwnProperty('studentInterest') &&
                  this.props.student.studentInterest) ||
                  (IEP.iep.hasOwnProperty("studentInterest") &&
                    IEP.iep.studentInterest)) ?
                <div>
                  <h2 className="font-bold font-24 mb-1">
                    Student Interest
                  </h2>
                  <h3 className="mb-4 font-20">
                    {(this.props.student.hasOwnProperty('studentInterest') &&
                      this.props.student.studentInterest) ?
                      this.props.student.studentInterest :
                      IEP.iep.studentInterest}
                  </h3>
                </div>
                : ''
            }

            {
              isMTSSPlan &&
                IEP.iep.hasOwnProperty("parentShared") &&
                IEP.iep.parentShared ?
                <div>
                  <h2 className="font-bold font-24 mb-1">
                    Parent Shared
                  </h2>
                  <h3 className="mb-4 font-20">
                    {IEP.iep.parentShared}
                  </h3>
                </div>
                : ''
            }

            {IEP.iep.hasOwnProperty('presentLevel') &&
              IEP.iep.presentLevel.hasOwnProperty('additionalInformation') ?
              <div>
                <h2 className="font-bold font-24 mb-1">
                  Additional Information
                </h2>
                <h3 className="mb-4 font-20">
                  {IEP.iep.presentLevel.additionalInformation}
                </h3>
              </div>
              : ''}

          </div > : ''
        }

      </div>
    )
  }
}

export default PlanView