import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db, firebaseStorage } from '../../firebase/Firebase'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
import Empty from '../../customcomponents/Empty'
import StudentUpload from '../student/StudentUpload'
import ColType from '../../Types'
import { flattenDoc, getUploadedFileId, isReadOnlyTeacherId } from '../.././Util'
import { Icon, Row, Col, Button, Modal, notification, Tooltip, Popconfirm } from 'antd'
import TeacherStudentUploadFileView from './TeacherStudentUploadFileView'
//import StudentUploads from '../student/StudentUploads'
const moment = require('moment')

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class TeacherStudentUploadView extends Component {
    state = {
        teacherId: '',
        studentIdx: 0,
        deletePlanModalVisible: false,
        addFileVisible: false,
        deleteDropdownVisible: {},
        deletingUploadedFile: false,
        deleteUploadedFile: null,
        // file currently being viewed
        uploadedFile: null,
        uploadedFileId: null,
        fileIdx: -1,
        IEPId: null,
        studentId: null,
        uploadedFilesListener: null,
        uploadedFiles: [],
        uploadedFilesFileNameDict: {},
        uploadedFilesDict: {},
        downloadURLs: {},
        fileModalVisible: false,
        selectedUploadedFile: null,
    }

    showFileModal = () => {
        this.setState({
            addFileVisible: true,
        })
    }

    hideFileModal = () => {
        this.setState({
            addFileVisible: false,
        })
    }

    showModal = () => {
        this.setState({
            deletePlanModalVisible: true,
        })
    }

    hideModal = () => {
        this.setState({
            deletePlanModalVisible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            deletePlanModalVisible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            deletePlanModalVisible: false,
        })
    }


    // Do fetch here
    componentDidMount() {
        this.setUploadedFileIdx(this.props.studentId, this.state.uploadedFileId)
        this.getUploadedFiles(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.setUploadedFileIdx(this.props.studentId, this.state.uploadedFileId)
        this.getUploadedFiles(nextProps)
    }

    componentWillUnmount() {
        // unsubscribe old listener if there is one
        if (this.state.uploadedFilesListener) {
            this.state.uploadedFilesListener()
        }
    }

    getUploadedFiles(props) {
        if (!(props.teacherBaseProps &&
            props.teacherBaseProps.teacher &&
            props.IEPId)) {
            return
        }

        if (isReadOnlyTeacherId(props.teacherBaseProps.teacher.id)) {
            return
        }

        // already have a listener for this studentId and IEPId
        if (this.props.studentId && this.state.studentId &&
            props.IEPId && this.state.IEPId &&
            props.studentId === this.state.studentId &&
            props.IEPId === this.state.IEPId) {
            return
        }

        // unsubscribe old listener if there is one
        if (this.state.uploadedFilesListener) {
            this.state.uploadedFilesListener()
        }

        var uploadedFilesListener = db.collection(ColType.uploadedfiles)
            .where('IEPId', '==', props.IEPId)
            .where('teacherId', '==', props.teacherBaseProps.teacher.id)
            .where('studentId', '==', props.studentId)
            //.where('schoolId', '==', props.teacherBaseProps.teacher.schoolId)
            //.where('districtId', '==', props.teacherBaseProps.teacher.districtId)
            .orderBy('timeStamp', 'desc')
            .onSnapshot((querySnapshot) => {
                var uploadedFiles = []
                var uploadedFilesDict = {}
                var uploadedFilesFileNameDict = {}
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        var uploadedFile = flattenDoc(doc)
                        var uploadedFileId = getUploadedFileId(uploadedFile)
                        // only add for valid uploaded file ids
                        if (uploadedFileId) {
                            uploadedFiles.push(uploadedFile)
                            uploadedFilesFileNameDict[uploadedFile.fileName] = uploadedFile
                            uploadedFilesDict[uploadedFileId] = uploadedFile

                            firebaseStorage.ref(uploadedFile.filePath).getDownloadURL()
                                .then((url) => {
                                    let downloadURLs = this.state.downloadURLs
                                    downloadURLs[uploadedFile.id] = url
                                    this.setState({ downloadURLs: downloadURLs })
                                })
                        }
                    }
                })

                this.setState({
                    uploadedFiles: uploadedFiles,
                    uploadedFilesFileNameDict: uploadedFilesFileNameDict,
                    uploadedFilesDict: uploadedFilesDict,
                })
            })

        this.setState({
            studentId: props.studentId,
            IEPId: props.IEPId,
            uploadedFilesListener: uploadedFilesListener,
            // clear old selected uploadedFile/id
            uploadedFile: null,
            uploadedFileId: null,
        })
    }

    onDeleteDropdownVisibleChange = (IEPId, visible) => {
        var deleteDropdownVisible = this.state.deleteDropdownVisible
        deleteDropdownVisible[IEPId] = visible
        this.setState({
            deleteDropdownVisible: deleteDropdownVisible,
        })
    }

    getFileIcon = (contentType) => {
        if (contentType === 'image/png' || contentType === 'image/jpg' ||
            contentType === 'image/jpeg') {
            return 'picture'
        }
        else if (contentType.includes("video")) {
            return "caret-right"
        }
        else if (contentType === 'application/octet-stream') {
            return 'file-text'
        }
        return 'file'
    }

    setUploadedFileIdx = (studentId, uploadedFileId) => {
        if (!studentId) {
            return
        }
        else if (!uploadedFileId) {
            return
        } else if (!this.state.uploadedFiles) {
            return
        }

        var fileIdx = -1
        var len = this.state.uploadedFiles.length
        for (var i = 0; i < len; i++) {
            var uploadedFile = this.state.uploadedFiles[i]
            var fileUploadedFileId = getUploadedFileId(uploadedFile)
            if (fileUploadedFileId === uploadedFileId) {
                fileIdx = i
                break
            }
        }
        if (fileIdx === -1) {
            return
        }

        this.setState({
            fileIdx: fileIdx,
        })
    }

    downloadFile = (file) => {
        if (!file) {
            console.log("downloadFile, file is null")
            return
        }

        firebaseStorage.ref().child(file.filePath).getDownloadURL()
            .then((url) => {
                var xhr = new XMLHttpRequest()
                xhr.responseType = 'blob'
                xhr.onload = function (event) {
                    var blob = xhr.response
                    console.log("blob", blob)
                    url = window.URL.createObjectURL(blob)
                    var a = document.createElement("a")
                    a.href = url
                    a.download = file.fileName
                    a.click()
                    window.URL.revokeObjectURL(url)
                }
                xhr.open('GET', url)
                xhr.send()
            })
            .catch((e) => {
                console.log("download file error", e)
                errorMessage("The file could not be downloaded. Try again or email support@dotit.app")
            })
    }

    deleteUploadedFile = (uploadedFile) => {
        if (!uploadedFile) {
            return
        }

        this.setState({
            deletingUploadedFile: true,
            uploadedFile: null,
            uploadedFileId: null,
        })

        // Create a reference to the file to delete
        var fileRef = firebaseStorage.ref().child(uploadedFile.filePath)

        // Delete the file
        fileRef.delete().then(() => {
            // File deleted successfully
            db.collection(ColType.uploadedfiles)
                .doc(uploadedFile.id)
                .delete()
                .then(() => {
                    successMessage("File has been deleted successfully.")

                    this.setState({
                        deletePlanModalVisible: false,
                        deleteUploadedFile: null,
                        deletingUploadedFile: false,
                    })
                })
                .catch((e) => {
                    errorMessage("Something went wrong deleting the file. " +
                        "Try again or email support@dotit.app.")
                    this.setState({
                        deletePlanModalVisible: false,
                        deleteUploadedFile: null,
                        deletingUploadedFile: false,
                    })
                })
        }).catch((e) => {
            errorMessage("Something went wrong deleting the file. " +
                "Try again or email support@dotit.app.")
            this.setState({
                deletePlanModalVisible: false,
                deleteUploadedFile: null,
                deletingUploadedFile: false,
            })
        })
    }

    handleFileModalCancel = () => {
        this.setState({
            fileModalVisible: false,
        })
    }

    selectFile = (uploadedFile) => {
        console.log('uploadedfile', this.state, uploadedFile)
        this.setState({
            selectedUploadedFile: uploadedFile,
            fileModalVisible: true,
        })
    }

    render() {
        return (
            <div className="mt-3">
                <Modal
                    title="Upload File"
                    visible={this.state.addFileVisible}
                    width={888}
                    footer={null}
                    onCancel={this.hideFileModal}
                >
                    <div>
                        {this.state.uploadedFilesFileNameDict ?
                            <StudentUpload
                                uploadedFilesFileNameDict={this.state.uploadedFilesFileNameDict}
                                teacher={this.props.teacherBaseProps.teacher}
                                student={this.props.student}
                                IEPId={this.props.IEPId}
                                onSuccess={this.hideFileModal}
                            />
                            : ''}
                    </div>
                </Modal>

                <Modal
                    title={null}
                    visible={this.state.deletePlanModalVisible}
                    footer={null}
                    onCancel={(e) => {
                        this.setState({
                            deletePlanModalVisible: false,
                        })
                    }}
                >
                    <div className="font-20 mb-2 mt-4 font-bold">Are you sure you want to permanently delete this file?</div>
                    <div className="flex flex-v-center">
                        <Button className="ant-btn lg-btn ml-auto mr-2"
                            disabled={this.state.deletingUploadedFile}
                            onClick={(e) => {
                                this.setState({
                                    deletePlanModalVisible: false,
                                })
                            }}
                        >
                            No
                        </Button>
                        <Button className="ant-btn ant-btn-primary lg-btn"
                            disabled={this.state.deletingUploadedFile}
                            onClick={(e) => {
                                this.deleteUploadedFile(this.state.deleteUploadedFile)
                            }}
                        >
                            {this.state.deletingUploadedFile ?
                                "Deleting..." :
                                "Yes"
                            }
                        </Button>
                    </div>
                </Modal>


                <div className="flex flex-v-center w-100 mb-4">
                    <div className="font-bold mb-0 font-24">
                        Uploads
                    </div>
                    <Button
                        size={"large"}
                        className="ml-auto"
                        onClick={this.showFileModal}
                        disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher ?
                            this.props.teacherBaseProps.teacher.id : null)}
                    >
                        <div className="w-100 flex flex-v-center">
                            <Icon type="upload" className="font-bold mr-1" />
                            <span>Upload file</span>
                        </div>
                    </Button>
                </div>

                <Modal
                    title={this.state.selectedUploadedFile ?
                        this.state.selectedUploadedFile.fileName
                        : 'View File'}
                    visible={this.state.fileModalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleFileModalCancel}
                    wrapClassName="fullscreen-modal"
                    style={{ top: '50px', 'left': '50px' }}
                >
                    {!(this.state.selectedUploadedFile &&
                        this.state.downloadURLs &&
                        this.state.downloadURLs.hasOwnProperty(this.state.selectedUploadedFile.id) &&
                        this.state.downloadURLs[this.state.selectedUploadedFile.id]) ?
                        <Empty
                            containerClassName="mt-2 text-center"
                            width={55}
                            height={55}
                            image={'/empty/goals.png'}
                            description={'There is currently no selected file.'}
                        />
                        :
                        <iframe
                            src={this.state.downloadURLs[this.state.selectedUploadedFile.id]}
                            title={this.state.selectedUploadedFile.fileName}
                            width="100%"
                            style={{ height: 'calc(100% - 8px)' }}
                        />
                    }
                </Modal>

                {this.state.uploadedFiles &&
                    this.state.uploadedFiles.length === 0 ?
                    <Empty
                        containerClassName="mt-2 text-center"
                        width={55}
                        height={55}
                        image={'/empty/goals.png'}
                        description={'There are currently no uploaded files.'}
                    />
                    : ''}

                {this.state.uploadedFiles &&
                    this.state.uploadedFiles.map((uploadedFile, idx) => {
                        return <div className='flex flex-v-center w-100 mb-3'>
                            <div
                                className='w-100 p-3 br-20 background-fff ant-shadow ant-shadow-more-hover parent-hover relative font-20 font-black mr-2 cursor-pointer'
                                onClick={(e) => {
                                    this.selectFile(uploadedFile)
                                }}
                            >
                                <div>{uploadedFile.fileName}</div>
                                <div className='text-muted font-16'>
                                    {uploadedFile.timeStamp && uploadedFile.timeStamp.hasOwnProperty('seconds') &&
                                        uploadedFile.timeStamp.seconds ?
                                        moment.utc(uploadedFile.timeStamp.seconds * 1000).format('MM/DD/YYYY')
                                        : ''}
                                </div>
                            </div>
                            <div className='flex flex-v-center'>
                                <Tooltip title='Download file'>
                                    <Button
                                        type="default"
                                        className="br-50 flex flex-v-center flex-h-center font-20 mr-2"
                                        style={{
                                            padding: '16px',
                                            width: '44px',
                                            height: '44px'
                                        }}
                                        onClick={(e) => {
                                            this.downloadFile(uploadedFile)
                                        }}
                                    >
                                        <Icon type="download" />
                                    </Button>
                                </Tooltip>
                                {!this.props.hasOwnProperty('isAdmin') ||
                                    (this.props.hasOwnProperty('isAdmin') && !this.props.isAdmin) ?
                                    <Popconfirm
                                        title="Are you sure you want to delete this file?"
                                        onConfirm={() => this.deleteUploadedFile(uploadedFile)}
                                        okText="Yes"
                                        cancelText="No"
                                        placement='left'
                                    >
                                        <Tooltip title='Delete file'>
                                            <Button
                                                type="default"
                                                className="br-50 flex flex-v-center flex-h-center font-20"
                                                style={{
                                                    padding: '16px',
                                                    width: '44px',
                                                    height: '44px'
                                                }}
                                            >
                                                <Icon type="delete" />
                                            </Button>
                                        </Tooltip>
                                    </Popconfirm> : ''}
                            </div>
                        </div>
                    })
                }
            </div >
        )
    }
}

export default TeacherStudentUploadView
