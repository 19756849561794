import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import { flattenDoc } from '../../Util'
import UserTextDisplay from '../../customcomponents/UserTextDisplay'

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class CoachesNoteDisplay extends Component {
    state = {
        notes: [],
        eventId: '',
        submitting: false,
        coachesNoteListener: null,
        shouldCallOnLoad: false,
    }

    componentDidMount() {
        this.getData(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.getData(nextProps)
    }

    componentWillUnmount() {
        this.unsubListener()
    }

    unsubListener = () => {
        //console.log('unsub get data')
        if (this.state.coachesNoteListener) {
            this.state.coachesNoteListener()
            this.setState({
                coachesNoteListener: null
            })
        }
    }

    getData = (componentProps) => {
        if (!componentProps.event) {
            return
        }

        // already have the data
        if (componentProps.event.id === this.state.eventId) {
            if (componentProps.onLoad) {
                componentProps.onLoad()
            }
            return
        }

        // store current eventId in state
        this.setState({
            eventId: componentProps.event.id
        })

        // unsub old listener
        this.unsubListener()

        let coachesNoteListener = db.collection(ColType.coachesNotes)
            .where('teacherId', '==', componentProps.teacherBaseProps.teacher.id)
            .where('schoolId', '==', componentProps.teacherBaseProps.teacher.schoolId)
            .where('districtId', '==', componentProps.teacherBaseProps.teacher.districtId)
            .where('eventId', '==', componentProps.event.id)
            .orderBy('timeStamp', 'desc')
            .limit(30)
            .onSnapshot((querySnapshot) => {
                let notes = []
                querySnapshot.forEach(doc => {
                    let note = flattenDoc(doc)
                    notes.push(note)
                })
                this.setState({ notes: notes }, () => {
                    setTimeout(() => {
                        if (componentProps.onLoad) {
                            componentProps.onLoad()
                        }
                    }, 1)
                })
            })

        this.setState({
            coachesNoteListener: coachesNoteListener,
        })
    }

    deleteNote = (note) => {
        db.collection(ColType.coachesNotes)
            .doc(note.id)
            .delete()
            .then(() => {
                console.log('Document updated')
                successMessage('Note deleted successfully.')
            })
            .catch((error) => {
                console.error('Error adding document: ', error)
                errorMessage("Something went wrong when removing the note.")
                this.setState({
                    submitting: true,
                })
            })
    }

    render() {
        let authorId = ''
        if (this.props.hasOwnProperty('admin') && this.props.admin) {
            authorId = this.props.admin.id
        } else if (this.props.teacherBaseProps.teacher) {
            authorId = this.props.teacherBaseProps.teacher.id
        }
        return (
            <div>
                {this.state.notes.map((note, idx) => {
                    return <div className="relative mb-3 pb-3 border-bottom" key={note.id}>
                        <div className={"absolute-tr" +
                            (this.props.allowDelete && note.authorId === authorId ? '' : ' display-none')}>
                            <Tooltip title="Delete note">
                                <Popconfirm
                                    title="Are you sure to delete this note?"
                                    onConfirm={() => {
                                        this.deleteNote(note)
                                    }}
                                    placement='topRight'
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button shape="circle" icon="delete" size={'large'}
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </div>
                        <div style={{ paddingRight: '64px' }}>
                            <UserTextDisplay
                                person={note.author}
                                timeStamp={note.timeStamp}
                                message={note.note}
                                iconToShow={'avatar'}
                            />
                        </div>
                    </div>
                })}
            </div>
        )
    }
}

export default CoachesNoteDisplay
