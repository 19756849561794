import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
//import { getIDFromURL, getQueryStringParam} from '../.././Util'
import ColType from '../.././Types'
import { Popover, Icon, Badge } from 'antd'
import { db } from '../../firebase/Firebase'
import { isReadOnlyTeacherId } from '../../Util'
import UserTextDisplay from '../../customcomponents/UserTextDisplay'

// Using this to add students until the step form for adding students is done.
class GeneralNotifications extends Component {
    state = {
        visible: false,
    }

    componentDidMount() {

    }

    onVisibleChange = (visible) => {
        this.setState({
            visible: visible
        })

        if (visible) {
            if (this.props.teacherBaseProps.generalNotifications &&
                this.props.teacherBaseProps.generalNotifications.length > 0) {
                let firstNotification = this.props.teacherBaseProps.generalNotifications[0]
                if (firstNotification.hasOwnProperty('visited') && !firstNotification.visited) {
                    // update first visited to modify the count
                    db.collection(ColType.generalNotifications)
                        .doc(firstNotification.id)
                        .update({
                            visited: true
                        })
                        .then(() => {
                            console.log('modified first notification')
                        })
                        .catch((e) => {
                            console.log('modify notification error', e)
                        })
                }
            }
        }
    }

    getCoachesNoteNotification = (notification) => {
        return <div
            key={notification.id}
            className="pl-2 pr-2 pt-1 pb-1 hover-light-grey cursor-pointer block font-black"
            onClick={(e) => {
                if (!window.location.href.includes('schedule')) {
                    this.props.history.push(
                        {
                            pathname: '/teacher/schedule/' + this.props.teacherBaseProps.teacherId
                        }
                    )
                }
                if (this.props.teacherBaseProps.setShowEventFunc) {
                    this.props.teacherBaseProps.setShowEventFunc(notification.eventId)
                }
                this.setState({
                    visible: false,
                })
            }}
        >
            <UserTextDisplay
                person={notification.author}
                timeStamp={notification.timeStamp}
                message={notification.note}
                ellipsis={true}
                iconToShow={'coachesNote'}
            />
        </div>
    }

    render() {
        return (
            <Popover
                visible={this.state.visible}
                onVisibleChange={this.onVisibleChange}
                placement="bottomRight"
                overlayInnerStyle={{ padding: '0px;' }}
                title={
                    <div className="pt-1 pb-1 font-20 font-bold" style={{ width: '400px', maxWidth: '400px' }}>
                        Notifications
                    </div>
                } content={
                    <div style={{ width: '400px', maxWidth: '400px' }}>
                        <div className="p-0 border-right-none negate-popover-padding">
                            <div className='p-0 font-black' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {isReadOnlyTeacherId(this.props.teacherBaseProps.teacherId) ||
                                    (this.props.teacherBaseProps.generalNotifications &&
                                        this.props.teacherBaseProps.generalNotifications.length === 0) ?
                                    <div className="font-20 p-2">You currently have no notifications</div>
                                    : ''}
                                {this.props.teacherBaseProps.generalNotifications &&
                                    this.props.teacherBaseProps.generalNotifications.map((notification, index) => {
                                        if (notification.notificationType === 'coachesNotes') {
                                            return this.getCoachesNoteNotification(notification)
                                        } else {
                                            return ''
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                } trigger="click">
                <div className="pl-1 pr-1 cursor-pointer">
                    <Badge
                        count={this.props.teacherBaseProps.generalNotificationsNumUnvisited}
                        overflowCount={9}
                    >
                        <div className="relative">
                            <Icon type="bell" className="font-bold font-24 mr-0 font-bold"
                                style={{ verticalAlign: "-5px" }}>
                            </Icon>
                        </div>
                    </Badge>
                </div>
            </Popover>
        )
    }
}

export default GeneralNotifications