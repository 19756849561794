import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
//import PersonHeader from '../../login/PersonHeader'
import CustomFooter from '../../login/CustomFooter'
import Empty from '../.././customcomponents/Empty'
import PlanView from '../student/PlanView'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import Note from '../progressmonitoring/Note'
import UIChart from '../progressmonitoring/UIChart'
import ReactToPrint from "react-to-print"
import { flattenDoc, getIDFromURL, capitalizeFirstChar, getQueryStringParam } from '../../Util'
import ColType from '../../Types'
import { Layout, Icon, Button, Table, Checkbox, Row, Col } from 'antd'
const { Content } = Layout
var moment = require('moment')

let progressMonitoringContainerRef = null

class TeacherStudentReport extends Component {
  state = {
    snapshotListeners: [],
    teacherId: '',
    studentId: '',
    iepId: '',
    loadingTeachersDict: {},
    teacherDict: {},
    teacher: null,
    student: null,
    IEPGoals: null,
    IEPDict: null,
    profileMode: 1,
    IEPMeasurements: {},
    measurements: [],
    notes: [],
    openKeys: [],
    selectedKeys: [],
    componentMounted: false,
    loadingNewData: false,
    dataForStudentIdLoaded: null,
    chartHasResizedAtLeastOnce: false,
    progressMonitoringContainerWidth: 500,
    newMeasurement: "",
    showNotes: false,
    showProgressMonitoring: false,
    activeKey: '1',
    currentMode: 'current',
    studentIdx: -1,
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Report - Dot It'

    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam("student")

    // when called from admin, there is no query string information,
    // the info comes from props.
    if (!teacherId && this.props.teacherBaseProps.hasOwnProperty('teacherId') &&
      this.props.teacherBaseProps.teacherId) {
      teacherId = this.props.teacher.teacherId
    }
    if (!studentId &&
      this.props.teacherBaseProps.hasOwnProperty('studentId')
      && this.props.teacherBaseProps.studentId) {
      studentId = this.props.teacherBaseProps.studentId
    }

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
    }, () => {
      if (this.props.teacherBaseProps.teacher &&
        this.props.teacherBaseProps.students &&
        this.props.teacherBaseProps.studentDict &&
        studentId) {
        var studentIdx = -1
        for (var i = 0; i < this.props.teacherBaseProps.students.length; i++) {
          var student = this.props.teacherBaseProps.students[i]
          if (student.id === studentId) {
            studentIdx = i
            break
          }
        }
        this.setState({
          studentId: studentId,
          student: this.props.teacherBaseProps.studentDict[studentId],
          studentIdx: studentIdx,
        })
        this.getStudentData()
      }
    })

    window.addEventListener("resize", this.resize)
  }

  componentWillReceiveProps(nextProps) {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam("student")

    // when called from admin, there is no query string information,
    // the info comes from props.
    if (!teacherId && this.props.teacherBaseProps.hasOwnProperty('teacherId') &&
      this.props.teacherBaseProps.teacherId) {
      teacherId = this.props.teacher.teacherId
    }
    if (!studentId &&
      this.props.teacherBaseProps.hasOwnProperty('studentId')
      && this.props.teacherBaseProps.studentId) {
      studentId = this.props.teacherBaseProps.studentId
    }

    this.setState({
      teacherId: teacherId,
      studentId: studentId
    }, () => {
      if (this.props.teacherBaseProps.teacher &&
        this.props.teacherBaseProps.students &&
        this.props.teacherBaseProps.studentDict &&
        studentId) {
        var studentIdx = -1
        for (var i = 0; i < this.props.teacherBaseProps.students.length; i++) {
          var student = this.props.teacherBaseProps.students[i]
          if (student.id === studentId) {
            studentIdx = i
            break
          }
        }
        this.setState({
          studentId: studentId,
          student: this.props.teacherBaseProps.studentDict[studentId],
          studentIdx: studentIdx,
        })

        this.getStudentData()
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  resize = () => {
    if (progressMonitoringContainerRef) {
      this.setState({
        progressMonitoringContainerWidth: progressMonitoringContainerRef.clientWidth,
        chartHasResizedAtLeastOnce: true,
      })
    } else {
      this.setState({
        progressMonitoringContainerWidth: 500,
        chartHasResizedAtLeastOnce: true,
      })
    }
  }

  getTeacherData = (teacherId) => {
    if (!teacherId) {
      return
    }
    if (this.state.loadingTeachersDict.hasOwnProperty(teacherId) &&
      this.state.loadingTeachersDict[teacherId]) {
      return
    }
    if (this.state.teacherDict.hasOwnProperty(teacherId)) {
      return
    }

    let loadingTeachersDict = this.state.loadingTeachersDict
    loadingTeachersDict[teacherId] = true
    this.setState({
      loadingTeachersDict: loadingTeachersDict,
    }, () => {
      db.collection(ColType.teacher)
        .doc(teacherId)
        .get()
        .then((doc) => {
          var teacher = flattenDoc(doc)
          var teacherDict = this.state.teacherDict
          teacherDict[teacherId] = teacher
          this.setState({
            teacherDict: teacherDict,
          })
        })
    })
  }

  getStudentData() {
    if (!this.props.teacherBaseProps.IEPGoals) {
      return
    }

    var IEPGoals = []
    var IEPDict = {}
    this.props.teacherBaseProps.IEPGoals.map((iep, idx) => {
      if (iep.studentId !== this.state.studentId) {
        return false
      }
      IEPGoals.push(iep)
      IEPDict[iep.id] = iep
      IEPDict[iep.id].measurements = []
      IEPDict[iep.id].notes = []
      IEPDict[iep.id].measurementsDataCalled = false
      IEPDict[iep.id].notesDataCalled = false
      return false
    })

    let IEPGoalsIdentical = false
    if (this.state.IEPGoals) {
      // only check len > 0 and identical len
      if (this.state.IEPGoals.length > 0 && this.state.IEPGoals.length === IEPGoals.length) {
        for (var i = 0; i < this.state.IEPGoals.length; i++) {
          if (this.state.IEPGoals[i].id === IEPGoals[i].id) {
            IEPGoalsIdentical = true
            break
          }
        }
      }
    }

    // if the IEP goals are identical, don't do anything
    if (IEPGoalsIdentical) {
      return
    }

    this.setState({
      IEPGoals: IEPGoals,
      IEPDict: IEPDict,
      componentMounted: true,
    }, () => {
      IEPGoals.map((iep, index) => {
        if (this.props.hasOwnProperty('isAdmin') &&
          this.props.isAdmin) {
          this.getTeacherData(iep.teacherId)

          db.collection(ColType.measurements)
            //.where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('studentId', '==', this.state.studentId)
            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
            .where('iepId', '==', iep.id)
            .orderBy('timeStamp')
            .get()
            .then((querySnapshot) => {
              var measurements = []
              querySnapshot.forEach((doc) => {
                const measurement = flattenDoc(doc)
                this.getTeacherData(measurement.teacherId)
                measurements.push(measurement)
              })

              var IEPDict = this.state.IEPDict
              // the left/right arrow can be pressed quickly, it may be
              // a different person by the time this call executes
              if (IEPDict.hasOwnProperty(iep.id)) {
                IEPDict[iep.id].measurements = measurements
                this.setState({
                  IEPDict: IEPDict,
                })
              }
            })

          db.collection(ColType.notes)
            //.where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('studentId', '==', this.state.studentId)
            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
            .where('iepId', '==', iep.id)
            .orderBy('timeStamp', 'desc')
            .limit(25)
            .get()
            .then((querySnapshot) => {
              var notes = []
              querySnapshot.forEach((doc) => {
                const note = flattenDoc(doc)
                this.getTeacherData(note.teacherId)
                notes.push(note)
              })

              var IEPDict = this.state.IEPDict
              // the left/right arrow can be pressed quickly, it may be
              // a different person by the time this call executes
              if (IEPDict.hasOwnProperty(iep.id)) {
                IEPDict[iep.id].notes = notes
                this.setState({
                  IEPDict: IEPDict,
                })
              }
            })
        } else {
          db.collection(ColType.measurements)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
            .where('iepId', '==', iep.id)
            .orderBy('timeStamp')
            .get()
            .then((querySnapshot) => {
              var measurements = []
              querySnapshot.forEach((doc) => {
                measurements.push(flattenDoc(doc))
              })

              var IEPDict = this.state.IEPDict
              // the left/right arrow can be pressed quickly, it may be
              // a different person by the time this call executes
              if (IEPDict.hasOwnProperty(iep.id)) {
                IEPDict[iep.id].measurements = measurements
                this.setState({
                  IEPDict: IEPDict,
                })
              }
            })

          db.collection(ColType.notes)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
            .where('iepId', '==', iep.id)
            .orderBy('timeStamp', 'desc')
            .limit(25)
            .get()
            .then((querySnapshot) => {
              var notes = []
              querySnapshot.forEach((doc) => {
                notes.push(flattenDoc(doc))
              })

              var IEPDict = this.state.IEPDict
              // the left/right arrow can be pressed quickly, it may be
              // a different person by the time this call executes
              if (IEPDict.hasOwnProperty(iep.id)) {
                IEPDict[iep.id].notes = notes
                this.setState({
                  IEPDict: IEPDict,
                })
              }
            })
        }

        return false
      })
    })
  }

  onOpenChange = (openKeys) => {
    console.log(openKeys)

    this.setState({
      openKeys: openKeys,
    })
  }

  menuClick = ({ item, key, keyPath }) => {
    console.log({ item, key, keyPath })
    if (keyPath.length < 2) return

    this.setState({
      iepId: keyPath[0],
      studentId: keyPath[1],
      selectedKeys: [key],
      loadingNewData: true,
    }, () => {
      this.subscribeMeasurementListener()
    })
  }

  tabChange = (activeKey) => {
    this.setState({
      activeKey: activeKey,
    })
  }

  setChartKey = () => {
    this.setState({
      activeKey: '1',
    })
  }

  modeChange = (e) => {
    console.log('Radio change', e)
    this.setState({
      currentMode: e.target.value,
      iep: null,
      activeKey: '1',
      openKeys: [],
      selectedKeys: [],
    })
  }

  showProgressMonitoringOnChange = (e) => {
    this.setState({
      showProgressMonitoring: e.target.checked
    })
  }

  showNotesOnChange = (e) => {
    this.setState({
      showNotes: e.target.checked
    })
  }

  render() {
    let chartWidth = 500
    if (this.state.chartHasResizedAtLeastOnce) {
      chartWidth = this.state.progressMonitoringContainerWidth
    } else if (progressMonitoringContainerRef) {
      chartWidth = progressMonitoringContainerRef.clientWidth
    }

    let prevStudentIdx = 0
    let nextStudentIdx = 0
    if (this.props.teacherBaseProps.students && this.state.studentIdx !== -1) {
      prevStudentIdx = this.state.studentIdx - 1
      nextStudentIdx = this.state.studentIdx + 1
      if (prevStudentIdx < 0) {
        prevStudentIdx = 0
      }
      if (nextStudentIdx > this.props.teacherBaseProps.students.length - 1) {
        nextStudentIdx = this.props.teacherBaseProps.students.length - 1
      }
    }

    return (
      <div>
        <Layout className={(this.props.hasOwnProperty("noMarginTop") &&
          this.props.noMarginTop ?
          "" :
          "layout-header-mt")}>
          {this.props.teacherBaseProps.teacher ?
            <Layout>

              <Layout className={(this.props.hasOwnProperty("noMarginTop") &&
                this.props.noMarginTop ?
                "" : "content")}>
                <Content className={(this.props.hasOwnProperty("noMarginTop") &&
                  this.props.noMarginTop ?
                  "" : "layout-content")}>
                  {this.state.student && this.state.IEPGoals && this.state.IEPDict ?
                    <div className="sub-menu-width m-lr-auto background-fff">
                      {!this.props.hasOwnProperty("showStudentNav") ||
                        (this.props.hasOwnProperty("showStudentNav") && this.props.showStudentNav) ?
                        <div>
                          <div className="w-100 flex flex-v-center mb-4 font-16 font-bold">
                            <Link to={"/teacher/reports/" + this.props.teacherBaseProps.teacher.id} className="font-16">
                              Reports
                            </Link>
                            <Icon type="right" className="ml-1 mr-1 font-16" />
                            <span className="font-16">
                              {this.props.teacherBaseProps.studentDict[this.state.studentId].firstName + " " + this.props.teacherBaseProps.studentDict[this.state.studentId].lastName}
                            </span>
                          </div>


                          <div className="text-center mb-4">
                            <Row type="flex" align="middle">
                              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                {this.state.studentIdx !== -1 ?
                                  <div className={"float-left flex flex-v-center" +
                                    (this.state.studentIdx === 0 ? " invisible" : "")}>
                                    <Link to={"/teacher/student-report/" + this.props.teacherBaseProps.teacher.id +
                                      "?student=" + this.props.teacherBaseProps.students[prevStudentIdx].id}
                                      disabled={this.state.studentIdx === 0}
                                      className="ant-btn ant-btn-circle circle-icon-btn-lg">
                                      <Icon type="arrow-left" />
                                    </Link>
                                    <span className="font-16 font-bold ml-2">Previous student</span>
                                  </div>
                                  : ''}
                              </Col>
                              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                <PersonAvatar
                                  person={this.state.student}
                                  size={'large'}
                                  containerClassName="font-30 no-text-highlight"
                                  avatarClassName="font-20 font-bold"
                                  personClassName="font-30 font-bold"
                                />
                              </Col>
                              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                {this.state.studentIdx !== -1 ?
                                  <div className={"float-right flex flex-v-center" +
                                    (this.state.studentIdx === this.props.teacherBaseProps.students.length - 1 ? " invisible" : "")}>
                                    <span className="font-16 font-bold mr-2">Next student</span>
                                    <Link to={"/teacher/student-report/" + this.props.teacherBaseProps.teacher.id +
                                      "?student=" + this.props.teacherBaseProps.students[nextStudentIdx].id}
                                      className="ant-btn ant-btn-circle circle-icon-btn-lg">
                                      <Icon type="arrow-right" />
                                    </Link>
                                  </div>
                                  : ''}
                              </Col>
                            </Row>
                          </div>
                        </div> : ''}
                      {this.state.IEPGoals.length === 0 ?
                        <div className="text-center w-100">
                          <Empty
                            containerClassName="mt-4 pt-2 text-center font-16"
                            width={55}
                            height={55}
                            description={'No data'}
                          />
                        </div>
                        :
                        <div>
                          <div className="border br-4 overflow-hidden ant-shadow-transparent-top">
                            <h1 className={"pt-3 pb-1 pl-4 pr-4 font-bold font-30"}>
                              <span>Student Report</span>
                            </h1>
                            <div className="flex flex-v-center pl-4 pr-4 pb-3 mb-3 border-bottom">
                              <Checkbox
                                size={'large'}
                                className="mr-1 font-16 font-black"
                                onChange={this.showProgressMonitoringOnChange}
                                checked={this.state.showProgressMonitoring}
                              >
                                <span className="pl-1 font-bold">Show progress monitoring</span>
                              </Checkbox>
                              <Checkbox
                                size={'large'}
                                className="font-16 font-black"
                                onChange={this.showNotesOnChange}
                                checked={this.state.showNotes}
                              >
                                <span className="pl-1 font-bold">Show notes</span>
                              </Checkbox>

                              <ReactToPrint
                                trigger={() =>
                                  <Button
                                    type={'primary'}
                                    className="ml-auto lg-btn pl-3 pr-3"
                                  >
                                    <Icon type="printer" className="font-bold mr-1 font-20" />
                                    <span className="font-20">Print</span>
                                  </Button>
                                }
                                content={() => this.componentRef}
                              />
                            </div>
                            {/*<Checkbox size={'large'} className="mr-2"
                    onChange={this.showRubricOnChange}>
                      <span className="pl-1">Show Rubric</span>
                    </Checkbox>*/}


                            {/** offset the margin of the printable component so that it lines up correctly */}
                            <Content>
                              {/** printable component, gave it layout-content and offsetted it above */}
                              <div className="pl-4 pr-4 pb-4 mt-0 print-remove-padding" ref={el => (this.componentRef = el)}>
                                {["IEP", "Tier 2", "Tier 3"].map((goalType, index) => {
                                  return this.state.IEPGoals.map((iep, index) => {
                                    if (goalType === "IEP" && iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
                                      return false
                                    } else if (goalType !== "IEP" && !iep.hasOwnProperty("isMTSS")) {
                                      return false
                                    } else if (iep.hasOwnProperty("isMTSS") &&
                                      iep.isMTSS &&
                                      iep.iep.hasOwnProperty("tier") &&
                                      iep.iep.tier !== goalType) {
                                      return false
                                    }
                                    var measurementTableColumns = [{
                                      title: "Date",
                                      dataIndex: "date",
                                      key: "date",
                                    },
                                    {
                                      title: "Info",
                                      dataIndex: "info",
                                      key: "info",
                                    }]
                                    var measurementTableData = []
                                    var baselineMeasurement = {
                                      key: "baseline-" + iep.id,
                                      date: moment.utc(iep.timeStamp.seconds * 1000)
                                        .format("MM/DD/YYYY"),
                                      info: "Baseline"
                                    }
                                    iep.iep.baselineProgressMonitoring.map((measurement, idx) => {
                                      measurementTableColumns.push({
                                        title: "Critical Skill " + (idx + 1),
                                        dataIndex: measurement.info,
                                        key: measurement.info,
                                      })
                                      baselineMeasurement[measurement.info] =
                                        measurement.num + " / " + measurement.max
                                      return false
                                    })
                                    measurementTableData.push(baselineMeasurement)
                                    this.state.IEPDict[iep.id].measurements.map((measurement, idx) => {
                                      var d = {
                                        key: "measurement-table-" + idx + "-iep-" + iep.id,
                                        date: moment.utc(measurement.timeStamp.seconds * 1000)
                                          .format("MM/DD/YYYY"),
                                        info: "Score"
                                      }
                                      measurement.progressMonitoringResult.map((res, idx) => {
                                        d[res.info] = res.num + " / " + res.max
                                        return false
                                      })
                                      measurementTableData.push(d)
                                      return false
                                    })
                                    var targetMeasurement = {
                                      key: "target-" + iep.id,
                                      date: "Upon completion",
                                      info: "Target"
                                    }
                                    iep.iep.targetProgressMonitoring.map((measurement, idx) => {
                                      targetMeasurement[measurement.info] =
                                        measurement.num + " / " + measurement.max
                                      return false
                                    })
                                    measurementTableData.push(targetMeasurement)
                                    return <div className="pt-4 print-remove-padding" key={'iep-print-' + iep.id}>
                                      <h1 className="mb-2 mt-0 font-bold">
                                        {this.props.hasOwnProperty('isAdmin') &&
                                          this.props.isAdmin &&
                                          iep.hasOwnProperty("teacherId") &&
                                          iep.teacherId &&
                                          this.state.teacherDict &&
                                          this.state.teacherDict.hasOwnProperty(iep.teacherId) &&
                                          this.state.teacherDict[iep.teacherId] ?
                                          <div className='font-20 mb-1'>
                                            Created by or transferred to: {this.state.teacherDict[iep.teacherId].firstName + ' ' +
                                              this.state.teacherDict[iep.teacherId].lastName}
                                          </div>
                                          :
                                          <div className='font-20'>Created by or transferred to: Not found.</div>
                                        }
                                        <PersonAvatar
                                          size={'large'} person={this.state.student}
                                          personClassName="font-black"
                                        />
                                      </h1>
                                      <div className="font-16 font-500 mb-3">
                                        <h2 className="font-bold mb-0 font-24">
                                          {iep.iep.mainSubject}
                                        </h2>
                                        <h2 className="mb-0 font-24">
                                          {capitalizeFirstChar(iep.iep.standardDescription)}
                                        </h2>
                                        {/*
                                        <h3 className="font-20">
                                          Date added: {moment(iep.timeStamp.seconds * 1000).format('MM/DD/YYYY')}
                                        </h3>
                                        */}
                                      </div>
                                      <div className="mb-3">
                                        <div className="font-24 font-bold mb-1">
                                          {(iep.hasOwnProperty("isMTSS") && iep.isMTSS) ?
                                            iep.iep.tier + " Goal" : "IEP Goal"}
                                        </div>
                                        <h3 className="mb-4 font-20">
                                          {iep.iep.iepParagraph}
                                        </h3>
                                        <PlanView iep={iep} student={this.state.student} />
                                      </div>
                                      <div className={"" + (!this.state.showNotes ? "mb-3" : '')}>
                                        {this.state.showProgressMonitoring ?
                                          <div>
                                            <div className="mb-3" ref={(node) => {
                                              progressMonitoringContainerRef = node
                                            }}>
                                              <UIChart
                                                containerWidth={chartWidth}
                                                allowMeasurementAdding={false}
                                                iep={iep}
                                                measurements={(this.state.IEPDict.hasOwnProperty(iep.id) &&
                                                  this.state.IEPDict[iep.id]) ? this.state.IEPDict[iep.id].measurements : ''}
                                                loadingNewData={false}
                                              />
                                            </div>
                                          </div> : ''}

                                        {this.state.showRubric ?
                                          <div className={"mt-4 mb-2" +
                                            (!this.state.showNotes ?
                                              " page-break-inside-avoid" : "")}>
                                            <div className="font-18 font-bold mb-2">Rubric</div>
                                            {iep.iep.progressMonitoring.map((item, index) => {
                                              return <div className="mb-1"
                                                key={"progress-monitoring-rubrik" + index}>
                                                <span className="font-18 mr-2">
                                                  Critical skill {index + 1}:</span>
                                                <span>_____</span>
                                                <span className="font-18">
                                                  <span className="ml-1 mr-1">/</span>
                                                  <span>{item.num + ' ' + item.info}</span>
                                                </span>
                                              </div>
                                            })
                                            }
                                            <div className="mt-4 mb-4">
                                              <Table
                                                className="table-fixed"
                                                pagination={false}
                                                dataSource={measurementTableData}
                                                columns={measurementTableColumns} />
                                            </div>
                                          </div>
                                          : ''}

                                        {!this.state.showNotes ?
                                          '' :
                                          <div className="page-break-after-always">
                                            <h2 className="font-bold mb-0 font-24">Notes</h2>
                                            <div>
                                              {this.state.IEPDict[iep.id].notes.length === 0 ?
                                                <div className="mt-1 font-20">
                                                  No notes to display.
                                                </div>
                                                : ''}
                                              {this.state.IEPDict[iep.id].notes.map((note, index) => {
                                                let teacher = this.props.teacherBaseProps.teacher
                                                if (this.props.hasOwnProperty('isAdmin') &&
                                                  this.props.isAdmin) {
                                                  if (this.state.teacherDict.hasOwnProperty(note.teacherId) &&
                                                    this.state.teacherDict[note.teacherId]) {
                                                    teacher = this.state.teacherDict[note.teacherId]
                                                  } else {
                                                    teacher = null
                                                  }
                                                }
                                                return <div key={note.id} className="page-break-inside-avoid">
                                                  <Note
                                                    teacher={teacher}
                                                    note={note}
                                                    iep={iep}
                                                    allowDelete={false}
                                                  />
                                                </div>
                                              })
                                              }
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  })
                                })
                                }
                              </div>
                            </Content>

                          </div>

                        </div>}
                    </div>
                    :
                    <div>
                      {this.props.teacherBaseProps.IEPGoals &&
                        this.props.teacherBaseProps.IEPGoals.length === 0 ?
                        <h2>No goals have been drafted yet.</h2>
                        :
                        ''
                      }
                    </div>
                  }
                </Content>
              </Layout>

            </Layout>
            : ''}
        </Layout>
        {!this.props.hasOwnProperty("showFooter") ||
          (this.props.hasOwnProperty("showFooter") && this.props.showFooter) ?
          <CustomFooter teacherId={this.state.teacherId} />
          : ''}
      </div>
    )
  }
}

export default TeacherStudentReport
