import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../../Types'
import PlanView from './PlanView'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import UIChart from '../progressmonitoring/UIChart'
import Note from '../progressmonitoring/Note'
import { flattenDoc, capitalizeFirstChar, isReadOnlyTeacherId } from '../.././Util'
import { Button, Modal, Icon, Checkbox } from 'antd'
import ReactToPrint from "react-to-print"
import Keywords from './Keywords'

let progressMonitoringContainerRef = null

class GoalViewer extends Component {
  state = {
    teacherId: '',
    teacher: [],
    student: null,
    submitting: false,
    isPrinting: false,
    measurements: [],
    notes: [],
    progressMonitoringContainerWidth: 500,
    showNotes: false,
    showProgressMonitoring: false,
  }

  showModal = () => {
    // only get student data here when the modal is opened
    // don't want to get it before the modal has been opened or
    // it will be grabbed on every new student plan display
    this.getStudentData()
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  showProgressMonitoringOnChange = (e) => {
    this.setState({
      showProgressMonitoring: e.target.checked
    })
  }

  showNotesOnChange = (e) => {
    this.setState({
      showNotes: e.target.checked
    })
  }

  getStudentData() {
    if (!(this.props.teacherBaseProps.teacher && this.props.iep)) {
      return
    }
    if (isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) {
      var measurements = []
      if (this.props.teacherBaseProps.readOnlyIEPMeasurementDict &&
        this.props.teacherBaseProps.readOnlyIEPMeasurementDict.hasOwnProperty(this.props.iep.id)) {
        measurements = this.props.teacherBaseProps.readOnlyIEPMeasurementDict[this.props.iep.id]
      }
      var notes = []
      if (this.props.teacherBaseProps.readOnlyIEPNoteDict &&
        this.props.teacherBaseProps.readOnlyIEPNoteDict.hasOwnProperty(this.props.iep.id)) {
        notes = this.props.teacherBaseProps.readOnlyIEPNoteDict[this.props.iep.id]
      }

      this.setState({
        measurements: measurements,
        notes: notes,
      })
    } else {
      db.collection(ColType.measurements)
        .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
        //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
        //.where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
        .where('iepId', '==', this.props.iep.id)
        .orderBy('timeStamp')
        .get()
        .then((querySnapshot) => {
          var measurements = []
          querySnapshot.forEach((doc) => {
            measurements.push(flattenDoc(doc))
          })

          this.setState({
            measurements: measurements
          })
        })

      db.collection(ColType.notes)
        .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
        .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
        .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
        .where('iepId', '==', this.props.iep.id)
        .orderBy('timeStamp', 'desc')
        .limit(25)
        .get()
        .then((querySnapshot) => {
          var notes = []
          querySnapshot.forEach((doc) => {
            notes.push(flattenDoc(doc))
          })

          this.setState({
            notes: notes,
          })
        })
    }
  }

  render() {
    let chartWidth = 500
    if (this.state.chartHasResizedAtLeastOnce) {
      chartWidth = this.state.progressMonitoringContainerWidth
    } else if (progressMonitoringContainerRef) {
      chartWidth = progressMonitoringContainerRef.clientWidth
    }

    let accommodationNum = 0
    let accommodationNums = {}
    if (this.props.student) {
      if (this.props.student.accommodations.hasOwnProperty('instructionBarrier')) {
        accommodationNum += 1
        accommodationNums["instructionBarrier"] = accommodationNum
      }
      if (this.props.student.accommodations.hasOwnProperty('engagementBarrier')) {
        accommodationNum += 1
        accommodationNums["engagementBarrier"] = accommodationNum
      }
      if (this.props.student.accommodations.hasOwnProperty('organizationBarrier')) {
        accommodationNum += 1
        accommodationNums["organizationBarrier"] = accommodationNum
      }
    }

    return (
      <div>
        {this.props.iep ?
          <div>
            <Button
              size={"large"}
              className="ant-btn ant-btn-lg font-bold pl-2 pr-2 paper-btn"
              onClick={this.showModal}
            >
              <Icon type="printer" className="font-bold" />
              <span>
                {(this.props.iep.hasOwnProperty("isMTSS") &&
                  this.props.iep.isMTSS) ?
                  "Print " + this.props.iep.iep.tier + " Goal" : "Print IEP"}
              </span>
            </Button>
            <Modal
              title={"Print " + ((this.props.iep.hasOwnProperty("isMTSS") &&
                this.props.iep.isMTSS) ?
                this.props.iep.iep.tier + " Goal" : "IEP Goal")}
              visible={this.state.visible}
              footer={null}
              width={1024}
              bodyStyle={{ padding: '0px' }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <div className="font-28 font-bold pl-3 pt-3">
                <div className="pr-3 flex flex-v-center">
                  <ReactToPrint
                    trigger={() =>
                      <Button
                        size={'large'}
                        className="ant-btn-primary"
                      >
                        <Icon type="printer" className="font-bold" />
                        <span>Print</span>
                      </Button>
                    }
                    content={() => this.content}
                  />
                  <Checkbox
                    size={'large'}
                    className="ml-auto mr-1"
                    onChange={this.showProgressMonitoringOnChange}
                    checked={this.state.showProgressMonitoring}
                  >
                    <span className="pl-1 font-bold">Show progress monitoring</span>
                  </Checkbox>
                  <Checkbox
                    size={'large'}
                    onChange={this.showNotesOnChange}
                    checked={this.state.showNotes}
                  >
                    <span className="pl-1 font-bold">Show notes</span>
                  </Checkbox>
                </div>
              </div>
              <div ref={el => (this.content = el)} className="p-3 page-break-inside-avoid print-remove-padding">
                <div>
                  <PersonAvatar
                    person={this.props.student}
                    size={'large'}
                    containerClassName="font-25 mt-6px mb-1"
                    avatarClassName="font-25 font-bold"
                    personClassName="font-bold font-black"
                  />
                  <div>
                    <h1 className="font-bold mb-0">
                      {this.props.iep.iep.mainSubject}
                    </h1>
                    <h2 className="mb-2 font-bold">
                      {capitalizeFirstChar(this.props.iep.iep.standardDescription)}
                    </h2>
                    <div className="mb-3">

                      <h2 className="font-bold font-24 mb-1">
                        {(this.props.iep.hasOwnProperty("isMTSS") && this.props.iep.isMTSS) ?
                          this.props.iep.iep.tier + " Goal" : "IEP Goal"}
                      </h2>

                      <div className='font-20'>
                        <Keywords paragraph={this.props.iep.iep.iepParagraph} />
                      </div>
                      <PlanView iep={this.props.iep} student={this.props.student} />
                    </div>

                    <div className={"" + (!this.state.showNotes ? "mb-3" : '')}>
                      {this.state.showProgressMonitoring ?
                        <div className=''>
                          <div className="mb-3" ref={(node) => {
                            progressMonitoringContainerRef = node
                          }}>
                            <UIChart
                              containerWidth={chartWidth}
                              allowMeasurementAdding={false}
                              iep={this.props.iep}
                              measurements={this.state.measurements}
                            />
                          </div>
                        </div> : ''}


                      {!this.state.showNotes ?
                        '' :
                        <div className="">
                          <h2 className="font-bold mb-0 font-24">Notes</h2>
                          <div>
                            {this.state.notes.length === 0 ?
                              <div className="mt-1 font-20">
                                No notes to display.
                              </div>
                              : ''}
                            {this.state.notes.map((note, index) => {
                              return <div key={note.id} class="page-break-inside-avoid">
                                <Note
                                  teacher={this.props.teacherBaseProps.teacher}
                                  note={note}
                                  iep={this.props.iep}
                                  allowDelete={false}
                                />
                              </div>
                            })
                            }
                          </div>
                        </div>
                      }
                    </div>

                  </div>
                </div>
              </div>

            </Modal>
          </div>
          : ''}
      </div>
    )
  }
}

export default GoalViewer