import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import {
    createIEPGoalText, createMTSSGoalText
} from '../.././Util'
import { Button, Form, notification, DatePicker, Modal } from 'antd'
const FormItem = Form.Item


// Using this to add students until the step form for adding students is done.
class ExtendGoalModal extends Component {
    state = {
        teacherId: '',
        submitting: false,
        visible: false,
    }

    componentDidMount() {
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    // add a new event to the teacher's events
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)
                    this.props.iep.iep.completionDate = values.completionDate.format('MM/DD/YYYY')
                    if (this.props.iep.hasOwnProperty('isMTSS') && this.props.iep.isMTSS) {
                        this.props.iep.iep.iepParagraph = createMTSSGoalText(this.props.iep.iep, this.props.student, this.props.iep.iep.numProgressMonitoringScores)
                    } else {
                        this.props.iep.iep.iepParagraph = createIEPGoalText(this.props.iep.iep, this.props.student, this.props.iep.iep.numProgressMonitoringScores)
                    }
                    db.collection(ColType.iep)
                        .doc(this.props.iep.id)
                        .update({
                            'iep.completionDate': this.props.iep.iep.completionDate,
                            'iep.iepParagraph': this.props.iep.iep.iepParagraph,
                        })
                        .then(() => {
                            notification.open({
                                type: 'success',
                                duration: 4,
                                message: 'Goal completion date extended successfully',
                                placement: 'bottomRight'
                            })

                            this.setState({
                                submitting: false,
                                visible: false,
                            }, () => {
                                this.props.form.resetFields()
                            })
                        })
                        .catch((e) => {
                            console.log('error', e)
                            this.setState({
                                submitting: false,
                            }, () => {
                                notification.open({
                                    type: 'error',
                                    duration: 4,
                                    message: 'Something went wrong. Please try again.',
                                    placement: 'bottomRight'
                                })
                            })
                        })
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        return (
            <div className='ml-auto'>
                <div>
                    <Button size="large" onClick={this.showModal}>Change completion date</Button>
                </div>

                <Modal
                    title={null}
                    visible={this.state.visible}
                    footer={null}
                    onCancel={(e) => {
                        this.hideModal()
                    }}
                >
                    <div className="font-20 mb-2 mt-4 font-bold">Change the completion date of this goal</div>
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        <FormItem
                            label={'Completion date'}>
                            {getFieldDecorator('completionDate', {
                                rules: [{
                                    required: true,
                                    message: 'Select a completion date.'
                                }],
                            })(
                                <DatePicker
                                    size={'large'}
                                    onChange={this.onCompletionDateChange}
                                    format={"MM/DD/YYYY"}
                                />
                            )}
                        </FormItem>

                        <div className="flex flex-v-center">
                            <Button className="ant-btn lg-btn ml-auto mr-2"
                                disabled={this.state.submitting}
                                onClick={(e) => {
                                    this.hideModal()
                                }}
                            >
                                Cancel
                            </Button>
                            <FormItem className="mb-0">
                                <Button
                                    type="primary"
                                    size={'large'}
                                    disabled={this.state.submitting}
                                    htmlType="submit"
                                    className="ant-btn ant-btn-primary lg-btn text-align-center float-right font-bold"
                                >
                                    <span>Change completion date</span>
                                </Button>
                            </FormItem>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(ExtendGoalModal)
