import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import VideoContainer from '../video/VideoContainer'
import { firebase } from '../firebase/Firebase'
//import { getInitials, isSandboxNoSub, isReadOnlyTeacherId } from '.././Util'
import { Layout, Menu, message } from 'antd'
const { Header } = Layout
//const moment = require('moment')

const errorMessage = (description) => {
    message.error(description)
}

class TeacherHeaderForms extends Component {
    state = {
        newPopoverVisible: false,
        accountPopoverVisible: false,
        searchValue: '',
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    componentDidMount() {

    }

    componentWillReceiveProps(newProps) {
        // on url change set popovers visible to false
        this.setState({
            newPopoverVisible: false,
            accountPopoverVisible: false,
        })
    }

    newVisibleChange = (visible) => {
        this.setState({ newPopoverVisible: visible })
    }

    accountVisibleChange = (visible) => {
        this.setState({ accountPopoverVisible: visible })
    }

    signOut = () => {
        firebase.auth().signOut().then(function () {
            console.log('Signed Out')
            //sending users to the sign in page after signing out for now.
            window.location.href = "/sign-in"
        }, function (error) {
            console.error('Sign Out Error', error)
            errorMessage("There was an error signing you out. Please try again.")
        })
    }

    onChange = (value) => {
        console.log(`selected ${value}`)
    }

    onSelect = (value, option) => {
        console.log('on select', value, option)
        console.log('/teacher/student/' +
            this.props.person.id + '?student=' +
            option.key)

        this.props.history.push(
            {
                pathname: '/teacher/student/' +
                    this.props.person.id + '?student=' +
                    option.key
            }
        )
    }

    render() {
        return (
            <div>
                <Header
                    className={(this.props.hasOwnProperty("positionNotFixed") &&
                        this.props.positionNotFixed ? '' :
                        'position-fixed') +
                        (this.props.readOnly ? ' t-80' : '')}
                    style={{
                        padding: 0,
                        textAlign: 'left',
                        width: '100%',
                        zIndex: 2,
                        borderBottom: '1px solid #f1f1f1'
                    }}
                >
                    <div>
                        <Menu
                            selectedKeys={[this.props.selectedKey]}
                            mode="horizontal"
                            className="font-16 no-border-bottom flex flex-row"
                            style={{ lineHeight: '80px' }}
                        >
                            <Menu.Item key="teacher-logo" className="inline-flex flex-v-center menu-no-bb p-0 ml-20 mr-3">
                                <img src='/dotitlogo.png' alt='logo' height='40' />
                            </Menu.Item>
                        </Menu>
                    </div>
                </Header>
            </div>
        )
    }
}

export default TeacherHeaderForms