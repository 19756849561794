import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Button, Form, Input, notification, Tooltip } from 'antd'
import PersonAvatar from '../../customcomponents/PersonAvatar'
const FormItem = Form.Item
const { TextArea } = Input

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

// Using this to add students until the step form for adding students is done.
class AddCoachesNoteForm extends Component {
    state = {
        submitting: false,
    }

    componentDidMount() {

    }

    // add a new event to the teacher's events
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)

                    // default author is the teacher
                    let isAdmin = this.props.hasOwnProperty('admin') && this.props.admin
                    let authorId = this.props.teacherBaseProps.teacher.id
                    let author = {
                        firstName: this.props.teacherBaseProps.teacher.firstName,
                        lastName: this.props.teacherBaseProps.teacher.lastName,
                    }

                    // if any admin is writing this note, then the author is
                    // the admin (school admin/instructional facilitator/admin
                    // should all pass the admin prop).
                    if (isAdmin) {
                        authorId = this.props.admin.id
                        author = {
                            firstName: this.props.admin.firstName,
                            lastName: this.props.admin.lastName,
                        }
                    }

                    let note = {
                        eventId: this.props.event.id,
                        authorId: authorId,
                        author: author,
                        teacherId: this.props.teacherBaseProps.teacher.id,
                        schoolId: this.props.teacherBaseProps.teacher.schoolId,
                        districtId: this.props.teacherBaseProps.teacher.districtId,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        note: values.note,
                    }

                    db.collection(ColType.coachesNotes)
                        .add(note)
                        .then(() => {
                            successMessage('Note added successfully.')
                            this.props.form.resetFields()
                            this.setState({
                                submitting: false
                            })

                            // if admin send a notification to the teacher of the note
                            if (isAdmin) {
                                note.notificationType = 'coachesNotes'
                                note.eventTitle = this.props.event.title
                                // affects notification display, must be present
                                note.visited = false
                                db.collection(ColType.generalNotifications)
                                    .add(note)
                                    .then(() => {
                                        console.log('notification sent')
                                    })
                                    .catch((e) => {
                                        console.log('general notification error: ', e)
                                    })
                            }
                        })
                        .catch((error) => {
                            console.error('Error adding document: ', error)
                            this.setState({
                                submitting: false
                            })
                            errorMessage("Something went wrong when trying to edit the group.")
                        })
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        let author = null
        if (this.props.hasOwnProperty('admin') && this.props.admin) {
            author = this.props.admin
        } else if (this.props.teacherBaseProps.teacher) {
            author = this.props.teacherBaseProps.teacher
        }

        return (
            <div>
                <Form
                    onSubmit={this.handleSubmit} className="block-label-form">
                    <div className="flex w-100">
                        <div className="mr-1">
                            {author ?
                                <Tooltip title={author.firstName + ' ' + author.lastName}>
                                    <div>
                                        <PersonAvatar
                                            person={author}
                                            size={'large'}
                                            avatarColor={'#d3adf7'}
                                            containerClassName="font-20 no-text-highlight"
                                            avatarClassName="font-20 font-bold avatar-vlg"
                                            personClassName="font-bold"
                                            displayUserName={false}
                                        />
                                    </div>
                                </Tooltip>
                                : ''}
                        </div>
                        <div className="w-100 no-margin-bottom-form-item">
                            <FormItem {...formItemLayout} label="">
                                {getFieldDecorator('note', {
                                    rules: [{ required: true, message: 'Note is required.' }],
                                })(
                                    <TextArea
                                        placeholder="Note..."
                                        autosize={{ minRows: 2 }}
                                    />

                                )}
                            </FormItem>
                        </div>
                    </div>

                    <FormItem className="mb-0 mt-3">
                        <Button
                            type="primary"
                            className="float-right"
                            size={'large'}
                            htmlType="submit"
                            disabled={this.state.submitting}
                        >
                            Add note
                        </Button>
                    </FormItem>
                </Form>
            </div>

        )
    }
}

export default Form.create()(AddCoachesNoteForm)
