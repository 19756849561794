import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc, getInitials } from '.././Util'
import ColType from '.././Types'
import { Icon, Form, Input, Button, Avatar, Checkbox } from 'antd'
import EditPersonnelNameForm from '../admin/EditPersonnelNameForm'
const FormItem = Form.Item

// This page can only be accessed by us, it is how you add admins that then can add other users.
class DistrictSearch extends Component {
  state = {
    districtInfo: [],
    teachersObj: {},
    schoolAdminsObj: {},
    instructionalFacilitatorsObj: {},
    adminsObj: {},
    searchByCleverId: true,
  }

  componentDidMount() {

  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        let district = null
        if (this.state.searchByCleverId) {
          let doc = await db.collection(ColType.district)
            .doc(values.districtName)
            .get()
          district = flattenDoc(doc)
        } else {
          let docs = await db.collection(ColType.district)
            .where('district', '==', values.districtName)
            .get()
          if (docs.empty) {
            return false
          }
          district = flattenDoc(docs.docs[0])
        }

        console.log("found districts", district)

        var districtInfo = []

        db.collection(ColType.schoolAdmin)
          .where('districtId', '==', district.id)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return false
            }

            var schoolAdmins = []
            querySnapshot.forEach((doc) => {
              var schoolAdmin = flattenDoc(doc)
              schoolAdmins.push(schoolAdmin)
            })

            let schoolAdminsObj = this.state.schoolAdminsObj
            schoolAdminsObj[district.id] = schoolAdmins

            this.setState({
              schoolAdminsObj: schoolAdminsObj,
            })
          })

        db.collection(ColType.instructionalFacilitator)
          .where('districtId', '==', district.id)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return false
            }

            var instructionalFacilitators = []
            querySnapshot.forEach((doc) => {
              var instructionalFacilitator = flattenDoc(doc)
              instructionalFacilitators.push(instructionalFacilitator)
            })

            let instructionalFacilitatorsObj = this.state.instructionalFacilitatorsObj
            instructionalFacilitatorsObj[district.id] = instructionalFacilitators

            this.setState({
              instructionalFacilitatorsObj: instructionalFacilitatorsObj,
            })
          })

        // teachers
        db.collection(ColType.teacher)
          .where('districtId', '==', district.id)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return false
            }

            var teachers = []
            querySnapshot.forEach((doc) => {
              var teacher = flattenDoc(doc)
              teachers.push(teacher)
            })

            console.log('teachers', teachers)

            let teachersObj = this.state.teachersObj
            teachersObj[district.id] = teachers

            this.setState({
              teachersObj: teachersObj,
            })
          })

        // admins
        db.collection(ColType.admin)
          .where('districtId', '==', district.id)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return false
            }

            var admins = []
            querySnapshot.forEach((doc) => {
              var admin = flattenDoc(doc)
              admins.push(admin)
            })

            console.log('admins', admins)

            let adminsObj = this.state.adminsObj
            adminsObj[district.id] = admins

            this.setState({
              adminsObj: adminsObj,
            })
          })



        return db.collection(ColType.admin)
          .where('districtId', '==', district.id)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            db.collection(ColType.users)
              .where('districtId', '==', district.id)
              .limit(1)
              .get()
              .then((userQuerySnapshot) => {
                if (querySnapshot.empty || userQuerySnapshot.empty) {
                  return false
                }
                var adminDoc = querySnapshot.docs[0]
                var adminObj = flattenDoc(adminDoc)
                var userDoc = userQuerySnapshot.docs[0]
                var userObj = flattenDoc(userDoc)
                let districtObj = {
                  district: district,
                  admin: adminObj,
                  user: userObj,
                }
                districtInfo.push(districtObj)

                console.log("found admin in district", adminObj)
                if (this.props.hasOwnProperty("onDistrictFound")) {
                  this.props.onDistrictFound(districtObj)
                }
                this.setState({
                  districtInfo: districtInfo,
                })
              })
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 25 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 25 },
        sm: { span: 17 },
      },
    }

    return (
      <div className="border br-4 p-3">
        <div className="font-24 font-bold mb-3">Search for a district</div>
        <Checkbox className='mb-2' size='large' checked={this.state.searchByCleverId}
          onChange={(e) => {
            this.setState({
              searchByCleverId: e.target.checked,
            })
          }}>
          <span className='pl-1'>Search by Clever Id</span>
        </Checkbox>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem {...formItemLayout} label="District name">
            {getFieldDecorator('districtName', {
              rules: [{ required: true, message: 'Please input a district name.' }],
            })(
              <Input
                size={"large"}
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="District name..." />
            )}
          </FormItem>
          <FormItem className="mb-0">
            <Button
              size={"large"}
              type="primary"
              htmlType="submit"
              className="login-form-button text-align-center w-100"
            >
              Search for a district
            </Button>
          </FormItem>
        </Form>

        <div className="mt-3 pt-3 border-top">
          <h2>Search Results</h2>
          {this.state.districtInfo.length === 0 ?
            <h3>No results</h3> :
            ''}
          {this.state.districtInfo.map((districtInfo, i) => {
            return <div key={'district-' + districtInfo.district.district}>
              <div className="font-bold mb-05 font-20">
                <div>District: {districtInfo.district.district}</div>
                <div>id: {districtInfo.district.id}</div>
              </div>
              <div className='pl-2 font-16'>
                <div className="font-bold mb-05">Admins:</div>
                <div className='pl-2'>
                  <Link
                    to={"/admin/admin-home/" + districtInfo.admin.id}
                    className="p-2 font-16 border br-4 w-100 inline-block mb-1"
                  >
                    <div className="font-bold mb-05">
                      District: {districtInfo.district.district}
                    </div>
                    <div>
                      Go to {districtInfo.admin.firstName + " " +
                        districtInfo.admin.lastName}'s page</div>
                  </Link>
                </div>
                <div className="font-bold mb-05">
                  Admins:
                </div>
                <div className='pl-2'>
                  {this.state.adminsObj.hasOwnProperty(districtInfo.district.id) ?
                    <div>
                      {this.state.adminsObj[districtInfo.district.id].map((admin, i) => {
                        return <div className='w-100 flex flex-v-center' key={'school-admin-' + admin.id}>
                          <Link to={"/admin/admin-home/" + admin.id}
                            className="p-2 font-16 border br-4 w-100 inline-block mb-1"
                          >
                            <div>
                              <Avatar
                                size="large"
                                className="inline-block mr-8 avatar-vlg"
                                style={{ backgroundColor: '#d3adf7' }}
                                containerClassName="font-30 mt-05 no-text-highlight"
                                avatarClassName="font-30 font-bold"
                                personClassName="font-40 font-bold mt-1"
                              >
                                <span className="font-bold font-20 font-black">{getInitials(admin)}</span>
                              </Avatar>
                              <span className="font-20 font-bold vertical-align-middle">
                                {admin.firstName + " " + admin.lastName}
                              </span>
                            </div>
                            <div>{admin.emailAddress}</div>
                            <div>{'id: ' + admin.id}</div>
                          </Link>
                          <div className='ml-2'>
                            <EditPersonnelNameForm
                              person={admin}
                              collectionType={ColType.admin}
                            />
                          </div>
                        </div>
                      })}
                    </div> :
                    <div>No school admins</div>
                  }
                </div>
                <div className="font-bold mb-05">
                  School Admins:
                </div>
                <div className='pl-2'>
                  {this.state.schoolAdminsObj.hasOwnProperty(districtInfo.district.id) ?
                    <div>
                      {this.state.schoolAdminsObj[districtInfo.district.id].map((schoolAdmin, i) => {
                        return <div className='w-100 flex flex-v-center' key={'school-admin-' + schoolAdmin.id}>
                          <Link to={"/school-admin/school-admin-home/" + schoolAdmin.id}
                            className="p-2 font-16 border br-4 w-100 inline-block mb-1"
                          >
                            <div>
                              <Avatar
                                size="large"
                                className="inline-block mr-8 avatar-vlg"
                                style={{ backgroundColor: '#d3adf7' }}
                                containerClassName="font-30 mt-05 no-text-highlight"
                                avatarClassName="font-30 font-bold"
                                personClassName="font-40 font-bold mt-1"
                              >
                                <span className="font-bold font-20 font-black">{getInitials(schoolAdmin)}</span>
                              </Avatar>
                              <span className="font-20 font-bold vertical-align-middle">
                                {schoolAdmin.firstName + " " + schoolAdmin.lastName}
                              </span>
                            </div>
                            <div>{schoolAdmin.emailAddress}</div>
                            <div>{'id: ' + schoolAdmin.id}</div>
                          </Link>
                          <div className='ml-2'>
                            <EditPersonnelNameForm
                              person={schoolAdmin}
                              collectionType={ColType.schoolAdmin}
                            />
                          </div>
                        </div>
                      })}
                    </div> :
                    <div>No school admins</div>
                  }
                </div>
                <div className="font-bold mb-05">
                  Instructional Facilitators:
                </div>
                <div className='pl-2'>
                  {this.state.instructionalFacilitatorsObj.hasOwnProperty(districtInfo.district.id) ?
                    <div>
                      {this.state.instructionalFacilitatorsObj[districtInfo.district.id].map((instructionalFacilitator, i) => {
                        return <div className='w-100 flex flex-v-center' key={'instructional-facilitator-' + instructionalFacilitator.id}>
                          <Link to={"/instructional-facilitator/instructional-facilitator-home/" + instructionalFacilitator.id}
                            className="p-2 font-16 border br-4 w-100 inline-block mb-1"
                          >
                            <div>
                              <Avatar
                                size="large"
                                className="inline-block mr-8 avatar-vlg"
                                style={{ backgroundColor: '#d3adf7' }}
                                containerClassName="font-30 mt-05 no-text-highlight"
                                avatarClassName="font-30 font-bold"
                                personClassName="font-40 font-bold mt-1"
                              >
                                <span className="font-bold font-20 font-black">{getInitials(instructionalFacilitator)}</span>
                              </Avatar>
                              <span className="font-20 font-bold vertical-align-middle">
                                {instructionalFacilitator.firstName + " " + instructionalFacilitator.lastName}
                              </span>
                            </div>
                            <div>{instructionalFacilitator.emailAddress}</div>
                            <div>{'id: ' + instructionalFacilitator.id}</div>
                          </Link>
                          <div className='ml-2'>
                            <EditPersonnelNameForm
                              person={instructionalFacilitator}
                              collectionType={ColType.instructionalFacilitator}
                            />
                          </div>
                        </div>
                      })}
                    </div> :
                    <div>No instructional facilitators</div>
                  }
                </div>

                <div className="font-bold mb-05">
                  Teachers:
                </div>
                <div className='pl-2'>
                  {this.state.teachersObj.hasOwnProperty(districtInfo.district.id) ?
                    <div>
                      {this.state.teachersObj[districtInfo.district.id].map((teacher, i) => {
                        return <div className='w-100 flex flex-v-center' key={'instructional-facilitator-' + teacher.id}>
                          <Link to={"/teacher/teacher-home/" + teacher.id}
                            className="p-2 font-16 border br-4 w-100 inline-block mb-1"
                            key={'instructional-facilitator-' + teacher.id}>
                            <div>
                              <Avatar
                                size="large"
                                className="inline-block mr-8 avatar-vlg"
                                style={{ backgroundColor: '#d3adf7' }}
                                containerClassName="font-30 mt-05 no-text-highlight"
                                avatarClassName="font-30 font-bold"
                                personClassName="font-40 font-bold mt-1"
                              >
                                <span className="font-bold font-20 font-black">{getInitials(teacher)}</span>
                              </Avatar>
                              <span className="font-20 font-bold vertical-align-middle">
                                {teacher.firstName + " " + teacher.lastName}
                              </span>
                            </div>
                            <div>{teacher.emailAddress}</div>
                            <div>{'id: ' + teacher.id}</div>
                          </Link>
                          <div className='ml-2'>
                            <EditPersonnelNameForm
                              person={teacher}
                              collectionType={ColType.teacher}
                            />
                          </div>
                        </div>
                      })}
                    </div> :
                    <div>No instructional facilitators</div>
                  }
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    )
  }
}

export default Form.create()(DistrictSearch)