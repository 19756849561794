import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import ColType from '.././Types'
import { Icon, Form, Input, Button, Checkbox, message } from 'antd'
import LockPacingGuideForm from './LockPacingGuideForm'
import PacingGuideBeingCreatedForm from './PacingGuideBeingCreatedForm'
import StateStandardYearForm from './StateStandardYearForm'
const FormItem = Form.Item


class AdminLockPacingGuide extends Component {
    state = {
        states: ['SC', 'KY']
    }

    componentDidMount() {

    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 25 },
                sm: { span: 9 },
            },
            wrapperCol: {
                xs: { span: 25 },
                sm: { span: 15 },
            },
        }

        return (
            <div>
                <SuperAdminHeader menuKey={'lock-pacing-guide'} />

                <div className='w-100 flex'>
                    <div className='w-50'>
                        <LockPacingGuideForm />
                        <div className='mt-4'>
                            <PacingGuideBeingCreatedForm />
                        </div>
                    </div>
                    <div className='mt-100 pr-4' style={{ width: '50%' }}>
                        <StateStandardYearForm />
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create()(AdminLockPacingGuide)