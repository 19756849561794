import React, { Component } from 'react'
import { Route } from 'react-router-dom'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
import { flattenDoc, getYearStart } from '../.././Util'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import { Layout } from 'antd'
import PacingGuideTeacherAssessments from './PacingGuideTeacherAssessments'
import PacingGuideTeacherAssessment from './PacingGuideTeacherAssessment'
//const { Content } = Layout
//var moment = require('moment')

const PacingGuideTeacherAssessmentsComponent = (props, baseProps, state) => {
    return <PacingGuideTeacherAssessments assessmentBaseProps={state} teacherBaseProps={baseProps.teacherBaseProps} {...props} />
}

const PacingGuideTeacherAssessmentComponent = (props, baseProps, state) => {
    return <PacingGuideTeacherAssessment assessmentBaseProps={state} teacherBaseProps={baseProps.teacherBaseProps} {...props} />
}

class PacingGuideTeacherAssessmentsBase extends Component {
    state = {
        assessments: [],
        assessmentsDict: {},
        deletedAssessments: [],
        deletedAssessmentsDict: {},
        retrieved: false,
        assessmentListener: null,
        deletedAssessmentListener: null,
        dataRetrieved: false
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData = () => {
        if (!this.props.teacherBaseProps.teacher) {
            return
        }
        if (this.state.retrieved) {
            return
        }

        this.setState({ retrieved: true })

        if (this.state.assessmentListener) {
            this.state.assessmentListener()
        }

        const yearStart = getYearStart();
        const assessmentListener = db.collection(ColType.cleverAssessments)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
            .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('status', '==', 'current')
            .where('timeStamp', '>=', yearStart)
            .orderBy('timeStamp', 'asc')
            .limit(400)
            .onSnapshot((querySnapshot) => {
                let assessments = []
                let assessmentsDict = {}
                querySnapshot.forEach((doc, idx) => {
                    let assessment = flattenDoc(doc)
                    try {
                        assessment.quiz.docs = JSON.parse(assessment.quiz.docs)
                    } catch (e) {
                        console.log('error parsing quiz docs', idx)
                    }
                    assessments.push(assessment)
                    assessmentsDict[doc.id] = assessment
                })

                //console.log('assessment listener', assessments)
                this.setState({
                    assessments: assessments,
                    assessmentsDict: assessmentsDict,
                    dataRetrieved: true
                })
            })

        const deletedAssessmentListener = db.collection(ColType.cleverAssessments)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
            .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('status', '==', 'deleted')
            .orderBy('timeStamp', 'asc')
            .limit(150)
            .onSnapshot((querySnapshot) => {
                let deletedAssessments = []
                let deletedAssessmentsDict = {}
                querySnapshot.forEach((doc, idx) => {
                    let assessment = flattenDoc(doc)
                    try {
                        assessment.quiz.docs = JSON.parse(assessment.quiz.docs)
                    } catch (e) {
                        console.log('error parsing quiz docs', idx)
                    }
                    deletedAssessments.push(assessment)
                    deletedAssessmentsDict[doc.id] = assessment
                })

                //console.log('assessment listener', deletedAssessments)
                this.setState({
                    deletedAssessments: deletedAssessments,
                    deletedAssessmentsDict: deletedAssessmentsDict,
                })
            })

        this.setState({
            assessmentListener: assessmentListener,
            deletedAssessmentListener: deletedAssessmentListener
        })
    }

    componentWillUnmount() {
        if (this.state.assessmentListener) {
            this.state.assessmentListener()
        }
        if (this.state.deletedAssessmentListener) {
            this.state.deletedAssessmentListener()
        }
    }

    render() {
        const { match: { url } } = this.props

        return (
            <div>
                <Route path={`${url}/all/*`}
                    render={props => PacingGuideTeacherAssessmentsComponent(props, this.props, this.state)} />
                <Route path={`${url}/quiz/*`}
                    render={props => PacingGuideTeacherAssessmentComponent(props, this.props, this.state)} />
            </div>
        )
    }
}

export default PacingGuideTeacherAssessmentsBase
