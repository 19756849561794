import React, { Component } from 'react';
import '../styles/GlobalStyle.css';
import ColType from '../Types';
import { db, firebase } from '../firebase/Firebase';
import { Form, Input, Button, Icon, Modal, notification, DatePicker } from 'antd';

const { TextArea } = Input;
const FormItem = Form.Item;

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    });
};

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    });
};

class ConsultationReportForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        formData: [
            {
                label: 'First name',
                fieldDecorator: 'firstName',
                placeholder: 'First name...',
                type: 'input',
                required: true
            },
            {
                label: 'Last name',
                fieldDecorator: 'lastName',
                placeholder: 'Last name...',
                type: 'input',
                required: true
            },
            {
                label: 'Description of how the professional learning plan was supported',
                fieldDecorator: 'description',
                placeholder: 'Description...',
                type: 'textarea',
                required: true
            }
        ]
    };

    showModal = () => {
        this.setState({ modalVisible: true });
    };

    handleOk = () => {
        this.setState({ modalVisible: false });
    };

    handleCancel = () => {
        this.setState({ modalVisible: false });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!(this.props.schoolId && this.props.districtId && this.props.personId)) {
            console.error('missing props in report form', this.props);
            return;
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ submitting: true });

                values.districtId = this.props.districtId;
                values.schoolId = this.props.schoolId;
                values.personId = this.props.personId;
                values.timeStamp = firebase.firestore.FieldValue.serverTimestamp();

                // Remove empty optional fields
                Object.keys(values).forEach(key => {
                    if (values[key] === undefined || values[key] === '') {
                        delete values[key];
                    }
                });

                db.collection(ColType.consultationReport)
                    .add(values)
                    .then((docRef) => {
                        console.log("Document written with ID: ", docRef.id);
                        successMessage('Report added successfully.');
                        this.setState({ submitting: false });
                        this.props.form.resetFields();
                        this.handleCancel();
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                        errorMessage('Error adding report, please try again.');
                        this.setState({ submitting: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemBlockLayout = {
            labelCol: { xs: { span: 24 }, sm: { span: 24 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
        };

        return (
            <div className='ml-auto'>
                <Button type="primary" className="lg-btn" onClick={this.showModal}>
                    <Icon type="plus" /> Add report
                </Button>
                <Modal
                    title="Add consultation report"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        {this.state.formData.map((item, index) => (
                            <FormItem {...formItemBlockLayout} label={item.label + ':'} key={index}>
                                {getFieldDecorator(item.fieldDecorator, {
                                    rules: [{ required: item.required, message: item.required ? 'Required.' : '' }],
                                })(
                                    item.type === 'input' ? (
                                        <Input size="large" placeholder={item.placeholder} />
                                    ) : item.type === 'textarea' ? (
                                        <TextArea rows={8} size="large" placeholder={item.placeholder} />
                                    ) : item.type === 'date' ? (
                                        <DatePicker size="large" style={{ width: '100%' }} />
                                    ) : null
                                )}
                            </FormItem>
                        ))}

                        <FormItem className="mb-0">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="lg-btn float-right"
                                disabled={this.state.submitting}
                            >
                                {this.state.submitting ?
                                    <span><Icon type="loading" className="mr-1" />Adding report...</span> :
                                    <span><Icon type="plus" /> Add report</span>}
                            </Button>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(ConsultationReportForm);