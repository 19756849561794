import React from 'react'


class MathText extends React.Component {
    state = {
        MathComponent: null
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            try {
                const MathJax = require('mathjax-react')
                this.setState({ MathComponent: MathJax.MathComponent })
            } catch (error) {
                console.error(`Dynamic import failed: ${error}`)
            }
        }
    }

    render() {
        const { MathComponent } = this.state

        let parts = []
        if (this.props.text && typeof this.props.text === 'string') {
            parts = this.props.text.split(/(\$[^$]*\$)/g)
        }

        return (
            <div className='inline-block'>
                {MathComponent ?
                    <div className='force-all-inline'>
                        {parts.map((part, i) => {
                            if (part.startsWith("$")) {
                                // This is a math part - render it inside a MathComponent
                                return <MathComponent key={i} tex={part.slice(1, -1)} />
                            } else {
                                // This is a regular text part - render it inside a span
                                return <span key={i}>{part}</span>
                            }
                        })}
                    </div>
                    :
                    <span>{this.props.text}</span>}
            </div>
        )
    }
}

export default MathText
