import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import ColType from '.././Types'
import { Icon, Form, Input, Button } from 'antd'
const FormItem = Form.Item

// This page can only be accessed by us, it is how you add admins that then can add other users.
class AdminSearch extends Component {
  state = {
    districtInfo: [],
  }

  componentDidMount() {

  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        db.collection(ColType.admin)
          .where('lastName', '==', values.lastName)
          .get()
          .then((querySnapshot) => {
            var admins = []
            querySnapshot.forEach((doc) => {
              var admin = flattenDoc(doc)
              admins.push(admin)
            })
            
            console.log("found admins", admins)

            var districtInfo = []

            admins.map((admin, i) => {
              console.log(admin.districtId)
              return db.collection(ColType.district)
                .doc(admin.districtId)
                .get()
                .then((doc) => {
                  console.log('doc', doc)
                  var district = flattenDoc(doc)
                  districtInfo.push({
                    district: district,
                    admin: admin,
                  })

                  console.log("found admin in district", admin)

                  this.setState({
                    districtInfo: districtInfo,
                  })
                })
            })
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 25 },
        sm: { span: 9 },
      },
      wrapperCol: {
        xs: { span: 25 },
        sm: { span: 15 },
      },
    }

    return (
      <div className="border br-4 p-3">
        <div className="font-24 font-bold mb-3">Search for an admin</div>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem {...formItemLayout} label="Admin's last name">
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: 'Please input the admin\'s last name.' }],
            })(
              <Input
                size={"large"}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="last name..." />
            )}
          </FormItem>
          <FormItem className="mb-0">
            <Button
              size={"large"}
              type="primary"
              htmlType="submit"
              className="login-form-button text-align-center w-100"
            >
              Search for an admin
            </Button>
          </FormItem>
        </Form>

        <div className="mt-3 pt-3 border-top">
          <h2>Search Results</h2>
          {this.state.districtInfo.length === 0 ?
          <h3>No results</h3> :
          ''}
          {this.state.districtInfo.map((districtInfo, i) => {
            console.log(districtInfo)
            return <Link 
              to={"/admin/admin-home/" + districtInfo.admin.id}
              className="p-2 font-16 border br-4 w-100 inline-block mb-1"
            >
              <div className="font-bold mb-05">
                District: {districtInfo.district.district}
              </div>
              <div>
                Go to {districtInfo.admin.firstName + " " + 
                districtInfo.admin.lastName}'s page</div>
            </Link>
          })}
        </div>
      </div>
    )
  }
}

export default Form.create()(AdminSearch)