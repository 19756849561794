import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import ColType from '.././Types'
import { Icon, Button, Input } from 'antd'


// This page can only be accessed by us, it is how you add admins that then can add other users.
class SuperAdminPacingGuideFeedback extends Component {
    state = {
        feedbacks: [],
        feedbackListener: null,
        pacingGuideFromId: '',
        pacingGuideToId: ''
    }

    componentDidMount() {
        let feedbackListener = db.collection(ColType.pacingGuideFeedback)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot(querySnapshot => {
                let feedbacks = []
                querySnapshot.forEach((doc) => {
                    let feedback = flattenDoc(doc)
                    feedback.content = JSON.parse(feedback.content)
                    feedbacks.push(feedback)
                })
                console.log(feedbacks)
                this.setState({
                    feedbacks: feedbacks
                })
            })

        this.setState({
            feedbackListener: feedbackListener
        })
    }

    componentWillUnmount() {
        if (this.state.feedbackListener) {
            this.state.feedbackListener()
        }
    }

    deleteFeedback = (feedbackId) => {
        db.collection(ColType.pacingGuideFeedback)
            .doc(feedbackId)
            .delete()
            .then(() => {
                console.log("Document successfully deleted!")
            })
            .catch((error) => {
                console.error("Error removing document: ", error)
            })
    }

    /*
    copyPacingGuides = async (performCopy) => {
        return
        console.log('see what copy will do', this.state.pacingGuideFromId, this.state.pacingGuideToId)
        if (!(this.state.pacingGuideFromId && this.state.pacingGuideToId)) {
            console.log('missing ids')
            return
        }

        console.log('from district id', this.state.pacingGuideFromId, 'to district id', this.state.pacingGuideToId)

        let fromDistrict = await db.collection(ColType.district).doc(this.state.pacingGuideFromId).get()
        let toDistrict = await db.collection(ColType.district).doc(this.state.pacingGuideToId).get()

        console.log(fromDistrict, toDistrict)
        if (!(fromDistrict.exists && toDistrict.exists)) {
            console.log('from and to districts dont exist')
            return
        }

        let fromDistrictDoc = flattenDoc(fromDistrict)
        let toDistrictDoc = flattenDoc(toDistrict)
        console.log('from district', fromDistrictDoc, 'to district', toDistrictDoc)
        //vPbvq5NGJK8pmJtId9Ek
        //521ce2f24da7037445000552

        db.collection(ColType.pacingGuides)
            .where('districtId', '==', this.state.pacingGuideFromId)
            .get()
            .then((querySnapshot) => {
                let pacingGuides = []
                querySnapshot.forEach((doc) => {
                    let pacingGuide = flattenDoc(doc)
                    pacingGuides.push(pacingGuide)
                })
                if (!performCopy) {
                    console.log('would move pacing guides', pacingGuides, pacingGuides.length)
                } else {
                    console.log('moving pacing guides', pacingGuides, pacingGuides.length)
                    pacingGuides.forEach(pacingGuide => {
                        const pacingGuideCopy = JSON.parse(JSON.stringify(pacingGuide))
                        delete pacingGuideCopy.id
                        pacingGuideCopy.districtId = this.state.pacingGuideToId
                        //console.log('pacing guide to be moved over', pacingGuideCopy)
                        db.collection(ColType.pacingGuides)
                            .add(pacingGuideCopy)
                            .then((docRef) => {
                                console.log("Document written with ID: ", docRef.id)
                            })
                            .catch((error) => {
                                console.error("Error adding document: ", error)
                            })
                    })
                }
            })
    }
    */

    render() {
        return (
            <div>
                <SuperAdminHeader menuKey={'pacing-guide-feedback'} />

                {/*
                <div className='p-2 ant-shadow'>
                    <h1>Copy pacing guide over</h1>
                    <Input size='large' type='text' placeholder='From district id' className='mr-2' onChange={(e) => {
                        console.log('from id change', e.target.value)
                        this.setState({
                            pacingGuideFromId: e.target.value
                        })
                    }} />
                    <Input size='large' placeholder='To district id' className='mr-2' onChange={(e) => {
                        console.log('to id change', e.target.value)
                        this.setState({
                            pacingGuideToId: e.target.value
                        })
                    }} />
                    <Button onClick={() => {
                        this.copyPacingGuides(false)
                    }}>
                        See what copy will do
                    </Button>
                    <Button onClick={() => {
                        this.copyPacingGuides(true)
                    }}>
                        Perform copy
                    </Button>
                </div>
                */}

                <div className="border br-4 p-4 w-1400 m-lr-auto mt-100 mb-100">
                    <h1>Pacing Guide Feedback (Doc is included, check console.log)</h1>

                    {this.state.feedbacks.length === 0 ?
                        <div className='font-20'>No feedbacks</div>
                        : ''}

                    {this.state.feedbacks.map((feedback, index) => {
                        return <div key={feedback.id} className='flex w-100 font-16 p-2 border-bottom'>
                            <div className='mr-2'>
                                <b>Standard category:</b> {feedback.content.standard.standardCategory}
                            </div>
                            <div className='mr-2'>
                                <b>Standard:</b> {feedback.content.standard.standard}
                            </div>
                            <div className='mr-2'>
                                <b>Resource name:</b> {feedback.content.resourceName}
                            </div>
                            <div className='mr-2'>
                                <b>Feedback:</b> {feedback.content.hasOwnProperty('feedback') ? feedback.content.feedback : '""'}
                            </div>
                            <div className='mr-2'>
                                <b>Person:</b> {feedback.content.hasOwnProperty('person') ? feedback.content.person.firstName + ' ' + feedback.content.person.lastName + ' ' + feedback.content.person.emailAddress : '""'}
                            </div>
                            <div className='mr-2'>
                                <b>Time:</b> {feedback.timeStamp && feedback.timeStamp.hasOwnProperty('seconds') ? new Date(feedback.timeStamp.seconds * 1000).toUTCString() : '""'}
                            </div>
                            <Button className='ml-auto' onClick={() => {
                                this.deleteFeedback(feedback.id)
                            }}>
                                <Icon type='delete' />
                            </Button>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

export default SuperAdminPacingGuideFeedback