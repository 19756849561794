import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
import '../../styles/GlobalStyle.css'
import {
    getQueryStringParam, cleverGetStudents, flattenDoc, FOLDER_CURRENT, changeIEPGoalsFolder, getAvatarColor, FOLDER_ARCHIVED, cleverReauth
} from '../.././Util'
import { Button, Icon, Radio, notification, Checkbox, Spin } from 'antd'
const RadioGroup = Radio.Group
//import AddStudentCSV from './AddStudentCSV'

class TeacherAddStudentsClever extends Component {
    state = {
        cleverResult: { error: false, status: 'initial', message: '' },
        cleverStudents: [],
        cleverStudentDict: {},
        completed: null,
        students: [],
        studentsToAdd: null,
        studentsToReturnToCaseload: null,
        studentsWhoMayBeCleverStudents: null,
        studentsWhoMayBeCleverStudentsBool: null,
        archive: false,
        linkingStudents: false,
    }

    componentDidMount() {
        // completed means that the user has logged in with Clever and
        // has been redirected back to this page.
        let completed = getQueryStringParam('completed')
        // archive means archive old caseload
        let archive = getQueryStringParam('archive')
        this.setState({
            completed: completed,
            archive: archive,
        }, () => {
            // coming from a clever login, initially automatically call addStudentsClever
            // when teacherBaseProps is ready
            if (completed) {
                let teacherBasePropsInterval = setInterval(() => {
                    if (!(this.props.teacherBaseProps && this.props.teacherBaseProps.teacher)) {
                        return
                    }
                    console.log('interval')
                    clearInterval(teacherBasePropsInterval)

                    this.addStudentsClever()
                }, 100)
            }
        })
    }

    triggerCleverReauth = () => {
        if (!(this.props.hasOwnProperty('reauthContinueURL') && this.props.reauthContinueURL)) {
            return
        }
        let url = this.props.reauthContinueURL;

        let params = {
            archive: this.state.archive,
            completed: true,
        };

        let query = Object.keys(params)
            .map(key => key + '=' + encodeURIComponent(params[key]))
            .join('&');

        let urlWithQueryString = url.includes('?') ? url + "&" + query : url + "?" + query;

        cleverReauth(urlWithQueryString)
    }

    addStudentsClever = () => {
        if (!(this.props.teacherBaseProps && this.props.teacherBaseProps.teacher)) {
            return
        }

        this.setState({
            cleverResult: { error: false, status: 'loading', message: '' }
        })

        // cleverReauth has been called, is authed with clever, can make clever api calls
        cleverGetStudents()
            .then((res) => {
                console.log(res)
                if (res.message !== 'success') {
                    this.setState({
                        cleverResult: { error: true, status: 'error', message: 'An error occurred. Please check to make sure that your students are in Clever.' }
                    })
                }
                else if (res.message === 'success') {
                    const cleverStudents = res.students
                    let cleverStudentsDict = {}
                    cleverStudents.map((cleverStudent) => {
                        cleverStudentsDict[cleverStudent.data.id] = cleverStudent
                        return false
                    })
                    this.setState({
                        cleverStudents: cleverStudents,
                        cleverResult: { error: false, status: 'success', message: 'Successfully retrieved students from Clever.' }
                    })

                    // see if all of the clever students exist
                    if (res.students.length > 0) {
                        db.collection(ColType.student)
                            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                            .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                            .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                            .limit(1000)
                            .get()
                            .then((querySnapshot) => {
                                let students = []
                                let studentCleverDict = {}
                                querySnapshot.forEach((doc) => {
                                    let student = flattenDoc(doc)
                                    students.push(student)
                                    if (student.hasOwnProperty('cleverId') &&
                                        student.cleverId !== '') {
                                        studentCleverDict[student.cleverId] = student
                                    }
                                })

                                this.setState({
                                    students: students,
                                })

                                console.log(students)
                                // students that need to be added to the caseload, don't currently exist
                                let studentsToAdd = {}
                                // students that do exist and have cleverIds that are archived/deleted and need to be
                                // returned to the caseload
                                let studentsToReturnToCaseload = []
                                // students that do exist but do not have cleverIds that may be the same student
                                // as the one in clever, should link them in this case
                                // key: potential cleverId of the Dot It student, value: existing Dot It student
                                let studentsWhoMayBeCleverStudents = {}
                                let studentsWhoMayBeCleverStudentsBool = {}
                                // after these two steps are done, the caseload is perfectly in sync with clever
                                cleverStudents.map((cleverStudent) => {
                                    const studentCleverId = cleverStudent.data.id
                                    if (!studentCleverDict.hasOwnProperty(studentCleverId)) {
                                        studentsToAdd[studentCleverId] = cleverStudent
                                        // see if an existing Dot It student without a cleverId is the same student
                                        // as this clever student, if so we shouldn't add them to coltype.students again
                                        // but should link them
                                        let studentWhoMayBeCleverStudent = this.findStudentWithName(students, cleverStudent.data.name.first, cleverStudent.data.name.last)
                                        if (studentWhoMayBeCleverStudent) {
                                            studentsWhoMayBeCleverStudents[studentCleverId] = studentWhoMayBeCleverStudent
                                            studentsWhoMayBeCleverStudentsBool[studentCleverId] = true
                                        }
                                    } else {
                                        let existingStudent = studentCleverDict[studentCleverId]
                                        if (existingStudent.folder !== FOLDER_CURRENT) {
                                            studentsToReturnToCaseload.push(existingStudent)
                                        }
                                    }
                                    return false
                                })

                                this.setState({
                                    cleverStudents: cleverStudents,
                                    cleverStudentsDict: cleverStudentsDict,
                                    studentCleverDict: studentCleverDict,
                                    studentsToAdd: studentsToAdd,
                                    studentsToReturnToCaseload: studentsToReturnToCaseload,
                                    studentsWhoMayBeCleverStudents: studentsWhoMayBeCleverStudents,
                                    studentsWhoMayBeCleverStudentsBool: studentsWhoMayBeCleverStudentsBool,
                                }, () => {
                                    // no additional work needs to be done, confirm
                                    if (Object.keys(studentsWhoMayBeCleverStudents).length === 0) {
                                        this.addStudentsCleverConfirm()
                                    }
                                    // need an additional step of linking students before confirming 
                                    else {

                                    }
                                })
                            })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    cleverResult: { error: true, status: 'initial', message: err.message },
                })
            })

    }

    addStudentsCleverConfirm = () => {
        if (!(this.props.teacherBaseProps && this.props.teacherBaseProps.teacher)) {
            return
        }

        let promises = []
        Object.keys(this.state.studentsToAdd).map((cleverId) => {
            let student = this.state.studentsToAdd[cleverId]
            promises.push(new Promise((resolve, reject) => {
                let values = {}
                // store all cleverData in the student object
                values.cleverData = student.data
                values.cleverId = student.data.id
                // get values from clever
                values['race'] = 'White'
                values['ethnicity'] = 'Not Hispanic or Latino'

                values.mayHaveTroubleWith = 'information'
                values.studentInterest = ''
                values.grade = 'K'
                if (student.hasOwnProperty('roles') &&
                    student.roles &&
                    student.roles.hasOwnProperty('student') &&
                    student.roles.student &&
                    student.roles.student.hasOwnProperty('grade') &&
                    student.roles.student.grade) {
                    values.grade = student.roles.student.grade
                }
                values.gender = 'Male'

                values.firstName = student.data.name.first
                values.lastName = student.data.name.last
                values.teacherId = this.props.teacherBaseProps.teacher.id
                values.schoolId = this.props.teacherBaseProps.teacher.schoolId
                values.districtId = this.props.teacherBaseProps.teacher.districtId
                // grade may not exist, this is legacy anyways
                values.avatarColor = getAvatarColor('K')
                values.disability = "Autism"
                values.timeStamp = firebase.firestore.FieldValue.serverTimestamp()
                values.folder = FOLDER_CURRENT
                values.mayHaveTroubleWithDescription = this.getMayHaveTroubleWithDescription(values.mayHaveTroubleWith)

                db.collection(ColType.student)
                    .add(values)
                    .then((docRef) => {
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    })
            }))
            return false
        })

        this.state.studentsToReturnToCaseload.map((student) => {
            // should never happen, but just in case
            if (student.folder === FOLDER_CURRENT) {
                return false
            }
            promises.push(new Promise((resolve, reject) => {
                db.collection(ColType.iep)
                    .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
                    .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
                    .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
                    .where('folder', '==', student.folder)
                    .orderBy('timeStamp', 'desc')
                    .get()
                    .then((querySnapshot) => {
                        var IEPGoals = []
                        querySnapshot.forEach((doc) => {
                            let iep = flattenDoc(doc)
                            IEPGoals.push(iep)
                        })

                        changeIEPGoalsFolder(student,
                            IEPGoals,
                            IEPGoals,
                            student.folder,
                            FOLDER_CURRENT,
                            false)
                            .then(() => {
                                resolve()
                            })
                            .catch((err) => {
                                reject(err)
                            })
                    })
                    .catch((err) => {
                        reject(err)
                    })
            }))
            return false
        })

        Promise.all(promises)
            .then(() => {
                if (this.state.archive &&
                    this.props.teacherBaseProps &&
                    this.props.teacherBaseProps.students &&
                    this.props.teacherBaseProps.studentIEPDict) {
                    let archivePromises = []
                    // any student who has no cleverId, or whose cleverId is not
                    // in the cleverStudentsDict needs to be archived
                    this.props.teacherBaseProps.students.map((student, idx) => {
                        // only should archive students who are in the current folder
                        // should always be true here but just checking anyways
                        if (student.folder !== FOLDER_CURRENT) {
                            return false
                        }

                        var shouldArchive = false
                        if (!student.hasOwnProperty('cleverId')) {
                            shouldArchive = true
                        } else if (!this.state.cleverStudentsDict.hasOwnProperty(student.cleverId)) {
                            shouldArchive = true
                        }

                        console.log('here', student.firstName, shouldArchive)

                        if (shouldArchive) {
                            let IEPGoals = []
                            if (this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(student.id)) {
                                IEPGoals = this.props.teacherBaseProps.studentIEPDict[student.id]
                            }

                            archivePromises.push(new Promise((resolve, reject) => {
                                changeIEPGoalsFolder(student,
                                    IEPGoals,
                                    IEPGoals,
                                    student.folder,
                                    FOLDER_ARCHIVED,
                                    false)
                                    .then(() => {
                                        resolve()
                                    })
                                    .catch((err) => {
                                        reject(err)
                                    })
                            }))
                        }

                        return false
                    })

                    Promise.all(archivePromises)
                        .then(() => {
                            notification.open({
                                type: 'success',
                                duration: 4,
                                message: 'Students synced with Clever successfully',
                                placement: 'bottomRight'
                            })

                            if (this.props.teacherBaseProps &&
                                this.props.teacherBaseProps.teacher) {
                                this.props.history.push(
                                    {
                                        pathname: '/teacher/home/' + this.props.teacherBaseProps.teacher.id
                                    }
                                )
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    // all done
                    notification.open({
                        type: 'success',
                        duration: 4,
                        message: 'Students synced with Clever successfully',
                        placement: 'bottomRight'
                    })

                    if (this.props.teacherBaseProps &&
                        this.props.teacherBaseProps.teacher) {
                        this.props.history.push(
                            {
                                pathname: '/teacher/home/' + this.props.teacherBaseProps.teacher.id
                            }
                        )
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getMayHaveTroubleWithDescription = (value) => {
        if (value === 'information') {
            return "Recalling facts and remembering information."
        }
        else if (value === 'vocabulary') {
            return "Lack of background knowledge and " +
                "weak vocabulary."
        }
        else if (value === 'problemSolving') {
            return "Independent problem solving."
        }
        else if (value === 'efficiency') {
            return "Working quickly and efficiently to " +
                "complete assignments."
        }
        else if (value === 'ell') {
            return "Difficulty comprehending lessons due to " +
                "language acquisition."
        }
        else {
            return "Not specified."
        }
    }

    findStudentWithName = (students, firstName, lastName) => {
        let student = null
        for (let s of students) {
            if (s.firstName.toLowerCase() === firstName.toLowerCase() &&
                s.lastName.toLowerCase() === lastName.toLowerCase()) {
                student = s
                break
            }
        }
        if (!student) {
            // search for first char of last name if full match wasn't found (i.e Bobby P)
            for (let s of students) {
                if (s.lastName.length === 0 || lastName.length === 0) {
                    continue
                }
                if (s.firstName.toLowerCase() === firstName.toLowerCase() &&
                    s.lastName.toLowerCase()[0] === lastName.toLowerCase()[0]) {
                    student = s
                    break
                }
            }
        }
        return student
    }

    handleArchiveCheckbox = (e) => {
        this.setState({
            archive: e.target.checked,
        })
    }

    linkStudentsAndConfirm = () => {
        this.setState({
            linkingStudents: true,
        })

        let studentsToAdd = this.state.studentsToAdd
        let promises = []
        Object.keys(this.state.studentsWhoMayBeCleverStudentsBool).map((cleverId, idx) => {
            const isSameStudent = this.state.studentsWhoMayBeCleverStudentsBool[cleverId]
            if (isSameStudent) {
                // remove from studentsToAdd
                delete studentsToAdd[cleverId]
                let student = this.state.studentsWhoMayBeCleverStudents[cleverId]
                // update the dot it student with the cleverId to link them
                promises.push(new Promise((resolve, reject) => {
                    db.collection(ColType.student)
                        .doc(student.id)
                        .update({
                            cleverId: cleverId,
                        })
                        .then(() => {
                            resolve()
                        })
                        .catch((err) => {
                            reject(err)
                        })
                }))
            }
            return false
        })

        this.setState({
            studentsToAdd: studentsToAdd,
        }, () => {
            Promise.all(promises)
                .then(() => {
                    // push through changes with modified studentsToAdd when all
                    // link promises finish
                    this.addStudentsCleverConfirm()
                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        linkingStudents: false,
                    })
                })
        })
    }

    render() {
        return (
            <div>
                <div className='bkg-purple br-15 p-4 mb-2'>
                    <div className='flex flex-v-center w-100'>
                        <div>
                            <div className="font-bold mb-0 font-40 font-fff mr-2">
                                <Icon type='sync' className='mr-2' />
                                <span>Sync students from Clever</span>
                            </div>
                        </div>
                        <img src='/add-buttons/add-student.png' alt='add-student' width={220} className='ml-auto' />
                    </div>
                </div>
                <div>
                    {!this.state.completed && (this.state.cleverResult.status === 'initial' ||
                        this.state.cleverResult.status === 'loading') ?
                        <div className='font-20'>
                            <div className='font-bold'>Step 1 (Optional):</div>
                            <div className='mb-1'>
                                <div>Would you like to archive any students in Dot It who are
                                    not in your Clever caseload?</div>
                                <Checkbox size='large' onChange={this.handleArchiveCheckbox}
                                    checked={this.state.archive}>
                                    <span className='ml-1 font-20 font-black va-minus-2'>
                                        Yes, archive students that are not in Clever.
                                    </span>
                                </Checkbox>
                            </div>
                            <div className='font-bold mt-2'>Step 2:</div>
                            <div className='mb-1'>Log in with Clever</div>
                            <Button onClick={this.triggerCleverReauth} type='primary' size='large'
                                className='transparent-btn'
                                disabled={this.state.cleverResult.status === 'loading'}>
                                <img src="https://files.readme.io/efb3f5d-LogInWithClever2x.png" alt="Clever log in button" class="" align="" caption="" height="auto" width="auto" loading="lazy" />
                            </Button>
                        </div>
                        : ''}
                    {(!this.state.studentsWhoMayBeCleverStudents ||
                        (this.state.studentsWhoMayBeCleverStudents &&
                            Object.keys(this.state.studentsWhoMayBeCleverStudents).length === 0)) &&
                        this.state.completed ?
                        <div>
                            {this.state.cleverResult.error ?
                                <div>
                                    <div className='text-left font-20 p-3 br-4 bg-light-red mb-2'>
                                        {this.state.cleverResult.message}
                                    </div>

                                    <Button onClick={this.addStudentsClever} type='primary' size='large'
                                        className='ant-btn ant-btn-primary lg-btn'
                                        disabled={this.state.cleverResult.status === 'loading'}>
                                        <Icon type='sync' />
                                        <span>Try again</span>
                                    </Button>
                                </div>
                                :
                                <div className='text-center'>
                                    <div className='font-20 mb-2 mt-1 flex flex-v-center flex-h-center w-100'>
                                        <Icon type='sync' className='font-24 mr-1' />
                                        <div>Syncing students from Clever...</div>
                                    </div>
                                    <Spin size="large" className="ant-spin-vlg" />
                                </div>
                            }
                        </div>
                        : ''}
                    {this.state.studentsWhoMayBeCleverStudents &&
                        Object.keys(this.state.studentsWhoMayBeCleverStudents).length > 0 ?
                        <div>
                            <div className='font-20 mb-1'>These Dot It students may be the same as in your Clever account. If so, click the button to link them.</div>
                            <table className='bordered-table font-20'>
                                <thead>
                                    <tr>
                                        <th>Dot It Student</th>
                                        <th>Clever Student</th>
                                        <th>Are these the same students?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.studentsWhoMayBeCleverStudents &&
                                        Object.keys(this.state.studentsWhoMayBeCleverStudents).map((cleverId, idx) => {
                                            const student = this.state.studentsWhoMayBeCleverStudents[cleverId]
                                            const cleverStudent = this.state.cleverStudentsDict[cleverId]
                                            return <tr key={'mayBeCleverStudent' + idx}>
                                                <td>
                                                    {student.firstName} {student.lastName}
                                                </td>
                                                <td>
                                                    {cleverStudent.data.name.first} {cleverStudent.data.name.last}
                                                </td>
                                                <td>
                                                    <RadioGroup size='large' onChange={(e) => {
                                                        const value = e.target.value
                                                        let studentsWhoMayBeCleverStudentsBool = this.state.studentsWhoMayBeCleverStudentsBool
                                                        studentsWhoMayBeCleverStudentsBool[cleverId] = value
                                                        this.setState({
                                                            studentsWhoMayBeCleverStudentsBool: studentsWhoMayBeCleverStudentsBool,
                                                        })
                                                    }}
                                                        value={this.state.studentsWhoMayBeCleverStudentsBool[cleverId]}>
                                                        <Radio value={true}>Yes</Radio>
                                                        <Radio value={false}>No</Radio>
                                                    </RadioGroup>
                                                </td>
                                            </tr>
                                        })}
                                </tbody>
                            </table>
                            <div className='w-100 flex flex-v-center'>
                                <Button className='ant-btn ant-btn-primary lg-btn mt-2 ml-auto'
                                    disabled={this.state.linkingStudents}
                                    onClick={this.linkStudentsAndConfirm}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        : ''}
                </div>
            </div>
        )
    }
}

export default TeacherAddStudentsClever