import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
//import { getStandardNumber } from '../Util'
//import { Empty } from 'antd'

class StandardNumber extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        // used to make a call to getStandardNumber
        let standardNumber = ''

        return (
            <span>
                {standardNumber !== '' ?
                    <span
                        className={this.props.hasOwnProperty('containerClassName') ? this.props.containerClassName : ''}
                        style={this.props.hasOwnProperty('containerStyle') ? this.props.containerStyle : {}}
                    >
                        <span className={this.props.className ? this.props.className : 'mr-1'}>
                            {standardNumber}
                        </span>
                    </span> :
                    ''}
            </span>
        )
    }
}

export default StandardNumber