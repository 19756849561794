import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
//import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Form, Input, Button, Icon, Modal, notification } from 'antd'
const FormItem = Form.Item
const TextArea = Input.TextArea

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class SchoolProgressReportUpdateForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        formData: [
            {
                label: 'Title',
                fieldDecorator: 'reportTitle',
                placeholder: '',
            },
            {
                label: 'Description',
                fieldDecorator: 'reportDescription',
                placeholder: '',
            },
            {
                label: 'Reflection',
                fieldDecorator: 'reportReflection',
                placeholder: '',
            },
            {
                label: "Administration will monitor and support students' progress by",
                fieldDecorator: 'adminProgress',
                placeholder: '',
            },
            {
                label: "Coaches will facilitate students' progress by",
                fieldDecorator: 'coachesCaptureProgress',
                placeholder: '',
            },
            {
                label: "Teachers will capture students' progress by",
                fieldDecorator: 'teachersAssessProgress',
                placeholder: '',
            },
        ]
    }

    componentDidMount() {
        if (this.props.report) {
            let formObj = {}
            for (let formVal of this.state.formData) {
                if (!this.props.report.hasOwnProperty(formVal.fieldDecorator)) {
                    continue
                } else {
                    formObj[formVal.fieldDecorator] = this.props.report[formVal.fieldDecorator]
                }
            }
            this.props.form.setFieldsValue(formObj)
        }
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleOk = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!(this.props.schoolId && this.props.districtId && this.props.report)) {
            console.error('missing props', this.props)
            return
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values)

                this.setState({
                    submitting: true,
                })

                //console.log(values)

                db.collection(ColType.schoolProgressReport)
                    .doc(this.props.report.id)
                    .update(values)
                    .then(() => {
                        successMessage('Report edited successfully.')
                        this.setState({
                            submitting: false,
                        })
                        this.handleCancel()
                    })
                    .catch((error) => {
                        console.error("Error updating document: ", error)
                        errorMessage('Error editing report, please try again.')
                        this.setState({
                            submitting: false,
                        })
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Button type="default" className="lg-btn mr-1" onClick={this.showModal}>
                    <Icon type="edit" /> Edit
                </Button>
                <Modal
                    title="Edit progress report"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        {this.state.formData.map((item, index) => {
                            return <FormItem {...formItemBlockLayout} label={item.label + '::'}>
                                {getFieldDecorator(item.fieldDecorator, {
                                    rules: [{ required: true, message: 'Required.' }],
                                })(
                                    <TextArea rows={4} size={"large"} placeholder={item.placeholder} />
                                )}
                            </FormItem>
                        })}

                        <FormItem className="mb-0">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="lg-btn float-right"
                            >
                                {this.state.submitting ?
                                    <span><Icon type="loading" className="mr-1" /> Editing...</span> :
                                    <span><Icon type="edit" /> Edit</span>}
                            </Button>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(SchoolProgressReportUpdateForm)