import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
//import PersonHeader from '../../login/PersonHeader'
//import MTSSLessonTemplate from './MTSSLessonTemplate'
import CustomFooter from '../../login/CustomFooter'
import UIChart from './UIChart'
import ProgressMonitoringTest from './ProgressMonitoringTest'
import { flattenDoc, getIDFromURL, isReadOnlyTeacherId } from '../../Util'
import ColType from '../../Types'
import { Icon, Button, notification, Modal } from 'antd'
//var moment = require('moment')

const successMessage = (description) => {
  notification.open({
    type: 'success',
    message: description,
    placement: 'bottomRight'
  })
}

const errorMessage = (description) => {
  notification.open({
    type: 'error',
    message: description,
    placement: 'bottomRight'
  })
}

let progressMonitoringContainerRef = null

class ProgressMonitoring extends Component {
  state = {
    measurementListener: null,
    teacherId: '',
    studentId: '',
    iepId: '',
    teacher: null,
    student: null,
    profileMode: 1,
    IEPMeasurements: {},
    measurements: [],
    openKeys: [],
    selectedKeys: [],
    componentMounted: false,
    loadingNewData: false,
    chartHasResizedAtLeastOnce: false,
    progressMonitoringContainerWidth: 500,
    newMeasurement: "",
    activeKey: '1',
    currentMode: 'current',
    visible: false,
  }

  componentWillReceiveProps(nextProps) {
    // only for initial page load
    // iepId must be null so measurement additions don't
    // trigger a new listener call.
    if (nextProps.teacherBaseProps.studentDict &&
      nextProps.teacherBaseProps.IEPGoals &&
      nextProps.teacherBaseProps.IEPDict &&
      nextProps.teacherBaseProps.currentTimeStamp) {
      var currentIEPId = this.state.iepId
      var teacherId = getIDFromURL(window.location)
      var studentId = nextProps.studentId
      var iepId = nextProps.IEPId
      var isArchived = nextProps.teacherBaseProps.IEPDict.hasOwnProperty(iepId) &&
        nextProps.teacherBaseProps.IEPDict[iepId].hasOwnProperty('isArchived') &&
        nextProps.teacherBaseProps.IEPDict[iepId].isArchived ?
        true :
        false

      this.setState({
        teacherId: teacherId,
        studentId: studentId,
        iepId: iepId,
        currentMode: !isArchived ? 'current' : 'archived',
      }, () => {
        // only resubscribe measurement listeners if the iep changed
        if (iepId !== currentIEPId) {
          this.subscribeMeasurementListener()
        }
      })
    }
  }

  // Do fetch here
  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = this.props.studentId
    var iepId = this.props.IEPId

    window.addEventListener("resize", this.resize)

    if (iepId &&
      this.props.teacherBaseProps.studentDict &&
      this.props.teacherBaseProps.IEPGoals &&
      this.props.teacherBaseProps.IEPDict &&
      this.props.teacherBaseProps.currentTimeStamp) {

      var isArchived = (this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId) &&
        this.props.teacherBaseProps.studentDict[studentId].hasOwnProperty('isArchived') &&
        this.props.teacherBaseProps.studentDict[studentId].isArchived) ||
        (this.props.teacherBaseProps.IEPDict.hasOwnProperty(iepId) &&
          this.props.teacherBaseProps.IEPDict[iepId].hasOwnProperty('isArchived') &&
          this.props.teacherBaseProps.IEPDict[iepId].isArchived) ?
        true :
        false

      this.setState({
        teacherId: teacherId,
        studentId: studentId,
        iepId: iepId,
        currentMode: !isArchived ? 'current' : 'archived',
      }, () => {
        this.subscribeMeasurementListener()
      })
    } else {
      this.setState({
        teacherId: teacherId,
        studentId: studentId,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
    this.unsubscribeListeners()
  }

  resize = () => {
    if (progressMonitoringContainerRef) {
      this.setState({
        progressMonitoringContainerWidth: progressMonitoringContainerRef.clientWidth,
        chartHasResizedAtLeastOnce: true,
      })
    } else {
      this.setState({
        progressMonitoringContainerWidth: 500,
        chartHasResizedAtLeastOnce: true,
      })
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  subscribeMeasurementListener() {
    this.setState({
      loadingNewData: true
    })
    this.unsubscribeListeners()
    var studentId = this.state.studentId
    var iepId = this.state.iepId
    //console.log(this.props.teacherBaseProps.IEPGoals)
    if (this.props.teacherBaseProps.IEPGoals.length === 0 || !studentId || !iepId) {
      this.setState({
        student: null,
        iep: null,
        componentMounted: true,
      })
      return
    }

    var student = this.props.teacherBaseProps.studentDict[studentId]
    var iep = this.props.teacherBaseProps.IEPDict[iepId]

    this.setState({
      openKeys: [student.grade, student.id],
      selectedKeys: [iepId],
      studentId: studentId,
      iepId: iepId,
      student: student,
      iep: iep,
      measurementListener: null,
    }, () => {
      if (isReadOnlyTeacherId(this.state.teacherId)) {
        var measurements = []
        if (this.props.teacherBaseProps.readOnlyIEPMeasurementDict &&
          this.props.teacherBaseProps.readOnlyIEPMeasurementDict.hasOwnProperty(iepId)) {
          measurements = this.props.teacherBaseProps.readOnlyIEPMeasurementDict[iepId]
        }
        this.setState({
          measurements: [],
        }, () => {
          this.setState({
            measurements: measurements,
            loadingNewData: false,
          })
        })
      } else {
        var measurementListener = db.collection(ColType.measurements)
          .where('teacherId', '==', this.state.teacherId)
          //.where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
          //.where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
          .where('iepId', '==', iepId)
          .orderBy('timeStamp')
          .onSnapshot((querySnapshot) => {
            var measurements = []
            querySnapshot.forEach((doc) => {
              measurements.push(flattenDoc(doc))
            })

            // add to read only data so that the read only display can use the data
            if (this.props.teacherBaseProps.addToReadOnlyIEPMeasurementDictFunc) {
              this.props.teacherBaseProps.addToReadOnlyIEPMeasurementDictFunc(iepId, measurements)
            }

            // need to do this to clear old keys on the nivo chart.
            // set empty measurement first, then new ones.
            this.setState({
              measurements: [],
            }, () => {
              this.setState({
                measurements: measurements,
                loadingNewData: false,
              })
            })
          })

        this.setState({
          measurementListener: measurementListener,
        })
      }
    })
  }

  unsubscribeListeners() {
    if (this.state.measurementListener) {
      this.state.measurementListener()
    }
  }

  onOpenChange = (openKeys) => {
    console.log(openKeys)

    this.setState({
      openKeys: openKeys,
    })
  }

  menuClick = ({ item, key, keyPath }) => {
    console.log({ item, key, keyPath })
    if (keyPath.length < 2) return

    this.setState({
      iepId: keyPath[0],
      studentId: keyPath[1],
      selectedKeys: [key],
      loadingNewData: true,
    }, () => {
      this.subscribeMeasurementListener()
    })
  }

  tabChange = (activeKey) => {
    this.setState({
      activeKey: activeKey,
    })
  }

  setChartKey = () => {
    this.setState({
      activeKey: '1',
    })
  }

  archiveGoal = () => {
    var iep = this.state.iep.iep
    iep.isArchived = true
    console.log('archived', iep, iep.id)

    db.collection(ColType.iep)
      .doc(iep.id)
      .update(iep)
      .then(() => {
        successMessage("Goal completed successfully.")
        this.setState({
          iep: null,
          activeKey: '1',
        })
      })
      .catch((error) => {
        errorMessage("Goal could not be archived. Please try again or contact support.")
      })
  }

  unArchiveGoal = () => {
    var iep = this.state.iep.iep
    iep.isArchived = false
    console.log('unArchived', iep, iep.id)

    db.collection(ColType.iep)
      .doc(iep.id)
      .update(iep)
      .then(() => {
        successMessage("Goal added back to current goals.")
        this.setState({
          iep: null,
          activeKey: '1',
        })
      })
      .catch((error) => {
        errorMessage("Goal could not be be added back to current goals. " +
          "Please try again or contact support.")
      })
  }

  modeChange = (e) => {
    console.log('Radio change', e)
    this.setState({
      currentMode: e.target.value,
      iep: null,
      activeKey: '1',
      openKeys: [],
      selectedKeys: [],
    })
  }

  render() {
    let chartWidth = 500
    if (this.state.chartHasResizedAtLeastOnce) {
      chartWidth = this.state.progressMonitoringContainerWidth
    } else if (progressMonitoringContainerRef) {
      chartWidth = progressMonitoringContainerRef.clientWidth
    }
    return (
      <div>
        <div>
          {this.props.teacherBaseProps.teacher ?
            <div>

              <div>
                <div>
                  {this.state.student && this.state.iep ?
                    <div className="mt-3">
                      {/*<div>
                      <div 
                                className={"font-16 p-2 font-fff inline-block " +
                              "font-bold br-4 " + 
                              ((this.state.iep.iep.totalMeasurements === 0 || 
                                (this.state.iep.iep.totalMeasurements > 0 && 
                                  this.state.iep.iep.onTrack)) ? 'background-green' : 
                              'background-red')}>
                                {(this.state.iep.iep.totalMeasurements === 0 || 
                                (this.state.iep.iep.totalMeasurements > 0 && 
                                  this.state.iep.iep.onTrack)) ? "Goal is on Track" : 
                                "Goal is not on Track"}
                              </div>
                                </div>*/}



                      {this.state.iep && this.state.measurements ?
                        <div>
                          <Modal
                            title="Add measurement"
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            width={550}
                            footer={null}
                          >
                            <div>
                              <ProgressMonitoringTest
                                key={this.state.iep.iep.id}
                                teacher={this.props.teacherBaseProps.teacher}
                                school={this.props.teacherBaseProps.school}
                                iep={this.state.iep}
                                student={this.state.student}
                                measurements={this.state.measurements}
                                setChartKey={this.setChartKey}
                                onCompletion={this.hideModal}
                                onCancel={this.hideModal}
                              />
                            </div>
                          </Modal>

                          <div className="background-fff br-4">
                            <div>

                              <div ref={(node) => {
                                progressMonitoringContainerRef = node
                              }}>

                                <div>
                                  <div>
                                  </div>
                                  <div>
                                    <div className="flex mb-3">
                                      <div>
                                        <h2 className="font-bold mb-0 font-24">Goal progress summary</h2>
                                        <div className="font-20">
                                          <Icon type="info-circle-o"
                                            className="mr-1 text-muted"
                                          />
                                          <span className="text-muted">
                                            {'Click a point on the chart to delete a score.'}
                                          </span>
                                        </div>
                                      </div>
                                      <Button
                                        onClick={this.showModal}
                                        disabled={isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)}
                                        size={"large"}
                                        className="ml-auto mr-1">
                                        <Icon type="plus" className="font-bold" />
                                        Add measurement
                                      </Button>

                                    </div>
                                    <UIChart
                                      containerWidth={chartWidth}
                                      allowMeasurementAdding={!isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)}
                                      teacher={this.props.teacherBaseProps.teacher}
                                      school={this.props.teacherBaseProps.school}
                                      iep={this.state.iep}
                                      student={this.state.student}
                                      measurements={this.state.measurements}
                                      loadingNewData={this.state.loadingNewData}
                                    />
                                  </div>

                                </div>



                              </div>

                            </div>

                          </div>
                          <div>

                          </div>
                        </div>
                        : ''}
                    </div>
                    :
                    ''
                  }
                </div>
              </div>
              {this.state.componentMounted ? <CustomFooter /> : ''}

            </div>
            : ''}
        </div>
      </div>
    )
  }
}

export default ProgressMonitoring
