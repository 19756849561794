import React, { Component } from 'react'
import { Route } from 'react-router-dom'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '../Util'
import ColType from '../Types'
import WorkshopObservationTool from './WorkshopObersvationTool'
import WorkshopObservationToolForm from './WorkshopObservationToolForm'
import { Col } from 'antd'

const WorkshopObservationToolComponent = (props, passedProps, state) => {
    return <WorkshopObservationTool workshopObservationBaseProps={state} {...passedProps} {...props} />
}

const WorkshopObservationToolFormComponent = (props, passedProps, state) => {
    return <WorkshopObservationToolForm workshopObservationBaseProps={state} {...passedProps} {...props} />
}

class WorkshopObservationToolBase extends Component {
    state = {
        workshopObservations: null,
        workshopListener: null,
        creatingWorkshopObservation: false,
        schools: null,
        schoolsDict: null,
        dataRetrieved: false
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    hasNecessaryProps = () => {
        return this.props.districtId && this.props.schoolId && this.props.authorId && this.props.authorType
    }

    getData = () => {
        if (!this.hasNecessaryProps()) {
            return
        }
        if (this.state.dataRetrieved) {
            return
        }

        this.setState({
            dataRetrieved: true
        })

        db.collection(ColType.school)
            .where('districtId', '==', this.props.districtId)
            .get()
            .then(querySnapshot => {
                var schools = []
                var schoolsDict = {}
                var schoolTypes = {
                    preschool: [],
                    elementarySchool: [],
                    k8School: [],
                    middleSchool: [],
                    highSchool: [],
                }

                querySnapshot.forEach((doc) => {
                    var school = flattenDoc(doc)
                    schools.push(school)
                    schoolsDict[school.id] = school
                    if (!schoolTypes.hasOwnProperty(school.schoolType)) {
                        schoolTypes[school.schoolType] = []
                    }
                    schoolTypes[school.schoolType].push(school)
                })

                schools.sort((a, b) => {
                    if (a.hasOwnProperty('schoolType') &&
                        b.hasOwnProperty('schoolType')) {
                        return this.getSchoolTypeSortWeight(a.schoolType) - this.getSchoolTypeSortWeight(b.schoolType)
                    }

                    return 0
                })

                this.setState({
                    schools: schools,
                    schoolsDict: schoolsDict,
                    schoolTypes: schoolTypes,
                })
            })

        // just to be safe
        if (this.state.workshopListener) {
            this.state.workshopListener()
        }

        let workshopListener = db.collection(ColType.workshopObservationTool)
            .where('districtId', '==', this.props.districtId)
            .where('schoolId', '==', this.props.schoolId)
            .where('authorId', '==', this.props.authorId)
            .orderBy('lastSave', 'desc')
            .onSnapshot(snapshot => {
                let workshopObservations = []
                let workshopObservationsDict = {}
                snapshot.forEach(doc => {
                    const workshopObservation = flattenDoc(doc)
                    workshopObservations.push(workshopObservation)
                    workshopObservationsDict[workshopObservation.id] = workshopObservation
                })
                this.setState({
                    workshopObservations: workshopObservations,
                    workshopObservationsDict: workshopObservationsDict
                })
            })

        this.setState({
            workshopListener: workshopListener
        })

    }

    componentWillUnmount() {
        if (this.state.workshopListener) {
            this.state.workshopListener()
        }
    }

    getSchoolTypeSortWeight = (schoolType) => {
        if (!schoolType) {
            return -1
        }
        if (schoolType === 'elementarySchool') {
            return 0
        } else if (schoolType === 'k8School') {
            return 1
        } else if (schoolType === 'middleSchool') {
            return 2
        } else if (schoolType === 'highSchool') {
            return 3
        }

        return 0
    }

    render() {
        const { match: { url } } = this.props
        //console.log(this.state, this.props)
        return (
            <div>
                <Route path={`${url}/documents/*`}
                    render={props => WorkshopObservationToolComponent(props, this.props, this.state)} />
                <Route path={`${url}/document/*`}
                    render={props => WorkshopObservationToolFormComponent(props, this.props, this.state)} />
            </div>
        )
    }
}

export default WorkshopObservationToolBase