import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import { db } from '../../firebase/Firebase'
//import ColType from '../.././Types'
//import { Button, Form, Radio, Icon, Spin } from 'antd'
import { Document } from './Edviz'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//var moment = require('moment')

class EdvizComponentTest extends Component {
    state = {
        testComponents: {
            "components": [
                {
                    "type": "H1",
                    "heading": "Heading 1: Torture Test Document"
                },
                {
                    "type": "H2",
                    "heading": "Heaing 2: Testing Heading 2"
                },
                {
                    "type": "H3",
                    "heading": "Heaing 3: Testing Heading 3"
                },
                {
                    "type": "P",
                    "content": [
                        {
                            "type": "Text",
                            "content": "This is a test document for the LaTeX/React generator. "
                        },
                        {
                            "type": "Text",
                            "content": "It contains a "
                        },
                        {
                            "type": "BoldText",
                            "content": "variety"
                        },
                        {
                            "type": "Text",
                            "content": " of components to test the generator."
                        }
                    ]
                },
                {
                    "type": "H2",
                    "heading": "Text"
                },
                {
                    "type": "P",
                    "content": [
                        {
                            "type": "Text",
                            "content": "BoldText: "
                        },
                        {
                            "type": "BoldText",
                            "content": "This is bold text"
                        },
                        {
                            "type": "LineBreak"
                        }
                    ]
                },
                {
                    "type": "P",
                    "content": [
                        {
                            "type": "Text",
                            "content": "ItalicText: "
                        },
                        {
                            "type": "ItalicText",
                            "content": "This is italic text"
                        },
                        {
                            "type": "LineBreak"
                        }
                    ]
                },
                {
                    "type": "P",
                    "content": [
                        {
                            "type": "Text",
                            "content": "UnderlineText: "
                        },
                        {
                            "type": "UnderlineText",
                            "content": "This is underlined text"
                        },
                        {
                            "type": "LineBreak"
                        }
                    ]
                },
                {
                    "type": "P",
                    "content": [
                        {
                            "type": "Text",
                            "content": "BlankText: "
                        },
                        {
                            "type": "BlankText"
                        },
                        {
                            "type": "LineBreak"
                        }
                    ]
                },
                {
                    "type": "H3",
                    "heading": "PassageText"
                },
                {
                    "type": "LineBreak"
                },
                {
                    "type": "PassageText",
                    "content": "This is a passage of text, that should be formatted with tigher margins."
                },
                {
                    "type": "LineBreak"
                },
                {
                    "type": "H2",
                    "heading": "Tables"
                },
                {
                    "type": "H3",
                    "heading": "Table with title"
                },
                {
                    "type": "LineBreak"
                },
                {
                    "type": "Table",
                    "data": [
                        [
                            {
                                "type": "Text",
                                "content": "Column 1"
                            },
                            {
                                "type": "Text",
                                "content": "Column 2"
                            },
                            {
                                "type": "Text",
                                "content": "Column 3"
                            }
                        ],
                        [
                            {
                                "type": "Text",
                                "content": "Row 1"
                            },
                            {
                                "type": "Text",
                                "content": "Row 1"
                            },
                            {
                                "type": "Text",
                                "content": "Row 1"
                            }
                        ],
                        [
                            {
                                "type": "Text",
                                "content": "Row 2"
                            },
                            {
                                "type": "Text",
                                "content": "Row 2"
                            },
                            {
                                "type": "Text",
                                "content": "Row 2"
                            }
                        ]
                    ],
                    "title": "Table Title"
                },
                {
                    "type": "H3",
                    "heading": "Table without title"
                },
                {
                    "type": "LineBreak"
                },
                {
                    "type": "Table",
                    "data": [
                        [
                            {
                                "type": "Text",
                                "content": "Column 1"
                            },
                            {
                                "type": "Text",
                                "content": "Column 2"
                            }
                        ],
                        [
                            {
                                "type": "Text",
                                "content": "Row 1"
                            },
                            {
                                "type": "Text",
                                "content": "Row 1"
                            }
                        ],
                        [
                            {
                                "type": "Text",
                                "content": "Row 2"
                            },
                            {
                                "type": "Text",
                                "content": "Row 2"
                            }
                        ],
                        [
                            {
                                "type": "Text",
                                "content": "Row 3"
                            },
                            {
                                "type": "Text",
                                "content": "Row 3"
                            }
                        ]
                    ],
                    "title": null
                },
                {
                    "type": "H2",
                    "heading": "Lists - Should appear side by side"
                },
                {
                    "type": "HorizontalBox",
                    "components": [
                        {
                            "type": "EnumList",
                            "items": [
                                {
                                    "type": "Text",
                                    "content": "Item 1"
                                },
                                {
                                    "type": "Text",
                                    "content": "Item 2"
                                },
                                {
                                    "type": "Text",
                                    "content": "Item 3"
                                }
                            ]
                        },
                        {
                            "type": "ItemList",
                            "items": [
                                {
                                    "type": "Text",
                                    "content": "Item 1"
                                },
                                {
                                    "type": "Text",
                                    "content": "Item 2"
                                },
                                {
                                    "type": "Text",
                                    "content": "Item 3"
                                }
                            ]
                        }
                    ]
                },
                {
                    "type": "H2",
                    "heading": "Math Text"
                },
                {
                    "type": "H3",
                    "heading": "Equation"
                },
                {
                    "type": "LineBreak"
                },
                {
                    "type": "Equation",
                    "content": "x^2 + y^2 = z^2"
                },
                {
                    "type": "H3",
                    "heading": "Align"
                },
                {
                    "type": "LineBreak"
                },
                {
                    "type": "Align",
                    "equations": [
                        {
                            "type": "Equation",
                            "content": "x + y = 1"
                        },
                        {
                            "type": "Equation",
                            "content": "x - y = 2"
                        },
                        {
                            "type": "Equation",
                            "content": "x + y = 3"
                        }
                    ]
                },
                {
                    "type": "H2",
                    "heading": "Geometry"
                },
                {
                    "type": "HorizontalBox",
                    "components": [
                        {
                            "type": "Circle",
                            "radius": 4
                        },
                        {
                            "type": "Rectangle",
                            "width": 4,
                            "height": 5
                        },
                        {
                            "type": "Square",
                            "side": 4
                        },
                        {
                            "type": "Triangle",
                            "base": 4,
                            "height": 5
                        }
                    ]
                },
                {
                    "type": "HorizontalBox",
                    "components": [
                        {
                            "type": "Ellipse",
                            "major_axis": 4,
                            "minor_axis": 5
                        },
                        {
                            "type": "RegularPolygon",
                            "sides": 4,
                            "side_length": 5
                        },
                        {
                            "type": "RegularPolygon",
                            "sides": 7,
                            "side_length": 5
                        },
                        {
                            "type": "RegularPolygon",
                            "sides": 12,
                            "side_length": 5
                        }
                    ]
                },
                {
                    "type": "HorizontalBox",
                    "components": [
                        {
                            "type": "Trapezoid",
                            "base1": 4,
                            "base2": 5,
                            "height": 2
                        },
                        {
                            "type": "Parallelogram",
                            "base": 6,
                            "side": 5
                        },
                        {
                            "type": "Rhombus",
                            "diagonal1": 4,
                            "diagonal2": 5
                        },
                        {
                            "type": "CircleSector",
                            "radius": 4,
                            "angle": 45
                        }
                    ]
                },
                {
                    "type": "Rectangle",
                    "width": 40,
                    "height": 5
                },
                {
                    "type": "H2",
                    "heading": "Graphs"
                },
                {
                    "type": "H3",
                    "heading": "Number Line with no points"
                },
                {
                    "type": "NumberLine",
                    "xmin": 0,
                    "xmax": 10,
                    "points": null
                },
                {
                    "type": "H3",
                    "heading": "Number Line with points (2, 3, 4, 5, 6, 7, 8)"
                },
                {
                    "type": "NumberLine",
                    "xmin": -2,
                    "xmax": 3,
                    "points": [
                        1
                    ]
                },
                {
                    "type": "H3",
                    "heading": "Scatter Plot"
                },
                {
                    "type": "ScatterPlot",
                    "x": [
                        2019,
                        2020,
                        2021
                    ],
                    "y": [
                        3,
                        3.5,
                        5
                    ],
                    "title": "Price of Apples Over Time",
                    "x_label": "Year",
                    "y_label": "Price"
                },
                {
                    "type": "H3",
                    "heading": "Line Plot"
                },
                {
                    "type": "LinePlot",
                    "x": [
                        2019,
                        2020,
                        2021
                    ],
                    "y": [
                        3,
                        3.5,
                        5
                    ],
                    "title": "Price of Apples Over Time",
                    "x_label": "Year",
                    "y_label": "Price"
                },
                {
                    "type": "H3",
                    "heading": "Clocks - Should appear side by side"
                },
                {
                    "type": "HorizontalBox",
                    "components": [
                        {
                            "type": "Clock",
                            "hour": null,
                            "minute": null,
                            "second": null
                        },
                        {
                            "type": "Clock",
                            "hour": 12,
                            "minute": null,
                            "second": null
                        },
                        {
                            "type": "Clock",
                            "hour": 12,
                            "minute": 30,
                            "second": null
                        },
                        {
                            "type": "Clock",
                            "hour": 12,
                            "minute": 30,
                            "second": 45
                        }
                    ]
                },
                {
                    "type": "H2",
                    "heading": "Icons"
                },
                {
                    "type": "H3",
                    "heading": "Icon"
                },
                {
                    "type": "Icon",
                    "name": "x-ray"
                },
                {
                    "type": "H3",
                    "heading": "Icon Bunch (30 x 6)"
                },
                {
                    "type": "IconBunch",
                    "name": "weight-hanging",
                    "columns": 6,
                    "rows": 30
                },
                {
                    "type": "H2",
                    "heading": "Assessment"
                },
                {
                    "question": [
                        {
                            "type": "Text",
                            "content": "Question goes here."
                        }
                    ],
                    "answers": [
                        {
                            "type": "Answer",
                            "id": 0,
                            "correct": true,
                            "choice": [
                                {
                                    "type": "Text",
                                    "content": "Correct choice."
                                }
                            ],
                            "reason": {
                                "type": "Text",
                                "content": "Correct reasoning."
                            }
                        },
                        {
                            "type": "Answer",
                            "id": 1,
                            "correct": false,
                            "choice": [
                                {
                                    "type": "Text",
                                    "content": "Incorrect choice."
                                }
                            ],
                            "reason": {
                                "type": "Text",
                                "content": "Incorrect reasoning."
                            }
                        },
                        {
                            "type": "Answer",
                            "id": 2,
                            "correct": false,
                            "choice": [
                                {
                                    "type": "Text",
                                    "content": "Incorrect choice."
                                }
                            ],
                            "reason": {
                                "type": "Text",
                                "content": "Incorrect reasoning."
                            }
                        },
                        {
                            "type": "Answer",
                            "id": 3,
                            "correct": false,
                            "choice": [
                                {
                                    "type": "Text",
                                    "content": "Incorrect choice."
                                }
                            ],
                            "reason": {
                                "type": "Text",
                                "content": "Incorrect reasoning."
                            }
                        }
                    ],
                    "type": "Assessment"
                }
            ],
            "metadata": {
                "docType": "KILROY"
            }
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className='mt-100 pl-4 pr-4 pt-1'>
                <Document
                    components={this.state.testComponents.components}
                />
            </div>
        )
    }
}

export default EdvizComponentTest
