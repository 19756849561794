import React, { Component } from 'react';
import AssessmentAnalytics from '../admin/AssessmentAnalytics';
//import Empty from '../customcomponents/Empty';

class InstructionalFacilitatorAssessmentAnalytics extends Component {
    state = {
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                {this.props.instructionalFacilitatorBaseProps &&
                    this.props.instructionalFacilitatorBaseProps.district &&
                    this.props.instructionalFacilitatorBaseProps.districtSettings &&
                    this.props.instructionalFacilitatorBaseProps.schools &&
                    this.props.instructionalFacilitatorBaseProps.schoolsDict &&
                    this.props.instructionalFacilitatorBaseProps.instructionalFacilitator ?
                    <AssessmentAnalytics
                        districtId={this.props.instructionalFacilitatorBaseProps.district.id}
                        district={this.props.instructionalFacilitatorBaseProps.district}
                        districtSettings={this.props.instructionalFacilitatorBaseProps.districtSettings}
                        schools={this.props.instructionalFacilitatorBaseProps.schools}
                        schoolsDict={this.props.instructionalFacilitatorBaseProps.schoolsDict}
                        reviewerId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id}
                        person={{
                            firstName: this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.firstName,
                            lastName: this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.lastName,
                        }}
                        analyticsFor='school'
                    /> : ''}
            </div>

        );
    }
}

export default InstructionalFacilitatorAssessmentAnalytics;
