import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Layout, Row, Col, message, Select, Tabs, Button, Modal, Icon } from 'antd'
import DistrictSAMScores from './DistrictSAMScores'
import DistrictStatsV2 from './DistrictStatsV2'
const { Content } = Layout
const TabPane = Tabs.TabPane
const moment = require('moment')

const errorMessage = (description) => {
  message.error(description)
}

class AdminHome extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    adminId: '',
    selectedId: null,
    teacherId: '',
    studentId: '',
    teacher: null,
    student: null,
    teachers: [],
    students: [],
    studentDict: {},
    IEPGoals: [],
    IEPDict: {},
    calendarEvents: null,
    calendarDict: null,
    events: null,
    studentReportData: {},
    teacherTab: 'schedule',
    loadingData: false,
    readOnly: true,
    showTutorialModal: false,
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Dashboard - Dot It'

    var adminId = getIDFromURL(window.location)

    this.setState({
      adminId: adminId,
    })
  }

  componentWillReceiveProps(nextProps) {
    let selectedId = getQueryStringParam('s')
    //let teacherId = getQueryStringParam('t')
    let prevSelectedId = this.state.selectedId
    // let prevSelectedTeacherId = this.state.teacherId

    this.setState({
      selectedId: selectedId,
    })

    if (selectedId && prevSelectedId !== selectedId) {
      this.setState({
        teacherId: '',
        studentId: '',
        teacher: null,
        student: null,
        teachers: [],
        students: [],
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
        calendarEvents: null,
        calendarDict: null,
        events: null,
      })

      this.getTeachers(selectedId)
    }
  }

  onTutorialModalCancel = () => {
    if (this.tutorialVideoRef) {
      this.tutorialVideoRef.pause()
    }
    this.setState({
      showTutorialModal: false,
    })
  }

  getTeachers = (schoolId) => {
    if (schoolId && this.props.adminBaseProps.admin) {
      this.setState({
        loadingData: true,
      }, () => {
        db.collection(ColType.teacher)
          .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
          .where('schoolId', '==', schoolId)
          .get()
          .then((querySnapshot) => {
            var teachers = []

            querySnapshot.forEach((doc) => {
              var teacher = flattenDoc(doc)
              if (!(teacher.hasOwnProperty('isVisible') && !teacher.isVisible)) {
                teachers.push(teacher)
              }
            })

            teachers.sort((a, b) => {
              if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                return 1
              } else {
                return -1
              }
            })

            this.setState({
              teachers: teachers,
              loadingData: false,
            })
          })
      })
    }
  }

  getStudents = (teacher) => {
    if (teacher && this.props.adminBaseProps.admin) {
      let promises = []

      this.setState({
        loadingData: true,
      })

      promises.push(new Promise((resolve, reject) => {
        db.collection(ColType.iep)
          .where('teacherId', '==', teacher.id)
          .where('schoolId', '==', teacher.schoolId)
          .where('districtId', '==', teacher.districtId)
          .orderBy('timeStamp', 'desc')
          .get()
          .then((querySnapshot) => {
            var IEPGoals = []
            var IEPDict = {}

            querySnapshot.forEach((doc) => {
              var iep = flattenDoc(doc)
              IEPGoals.push(iep)
              IEPDict[iep.id] = iep
            })

            this.setState({
              IEPGoals: IEPGoals,
              IEPDict: IEPDict,
            })

            resolve()
          })
          .catch((e) => {
            reject(e)
          })
      }))

      promises.push(new Promise((resolve, reject) => {
        db.collection(ColType.calendarEvents)
          .where('teacherId', '==', teacher.id)
          .where('schoolId', '==', teacher.schoolId)
          .where('districtId', '==', teacher.districtId)
          .orderBy('startTime')
          .orderBy('index')
          .orderBy('duration', 'desc')
          .get()
          .then((querySnapshot) => {
            var calendarEvents = []
            var calendarDict = {}
            var events = []
            var idx = 0

            querySnapshot.forEach((doc) => {
              if (!doc.exists) return
              var event = flattenDoc(doc)
              // store the doc for deletes
              event.doc = doc
              calendarEvents.push(event)
              var start = moment.utc(event.startTime.seconds * 1000)
              var end = new Date(event.endTime)
              //start = new Date(2015, 5, parseInt(event.day, 10),
              //  start.getHours(), start.getMinutes(), 0)
              end = new Date(2015, 5, parseInt(event.day, 10),
                end.getHours(), end.getMinutes(), 0)
              event.start = start
              event.end = end

              events.push(event)
              calendarDict[idx.toString()] = {
                'flattenedDoc': event,
                'unFlattenedDoc': doc.data()
              }
            })

            this.setState({
              calendarEvents: calendarEvents,
              calendarDict: calendarDict,
              events: events,
            })

            resolve()
          })
          .catch((e) => {
            reject(e)
          })
      }))

      promises.push(new Promise((resolve, reject) => {
        db.collection(ColType.student)
          .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
          .where('teacherId', '==', teacher.id)
          .get()
          .then((querySnapshot) => {
            var students = []
            var studentDict = {}

            querySnapshot.forEach((doc) => {
              var student = flattenDoc(doc)
              students.push(student)
              studentDict[student.id] = student
            })

            students.sort((a, b) => {
              var levelDifference = 0
              if (a.grade === 'K' && b.grade === 'K') {
                if (levelDifference !== 0) return levelDifference
                if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                  return 1
                } else {
                  return -1
                }
              }
              if (a.grade === 'K' && b.grade !== 'K') return -1
              if (a.grade !== 'K' && b.grade === 'K') return 1
              if (a.grade === b.grade) {
                if (levelDifference !== 0) return levelDifference
                if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                  return 1
                } else {
                  return -1
                }
              }
              var aGrade = parseInt(a.grade, 10)
              var bGrade = parseInt(b.grade, 10)
              if (aGrade > bGrade) return 1
              if (bGrade > aGrade) return -1
              return 0
            })

            this.setState({
              students: students,
              studentDict: studentDict,
            })

            resolve()
          })
          .catch((e) => {
            reject(e)
          })
      }))

      Promise.all(promises)
        .then(() => {
          this.setState({
            loadingData: false,
          })
        })
        .catch((e) => {
          this.setState({
            loadingData: false,
          })
        })
    }
  }

  getIEPs = (teacher, student) => {
    if (teacher && student && this.props.adminBaseProps.admin) {
      db.collection(ColType.iep)
        .where('districtId', '==', this.props.adminBaseProps.admin.districtId)
        //.where('schoolId', '==', teacher.schoolId)
        .where('teacherId', '==', teacher.id)
        .where('studentId', '==', student.id)
        .get()
        .then((querySnapshot) => {
          var IEPGoals = []
          var IEPDict = {}

          querySnapshot.forEach((doc) => {
            var iep = flattenDoc(doc)
            IEPGoals.push(iep)
            IEPDict[iep.id] = iep
          })

          this.setState({
            IEPGoals: IEPGoals,
            IEPDict: IEPDict,
          })
        })
    }
  }

  newVisibleChange = (visible) => {
    this.setState({ newPopoverVisible: visible })
  }

  accountVisibleChange = (visible) => {
    this.setState({ accountPopoverVisible: visible })
  }

  signOut = () => {
    firebase.auth().signOut().then(function () {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      window.location.href = "/sign-in"
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/admin/school-summary/' +
          this.props.person.id + '?district=' +
          this.props.person.districtId + '&school=' +
          option.key
      }
    )
  }

  onSchoolOrDistrictSelect = (schoolOrDistrict) => {
    if (this.props.adminBaseProps.district &&
      this.props.adminBaseProps.district.id === schoolOrDistrict) {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id
        }
      )
    }
    else {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id + '?s=' + schoolOrDistrict
        }
      )
    }
  }

  teacherClick = (teacher) => {
    this.setState({
      teacher: teacher,
      teacherId: teacher.id
    })

    this.getStudents(teacher)

    window.scroll(0, 0)
  }

  studentClick = (student) => {
    this.setState({
      student: student,
      studentId: student.id,
      studentReportData: {
        teacherId: this.state.teacher.id,
        studentId: student.id,
        teacher: this.state.teacher,
        student: student,
        teachers: this.state.teachers,
        students: this.state.students,
        studentDict: this.state.studentDict,
        // goals for this student
        IEPGoals: this.state.IEPGoals.filter((e) => e.studentId === student.id),
        IEPDict: this.state.IEPDict,
      }
    })

    window.scroll(0, 0)
  }

  onSchoolOrDistrictSelect = (schoolOrDistrict) => {
    this.setState({
      selectedOptionId: schoolOrDistrict
    })

    if (this.props.adminBaseProps.district &&
      this.props.adminBaseProps.district.id === schoolOrDistrict) {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id
        }
      )
    }
    else {
      this.props.history.push(
        {
          pathname: '/admin/admin-home/' +
            this.props.adminBaseProps.admin.id + '?s=' + schoolOrDistrict
        }
      )
    }
  }

  getSchoolTypeIcon = (school) => {
    let schoolIcon = '/school-icons/1.png'
    if (school.hasOwnProperty('schoolType')) {
      if (school.schoolType === 'k8School') {
        schoolIcon = '/school-icons/2.png'
      } else if (school.schoolType === 'middleSchool') {
        schoolIcon = '/school-icons/3.png'
      } else if (school.schoolType === 'highSchool') {
        schoolIcon = '/school-icons/4.png'
      }
    }

    return schoolIcon
  }

  render() {
    let selectedId = null
    let selectedStats = null
    let selectedDisplayName = ''
    let districtId = null
    if (this.props.adminBaseProps.district) {
      districtId = this.props.adminBaseProps.district.id
      // default is to select the district if no school is selected
      selectedDisplayName = this.props.adminBaseProps.district.district
      selectedId = this.props.adminBaseProps.district.id

      if (this.state.selectedId) {
        selectedId = this.state.selectedId

        if (selectedId !== this.props.adminBaseProps.district.id &&
          this.props.adminBaseProps.schoolsDict &&
          this.props.adminBaseProps.schoolsDict.hasOwnProperty(selectedId)) {
          selectedDisplayName = this.props.adminBaseProps.schoolsDict[selectedId].schoolName
        }
      }

      if (this.props.adminBaseProps.allStatOptionsDict.hasOwnProperty(selectedId)) {
        selectedStats = this.props.adminBaseProps.allStatOptionsDict[selectedId]
      }
    }
    //console.log(districtId)

    return (
      <div>
        <div>
          <Layout className="content relative">
            <Layout>
              <Content className='layout-header-mt'>
                <div>
                  {this.state.adminId &&
                    this.props.adminBaseProps.admin &&
                    this.props.adminBaseProps.district &&
                    this.props.adminBaseProps.schoolsDict
                    ?
                    <div>
                      <div className="sub-menu-width-vlg m-lr-auto mt-3">
                        <div>
                          <Row gutter={32}>
                            <Col span={24}>
                              {this.props.adminBaseProps.district &&
                                this.props.adminBaseProps.district.district === 'Dot It Demo District' ?
                                <div>
                                  <div className='mb-3 p-3 br-15'
                                    style={{ background: '#b5f5ec' }}>
                                    <div className='font-30 mb-1'>
                                      Welcome to the Dot It Demo
                                    </div>
                                    <Button type='primary' className='lg-btn'
                                      onClick={(e) => {
                                        this.setState({
                                          showTutorialModal: true,
                                        })
                                      }}>
                                      <Icon type='caret-right' />
                                      Watch Demo Video
                                    </Button>
                                  </div>

                                  <Modal
                                    visible={this.state.showTutorialModal}
                                    onCancel={this.onTutorialModalCancel}
                                    footer={null}
                                    width={800}>
                                    <video
                                      ref={el => (this.tutorialVideoRef = el)}
                                      src='https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/demo.mp4?alt=media&token=f7719e0d-ff50-46db-a11f-d18edb347ca3'
                                      controls
                                      className='w-100'
                                      style={{ height: 'auto' }}
                                    />
                                  </Modal>
                                </div>

                                : ''}
                              <div className='br-20 overflow-hidden mb-3 mt-2'>
                                <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                  style={{ height: '280px', }}>

                                  <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#333' }}>
                                  </div>
                                  <div className="absolute-tl w-100 h-100 image-box-mask"
                                    style={{ backgroundImage: 'url(/banner-2-new.png)' }}
                                  >
                                  </div>
                                  <div className='absolute-tl w-100 h-100'>
                                    <div className='h-100'>
                                      <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                        <div className='w-100'>
                                          <div className='font-40 font-bold font-fff'>{selectedDisplayName}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='font-24 font-bold mb-2 mt-3'>Schools</div>
                              {this.props.adminBaseProps.schools ?
                                <Select
                                  showSearch
                                  placeholder="Search for a school"
                                  className='w-100 mb-2'
                                  optionFilterProp="children"
                                  onSelect={(schoolId) => {
                                    if (this.props.adminBaseProps.admin && schoolId) {
                                      this.props.history.push({
                                        pathname: '/admin/school/' + this.props.adminBaseProps.admin.id + '?s=' + schoolId
                                      })
                                    }
                                  }}
                                  size="large"
                                  filterOption={(input, option) => {
                                    if (option.props.children.length < 2) {
                                      return false
                                    }
                                    return option.props.children[1].toLowerCase().includes(input.toLowerCase())
                                  }}
                                >
                                  {this.props.adminBaseProps.schools &&
                                    this.props.adminBaseProps.schools.map((school, idx) => {
                                      return <Select.Option value={school.id} key={'search-' + school.id}>
                                        <img src={this.getSchoolTypeIcon(school)} alt='school-icon' width={32} className='mr-1' />
                                        {school.schoolName}
                                      </Select.Option>
                                    })}
                                </Select>
                                : ''}

                              {this.props.adminBaseProps.schools && this.props.adminBaseProps.schools.length === 0 ?
                                <div className='font-16 font-bold mb-1'>No schools</div>
                                : ''}
                              {this.props.adminBaseProps.schools &&
                                this.props.adminBaseProps.schools.map((school, idx) => {
                                  return <Link to={'/admin/school/' + this.props.adminBaseProps.admin.id + '?s=' + school.id}
                                    id={'right-menu-' + school.id}>
                                    <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}>
                                      <img src={this.getSchoolTypeIcon(school)} alt='school-icon' width={50} className='mr-1' />
                                      {school.schoolName}
                                    </div>
                                  </Link>
                                })}

                            </Col>

                          </Row>


                        </div>
                      </div>

                    </div>
                    : ''}
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
        <CustomFooter />
      </div>
    )
  }
}

export default AdminHome