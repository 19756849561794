import React from 'react';
import { Document as Document1 } from './1/Edviz';
import { Document as Document2 } from './2/Edviz';

class EdvizDelagator extends React.Component {
  state = {}

  render() {
    //console.log(this.props)

    if (!(this.props.hasOwnProperty('document') && this.props.document)) {
      return '';
    }

    let version = '1';
    if (this.props.document.hasOwnProperty('metadata') &&
      this.props.document.metadata &&
      this.props.document.metadata.hasOwnProperty('edvizVersion') &&
      this.props.document.metadata.edvizVersion) {
      version = this.props.document.metadata.edvizVersion;
    }

    let components = [];
    if (this.props.document.hasOwnProperty('components') &&
      this.props.document.components) {
      components = this.props.document.components;
    }

    let assessment = null;
    if (this.props.document.hasOwnProperty('assessment') &&
      this.props.document.assessment) {
      assessment = this.props.document.assessment;
    }

    // Select the appropriate Document component based on the version
    let DocumentComponent;
    switch (version) {
      case "2":
        DocumentComponent = Document2;
        break;
      case "1":
      default:
        DocumentComponent = Document1;
        break;
    }

    return <DocumentComponent
      additionalData={this.props.hasOwnProperty('additionalData') ? this.props.additionalData : {}}
      components={components}
      assessment={assessment}
    />;
  }
};

export default EdvizDelagator;
