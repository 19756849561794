import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import { flattenDoc, FOLDER_CURRENT, getQueryStringParam } from '../Util'
import { Layout, Row, Col, Select, Icon } from 'antd'
import PersonAvatar from '../customcomponents/PersonAvatar'
import Scheduler from '../teacher/scheduler/Scheduler'
import StudentSummary from '../admin/StudentSummary'
import AdminTeacherView from '../admin/AdminTeacherView'
const { Content } = Layout
const moment = require('moment')

class InstructionalFacilitatorTeacherView extends Component {
    state = {
        schoolId: '',
        teacherId: '',
        teacher: '',
        students: null,
        studentDict: null,
        IEPGoals: null,
        IEPDict: null,
        calendarEvents: null,
        calendarDict: null,
        events: null,
        loadedData: false,
        readOnly: true,
        currentTeacherTab: 'teachers',
    }

    // Do fetch here
    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(props, newProps) {
        this.getData()
    }

    getData() {
        if (this.state.loadedData) {
            return
        }

        let schoolId = getQueryStringParam('s')
        var teacherId = getQueryStringParam('t')

        this.setState({
            schoolId: schoolId,
            teacherId: teacherId,
        })

        if (teacherId && this.props.instructionalFacilitatorBaseProps.instructionalFacilitator) {
            this.setState({
                loadedData: true,
            })

            db.collection(ColType.teacher)
                .doc(teacherId)
                .get()
                .then((doc) => {
                    var teacher = flattenDoc(doc)

                    this.setState({
                        teacher: teacher,
                    })

                    db.collection(ColType.iep)
                        .where('teacherId', '==', teacherId)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        .orderBy('timeStamp', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            var IEPGoals = []
                            var IEPDict = {}

                            querySnapshot.forEach((doc) => {
                                var iep = flattenDoc(doc)
                                IEPGoals.push(iep)
                                IEPDict[iep.id] = iep
                            })

                            this.setState({
                                IEPGoals: IEPGoals,
                                IEPDict: IEPDict,
                            })
                        })

                    db.collection(ColType.calendarEvents)
                        .where('teacherId', '==', teacherId)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        .orderBy('startTime')
                        .orderBy('index')
                        .orderBy('duration', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            var calendarEvents = []
                            var calendarDict = {}
                            var events = []
                            var idx = 0

                            querySnapshot.forEach((doc) => {
                                if (!doc.exists) return
                                var event = flattenDoc(doc)
                                // store the doc for deletes
                                event.doc = doc
                                calendarEvents.push(event)
                                var start = moment.utc(event.startTime.seconds * 1000)
                                var end = new Date(event.endTime)
                                //start = new Date(2015, 5, parseInt(event.day, 10),
                                //  start.getHours(), start.getMinutes(), 0)
                                end = new Date(2015, 5, parseInt(event.day, 10),
                                    end.getHours(), end.getMinutes(), 0)
                                event.start = start
                                event.end = end

                                events.push(event)
                                calendarDict[idx.toString()] = {
                                    'flattenedDoc': event,
                                    'unFlattenedDoc': doc.data()
                                }
                            })

                            this.setState({
                                calendarEvents: calendarEvents,
                                calendarDict: calendarDict,
                                events: events,
                            })
                        })
                })

            const studentUpdateStateFunc = (querySnapshot) => {
                var students = []
                var studentDict = {}

                querySnapshot.forEach((doc) => {
                    var student = flattenDoc(doc)
                    students.push(student)
                    studentDict[student.id] = student
                })

                students.sort((a, b) => {
                    var levelDifference = 0
                    if (a.grade === 'K' && b.grade === 'K') {
                        if (levelDifference !== 0) return levelDifference
                        if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                            return 1
                        } else {
                            return -1
                        }
                    }
                    if (a.grade === 'K' && b.grade !== 'K') return -1
                    if (a.grade !== 'K' && b.grade === 'K') return 1
                    if (a.grade === b.grade) {
                        if (levelDifference !== 0) return levelDifference
                        if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                            return 1
                        } else {
                            return -1
                        }
                    }
                    var aGrade = parseInt(a.grade, 10)
                    var bGrade = parseInt(b.grade, 10)
                    if (aGrade > bGrade) return 1
                    if (bGrade > aGrade) return -1
                    return 0
                })

                this.setState({
                    students: students,
                    studentDict: studentDict,
                })
            }

            db.collection(ColType.student)
                .where('districtId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId)
                .where('schoolId', '==', schoolId)
                .where('teacherIds', 'array-contains', teacherId)
                .where('folder', '==', FOLDER_CURRENT)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        studentUpdateStateFunc(querySnapshot)
                    } else {
                        db.collection(ColType.student)
                            .where('districtId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId)
                            .where('schoolId', '==', schoolId)
                            .where('teacherId', '==', teacherId)
                            .where('folder', '==', FOLDER_CURRENT)
                            .get()
                            .then((querySnapshot) => {
                                studentUpdateStateFunc(querySnapshot)
                            })
                    }
                })
        }
    }

    render() {
        let schoolIdx = 0
        let url = '/instructional-facilitator/instructional-facilitator-home/'
        let qs = ''
        if (this.state.schoolId && this.props.instructionalFacilitatorBaseProps.schools) {
            schoolIdx = this.props.instructionalFacilitatorBaseProps.schools.findIndex((school) => {
                return school.id === this.state.schoolId
            })
        }
        if (this.state.schoolId && schoolIdx > 0) {
            url = '/instructional-facilitator/school/'
            qs = '?s=' + this.state.schoolId
        }

        return (
            <div>
                <AdminTeacherView
                    adminBaseProps={this.props.instructionalFacilitatorBaseProps}
                    instructionalFacilitatorBaseProps={this.props.instructionalFacilitatorBaseProps}
                    {...this.props}
                />
            </div>
        )
    }
}

export default InstructionalFacilitatorTeacherView