import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc, getStandardsColType, getStateStandardNamesDict, manualCheckQuestionDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Input, Layout, Button, Icon, Menu, Select, notification, Popconfirm } from 'antd'
import { Document, getTextFromDocument } from '../teacher/edvizv2/Edviz'
const Sider = Layout.Sider
const Content = Layout.Content
var moment = require('moment')

class SuperAdminQuestionReview extends Component {
    state = {
        state: 'SC',
        prevStandardName: '',
        prevStandardCategory: '',
        standardName: '4th Grade',
        standardCategory: 'Math',
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        standards: [],
        standardNamesDict: {},
        totalWeeks: 37,
        weeks: null,
        weekNums: [],
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        selectedStandard: null,
        questionBankListener: null,
        reviewQuestionBankListener: null,
        questionDocs: [],
        reviewedQuestionDocs: [],
        mode: 'questionBank',
        selectedReviewQuestion: null,
        reviewedQuestionDocsDict: {},
        questionBankGradePassages: {},
    }

    componentDidMount() {
        this.setState({
            standardNamesDict: getStateStandardNamesDict(this.state.state)
        })
        this.getStandards()
        this.getQuestionBankPassages()
    }

    getQuestionBankPassages = async () => {
        const grades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        grades.forEach(async (grade) => {
            try {
                const querySnapshot = await db.collection(ColType.questionBankPassages)
                    .where('metadata.grade', '==', '' + grade)
                    .get()

                let questionBankPassages = []
                let passagesDict = {}
                querySnapshot.forEach((doc) => {
                    const questionBankPassage = flattenDoc(doc)
                    questionBankPassage.components = JSON.parse(questionBankPassage.components)
                    questionBankPassages.push(questionBankPassage)
                    passagesDict[questionBankPassage.id] = questionBankPassage
                })

                let questionBankGradePassages = this.state.questionBankGradePassages
                questionBankGradePassages[grade] = questionBankPassages

                this.setState({
                    passages: questionBankPassages,
                    passagesDict: passagesDict,
                    questionBankGradePassages: questionBankGradePassages,
                })

                return questionBankPassages
            } catch (e) {
                console.log('error', e)
                return []
            }
        })
    }

    getStandards = async () => {
        if (this.state.standardName === this.state.prevStandardName &&
            this.state.standardCategory === this.state.prevStandardCategory) {
            return
        }

        this.setState({
            prevStandardName: this.state.standardName,
            prevStandardCategory: this.state.standardCategory,
        })

        const standardsColType = getStandardsColType(this.state.state)

        const querySnapshot = await db.collection(standardsColType)
            .where('standardName', '==', this.state.standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .orderBy('sortOrder')
            .get()

        let standards = []
        let standardsDict = {}
        querySnapshot.forEach((doc) => {
            let standard = flattenDoc(doc)
            standards.push(standard)
            standardsDict[standard.standard] = standard
        })

        await new Promise((resolve, reject) => {
            this.setState({
                standards: standards,
                standardsDict: standardsDict,
            }, () => {
                resolve()
            })
        })
    }

    getQuestionsForStandard = () => {
        if (!this.state.selectedStandard) {
            console.log('no standard selected')
            return
        }

        if (this.state.questionBankListener) {
            this.state.questionBankListener()
        }

        if (this.state.reviewQuestionBankListener) {
            this.state.reviewQuestionBankListener()
        }

        const questionBankListener = db.collection(ColType.questionBank)
            .where('metadata.status', '==', 'created')
            .where('metadata.standard.standard', '==', this.state.selectedStandard.standard)
            .where('metadata.standard.standardCategory', '==', this.state.standardCategory)
            .where('metadata.standard.standardName', '==', this.state.standardName)
            .where('metadata.standard.state', '==', this.state.state)
            .onSnapshot((querySnapshot) => {
                let questionDocs = [];
                querySnapshot.forEach((doc) => {
                    const questionDoc = flattenDoc(doc);
                    questionDoc.components = JSON.parse(questionDoc.components);
                    questionDocs.push(questionDoc);
                });

                // sort by metadata.createdAt, oldest questions first
                questionDocs.sort((a, b) => {
                    return new Date(a.metadata.createdAt) - new Date(b.metadata.createdAt)
                })


                console.log('questionDocs', questionDocs)

                this.setState({
                    questionDocs: questionDocs,
                })
            })

        const reviewQuestionBankListener = db.collection(ColType.questionBank)
            .where('metadata.status', '==', 'reviewed')
            .where('metadata.standard.standard', '==', this.state.selectedStandard.standard)
            .where('metadata.standard.standardCategory', '==', this.state.standardCategory)
            .where('metadata.standard.standardName', '==', this.state.standardName)
            .where('metadata.standard.state', '==', this.state.state)
            .onSnapshot((querySnapshot) => {
                let questionDocs = [];
                let questionDict = {};
                querySnapshot.forEach((doc) => {
                    const questionDoc = flattenDoc(doc);
                    questionDoc.components = JSON.parse(questionDoc.components);
                    questionDocs.push(questionDoc);
                    questionDict[questionDoc.id] = questionDoc;
                });

                console.log('reviewed questionDocs', questionDocs)
                questionDocs.sort((a, b) => {
                    if (!(a && a.metadata && a.metadata.reviewedAt && a.metadata.reviewedAt.seconds)) {
                        return 0
                    }
                    if (!(b && b.metadata && b.metadata.reviewedAt && b.metadata.reviewedAt.seconds)) {
                        return 0
                    }
                    return b.metadata.reviewedAt.seconds - a.metadata.reviewedAt.seconds
                })

                let newSelectedReviewQuestion = null
                if (this.state.selectedReviewQuestion &&
                    !questionDict.hasOwnProperty(this.state.selectedReviewQuestion.id)) {
                    if (this.state.reviewedQuestionDocs.length > 1) {
                        newSelectedReviewQuestion = this.state.reviewedQuestionDocs[1]
                    }
                }

                this.setState({
                    reviewedQuestionDocs: questionDocs,
                    reviewedQuestionDocsDict: questionDict,
                    selectedReviewQuestion: newSelectedReviewQuestion,
                })
            })

        this.setState({
            questionBankListener: questionBankListener,
            reviewQuestionBankListener: reviewQuestionBankListener,
        })
    }

    onStandardCategoryChange = (value) => {
        this.setState({
            mode: 'questionBank',
            standardCategory: value,
            standardName: this.state.standardNamesDict[value][0],
        }, async () => {
            await this.getStandards()
            this.getQuestionsForStandard()
        })
    }

    onStandardNameChange = (value) => {
        this.setState({
            mode: 'questionBank',
            standardName: value,
        }, async () => {
            await this.getStandards()
            this.getQuestionsForStandard()
        })
    }

    onStandardChange = (value) => {
        this.setState({
            mode: 'questionBank',
            selectedStandard: this.state.standardsDict[value],
        }, () => {
            this.getQuestionsForStandard()
        })
    }

    addToQuestionBank = () => {
        if (!this.state.selectedStandard) {
            return
        }
        if (this.state.questionDocs.length === 0) {
            return
        }

        const selectedQuestionDoc = this.state.questionDocs[0]
        if (!manualCheckQuestionDoc(selectedQuestionDoc)) {
            notification.error({
                message: 'Question is not valid!',
                description: 'This question is not valid. Please fix the question before adding to question bank.',
                position: 'topRight',
            })
            return
        }

        selectedQuestionDoc.metadata.status = 'reviewed'
        selectedQuestionDoc.metadata.reviewedAt = firebase.firestore.FieldValue.serverTimestamp()

        db.collection(ColType.questionBank)
            .doc(selectedQuestionDoc.id)
            .update({
                metadata: selectedQuestionDoc.metadata,
            })
            .then(() => {
                notification.success({
                    message: 'Question added to question bank!',
                    description: 'Question added to question bank successfully.',
                    position: 'topRight',
                })
            })
    }

    rejectQuestion = () => {
        if (!this.state.selectedStandard) {
            return
        }
        if (this.state.questionDocs.length === 0) {
            return
        }

        const selectedQuestionDoc = this.state.questionDocs[0]
        selectedQuestionDoc.metadata.status = 'removed'
        selectedQuestionDoc.metadata.reviewedAt = firebase.firestore.FieldValue.serverTimestamp()

        db.collection(ColType.questionBank)
            .doc(selectedQuestionDoc.id)
            .update({
                metadata: selectedQuestionDoc.metadata,
            })
            .then(() => {
                notification.success({
                    message: 'Question rejected!',
                    description: 'Question rejected successfully.',
                    position: 'topRight',
                })
            })
    }

    onReviewedQuestionSelect = (questionId) => {
        if (!this.state.reviewedQuestionDocsDict.hasOwnProperty(questionId)) {
            return
        }

        this.setState({
            selectedReviewQuestion: this.state.reviewedQuestionDocsDict[questionId],
            mode: 'reviewedQuestion',
        })
    }

    rejectReviewQuestion = () => {
        if (!this.state.selectedReviewQuestion) {
            return
        }

        const selectedQuestionDoc = this.state.selectedReviewQuestion
        selectedQuestionDoc.metadata.status = 'removed'
        selectedQuestionDoc.metadata.reviewedAt = firebase.firestore.FieldValue.serverTimestamp()

        db.collection(ColType.questionBank)
            .doc(selectedQuestionDoc.id)
            .update({
                metadata: selectedQuestionDoc.metadata,
            })
            .then(() => {
                /*this.setState({
                    mode: 'questionBank',
                })*/

                notification.success({
                    message: 'Question rejected!',
                    description: 'Question rejected successfully.',
                    position: 'topRight',
                })
            })
    }

    getNumDOKQuestions = (questionDocs, dokLevel) => {
        let count = 0

        questionDocs.forEach((questionDoc) => {
            if (questionDoc.metadata.dokLevel === dokLevel) {
                count += 1
            }
        })

        return count
    }

    render() {
        console.log(this.state)
        return <div>
            <SuperAdminHeader menuKey={'quiz-review'} />
            <div>
                <Sider
                    width={270}
                    style={{
                        overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 64px)',
                        position: 'fixed',
                        left: 0, borderRight: '1px solid #f1f1f1',
                        background: '#fff',
                    }}>
                    <div style={{ marginTop: 64 }}>
                        <div className='pl-2 pr-2'>
                            <Select onChange={this.onStandardCategoryChange} value={this.state.standardCategory} size='large' className='w-100 mb-1'>
                                {this.state.standardCategories.map((category, ind) => {
                                    return <Select.Option key={'category-' + ind} value={category}>{category}</Select.Option>
                                })}
                            </Select>
                            <Select onChange={this.onStandardNameChange} value={this.state.standardName} size='large' className='w-100'>
                                {this.state.standardNamesDict &&
                                    this.state.standardNamesDict.hasOwnProperty(this.state.standardCategory) &&
                                    this.state.standardNamesDict[this.state.standardCategory].map((standardName, ind) => {
                                        return <Select.Option key={'grade-' + ind} value={standardName}>{standardName}</Select.Option>
                                    })}
                            </Select>

                            <Select placeholder='Select standard...' onChange={this.onStandardChange}
                                value={this.state.selectedStandard ? this.state.selectedStandard.standard : undefined} size='large' className='w-100 mt-2'>
                                {this.state.standards.map((standard, ind) => {
                                    return <Select.Option key={'standard-' + ind} value={standard.standard}>{standard.standard}</Select.Option>
                                }
                                )}
                            </Select>

                            {this.state.selectedStandard ?
                                <div className='font-20 br-4 w-100 mt-4'>
                                    <div className='mb-05 font-bold'>Question Bank:</div>
                                    <div>{this.state.reviewedQuestionDocs.length} questions</div>
                                    <div className='mb-05 font-bold mt-2'>Questions to review:</div>
                                    <div>{this.state.questionDocs.length} questions</div>
                                    {this.state.questionDocs.length === 0 ?
                                        <div className='text-success mt-2'>
                                            <Icon type='check' className='mr-1' />
                                            <span>There are no more questions to review for this standard!</span>
                                        </div>
                                        : ''}
                                </div> : ''}

                            {this.state.selectedStandard ?
                                <div>
                                    <div className='font-20 font-bold mb-1 mt-2 pt-2 border-top-light'>
                                        Current question bank for this standard:</div>
                                    <div className='font-16'>DOK Level 1: {this.getNumDOKQuestions(this.state.reviewedQuestionDocs, 1)}</div>
                                    <div className='font-16'>DOK Level 2: {this.getNumDOKQuestions(this.state.reviewedQuestionDocs, 2)}</div>
                                    <div className='font-16 mb-1'>DOK Level 3: {this.getNumDOKQuestions(this.state.reviewedQuestionDocs, 3)}</div>
                                    <Menu selectedKeys={this.state.selectedReviewQuestion ?
                                        this.state.selectedReviewQuestion.id : undefined} mode='inline' onSelect={(e) => {
                                            this.onReviewedQuestionSelect(e.key)
                                        }}>
                                        {this.state.reviewedQuestionDocs.map((questionDoc, ind) => {
                                            if (!(questionDoc && questionDoc.metadata &&
                                                questionDoc.metadata.reviewedAt && questionDoc.metadata.reviewedAt.seconds)) {
                                                return false
                                            }
                                            return <Menu.Item key={questionDoc.id}>
                                                {manualCheckQuestionDoc(questionDoc) ?
                                                    <Icon type='check' className='mr-1 text-success' title='Question is valid.' /> :
                                                    <Icon type='exclamation-circle' className='mr-1 text-danger' title='Question is not valid!' />}
                                                <span>{ind + 1}. Reviewed on: {questionDoc.metadata.hasOwnProperty('reviewedAt') &&
                                                    questionDoc.metadata.reviewedAt.hasOwnProperty('seconds') &&
                                                    moment.utc(questionDoc.metadata.reviewedAt.seconds * 1000).format('MM/DD/YYYY')}</span>
                                            </Menu.Item>
                                        })}
                                    </Menu>
                                    <div className='mt-2 font-16'>
                                        {this.state.selectedStandard.title}
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                </Sider>
                <Layout className="content" style={{ marginLeft: 270, marginTop: "32px", overflowX: 'initial' }}>
                    <Content
                        className={"layout-content background-fff p-3 pt-0 br-4 print-remove-padding print-remove-margin"}
                    >
                        <div className='w-680 m-lr-auto'>
                            {this.state.mode === 'questionBank' ?
                                <div>
                                    {this.state.selectedStandard && this.state.questionDocs.length === 0 ?
                                        <div className='font-20 flex-v-center mt-2 text-success'>
                                            <Icon type='check' className='mr-1' />
                                            <span>There are no more questions to review for this standard!</span>
                                        </div>
                                        : ''}
                                    {this.state.questionDocs.map((questionDoc, ind) => {
                                        // one question at a time, always show the first question
                                        if (ind !== 0) {
                                            return
                                        }
                                        return <div className='mt-2 font-black font-20 border background-fff ant-shadow br-4 p-3 mb-100'>
                                            {!manualCheckQuestionDoc(questionDoc) ?
                                                <div className='text-danger mb-2'>
                                                    <Icon type='exclamation-circle' className='mr-1' />
                                                    <span>This question is not valid! It can not be added to the question bank. Reject it.</span>
                                                </div>
                                                : ''}
                                            <div className='font-24 font-bold mb-2'>
                                                DOK Level {questionDoc.metadata.dokLevel}
                                            </div>
                                            <Document
                                                assessment={{
                                                    standardName: questionDoc.metadata.standard.standardName,
                                                    standardCategory: questionDoc.metadata.standard.standardCategory,
                                                    standard: questionDoc.metadata.standard.standard,
                                                }}
                                                components={questionDoc.components}
                                                additionalData={{
                                                    showAllReasons: true,
                                                }}
                                            />

                                            <div className='mt-4'>
                                                <div className='font-16 font-bold'>
                                                    This question is for the standard: {questionDoc.metadata.standard.standard}
                                                </div>
                                                {questionDoc.metadata.standard.hasOwnProperty('isPriority') &&
                                                    questionDoc.metadata.standard.isPriority ?
                                                    <div className='font-16'>*Priority Standard</div>
                                                    : ''}
                                                <div className='mt-1 font-16'>{questionDoc.metadata.standard.title}</div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                                :
                                <div>
                                    {this.state.selectedReviewQuestion ?
                                        <div className='mt-2 font-black font-20 border background-fff ant-shadow br-4 p-3 mb-100'>
                                            <div className='font-24 font-bold mb-2'>
                                                DOK Level {this.state.selectedReviewQuestion.metadata.dokLevel}
                                            </div>
                                            <Document
                                                assessment={{
                                                    standardName: this.state.selectedReviewQuestion.metadata.standard.standardName,
                                                    standardCategory: this.state.selectedReviewQuestion.metadata.standard.standardCategory,
                                                    standard: this.state.selectedReviewQuestion.metadata.standard.standard,
                                                }}
                                                components={this.state.selectedReviewQuestion.components}
                                                additionalData={{
                                                    showAllReasons: true,
                                                }}
                                            />

                                            <div className='mt-4'>
                                                <div className='font-16 font-bold'>
                                                    This question is for the standard: {this.state.selectedReviewQuestion.metadata.standard.standard}
                                                </div>
                                                {this.state.selectedReviewQuestion.metadata.standard.hasOwnProperty('isPriority') &&
                                                    this.state.selectedReviewQuestion.metadata.standard.isPriority ?
                                                    <div className='font-16'>*Priority Standard</div>
                                                    : ''}
                                                <div className='mt-1 font-16'>{this.state.selectedReviewQuestion.metadata.standard.title}</div>
                                            </div>
                                        </div>
                                        : ''}
                                </div>}
                        </div>
                    </Content>

                    {this.state.mode === 'questionBank' ?
                        <div>
                            {this.state.selectedStandard && this.state.questionDocs.length > 0 ?
                                <div className='w-100 border-top-lighter p-3 text-center'
                                    style={{
                                        position: 'fixed', bottom: '0px', left: '270px',
                                        width: 'calc(100% - 270px', zIndex: '10', background: '#fff'
                                    }}>
                                    <div className='flex flex-h-center flex-v-center w-100'>
                                        <Button onClick={this.rejectQuestion} className='lg-btn mr-2' type='danger'><Icon type='delete' />Reject</Button>
                                        <Button onClick={this.addToQuestionBank}
                                            disabled={!manualCheckQuestionDoc(this.state.questionDocs[0])}
                                            className='lg-btn' type='primary'
                                        ><Icon type='plus' />{!manualCheckQuestionDoc(this.state.questionDocs[0]) ?
                                            'This question is not valid! Can not be added.' : 'Add to question bank'}</Button>
                                    </div>
                                </div> : ''}
                        </div>
                        :
                        <div>
                            {this.state.selectedReviewQuestion ?
                                <div className='w-100 border-top-lighter p-3 text-center'
                                    style={{
                                        position: 'fixed', bottom: '0px', left: '270px',
                                        width: 'calc(100% - 270px', zIndex: '10', background: '#fff'
                                    }}>
                                    <div className='flex flex-h-center flex-v-center w-100'>
                                        <Button onClick={(e) => {
                                            this.setState({
                                                mode: 'questionBank',
                                                selectedReviewQuestion: null,
                                            })
                                        }} className='lg-btn mr-2' type='default'><Icon type='arrow-left' />Back to question bank</Button>
                                        <Button onClick={this.rejectReviewQuestion} className='lg-btn' type='danger'><Icon type='delete' />Reject</Button>
                                    </div>
                                </div>
                                : ''}
                        </div>}
                </Layout>
            </div>

        </div>
    }
}

export default SuperAdminQuestionReview