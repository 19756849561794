import React from 'react'
import Answers from './Answers'
import MathText from './MathText'

class IntNumberLineAssessment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pre_text: props.pre_text,
            post_text: props.post_text,
            answers: props.answers || null,
            xmin: parseInt(props.xmin, 10),
            xmax: parseInt(props.xmax, 10),
            points: props.points || []
        }

        // Choose the number of ticks based on the range
        let num_ticks = Math.min(21, this.state.xmax - this.state.xmin + 1)

        this.state.xticks = Array.from({ length: num_ticks }, (_, i) => this.state.xmin + i * (this.state.xmax - this.state.xmin) / (num_ticks - 1))
        this.state.xticks_str = this.state.xticks.map(x => Math.round(x)).join(",")
    }

    generateTicks(xmin, xmax) {
        const range = xmax - xmin
        const numTicks = Math.min(range + 1, 21)
        const step = range / (numTicks - 1)
        const ticks = Array.from({ length: numTicks }, (_, i) => Math.round(xmin + i * step))
        return ticks
    }


    render() {
        const { pre_text, post_text, xmin, xmax, answers, points } = this.state

        const padding = 30
        const ticks = this.generateTicks(xmin, xmax)
        const svgWidth = 600 + padding * 2  // add padding to SVG width
        const scale = (svgWidth - padding * 2) / (xmax - xmin)  // scale according to padded SVG

        return (
            <div>
                <p><MathText text={pre_text} /></p>
                <div>
                    <svg width={svgWidth} height="80">
                        <line x1={padding} y1="40" x2={svgWidth - padding} y2="40" stroke="black" />
                        {ticks.map((tick, index) => (
                            <g key={index} transform={`translate(${padding + (tick - xmin) * scale})`}>
                                <line x1="0" y1="35" x2="0" y2="45" stroke="black" />
                                <text x="0" y="70" textAnchor="middle">{tick}</text>
                            </g>
                        ))}
                        {points.map((point, index) => (
                            <circle key={index} cx={padding + (point - xmin) * scale} cy="40" r="5" fill="black" />
                        ))}
                    </svg>
                </div>
                <p><MathText text={post_text} /></p>

                <Answers answers={answers} />
            </div>
        )
    }
}

export default IntNumberLineAssessment
