import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Form, Button, Input, message, Icon, Row, Col } from 'antd'
const FormItem = Form.Item
const TextArea = Input.TextArea
var moment = require('moment')

const successMessage = (description) => {
  message.success(description)
}

const errorMessage = (description) => {
  message.error(description)
}


class SuperAdminSupportChat extends Component {
  state = {
    submitting: false,
    chatVisible: false,
    user: null,
    uid: null,
    activeChats: [],
    chatMessage: null,
    activeChatListener: null,
    chatMessagesListener: null,
  }

  componentDidMount() {
    this.chatBoxScrollToBottom()
    var activeChatListener = db.collection(ColType.supportDeskActive)
        .where("isActive", "==", true)
        .limit(30)
        .onSnapshot((querySnapshot) => {
          var activeChats = []
          querySnapshot.forEach((doc) => {
            var activeChat = flattenDoc(doc)
            activeChats.push(activeChat)
          })
          this.setState({
            activeChats: activeChats
          })
        })

    this.setState({
        activeChatListener: activeChatListener
    })
  }
  
  componentDidUpdate() {
    this.chatBoxScrollToBottom()
  }

  componentWillUnmount() {
    if (this.state.activeChatListener) {
      this.state.activeChatListener()
    }
    if (this.state.chatMessagesListener) {
      this.state.chatMessagesListener()
    }
  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!this.state.uid) return

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)

        this.setState({
            submitting: true,
        })

        var messageObj = {
          uid: this.state.uid,
          accessLevel: 'dotit',
          message: values.message,
          timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        }
        
        var activeChatRef = db.collection(ColType.supportDeskActive).doc(this.state.uid)
        db.runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(activeChatRef).then((doc) => {
              if (!doc.exists) {
                console.log("transaction start error", e)
                errorMessage("Chat was not received.")
                this.setState({
                    submitting: false,
                })
                return
              }
      
              var newNumResponses = doc.data().numResponses + 1
              transaction.update(activeChatRef, { 
                numResponses: newNumResponses,
                isActive: false,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
              })
          })
        })
        .then(() => {
            console.log("Chat transaction successfully committed!")
            db.collection(ColType.supportDesk)
              .add(messageObj)
              .then(() => {
                console.log("teacher support desk chat message success")
                successMessage("Chat received.")
                this.props.form.setFieldsValue({'message': ''})
                this.setState({
                    submitting: false,
                })
              })
              .catch((e) => {
                console.log("teacher support desk chat message error", e)
                errorMessage("Chat was not received.")
                this.setState({
                    submitting: false,
                })
              })
        }).catch((e) => {
            console.log("Chat transaction failed: ", e)
            errorMessage("Chat was not received.")
            this.setState({
                submitting: false,
            })
        })
      }
    })
  }

  chatBoxScrollToBottom = () => {
    if (!this.chatBox) return
    this.chatBox.scrollTop = this.chatBox.scrollHeight
  }

  showChat = () => {
    this.setState({
        chatVisible: true
    })
  }

  hideChat = () => {
    this.setState({
        chatVisible: false
    })
  }

  getUserChat = (uid) => {
    // unsub previous listener before getting the new one
    if (this.state.chatMessagesListener) {
      this.state.chatMessagesListener()
      this.setState({
        chatMessagesListener: null,
      })
    }

    var chatMessagesListener = db.collection(ColType.supportDesk)
    .where('uid', '==', uid)
    .orderBy('timeStamp', 'asc')
    .limit(30)
    .onSnapshot((querySnapshot) => {
      var chatMessages = []

      querySnapshot.forEach((doc) => {
        var chatMessage = flattenDoc(doc)
        chatMessages.push(chatMessage)
      })

      this.setState({
        chatMessages: chatMessages,
      })
    })

    this.setState({
      uid: uid,
      chatMessagesListener: chatMessagesListener,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
        <div>
        <SuperAdminHeader menuKey={'support-chat'} />
        <div className="mt-100 p-4">
          <Row>
            <Col span={6}>
              <div className="font-18 font-bold mb-2">Active Chats</div>
            {this.state.activeChats.map((activeChat, idx) => {
              return <div key={"active-chat-" + activeChat.id}
              className="font-16 p-2 grey-hover cursor-pointer"
                onClick={() => this.getUserChat(activeChat.uid)}>
                {activeChat.firstName + " " + activeChat.lastName}
              </div>
            })}
            </Col>
            <Col span={2} />
            <Col span={16}>
              {this.state.uid ?
            <div className="background-fff p-3 w-400 br-4 ant-shadow-more relative">
            <div>
                <div className="font-18 font-bold mb-2">Chat</div>
                <Button className="transparent-btn absolute-tr font-16"
                    style={{top: '8px', right: '16px'}}
                    onClick={() => this.hideChat()}>
                    <Icon type="close" />
                </Button>
                <div className="p-2 mb-2 br-4 overflow-y-auto" 
                    style={{height: '200px', background: '#ebf2f2'}}
                    ref={(ref) => this.chatBox = ref}>
                    {this.state.chatMessages && 
                     this.state.chatMessages.map((chatMessage, idx) => {
                         return <div key={'chatMessage-' + chatMessage.id}>
                              {chatMessage.accessLevel !== "dotit" ?
                              <div className="inline-block w-100">
                              <div className="inline-block mb-05 float-right">
                                <div className="p-2 font-16 br-4"
                                style={{background: '#d1e0e0'}}>
                                    {chatMessage.message}
                                </div>
                                <div className="font-13 mb-2">
                                      {chatMessage.hasOwnProperty('timeStamp') &&
                                       chatMessage.timeStamp &&
                                       chatMessage.timeStamp.hasOwnProperty('seconds') &&
                                       chatMessage.timeStamp.seconds ?
                                       moment(chatMessage.timeStamp.seconds * 1000)
                                        .format("hh:mm A - MM/DD/YYYY") : ''}
                                  </div>
                              </div>
                              </div>
                            :
                              <Row gutter={8}>
                                <Col span={3}>
                                  <div 
                                    className={"background-yellow br-50 " +
                                    "flex flex-v-center flex-h-center"}
                                    style={{width: '32px', height: '32px'}}>
                                    <span className="font-bold font-14 font-fff">
                                      D</span>
                                  </div>
                                </Col>
                                <Col span={21}>
                                  <div className="p-2 font-16 br-4 mb-05"
                                  style={{background: '#fcffff'}}>
                                      {chatMessage.message}
                                  </div>
                                  <div className="font-13 mb-2">
                                      {chatMessage.hasOwnProperty('timeStamp') &&
                                       chatMessage.timeStamp &&
                                       chatMessage.timeStamp.hasOwnProperty('seconds') &&
                                       chatMessage.timeStamp.seconds ?
                                       moment(chatMessage.timeStamp.seconds * 1000)
                                        .format("hh:mm A - MM/DD/YYYY") : ''}
                                  </div>
                                </Col>
                              </Row>
                            }
                        </div>
                     })}
                </div>
            </div>
            <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem {...formItemLayout}>
                    {getFieldDecorator('message', {
                    rules: [{ required: true, message: 'Please input a message.' }],
                    })(
                    <TextArea size={"large"} placeholder="Message..." rows={4} />
                    )}
                </FormItem>
                
                <FormItem className="mb-0">
                    <Button disabled={this.state.submitting} 
                    type="primary" 
                    htmlType="submit" 
                    className={"login-form-button text-align-center " +
                    "float-right font-bold-important"}
                    >
                     {this.state.submitting ? 'Sending...' : 'Chat'}
                    </Button>
                </FormItem>
            </Form>
            </div> : ''}
            </Col>
            </Row>
        </div>
        </div>
    )
  }
}

export default Form.create()(SuperAdminSupportChat)
