import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { Menu, Icon } from 'antd'
import CustomFooter from '../../login/CustomFooter'
import IEPDrafts from './IEPDrafts'

class DraftStepContainer extends Component {
    state = {
        selectedMenuKey: 'currentDraft',
    }

    componentDidMount() {

    }

    onMenuClick = (e) => {
        this.setState({
            selectedMenuKey: e.key,
        })
    }

    // if a selected draft is at the same step, change
    // back to currentDraft
    onNewDraftSelected = () => {
        this.setState({
            selectedMenuKey: 'currentDraft',
        })
    }

    render() {
        return (
            <div>
                <div className='flex w-100'>
                    <Menu
                        onClick={this.onMenuClick}
                        selectedKeys={[this.state.selectedMenuKey]}
                        mode="inline"
                        className='font-20 menu-vlg pt-40 h-100'
                        style={{ width: '256px', position: 'fixed', top: '85px', left: '0px', zIndex: '999' }}
                    >
                        <Menu.Item key="currentDraft">
                            <div className="font-20">
                                <Icon type="file-text" className="font-24" />
                                <span>Current Draft</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item key="savedDrafts">
                            <div className="font-20">
                                <Icon type="file" className="font-24" />
                                <span>
                                    {this.props.hasOwnProperty('isMTSS') && this.props.isMTSS ?
                                        'Saved Tier Plans' :
                                        'Saved IEP Drafts'
                                    }
                                </span>
                            </div>
                        </Menu.Item>
                    </Menu>
                    <div className="w-100" style={{ marginLeft: '256px' }}>
                        {this.props.hasOwnProperty('dataLoaded') &&
                            this.props.dataLoaded ?
                            <div className='w-800 m-lr-auto mt-40'>
                                {this.state.selectedMenuKey === 'currentDraft' ?
                                    <div>
                                        {window.location.href.indexOf('add-goal-student') === -1 ?
                                            <h3 className="mb-2 font-black">
                                                <Icon className='mr-1 font-green' type="check" />
                                                <span className='mr-1'>This draft's progress has been saved in</span>
                                                <span>
                                                    <Icon className='mr-05' type="file" />
                                                    <span>
                                                        {this.props.hasOwnProperty('isMTSS') && this.props.isMTSS ?
                                                            'Saved Tier Plans' :
                                                            'Saved IEP Drafts'
                                                        }
                                                    </span>
                                                </span>
                                                <span>.</span>
                                            </h3>
                                            : ''}
                                        {this.props.children}
                                    </div>
                                    :
                                    <IEPDrafts
                                        iepDrafts={this.props.hasOwnProperty('isMTSS') && this.props.isMTSS ?
                                            this.props.teacherBaseProps.pepDrafts :
                                            this.props.teacherBaseProps.iepDrafts}
                                        studentDict={this.props.teacherBaseProps.studentDict}
                                        isMTSS={this.props.hasOwnProperty('isMTSS') ? this.props.isMTSS : false}
                                        onNewDraftSelected={this.onNewDraftSelected}
                                    />
                                }
                                <div className='mt-50'>
                                    <CustomFooter />
                                </div>
                            </div>
                            : ''}
                    </div>


                </div>

            </div>
        )
    }
}

export default DraftStepContainer