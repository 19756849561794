import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { isReadOnlyTeacherId, changeIEPGoalsFolder, FOLDER_ARCHIVED, FOLDER_CURRENT, FOLDER_PERMANENTLY_DELETED, FOLDER_TRASHBIN } from '../../Util'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import { Button, Modal, notification, Icon, Input } from 'antd'

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class ChangeFolder extends Component {
    state = {
        visible: false,
        submitting: false,
        deleteInput: '',
    }

    componentDidMount() {

    }

    show = () => {
        this.setState({
            visible: true,
            deleteInput: '',
        })
    }

    hide = () => {
        this.setState({
            visible: false,
            deleteInput: '',
        })
    }

    onChangeFolder = () => {
        if (!(this.props.hasOwnProperty('student') && this.props.student &&
            this.props.hasOwnProperty('IEPGoals') && this.props.IEPGoals &&
            this.props.hasOwnProperty('folderTo') && this.props.folderTo &&
            this.props.hasOwnProperty('changeStudentFolderToo') && this.props.changeStudentFolderToo)) {
            return
        }

        this.setState({
            submitting: true,
        })

        // store this before the call as props will be updated if there are IEP listeners
        // and the new values will be in props.
        let allGoalsMoved = this.props.totalIEPGoals.length === this.props.IEPGoals.length
        // store goals here because listeners will create a new array on update
        let IEPGoals = this.props.IEPGoals

        if (this.props.onBeforeMove) {
            this.props.onBeforeMove(this.props.student)
        }

        changeIEPGoalsFolder(this.props.student,
            this.props.totalIEPGoals,
            this.props.IEPGoals,
            this.props.folderFrom,
            this.props.folderTo,
            this.props.isNotStudent)
            .then(() => {
                if (this.props.folderTo === FOLDER_ARCHIVED) {
                    if (this.props.isNotStudent) {
                        successMessage('The goal has been archived.')
                    } else {
                        successMessage('The student has been archived.')
                    }
                } else if (this.props.folderTo === FOLDER_CURRENT) {
                    if (this.props.isNotStudent) {
                        successMessage('The goal has been moved to your caseload.')
                    } else {
                        successMessage('The student has been moved to your caseload.')
                    }
                } else if (this.props.folderTo === FOLDER_TRASHBIN) {
                    if (this.props.isNotStudent) {
                        successMessage('The goal has been moved to the recycle bin.')
                    } else {
                        successMessage('The student has been moved to the reycle bin.')
                    }
                } else if (this.props.folderTo === FOLDER_PERMANENTLY_DELETED) {
                    if (this.props.isNotStudent) {
                        successMessage('The goal has been deleted.')
                    } else {
                        successMessage('The goals have been deleted.')
                    }
                }

                this.setState({
                    visible: false,
                    submitting: false,
                })

                // pass false as last param as the folder was changed successfully
                if (!allGoalsMoved) {
                    if (this.props.hasOwnProperty('onSingleGoalMoved') && this.props.onSingleGoalMoved) {
                        this.props.onSingleGoalMoved(this.props.student.id, false)
                    }
                } else {
                    if (this.props.hasOwnProperty('onAllGoalsMoved') && this.props.onAllGoalsMoved) {
                        this.props.onAllGoalsMoved(this.props.student.id, false)
                    }
                }

                // call on delete if necessary
                if (this.props.folderTo === FOLDER_TRASHBIN) {
                    if (this.props.hasOwnProperty('onDelete') && this.props.onDelete) {
                        // use IEPGoals stored in var above as listeners will overwrite
                        this.props.onDelete(this.props.student.id, IEPGoals)
                    }
                }
            })
            .catch((e) => {
                console.log('e', e)
                errorMessage('Could not archive student, please email support@dotit.app.')

                this.setState({
                    submitting: false,
                })

                // pass true for last param to let caller deal with an error state
                if (!allGoalsMoved) {
                    if (this.props.hasOwnProperty('onSingleGoalMoved') && this.props.onSingleGoalMoved) {
                        this.props.onSingleGoalMoved(this.props.student.id, true)
                    }
                } else {
                    if (this.props.hasOwnProperty('onAllGoalsMoved') && this.props.onAllGoalsMoved) {
                        this.props.onAllGoalsMoved(this.props.student.id, true)
                    }
                }
            })
    }

    onDeleteInputChange = (e) => {
        this.setState({
            deleteInput: e.target.value,
        })
    }

    render() {
        let actionIcon = "export"
        let buttonText = "Archive"
        let actionTitle = "Archive"
        let actionQuestion = "Do you want to archive this student?"

        if (this.props.folderTo === FOLDER_ARCHIVED) {
            actionIcon = "export"
            buttonText = "Archive student"
            actionTitle = "Archive student"
            actionQuestion = "Do you want to archive this student?"
            if (this.props.isNotStudent) {
                buttonText = "Archive goal"
                actionTitle = "Archive goal"
                actionQuestion = "Do you want to archive this goal?"
            }
        } else if (this.props.folderTo === FOLDER_CURRENT) {
            actionIcon = "export"
            buttonText = "Add student to caseload"
            actionTitle = "Add student to caseload"
            actionQuestion = "Do you want to add this student to your current caseload?"
            if (this.props.isNotStudent) {
                buttonText = "Add goal to caseload"
                actionTitle = "Add goal to caseload"
                actionQuestion = "Do you want to add this goal to your current caseload?"
            }
        } else if (this.props.folderTo === FOLDER_TRASHBIN) {
            actionIcon = "delete"
            buttonText = "Delete student"
            actionTitle = "Delete student"
            actionQuestion = "Do you want to move this student to the recycle bin? You can recover this student in the recycle bin at any time."
            if (this.props.isNotStudent) {
                buttonText = "Delete goal"
                actionTitle = "Delete goal"
                actionQuestion = "Do you want to move this goal to the recycle bin? You can recover this goal in the recycle bin at any time."
            }
        } else if (this.props.folderTo === FOLDER_PERMANENTLY_DELETED) {
            actionIcon = "delete"
            buttonText = "Delete goals below permanently"
            actionTitle = "Delete goals below permanently"
            actionQuestion = "Do you want to permanently delete all goals for this student in the recycle bin (shown below under \"goals\")? goals in your caseload or in archived will not be affected. This action cannot be undone. However, you can contact support (click the Need help? button above) to recover these goals."
            if (this.props.isNotStudent) {
                buttonText = "Delete goal permanently"
                actionTitle = "Delete goal permanently"
                actionQuestion = "Do you want to permanently delete this goal? This action cannot be undone. However, you can contact support (click the Need help? button above) to recover this goal."
            }
        } else {
            console.error('ChangeFolder.js: folderFrom or folderTo not handled', this.props.folderFrom, this.props.folderTo)
        }

        return (
            <div onClick={(e) => {
                // must do this so that the modal does not trigger a link click
                // which will cause a redirect
                e.preventDefault()
                e.stopPropagation()
            }}>
                <Button
                    size="large"
                    type="primary"
                    className="ant-btn ant-btn-lg font-bold pl-2 pr-2 paper-btn font-cyan-hover"
                    disabled={isReadOnlyTeacherId(this.props.teacherId)}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.show()
                    }}>
                    <Icon type={actionIcon} />
                    <span>{buttonText}</span>
                </Button>
                <Modal
                    title={null}
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.hide}
                >
                    {this.props.hasOwnProperty('student') && this.props.student ?
                        <PersonAvatar
                            person={this.props.student}
                            size={'large'}
                            containerClassName="font-20 mt-4"
                            avatarClassName="font-20 font-bold"
                            personClassName="font-bold"
                        /> : ''}
                    <div className="font-24 font-bold mb-2 mt-2">{actionTitle}</div>
                    <div className="mb-4 font-20">{actionQuestion}</div>

                    {this.props.folderTo === FOLDER_PERMANENTLY_DELETED ?
                        <div>
                            <div className="font-20 mb-05">Confirm by typing "delete":</div>
                            <Input
                                placeholder="delete"
                                size="large"
                                value={this.state.deleteInput}
                                onChange={this.onDeleteInputChange}
                            />
                        </div>
                        : ''}

                    <div className="flex flex-v-center mt-4">
                        <Button className="ant-btn lg-btn ml-auto mr-1"
                            disabled={false}
                            onClick={this.hide}
                        >
                            No
                        </Button>
                        <Button className="ant-btn ant-btn-primary lg-btn"
                            disabled={!this.state.visible || (
                                this.props.folderTo === FOLDER_PERMANENTLY_DELETED &&
                                this.state.deleteInput !== 'delete'
                            )}
                            onClick={this.onChangeFolder}
                        >
                            Yes
                        </Button>
                    </div>
                </Modal>


            </div>
        )
    }
}

export default ChangeFolder