import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, getQueryStringParam, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { Icon, Form, Input, Button,  message } from 'antd'
const FormItem = Form.Item

const successMessage = (description) => {
    message.success(description)
}

const errorMessage = (description) => {
    message.error(description)
}

// Reset password on initial verification. This is the page the user gets
// redirected to automatically after clicking on the email to verify their
// account. Need to sign them in => change their initial password.
class VerifyEmail extends Component {
  state = {
    user: {},
    email: '',
    initialPassword: '',
    submitting: false,
  }

  componentDidMount() {
    console.log("verify email mounted")
    var email = getQueryStringParam('email')
    var password = getQueryStringParam('token')

    //antd wants you to set known form values this way.
    this.props.form.setFieldsValue({
        email: email,
     })

    this.setState({
        initialPassword: password,
        email: email,
    }, () => {
        // sign them in with their email and initial password so they can reset it later.
        firebase.auth().signInWithEmailAndPassword(email, password)
            .catch((error) => {
                // sign in error
                var errorCode = error.code
                var errorMessage = error.message
                console.log("Could not sign in.", errorCode, errorMessage)
                // send to sign-in if they have already set their password
                this.props.history.push(
                  {
                    pathname: '/sign-in'
                  }
                )
            })
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        
        var user = firebase.auth().currentUser
        if (user === null) {
          errorMessage("User is null.")
          return
        }

        if (values.password !== values.confirmPassword) {
          errorMessage("Passwords do not match, please try again.")
          return
        }
        
        if (values.password.length < 6 || values.confirmPassword.length < 6) {
          errorMessage("Password must be at least 6 characters.")
          return
        }

        this.setState({
          submitting: true
        })

        // form successfully completed, update their password to the new password.
        user.updatePassword(values.password).then(() => {
            // Update successful.
            console.log("User password updated successfully.")
            successMessage("User password has been set successfully.")
            // get the user's job and link to the appropriate page.
            db.collection(ColType.users)
            .doc(user.uid)
            .get()
            .then((doc) => {
              var userInfo = flattenDoc(doc)
              console.log("userinfo", userInfo)
              db.collection(userInfo.accessLevel)
                .doc(userInfo.refId)
                .get()
                .then((doc) => {
                  var userJobInfo = flattenDoc(doc)
                  console.log(userInfo, userJobInfo)
                  linkAfterLogin(userInfo, userJobInfo)
                })
                .catch((e) => {
                  errorMessage("Something went wrong, please try " +
                  "again or contact support@dotit.app")
                  this.setState({
                    submitting: false
                  })
                })
            })
            .catch((e) => {
              errorMessage("Something went wrong, please try " +
              "again or contact support@dotit.app")
              this.setState({
                submitting: false
              })
            })
        }).catch((error) => {
            // An error happened.
            console.log("User password could not be updated.", error)
            errorMessage("User password could not be set.")
            this.setState({
              submitting: false
            })
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
        <div className="w-500 m-lr-auto pt-50">
            <div className="block w-100 m-lr-auto mb-4 text-center">
              <img src='/dot-it-logo.png' alt='logo' height='64' />
            </div>
            <div className="mb-4 pb-1 text-center font-25 font-bold">
                  Set your password</div>
                <div className="g-col inline-block w-100 padding-25">
                  <Form onSubmit={this.handleSubmit} className="login-form">
                    <FormItem {...formItemLayout} label="Email address">
                      {getFieldDecorator('email', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Input 
                          disabled={true} 
                          size={"large"} 
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                        placeholder="Email address..." />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Password">
                      {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input a password.' }],
                      })(
                        <Input 
                          size={"large"} 
                          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                          type="password" 
                          placeholder="Password" />
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Confirm password">
                      {getFieldDecorator('confirmPassword', {
                        rules: [{ required: true, message: 'Please input a password.' }],
                      })(
                        <Input 
                          size={"large"} 
                          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                          type="password" 
                          placeholder="Confirm password..." />
                      )}
                    </FormItem>
                    <FormItem className="mb-0 mt-3">
                      <Button 
                        size={"large"} 
                        disabled={this.state.submitting}
                        type="primary" 
                        htmlType="submit" 
                        className="login-form-button text-align-center w-100 font-bold-important"
                      >
                      {!this.state.submitting ?
                        <span>Confirm</span> :
                        <span ><Icon type="loading" className="mr-8"/>
                          Setting password...</span>
                      }
                      </Button>
                    </FormItem>
                </Form>
              </div>
            </div>
    )
  }
}

export default Form.create()(VerifyEmail)