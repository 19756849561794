import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import { flattenDoc, linkAfterLogin } from '../Util'
import ColType from '../Types'
import { message, Form, Input, Button, Icon } from 'antd'
import CustomFooter from './CustomFooter'
const FormItem = Form.Item

const errorMessage = (description) => {
    message.error(description)
}

class DemoSignInClever extends Component {
    state = {
        cleverCode: '',
        cleverScope: null,
        cleverState: null,
        userRole: '',
        user: null,
        signingIn: false,
        cleverSigningIn: false,
        hideEmail: false,
        cleverCodeError: false,
        userNotFoundError: false,
        userArchivedError: false,
        authListener: null,
    }

    componentDidMount() {
        document.title = "Sign in - Dot It"

        const authListener = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    user: user,
                })
            } else {
                this.setState({
                    user: user,
                })
            }
        })

        this.setState({
            authListener: authListener,
        })
    }

    componentWillUnmount() {
        if (this.state.authListener) {
            this.state.authListener()
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            message.destroy()
            if (!err) {
                this.setState({
                    signingIn: true,
                }, () => {
                    firebase.auth().signInWithEmailAndPassword(values.email, values.password)
                        .then(() => {
                            this.setState({
                                signingIn: false,
                            })
                        })
                        .catch((error) => {
                            var errorCode = error.code
                            var errorMessageString = error.message
                            if (errorMessageString === 'There ' +
                                'is no user record corresponding to this identifier. ' +
                                'The user may have been deleted.') {
                                errorMessageString = 'This account does not exist.'
                            }
                            else if (errorMessageString === 'The password is invalid ' +
                                'or the user does not have a password.') {
                                errorMessageString = 'Email address or password is incorrect.'
                            }
                            errorMessage(errorMessageString)
                            this.setState({
                                signingIn: false,
                            })
                        })
                })
            }
        })
    }

    cleverSignInWithCodeTest = async (role) => {
        if (!(role === 'admin' || role === 'coach' || role === 'teacher' || role === 'student')) {
            return
        }

        this.setState({
            cleverSigningIn: true,
        })

        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            let headers = new Headers()
            headers.append('Authorization', 'Bearer ' + idToken)
            headers.append('Content-Type', 'application/json')

            fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/clever-create-token-from-id", {
                method: "POST",
                body: JSON.stringify({
                    demoRole: role,
                }),
                mode: 'cors',
                headers: headers,
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Response status: ' + res.status)
                    }
                    return res.json()
                })
                .then(data => {
                    firebase.auth().signInWithCustomToken(data.token)
                        .then((userObj) => {
                            const user = userObj.user
                            db.collection(ColType.users)
                                .doc(user.uid)
                                .get()
                                .then((doc) => {
                                    var userInfo = flattenDoc(doc)
                                    if (userInfo.accessLevel === 'superAdmin') {
                                        window.location.href = '/super-admin/add-initial-admin'
                                        return
                                    }
                                    else {
                                        db.collection(userInfo.accessLevel)
                                            .doc(userInfo.refId)
                                            .get()
                                            .then((doc) => {
                                                var userJobInfo = flattenDoc(doc)
                                                if (userInfo.accessLevel === "teachers" && this.state.cleverState) {
                                                    window.location.href = this.state.cleverState
                                                } else {
                                                    linkAfterLogin(userInfo, userJobInfo)
                                                }
                                            })
                                    }
                                }).catch((error) => {
                                    console.log('get user info error', error)
                                    this.setState({
                                        cleverSigningIn: false,
                                    })
                                })
                        })
                        .catch((error) => {
                            console.log('student sign in error', error)
                            this.setState({
                                cleverSigningIn: false,
                            })
                        })
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({
                        cleverSigningIn: false,
                    })
                })
        }).catch((error) => {
            console.log('error', error)
            this.setState({
                cleverSigningIn: false,
            })
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 25 },
                sm: { span: 0 },
            },
            wrapperCol: {
                xs: { span: 25 },
                sm: { span: 24 },
            },
        }

        return (
            <div className="pt-4 pb-70 relative" style={{ minHeight: '100%' }}>
                <div className='absolute-tl w-100 h-100'>
                </div>

                <div className='relative'>
                    <div className="text-center mb-3">
                        <a href="https://dotit.app/">
                            <img src='/dotitlogo.png' alt='logo' height='64' />
                        </a>
                    </div>
                    {this.state.user ?
                        <div>
                            <Button className="text-left block lg-btn font-20 mb-2 p-4 w-500 m-lr-auto br-20 ant-shadow background-fff"
                                disabled={this.state.cleverSigningIn}
                                onClick={() => {
                                    this.cleverSignInWithCodeTest('admin')

                                }}
                            >
                                Demo District Administrator
                            </Button>
                            <Button className="text-left block lg-btn font-20 mb-2 p-4 w-500 m-lr-auto br-20 ant-shadow background-fff"
                                disabled={this.state.cleverSigningIn}
                                onClick={() => {
                                    this.cleverSignInWithCodeTest('coach')
                                }}
                            >
                                Demo Coach
                            </Button>
                            <Button className="text-left block lg-btn font-20 mb-2 p-4 w-500 m-lr-auto br-20 ant-shadow background-fff"
                                disabled={this.state.cleverSigningIn}
                                onClick={() => {
                                    this.cleverSignInWithCodeTest('teacher')
                                }}
                            >
                                Demo Teacher
                            </Button>
                            <Button className="text-left block lg-btn font-20 mb-2 p-4 w-500 m-lr-auto br-20 ant-shadow background-fff"
                                disabled={this.state.cleverSigningIn}
                                onClick={() => {
                                    this.cleverSignInWithCodeTest('student')
                                }}
                            >
                                Demo Student
                            </Button>

                            <Button type='primary' className='lg-btn mb-2 w-500 m-lr-auto mt-4'
                                onClick={() => {
                                    firebase.auth().signOut().then(() => {
                                        console.log("User was signed out successfully")
                                    }).catch((error) => {
                                        console.log("Could not sign out user", error)
                                    })
                                }}
                            >
                                Sign Out
                            </Button>
                        </div>
                        :
                        <div className="p-4 w-500 m-lr-auto br-20 ant-shadow background-fff">
                            <div className="block w-100 m-lr-auto mb-1 text-center">
                                <img src="/dot-it-sign-in.png" alt="sign-in" height="300" />
                            </div>
                            <div className="mb-3 text-center pt-3 font-30 font-bold">Sign in to Dot It</div>
                            <Form onSubmit={this.handleSubmit} className="login-form">
                                <FormItem {...formItemLayout} label="Email address">
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: 'Please input your email address.' }],
                                    })(
                                        <Input
                                            size={"large"}
                                            type={this.state.hideEmail ? 'password' : null}
                                            placeholder="Email Address..." />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Please input a password.' }],
                                    })(
                                        <Input
                                            size={"large"}
                                            type="password"
                                            placeholder="Password..." />
                                    )}
                                </FormItem>
                                <FormItem className="mb-0">
                                    <Button
                                        disabled={this.state.signingIn}
                                        size={"large"}
                                        type="primary"
                                        htmlType="submit"
                                        className="full-width-btn mt-05">
                                        {!this.state.signingIn ?
                                            <span className="font-bold">Sign in</span> :
                                            <span className="font-bold">
                                                <Icon type="loading" className="mr-8" />Signing in...</span>
                                        }
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>}
                    <CustomFooter
                        theme='dark'
                    />
                </div>
            </div>

        )
    }
}

export default Form.create()(DemoSignInClever)