import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
//import { flattenDoc } from '../Util'
import ColType from '../Types'
import { Button, Icon, notification, Layout } from 'antd'
import Empty from '../customcomponents/Empty'
const moment = require('moment')
const Content = Layout.Content

class WorkshopObservationTool extends Component {
    state = {
        workshopObservations: null,
        workshopListener: null,
        creatingWorkshopObservation: false,
        dataRetrieved: false
    }

    componentDidMount() {
    }


    hasNecessaryProps = () => {
        return this.props.districtId && this.props.schoolId && this.props.authorId && this.props.authorType
    }

    getDocumentURL = (documentId) => {
        if (!this.props.authorType) {
            return ''
        }
        let userRoute = ''
        if (this.props.authorType === ColType.schoolAdmin) {
            userRoute = 'school-admin'
        } else if (this.props.authorType === ColType.instructionalFacilitator) {
            userRoute = 'instructional-facilitator'
        } else {
            console.log('authorType not found', this.props.authorType)
            return ''
        }

        return `/${userRoute}/${userRoute}-workshop-observation-tool/document/${this.props.authorId}?document=${documentId}`
    }

    createWorkshopObservation = () => {
        if (!this.hasNecessaryProps()) {
            console.log('missing props')
            return
        }

        this.setState({
            creatingWorkshopObservation: true
        })

        db.collection(ColType.workshopObservationTool).add({
            districtId: this.props.districtId,
            schoolId: this.props.schoolId,
            authorId: this.props.authorId,
            authorType: this.props.authorType,
            formData: {},
            lastSave: firebase.firestore.FieldValue.serverTimestamp(),
            timeStamp: firebase.firestore.FieldValue.serverTimestamp()
        }).then(docRef => {
            notification.open({
                type: 'success',
                duration: 4,
                message: 'Workshop observation created successfully',
                placement: 'bottomRight'
            })
            let docRoute = this.getDocumentURL(docRef.id)
            if (!docRoute) {
                return
            }
            this.props.history.push(docRoute)
        }).catch(error => {
            console.log('error', error)
            this.setState({
                creatingWorkshopObservation: false
            })
            notification.open({
                type: 'error',
                duration: 4,
                message: 'An error occurred, please try again.',
                placement: 'bottomRight'
            })
        })
    }

    render() {
        //console.log(this.state, this.props)
        return (
            <div>
                <div>
                    <Layout className="content relative layout-header-mt">
                        <Layout>
                            <Content className='mt-4 sub-menu-width m-lr-auto flex w-100'>
                                <div className='w-100'>
                                    <div className='flex flex-v-center w-100 mb-2'>
                                        <div className='font-30 font-bold'>Workshop Observation Tool</div>
                                        <Button type="primary"
                                            className='ant-btn ant-btn-primary lg-btn ml-auto'
                                            disabled={this.state.creatingWorkshopObservation}
                                            onClick={this.createWorkshopObservation}>
                                            <Icon type='plus' />
                                            Create Workshop Observation
                                        </Button>
                                    </div>
                                    <div>
                                        {this.props.workshopObservationBaseProps &&
                                            this.props.workshopObservationBaseProps.workshopObservations &&
                                            this.props.workshopObservationBaseProps.workshopObservations.length === 0 ?
                                            <div className="mt-4 font-16 w-500 m-lr-auto">
                                                <Empty
                                                    containerClassName="mt-4 pt-2 text-center"
                                                    width={55}
                                                    height={55}
                                                    image={'/empty/progress.png'}
                                                    description={'There are currently no workshop observations. Click the "Create Workshop Observation" button to get started!'}
                                                />
                                            </div>
                                            : ''}
                                        {this.props.workshopObservationBaseProps.workshopObservations &&
                                            this.props.workshopObservationBaseProps.workshopObservations.map(workshopDocument => {
                                                const docRoute = this.getDocumentURL(workshopDocument.id)
                                                return <Link key={workshopDocument.id}
                                                    to={docRoute ? docRoute : '#'}
                                                    className={'p-3 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-black font-20'}
                                                >
                                                    <div>{workshopDocument.formData.hasOwnProperty('school') &&
                                                        this.props.workshopObservationBaseProps.schoolsDict &&
                                                        this.props.workshopObservationBaseProps.schoolsDict[workshopDocument.formData.school] ?
                                                        this.props.workshopObservationBaseProps.schoolsDict[workshopDocument.formData.school].schoolName
                                                        :
                                                        'No school selected'}
                                                    </div>
                                                    <div className='ml-auto font-16'>
                                                        {(workshopDocument && workshopDocument.lastSave &&
                                                            workshopDocument.lastSave.seconds ? ' (Last saved on: ' +
                                                            moment(workshopDocument.lastSave.seconds * 1000).format("MM/DD/YYYY - h:mm A") + ')' : '')}
                                                    </div>
                                                </Link>
                                            })}
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </div>
        )
    }
}

export default WorkshopObservationTool