import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import CustomFooter from '../login/CustomFooter'
import Empty from '../customcomponents/Empty'
import ReactToPrint from "react-to-print"
import ColType from '../Types'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc } from '../Util'
import { Icon, Radio, Spin, Switch, Button, Collapse, notification, Popconfirm } from 'antd'
var moment = require('moment')
var html2pdf = null
if (typeof window !== 'undefined') {
    html2pdf = require('html2pdf.js')
}

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class SAMScoring extends Component {
    state = {
        scoreOptions: [0, 1, 2, 3],
        SAMScores: [],
        editMode: {},
        retrieved: false,
        downloading: false,
        addingSAMReport: false,
        samScoresMounted: false,
        activeKey: '',
        listener: null,
    }

    componentDidMount() {
        this.getSAMScore()
    }

    componentWillReceiveProps(nextProps) {
        this.getSAMScore()
    }

    getSAMScore = () => {
        if (!this.props.districtId) {
            return
        }
        if (!this.props.schoolId) {
            return
        }
        if (this.state.retrieved) {
            return
        }

        this.setState({ retrieved: true })

        let listener = db.collection(ColType.SAMScore)
            .where("schoolId", "==", this.props.schoolId)
            .where("districtId", "==", this.props.districtId)
            .where("deleted", "==", false)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let SAMScores = []
                querySnapshot.forEach(doc => {
                    SAMScores.push(flattenDoc(doc))
                })
                this.setState({ SAMScores: SAMScores, samScoresMounted: true })
            })

        this.setState({
            listener: listener,
        })
    }

    componentWillUnmount() {
        if (this.state.listener) {
            this.state.listener()
        }
    }

    onScoreChange = (SAMScore, index, score) => {
        if (!this.props.districtId) {
            return
        }
        if (!this.props.schoolId) {
            return
        }
        if (!SAMScore.id) {
            return
        }

        SAMScore.items[index].score = score
        let categoryStats = this.getCategoryStats(SAMScore)

        db.collection(ColType.SAMScore)
            .doc(SAMScore.id)
            .update({
                items: SAMScore.items,
                category1Pct: categoryStats.category1Pct,
                category2Pct: categoryStats.category2Pct,
                category3Pct: categoryStats.category3Pct,
                category4Pct: categoryStats.category4Pct,
                category5Pct: categoryStats.category5Pct,
                category6Pct: categoryStats.category6Pct,
                schoolId: this.props.schoolId,
                districtId: this.props.districtId,
            })
    }

    getCategoryStats = (SAMScore) => {
        let category1Stats = 0
        let category2Stats = 0
        let category3Stats = 0
        let category4Stats = 0
        let category5Stats = 0
        let category6Stats = 0
        let SAMIdx = 0

        SAMScore.items.forEach((item, index) => {
            if (item.header) {
                return false
            }
            let score = parseInt(item.score, 10)
            if (SAMIdx < 5) {
                category1Stats += score
            } else if (SAMIdx < 16) {
                category2Stats += score
            } else if (SAMIdx < 20) {
                category3Stats += score
            } else if (SAMIdx < 28) {
                category4Stats += score
            } else if (SAMIdx < 35) {
                category5Stats += score
            } else if (SAMIdx < 42) {
                category6Stats += score
            }
            // don't count headers
            SAMIdx += 1
        })

        let values = {
            category1Pct: Math.round((category1Stats / this.getMaxScore(5)) * 100),
            category2Pct: Math.round((category2Stats / this.getMaxScore(11)) * 100),
            category3Pct: Math.round((category3Stats / this.getMaxScore(4)) * 100),
            category4Pct: Math.round((category4Stats / this.getMaxScore(8)) * 100),
            category5Pct: Math.round((category5Stats / this.getMaxScore(7)) * 100),
            category6Pct: Math.round((category6Stats / this.getMaxScore(7)) * 100),
        }

        return values
    }

    getMaxScore = (num) => {
        return num * 3
    }

    createSAMScore = (districtId, schoolId) => {
        return {
            items: [
                { header: true, title: "1. Leadership Domain (Items 1-5)", score: 0 },
                { header: false, title: "1. The principal is actively involved", score: 0 },
                { header: false, title: "2. A leadership team is established", score: 0 },
                { header: false, title: "3. The leadership team actively engages in ongoing professional development", score: 0 },
                { header: false, title: "4. A strategic plan for MTSS implementation is developed", score: 0 },
                { header: false, title: "5. The leadership team is actively facilitating implementation", score: 0 },
                { header: true, title: "2. Building the Capacity/Infrastructure for Implementation Domain (Items 6-16)", score: 0 },
                { header: false, title: "6. The critical elements of MTSS are defined and understood", score: 0 },
                { header: false, title: "7. Professional development and coaching provided to staff", score: 0 },
                { header: false, title: "8. The leadership team facilitates PD on data-based problem-solving", score: 0 },
                { header: false, title: "9. The leadership team facilitates PD on multi-tiered instruction and intervention", score: 0 },
                { header: false, title: "10. Coaching is used to support MTSS implementation", score: 0 },
                { header: false, title: "11. Schedules provide adequate time for training and coaching", score: 0 },
                { header: false, title: "12. Schedules provide adequate time to administer assessments", score: 0 },
                { header: false, title: "13. Schedules provide adequate time for multiple tiers of instruction/interventions", score: 0 },
                { header: false, title: "14. Schedules provide adequate time for data-based problem-solving", score: 0 },
                { header: false, title: "15. Processes, procedures, and decision-rules are established for DBPS", score: 0 },
                { header: false, title: "16. Resources to support MTSS implementation are identified and allocated", score: 0 },
                { header: true, title: "3. Communication and Collaboration Domain (Items 17-20)", score: 0 },
                { header: false, title: "17. Staff have consensus and engage in MTSS Implementation", score: 0 },
                { header: false, title: "18. Staff are provided data on MTSS fidelity and student outcomes", score: 0 },
                { header: false, title: "19. The infrastructure exists to support family and community engagement", score: 0 },
                { header: false, title: "20. Educators actively engage families in MTSS", score: 0 },
                { header: true, title: "4. Data-Based Problem-Solving Domain (Items 21-28)", score: 0 },
                { header: false, title: "21. DBPS for student outcomes occurs across content areas, grade levels, and tiers", score: 0 },
                { header: false, title: "22. Across tiers, data used to identify “gap” between expected and current outcomes", score: 0 },
                { header: false, title: "23. Data are used to identify reasons why students are not meeting expectations", score: 0 },
                { header: false, title: "24. Plans based on verified reasons why students are not meeting expectations", score: 0 },
                { header: false, title: "25. Student progress specific to academic or behavior goals are monitored", score: 0 },
                { header: false, title: "26. Data-based problem solving is part of a student's full and individual evaluation", score: 0 },
                { header: false, title: "27. Data are used to address performance across diverse group", score: 0 },
                { header: false, title: "28. Resources for implementation of MTSS are addressed through data-based problem-solving", score: 0 },
                { header: true, title: "5. Three Tiered Instructional /Intervention Model Domain (Items 29-35)", score: 0 },
                { header: false, title: "29. Instruction at all tiers is accessible and responsive for all students", score: 0 },
                { header: false, title: "30. Tier 1 academic practices clearly identify learning standards", score: 0 },
                { header: false, title: "31. Tier 1 behavior practices identify school-wide expectations", score: 0 },
                { header: false, title: "32. Tier 2 academic practices include common student needs, are linked to Tier 1", score: 0 },
                { header: false, title: "33. Tier 2 behavior practices include common student needs, are linked to Tier 1", score: 0 },
                { header: false, title: "34. Tier 3 academic practices are based on students' needs, aligned with Tier 1 and Tier 2", score: 0 },
                { header: false, title: "35. Tier 3 behavior practices are based on students' needs, aligned with Tier 1 and Tier 2", score: 0 },
                { header: true, title: "6. Data-Evaluations Domain (Items 36-42)", score: 0 },
                { header: false, title: "36. Staff understand and have access to data sources", score: 0 },
                { header: false, title: "37. Data systems enable educators to engage in data-based problem solving for equity", score: 0 },
                { header: false, title: "38. Policies and procedures for decision-making are established", score: 0 },
                { header: false, title: "39. Effective data tools are used appropriately and independently by staff", score: 0 },
                { header: false, title: "40. Data sources are used to evaluate the fidelity and impact", score: 0 },
                { header: false, title: "41. Available resources are allocated effectively", score: 0 },
                { header: false, title: "42. Data sources are monitored for consistency and accuracy", score: 0 },
            ],
            category1Pct: 0,
            category2Pct: 0,
            category3Pct: 0,
            category4Pct: 0,
            category5Pct: 0,
            category6Pct: 0,
            deleted: false,
            districtId: districtId,
            schoolId: schoolId,
            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        }
    }

    deleteSAMScore = (SAMScoreId) => {
        db.collection(ColType.SAMScore).doc(SAMScoreId).update({ 'deleted': true }).then(() => {
            this.setState({
                SAMScores: this.state.SAMScores.filter(SAMScore => SAMScore.id !== SAMScoreId)
            })
            successMessage('Report deleted successfully.')
        }).catch((error) => {
            console.log(error)
            errorMessage('Error deleting report, please try again.')
        })
    }

    addSAMScore = () => {
        if (!(this.props.districtId && this.props.schoolId)) {
            return
        }

        this.setState({
            addingSAMReport: true
        })

        db.collection(ColType.SAMScore)
            .add(this.createSAMScore(this.props.districtId, this.props.schoolId))
            .then((docRef) => {
                successMessage('Report added successfully.')
                let editMode = this.state.editMode
                editMode[docRef.id] = true
                this.setState({
                    addingSAMReport: false,
                    editMode: editMode,
                    activeKey: 'panel-' + docRef.id
                })
            })
            .catch((error) => {
                console.log(error)
                errorMessage('Error adding report, please try again.')
                this.setState({
                    addingSAMReport: false
                })
            })
    }

    inEditMode = (SAMScoreId) => {
        return this.state.editMode.hasOwnProperty(SAMScoreId) && this.state.editMode[SAMScoreId]
    }

    onCollapseChange = (key) => {
        this.setState({
            activeKey: key
        })
    }

    render() {
        return (
            <div>
                <div className='w-100 flex flex-v-center mb-2'>
                    <div className='font-30 font-bold'>SAM Reports</div>
                    <Button type="primary" className="lg-btn ml-auto"
                        onClick={this.addSAMScore}
                        disabled={this.addingSAMReport}
                    >
                        <Icon type="plus" /> Add SAM Report
                    </Button>
                </div>
                {this.state.samScoresMounted ?
                    this.state.SAMScores.length === 0 ?
                        <Empty
                            containerClassName="mt-4 text-center"
                            width={55}
                            height={55}
                            image={'/empty/goals.png'}
                            description={'There are currently no SAM reports.'}
                        />
                        :
                        <Collapse activeKey={this.state.activeKey} bordered={false} accordion={true}
                            onChange={this.onCollapseChange}>
                            {this.state.SAMScores.map((SAMScore, index) => {
                                let reportTitle = 'SAM Report'
                                let contentRefName = 'content-' + SAMScore.id
                                return <Collapse.Panel
                                    header={<div className='font-20 w-100 flex flex-v-center'>
                                        <div className='ellipsis' style={{ maxWidth: '400px' }}>{reportTitle}</div>

                                        <div className='ml-auto flex flex-v-center'>
                                            <div className='font-16'>{
                                                (SAMScore.hasOwnProperty('timeStamp') &&
                                                    SAMScore.timeStamp &&
                                                    SAMScore.timeStamp.hasOwnProperty('seconds') &&
                                                    SAMScore.timeStamp.seconds ?
                                                    moment.utc(SAMScore.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                                    :
                                                    '')}
                                            </div>
                                        </div></div>} key={'panel-' + SAMScore.id}>
                                    <div>
                                        <div className='mt-3 flex flex-v-center w-100 ml-auto'>
                                            <div className='ml-auto flex'>
                                                <ReactToPrint
                                                    trigger={() =>
                                                        <Button
                                                            size={'large'}
                                                            type="default"
                                                            className="font-20 flex flex-v-center mb-2"
                                                            style={{
                                                                padding: '16px',
                                                                height: '44px'
                                                            }}
                                                            disabled={this.state.downloading}
                                                        >
                                                            <Icon type="download" className="mr-1" />
                                                            {this.state.downloading ? 'Downloading...' : 'Download'}
                                                        </Button>
                                                    }
                                                    content={() => this[contentRefName]}
                                                    onBeforeGetContent={async () => {
                                                        return new Promise((resolve, reject) => {
                                                            this.setState({
                                                                downloading: true,
                                                            }, () => {
                                                                resolve()
                                                            })
                                                        })
                                                    }}
                                                    print={async (printIframe) => {
                                                        if (!html2pdf) {
                                                            this.setState({
                                                                downloading: false,
                                                            })
                                                            return new Promise((resolve, reject) => { resolve() })
                                                        }

                                                        const document = printIframe.contentDocument
                                                        if (document) {
                                                            const html = document.getElementsByTagName('html')[0]
                                                            await html2pdf().from(html).set({
                                                                image: { type: 'png', quality: 1.0 },
                                                                html2canvas: { scale: 1.0 },
                                                                margin: 12,
                                                                pagebreak: { mode: ['avoid-all', 'css'], avoid: '.page-break-inside-avoid-not-important' },
                                                            }).save('SAMReport.pdf')
                                                        }

                                                        this.setState({
                                                            downloading: false,
                                                        })


                                                        return new Promise((resolve, reject) => { resolve() })
                                                    }}
                                                />
                                                <Popconfirm
                                                    title="Are you sure you want to delete this report?"
                                                    onConfirm={() => {
                                                        this.deleteSAMScore(SAMScore.id)
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="danger"
                                                        className="br-50 flex flex-v-center flex-h-center font-20 ml-2"
                                                        style={{
                                                            padding: '16px',
                                                            width: '44px',
                                                            height: '44px'
                                                        }}
                                                    >
                                                        <Icon type="delete" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                        <div ref={el => (this[contentRefName] = el)} className='font-black'>
                                            <div className='font-24 font-bold'>
                                                {this.props.schoolName}
                                            </div>
                                            <div className='font-24'>
                                                <div>Self-Assessment of MTSS Implementation (SAM)</div>
                                                <div className='font-20 mb-2'>
                                                    {(SAMScore.hasOwnProperty('timeStamp') &&
                                                        SAMScore.timeStamp &&
                                                        SAMScore.timeStamp.hasOwnProperty('seconds') &&
                                                        SAMScore.timeStamp.seconds ?
                                                        moment.utc(SAMScore.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                                        :
                                                        '')}
                                                </div>
                                            </div>
                                            <div className='font-20'>Rate each item on a scale from 0-3</div>
                                            <ul className='font-20 mb-2'>
                                                <li>0 = Not Started</li>
                                                <li>1 = Emerging/Developing</li>
                                                <li>2 = Operationalizing</li>
                                                <li>3 = Optimizing</li>
                                            </ul>
                                            {this.state.loading ?
                                                <div className={"flex flex-h-center" +
                                                    " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                                    <div>
                                                        <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    {!this.state.downloading ?
                                                        <div className='flex flex-v-center w-100 font-20 mb-2'>
                                                            <div className='inline-flex flex-v-center mr-2 ml-auto'>
                                                                <Icon type='edit' className='mr-1' />
                                                                <span>Edit</span>
                                                            </div>
                                                            <div className='inline-flex'>
                                                                <Switch size='large' checked={this.inEditMode(SAMScore.id)} onChange={(checked) => {
                                                                    let editMode = this.state.editMode
                                                                    if (checked) {
                                                                        editMode[SAMScore.id] = true
                                                                    } else {
                                                                        delete editMode[SAMScore.id]
                                                                    }
                                                                    this.setState({ editMode: editMode })
                                                                }} />
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    {SAMScore.items.map((item, index) => {
                                                        let itemCategoryPct = 100.0
                                                        if (item.header) {
                                                            if (index === 0) {
                                                                itemCategoryPct = SAMScore.category1Pct
                                                            } else if (index === 6) {
                                                                itemCategoryPct = SAMScore.category2Pct
                                                            } else if (index === 18) {
                                                                itemCategoryPct = SAMScore.category3Pct
                                                            } else if (index === 23) {
                                                                itemCategoryPct = SAMScore.category4Pct
                                                            } else if (index === 32) {
                                                                itemCategoryPct = SAMScore.category5Pct
                                                            } else if (index === 40) {
                                                                itemCategoryPct = SAMScore.category6Pct
                                                            }
                                                        }
                                                        return <div key={'panel-container-' + index} className='font-20'>
                                                            {item.header ?
                                                                <div className={"font-20 p-2 br-4 mb-2 flex w-100" +
                                                                    (index !== 0 ? ' mt-1' : '')} style={{ background: 'rgb(181, 245, 236)' }}>
                                                                    <div className='inline-flex mr-4'>{item.title}</div>
                                                                    <div className='ml-auto inline-flex'>{itemCategoryPct + '%'}</div>
                                                                </div>
                                                                :
                                                                <div className='p-1'>
                                                                    <div className='flex'>
                                                                        <div className='inline-flex mr-2'>{item.title}</div>
                                                                        {this.inEditMode(SAMScore.id) ?
                                                                            <Radio.Group
                                                                                defaultValue={'3'}
                                                                                optionType="button"
                                                                                buttonStyle="solid"
                                                                                size='large'
                                                                                className='ml-auto inline-flex'
                                                                                value={item.score}
                                                                                onChange={(e) => { this.onScoreChange(SAMScore, index, e.target.value) }}
                                                                            >
                                                                                <Radio.Button value={0}>0</Radio.Button>
                                                                                <Radio.Button value={1}>1</Radio.Button>
                                                                                <Radio.Button value={2}>2</Radio.Button>
                                                                                <Radio.Button value={3}>3</Radio.Button>
                                                                            </Radio.Group>
                                                                            :
                                                                            <div className='inline-flex ml-auto'>
                                                                                {this.state.scoreOptions.map((score, idx) => {
                                                                                    let styleObj = {
                                                                                        height: '40px', fontSize: '16px',
                                                                                        'lineHeight': '38px', padding: '8px',
                                                                                        borderLeft: '1px solid #ccc',
                                                                                        borderTop: '1px solid #ccc',
                                                                                        borderBottom: '1px solid #ccc',
                                                                                        paddingLeft: '15px', paddingRight: '15px',
                                                                                    }
                                                                                    if (score === item.score) {
                                                                                        styleObj.background = '#13c2c2'
                                                                                        styleObj.color = '#fff'
                                                                                    }
                                                                                    if (idx === 0) {
                                                                                        styleObj.borderTopLeftRadius = '4px'
                                                                                        styleObj.borderBottomLeftRadius = '4px'
                                                                                    } else if (idx === this.state.scoreOptions.length - 1) {
                                                                                        styleObj.borderTopRightRadius = '4px'
                                                                                        styleObj.borderBottomRightRadius = '4px'
                                                                                        styleObj.borderRight = '1px solid #ccc'
                                                                                    }
                                                                                    return <div
                                                                                        key={'values-' + idx}
                                                                                        style={styleObj}
                                                                                        className='flex flex-h-center flex-v-center'>
                                                                                        {score}
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Collapse.Panel>
                            })}
                        </Collapse>
                    : ''}
            </div>
        )
    }
}

export default SAMScoring