import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import StudentUpload from '../teacher/student/StudentUpload'
import { db, firebaseStorage } from '../firebase/Firebase'
//import ReactToPrint from "react-to-print"
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import { flattenDoc } from '../Util'
import ColType from '../Types'
import { Collapse, Modal, Button, Icon, notification, Popconfirm } from 'antd'
import Empty from '../customcomponents/Empty'
import PersonAvatar from '../customcomponents/PersonAvatar'
import StudentUploadSetTitleDescription from '../teacher/student/StudentUploadSetTitleDescription'
var moment = require('moment')


const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}


class SchoolUploads extends Component {
    state = {
        uploadedFiles: [],
        downloadURLs: {},
        uploadedFilesListener: null,
        uploadedFilesMounted: false,
        modalVisible: false,
        editModalVisible: false,
        selectedUploadedFile: null,
    }

    componentDidMount() {
        let uploadedFilesListener = db.collection(ColType.uploadedfiles)
            .where('districtId', '==', this.props.districtId)
            .where('schoolId', '==', this.props.schoolId)
            .where('ownerId', '==', 'district')
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let uploadedFiles = []
                querySnapshot.forEach((doc) => {
                    let uploadedFile = flattenDoc(doc)
                    firebaseStorage.ref(uploadedFile.filePath).getDownloadURL()
                        .then((url) => {
                            let downloadURLs = this.state.downloadURLs
                            downloadURLs[uploadedFile.id] = url
                            this.setState({ downloadURLs: downloadURLs })
                        })
                    uploadedFiles.push(flattenDoc(doc))
                })

                this.setState({
                    uploadedFiles: uploadedFiles,
                    uploadedFilesMounted: true,
                })
            })

        this.setState({
            uploadedFilesListener: uploadedFilesListener,
        })
    }

    componentWillUnmount() {
        if (this.state.uploadedFilesListener) {
            this.state.uploadedFilesListener()
        }
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleEditCancel = () => {
        this.setState({
            editModalVisible: false,
            selectedUploadedFile: null,
        })
    }

    downloadFile = (file) => {
        if (!file) {
            console.log("downloadFile, file is null")
            return
        }

        firebaseStorage.ref().child(file.filePath).getDownloadURL()
            .then((url) => {
                var xhr = new XMLHttpRequest()
                xhr.responseType = 'blob'
                xhr.onload = function (event) {
                    var blob = xhr.response
                    console.log("blob", blob)
                    url = window.URL.createObjectURL(blob)
                    var a = document.createElement("a")
                    a.href = url
                    a.download = file.fileName
                    a.click()
                    window.URL.revokeObjectURL(url)
                }
                xhr.open('GET', url)
                xhr.send()
            })
            .catch((e) => {
                console.log("download file error", e)
                errorMessage("The file could not be downloaded. Try again or email support@dotit.app")
            })
    }

    deleteUploadedFile = (uploadedFile) => {
        if (!uploadedFile) {
            return
        }

        // Create a reference to the file to delete
        var fileRef = firebaseStorage.ref().child(uploadedFile.filePath)

        // Delete the file
        fileRef.delete().then(() => {
            // File deleted successfully
            db.collection(ColType.uploadedfiles)
                .doc(uploadedFile.id)
                .delete()
                .then(() => {
                    successMessage("File has been deleted successfully.")
                })
                .catch((e) => {
                    errorMessage("Something went wrong deleting the file. " +
                        "Try again or email support@dotit.app.")
                })
        }).catch((e) => {
            errorMessage("Something went wrong deleting the file. " +
                "Try again or email support@dotit.app.")
        })
    }

    render() {
        return (
            <div>
                <div className='w-100 flex flex-v-center mb-2'>
                    <div className='font-30 font-bold'>Uploaded files</div>
                    <Button type="primary" className="lg-btn ml-auto" onClick={this.showModal}>
                        <Icon type="upload" /> Upload file
                    </Button>
                </div>
                <Modal
                    title="Upload file"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <StudentUpload
                        uploadedFilesFileNameDict={{}}
                        teacher={{
                            id: 'district',
                            schoolId: this.props.schoolId,
                            districtId: this.props.districtId,
                        }}
                        student={{ id: 'district' }}
                        IEPId={'district'}
                        extraData={{
                            person: this.props.person,
                            personRole: this.props.accessLevel,
                        }}
                        onSuccess={() => { this.handleCancel() }}
                    />
                </Modal>

                <Modal
                    title="Add title and description"
                    visible={this.state.editModalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleEditCancel}
                >
                    <StudentUploadSetTitleDescription
                        onSubmitSuccess={((title, description) => {
                            if (this.state.selectedUploadedFile) {
                                db.collection(ColType.uploadedfiles)
                                    .doc(this.state.selectedUploadedFile.id)
                                    .update({
                                        uploadedFileTitle: title,
                                        uploadedFileDescription: description,
                                    })
                                    .then(() => {
                                        successMessage("Title and description added successfully.")
                                        this.handleEditCancel()
                                    })
                                    .catch((e) => {
                                        errorMessage("Something went wrong updating the file, please try again.")
                                    })
                            }
                        })}
                    />
                </Modal>

                {this.state.uploadedFilesMounted ?
                    this.state.uploadedFiles.length === 0 ?
                        <Empty
                            containerClassName="mt-2 text-center"
                            width={55}
                            height={55}
                            image={'/empty/goals.png'}
                            description={'No uploads.'}
                        />
                        :
                        <Collapse bordered={false}>
                            {this.state.uploadedFiles.map((uploadedFile, index) => {
                                let downloadURL = ''
                                if (this.state.downloadURLs.hasOwnProperty(uploadedFile.id)) {
                                    downloadURL = this.state.downloadURLs[uploadedFile.id]
                                }
                                let paddingBottom = '100%'
                                if (uploadedFile.hasOwnProperty('contentType')) {
                                    if (uploadedFile.contentType.includes('video')) {
                                        paddingBottom = '56.25%'
                                    }
                                }
                                return <Collapse.Panel header={<div className='font-20 flex flex-v-center w-100'>
                                    <div className='ellipsis' style={{ maxWidth: '400px' }}>
                                        {uploadedFile.uploadedFileTitle}
                                    </div>
                                    <div className='ml-auto'>
                                        {uploadedFile.hasOwnProperty('person') &&
                                            uploadedFile.person ?
                                            <div>
                                                <PersonAvatar
                                                    person={uploadedFile.person}
                                                    avatarColor={'#d3adf7'}
                                                    avatarTextColor={'#000'}
                                                    containerClassName="font-16"
                                                    avatarClassName="font-16 font-bold"
                                                    personClassName="font-black font-16"
                                                />
                                                <span className='font-16 pl-05'>{
                                                    'on ' + (uploadedFile.timeStamp &&
                                                        uploadedFile.timeStamp.hasOwnProperty('seconds') &&
                                                        uploadedFile.timeStamp.seconds ?
                                                        moment.utc(uploadedFile.timeStamp.seconds * 1000).format("MM/DD/YYYY") : '')}</span>
                                            </div>
                                            :
                                            <span className='font-16'>
                                                {(uploadedFile.timeStamp &&
                                                    uploadedFile.timeStamp.hasOwnProperty('seconds') &&
                                                    uploadedFile.timeStamp.seconds ?
                                                    moment.utc(uploadedFile.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                                    :
                                                    '')}</span>}
                                    </div>
                                </div>} key={uploadedFile.id}>
                                    <div className='border-top pt-2'>
                                        <div className='w-100 flex flex-v-center mb-3'>
                                            <div className='flex flex-v-center ml-auto'>
                                                <Button
                                                    size={'large'}
                                                    type="default"
                                                    className="font-20 flex flex-v-center mr-1"
                                                    style={{
                                                        padding: '16px',
                                                        height: '44px'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({
                                                            selectedUploadedFile: uploadedFile,
                                                        }, () => {
                                                            this.setState({
                                                                editModalVisible: true,
                                                            })
                                                        })
                                                    }}
                                                >
                                                    <Icon type="edit" className="mr-1" />Add title and description
                                                </Button>
                                                <Button
                                                    size={'large'}
                                                    type="default"
                                                    className="font-20 flex flex-v-center mr-1"
                                                    style={{
                                                        padding: '16px',
                                                        height: '44px'
                                                    }}
                                                    onClick={() => {
                                                        this.downloadFile(uploadedFile)
                                                    }}
                                                >
                                                    <Icon type="download" className="mr-1" />Download
                                                </Button>
                                                <Popconfirm
                                                    title="Are you sure you want to delete this file?"
                                                    onConfirm={() => {
                                                        this.deleteUploadedFile(uploadedFile)
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="danger"
                                                        className="br-50 flex flex-v-center flex-h-center font-20"
                                                        style={{
                                                            padding: '16px',
                                                            width: '44px',
                                                            height: '44px'
                                                        }}
                                                    >
                                                        <Icon type="delete" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                        <div className='font-20 font-black'>
                                            <div>
                                                {downloadURL !== '' && uploadedFile ?
                                                    <div>
                                                        <div>
                                                            {uploadedFile.hasOwnProperty('uploadedFileTitle') &&
                                                                uploadedFile.uploadedFileTitle ?
                                                                <div className='font-24 font-bold font-black'>
                                                                    {uploadedFile.uploadedFileTitle}
                                                                </div> : ''}
                                                            {uploadedFile.hasOwnProperty('uploadedFileDescription') &&
                                                                uploadedFile.uploadedFileDescription ?
                                                                <div className='font-20 font-black mb-2'>
                                                                    {uploadedFile.uploadedFileDescription}
                                                                </div> : ''}
                                                        </div>
                                                        {uploadedFile.hasOwnProperty("contentType") &&
                                                            uploadedFile.contentType.includes("image") ?
                                                            <img alt={uploadedFile.fileName}
                                                                src={downloadURL}
                                                                className="max-w-100 w-100"
                                                            />
                                                            :
                                                            <div className="relative" style={{ width: '100%', paddingBottom: paddingBottom }}>
                                                                <iframe title="video" className='w-100 h-100 absolute-tl' src={downloadURL} allowFullScreen={true} />
                                                            </div>
                                                        }

                                                    </div>
                                                    : ''}
                                            </div>

                                        </div>
                                    </div>
                                </Collapse.Panel>
                            })}
                        </Collapse>
                    : ''
                }
            </div>
        )
    }
}

export default SchoolUploads