import React from 'react'
let MathComponent = null

if (typeof window !== 'undefined') {
    MathComponent = require('mathjax-react').default
}

class EquationSystemAssessment extends React.Component {
    render() {
        const { pre_text, equations, post_text, answers } = this.props

        return (
            <div>
                {MathComponent ?
                    <div>
                        <p>{pre_text}</p>
                        {equations.map((eqn, index) => (
                            <MathComponent key={index} tex={eqn} />
                        ))}
                        <p>{post_text}</p>
                        <ul>
                            {answers.map((answer, index) => (
                                <li key={index}>{answer}</li>
                            ))}
                        </ul>
                    </div> : ''}
            </div>
        )
    }
}

export default EquationSystemAssessment

