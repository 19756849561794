import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc, createIEPGoalText, createMTSSGoalText } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Select, Radio } from 'antd'
const Option = Select.Option

class SuperAdminGoals extends Component {
    state = {
        grade: '',
        subjects: [],
        IEPGoals: [],
        numProgressMonitoringScores: 2,
        goalMode: 'iep',
    }

    componentDidMount() {

    }

    getData(grade, subject) {
        if (!(grade && subject)) {
            return
        }

        console.log('getData', grade, subject)

        this.setState({
            IEPGoals: []
        }, () => {
            let col = ColType.iepStandards
            if (this.state.goalMode === 'mtss') {
                col = ColType.pepStandards
            }
            db.collection(col)
                .where('grade', '==', grade)
                .where('mainSubject', '==', subject)
                //.where('category', '==', iepDraft.category)
                //.where('level', '==', iepDraft.level)
                .orderBy('standard', 'asc')
                .get()
                .then((querySnapshot) => {
                    var IEPGoals = []

                    querySnapshot.forEach((doc) => {
                        var iep = flattenDoc(doc)
                        if (this.state.goalMode === 'iep') {
                            iep.paragraphOption1 = createIEPGoalText(iep, { firstName: 'John' }, 2)
                            iep.paragraphOption2 = createIEPGoalText(iep, { firstName: 'John' }, 3)
                            iep.paragraphOption3 = createIEPGoalText(iep, { firstName: 'John' }, 4)
                        } else {
                            iep.paragraphOption1 = createMTSSGoalText(iep, { firstName: 'John' }, 2)
                            iep.paragraphOption2 = createMTSSGoalText(iep, { firstName: 'John' }, 3)
                            iep.paragraphOption3 = createMTSSGoalText(iep, { firstName: 'John' }, 4)
                        }
                        IEPGoals.push(iep)
                    })

                    IEPGoals.sort((a, b) => {
                        if (a.mainSubject > b.mainSubject) {
                            return 1
                        } else if (a.mainSubject < b.mainSubject) {
                            return -1
                        } else {
                            return 0
                        }
                    })

                    IEPGoals.sort((a, b) => {
                        if (a.category > b.category) {
                            return 1
                        } else if (a.category < b.category) {
                            return -1
                        } else {
                            return 0
                        }
                    })

                    IEPGoals.sort((a, b) => {
                        if (a.standard > b.standard) {
                            return 1
                        } else if (a.standard < b.standard) {
                            return -1
                        } else {
                            return 0
                        }
                    })

                    console.log(IEPGoals[0], IEPGoals.length)

                    this.setState({
                        IEPGoals: IEPGoals,
                    }, () => {

                    })
                })
        })
    }

    onGradeChange = (value) => {
        this.setState({
            grade: value,
        }, () => {
            this.getData(value, this.state.subjects)
        })
    }

    subjectChange = (value) => {
        this.setState({
            subjects: value,
        }, () => {
            this.getData(this.state.grade, value)
        })
    }

    onNumProgressMonitoringScoresChange = (e) => {
        this.setState({
            numProgressMonitoringScores: e.target.value,
        })
    }

    onGoalModeChange = (e) => {
        console.log(e.target.value)
        this.setState({
            goalMode: e.target.value,
        })
    }

    render() {
        return (
            <div>
                <SuperAdminHeader menuKey={'IEPGoals'} />
                <div className='mt-100 w-600 m-lr-auto'>
                    <Radio.Group value={this.state.goalMode} size='large' buttonStyle="solid" className='mb-3'
                        onChange={this.onGoalModeChange}>
                        <Radio.Button value={'iep'}>IEP</Radio.Button>
                        <Radio.Button value={'mtss'}>MTSS</Radio.Button>
                    </Radio.Group>

                    <Select className='w-100 mb-1' size={'large'} placeholder="Grade..." onChange={this.onGradeChange}>
                        <Option value="K">K</Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                        <Option value="8">8</Option>
                        <Option value="9">9</Option>
                        <Option value="10">10</Option>
                        <Option value="11">11</Option>
                        <Option value="12">12</Option>
                    </Select>
                    <Select
                        className='w-100 mb-1'
                        size={'large'}
                        placeholder="Subjects..."
                        onChange={this.subjectChange}
                    >
                        <Option value={'Reading Comprehension in Literature'}>
                            Reading Comprehension in Literature
                        </Option>
                        <Option value={'Reading Comprehension in Informational Text'}>
                            Reading Comprehension in Informational Text
                        </Option>
                        {this.state.grade === 'K' ||
                            parseInt(this.state.grade, 10) < 5 ?
                            <option value={'Reading Foundations'}>
                                Reading Foundational Skills
                            </option>
                            : ''}
                        <Option value={'Math'}>
                            Math
                        </Option>
                        <Option value={'Writing'}>
                            Writing
                        </Option>
                        <Option value={'Social Emotional Learning'}>
                            Social Emotional Learning
                        </Option>
                    </Select>

                    <div className="border br-4 p-4 mb-100 font-16" contentEditable={true}>
                        {this.state.IEPGoals.length === 0 ? 'No goals' : ''}
                        {this.state.IEPGoals.map((iep, index) => {
                            return <div className='p-2 mb-2' key={iep.id}>
                                <div className="font-bold">{'Grade: ' + iep.grade + ' - ' + iep.mainSubject}</div>
                                {this.state.goalMode === 'iep' ?
                                    <div className="font-bold">{iep.category + ', ' + iep.level + ', ' + iep.standard}</div>
                                    :
                                    <div className="font-bold">{iep.level + ', ' + iep.standard}</div>
                                }
                                <div>Option 1:</div>
                                <div>{iep.paragraphOption1}</div>
                                <div>Option 2:</div>
                                <div>{iep.paragraphOption2}</div>
                                <div>Option 3:</div>
                                <div>{iep.paragraphOption3}</div>
                            </div>

                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default SuperAdminGoals