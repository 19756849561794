import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import PersonAvatar from '../customcomponents/PersonAvatar'
import { Icon, Popover, Modal } from 'antd'
import { getColorGradient, getDOKLevelFromGrade, getStudentSurveysResults } from '../Util'
import { Document } from '../teacher/edvizv2/Edviz'
import DocumentDelegator from '../edviz/EdvizDelegator'


class DOKTeacherBreakdown extends Component {
    state = {
        studentDict: {},
        questionDataDict: {},
        questionData: [],
        questionsMostIncorrect: [],
        questionsMostCorrect: [],
        studentsCompleted: 0,
        visible: false,
        viewingLessonPlanItem: null,
        viewingQuestionIdx: 0,
    }

    componentDidMount() {
        this.getStats()
    }

    componentWillReceiveProps(nextProps) {
        this.getStats()
    }

    showModal = (lessonPlanItem, questionIdx) => {
        this.setState({
            visible: true,
            viewingLessonPlanItem: lessonPlanItem,
            viewingQuestionIdx: questionIdx,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    studentDictHasAllStudents = (quiz) => {
        const studentIds = Object.keys(quiz.studentGrades)
        for (let i = 0; i < studentIds.length; i++) {
            const studentId = studentIds[i]
            if (!this.props.studentDict.hasOwnProperty(studentId)) {
                return false
            }
        }
        return true
    }

    numCompleted = (quiz) => {
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            if (studentGrade.graded) {
                count += 1
            }
            return false
        })
        return count
    }

    areChosenAnswersCorrect = (question, chosenAnswer) => {
        const questionAnswers = question.components[0].answers
        for (let answer of chosenAnswer.answer) {
            if (!questionAnswers[answer].correct) {
                return false
            }
        }
        return true
    }

    isChosenAnswerCorrect = (questionDoc, chosenAnswersBaseQuiz) => {
        const uuid = questionDoc.metadata.uuid
        if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
            return false
        }
        const chosenAnswerBaseQuestionDocId = chosenAnswersBaseQuiz[uuid]
        for (let i = 0; i < questionDoc.components[0].answers.length; i++) {
            const answer = questionDoc.components[0].answers[i]
            if (answer.correct && answer.id === chosenAnswerBaseQuestionDocId) {
                return true
            }
        }
        return false
    }

    getStudentsInDOKLevel = (quiz, dokLevel) => {
        let students = []
        let studentsDict = {}
        if (!(quiz && dokLevel)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                highestDOKLevel = getDOKLevelFromGrade(finalGrade)
            }
            if (highestDOKLevel !== dokLevel) {
                return false
            }
            if (highestDOKLevel === dokLevel && assessmentCompleted) {
                count += 1
                if (!studentsDict.hasOwnProperty(studentId)) {
                    studentsDict[studentId] = true
                    students.push(studentId)
                }
            }
            // dokLevel1 if not completed
            if (!assessmentCompleted && dokLevel === 1) {
                count += 1
                if (!studentsDict.hasOwnProperty(studentId)) {
                    studentsDict[studentId] = true
                    students.push(studentId)
                }
            }
            return false
        })

        return students
    }

    getAverageForQuestionUUID = (quiz, questionUUID) => {
        let students = []
        let studentsDict = {}
        if (!(quiz)) {
            return students
        }
        if (quiz.quiz.docs.length === 0) {
            return students
        }
        let correctCount = 0
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (uuid !== questionUUID) {
                        continue
                    }
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        correctCount += 1
                    }
                    count += 1
                }
            }
            return false
        })

        if (correctCount === 0 || count === 0) {
            return '0.00'
        }
        return ((correctCount / count) * 100).toFixed(2)
    }

    getAverageForQuiz = (quiz) => {
        let students = []
        let studentsDict = {}
        if (!(quiz)) {
            return '100'
        }
        if (quiz.quiz.docs.length === 0) {
            return '100'
        }
        let correctCount = 0
        let count = 0
        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                const finalGrade = studentGrade.finalGrade.grade * 100
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        correctCount += 1
                    }
                    count += 1
                }
            }
            return false
        })

        if (correctCount === 0 || count === 0) {
            return '0.00'
        }

        return ((correctCount / count) * 100).toFixed(2)
    }

    getStats = () => {
        const quiz = this.props.quiz
        if (!quiz) {
            return
        }
        if (quiz.quiz.docs.length === 0) {
            return
        }
        if (!(this.props &&
            this.props.studentDict)) {
            return
        }
        /*if (this.state.statsRetrieved) {
            return
        }
    
        this.setState({ statsRetrieved: true })*/

        let stats = {
            questionDataDict: {},
            questionData: [],
            questionsMostIncorrect: [],
            questionsMostCorrect: [],
            studentsCompleted: 0,
        }

        Object.keys(quiz.studentGrades).map((studentId) => {
            if (!this.props.studentDict.hasOwnProperty(studentId)) {
                return false
            }
            if (!quiz.studentGrades.hasOwnProperty(studentId)) {
                return false
            }
            const student = this.props.studentDict[studentId]
            const studentGrade = quiz.studentGrades[studentId]
            let assessmentCompleted = studentGrade.graded
            let highestDOKLevel = 1
            if (assessmentCompleted) {
                stats.studentsCompleted += 1
                const chosenAnswersBaseQuiz = studentGrade.chosenAnswersBaseQuiz
                for (let i = 0; i < quiz.quiz.docs.length; i++) {
                    const questionDoc = quiz.quiz.docs[i]
                    const dokLevel = questionDoc.metadata.dokLevel
                    const uuid = questionDoc.metadata.uuid
                    if (!chosenAnswersBaseQuiz.hasOwnProperty(uuid)) {
                        continue
                    }
                    const correct = this.isChosenAnswerCorrect(questionDoc, chosenAnswersBaseQuiz)
                    if (correct) {
                        const dokLevelNumber = parseInt(dokLevel, 10)
                        if (dokLevelNumber > highestDOKLevel) {
                            highestDOKLevel = dokLevelNumber
                        }
                    }

                    if (!stats.questionDataDict.hasOwnProperty(i)) {
                        stats.questionDataDict[i] = {
                            correct: 0,
                            incorrect: 0,
                            total: 0,
                        }
                    }
                    if (correct) {
                        stats.questionDataDict[i].correct += 1
                    } else {
                        stats.questionDataDict[i].incorrect += 1
                    }
                    stats.questionDataDict[i].total += 1
                }
            }
        })

        for (let i = 0; i < quiz.quiz.docs.length; i++) {
            if (!stats.questionDataDict.hasOwnProperty(i)) {
                stats.questionData.push({
                    questionIndex: i,
                    correct: 0,
                    incorrect: 0,
                    total: 0,
                })
                continue
            }
            const questionData = stats.questionDataDict[i]
            stats.questionData.push({
                questionIndex: i,
                correct: questionData.correct,
                incorrect: questionData.incorrect,
                total: questionData.total,
            })
        }

        stats.questionsMostIncorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.incorrect - a.incorrect
        })
        stats.questionsMostCorrect = Object.assign([], stats.questionData).sort((a, b) => {
            return b.correct - a.correct
        })

        this.setState({
            stats: stats,
            statsCompleted: true,
        })
    }

    getStudentView = (studentId) => {
        if (!(this.props.quiz &&
            this.props.studentDict &&
            studentId &&
            this.props.studentDict.hasOwnProperty(studentId))) {
            return false
        }
        const quiz = this.props.quiz
        const student = this.props.studentDict[studentId]
        if (!(student && quiz)) {
            return false
        }
        if (quiz.quiz.docs.length === 0) {
            return false
        }
        if (!quiz.studentGrades.hasOwnProperty(student.id)) {
            return false
        }
        const studentGrade = quiz.studentGrades[student.id]
        let assessmentCompleted = studentGrade.graded

        if (this.props.hasOwnProperty('onlyShowCompleted') &&
            this.props.onlyShowCompleted &&
            !assessmentCompleted) {
            return false
        }

        return <tr key={'tr-' + student.id}>
            <td className='sticky-left sticky-left-shadow'>
                <PersonAvatar
                    person={student}
                    size={'large'}
                    containerClassName="font-20"
                    avatarClassName="font-20 font-bold mr-2"
                    personClassName="font-black font-24 ellipsis"
                    personStyle={{ maxWidth: '220px' }}
                />
            </td>
            {this.props.hasOwnProperty('showProgress') && this.props.showProgress ?
                <td style={{ border: '1px solid #d9d9d9' }}>
                    {assessmentCompleted ?
                        <div className='text-success font-bold'>
                            Completed
                        </div> :
                        <div className='text-blue font-bold'>In progress</div>
                    }
                </td> : ''}
            {quiz.quiz.docs.map((question, index) => {
                let allCorrect = false
                if (assessmentCompleted) {
                    let studentGrade = quiz.studentGrades[student.id]
                    if (studentGrade.hasOwnProperty('chosenAnswersBaseQuiz')) {
                        allCorrect = this.isChosenAnswerCorrect(question, studentGrade.chosenAnswersBaseQuiz)
                    }
                }
                let borderClasses = ' border-right border-top border-bottom'
                if (index === 0) {
                    borderClasses += ' border-left br-tl-4 br-bl-4'
                } else if (index === quiz.quiz.docs.length - 1) {
                    borderClasses += ' br-tr-4 br-br-4'
                }
                return <td className={'results-table-question-cell'}
                    style={{
                        backgroundColor: assessmentCompleted ? allCorrect ? '#fff' : '#ffccc7' : '#fff',
                        border: '1px solid #d9d9d9'
                    }}
                    key={'assessment-' + student.id + index}>
                    <div className='flex flex-v-center flex-h-center'>
                        {assessmentCompleted ?
                            allCorrect ?
                                <Icon type='check' className='font-bold' style={{ color: '#389e0d' }} /> :
                                <Icon type='close' className='font-bold' style={{ color: '#cf1322' }} /> :
                            <div className='text-center' style={{ width: '20px' }}>-</div>}
                        {/*<div className='ml-1'>{question.metadata.standard.standard}</div>*/}
                    </div>
                </td>
            })}
            <td className='sticky-right sticky-right-shadow'>{assessmentCompleted ? (quiz.studentGrades[student.id].finalGrade.grade * 100) + '%' : '-'}</td>
        </tr>
    }

    sortDOKStudents = (studentIds) => {
        if (!this.props.quiz) {
            return studentIds
        }

        return studentIds.sort((a, b) => {
            if (!this.props.quiz.studentGrades.hasOwnProperty(a) ||
                !this.props.quiz.studentGrades.hasOwnProperty(b)) {
                return 0
            }

            const studentAGrade = this.props.quiz.studentGrades[a].finalGrade.grade
            const studentBGrade = this.props.quiz.studentGrades[b].finalGrade.grade
            return studentAGrade - studentBGrade
        })
    }

    render() {
        return (
            <div className='relative'>
                {this.props.quiz && this.props.teacherRetakes && this.props.teachers ?
                    <div>
                        <table className='w-100 font-16 results-table'>
                            <thead>
                                <tr>
                                    <th className='sticky-left sticky-left-shadow pl-2 background-fff'
                                        style={{ borderRight: '1px solid transparent' }}
                                    >
                                        Name
                                    </th>
                                    {this.props.quiz.quiz.docs.map((lessonPlanItem, idx) => {
                                        return <th className='results-table-question-cell cursor-pointer'
                                            onClick={(e) => {
                                                this.showModal(lessonPlanItem, idx)
                                            }}
                                            title='Click to view question'>
                                            <div>
                                                <div className='font-14 font-normal text-muted'>QUESTION {idx + 1}</div>
                                                <div>{lessonPlanItem.metadata.standard.standard}</div>
                                            </div>
                                        </th>
                                    })}
                                    <th className='sticky-right sticky-right-shadow' style={{ paddingLeft: '22px' }}>Grade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.teachers.map((teacher, teacherIndex) => {
                                    if (!this.props.teacherRetakes.hasOwnProperty(teacher.id)) {
                                        return false
                                    }
                                    return this.props.teacherRetakes[teacher.id].map((quiz, index) => {
                                        let studentSurveyResults = null;
                                        if (quiz.hasOwnProperty('studentSurveys') &&
                                            quiz.studentSurveys) {
                                            studentSurveyResults = getStudentSurveysResults(quiz.studentSurveys);
                                        }
                                        return <tr>
                                            <td className='sticky-left sticky-left-shadow' style={{ border: '1px solid #d9d9d9' }}>
                                                <div>
                                                    <div className='flex flex-v-center'>
                                                        <PersonAvatar
                                                            person={quiz.teacher}
                                                            size={'large'}
                                                            containerClassName="font-16"
                                                            avatarClassName="font-16 font-bold mr-2"
                                                            personClassName="font-black font-16 ellipsis"
                                                            personStyle={{ maxWidth: '220px' }}
                                                        />
                                                        <div className='ml-1'>#{index + 1}</div>
                                                    </div>
                                                    <div className='flex w-100 mt-1'>
                                                        <div className='font-16'>
                                                            {this.numCompleted(quiz)} / {Object.keys(quiz.studentGrades).length} students completed this test
                                                        </div>
                                                    </div>
                                                    {studentSurveyResults ?
                                                        <div title={'Student responses to the question: How do you feel about this test?'}>
                                                            <div className='flex flex-v-center w-100'>
                                                                <div className='mr-2'>
                                                                    <div className='flex flex-v-center'>
                                                                        <div style={{ fontSize: '32px' }}>😀</div>
                                                                        <div className='font-16 ml-1'>
                                                                            {(studentSurveyResults.goodPct * 100).toFixed(2) + '%'}
                                                                        </div>
                                                                    </div>
                                                                    <div className='br-4 overflow-hidden'
                                                                        style={{ width: '102px', height: '8px', background: '#a8a8a8' }}>
                                                                        <div className='background-green h-100'
                                                                            style={{ width: (studentSurveyResults.goodPct * 100).toFixed(2) + '%' }}></div>
                                                                    </div>
                                                                </div>
                                                                <div className='mr-2'>
                                                                    <div className='flex flex-v-center'>
                                                                        <div style={{ fontSize: '32px' }}>😐</div>
                                                                        <div className='font-16 ml-1'>
                                                                            {(studentSurveyResults.somewhatGoodPct * 100).toFixed(2) + '%'}
                                                                        </div>
                                                                    </div>
                                                                    <div className='br-4 overflow-hidden'
                                                                        style={{ width: '102px', height: '8px', background: '#a8a8a8' }}>
                                                                        <div className='background-orange h-100'
                                                                            style={{ width: (studentSurveyResults.somewhatGoodPct * 100).toFixed(2) + '%' }}></div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='flex flex-v-center'>
                                                                        <div style={{ fontSize: '32px' }}>☹️</div>
                                                                        <div className='font-16 ml-1'>
                                                                            {(studentSurveyResults.notGoodPct * 100).toFixed(2) + '%'}
                                                                        </div>
                                                                    </div>
                                                                    <div className='br-4 overflow-hidden'
                                                                        style={{ width: '102px', height: '8px', background: '#a8a8a8' }}>
                                                                        <div className='background-red h-100'
                                                                            style={{ width: (studentSurveyResults.notGoodPct * 100).toFixed(2) + '%' }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </td>
                                            {quiz.quiz.docs.map((lessonPlanItem, idx) => {
                                                const avgStr = this.getAverageForQuestionUUID(quiz, lessonPlanItem.metadata.uuid)
                                                const avgNum = parseFloat(avgStr)
                                                return <td className='purple-border-hover cursor-pointer'
                                                    style={{
                                                        border: '1px solid #d9d9d9',
                                                        background: getColorGradient(avgNum)
                                                    }}
                                                    onClick={(e) => {
                                                        this.showModal(lessonPlanItem, idx)
                                                    }}
                                                    title='Click to view question'>
                                                    <div className='text-center'>
                                                        {avgStr}%
                                                    </div>
                                                </td>
                                            })}
                                            <td className='sticky-right sticky-right-shadow text-center'
                                                style={{
                                                    border: '1px solid #d9d9d9',
                                                    background: getColorGradient(parseFloat(this.getAverageForQuiz(quiz)))
                                                }}>
                                                {this.getAverageForQuiz(quiz)}%
                                            </td>
                                        </tr>
                                    })
                                })}
                            </tbody>
                        </table>

                        <Modal
                            title='View question'
                            width={748}
                            visible={this.state.visible}
                            onCancel={this.hideModal}
                            footer={null}
                        >
                            {this.state.viewingLessonPlanItem ?
                                <div className='question-container font-black font-20 border ant-shadow br-4 p-3'>
                                    <div className='font-30 flex flex-v-center space-between w-100'>
                                        <div className='inline-flex'>Question {this.state.viewingQuestionIdx + 1}</div>
                                    </div>
                                    <div>
                                        <DocumentDelegator
                                            document={this.state.viewingLessonPlanItem}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <div className='font-16 font-bold'>
                                            This question is for the standard: {this.state.viewingLessonPlanItem.metadata.standard.standard}
                                        </div>
                                        {this.state.viewingLessonPlanItem.metadata.standard.hasOwnProperty('isPriority') &&
                                            this.state.viewingLessonPlanItem.metadata.standard.isPriority ?
                                            <div className='font-16'>*Priority Standard</div>
                                            : ''}
                                        <div className='mt-1 font-16'>{this.state.viewingLessonPlanItem.metadata.standard.title}</div>
                                    </div>
                                </div>
                                : ''}
                        </Modal>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default DOKTeacherBreakdown
