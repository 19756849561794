import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import VideoContainer from '../video/VideoContainer'
import { firebase } from '../firebase/Firebase'
import { getInitials } from '.././Util'
import { Layout, Menu, message, Icon, Popover, Avatar } from 'antd'
const { Header } = Layout
//const moment = require('moment')

const errorMessage = (description) => {
    message.error(description)
}

class StudentHeader extends Component {
    state = {
        newPopoverVisible: false,
        searchValue: '',
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    componentDidMount() {

    }

    componentWillReceiveProps(newProps) {
        // on url change set popovers visible to false
        this.setState({
            newPopoverVisible: false,
        })
    }

    newVisibleChange = (visible) => {
        this.setState({ newPopoverVisible: visible })
    }

    signOut = () => {
        firebase.auth().signOut().then(function () {
            console.log('Signed Out')
            //sending users to the sign in page after signing out for now.
            //window.location.href = "/signed-out-student-clever"
        }, function (error) {
            console.error('Sign Out Error', error)
            errorMessage("There was an error signing you out. Please try again.")
        })
    }

    onChange = (value) => {
        console.log(`selected ${value}`)
    }

    onSelect = (value, option) => {
        console.log('on select', value, option)
        console.log('/teacher/student/' +
            this.props.person.id + '?student=' +
            option.key)

        this.props.history.push(
            {
                pathname: '/teacher/student/' +
                    this.props.person.id + '?student=' +
                    option.key
            }
        )
    }

    render() {
        return (
            <div>
                <Header
                    className={(this.props.hasOwnProperty("positionNotFixed") &&
                        this.props.positionNotFixed ? '' :
                        'position-fixed') +
                        (this.props.readOnly ? ' t-80' : '')}
                    style={{
                        padding: 0,
                        textAlign: 'left',
                        width: '100%',
                        zIndex: 2,
                        borderBottom: '1px solid #f1f1f1'
                    }}
                >
                    {this.props.person != null &&
                        Object.keys(this.props.person).length !== 0 &&
                        this.props.person.constructor === Object ?
                        <div>
                            <Menu
                                selectedKeys={[]}
                                mode="horizontal"
                                className="font-16 no-border-bottom flex flex-row"
                                style={{ lineHeight: '80px' }}
                            >
                                <Menu.Item key="teacher-logo" className="inline-flex flex-v-center menu-no-bb p-0 ml-20 mr-3">
                                    <img src='/dotitlogo.png' alt='logo' height='50' />
                                </Menu.Item>

                                <Menu.Item key="quizzes" className="font-500 inline-flex flex-center p-0">
                                    <Link
                                        to={'/student/quizzes'}
                                        className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                                            (typeof window !== 'undefined' && window.location.href.includes('/quizzes') ? ' text-cyan' : '')}
                                    >
                                        <Icon type="solution" className={"va-middle " +
                                            "mr-0 br-50 p-1 font-24"} />
                                        <span className="va-minus-4 font-20">Quizzes</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="completed-quizzes" className="font-500 inline-flex flex-center p-0">
                                    <Link
                                        to={'/student/completed-quizzes'}
                                        className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                                            (typeof window !== 'undefined' && window.location.href.includes('/completed-quizzes') ? ' text-cyan' : '')}
                                    >
                                        <Icon type="check-square" className={"va-middle " +
                                            "mr-0 br-50 p-1 font-24"} />
                                        <span className="va-minus-4 font-20">Completed Quizzes</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="teacher-sign-out"
                                    className="inline-flex flex-v-center menu-no-bb ml-auto pr-20">
                                    <Popover
                                        placement="bottomLeft"
                                        title={
                                            <div className="pt-1 pb-1 flex flex-v-center w-100">
                                                <Avatar
                                                    size="large"
                                                    style={{ backgroundColor: '#d3adf7' }}
                                                    className="avatar-vlg"
                                                >
                                                    <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                                                </Avatar>
                                                <span className="font-20 ml-1 font-bold vertical-align-middle ellipsis" style={{ maxWidth: '220px' }}>
                                                    {this.props.person.firstName + " " + this.props.person.lastName}
                                                </span>
                                            </div>
                                        } content={
                                            <div className="p-0 border-right-none negate-popover-padding">
                                                <div className="ant-menu-item p-0" onClick={this.signOut}>
                                                    <span className="font-16 p-2 block font-bold">
                                                        <span className="va-minus-1 font-20">Sign out</span>
                                                    </span>
                                                </div>
                                            </div>
                                        } trigger="click">
                                        <span className="h-80px inline-flex flex-v-center cursor-pointer">
                                            <span className="inline-block-important">
                                                <Avatar
                                                    size="large"
                                                    style={{ backgroundColor: '#d3adf7' }}
                                                    className="avatar-vlg"
                                                >
                                                    <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                                                </Avatar>
                                            </span>
                                        </span>
                                    </Popover>
                                </Menu.Item>

                            </Menu>
                        </div> : ''}
                </Header>
            </div>
        )
    }
}

export default StudentHeader