import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import Empty from '../../customcomponents/Empty'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import { Layout, Icon } from 'antd'
const { Content } = Layout
var moment = require('moment')

class TeacherGroupReports extends Component {
  state = {

  }

  componentWillReceiveProps(nextProps) {

  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Student Groups - Dot It'
  }

  noDataForDay = (dayNum) => {
    if (!this.props.teacherBaseProps.events) return true
    for (var i = 0; i < this.props.teacherBaseProps.events.length; i++) {
      var event = this.props.teacherBaseProps.events[i]
      if (dayNum !== event.day) continue
      if (event.servicedIn === "Teacher Event") continue
      return false
    }
    return true
  }

  render() {
    return (
      <div className="background-fff-important">
        <Layout className="content layout-header-mt background-fff-important min-w-1024">
          <Layout className="background-fff-important">
            <Content className="layout-content">
              <div className="">
                {this.props.teacherBaseProps.teacher ?
                  <div>
                    <h1 className="flex flex-v-center mb-3 pb-3 border-bottom font-bold">
                      <Icon type="team" className="mr-2 font-30" />
                      <span className="va-middle">
                        Student Groups
                      </span>
                    </h1>
                    <div className="w-100 h-100">
                      {[1, 2, 3, 4, 5].map((dayNum, index) => {
                        var dayString = 'Monday'
                        if (dayNum === 2) dayString = 'Tuesday'
                        else if (dayNum === 3) dayString = 'Wednesday'
                        else if (dayNum === 4) dayString = 'Thursday'
                        else if (dayNum === 5) dayString = 'Friday'
                        return <div
                          className="w-20 inline-block pr-4 inline-flex flex-col"
                          key={'day-' + dayNum}>
                          <div className="border-lg p-2 mb-2">
                            <div className="text-center font-20 ">{dayString}</div>
                          </div>
                          {this.noDataForDay(dayNum) ?
                            <Empty
                              containerClassName="mt-4 pt-2 text-center"
                              width={55}
                              height={55}
                            /> : ''}
                          {this.props.teacherBaseProps.events &&
                            this.props.teacherBaseProps.events.map((event, index) => {
                              if (dayNum !== event.day) return false
                              if (event.servicedIn === "Teacher Event") return false
                              return <Link
                                to={'/teacher/lesson-plan/' +
                                  this.props.teacherBaseProps.teacher.id + '?calendarevent=' + event.id}
                                className={"p-2 w-100 h-100 block br-4 up-hover" +
                                  " shadow-hover mb-2 ant-btn" +
                                  " ant-btn br-4-important relative parent-hover white-space-prewrap"}
                                key={'group-' + event.id}
                              >
                                <div>
                                  <div className="font-18 mb-1 text-left">
                                    {moment.utc(event.startTime.seconds * 1000).format("hh:mm A").replace(/^(?:00:)?0?/, '') +
                                      ' - ' + moment.utc(event.startTime.seconds * 1000)
                                        .add(event.duration, 'minutes')
                                        .format("hh:mm A").replace(/^(?:00:)?0?/, '')
                                    }
                                  </div>
                                  <div className="font-20 mb-2 white-space-prewrap text-left">
                                    {event.title}
                                  </div>
                                  {event.hasOwnProperty('ieps') && event.ieps &&
                                    event.ieps.map((iep, index) => {
                                      if (!this.props.teacherBaseProps.IEPDict) return false
                                      if (!this.props.teacherBaseProps.studentDict) return false
                                      if (!(this.props.teacherBaseProps.IEPDict.hasOwnProperty(iep.iepId)))
                                        return false
                                      var studentId = this.props.teacherBaseProps.IEPDict[iep.iepId].studentId
                                      if (!(this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)))
                                        return false

                                      var student = this.props.teacherBaseProps.studentDict[studentId]
                                      return <div className="mb-2 text-left"
                                        title={student.firstName + " " + student.lastName}
                                        key={'student-' + student.id + '-' + event.id + '-' + index}>
                                        <PersonAvatar
                                          person={student}
                                          size={'large'}
                                          containerClassName="font-16"
                                          avatarClassName="font-16 font-bold mr-1"
                                          personClassName="font-black font-normal"
                                        />
                                      </div>
                                    })
                                  }

                                </div>
                              </Link>
                            })}
                        </div>

                      })
                      }

                    </div>
                  </div>
                  : ''}

              </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default TeacherGroupReports