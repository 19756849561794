const firebase = require('firebase')
// Required for side-effects
require('firebase/firestore')
require('firebase/storage')

firebase.initializeApp({
  apiKey: 'AIzaSyBk2ZLSvCf6hp-CN-5H8tbGuwRpjKhp5gI',
  authDomain: 'education-9d7f3.firebaseapp.com',
  databaseURL: 'https://education-9d7f3.firebaseio.com/',
  projectId: 'education-9d7f3',
  storageBucket: 'uploaded-files-8pmvb'
})

// firestore
var db = firebase.firestore()

var firebaseStorage = firebase.storage()

// Used for admins to create user accounts, by default
// when you create a new user it logs you into the account
// you just created. This second ref is a fix for that problem,
// see files in the Login directory.
var secondaryFirebaseRef = firebase.initializeApp({
  apiKey: 'AIzaSyBk2ZLSvCf6hp-CN-5H8tbGuwRpjKhp5gI',
  authDomain: 'education-9d7f3.firebaseapp.com',
  projectId: 'education-9d7f3'
}, 'Secondary')

var dotitFirebaseRef = firebase.initializeApp({
  apiKey: 'AIzaSyA6Fn7CVcYj1_oy8zFEN0UNycmvHOoVx80',
  authDomain: 'smartlearningsystems-dcac2.firebaseapp.com',
  databaseURL: 'https://smartlearningsystems-dcac2.firebaseio.com',
  projectId: 'smartlearningsystems-dcac2',
  storageBucket: 'smartlearningsystems-dcac2.appspot.com'
}, 'dotitdb')

// dot it db
var dotitdb = dotitFirebaseRef.firestore()

export { db }
export { firebase }
export { secondaryFirebaseRef }
export { firebaseStorage }
export { dotitdb }