import React from 'react'
import MathText from './MathText'

function FlashCard(props) {
    const { category, front, back } = props

    return (
        <div style={{ border: '1px solid black', margin: '10px', padding: '20px' }}>
            <h2 style={{ fontStyle: 'italic' }}><MathText text={category} /></h2>
            <h1><MathText text={front} /></h1>
            <h3><MathText text={back} /></h3>
        </div>
    )
}

function FlashCards(props) {
    return (
        <div>
            {props.flashcards.map((flashcard, index) => (
                <FlashCard key={index} category={flashcard[0]} front={flashcard[1]} back={flashcard[2]} />
            ))}
        </div>
    )
}

export default FlashCards
