import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { getIDFromURL } from '../.././Util'
import { Layout } from 'antd'
import CustomFooter from '../../login/CustomFooter'
const { Content } = Layout


// Using this to add students until the step form for adding students is done.
class NeedHelp extends Component {
    state = {
        submitting: false,
        submitted: false,
    }

    componentDidMount() {
        document.title = "Need help? - Dot It"
        var teacherId = getIDFromURL(window.location)


        this.setState({
            teacherId: teacherId,
        })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">

                            <div className="w-750 m-lr-auto br-4 pt-3">
                                <div className="block w-100 m-lr-auto text-center">
                                    <img src="/contact-us-icon.png" alt="sign-in" height="300" />
                                </div>
                                <iframe aria-label='Need help?' frameborder="0" style={{
                                    height: '740px',
                                    width: '99%',
                                    border: 'none',
                                }} src='https://forms.zohopublic.com/smartlearningsystems1/form/SoftwareHelpForm/formperma/ZUhZANyqORjuePMN9wX3C6ACKAomHKdHFC-ALqPj_FQ'></iframe>

                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div >
        )
    }
}

export default NeedHelp