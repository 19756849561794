import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { Icon, Tooltip } from 'antd'

class TeacherSummary extends Component {
    render() {
        return (
            <div>
                {/*this.props.teacher &&
                    this.props.teacher.hasOwnProperty('numStudents') &&
                    this.props.teacher.hasOwnProperty('numGoals') &&
                    this.props.teacher.hasOwnProperty('numTierOneNotes') &&
                    this.props.teacher.hasOwnProperty('numProgressMonitoring') ?
                    <div className='flex flex-v-center w-100 text-muted-darker font-16 mt-1'>
                        <div className='mr-2'>
                            <Tooltip title="Number of students">
                                <div className='flex'>
                                    <div><Icon type='user' className='mr-1' /></div>
                                    <div>{this.props.teacher.numStudents}</div>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='mr-2'>
                            <Tooltip title="Number of goals">
                                <div className='flex'>
                                    <div><Icon type='solution' className='mr-1' /></div>
                                    <div>{this.props.teacher.numGoals}</div>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='mr-2'>
                            <Tooltip title="Number of tier one notes">
                                <div className='flex'>
                                    <div><Icon type='file-text' className='mr-1' /></div>
                                    <div>{this.props.teacher.numTierOneNotes}</div>
                                </div>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Number of progress monitoring measurements">
                                <div className='flex'>
                                    <div><Icon type='line-chart' className='mr-1' /></div>
                                    <div>{this.props.teacher.numProgressMonitoring}</div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                : ''*/}
            </div>
        )
    }
}

export default TeacherSummary