import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import { Tag, Button, Icon, message, Modal } from 'antd'
import { getQueryStringParam } from '../../Util'
var moment = require('moment')

const errorMessage = (description) => {
  message.destroy()
  message.error(description)
}

const successMessage = (description) => {
  message.destroy()
  message.success(description)
}

class IEPDrafts extends Component {
  state = {
    visible: false,
    deleting: false,
  }

  componentDidMount() {

  }

  deleteDraft = (draftId) => {
    let isMTSS = this.props.hasOwnProperty('isMTSS') && this.props.isMTSS
    let col = ColType.iepDrafts
    if (isMTSS) {
      col = ColType.pepDrafts
    }

    db.collection(col)
      .doc(draftId)
      .delete()
      .then(() => {
        successMessage("Draft deleted successfully.")
        this.setState({
          deleting: false,
          visible: false,
        })
      })
      .catch((error) => {
        errorMessage("Draft could not be deleted.")
        this.setState({
          deleting: false,
          visible: false,
        })
      })
  }

  render() {
    let isMTSS = this.props.hasOwnProperty('isMTSS') && this.props.isMTSS

    return <div className="w-800 m-lr-auto">
      <div>
        <h1 className='mb-0 font-bold'>
          {isMTSS ?
            'Saved Tier Plan Drafts' :
            'Saved IEP Drafts'}
        </h1>
        <h2 className='mb-3'>
          {isMTSS ?
            'Select a saved draft below to continue drafting that tier plan.' :
            'Select a saved draft below to continue drafting that IEP.'}
        </h2>
        {this.props.iepDrafts ?
          this.props.iepDrafts.length === 0 ?
            <h2>There are currently no drafts.</h2>
            :
            this.props.iepDrafts.map((iepDraft, index) => {
              let studentId = ''
              // for multiple students in the mtss plan drafts
              if (Array.isArray(iepDraft.studentId) && iepDraft.studentId.length > 0) {
                studentId = iepDraft.studentId[0]
              } else {
                studentId = iepDraft.studentId
              }
              if (!studentId) {
                return false
              }
              if (!(this.props.studentDict && this.props.studentDict.hasOwnProperty(studentId))) {
                return false
              }
              if (!(iepDraft.hasOwnProperty('timeStamp') &&
                iepDraft.timeStamp &&
                iepDraft.timeStamp.hasOwnProperty('seconds') &&
                iepDraft.timeStamp.seconds)) {
                return false
              }
              let currentDraftId = getQueryStringParam('draft')
              let isCurrentDraft = false
              if (currentDraftId) {
                isCurrentDraft = currentDraftId === iepDraft.id
              }
              let isCurrentDraftAndCurrentStep = window.location.pathname === '/teacher/add-goal-' +
                iepDraft.step.path + '/' +
                iepDraft.teacherId &&
                window.location.search === '?student=' + studentId +
                '&draft=' + iepDraft.id

              if (isMTSS) {
                isCurrentDraftAndCurrentStep = window.location.pathname === '/teacher/mtss-add-goal-' +
                  iepDraft.step.path + '/' +
                  iepDraft.teacherId &&
                  window.location.search === '?student=' + studentId +
                  '&draft=' + iepDraft.id
              }

              let linkToPathName = '/teacher/add-goal-' + iepDraft.step.path + '/' +
                iepDraft.teacherId
              if (isMTSS) {
                linkToPathName = '/teacher/mtss-add-goal-' + iepDraft.step.path + '/' +
                  iepDraft.teacherId
              }
              return <div key={'draft-' + iepDraft.id}>
                <Link to={{
                  pathname: linkToPathName,
                  search: '?student=' + studentId +
                    '&draft=' + iepDraft.id,
                }}
                  disabled={isCurrentDraftAndCurrentStep}
                  onClick={(e) => {
                    if (!isCurrentDraftAndCurrentStep) {
                      if (this.props.hasOwnProperty('onNewDraftSelected')) {
                        this.props.onNewDraftSelected()
                      }
                    }
                  }}
                  className={"w-100 h-100 br-4-important font-20 flex flex-v-center" +
                    " shadow-hover mb-2 p-3 ant-btn" +
                    " ant-btn relative parent-hover text-left"}
                >
                  {!isCurrentDraft ?
                    <div className="absolute-tr show-on-parent-hover font-bold flex flex-v-center pl-2 pr-2 h-100 background-fff no-animation-transition"
                      style={{ right: '8px', border: 'none' }}>
                      <Button onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({
                          visible: true,
                          deleteDraft: iepDraft,
                        })
                      }}>
                        <Icon type="delete" />
                        <span>Delete</span>
                      </Button>
                    </div> : ''}
                  <PersonAvatar
                    person={this.props.studentDict[studentId]}
                    size={'large'}
                    containerClassName="font-20"
                    avatarClassName="font-20 font-bold mr-2"
                    personClassName="font-black font-24"
                  />
                  {isCurrentDraft ?
                    <Tag color="blue" className="ml-1 font-20">Current draft.</Tag>
                    : ''}
                  <div className="ml-auto font-normal">
                    {iepDraft.hasOwnProperty('timeStamp') &&
                      iepDraft.timeStamp &&
                      iepDraft.timeStamp.hasOwnProperty('seconds') &&
                      iepDraft.timeStamp.seconds ?
                      moment.utc(iepDraft.timeStamp.seconds * 1000).format("MM/DD/YYYY") :
                      ''}
                  </div>
                </Link>
              </div>
            })
          : ''}
      </div>

      <Modal
        title="Delete draft"
        visible={this.state.visible}
        onCancel={() => {
          this.setState({
            visible: false,
          })
        }}
        footer={
          <div>
            <Button size="large" disabled={this.state.deleting}
              onClick={() => {
                this.setState({
                  visible: false
                })
              }}>No</Button>
            <Button size="large" type="primary" disabled={this.state.deleting}
              onClick={
                () => {
                  if (this.state.deleteDraft) {
                    this.setState({
                      deleting: true,
                    })
                    this.deleteDraft(this.state.deleteDraft.id)
                  }
                }
              }>Yes</Button>
          </div>
        }
      >
        <h2>Do you want to delete this draft?</h2>
      </Modal>
    </div >
  }
}

export default IEPDrafts