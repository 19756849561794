import React from 'react'

class Standard extends React.Component {
    render() {
        const { subject, category, grade, standard, title } = this.props

        return (
            <div className='font-20'>
                <p><b>Subject:</b> {subject}</p>
                <p><b>Category:</b> {category}</p>
                <p><b>Grade:</b> {grade}</p>
                <p><b>Standard:</b> {standard}</p>
                <p>{title}</p>
            </div>
        )
    }
}

export default Standard
