import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import VideoContainer from '../video/VideoContainer'
import { firebase } from '../firebase/Firebase'
import { getInitials, isSandboxNoSub, isReadOnlyTeacherId } from '.././Util'
import { Layout, Icon, Menu, Avatar, Popover, message, Tooltip, Modal } from 'antd'
import GeneralNotifications from '../teacher/student/GeneralNotifications'
const { Header } = Layout
//const moment = require('moment')

const errorMessage = (description) => {
  message.error(description)
}

class TeacherHeader extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    searchValue: '',
    visible: false,
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {
    // on url change set popovers visible to false
    this.setState({
      newPopoverVisible: false,
      accountPopoverVisible: false,
    })
  }

  newVisibleChange = (visible) => {
    this.setState({ newPopoverVisible: visible })
  }

  accountVisibleChange = (visible) => {
    this.setState({ accountPopoverVisible: visible })
  }

  signOut = () => {
    firebase.auth().signOut().then(() => {
      if (this.props.teacherBaseProps &&
        this.props.teacherBaseProps.hasOwnProperty('district') &&
        this.props.teacherBaseProps.district &&
        this.props.person &&
        this.props.person.id === '64c9bd88ee3bd703dc3f33ca' &&
        (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
          this.props.teacherBaseProps.district.id === '64e3cb7826043cd12a0f829e')) {
        this.props.history.push(
          {
            pathname: '/demo-sign-in'
          }
        )
      } else {
        this.props.history.push(
          {
            pathname: '/sign-in/'
          }
        )
      }
      //sending users to the sign in page after signing out for now.
      //window.location.href = "/sign-in"
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/teacher/student/' +
          this.props.person.id + '?student=' +
          option.key
      }
    )
  }

  render() {
    return (
      <div>
        <Header
          className={(this.props.hasOwnProperty("positionNotFixed") &&
            this.props.positionNotFixed ? '' :
            'position-fixed') +
            (this.props.readOnly ? ' t-80' : '')}
          style={{
            padding: 0,
            textAlign: 'left',
            width: '100%',
            zIndex: 5,
            borderBottom: '1px solid #f1f1f1'
          }}
        >
          {this.props.person != null &&
            Object.keys(this.props.person).length !== 0 &&
            this.props.person.constructor === Object ?
            <div>
              <Modal
                title="Upgrade Account"
                visible={this.state.visible}
                width={500}
                footer={null}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <div className="font-20">
                  <div className="mb-2">
                    This feature is available with an upgraded account.
                  </div>
                  {isSandboxNoSub(this.props.teacherBaseProps) ?
                    <Link to={"/teacher/subscribe/" +
                      this.props.teacherBaseProps.teacher.id}
                      onClick={this.hideModal}
                      className={"ant-btn ant-btn-primary ant-btn-lg " +
                        "font-bold-important font-18 w-100"}
                    >
                      Upgrade My Account
                    </Link>
                    : ''}
                </div>
              </Modal>
              <Menu
                selectedKeys={[this.props.selectedKey]}
                mode="horizontal"
                className="font-16 no-border-bottom flex flex-row"
                style={{ lineHeight: '80px' }}
              >
                {this.props.readOnly ?
                  <Menu.Item
                    key="teacher-view-only"
                    className="inline-flex flex-v-center menu-no-bb"
                    style={{ marginRight: 48 }}
                  >
                    <Tooltip title="This information can be viewed only."
                      placement="bottomLeft" mouseEnterDelay={.75}>
                      <div>View only</div>
                    </Tooltip>
                  </Menu.Item>
                  : ''}

                {!this.props.readOnly ?
                  <Menu.Item key="teacher-logo" className="inline-flex flex-v-center menu-no-bb p-0 ml-20 mr-3">
                    {isReadOnlyTeacherId(this.props.person.id) ?
                      <a href="https://dotit.app/">
                        <img src='/dotitlogo.png' alt='logo' height='50' />
                      </a>
                      :
                      <Link to={'/teacher/home/' + this.props.person.id}>
                        <img src='/dotitlogo.png' alt='logo' height='50' />
                      </Link>
                    }
                  </Menu.Item>
                  : ''}

                <Menu.Item key="pacing-guide" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/teacher/pacing-guide/guide/' + this.props.person.id}
                    className={"pl-2 pr-2 onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'guide' || this.props.selectedKey === 'lesson-plan' ? ' text-cyan' : '')}
                  >
                    <Icon type="read" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Pacing Guide</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="quizzes" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/teacher/quizzes/all/' + this.props.person.id}
                    className={"pl-2 pr-2 onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'all' ? ' text-cyan' : '')}
                  >
                    <Icon type="book" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Assessments</span>
                  </Link>
                </Menu.Item>

                {/*
                <Menu.Item key="weekly-planner" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/teacher/pacing-guide/weekly-planner/' + this.props.person.id}
                    className={"pl-2 pr-2 onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'weekly-planner' ? ' text-cyan' : '')}
                  >
                    <Icon type="schedule" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Weekly Planner</span>
                  </Link>
                </Menu.Item>
                */}

                {/*<Menu.Item key="class" className="font-500 inline-flex flex-center">
              <Tooltip title="My students" placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/teacher/class/' + this.props.person.id}>
                  <Icon type="user" 
                  className={"font-24 va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 font-30 " +
                  (this.props.readOnly ? "ant-btn-outlined" : "ant-btn-primary")} />
                </Link>
                </Tooltip>
              </Menu.Item>*/}

                {/*<Menu.Item key="schedule"
                  className="font-500 inline-flex flex-center text-muted p-0">
                  <Link
                    to={'/teacher/schedule/' + this.props.person.id}
                    disabled={isReadOnlyTeacherId(this.props.person.id)}
                    onClick={(e) => {
                      if (isSandboxNoSub(this.props.teacherBaseProps)) {
                        e.preventDefault()
                        this.showModal()
                      }
                    }
                    }
                    className={"relative pl-2 pr-2 onboard-schedule-tab font-bold" +
                      (this.props.selectedKey === 'schedule' ? ' text-cyan' : '')}
                  >
                    <Icon type="calendar"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Practice</span>
                  </Link>
                  </Menu.Item>*/}

                {/* 
                <Menu.Item key="groups" className="font-500 inline-flex flex-center p-0">
                  <Tooltip title="Student groups" placement="bottom" mouseEnterDelay={.75}>
                    <Link
                      to={'/teacher/groups/' + this.props.person.id}
                      disabled={isReadOnlyTeacherId(this.props.person.id)}
                      onClick={(e) => {
                        if (isSandboxNoSub(this.props.teacherBaseProps)) {
                          e.preventDefault()
                          this.showModal()
                        }
                      }
                      }
                      className={"relative p-lr-20-important onboard-group-reports-tab" +
                        (this.props.selectedKey === 'groups' ? ' text-cyan' : '')}>
                      <Icon type="team"
                        className={"font-24 va-middle flex-h-center " +
                          "mr-0 br-50 p-1 font-30"} />
                      <span className="va-minus-4">Groups</span>
                    </Link>
                  </Tooltip>
                </Menu.Item>
                */}

                {/* 
                <Menu.Item key="uploads"
                  className="font-500 inline-flex flex-center text-muted p-0">
                  <Link
                    to={'/teacher/uploads/' + this.props.person.id}
                    disabled={isReadOnlyTeacherId(this.props.person.id)}
                    onClick={(e) => {
                      if (isSandboxNoSub(this.props.teacherBaseProps)) {
                        e.preventDefault()
                        this.showModal()
                      }
                    }
                    }
                    className={"relative p-lr-20-important onboard-uploads-tab" +
                      (this.props.selectedKey === 'uploads' ? ' text-cyan' : '')}
                  >
                    <Icon type="upload"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Uploads</span>
                  </Link>
                </Menu.Item>
                */}

                {/*<Menu.Item key="chat"
                  className="font-500 inline-flex flex-center text-muted p-0">
                  <Link
                    to={'/teacher/chat/' + this.props.person.id}
                    disabled={isReadOnlyTeacherId(this.props.person.id)}
                    onClick={(e) => {
                      if (isSandboxNoSub(this.props.teacherBaseProps)) {
                        e.preventDefault()
                        this.showModal()
                      }
                    }
                    }
                    className={"relative pl-2 pr-2 onboard-chat-tab relative font-bold" +
                      (this.props.selectedKey === 'chat' ? ' text-cyan' : '')}
                  >
                    {this.props.teacherBaseProps.hasChatNotifications ?
                      <div className="absolute-tr chat-active-dot"
                        style={{ top: '30px', right: '4px' }}>
                      </div>
                      : ''}
                    <Icon type="message"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">
                      <span>Teams</span>
                    </span>
                  </Link>
                    </Menu.Item>*/}


                <Menu.Item key="meetings" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/teacher/meetings/' + this.props.person.id}
                    className={"pl-2 pr-2 onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'meetings' ? ' text-cyan' : '')}
                  >
                    <Icon type="schedule" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Meetings</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="home" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/teacher/home/' + this.props.person.id}
                    className={"pl-2 pr-2 onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'home' ? ' text-cyan' : '')}
                  >
                    <Icon type="user" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Students</span>
                  </Link>
                </Menu.Item>

                {/* 
                <Menu.Item key="tier-one"
                  className="font-500 inline-flex flex-center text-muted p-0">
                  <Link
                    to={'/teacher/tier-one/' + this.props.person.id}
                    disabled={isReadOnlyTeacherId(this.props.person.id)}
                    onClick={(e) => {
                      if (isSandboxNoSub(this.props.teacherBaseProps)) {
                        e.preventDefault()
                        this.showModal()
                      }
                    }
                    }
                    className={"relative p-lr-20-important onboard-tier-one-tab relative font-bold" +
                      (this.props.selectedKey === 'tier-one' ? ' text-cyan' : '')}
                  >
                    <Icon type="team"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">
                      <span>Tier One</span>
                    </span>
                  </Link>
                </Menu.Item>*/}

                {/* 
                <Menu.Item key="learn"
                  className="font-500 inline-flex flex-center text-muted p-0">
                  <Link
                    to={'/teacher/chat/' + this.props.person.id}
                    disabled={isReadOnlyTeacherId(this.props.person.id)}
                    onClick={(e) => {
                      if (isSandboxNoSub(this.props.teacherBaseProps)) {
                        e.preventDefault()
                        this.showModal()
                      }
                    }
                    }
                    className={"relative p-lr-20-important onboard-chat-tab relative font-bold" +
                      (this.props.selectedKey === 'chat' ? ' text-cyan' : '')}
                  >
                    {this.props.teacherBaseProps.hasChatNotifications ?
                      <div className="absolute-tr chat-active-dot"
                        style={{ top: '30px', right: '4px' }}>
                      </div>
                      : ''}
                    <Icon type="book"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">
                      <span>Learn</span>
                    </span>
                  </Link>
                </Menu.Item>
                */}

                {/*
                <Menu.Item key="help-desk" className="inline-flex flex-center menu-no-bb p-0 mr-20 cursor-default ml-auto">
                  <div className="h-100 flex flex-v-center">
                    <Link
                      to={"/teacher/help-desk/" + this.props.person.id}
                      className="ant-btn ant-btn-lg ant-btn-primary font-bold pl-2 pr-2 inline-block-important">
                      <span className="font-bold font-18">Help Desk</span>
                    </Link>
                  </div>
                </Menu.Item>
                */}

                {/* 
                <Menu.Item key="learn" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/teacher/help-desk/' + this.props.person.id}
                    onClick={(e) => {
                      if (isSandboxNoSub(this.props.teacherBaseProps)) {
                        e.preventDefault()
                        this.showModal()
                      }
                    }
                    }
                    className={"relative p-lr-20-important onboard-reports-tab" +
                      (this.props.selectedKey === 'learn' ? ' text-cyan' : '')}>
                    <Icon type="bulb"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Learn</span>
                  </Link>
                </Menu.Item>
                */}

                {isReadOnlyTeacherId(this.props.person.id) ?
                  <Menu.Item key="contact-us" className="inline-flex flex-center menu-no-bb p-0 cursor-default ml-auto" style={{ marginRight: '12px' }}>
                    <div className="h-100 flex flex-v-center">
                      <Link
                        to={"/teacher/contact-us/" + this.props.person.id}
                        className="ant-btn ant-btn-lg ant-btn-primary font-bold pl-2 pr-2 inline-block-important onboard-contact-us-btn">
                        <Icon type="mail" className="font-bold font-24 mr-05 "
                          style={{ verticalAlign: "-5px" }} />
                        <span className="font-bold font-20">Contact us</span>
                      </Link>
                    </div>
                  </Menu.Item>
                  : ''}

                {!isReadOnlyTeacherId(this.props.person.id) ?
                  <Menu.Item key="contact-us" className="inline-flex flex-center menu-no-bb p-0 cursor-default ml-auto" style={{ marginRight: '12px' }}>
                    <div className="h-100 flex flex-v-center">
                      <Link
                        to={"/teacher/help/" + this.props.person.id}
                        className="ant-btn ant-btn-lg font-bold pl-2 pr-2 inline-block-important">
                        <span className="font-bold font-20">Need help?</span>
                      </Link>
                    </div>
                  </Menu.Item>
                  : ''}

                <Menu.Item key="general-notifications" className="inline-flex flex-v-center menu-no-bb p-0 cursor-default" style={{ marginRight: '12px' }}>
                  <GeneralNotifications
                    teacherBaseProps={this.props.teacherBaseProps}
                    history={this.props.history}
                  />
                </Menu.Item>

                {/*<Menu.Item key="search" className="inline-flex flex-v-center menu-no-bb ml-auto">
                <Select
                  showSearch
                  className="ant-select-very-large cursor-text br-100"
                  placeholder={'Search students'}
                  showArrow={false}
                  onChange={this.onChange}
                  onSelect={this.onSelect}
                  style={{ width: '350px' }}
                >
                  {this.props.students && this.props.students.map((student, index) => {
                    return <Option
                      key={student.id}
                      value={student.firstName + ' ' + student.lastName}
                      title={student.firstName + ' ' + student.lastName}
                    >
                      <div className="w-100 flex flex-v-center">
                        <PersonAvatar person={student} size={'default'} />
                        <span className="ml-auto text-muted font-500 font-13">
                        Grade: {student.grade}</span>
                      </div>
                    </Option>
                  })
                  }
                </Select>
                <Icon type="search" className="font-16 select-suffix" />
                </Menu.Item>*/}


                {!isReadOnlyTeacherId(this.props.person.id) ?
                  <Menu.Item key="teacher-sign-out"
                    className="inline-flex flex-v-center menu-no-bb p-0 cursor-default">
                    <Popover
                      visible={this.state.accountPopoverVisible}
                      onVisibleChange={this.accountVisibleChange}
                      placement="bottomRight"
                      title={
                        <div className="pt-1 pb-1" style={{ width: '225px' }}>
                          <Avatar
                            size="large"
                            className="inline-block mr-8 avatar-vlg"
                            style={{ backgroundColor: '#d3adf7' }}
                          >
                            <span className="font-bold font-20 font-black">
                              {getInitials(this.props.person)}
                            </span>
                          </Avatar>
                          <span className="font-20 font-bold vertical-align-middle inline-block ellipsis"
                            style={{ maxWidth: '164px' }}>
                            {this.props.person.firstName + " " + this.props.person.lastName}
                          </span>
                          {this.props.user &&
                            this.props.user.hasOwnProperty("isSandbox") &&
                            this.props.user.isSandbox &&
                            this.props.user.hasOwnProperty("subscriptionDaysLeft") &&
                            this.props.user.subscriptionDaysLeft ?
                            <div className="mt-2">
                              {(this.props.user.subscriptionDaysLeft < 0 ?
                                0 :
                                this.props.user.subscriptionDaysLeft > 365 ?
                                  365 :
                                  this.props.user.subscriptionDaysLeft) +
                                " days remaining in subscription."}
                            </div>
                            : ''}
                          {/*this.props.currentTimeStamp && 
                      this.props.user && 
                      this.props.user.role === 'teachers' && 
                      this.props.user.hasOwnProperty("isSandbox") &&
                      this.props.user.isSandbox ?
                        <div>
                        <div className="mt-2 font-14">{moment.utc(this.props.user
                            .timeStamp.seconds * 1000).add(91, 'days').diff(
                          this.props.currentTimeStamp, 
                            'days') + ' days remaining in subscription'}</div>
                        <div className="mt-1 w-200">
                        If you are enjoying Dot It IEP, ask your principal
                      or district leaders to reach out by emailing
                      <span className="text-cyan font-bold ml-1">support@dotit.app</span>.
                        </div>
                        </div>
                            : ''*/}
                        </div>
                      } content={
                        <div className="p-0 border-right-none negate-popover-padding pt-1 pb-1"
                          style={{ width: '270px' }}>
                          <div className="ant-menu-item p-0">
                            <Link
                              to={'/teacher/all-records/' + this.props.person.id}
                              className="block font-bold menu-item-a-link" style={{ padding: '12px', paddingLeft: '16px', paddingRight: '16px' }}>
                              <Icon type="solution"
                                className={"va-middle " +
                                  "mr-2 br-50 font-24"} />
                              <span className="va-minus-4 font-20">Records</span>
                            </Link>
                          </div>

                          <div className='pt-1 mt-1' style={{ borderTop: '1px solid #e8e8e8' }}>
                            <div className="ant-menu-item p-0" onClick={this.signOut}>
                              <span className="block font-bold" style={{ padding: '12px', paddingLeft: '16px', paddingRight: '16px' }}>
                                <Icon type="logout"
                                  className={"va-middle " +
                                    "mr-2 br-50 font-24"} />
                                <span className="va-minus-4 font-20">Sign Out</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      } trigger="click">
                      <span className="h-80px inline-flex flex-v-center cursor-pointer mr-20">
                        <span className="inline-block-important">
                          <Avatar
                            size="large"
                            style={{ backgroundColor: '#d3adf7' }}
                            className="avatar-vlg"
                          >
                            <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                          </Avatar>
                        </span>
                      </span>
                    </Popover>
                  </Menu.Item> :
                  <div className="inline-flex flex-v-center">
                    <div
                      className="inline-flex flex-v-center menu-no-bb pr-20">
                      <Popover
                        visible={this.state.accountPopoverVisible}
                        onVisibleChange={this.accountVisibleChange}
                        placement="bottomLeft"
                        title={
                          <div className="pt-1 pb-1">
                            <Avatar
                              size="large"
                              className="inline-block mr-8"
                              style={{ backgroundColor: '#d3adf7' }}
                            >
                              <span className="font-bold font-black">T</span>
                            </Avatar>
                            <span className="font-16 font-bold vertical-align-middle">
                              Example Teacher
                            </span>
                          </div>
                        } content={
                          <div className="p-0 border-right-none negate-popover-padding">
                            <a href="https://dotit.app/" className="ant-menu-item p-0">
                              <span className="font-16 p-2 block font-bold">
                                <Icon type="arrow-left"
                                  className="mr-3 font-24 va-middle" />
                                <span className="va-minus-1">Back to home page</span>
                              </span>
                            </a>
                          </div>
                        } trigger="click">
                        <span className="h-80px inline-flex flex-v-center cursor-pointer">
                          <Avatar
                            size="large"
                            style={{ backgroundColor: '#d3adf7' }}
                          >
                            <span className="font-bold font-black">T</span>
                          </Avatar>
                        </span>
                      </Popover>
                    </div>
                  </div>
                }

              </Menu>
            </div> : ''}
        </Header>
      </div>
    )
  }
}

export default TeacherHeader