import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { Icon, Form, Input, Button, message } from 'antd'
const FormItem = Form.Item


const errorMessage = (description) => {
  // stays permanently, I'm destroying when form is submitted.
  message.error(description, 0)
}

class SetupAccount extends Component {
  state = {
    user: {},
    admin: {},
    signingIn: false,
    hideEmail: false,
    userInfo: null,
    authListener: null,
  }

  componentDidMount() {
    document.title = "Setup account - Dot It"

    var authListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log("signed in user", user)
        this.setState({
          user: user,
        }, () => {
          db.collection(ColType.users)
            .doc(user.uid)
            .get()
            .then((doc) => {
              var userInfo = flattenDoc(doc)
              console.log("userinfo", userInfo)
              if (userInfo.accessLevel === 'superAdmin') {
                window.location.href = '/super-admin/add-initial-admin'
                return
              }
              this.setState({
                userInfo: userInfo,
              })
              this.props.form.setFieldsValue({
                email: userInfo.emailAddress,
              })
            })
        })
      } else {
        console.log("signed out user", user)
        // User is signed out.
        this.setState({
          user: user,
        })
      }
    })

    this.setState({
      authListener: authListener,
    })
  }

  componentWillUnmount() {
    if (this.state.authListener) {
      this.state.authListener()
    }
  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    if (!this.state.userInfo) {
      errorMessage("Internal error: User not found. " +
        "Please report this error message to support@dotit.app")
      return
    }
    this.props.form.validateFields((err, values) => {
      message.destroy() // destroy previous error messages on form submit.
      if (!err) {
        console.log('Received values of form: ', values)
        var user = firebase.auth().currentUser

        if (user === null) {
          errorMessage("Internal error: User is null. " +
            "Please report this error message to support@dotit.app")
          return
        }
        else if (values.password !== values.confirmPassword) {
          errorMessage("Passwords do not match, please try again.")
          return
        }
        else if (values.password.length < 6 || values.confirmPassword.length < 6) {
          errorMessage("Password must be at least 6 characters.")
          return
        }

        this.setState({
          signingIn: true,
        }, () => {
          user.updatePassword(values.password)
            .then(() => {
              console.log("User updated password, updating setup completed flag")
              // update completedSetup flag so that the users don't have to set their
              // passwords everytime they log in. Then link them to their account page.
              db.collection(ColType.users)
                .doc(this.state.userInfo.id)
                .update({
                  completedSetup: true,
                })
                .then(() => {
                  db.collection(this.state.userInfo.accessLevel)
                    .doc(this.state.userInfo.refId)
                    .get()
                    .then((doc) => {
                      var userJobInfo = flattenDoc(doc)
                      console.log(this.state.userInfo, userJobInfo)
                      linkAfterLogin(this.state.userInfo, userJobInfo)
                    })
                    .catch((e) => {
                      console.log(e)
                      errorMessage("Internal error: Linking failed. " +
                        "Please report this error message to support@dotit.app")
                      this.setState({
                        signingIn: false,
                      })
                    })
                })
                .catch((e) => {
                  console.log(e)
                  errorMessage("Internal error: Updating user failed. " +
                    "Please report this error message to support@dotit.app")
                  this.setState({
                    signingIn: false,
                  })
                })
            })
            .catch((e) => {
              console.log(e)
              errorMessage("Internal error: Setting password failed. " +
                "Please report this error message to support@dotit.app")
              this.setState({
                signingIn: false,
              })
            })
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <div className="h-100 pt-4 pb-70">
        <div className="block w-100 m-lr-auto mb-3 text-center">
          <img src='/dotitlogo.png' alt='logo' height='64' />
        </div>
        <div className="g-col p-4 w-500 m-lr-auto br-15 ant-shadow-more">
          <div className="mb-0 text-center font-30 font-bold">Set Your Password</div>
          <div className="font-18 text-center mb-3">
            It looks like this is your first time signing in.<br />What do you
            want your password to be?
          </div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <FormItem {...formItemLayout} label="Email address">
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input your email address.' }],
              })(
                <Input
                  size={"large"}
                  disabled={true}
                  placeholder="Email Address..." />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input a password.' }],
              })(
                <Input
                  size={"large"}
                  type="password"
                  placeholder="Password..." />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Confirm Password">
              {getFieldDecorator('confirmPassword', {
                rules: [{ required: true, message: 'Please input a password.' }],
              })(
                <Input
                  size={"large"}
                  type="password"
                  placeholder="Password..." />
              )}
            </FormItem>
            <FormItem className="mb-0">
              <Button
                disabled={this.state.signingIn}
                size={"large"}
                type="primary"
                htmlType="submit"
                className="full-width-btn mt-05">
                {!this.state.signingIn ?
                  <span className="font-bold">Submit</span> :
                  <span className="font-bold">
                    <Icon type="loading" className="mr-8" />Submitting...</span>
                }
              </Button>
            </FormItem>
          </Form>
        </div>

        {/*<div className="text-center mt-4 font-16 font-bold">
            <Link to="/free-trial" className="text-cyan">
              Don't have an account? Get a 3 month free trial!
            </Link>
        </div>*/}
      </div>

    )
  }
}

export default Form.create()(SetupAccount)