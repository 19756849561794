import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
//import ReactToPrint from "react-to-print"
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import { flattenDoc } from '../../Util'
import ColType from '../../Types'
import { Modal, Button, Icon, notification, Upload, Progress, Popconfirm, Tooltip } from 'antd'
import Empty from '../../customcomponents/Empty'
import WeeklyPlanner from './WeeklyPlanner'
const moment = require('moment')
const Dragger = Upload.Dragger
const uuid = require("uuid")

//var moment = require('moment')

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}


class WeeklyPlannerUploads extends Component {
    state = {
        uploadedFiles: [],
        uploadedFilesFileNameDict: {},
        downloadURLs: {},
        uploadedFilesListener: null,
        uploadedFilesMounted: false,
        modalVisible: false,
        fileModalVisible: false,
        editModalVisible: false,
        selectedUploadedFile: null,
        uploadingFile: null,
        selectedFile: null,
        week: -1,
        storagePath: "gs://weekly-planner-uploads",
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData = () => {
        if (!(this.props.teacherBaseProps && this.props.teacherBaseProps.teacher &&
            this.props.districtId && this.props.personId && this.props.week)) {
            return
        }
        var storage = firebase.app().storage(this.state.storagePath)
        if (!storage) {
            return
        }
        if (this.props.week === this.state.week) {
            return
        }

        this.setState({
            week: this.props.week,
        })

        if (this.state.uploadedFilesListener) {
            this.state.uploadedFilesListener()
        }

        let uploadedFilesListener = db.collection(ColType.weeklyPlannerUploadedFiles)
            .where('districtId', '==', this.props.districtId)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
            .where('week', '==', this.props.week)
            .where('ownerId', '==', this.props.personId)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let uploadedFiles = []
                let uploadedFilesFileNameDict = {}
                querySnapshot.forEach((doc) => {
                    let uploadedFile = flattenDoc(doc)
                    storage.ref(uploadedFile.filePath).getDownloadURL()
                        .then((url) => {
                            let downloadURLs = this.state.downloadURLs
                            downloadURLs[uploadedFile.id] = url
                            this.setState({ downloadURLs: downloadURLs })
                        })
                    uploadedFiles.push(uploadedFile)
                    uploadedFilesFileNameDict[uploadedFile.fileName] = uploadedFile
                })

                //console.log('uploadedfiles', uploadedFiles)

                this.setState({
                    uploadedFiles: uploadedFiles,
                    uploadedFilesFileNameDict: uploadedFilesFileNameDict,
                    uploadedFilesMounted: true,
                })
            })

        this.setState({
            uploadedFilesListener: uploadedFilesListener,
        })
    }

    componentWillUnmount() {
        if (this.state.uploadedFilesListener) {
            this.state.uploadedFilesListener()
        }
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleFileModalCancel = () => {
        this.setState({
            fileModalVisible: false,
        })
    }

    handleEditCancel = () => {
        this.setState({
            editModalVisible: false,
            selectedUploadedFile: null,
        })
    }

    downloadFile = (file) => {
        if (!file) {
            console.log("downloadFile, file is null")
            return
        }
        var storage = firebase.app().storage(this.state.storagePath)
        if (!storage) {
            return
        }

        console.log(storage)
        console.log("download file", file)

        storage.ref().child(file.filePath).getDownloadURL()
            .then((url) => {
                var xhr = new XMLHttpRequest()
                xhr.responseType = 'blob'
                xhr.onload = function (event) {
                    var blob = xhr.response
                    console.log("blob", blob)
                    url = window.URL.createObjectURL(blob)
                    var a = document.createElement("a")
                    a.href = url
                    a.download = file.fileName
                    a.click()
                    window.URL.revokeObjectURL(url)
                }
                xhr.open('GET', url)
                xhr.send()
            })
            .catch((e) => {
                console.log("download file error", e)
                errorMessage("The file could not be downloaded. Try again or email support@dotit.app")
            })
    }

    deleteUploadedFile = (uploadedFile) => {
        if (!uploadedFile) {
            return
        }
        var storage = firebase.app().storage(this.state.storagePath)
        if (!storage) {
            return
        }

        // Create a reference to the file to delete
        var fileRef = storage.ref().child(uploadedFile.filePath)

        // Delete the file
        fileRef.delete().then(() => {
            // File deleted successfully
            db.collection(ColType.weeklyPlannerUploadedFiles)
                .doc(uploadedFile.id)
                .delete()
                .then(() => {
                    successMessage("File has been deleted successfully.")
                })
                .catch((e) => {
                    console.log('error deleting doc', e)
                    errorMessage("Something went wrong deleting the file. " +
                        "Try again or email support@dotit.app.")
                })
        }).catch((e) => {
            console.log('error deleting file', e)
            errorMessage("Something went wrong deleting the file. " +
                "Try again or email support@dotit.app.")
        })
    }

    uploadFile = (file) => {
        if (!(this.props.teacherBaseProps && this.props.teacherBaseProps.teacher &&
            this.props.districtId && this.props.personId && this.props.week)) {
            return
        }
        var storage = firebase.app().storage(this.state.storagePath)
        if (!storage) {
            console.log('storage not found')
            return
        }
        console.log("uploading file", file.name)

        // if a file exists with this file name add a (1) to ...(99)
        // at the end of the file name. will overwrite a file with (99) already
        // but that shouldn't happen often.
        if (this.state.uploadedFilesFileNameDict.hasOwnProperty(file.name)) {
            var originalFileName = file.name
            // overwrite file name(99) if the same name exists for 0 to 99
            // should be fine
            for (var i = 1; i < 99; i++) {
                file.name = originalFileName + " (" + i + ")"
                if (!this.state.uploadedFilesFileNameDict.hasOwnProperty(file.name)) {
                    break
                }
            }
        }

        this.setState({
            uploadingFile: file,
        })

        var districtId = this.props.districtId
        var uuidValue = uuid.v4()
        var filePath = districtId + '/' + this.props.teacherBaseProps.teacher.id + '/' + this.props.week + '/' + uuidValue + '/' + file.name
        // '/' creates a new directory, so I a using '--' in the db and storing it
        var filePathNoSlashes = districtId + '--' + this.props.teacherBaseProps.teacher.id + '--' + this.props.week + '--' + uuidValue + '--' + file.name
        var metadataObj = {
            'ownerId': this.props.personId,
            'districtId': districtId,
            'teacherId': this.props.teacherBaseProps.teacher.id,
            'schoolId': this.props.teacherBaseProps.teacher.schoolId,
            'week': this.props.week,
            'fileName': file.name,
            'filePath': filePath,
            'filePathNoSlashes': filePathNoSlashes,
        }
        var metadata = {
            customMetadata: metadataObj
        }

        console.log('filepath', filePath)

        // Create the file using filePath in storage, so that the file will create
        // sub directories. In the db the path with no slashes will be used so that
        // sub direcotires are created.
        var fileRef = storage.ref().child(filePath)
        fileRef.put(file.originFileObj, metadata).then((snapshot) => {
            // get download url from ref now that it has been uploaded
            fileRef.getDownloadURL()
                .then((downloadURL) => {
                    // no longer storing the download url, it is retrieved through getDownloadURL
                    // add the storage bucket (don't need it now but might need it later)
                    metadataObj.bucket = snapshot.metadata.bucket
                    // add the type of file to metadata
                    metadataObj.contentType = snapshot.metadata.contentType
                    console.log(this.state.uploadedFileTitle, this.state.uploadedFileDescription)
                    if (this.state.uploadedFileTitle) {
                        metadataObj.uploadedFileTitle = this.state.uploadedFileTitle
                    } else {
                        metadataObj.uploadedFileTitle = 'Untitled upload'
                    }

                    if (this.state.uploadedFileDescription) {
                        metadataObj.uploadedFileDescription = this.state.uploadedFileDescription
                    } else {
                        metadataObj.uploadedFileDescription = 'No description'
                    }

                    // storage can't have a timestamp, so add it now
                    metadataObj.timeStamp = firebase.firestore.FieldValue.serverTimestamp()

                    if (this.props.hasOwnProperty('extraData') &&
                        this.props.extraData) {
                        Object.keys(this.props.extraData).forEach((key) => {
                            metadataObj[key] = this.props.extraData[key]
                        })
                    }

                    // add metadata obj to the db
                    db.collection(ColType.weeklyPlannerUploadedFiles)
                        .add(metadataObj)
                        .then(() => {
                            console.log("metadataObj successfully set in db.")
                            successMessage(file.name + " was uploaded successfully.")

                            if (this.props.hasOwnProperty("onSuccess") && this.props.onSuccess) {
                                this.props.onSuccess()
                            }

                            this.setState({
                                modalVisible: false,
                                uploadingFile: null,
                                uploadedFileTitle: '',
                                uploadedFileDescription: '',
                            })
                        })
                        .catch((e) => {
                            console.log("error adding metadataObj in db", e)
                            errorMessage(file.name + " could not be uploaded, " +
                                "try again or contact support@dotit.app. There is a 30 MB upload limit.")

                            this.setState({
                                uploadingFile: null,
                            })
                        })
                })
                .catch((e) => {
                    errorMessage(file.name + " could not find a download url, " +
                        "try again or contact support@dotit.app. There is a 30 MB upload limit.")
                    console.log("get download url failed", e)
                    this.setState({
                        uploadingFile: null,
                    })
                })
        })
            .catch((e) => {
                console.log('file upload error', e)
                errorMessage(file.name + " could not be uploaded, " +
                    "try again or contact support@dotit.app. There is a 30 MB upload limit.")

                this.setState({
                    uploadingFile: null,
                })
            })
    }

    onChange = (info) => {
        const status = info.file.status
        if (status !== 'uploading') {
            console.log(info.file, info.fileList)
            this.uploadFile(info.file, this.props)
        }
    }

    selectFile = (uploadedFile) => {
        console.log('uploadedfile', this.state, uploadedFile)
        this.setState({
            selectedUploadedFile: uploadedFile,
            fileModalVisible: true,
        })
    }

    render() {
        return (
            <div>
                <div className='w-100 flex flex-v-center mb-2'>
                    <div className='font-24 font-bold'>Lesson Plan and Resources</div>
                    {!this.props.hasOwnProperty('isAdmin') ||
                        (this.props.hasOwnProperty('isAdmin') && !this.props.isAdmin) ?
                        <Button type="primary" className="lg-btn ml-auto" onClick={this.showModal}>
                            <Icon type="upload" /> Upload file
                        </Button>
                        : ''}
                </div>
                <Modal
                    title="Upload file"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <div className='font-20 font-bold mb-1'>Upload file</div>
                    {this.state.uploadingFile ?
                        <div style={{ height: "430px" }} className="w-100 br-4 border">
                            <div className="flex flex-v-center flex-h-center p-3 w-100 h-100 text-center">
                                <div className="w-100">
                                    <h2 className="ellipsis font-bold mb-0" title={this.state.uploadingFile.name}>
                                        {this.state.uploadingFile.name}
                                    </h2>
                                    <Progress percent={100} status="active" showInfo={false} />
                                    <h2 className="mt-2 mb-0">
                                        Uploading...
                                    </h2>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <Dragger
                                name={'file'}
                                disabled={this.state.uploadingFile}
                                showUploadList={false}
                                directory={false}
                                multiple={false}
                                onChange={this.onChange}
                            >
                                <div className="pt-150 pb-150">
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                    </p>
                                    <p className="ant-upload-text">Click or drag and drop a file to this area to upload.</p>
                                </div>
                            </Dragger>
                        </div>}
                </Modal>

                <Modal
                    title={this.state.selectedUploadedFile ?
                        this.state.selectedUploadedFile.fileName
                        : 'View File'}
                    visible={this.state.fileModalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleFileModalCancel}
                    wrapClassName="fullscreen-modal"
                    style={{ top: '50px', 'left': '50px' }}
                >
                    {!(this.state.selectedUploadedFile &&
                        this.state.downloadURLs &&
                        this.state.downloadURLs.hasOwnProperty(this.state.selectedUploadedFile.id) &&
                        this.state.downloadURLs[this.state.selectedUploadedFile.id]) ?
                        <Empty
                            containerClassName="mt-2 text-center"
                            width={55}
                            height={55}
                            image={'/empty/goals.png'}
                            description={'There is currently no selected file.'}
                        />
                        :
                        <iframe
                            src={this.state.downloadURLs[this.state.selectedUploadedFile.id]}
                            title={this.state.selectedUploadedFile.fileName}
                            width="100%"
                            style={{ height: 'calc(100% - 8px)' }}
                        />
                    }
                </Modal>

                {this.state.uploadedFilesMounted ?
                    this.state.uploadedFiles.length === 0 ?
                        <Empty
                            containerClassName="mt-2 text-center"
                            width={55}
                            height={55}
                            image={'/empty/goals.png'}
                            description={'There are currently no uploaded lesson plans or resources.'}
                        />
                        :
                        <div>
                            {this.state.uploadedFiles.map((uploadedFile, index) => {
                                if (!(this.state.downloadURLs &&
                                    this.state.downloadURLs.hasOwnProperty(uploadedFile.id) &&
                                    this.state.downloadURLs[uploadedFile.id])) {
                                    return false
                                }
                                return <div className='flex flex-v-center w-100 mb-3'>
                                    <div
                                        className='w-100 p-3 br-20 background-fff ant-shadow ant-shadow-more-hover parent-hover relative font-20 font-black mr-2 cursor-pointer'
                                        onClick={(e) => {
                                            this.selectFile(uploadedFile)
                                        }}
                                    >
                                        <div>{uploadedFile.fileName}</div>
                                        <div className='text-muted font-16'>
                                            {uploadedFile.timeStamp && uploadedFile.timeStamp.hasOwnProperty('seconds') &&
                                                uploadedFile.timeStamp.seconds ?
                                                moment.utc(uploadedFile.timeStamp.seconds * 1000).format('MM/DD/YYYY')
                                                : ''}
                                        </div>
                                    </div>
                                    {this.props.personId &&
                                        uploadedFile.hasOwnProperty('ownerId') &&
                                        uploadedFile.ownerId === this.props.personId ?
                                        <div className='flex flex-v-center'>
                                            <Tooltip title='Download file'>
                                                <Button
                                                    type="default"
                                                    className="br-50 flex flex-v-center flex-h-center font-20 mr-2"
                                                    style={{
                                                        padding: '16px',
                                                        width: '44px',
                                                        height: '44px'
                                                    }}
                                                    onClick={(e) => {
                                                        this.downloadFile(uploadedFile)
                                                    }}
                                                >
                                                    <Icon type="download" />
                                                </Button>
                                            </Tooltip>
                                            {!this.props.hasOwnProperty('isAdmin') ||
                                                (this.props.hasOwnProperty('isAdmin') && !this.props.isAdmin) ?
                                                <Popconfirm
                                                    title="Are you sure you want to delete this file?"
                                                    onConfirm={() => this.deleteUploadedFile(uploadedFile)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement='left'
                                                >
                                                    <Tooltip title='Delete file'>
                                                        <Button
                                                            type="default"
                                                            className="br-50 flex flex-v-center flex-h-center font-20"
                                                            style={{
                                                                padding: '16px',
                                                                width: '44px',
                                                                height: '44px'
                                                            }}
                                                        >
                                                            <Icon type="delete" />
                                                        </Button>
                                                    </Tooltip>
                                                </Popconfirm> : ''}
                                        </div>
                                        :
                                        <div style={{
                                            padding: '16px',
                                            width: '44px',
                                            height: '44px'
                                        }}>
                                        </div>}
                                </div>
                            })}
                        </div>
                    : ''
                }
            </div>
        )
    }
}

export default WeeklyPlannerUploads