import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
//import { firebase, db } from '../../firebase/Firebase'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
//import ColType from '../.././Types'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { getStrengths, getQueryStringParam, flattenDoc, getIDFromURL } from '../.././Util'
//import { PDFDocument, StandardFonts } from 'pdf-lib'
import download from 'downloadjs'
import { writePDFStep0 } from './FormUtils'
import { Layout, Button, Form, Input, Row, Col, DatePicker, Radio, notification } from 'antd'
import moment from 'moment'
import FormSteps from './FormSteps'
//import CustomFooter from '../../ login / CustomFooter'
const { Content } = Layout
const FormItem = Form.Item
const RadioGroup = Radio.Group

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

/*
const testPrefillObj = {
    "startDate": "8/18/2022",
    "endDate": "8/26/2023",
    "studentFirstName": "John",
    "studentLastName": "Smith",
    "studentPronouns": "he/him",
    "studentIDNumber": "5422392",
    "studentGrade": "5",
    "studentBirthdate": "12/24/2012",
    "studentAge": "10",
    "studentPrimaryLanguage": "english",
    "studentPrimaryLanguageOfFamilyGuardian": "english",
    "schoolDistrict": "Oceanside",
    "schoolOfResidenceOrChoice": "Seaside Elementary",
    "schoolTheStudentAttendsIfDifferent": "Seaside Elementary",
    "IEPType": "Annual"
}
*/

class FormStudent extends Component {
    state = {
        teacherId: null,
        studentId: null,
        teacher: null,
        selectedStudent: null,
        students: [],
        studentDict: {},
        iepDrafts: [],
        tabKey: '1',
        iepDraft: null,
        iepDraftMounted: false,
        selectedSubjects: [],
        studentStrengths: getStrengths(),
        selectedMenuKey: 'currentDraft',
        draft: null,
        // prefill obj keys
        formKeys: [
            'startDate',
            'endDate',
            'studentFirstName',
            'studentLastName',
            'studentPronouns',
            'studentIDNumber',
            'studentGrade',
            'studentBirthdate',
            'studentAge',
            'studentPrimaryLanguage',
            'studentPrimaryLanguageOfFamilyGuardian',
            'schoolDistrict',
            'schoolOfResidenceOrChoice',
            'schoolTheStudentAttendsIfDifferent',
            'IEPType',
        ],
    }

    componentDidMount() {
        //this.loadPDF(testPrefillObj)

        window.scrollTo(0, 0)

        var teacherId = getIDFromURL(window.location)
        let draftId = getQueryStringParam('draft')

        this.setState({
            teacherId: teacherId,
            draftId: draftId,
        })

        if (!draftId) {
            console.error('draftId is null')
            return
        }

        db.collection(ColType.californiaForm)
            .doc(draftId)
            .get()
            .then(doc => {
                if (doc.exists) {
                    let draft = flattenDoc(doc)
                    let formData = {}
                    for (let key of this.state.formKeys) {
                        if (draft.hasOwnProperty(key)) {
                            if (key === 'startDate' || key === 'endDate') {
                                formData[key] = moment.utc(formData[key])
                            } else {
                                formData[key] = draft[key]
                            }
                        }
                    }
                    this.props.form.setFieldsValue(formData)
                    this.setState({ draft: draft })
                }
            })
            .catch(err => {
                console.log(err)
                errorMessage('Error loading draft')
            })
    }

    loadPDF = async (prefillObj) => {
        /* 
        const existingPdfBytes = await fetch('/california/iep.pdf').then(res => res.arrayBuffer())
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // Embed the Helvetica font
        const drawFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        writePDFStep0(pdfDoc, drawFont, prefillObj)


        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
        // Trigger the browser to download the PDF document
        download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf")
        */
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                values.startDate = values.startDate.format('MM/DD/YYYY')
                values.endDate = values.endDate.format('MM/DD/YYYY')

                if (!values.schoolTheStudentAttendsIfDifferent) {
                    values.schoolTheStudentAttendsIfDifferent = ''
                }

                //this.loadPDF(values)

                // only advance step if this is the furthest step
                // completed in the draft
                let formStep = 1
                if (this.state.draft.hasOwnProperty('step') &&
                    this.state.draft.step < formStep) {
                    values.step = formStep
                }

                db.collection(ColType.californiaForm)
                    .doc(this.state.draftId)
                    .update(values)
                    .then(() => {
                        this.props.history.push(
                            {
                                pathname: '/teacher/california-form-step-one/' +
                                    this.state.teacherId + '?draft=' + this.state.draftId,
                            }
                        )
                    })
                    .catch((e) => {
                        console.log(e)
                        errorMessage('Something went wrong. Please try again.')
                    })
            }
        })
    }



    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="min-w-1120 m-lr-auto background-fff" style={{ maxWidth: '1200px' }}>
                            <div className='mt-4 flex'>

                                <div style={{ minWidth: '800px', maxWidth: '800px' }}>
                                    <div className="br-15">
                                        <div className='p-4 br-15' style={{ background: '#efdbff' }}>
                                            <div className='flex flex-v-center w-100'>
                                                <div className='mr-3'>
                                                    <h2 className="mb-05 font-20 font-bold">Step 1</h2>
                                                    <h2 className="mb-3 font-30">Student Information</h2>
                                                </div>
                                                <img src='/california/1.png' alt='draft-iep' width={250} className='ml-auto br-15 ant-shadow' />
                                            </div>
                                        </div>

                                        <div className='p-4'>
                                            <div className='font-24 font-bold mb-2'>Annual Individualized Education Program (IEP)</div>
                                            <Form onSubmit={this.handleSubmit}
                                                className="login-form text-align-left">

                                                <Row gutter={32}>
                                                    <Col span={12}>
                                                        <FormItem {...formItemBlockLayout} label={<div>
                                                            <div className='font-20 font-bold'>
                                                                IEP start date
                                                            </div>
                                                        </div>}
                                                            className="block-label">
                                                            {getFieldDecorator('startDate', {
                                                                rules: [{ required: true, message: 'Select greatest impact.' }],
                                                            })(
                                                                <DatePicker
                                                                    className='w-100'
                                                                    size={'large'}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>

                                                    <Col span={12}>
                                                        <FormItem {...formItemBlockLayout} label={<div>
                                                            <div className='font-20 font-bold'>
                                                                IEP end date
                                                            </div>
                                                        </div>}
                                                            className="block-label">
                                                            {getFieldDecorator('endDate', {
                                                                rules: [{ required: true, message: 'Select greatest impact.' }],
                                                            })(
                                                                <DatePicker
                                                                    className='w-100'
                                                                    size={'large'}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                </Row>


                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Student name
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentFirstName', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Student name
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentLastName', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Student pronouns
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentPronouns', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>


                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Student ID number
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentIDNumber', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Grade
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentGrade', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Student birthdate
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentBirthdate', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Student age
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentAge', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Primary language of the student
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentPrimaryLanguage', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Primary language(s) of the family/guardian
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentPrimaryLanguageOfFamilyGuardian', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        School district
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('schoolDistrict', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        School of residence or choice
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('schoolOfResidenceOrChoice', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        School the student attends (if different)
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('schoolTheStudentAttendsIfDifferent', {
                                                        rules: [{ required: false, message: 'Select greatest impact.' }],
                                                    })(
                                                        <Input size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        IEP type
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('IEPType', {
                                                        rules: [{ required: true, message: 'Select greatest impact.' }],
                                                    })(
                                                        <RadioGroup className='radio-group-display-block font-black'>
                                                            <Radio value="Initial" className='block ml-0 mb-05'><span className='pl-1 form-font-black'>Initial</span></Radio>
                                                            <Radio value="Annual" className='block ml-0 mb-05'><span className='pl-1 form-font-black'>Annual</span></Radio>
                                                            <Radio value="Amendment" className='block ml-0 mb-05'><span className='pl-1 form-font-black'>Amendment</span></Radio>
                                                        </RadioGroup>
                                                    )}
                                                </FormItem>

                                                <FormItem className="mb-0">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="lg-btn float-right"
                                                    >
                                                        Continue
                                                    </Button>
                                                </FormItem>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className='ml-4 w-100' style={{ minWidth: '368px' }}>
                                    <FormSteps
                                        teacherId={this.state.teacherId}
                                        draft={this.state.draft}
                                    />
                                </div>

                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default Form.create()(FormStudent)