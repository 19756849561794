import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Layout, Select, message } from 'antd'
import AdminChangeUserSchoolModal from './AdminChangeUserSchoolModal'
const { Content } = Layout

const errorMessage = (description) => {
    message.error(description)
}

class AdminChangeUserSchool extends Component {
    state = {
        newPopoverVisible: false,
        accountPopoverVisible: false,
        adminId: '',
        selectedId: null,
        teacherId: '',
        studentId: '',
        schoolId: '',
        teacher: null,
        student: null,
        teachers: null,
        students: [],
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
        calendarEvents: null,
        calendarDict: null,
        events: null,
        studentReportData: {},
        instructionalFacilitators: [],
        teacherTab: 'schedule',
        loadingData: false,
        loadedData: false,
        readOnly: true,
        selectedSchool: '',
        teachersSchoolsDict: {},
        teachersListener: null,
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Dashboard - Dot It'

        let adminId = getIDFromURL(window.location)
        let schoolId = getQueryStringParam('s')

        this.setState({
            adminId: adminId,
            schoolId: schoolId,
        })

        db.collection(ColType.admin)
            .doc(adminId)
            .get()
            .then(doc => {
                let admin = flattenDoc(doc)
                this.setState({
                    admin: admin,
                })

                db.collection(ColType.school)
                    .where('districtId', '==', admin.districtId)
                    .get()
                    .then(querySnapshot => {
                        let schools = []
                        let schoolsDict = {}
                        querySnapshot.forEach(doc => {
                            let school = flattenDoc(doc)
                            schoolsDict[school.id] = school
                            schools.push(school)
                        })

                        let teachersListener = db.collection(ColType.teacher)
                            .where('districtId', '==', admin.districtId)
                            .onSnapshot((querySnapshot) => {
                                let teachers = []
                                let teachersSchoolsDict = {}
                                querySnapshot.forEach((doc) => {
                                    let teacher = flattenDoc(doc)
                                    teachers.push(teacher)
                                    if (!teachersSchoolsDict.hasOwnProperty(teacher.schoolId)) {
                                        teachersSchoolsDict[teacher.schoolId] = []
                                    }
                                    teachersSchoolsDict[teacher.schoolId].push(teacher)
                                })
                                this.setState({
                                    schools: schools,
                                    schoolsDict: schoolsDict,
                                    selectedSchool: schools.length > 0 ? schools[0].id : '',
                                    teachers: teachers,
                                    teachersSchoolsDict: teachersSchoolsDict,
                                })
                            })

                        this.setState({
                            teachersListener: teachersListener,
                        })
                    })
            })
    }

    componentWillUnmount() {
        if (this.state.teachersListener) {
            this.state.teachersListener()
        }
    }

    render() {
        console.log(this.props)
        return (
            <div>
                <div>
                    <Layout className="content relative">
                        <Layout className='mt-100'>
                            <Content className="w-700 m-lr-auto">
                                <div className="font-30 font-bold mb-2 mt-2">
                                    Change User School - {this.props.adminBaseProps.district ? this.props.adminBaseProps.district.district : ''}
                                </div>
                                <Select size='large' value={this.state.selectedSchool} onChange={(e) => {
                                    this.setState({
                                        selectedSchool: e,
                                    })
                                }}>
                                    {this.state.schools && this.state.schools.map((school, index) => {
                                        return (
                                            <Select.Option key={school.id} value={school.id}>{school.schoolName}</Select.Option>
                                        )
                                    })}
                                </Select>
                                <div className='mt-3 font-20'>
                                    <div className='font-bold mb-1'>Teachers</div>
                                    {this.state.selectedSchool &&
                                        this.state.teachersSchoolsDict.hasOwnProperty(this.state.selectedSchool) &&
                                        this.state.teachersSchoolsDict[this.state.selectedSchool].map((teacher, idx) => {
                                            return <div key={teacher.id} className='flex w-100 flex-v-center'>
                                                <div>{teacher.firstName} {teacher.lastName}</div>
                                                <AdminChangeUserSchoolModal
                                                    teacher={teacher}
                                                    schools={this.state.schools}
                                                    adminBaseProps={this.props.adminBaseProps}
                                                />
                                            </div>
                                        })}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <CustomFooter />
            </div>
        )
    }
}

export default AdminChangeUserSchool