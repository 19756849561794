import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ColType from '../Types'
//import { Layout, Icon, Button, Checkbox, Table } from 'antd'
import ChatHome from '../teacher/chat/ChatHome'
import { flattenDoc } from '../Util'

class SchoolAdminChatHome extends Component {
    state = {
        schoolPersonnelDict: {},
        retrievedTeachers: false,
    }

    componentDidMount() {
        this.getTeachers()
    }

    componentWillReceiveProps(nextProps) {
        this.getTeachers()
    }

    getTeachers = () => {
        if (!(this.props.schoolAdminBaseProps &&
            this.props.schoolAdminBaseProps.schoolAdmin &&
            this.props.schoolAdminBaseProps.school)) {
            return
        }

        if (this.state.retrievedTeachers) {
            return
        }

        this.setState({
            retrievedTeachers: true,
        })

        let schoolPersonnelLocalDict = { 'school': this.props.schoolAdminBaseProps.school, 'teachers': [], 'instructionalFacilitators': [], 'schoolAdmins': [] }
        let schoolPersonnelDict = this.state.schoolPersonnelDict
        schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id] = schoolPersonnelLocalDict
        this.setState({
            schoolPersonnelDict: schoolPersonnelDict,
        }, () => {
            db.collection(ColType.teacher)
                .where('schoolId', '==', this.props.schoolAdminBaseProps.school.id)
                .get()
                .then((querySnapshot) => {
                    let teachers = []
                    querySnapshot.forEach((doc) => {
                        let teacher = flattenDoc(doc)
                        teachers.push(teacher)
                    })
                    let schoolPersonnelDict = this.state.schoolPersonnelDict
                    schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id]['teachers'] = schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id]['teachers'].concat(teachers)
                    this.setState({
                        schoolPersonnelDict: schoolPersonnelDict,
                    })
                })

            db.collection(ColType.instructionalFacilitator)
                .where('schoolIds', 'array-contains', this.props.schoolAdminBaseProps.school.id)
                .where('districtId', '==', this.props.schoolAdminBaseProps.school.districtId)
                .get()
                .then((querySnapshot) => {
                    let instructionalFacilitators = []
                    querySnapshot.forEach((doc) => {
                        let instructionalFacilitator = flattenDoc(doc)
                        instructionalFacilitators.push(instructionalFacilitator)
                    })
                    let schoolPersonnelDict = this.state.schoolPersonnelDict
                    schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id]['instructionalFacilitators'] = schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id]['instructionalFacilitators'].concat(instructionalFacilitators)
                    this.setState({
                        schoolPersonnelDict: schoolPersonnelDict,
                    })
                })

            db.collection(ColType.schoolAdmin)
                .where('schoolId', '==', this.props.schoolAdminBaseProps.school.id)
                .get()
                .then((querySnapshot) => {
                    let schoolAdmins = []
                    querySnapshot.forEach((doc) => {
                        let schoolAdmin = flattenDoc(doc)
                        schoolAdmins.push(schoolAdmin)
                    })
                    let schoolPersonnelDict = this.state.schoolPersonnelDict
                    schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id]['schoolAdmins'] = schoolPersonnelDict[this.props.schoolAdminBaseProps.school.id]['schoolAdmins'].concat(schoolAdmins)
                    this.setState({
                        schoolPersonnelDict: schoolPersonnelDict,
                    })
                })
        })
    }

    render() {
        return (
            <div>
                <ChatHome
                    personRole={'schoolAdmin'}
                    person={this.props.schoolAdminBaseProps.schoolAdmin}
                    schoolPersonnelDict={this.state.schoolPersonnelDict}
                    students={[]}
                    IEPGoals={[]}
                    IEPDict={{}}
                    schoolId={''}
                    chatNotificationDict={this.props.schoolAdminBaseProps.chatNotificationDict}
                    setCurrentlyViewingChatIdFunc={this.props.schoolAdminBaseProps.setCurrentlyViewingChatIdFunc}
                />
            </div>
        )
    }
}

export default SchoolAdminChatHome
