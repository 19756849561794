import React from 'react'

class TierOneDifferentiation extends React.Component {
    renderSections = () => {
        const sectionDisplayObj = {
            'remember': 'DOK 1 - Remember',
            'understand': 'DOK 2 - Understand',
            'apply': 'DOK 2 - Apply',
            'analyze': 'DOK 3 - Analyze',
            'evaluate': 'DOK 3 - Evaluate',
            'create': 'DOK 4 - Create',
        }
        const sections = ['remember', 'understand', 'apply', 'analyze', 'evaluate', 'create']
        return sections.map((sectionName) => {
            if (sectionName === 'type' || sectionName === 'standard') {
                return false
            }
            if (!sectionDisplayObj.hasOwnProperty(sectionName)) {
                return false
            }

            let content = this.props[sectionName]

            if (Array.isArray(content)) {
                content = (
                    <ul>
                        {content.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                )
            }

            return (
                <div key={sectionName}>
                    <div className='mt-3 mb-0 font-24 font-bold'>{sectionDisplayObj[sectionName]}</div>
                    <div className='font-20'>{content}</div>
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                {this.renderSections()}
            </div>
        )
    }
}

export default TierOneDifferentiation
