import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { awardsIncNotes, isReadOnlyTeacherId } from '../.././Util'
import { Button, Form, Input, message, Modal, Icon, notification } from 'antd'
const FormItem = Form.Item
const { TextArea } = Input

const errorMessage = (description) => {
    message.error(description)
}

// Using this to add students until the step form for adding students is done.
class AddSubjectNoteForm extends Component {
    state = {
        teacherId: '',
        teacher: [],
        student: null,
        submitting: false,
        visible: false,
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        // Clear form when iep id changes
        if (this.props.IEPId !== nextProps.IEPId) this.props.form.resetFields()
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }


    // add a new event to the teacher's events
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)
                    let collectionName = ColType.elaNotes
                    if (this.props.subject === 'math') {
                        collectionName = ColType.mathNotes
                    }
                    db.collection(collectionName)
                        .add({
                            iepId: this.props.IEPId,
                            teacherId: this.props.teacher.id,
                            studentId: this.props.student.id,
                            schoolId: this.props.teacher.schoolId,
                            districtId: this.props.teacher.districtId,
                            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                            message: values.message,
                        })
                        .then(() => {
                            console.log('Document updated')
                            //successMessage('Note added successfully.')
                            notification.open({
                                type: 'success',
                                message: 'Note added successfully',
                                placement: 'bottomRight'
                            })
                            this.props.form.resetFields()
                            this.setState({
                                submitting: false,
                                visible: false
                            })

                            // inc awards notes last
                            awardsIncNotes(this.props.teacher)
                        })
                        .catch((error) => {
                            console.error('Error adding document: ', error)
                            this.props.form.resetFields()
                            this.setState({
                                submitting: false,
                                visible: false,
                            })
                            errorMessage("Something went wrong when adding the note.")
                        })
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <div className="flex w-100 mb-2">
                    <div className='mr-2'>
                        <h2 className="font-bold mb-0 font-24">
                            {this.props.subject &&
                                this.props.subject === 'ela' ?
                                'ELA Notes - Description of Instruction' :
                                'Math Notes - Description of Instruction'}
                        </h2>
                        <h3 className="font-20 mb-2">
                            Document lesson progression, student strengths, and instructional decisions.
                        </h3>
                    </div>
                    <div className="ml-auto">
                        <Button
                            onClick={this.showModal}
                            disabled={isReadOnlyTeacherId(this.props.teacher.id)}
                            size={'large'}
                        >
                            <Icon type="plus" />
                            Add note
                        </Button>
                    </div>
                </div>
                <Modal
                    title="Add note"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        <FormItem {...formItemLayout} label="Lesson notes">
                            {getFieldDecorator('message', {
                                rules: [{ required: true, message: 'Required.' }],
                            })(
                                <TextArea
                                    placeholder="Lesson notes"
                                    autosize={{ minRows: 5 }}
                                />

                            )}
                        </FormItem>
                        <div className="flex flex-v-center">
                            <Button
                                size={'large'}
                                disabled={this.state.submitting}
                                onClick={this.hideModal}
                                className="ml-auto mr-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                            >
                                Add note
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>

        )
    }
}

export default Form.create()(AddSubjectNoteForm)
