import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc } from '.././Util'
import { Button, Form, Input, message, Modal, Icon, notification, Checkbox } from 'antd'
const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group
const { TextArea } = Input


// Using this to add students until the step form for adding students is done.
class ReviewQuestionForm extends Component {
    state = {
        teacherId: '',
        teacher: [],
        student: null,
        submitting: false,
        visible: false,
    }

    componentDidMount() {

    }


    showModal = () => {
        // set anonymous to true
        this.props.form.setFieldsValue({
            anonymous: true
        })
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }


    // add a new event to the teacher's events
    handleSubmit = (e) => {
        if (!(this.props.assessmentUUID && this.props.questionUUID &&
            this.props.reviewerId && this.props.person &&
            this.props.districtId)) {
            console.log('missing props', this.props)
            return
        }
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)
                    if (!values.otherReviewMessage) {
                        values.otherReviewMessage = ''
                    }
                    console.log('values', values)
                    let formObj = Object.assign({}, values)
                    formObj.reviewerId = this.props.reviewerId
                    if (!formObj.anonymous) {
                        formObj.person = this.props.person
                    }
                    formObj.districtId = this.props.districtId
                    if (this.props.hasOwnProperty('schools') && this.props.schools) {
                        let schoolIds = []
                        this.props.schools.forEach((school) => {
                            schoolIds.push(school.id)
                        })
                        formObj.schoolIds = schoolIds
                    }
                    formObj.assessmentUUID = this.props.assessmentUUID
                    formObj.questionUUID = this.props.questionUUID
                    formObj.timeStamp = firebase.firestore.FieldValue.serverTimestamp()
                    db.collection(ColType.questionReview)
                        .add(formObj)
                        .then((docRef) => {
                            console.log('Document written with ID: ', docRef.id)
                            this.setState({
                                submitting: false,
                                visible: false,
                            }, () => {
                                setTimeout(() => {
                                    this.props.form.resetFields()
                                }, 80)
                                this.hideModal()
                                notification.success({
                                    message: 'Review added',
                                    description: 'Your review has been added.',
                                    placement: 'bottomRight',
                                })
                            })
                        })
                        .catch((error) => {
                            console.error('Error adding document: ', error)
                            this.setState({
                                submitting: false,
                            }, () => {
                                notification.error({
                                    message: 'Error',
                                    description: 'There was an error adding your review.',
                                    placement: 'bottomRight',
                                })
                            })
                        })

                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Button
                    onClick={this.showModal}
                    size={'large'}
                    type='primary'
                    className='lg-btn'
                >
                    <Icon type="plus" />
                    Add review
                </Button>
                <Modal
                    title="Add review"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form font-black">
                        <FormItem {...formItemLayout} label="Question issues">
                            {getFieldDecorator('reviewChoices', {
                                rules: [{ required: true, message: 'Required.' }],
                            })(
                                <CheckboxGroup>
                                    <Checkbox className='ml-0 mb-05 flex flex-v-center' value="Does not match the standard">
                                        <span className='pl-1 font-black font-normal'>The question does not match the standard</span></Checkbox>
                                    <Checkbox className='ml-0 mb-05 flex flex-v-center' value="Incorrect answer">
                                        <span className='pl-1 font-black font-normal'>The correct answer is not correct</span></Checkbox>
                                    <Checkbox className='ml-0 mb-05 flex flex-v-center' value="Multiple correct answers">
                                        <span className='pl-1 font-black font-normal'>There are multiple correct answers</span></Checkbox>
                                    <Checkbox className='ml-0 mb-05 flex flex-v-center' value="Incorrect question">
                                        <span className='pl-1 font-black font-normal'>The question has errors in it</span></Checkbox>
                                    <Checkbox className='ml-0 mb-05 flex flex-v-center' value="Question badly formatted">
                                        <span className='pl-1 font-black font-normal'>The question is badly formatted</span></Checkbox>
                                    <Checkbox className='ml-0 mb-05 flex flex-v-center' value="Other">
                                        <span className='pl-1 font-black font-normal'>Other</span></Checkbox>
                                </CheckboxGroup>
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="Other review information (Optional)">
                            {getFieldDecorator('otherReviewMessage', {
                                rules: [{ required: false, message: 'Required.' }],
                            })(
                                <TextArea
                                    placeholder="Review information..."
                                    autosize={{ minRows: 5 }}
                                />

                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="">
                            {getFieldDecorator('anonymous', {
                                initialValue: true,
                                valuePropName: "checked",
                                rules: [{ required: false, message: 'Required.' }],
                            })(
                                <Checkbox className='ml-0 mb-05 flex flex-v-center' value={'anonymous'}>
                                    <span className='pl-1 font-black font-normal'>Anonymous</span></Checkbox>
                            )}
                        </FormItem>

                        <div className="flex flex-v-center">
                            <Button
                                size={'large'}
                                disabled={this.state.submitting}
                                onClick={this.hideModal}
                                className="ml-auto mr-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                            >
                                Add review
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>

        )
    }
}

export default Form.create()(ReviewQuestionForm)
