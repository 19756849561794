import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { capitalizeFirstChar, getFormattedProgressMonitoringText, getIDFromURL, getQueryStringParam, removeLastCommaIfNeeded } from '../.././Util'
import { Button, Modal, Row, Col, Icon } from 'antd'
import ReactToPrint from "react-to-print"
import PersonAvatar from '../../customcomponents/PersonAvatar'

class LessonTemplate extends Component {
  state = {
    teacherId: '',
    teacher: [],
    student: null,
    submitting: false,
    isPrinting: false,
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  setIsPrinting = (e) => {
    this.setState({
      isPrinting: true,
    })
  }


  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      isPrinting: false,
    })
  }

  render() {
    let strategy = ''
    let scaffolding = ''
    if (this.props.iep.iep.hasOwnProperty("given") &&
      this.props.iep.iep.given.length >= 1) {
      strategy = this.props.iep.iep.given[0]
      if (strategy.charAt(strategy.length - 1) === ',') {
        strategy = strategy.slice(0, -1)
      }
    }
    if (this.props.iep.iep.hasOwnProperty("given") &&
      this.props.iep.iep.given.length >= 2) {
      scaffolding = this.props.iep.iep.given[1]
      if (scaffolding.charAt(scaffolding.length - 1) === ',') {
        scaffolding = scaffolding.slice(0, -1)
      }
    }

    let progressMonitoringQuestions = getFormattedProgressMonitoringText(this.props.iep)

    return (
      <div className="inline-block">
        {this.props.iep && this.props.student ?
          <div>
            <Button
              size={"large"}
              className="ant-btn ant-btn-lg font-bold pl-2 pr-2 paper-btn"
              onClick={this.showModal}
            >
              <Icon type="printer" className="font-bold" />
              <span>Print assessment</span>
            </Button>
            <Modal
              title="Student Goal Assessment"
              visible={this.state.visible}
              footer={null}
              width={700}
              bodyStyle={{ padding: '0px' }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <div className="font-28 font-bold pl-3 pt-3 flex flex-v-center">
                <div className="pr-3">
                  <ReactToPrint
                    trigger={() =>
                      <Button
                        size={'large'}
                        className="ant-btn-primary"
                      >
                        <Icon type="printer" className="mr-1" />Print goal assessment
                      </Button>
                    }
                    content={() => this.content}
                  />
                </div>
              </div>
              <div ref={el => (this.content = el)}>
                <div className="p-3">
                  <div className="flex flex-v-center mb-2">
                    <span className="font-30 font-bold">
                      Student Goal Assessment
                    </span>
                  </div>
                  <div>
                    <div className="br-4">
                      <PersonAvatar person={this.props.student}
                        size={'large'}
                        containerClassName="font-24"
                        avatarClassName="font-24 font-bold"
                        personClassName="font-bold"
                      />
                      <h2 className="font-bold mb-05 mt-3">
                        {(this.props.iep.hasOwnProperty("isMTSS") && this.props.iep.isMTSS) ?
                          this.props.iep.iep.tier + " Goal" : "IEP Goal"}
                      </h2>
                      <h3 className="mb-2">
                        {this.props.iep.iep.iepParagraph}
                      </h3>
                    </div>
                  </div>
                  <div>

                    <Row gutter={0}
                      className="flex br-4 mt-2 mb-2">
                      <Col span={24}>
                        Teach and support the task in the boxes below.
                        Use this organizer to track progress on the task.
                      </Col>
                    </Row>

                    {this.props.iep.iep.hasOwnProperty('numProgressMonitoringScores') &&
                      this.props.iep.iep.numProgressMonitoringScores &&
                      this.props.iep.iep.hasOwnProperty('studentWill') &&
                      this.props.iep.iep.studentWill &&
                      this.props.iep.iep.studentWill.length >= 10 ?
                      <Row
                        style={{
                          minHeight: 'calc(100% - 340px)',
                          height: (this.props.iep.iep.progressMonitoring.length <= 2 ? '200px' : '400px')
                        }}
                      >
                        <Row
                          gutter={0}
                          className={(this.props.iep.iep.progressMonitoring.length <= 2 ? 'h-100' : 'h-50')}
                        >
                          {this.props.iep.iep.progressMonitoring.map((question, index) => {
                            let borderClassNames = ""
                            let startingAssessment = this.props.iep.iep.studentWill[0]
                            if (this.props.iep.hasOwnProperty('isMTSS') && this.props.iep.isMTSS) {
                              startingAssessment = this.props.iep.iep['given'][2]
                            }
                            if (index === 0) {
                              borderClassNames = " border-dark"
                            } else if (index === 1) {
                              startingAssessment = this.props.iep.iep.studentWill[5]
                              if (this.props.iep.hasOwnProperty('isMTSS') && this.props.iep.isMTSS) {
                                startingAssessment = this.props.iep.iep.studentWill[4]
                              }
                              borderClassNames = " border-top-dark border-right-dark border-bottom-dark"
                            } else if (index === 2) {
                              startingAssessment = this.props.iep.iep.studentWill[7]
                              if (this.props.iep.hasOwnProperty('isMTSS') && this.props.iep.isMTSS) {
                                startingAssessment = removeLastCommaIfNeeded(this.props.iep.iep.studentWill[6])
                              }
                              borderClassNames = " border-left-dark border-bottom-dark border-right-dark"
                            } else if (index === 3) {
                              startingAssessment = this.props.iep.iep.studentWill[9]
                              if (this.props.iep.hasOwnProperty('isMTSS') && this.props.iep.isMTSS) {
                                startingAssessment = this.props.iep.iep.studentWill[8]
                              }
                              // remove beginning and from string
                              if (startingAssessment.length >= 4 &&
                                startingAssessment.charAt(0) === 'a' &&
                                startingAssessment.charAt(1) === 'n' &&
                                startingAssessment.charAt(2) === 'd' &&
                                startingAssessment.charAt(3) === ' ') {
                                startingAssessment = startingAssessment.substr(4, startingAssessment.length)
                              }
                              borderClassNames = " border-bottom-dark border-right-dark"
                            }

                            startingAssessment = capitalizeFirstChar(startingAssessment)

                            return <Col span={12}
                              className={"p-2 relative h-100" + borderClassNames}
                              key={'lesson-template-' + index}>
                              {startingAssessment + ' ' + question.num + ' ' + question.info}
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                          })}
                        </Row>
                      </Row>
                      :
                      this.props.iep.iep.progressMonitoring.length === 4 &&
                        this.props.iep.iep.studentWill.length === 11 &&
                        progressMonitoringQuestions.length === 4 ?
                        <Row style={{ minHeight: 'calc(100% - 340px)', height: '400px' }}
                          className="border-dark br-4">
                          <Row gutter={0} className="border-bottom-dark flex h-50">
                            <Col span={12} className="p-2 border-right-dark relative h-100">
                              {progressMonitoringQuestions[0]}
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                            <Col span={12} className="p-2 relative h-100">
                              {progressMonitoringQuestions[1]}
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                          </Row>

                          <Row gutter={0} className="flex h-50 relative">
                            <Col span={12} className="p-2 border-right-dark relative h-100">
                              {progressMonitoringQuestions[2]}
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                            <Col span={12} className="p-2 relative h-100">
                              {progressMonitoringQuestions[3]}
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                          </Row>
                        </Row>
                        :
                        this.props.iep.iep.mainSubject !== "Social Emotional Learning" ?
                          <Row style={{ minHeight: 'calc(100% - 340px)', height: '400px' }}
                            className="border-dark br-4">
                            <Row gutter={0} className="border-bottom-dark flex h-50">
                              <Col span={12} className="p-2 border-right-dark relative h-100">
                                {this.props.iep.iep.studentWill[2]}
                                <div className="absolute-bl p-2">
                                  DATE: _________________________
                                </div>
                              </Col>
                              <Col span={12} className="p-2 relative h-100">
                                {this.props.iep.iep.studentWill[6]}
                                <div className="absolute-bl p-2">
                                  DATE: _________________________
                                </div>
                              </Col>
                            </Row>

                            <Row gutter={0} className="flex h-50 relative">
                              <Col span={12} className="p-2 border-right-dark relative h-100">
                                {this.props.iep.iep.studentWill[8]}
                                <div className="absolute-bl p-2">
                                  DATE: _________________________
                                </div>
                              </Col>
                              <Col span={12} className="p-2 relative h-100">
                                {this.props.iep.iep.studentWill[9]}
                                <div className="absolute-bl p-2">
                                  DATE: _________________________
                                </div>
                              </Col>
                            </Row>
                          </Row>
                          :
                          <Row style={{ minHeight: 'calc(100% - 340px)', height: '400px' }}
                            className="border-dark br-4">
                            <Col span={12} className="p-2 border-right-dark relative h-100">
                              {this.props.iep.iep.progressMonitoring.length > 0
                                ?
                                this.props.iep.iep.progressMonitoring[0].num + " " +
                                this.props.iep.iep.progressMonitoring[0].info
                                :
                                ""
                              }
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                            <Col span={12} className="p-2 relative h-100">
                              {this.props.iep.iep.progressMonitoring.length > 1
                                ?
                                this.props.iep.iep.progressMonitoring[1].num + " " +
                                this.props.iep.iep.progressMonitoring[1].info
                                :
                                ""
                              }
                              <div className="absolute-bl p-2">
                                DATE: _________________________
                              </div>
                            </Col>
                          </Row>
                    }

                  </div>
                </div>
              </div>
            </Modal>
          </div>
          : ''}
      </div>
    )
  }
}

export default LessonTemplate