import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ReactToPrint from "react-to-print"
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import { flattenDoc } from '../Util'
import ColType from '../Types'
import { Collapse, notification, Button, Icon, Popconfirm } from 'antd'
import Empty from '../customcomponents/Empty'
import PersonAvatar from '../customcomponents/PersonAvatar'
import SchoolPlanningReportForm from './SchoolPlanningReportForm'
import SchoolPlanningReportUpdateForm from './SchoolPlanningReportUpdateForm'
var moment = require('moment')
var html2pdf = null
if (typeof window !== 'undefined') {
    html2pdf = require('html2pdf.js')
}

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class SchoolPlanningReport extends Component {
    state = {
        documentTitle: window.document.title,
        reports: [],
        reportsMounted: false,
        reportsListener: null,
        reportKeys: [
            'adminIncentive',
            'coachesSuppor',
            'teachersPlans',
        ],
        reportKeysToDescriptions: {
            adminIncentive: "Administration will support and ensure standards-based planning by",
            coachesSupport: "Coaches will facilitate standards-based planning by",
            teachersPlans: "Teachers will engage in standards-based planning by",
        },
        administrationKeys: [
            'adminIncentive',
        ],
        coachesKeys: [
            'coachesSupport',
        ],
        teachersKeys: [
            'teachersPlans',
        ],
    }

    componentDidMount() {
        let reportsListener = db.collection(ColType.schoolPlanningReport)
            .where('districtId', '==', this.props.districtId)
            .where('schoolId', '==', this.props.schoolId)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let reports = []
                querySnapshot.forEach((doc) => {
                    reports.push(flattenDoc(doc))
                })
                this.setState({
                    reports: reports,
                    reportsMounted: true
                })
            })

        this.setState({
            reportsListener: reportsListener
        })

    }

    componentWillUnmount() {
        if (this.state.reportsListener) {
            this.state.reportsListener()
        }
    }

    render() {
        return (
            <div>
                <div className='w-100 flex flex-v-center mb-2'>
                    <div className='font-30 font-bold'>Plan Reports</div>
                    <SchoolPlanningReportForm
                        districtId={this.props.districtId}
                        schoolId={this.props.schoolId}
                        person={this.props.person}
                        accessLevel={this.props.accessLevel}
                    />
                </div>

                <div>
                    {this.state.reportsMounted ?
                        this.state.reports.length === 0 ?
                            <Empty
                                containerClassName="mt-2 text-center"
                                width={55}
                                height={55}
                                image={'/empty/goals.png'}
                                description={'No plan reports.'}
                            />
                            :
                            <Collapse bordered={false}>
                                {this.state.reports.map((report, index) => {
                                    let reportTitle = 'Report'
                                    if (report.hasOwnProperty('reportTitle') &&
                                        report.reportTitle) {
                                        reportTitle = report.reportTitle
                                    }
                                    let contentRefName = 'content-' + report.id
                                    return <Collapse.Panel header={<div className='font-20 w-100 flex flex-v-center'>
                                        <div className='ellipsis' style={{ maxWidth: '400px' }}>{reportTitle}</div>

                                        <div className='ml-auto flex flex-v-center'>
                                            {report.hasOwnProperty('person') &&
                                                report.person ?
                                                <div>
                                                    <PersonAvatar
                                                        person={report.person}
                                                        avatarColor={'#d3adf7'}
                                                        avatarTextColor={'#000'}
                                                        containerClassName="font-16"
                                                        avatarClassName="font-16 font-bold"
                                                        personClassName="font-black font-16"
                                                    />
                                                    <span className='font-16 pl-05'>{
                                                        'on ' + (report.timeStamp &&
                                                            report.timeStamp.hasOwnProperty('seconds') &&
                                                            report.timeStamp.seconds ?
                                                            moment.utc(report.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                                            :
                                                            '')}</span>
                                                </div>
                                                :
                                                <div className='font-16'>{
                                                    (report.timeStamp &&
                                                        report.timeStamp.hasOwnProperty('seconds') &&
                                                        report.timeStamp.seconds ?
                                                        moment.utc(report.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                                        :
                                                        '')}
                                                </div>
                                            }

                                        </div>
                                    </div>} key={report.id}>
                                        <div className='border-top pt-2'>
                                            <div className='font-20 mb-2 flex flex-v-center w-100 font-black'>
                                                <div className='ml-auto flex flex-v-center'>
                                                    <SchoolPlanningReportUpdateForm
                                                        districtId={this.props.districtId}
                                                        schoolId={this.props.schoolId}
                                                        report={report}
                                                    />
                                                    <ReactToPrint
                                                        trigger={() =>
                                                            <Button
                                                                size={'large'}
                                                                type="default"
                                                                className="font-20 flex flex-v-center mr-1"
                                                                style={{
                                                                    padding: '16px',
                                                                    height: '44px'
                                                                }}
                                                            >
                                                                <Icon type="download" className="mr-1" />Download
                                                            </Button>
                                                        }
                                                        content={() => this[contentRefName]}
                                                        print={async (printIframe) => {
                                                            if (!html2pdf) {
                                                                return new Promise((resolve, reject) => { resolve() })
                                                            }
                                                            const document = printIframe.contentDocument
                                                            if (document) {
                                                                const html = document.getElementsByTagName('html')[0]
                                                                await html2pdf().from(html).set({
                                                                    image: { type: 'png', quality: 1.0 },
                                                                    html2canvas: { scale: 1.0 },
                                                                    margin: 12,
                                                                    pagebreak: { mode: ['avoid-all', 'css'], avoid: '.page-break-inside-avoid-not-important' },
                                                                }).save('PlanReport.pdf')
                                                            }

                                                            return new Promise((resolve, reject) => { resolve() })
                                                        }}
                                                    />
                                                    <Popconfirm
                                                        title="Are you sure you want to delete this report?"
                                                        onConfirm={() => {
                                                            db.collection(ColType.schoolPlanningReport)
                                                                .doc(report.id)
                                                                .delete()
                                                                .then(() => {
                                                                    successMessage('Report deleted successfully.')
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error)
                                                                    errorMessage('Error deleting report, please try again.')
                                                                })
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="danger"
                                                            className="br-50 flex flex-v-center flex-h-center font-20"
                                                            style={{
                                                                padding: '16px',
                                                                width: '44px',
                                                                height: '44px'
                                                            }}
                                                        >
                                                            <Icon type="delete" />
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                            <div ref={el => (this[contentRefName] = el)}>
                                                <div className='font-black'>
                                                    <div className='flex flex-v-center pl-2 pr-2'>
                                                        <div className='font-30'>{reportTitle}</div>
                                                        <div className='ml-auto font-16'>{(report.timeStamp &&
                                                            report.timeStamp.hasOwnProperty('seconds') &&
                                                            report.timeStamp.seconds ?
                                                            moment.utc(report.timeStamp.seconds * 1000).format("MM/DD/YYYY")
                                                            :
                                                            '')}</div>
                                                    </div>
                                                    <div className='mb-2'>
                                                        {report.hasOwnProperty('reportDescription') &&
                                                            report.reportDescription ?
                                                            <div>
                                                                <div className='font-20 pl-2 pr-2'>{report.reportDescription}</div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <div className='mb-2'>
                                                        {report.hasOwnProperty('reportReflection') &&
                                                            report.reportReflection ?
                                                            <div>
                                                                <div className='page-break-inside-avoid-not-important'>
                                                                    <div className='p-2 br-15 font-24 mb-2 page-break-inside-avoid-not-important' style={{ background: '#b5f5ec' }}>Reflection</div>
                                                                </div>
                                                                <div className='font-20 pl-2 pr-2'>{report.reportReflection}</div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <div className='font-20 mb-2'>
                                                        <div className='page-break-inside-avoid-not-important'>
                                                            <div className='p-2 br-15 font-24 mb-2 page-break-inside-avoid-not-important' style={{ background: '#b5f5ec' }}>
                                                                Administration
                                                            </div>
                                                        </div>
                                                        {this.state.administrationKeys.map((key, index) => {
                                                            if (!this.state.reportKeysToDescriptions.hasOwnProperty(key)) {
                                                                return false
                                                            }
                                                            return <div className="mb-2 font-black pl-2 pr-2" key={key}>
                                                                <div className='font-20 font-bold'>{this.state.reportKeysToDescriptions[key] + ':'}</div>
                                                                <div className='font-20'>{report[key]}</div>
                                                            </div>
                                                        })}
                                                        <div className='page-break-inside-avoid-not-important'>
                                                            <div className='p-2 br-15 font-24 mb-2 page-break-inside-avoid-not-important' style={{ background: '#b5f5ec' }}>
                                                                Coaches
                                                            </div>
                                                        </div>
                                                        {this.state.coachesKeys.map((key, index) => {
                                                            if (!this.state.reportKeysToDescriptions.hasOwnProperty(key)) {
                                                                return false
                                                            }
                                                            return <div className="mb-2 font-black pl-2 pr-2" key={key}>
                                                                <div className='font-20 font-bold'>{this.state.reportKeysToDescriptions[key] + ':'}</div>
                                                                <div className='font-20'>{report[key]}</div>
                                                            </div>
                                                        })}
                                                        <div className='page-break-inside-avoid-not-important'>
                                                            <div className='p-2 br-15 font-24 mb-2 page-break-inside-avoid-not-important' style={{ background: '#b5f5ec' }}>
                                                                Teachers
                                                            </div>
                                                        </div>
                                                        {this.state.teachersKeys.map((key, index) => {
                                                            if (!this.state.reportKeysToDescriptions.hasOwnProperty(key)) {
                                                                return false
                                                            }
                                                            return <div className="mb-2 font-black pl-2 pr-2" key={key}>
                                                                <div className='font-20 font-bold'>{this.state.reportKeysToDescriptions[key] + ':'}</div>
                                                                <div className='font-20'>{report[key]}</div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        : ''}
                </div>
            </div>
        )
    }
}

export default SchoolPlanningReport