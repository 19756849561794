import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
//import PersonHeader from '../../login/PersonHeader'
import TeacherStudentRecordView from './TeacherStudentRecordView'
import CustomFooter from '../../login/CustomFooter'
import { flattenDoc } from '../../Util'
import ColType from '../../Types'
import { Layout, Button, Icon, Row, Col, Avatar } from 'antd'
import Empty from '../../customcomponents/Empty'
const Content = Layout.Content

class TeacherStudentRecords extends Component {
  state = {
    selectedRecord: null,
    studentRecordIdx: 0,
    records: [],
    students: [],
    studentDict: {},
    teachers: [],
    teacherDict: {},
    componentMounted: false,
    recordsMounted: false,
  }

  getRecordsSchoolAdmin() {
    this.setState({
      records: [],
      teachers: [],
      teacherDict: {},
      students: [],
      studentDict: {},
    }, () => {
      console.log('read only get records')
      db.collection(ColType.records)
      .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
      .where('schoolId', '==', this.props.teacherBaseProps.teacher.schoolId)
      .where('teachersWhoCanViewRecord', 'array-contains', this.props.teacherBaseProps.teacher.id)
      .get()
      .then((querySnapshot) => {
        var records = []
        querySnapshot.forEach((doc) => {
          var record = flattenDoc(doc)
          var studentRecordHasTeacherId = record.teachersWhoCanViewRecord
            .filter(teacherId => teacherId === this.props.teacherBaseProps.teacher.id)
          if (studentRecordHasTeacherId.length > 0)
            records.push(record)
        })
        console.log('records', records)
        
        this.setState({
          records: records,
        }, () => {
          this.state.records.map((record, idx) => {
            return record.studentRecords.map((studentRecord, idx) => {
              console.log('sr student', studentRecord.studentId, 
              'sr teacher', studentRecord.teacherId)
              db.collection(ColType.teacher)
              .doc(studentRecord.teacherId)
              .get()
              .then((doc) => {
                var teacher = flattenDoc(doc)
                console.log("teacher from record", teacher)
                var teachers = this.state.teachers
                var teacherDict = this.state.teacherDict
                teachers.push(teacher)
                teacherDict[teacher.id] = teacher

                this.setState({
                  teachers: teachers,
                  teacherDict: teacherDict,
                })
              })

              db.collection(ColType.student)
              .doc(studentRecord.studentId)
              .get()
              .then((doc) => {
                var student = flattenDoc(doc)
                console.log("student from record", student)
                var students = this.state.students
                var studentDict = this.state.studentDict
                students.push(student)
                studentDict[student.id] = student
                
                this.setState({
                  students: students,
                  studentDict: studentDict,
                  componentMounted: true,
                })
              })

              return false
            })
          })
        })
      
      })
    })
  }

  getRecords() {
    this.setState({
      records: [],
      teachers: [],
      teacherDict: {},
      students: [],
      studentDict: {},
    }, () => {
      db.collection(ColType.records)
      .where('districtId', '==', this.props.teacherBaseProps.teacher.districtId)
      .where('teachersWhoCanViewRecord', 'array-contains', this.props.teacherBaseProps.teacher.id)
      .get()
      .then((querySnapshot) => {
        var records = []
        querySnapshot.forEach((doc) => {
          var record = flattenDoc(doc)
          records.push(record)
        })
        console.log('records', records)
        
        this.setState({
          records: records,
        }, () => {
          this.state.records.map((record, idx) => {
            return record.studentRecords.map((studentRecord, idx) => {
              console.log('sr student', studentRecord.studentId, 
              'sr teacher', studentRecord.teacherId)
              db.collection(ColType.teacher)
              .doc(studentRecord.teacherId)
              .get()
              .then((doc) => {
                var teacher = flattenDoc(doc)
                console.log("teacher from record", teacher)
                var teachers = this.state.teachers
                var teacherDict = this.state.teacherDict
                teachers.push(teacher)
                teacherDict[teacher.id] = teacher

                this.setState({
                  teachers: teachers,
                  teacherDict: teacherDict,
                })
              })

              db.collection(ColType.student)
              .doc(studentRecord.studentId)
              .get()
              .then((doc) => {
                var student = flattenDoc(doc)
                console.log("student from record", student)
                var students = this.state.students
                var studentDict = this.state.studentDict
                students.push(student)
                studentDict[student.id] = student
                
                this.setState({
                  students: students,
                  studentDict: studentDict,
                  componentMounted: true,
                })
              })

              return false
            })
          })
        })
      
      })
    })
  }

  componentWillReceiveProps() {
    if (!this.props.teacherBaseProps.teacher || this.state.componentMounted) return
    // currently school admins can only view records that have a matching school id
    if (this.props.teacherBaseProps.schoolAdminBaseProps) this.getRecordsSchoolAdmin()
    else this.getRecords()
  }

  // Do fetch here
  componentDidMount() {
   if (!this.props.teacherBaseProps.teacher) return
   // currently school admins can only view records that have a matching school id
   if (this.props.teacherBaseProps.schoolAdminBaseProps) this.getRecordsSchoolAdmin()
   else this.getRecords()
  }

  getMinMaxGradeOfRecord = (record) => {
    var min = -1
    var max = -1
    record.studentRecords.map((studentRecord, idx) => {
      if (!this.state.studentDict.hasOwnProperty(studentRecord.studentId)) {
        return false
      }
      var s = this.state.studentDict[studentRecord.studentId]
      var g = s.grade
      if (g === 'K') g = 0
      g = parseInt(g, 10)
      if (min === -1 || g < min) {
        min = g
      }
      if (max === -1 || g > max) {
        max = g
      }
      return false
    })

    return [min, max]
  }

  render() {
    var numVisibleRecords = 0
    this.state.records.map((record, idx) => {
      if (record.studentRecords.length === 0) return false
      var studentRecord = record.studentRecords[0]
      if (!this.state.studentDict.hasOwnProperty(studentRecord.studentId)) 
        return false
      numVisibleRecords += 1
      return false
    })

    this.state.records.sort((a, b) => {
      if (!a || !b) return 0
      else if (a.studentRecords.length === 0 && b.studentRecords.length === 0) return 0
      else if (b.studentRecords.length === 0) return 1
      else if (a.studentRecords.length === 0) return -1
      var lastStudentRecordA = a.studentRecords[a.studentRecords.length - 1]
      var lastStudentRecordB = b.studentRecords[b.studentRecords.length - 1]
      if (!this.state.studentDict.hasOwnProperty(lastStudentRecordA.studentId) && 
          !this.state.studentDict.hasOwnProperty(lastStudentRecordB.studentId)) return 0
      else if (!this.state.studentDict.hasOwnProperty(lastStudentRecordB.studentId)) return -1
      else if (!this.state.studentDict.hasOwnProperty(lastStudentRecordA.studentId)) return 1
      var lastStudentA = this.state.studentDict[lastStudentRecordA.studentId]
      var lastStudentGradeA = lastStudentA.grade === 'K' ?  
      'K' : parseInt(lastStudentA.grade, 10)
      var lastStudentB = this.state.studentDict[lastStudentRecordB.studentId]
      var lastStudentGradeB = lastStudentB.grade === 'K' ?  
      'K' : parseInt(lastStudentB.grade, 10)
      if (lastStudentGradeA === lastStudentGradeB) return 0
      else if (lastStudentGradeA !== 'K' && lastStudentGradeB === 'K') return 1
      else if (lastStudentGradeA === 'K' && lastStudentGradeB !== 'K') return -1
      else if (lastStudentGradeA > lastStudentGradeB) return 1
      else if (lastStudentGradeA < lastStudentGradeB) return -1
      return 0
    })

    console.log(this.props, this.state, numVisibleRecords)
    return (
      
      <div>
      <Layout className="content layout-header-mt">
      <Layout>
          <Content className="layout-content">
            <div className="sub-menu-width m-lr-auto">
            <h1 className="flex flex-v-center mb-3 pb-3 border-bottom">
                 <span className="va-middle">Student Records</span>
               </h1>
            {!this.state.selectedRecord ?
                
              <div>
                
              <Row gutter={24}>
                {this.state.componentMounted && numVisibleRecords === 0 ?
                  <div className="text-center mt-1">
                    <Empty />
                  </div>
                : ''}
                {this.state.records.map((record, idx) => {
                  if (record.studentRecords.length === 0) return false
                  var studentRecord = record.studentRecords[0]
                  if (!this.state.studentDict.hasOwnProperty(studentRecord.studentId)) 
                    return false
                  var student = this.state.studentDict[studentRecord.studentId]
                  var minMaxGrade = this.getMinMaxGradeOfRecord(record)
                  var showMinAndMax = true
                  if (minMaxGrade[0] === minMaxGrade[1]) {
                    showMinAndMax = false
                  }
                  if (minMaxGrade[0] === 0) minMaxGrade[0] = 'K'
                  if (minMaxGrade[1] === 0) minMaxGrade[1] = 'K'
                  return <Col span={8}>
                  <div 
                  className={"w-100 br-4 h-150 up-hover" +
                  " shadow-hover ant-btn mb-3" +
                  " ant-btn border-cyan relative parent-hover"}
                  onClick={() => this.setState({selectedRecord: record, studentRecordIdx: 0})}>
                  <div className="w-100 h-100 flex flex-center text-left ml-1 mr-1">
                          <div className="w-100">
                            <div className="font-16 mb-1 mt-1">
                              <Avatar 
                                size="large" 
                                className="mr-8 font-16" 
                                style={{ backgroundColor: student.avatarColor}}
                              >
                                {student.grade}
                              </Avatar>
                              <div className="inline-block va-minus-14">
                              <div className="font-18">
                      {student.firstName + " " + student.lastName}</div> 
                      {!showMinAndMax ? 
                        <div className="font-16">{'Grade: ' + minMaxGrade[0]}</div> :
                        <div className="font-16">
                        {'Grade: ' + minMaxGrade[0] + '-' + minMaxGrade[1]}</div>
                      }
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                </Col>
                })}
                </Row>
              </div>
          : ''}
          {this.state.selectedRecord ? 
            <div className="sub-menu-width m-lr-auto mt-3">
             <Row className="border-bottom mb-2 pb-2">
                   <Col span={8}>
                 <div>
                  <Button className="font-18 btn-transparent" 
                  onClick={() => this.setState({selectedRecord: null})}>
                    <Icon type="arrow-left" className="mr-1"/>
                    <span>Back to student records</span>
                  </Button>
                 </div>
                 </Col>
                 <Col span={16}>
                <div className="font-24">
                    {'Record for ' + (this.state
                  .studentDict[this.state.selectedRecord
                    .studentRecords[0].studentId].firstName + ' ' +
                    this.state
                  .studentDict[this.state.selectedRecord
                    .studentRecords[0].studentId].lastName)}
                </div>
            </Col>
            </Row>
             <Row gutter={32}>
                 <Col span={8}>
                 <div className="mt-1">
                  <div className="mb-2 font-24">Teachers who have taught this student</div>
                  {this.state.selectedRecord.studentRecords.map((studentRecord, idx) => {
                    if (!this.state.teacherDict
                      .hasOwnProperty(studentRecord.teacherId)) return false
                    var teacher = this.state
                    .teacherDict[studentRecord.teacherId]
                    return <div className={'font-18 menu-cyan-hover cursor-pointer p-1 br-4' + 
                    (idx === this.state.studentRecordIdx ? ' menu-cyan-active font-bold': '')}
                    onClick={() => this.setState({ studentRecordIdx: idx })}>
                      {teacher.firstName + " " + teacher.lastName}
                    </div>
                  })}
                  </div>
                 </Col>
                 <Col span={16}>
              {this.state.teacherDict.hasOwnProperty(this.state.selectedRecord
                    .studentRecords[this.state.studentRecordIdx].teacherId) &&
                this.state.studentDict.hasOwnProperty([this.state.selectedRecord
                  .studentRecords[this.state.studentRecordIdx].studentId]) ?
              <TeacherStudentRecordView 
                record={this.state.selectedRecord} 
                teacher={this.state
                  .teacherDict[this.state.selectedRecord
                    .studentRecords[this.state.studentRecordIdx].teacherId]}
                student={this.state
                  .studentDict[this.state.selectedRecord
                    .studentRecords[this.state.studentRecordIdx].studentId]} 
              />
             
              : ''}
               </Col>
              </Row>
            </div>
          : ''}
          </div>
          </Content>
      </Layout>
      </Layout>
        <CustomFooter />
        </div>
    )
  }
}

export default TeacherStudentRecords
