import React, { lazy, Suspense, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Icon as AntdIcon, Progress } from 'antd';
import { ResponsiveLine } from '@nivo/line';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addCommasToNumbersInString, addCommasToNumbersInStringLatex } from '../../Util';
import { min } from 'moment';
import * as recharts from 'recharts';
import * as d3 from 'd3';

library.add(fas);

const COLORS = {
  green: '#74c476', // A green that complements the given blue
  blue: '#6baed6',  // The given blue color
  orange: '#fd8d3c', // A complementary orange
  purple: '#9e9ac8', // A complementary purple
  cyan: '#41b6c4',  // A complementary cyan
  pink: '#d95f02',   // A complementary pink
  red: '#c47474',
  yellow: '#e7d852',
  brown: '#c49474',
  pink: '#c474b8',
};

const LIGHTER_COLORS = {
  green: '#96d398',
  blue: '#90c2e0',
  orange: '#fda86d',
  purple: '#b7b6d6',
  cyan: '#71cbd5',
  pink: '#e27f41',
  red: '#d39696',
  yellow: '#ede47d',
  brown: '#d3b096',
  pink: '#d990e0',
};

function getColorValue(color) {
  const defaultColor = '#6baed6';
  if (!color) {
    return defaultColor;
  }
  if (!(COLORS.hasOwnProperty(color) && COLORS[color])) {
    return defaultColor;
  }

  return COLORS[color];
}

function getLighterColorValue(color) {
  const defaultColor = '#35576b';
  if (!color) {
    return defaultColor;
  }
  if (!(LIGHTER_COLORS.hasOwnProperty(color) && LIGHTER_COLORS[color])) {
    return defaultColor;
  }

  return LIGHTER_COLORS[color];
}

const MathComponent = lazy(() => import('mathjax-react').then(module => ({ default: module.MathComponent })));

const H1 = (props) => {
  let value = '';
  if (props.hasOwnProperty('content')) {
    value = props.content;
  } else if (props.hasOwnProperty('heading')) {
    value = props.heading;
  }
  return <h1>{value}</h1>;
};

const H2 = (props) => {
  let value = '';
  if (props.hasOwnProperty('content')) {
    value = props.content;
  } else if (props.hasOwnProperty('heading')) {
    value = props.heading;
  }
  return <h2>{value}</h2>;
};

const H3 = (props) => {
  let value = '';
  if (props.hasOwnProperty('content')) {
    value = props.content;
  } else if (props.hasOwnProperty('heading')) {
    value = props.heading;
  }
  return <h3>{value}</h3>;
};

const H4 = (props) => {
  let value = '';
  if (props.hasOwnProperty('content')) {
    value = props.content;
  } else if (props.hasOwnProperty('heading')) {
    value = props.heading;
  }
  return <h4>{value}</h4>;
};

const P = ({ content }) => <div className='mb-2 font-20'>
  {Array.isArray(content) ? content.map((item, index) => <span key={index}>{renderContent(item)}</span>) :
    renderContent(content)}
</div>;

const ItemList = ({ items }) => <ul>
  {items.map((item, index) => {
    if (item && item.hasOwnProperty('type') && item.type && item.type.toLowerCase() === 'linebreak') {
      return false;
    }
    return <li key={index}>
      {renderContent(item)}
    </li>
  })}
</ul>;

const EnumList = ({ items }) => <ol>
  {items.map((item, index) => {
    if (item && item.hasOwnProperty('type') && item.type && item.type.toLowerCase() === 'linebreak') {
      return false;
    }
    return <li key={index}>{renderContent(item)}</li>
  })}
</ol>;

const Heading1 = ({ content }) => (
  <div role="heading" aria-level="1" className="heading1">
    {renderContent(content)}
  </div>
);

const Heading2 = ({ content }) => (
  <div role="heading" aria-level="2" className="heading2">
    {renderContent(content)}
  </div>
);

const Heading3 = ({ content }) => (
  <div role="heading" aria-level="3" className="heading3">
    {renderContent(content)}
  </div>
);


const Bold = ({ content }) => <strong>{renderContent(content)}</strong>;

const BoldText = ({ content }) => <span style={{ fontWeight: 'bold' }}>{content}</span>;

const Italic = ({ content }) => <em>{renderContent(content)}</em>;

const Underline = ({ content }) => <u>{renderContent(content)}</u>;

const UnderlineText = ({ content }) => <span style={{ textDecoration: 'underline' }}>{content}</span>;

const Blank = ({ width }) => <span style={{ display: 'inline-block', width: `${width}em` }}>&nbsp;</span>;

const Linebreak = () => <div className='mt-2'></div>;

const LineBreak = () => <div className='mt-2'></div>;

const Text = ({ content }) => <span>{content}</span>;

const Equation = ({ content }) => {
  const formatNumber = (str) => {
    // Updated regex to match only sequences of individual numbers
    const isSequence = /^-?\d+(?:,\s*-?\d+){2,}$/.test(str.replace(/,/g, '').trim());
    const isOrderedPairSequence = /^\(\s*-?\d+(?:,\s*-?\d+)?\)(?:,\s*\(\s*-?\d+(?:,\s*-?\d+)?\)){2,}$/.test(str.trim());

    if (isSequence || isOrderedPairSequence) {
      return str;
    }

    return str
      .replace(/(\d),(\d)/g, '$1{,}$2')  // Preserve commas in number sequences
      .replace(/(,)(?=\D|$)/g, ', ')     // Add space after commas only if not followed by a digit
      .replace(/:\s*/g, '{\\colon}')
      .replace(/\s*÷\s*/g, '{\\thinspace}÷{\\thinspace}')
      .replace(/\s*×\s*/g, '{\\thinspace}×{\\thinspace}')
      .replace(/\{,\}/g, ',');           // Revert the temporary replacement for commas in number sequences
  };


  const formattedContent = formatNumber(content);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="force-all-inline inline-block">
        <MathComponent tex={formattedContent} />
      </div>
    </Suspense>
  );
};

const Body = ({ content }) => (
  <div className="body">
    <ComponentRenderer component={content} />
  </div>
);

const Passage = ({ title, body }) => (
  <div className="passage" style={{ margin: '20px 0', padding: '16px 10px', backgroundColor: '#f9f9f9', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
    <h3 style={{ textAlign: 'center', marginBottom: '15px', fontSize: '1.2em', fontWeight: 'bold' }}>{title}</h3>
    <div style={{ margin: '0 20px', lineHeight: '1.6', fontSize: '1em' }}>
      <Body content={body.content} />
    </div>
  </div>
);


const Slicedgeometry = ({ shape, slices, shaded, color }) => {
  const SHADED_COLOR = getColorValue(color);
  const STROKE_COLOR = '#000000';
  const radius = 100;
  const size = 200;

  const data = Array.from({ length: slices }, (_, i) => ({
    name: `Slice ${i}`,
    value: 1,
  }));

  const drawCircle = () => (
    <recharts.PieChart width={radius * 2} height={radius * 2}>
      <recharts.Pie
        data={data}
        dataKey="value"
        outerRadius={radius}
        innerRadius={0}
        startAngle={0}
        endAngle={360}
        isAnimationActive={false}
      >
        {data.map((_, index) => (
          <recharts.Cell
            key={`cell-${index}`}
            fill={index <= shaded ? SHADED_COLOR : 'transparent'}
            stroke={STROKE_COLOR}
            strokeWidth={1}
          />
        ))}
      </recharts.Pie>
    </recharts.PieChart>
  );

  const getClosestFactors = (num) => {
    let factors = [];
    for (let i = 1; i <= Math.sqrt(num); i++) {
      if (num % i === 0) {
        factors.push([i, num / i]);
      }
    }
    return factors.reduce((closest, current) => {
      return Math.abs(current[0] - current[1]) < Math.abs(closest[0] - closest[1]) ? current : closest;
    });
  };

  const drawSquare = () => {
    const isPerfectSquare = Number.isInteger(Math.sqrt(slices));
    const [rows, cols] = isPerfectSquare
      ? [Math.sqrt(slices), Math.sqrt(slices)]
      : getClosestFactors(slices);
    const sliceWidth = size / cols;
    const sliceHeight = size / rows;

    return (
      <svg width={size} height={size}>
        {Array.from({ length: rows }, (_, row) =>
          Array.from({ length: cols }, (_, col) => {
            const index = row * cols + col;
            if (index >= slices) return null;
            return (
              <rect
                key={`rect-${index}`}
                x={col * sliceWidth}
                y={row * sliceHeight}
                width={sliceWidth}
                height={sliceHeight}
                fill={index <= shaded ? SHADED_COLOR : 'transparent'}
                stroke={STROKE_COLOR}
                strokeWidth={1}
              />
            );
          })
        )}
      </svg>
    );
  };

  const drawTriangle = () => {
    const height = Math.sqrt(3) / 2 * size;
    const sliceHeight = height / slices;

    return (
      <svg width={size} height={height}>
        {Array.from({ length: slices }, (_, index) => {
          const y1 = height - index * sliceHeight;
          const y2 = height - (index + 1) * sliceHeight;
          const baseWidth = size - 2 * (index * (size / (2 * slices)));
          const nextBaseWidth = size - 2 * ((index + 1) * (size / (2 * slices)));

          const points = [
            `${(size - nextBaseWidth) / 2},${y2}`,
            `${(size + nextBaseWidth) / 2},${y2}`,
            `${(size + baseWidth) / 2},${y1}`,
            `${(size - baseWidth) / 2},${y1}`
          ].join(' ');

          return (
            <polygon
              key={`triangle-${index}`}
              points={points}
              fill={index <= shaded ? SHADED_COLOR : 'transparent'}
              stroke={STROKE_COLOR}
              strokeWidth={1}
            />
          );
        })}
      </svg>
    );
  };

  switch (shape) {
    case 'circle':
      return drawCircle();
    case 'square':
      return drawSquare();
    case 'triangle':
      return drawTriangle();
    default:
      return null;
  }
};

class Slicedrectangle extends React.Component {
  render() {
    const { rows, columns, shaded } = this.props;
    const totalSquares = rows * columns;
    const squares = [];

    for (let i = 0; i < totalSquares; i++) {
      squares.push(
        <div
          key={i}
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: i < shaded ? getColorValue(this.props.color) : 'white',
            border: '1px solid black',
            boxSizing: 'border-box',
          }}
        />
      );
    }

    const gridStyle = {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 50px)`,
      gridTemplateRows: `repeat(${rows}, 50px)`,
    };

    return <div style={gridStyle}>{squares}</div>;
  }
}

class Shapegroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderShapes(groups, shapesPerGroup, shape) {
    if (!(groups && shapesPerGroup && shape)) {
      return '';
    }
    const shapeElements = [];
    const shapeSize = 20; // Size of each shape in pixels
    const shapeMargin = 5; // Margin around each shape in pixels
    const shapesPerRow = 3; // Number of shapes per row
    const additionalPadding = 40; // Additional padding for the outer shape

    for (let i = 0; i < groups; i++) {
      const groupShapes = [];
      for (let j = 0; j < shapesPerGroup; j++) {
        groupShapes.push(
          <div
            key={`shape-${i}-${j}`}
            style={{
              width: `${shapeSize}px`,
              height: `${shapeSize}px`,
              margin: `${shapeMargin}px`,
              borderRadius: shape === 'circle' ? '50%' : '0',
              backgroundColor: getColorValue(this.props.color),
            }}
          ></div>
        );
      }

      // Wrap shapes into rows of 3
      const shapeRows = [];
      for (let k = 0; k < groupShapes.length; k += shapesPerRow) {
        shapeRows.push(
          <div key={`row-${i}-${k / shapesPerRow}`} style={{ display: 'flex' }}>
            {groupShapes.slice(k, k + shapesPerRow)}
          </div>
        );
      }

      const numRows = Math.ceil(shapesPerGroup / shapesPerRow);
      const groupSize = (shapeSize + shapeMargin * 2) * shapesPerRow + additionalPadding; // Calculate group size with additional padding

      shapeElements.push(
        <div
          key={`group-${i}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #000',
            borderRadius: '50%',
            margin: '10px',
            width: `${groupSize}px`,
            height: `${groupSize}px`,
            padding: `${shapeMargin}px`,
            boxSizing: 'border-box'
          }}
        >
          {shapeRows}
        </div>
      );
    }

    return shapeElements;
  }

  render() {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {this.renderShapes(this.props.groups, this.props.shapes_per_group, this.props.shape)}
      </div>
    );
  }
}

const Scatterchart = ({ x, y, title, color }) => {
  const data = x.map((value, index) => ({ x: value, y: y[index] }));

  return (
    <div className="chart-container">
      <h3>{title}</h3>
      <recharts.ResponsiveContainer width="100%" height={400}>
        <recharts.ScatterChart>
          <recharts.CartesianGrid stroke="#d2d2d2" />
          <recharts.XAxis type="number" dataKey="x" stroke="#000" />
          <recharts.YAxis type="number" dataKey="y" stroke="#000" />
          <recharts.ZAxis type="number" range={[128, 129]} />
          <recharts.Scatter name="Data" data={data} isAnimationActive={false} size={15} fill={getColorValue(color)} />
        </recharts.ScatterChart>
      </recharts.ResponsiveContainer>
    </div>
  );
};

const Linechart = ({ lines, title, color }) => {
  const data = lines.map((line, index) =>
    line.x.map((value, i) => ({ x: value, y: line.y[i], lineIndex: index }))
  );

  return (
    <div className="chart-container">
      <h3>{title}</h3>
      <recharts.ResponsiveContainer width="100%" height={400}>
        <recharts.LineChart>
          <recharts.CartesianGrid stroke="#d2d2d2" />
          <recharts.XAxis type="number" dataKey="x" stroke="#000" />
          <recharts.YAxis stroke="#000" />
          {lines.map((line, index) => (
            <recharts.Line
              key={index}
              type="monotone"
              dataKey="y"
              data={data[index]}
              stroke={getColorValue(color)}
              strokeWidth={4}
              dot={false}
              isAnimationActive={false}
            />
          ))}
        </recharts.LineChart>
      </recharts.ResponsiveContainer>
    </div>
  );
};

const CustomizedLabel = ({ x, y, width, value }) => (
  <g>
    <text x={x + width / 2} y={y - 15} fontSize={20} textAnchor="middle" fill="#000">
      {value}
    </text>
  </g>
);

const Barchart = ({ bars, title, color }) => {
  const data = bars.map(bar => ({ name: bar.label, value: bar.value }));
  const maxLength = Math.max(...bars.map(bar => bar.label.length));
  const fontSize = maxLength > 10 ? 12 : 20; // Scale down font size if label is long

  return (
    <div className="chart-container">
      <h3>{title}</h3>
      <recharts.ResponsiveContainer width="100%" height={420}>
        <recharts.BarChart data={data} margin={{ top: 40, right: 30, left: 20, bottom: 30 }}>
          <recharts.CartesianGrid stroke="#d2d2d2" />
          <recharts.XAxis dataKey="name" stroke="#000" tick={{ fontSize }} />
          <recharts.YAxis stroke="#000" />
          <recharts.Bar dataKey="value" fill={getColorValue(color)} stroke="#000" isAnimationActive={false}>
            <recharts.LabelList dataKey="value" content={<CustomizedLabel />} />
          </recharts.Bar>
        </recharts.BarChart>
      </recharts.ResponsiveContainer>
    </div>
  );
};


function isColorString(str) {
  const strLowerCased = str.toLowerCase();
  return strLowerCased === 'red' || strLowerCased === 'blue' || strLowerCased === 'green' ||
    strLowerCased === 'orange' || strLowerCased === 'purple' || strLowerCased === 'cyan' ||
    strLowerCased === 'pink' || strLowerCased === 'black' || strLowerCased === 'white' ||
    strLowerCased === 'yellow' || strLowerCased === 'brown' || strLowerCased === 'gray' ||
    strLowerCased === 'grey';
}

const Piechart = ({ slices, title }) => {
  const colors = [
    '#6baed6',
    '#74c476',
    '#c47474',
    '#fd8d3c',
    '#9e9ac8',
    '#41b6c4',
    '#d95f02',
    '#e7d852',
    '#c49474',
    '#c474b8'];
  let fontSizeClassName = 'font-20';
  let maxLabelStrLength = Math.max(...slices.map(slice => slice.label.length));
  if (maxLabelStrLength > 20) {
    fontSizeClassName = 'font-14';
  } else if (maxLabelStrLength > 10) {
    fontSizeClassName = 'font-16';
  } else if (maxLabelStrLength > 7) {
    fontSizeClassName = 'font-18';
  }
  const data = slices.map((slice, index) => {
    let color = colors[index % colors.length];
    if (isColorString(slice.label)) {
      color = slice.label.toLowerCase();
      color = getColorValue(color);
    }
    return {
      name: slice.label,
      value: slice.percentage,
      fill: color
    };
  });

  return (
    <div className={"chart-container " + fontSizeClassName}>
      <h3>{title}</h3>
      <recharts.ResponsiveContainer width="100%" height={400}>
        <recharts.PieChart>
          <recharts.Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            label={({ name, value }) => `${name}: ${value}%`}
            isAnimationActive={false}
          />
        </recharts.PieChart>
      </recharts.ResponsiveContainer>
    </div>
  );
};

const Stemplot = ({ stems, title }) => {
  return (
    <div className="chart-container">
      <h3>{title}</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '5px' }}>Stem</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>Leaves</th>
          </tr>
        </thead>
        <tbody>
          {stems.map((stem, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid black', padding: '5px' }}>{stem.stem}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{stem.leaves.join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Numberline = ({ start, end, step, mark_values, mark_labels, title, tick_labels }) => {
  const [width, setWidth] = useState(600);
  const svgRef = useRef(null);

  const baseHeight = 150;
  const titleHeight = title ? 50 : 0;
  const height = baseHeight + titleHeight;
  const axisY = baseHeight / 4 + titleHeight; // Restored to original position
  const marginX = 50;
  const tickLength = 10;

  const scale = (value) => {
    return ((value - start) / (end - start)) * (width - 2 * marginX) + marginX;
  };

  const tickValues = [];
  for (let i = start; i <= end; i += step) {
    tickValues.push(i);
  }

  const isFraction = (content) => {
    return typeof content === 'string' && (content.includes('\\frac') || content.includes('/'));
  };

  const renderLabel = (label) => {
    if (typeof label === 'object' && label.type === 'Text') {
      return label.content;
    }
    return label;
  };

  return (
    <div className="numberline-container">
      <svg ref={svgRef} width="600px" height={height}>
        {title && <text x={width / 2} y={titleHeight / 2} textAnchor="middle" fontSize={25}>{title}</text>}
        {/* Main Axis Line */}
        <line x1={marginX} y1={axisY} x2={width - marginX} y2={axisY} stroke="black" strokeWidth={3} />
        {/* Ticks and Tick Labels */}
        {tickValues.map((tick, index) => (
          <g key={tick} transform={`translate(${scale(tick)}, ${axisY})`}>
            <line y1={-tickLength / 2} y2={tickLength / 2} stroke="black" strokeWidth={3} />
            <foreignObject
              x={-30}
              y={isFraction(tick_labels[index]) ? tickLength + 5 : tickLength}
              width={60}
              height={50}
            >
              <div className="tick-label" style={{ textAlign: 'center', fontSize: '16px' }}>
                <Equation content={tick_labels[index]} />
              </div>
            </foreignObject>
          </g>
        ))}
        {/* Marks */}
        {mark_values.map((value, index) => {
          const label = renderLabel(mark_labels[index]);
          return (
            <g key={index} transform={`translate(${scale(value)}, ${axisY})`}>
              <circle cx={0} cy={0} r={5} fill="black" />
              <foreignObject
                x={-50}
                y={-42}
                width={100}
                height={50}
              >
                <div className="mark-label" style={{ textAlign: 'center', fontSize: '18px' }}>
                  {label}
                </div>
              </foreignObject>
            </g>
          );
        })}
      </svg>
    </div>
  );
};

const ResizableContent = ({ children, onResize }) => {
  const containerRef = useRef();
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries || entries.length === 0) {
        return;
      }
      const { width, height } = entries[0].contentRect;
      setContentSize({ width, height });
      onResize({ width, height });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [onResize]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      {children}
    </div>
  );
};

const ResizableForeignObject = ({ x, y, width, height, scale, onResize, children, rotate }) => (
  <foreignObject x={x} y={y} width={width} height={height}>
    <div
      xmlns="http://www.w3.org/1999/xhtml"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        textAlign: 'center',
        transform: rotate ? 'rotate(-90deg)' : 'none',
        transformOrigin: 'center',
      }}
    >
      <div style={{ transform: `scale(${scale})`, transformOrigin: 'center' }}>
        <ResizableContent onResize={onResize}>
          {children}
        </ResizableContent>
      </div>
    </div>
  </foreignObject>
);

const Circle = ({ radius, circumference, color }) => {
  const STROKE_COLOR = '#000000';
  const fixedSize = 250;
  const circleSize = 200;
  const centerX = fixedSize / 2;
  const centerY = fixedSize / 2;
  const [radiusScale, setRadiusScale] = useState(1);
  const [circumferenceScale, setCircumferenceScale] = useState(1);

  const handleRadiusResize = ({ width, height }) => {
    const maxWidth = circleSize / 2 - 10;
    const maxHeight = 50;
    const widthScale = maxWidth / width;
    const heightScale = maxHeight / height;
    const newScale = Math.min(widthScale, heightScale, 1);
    setRadiusScale(newScale);
  };

  const handleCircumferenceResize = ({ width, height }) => {
    const maxWidth = circleSize;
    const maxHeight = 50;
    const widthScale = maxWidth / width;
    const heightScale = maxHeight / height;
    const newScale = Math.min(widthScale, heightScale, 1);
    setCircumferenceScale(newScale);
  };

  return (
    <span className="chart-container inline-svg" style={{ width: `${fixedSize}px`, height: `${fixedSize + 50}px`, display: 'inline-block', textAlign: 'center' }}>
      <svg width={fixedSize} height={fixedSize + 50}>
        <circle
          cx={centerX}
          cy={centerY}
          r={circleSize / 2}
          fill={getLighterColorValue(color)}
          stroke={STROKE_COLOR}
          strokeWidth={2}
        />
        {radius && (
          <line
            x1={centerX}
            y1={centerY}
            x2={centerX + circleSize / 2}
            y2={centerY}
            stroke={STROKE_COLOR}
            strokeWidth={2}
          />
        )}
        {radius && (
          <ResizableForeignObject
            x={centerX}
            y={centerY}
            width={circleSize / 2}
            height="50"
            scale={radiusScale}
            onResize={handleRadiusResize}
          >
            <ComponentRenderer component={radius} />
          </ResizableForeignObject>
        )}
        {circumference && (
          <ResizableForeignObject
            x={centerX - circleSize / 2}
            y={centerY + circleSize / 2}
            width={circleSize}
            height="50"
            scale={circumferenceScale}
            onResize={handleCircumferenceResize}
          >
            <ComponentRenderer component={circumference} />
          </ResizableForeignObject>
        )}
      </svg>
    </span>
  );
};

const Square = ({ sidelength, color }) => {
  const STROKE_COLOR = '#000000';
  const fixedSize = 250;
  const squareSize = 200;
  const centerX = fixedSize / 2;
  const centerY = fixedSize / 2;
  const [sidelengthScale, setSidelengthScale] = useState(1);

  const handleSideLengthResize = ({ width, height }) => {
    const maxWidth = squareSize - 10;
    const maxHeight = 50;
    const widthScale = maxWidth / width;
    const heightScale = maxHeight / height;
    const newScale = Math.min(widthScale, heightScale, 1);
    setSidelengthScale(newScale);
  };

  return (
    <span className="chart-container inline-svg" style={{ width: `${fixedSize}px`, height: `${fixedSize + 50}px`, display: 'inline-block', textAlign: 'center' }}>
      <svg width={fixedSize} height={fixedSize + 50}>
        <rect
          x={centerX - squareSize / 2}
          y={centerY - squareSize / 2}
          width={squareSize}
          height={squareSize}
          fill={getColorValue(color)}
          stroke={STROKE_COLOR}
          strokeWidth={2}
        />
        {sidelength && (
          <line
            x1={centerX - squareSize / 2}
            y1={centerY - squareSize / 2}
            x2={centerX + squareSize / 2}
            y2={centerY - squareSize / 2}
            stroke={STROKE_COLOR}
            strokeWidth={2}
          />
        )}
        {sidelength && (
          <ResizableForeignObject
            x={centerX - squareSize / 2}
            y={centerY + squareSize / 2 - 10}
            width={squareSize}
            height="50"
            scale={sidelengthScale}
            onResize={handleSideLengthResize}
          >
            <ComponentRenderer component={sidelength} />
          </ResizableForeignObject>
        )}
      </svg>
    </span>
  );
};

const Rectangle = ({ width, height, orientation, color }) => {
  const STROKE_COLOR = '#000000';
  const fixedWidth = 320; // Increased by 10px to accommodate the left label
  const fixedHeight = 280;
  const [rectWidth, setRectWidth] = useState(200);
  const [rectHeight, setRectHeight] = useState(150);
  const centerX = fixedWidth / 2 + 5; // Adjusted to keep rectangle centered
  const centerY = (fixedHeight - 80) / 2;
  const [widthScale, setWidthScale] = useState(1);
  const [heightScale, setHeightScale] = useState(1);
  const [hasFraction, setHasFraction] = useState({ width: false, height: false });

  useEffect(() => {
    if (orientation === 'landscape') {
      setRectWidth(200);
      setRectHeight(150);
    } else {
      setRectWidth(150);
      setRectHeight(200);
    }
    setHasFraction({
      width: detectFraction(width),
      height: detectFraction(height)
    });
  }, [orientation, width, height]);

  const detectFraction = (component) => {
    if (Array.isArray(component) && component[0]?.type === 'Equation') {
      return component[0].content.includes('\\frac');
    }
    return false;
  };

  const handleWidthResize = ({ width: newWidth, height: newHeight }) => {
    const maxWidth = rectWidth;
    const maxHeight = hasFraction.width ? 80 : 60;
    const widthScale = maxWidth / newWidth;
    const heightScale = maxHeight / newHeight;
    const newScale = Math.min(widthScale, heightScale, 1);
    setWidthScale(newScale);
  };

  const handleHeightResize = ({ width: newWidth, height: newHeight }) => {
    const maxWidth = hasFraction.height ? 80 : 60;
    const maxHeight = rectHeight;
    const widthScale = maxWidth / newWidth;
    const heightScale = maxHeight / newHeight;
    const newScale = Math.min(widthScale, heightScale, 1);
    setHeightScale(newScale);
  };

  return (
    <span className="chart-container inline-svg" style={{ width: `${fixedWidth}px`, height: `${fixedHeight}px`, display: 'inline-block', textAlign: 'center' }}>
      <svg width={fixedWidth} height={fixedHeight} viewBox={`0 0 ${fixedWidth} ${fixedHeight}`}>
        <rect
          x={centerX - rectWidth / 2}
          y={centerY - rectHeight / 2}
          width={rectWidth}
          height={rectHeight}
          fill={getColorValue(color)}
          stroke={STROKE_COLOR}
          strokeWidth={2}
        />
        {width && (
          <ResizableForeignObject
            x={centerX - rectWidth / 2}
            y={centerY + rectHeight / 2 - 2}
            width={rectWidth}
            height={hasFraction.width ? 80 : 60}
            scale={widthScale}
            onResize={handleWidthResize}
          >
            <ComponentRenderer component={width} />
          </ResizableForeignObject>
        )}
        {height && (
          <ResizableForeignObject
            x={centerX - rectWidth / 2 - (hasFraction.height ? 105 : 85)} // Moved 3px further to the left
            y={centerY - rectHeight / 2}
            width={hasFraction.height ? 140 : 120}
            height={rectHeight}
            scale={heightScale}
            onResize={handleHeightResize}
            rotate={true}
          >
            <ComponentRenderer component={height} />
          </ResizableForeignObject>
        )}
      </svg>
    </span>
  );
};

const getPositionOffset = (position, width, height) => {
  const offset = -10;
  switch (position) {
    case 'above': return { dx: -width / 2, dy: -height - offset };
    case 'below': return { dx: -width / 2, dy: offset };
    case 'left': return { dx: -width - offset, dy: -height / 2 };
    case 'right': return { dx: offset, dy: -height / 2 };
    case 'above left': return { dx: -width - offset, dy: -height - offset };
    case 'above right': return { dx: offset, dy: -height - offset };
    case 'below left': return { dx: -width - offset, dy: offset };
    case 'below right': return { dx: offset, dy: offset };
    default: return { dx: -width / 2, dy: -height - offset };
  }
};

const Plot = ({ points, segments, xrange, yrange, title, color }) => {
  const svgRef = useRef();

  useEffect(() => {
    // Function to determine tick interval and generate balanced ticks
    const getBalancedTicks = (min, max) => {
      const absMax = Math.max(Math.abs(min), Math.abs(max));
      let interval;
      if (absMax <= 5) interval = 1;
      else if (absMax <= 10) interval = 2;
      else if (absMax <= 20) interval = 4;
      else if (absMax <= 50) interval = 10;
      else interval = Math.ceil(absMax / 5);

      const maxTick = Math.ceil(absMax / interval) * interval;
      return d3.range(-maxTick - interval, maxTick + interval * 2, interval);
    };

    // Ensure the ranges are symmetrical and add padding
    const maxAbsX = Math.max(Math.abs(xrange[0]), Math.abs(xrange[1]));
    const maxAbsY = Math.max(Math.abs(yrange[0]), Math.abs(yrange[1]));
    const balancedXRange = [-maxAbsX, maxAbsX];
    const balancedYRange = [-maxAbsY, maxAbsY];

    const width = 600;
    const height = 600;
    const margin = { top: 40, right: 40, bottom: 40, left: 40 };

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .style('background-color', 'white');

    svg.selectAll('*').remove(); // Clear previous renders

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Generate balanced ticks
    const xTicks = getBalancedTicks(balancedXRange[0], balancedXRange[1]);
    const yTicks = getBalancedTicks(balancedYRange[0], balancedYRange[1]);

    const xDomain = [xTicks[0], xTicks[xTicks.length - 1]];
    const yDomain = [yTicks[0], yTicks[yTicks.length - 1]];

    const x = d3.scaleLinear().domain(xDomain).range([0, width - margin.left - margin.right]);
    const y = d3.scaleLinear().domain(yDomain).range([height - margin.top - margin.bottom, 0]);

    const xAxisBottom = d3.axisBottom(x).tickValues(xTicks).tickFormat(d => d !== 0 ? d : '');
    const xAxisTop = d3.axisTop(x).tickValues(xTicks).tickFormat('');
    const yAxisLeft = d3.axisLeft(y).tickValues(yTicks).tickFormat(d => d !== 0 ? d : '');
    const yAxisRight = d3.axisRight(y).tickValues(yTicks).tickFormat('');

    // Append gridlines
    g.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height - margin.top - margin.bottom})`)
      .call(d3.axisBottom(x)
        .tickValues(xTicks)
        .tickSize(-height + margin.top + margin.bottom)
        .tickFormat('')
      )
      .selectAll('line')
      .style('stroke', '#ccc');

    g.append('g')
      .attr('class', 'grid')
      .call(d3.axisLeft(y)
        .tickValues(yTicks)
        .tickSize(-width + margin.left + margin.right)
        .tickFormat('')
      )
      .selectAll('line')
      .style('stroke', '#ccc');

    // Draw axes
    g.append('g')
      .attr('transform', `translate(0,${y(0)})`)
      .call(xAxisBottom)
      .selectAll("text")
      .style("font-size", "14px");

    g.append('g')
      .attr('transform', `translate(0,${y(0)})`)
      .call(xAxisTop)
      .selectAll('line')
      .style('stroke', 'none')
      .selectAll("text")
      .style("font-size", "14px");

    g.append('g')
      .attr('transform', `translate(${x(0)},0)`)
      .call(yAxisLeft)
      .selectAll("text")
      .style("font-size", "14px");

    g.append('g')
      .attr('transform', `translate(${x(0)},0)`)
      .call(yAxisRight)
      .selectAll('line')
      .style('stroke', 'none')
      .selectAll("text")
      .style("font-size", "14px");

    // Draw the points
    g.selectAll('.point')
      .data(points)
      .enter()
      .append('circle')
      .attr('class', 'point')
      .attr('cx', d => x(d[0]))
      .attr('cy', d => y(d[1]))
      .attr('r', 6)
      .attr('fill', getColorValue(color));

    // Draw the segments
    g.selectAll('.segment')
      .data(segments)
      .enter()
      .append('line')
      .attr('class', 'segment')
      .attr('x1', d => x(d[0]))
      .attr('y1', d => y(d[1]))
      .attr('x2', d => x(d[2]))
      .attr('y2', d => y(d[3]))
      .attr('stroke', getColorValue(color))
      .attr('stroke-width', 2);

    // Add labels
    g.selectAll('.label')
      .data(points)
      .enter()
      .append('text')
      .attr('class', 'label')
      .attr('x', d => x(d[0]))
      .attr('y', d => y(d[1]))
      .attr('dy', d => d[3].includes('below') ? 19 : -12)
      .attr('dx', d => d[3].includes('left') ? -17 : 12)
      .attr('text-anchor', 'middle')
      .attr('font-size', 16)
      .text(d => d[2]);

    // Title
    svg.append('text')
      .attr('x', (width / 2))
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '20px')
      .attr('font-weight', 'bold')
      .text(title);

  }, [points, segments, xrange, yrange, title, color]);

  return <svg ref={svgRef}></svg>;
};

const Table = ({ rows }) => (
  <table style={{ borderCollapse: 'collapse', width: '600px' }}>
    <tbody>
      {Array.isArray(rows) && rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {Array.isArray(row) && row.map((cell, cellIndex) => (
            <td
              key={cellIndex}
              style={{
                border: '1px solid #000',
                padding: '8px',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              <ComponentRenderer component={cell} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const Enumlist = ({ items }) => (
  <ol style={{ paddingLeft: '20px', margin: '10px 0' }}>
    {Array.isArray(items) && items.map((item, index) => (
      <li key={index} style={{ marginBottom: '5px' }}>
        <ComponentRenderer component={item} />
      </li>
    ))}
  </ol>
);

const Itemlist = ({ items }) => (
  <ul style={{ paddingLeft: '20px', margin: '10px 0' }}>
    {Array.isArray(items) && items.map((item, index) => (
      <li key={index} style={{ marginBottom: '5px' }}>
        <ComponentRenderer component={item} />
      </li>
    ))}
  </ul>
);

const Answer = ({ correct, choice, reason, index, showSolution, additionalData }) => {
  const choiceLabels = ['A.', 'B.', 'C.', 'D.'];

  return (
    <div className={`answer ${correct && showSolution ? 'correct' : 'incorrect'}`} style={{ marginBottom: '15px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: showSolution ? (correct ? '#e6ffe6' : '#ffe6e6') : '#fff' }}>
      <div className="answer-choice" style={{ marginBottom: '10px' }}>
        <span className="choice-label" style={{ fontWeight: 'bold', marginRight: '5px' }}>{choiceLabels[index]}</span>
        <ComponentRenderer component={choice} />
      </div>
      {showSolution && (
        <div className="answer-reason" style={{ marginTop: '10px' }}>
          <ComponentRenderer component={reason} />
          {additionalData &&
            additionalData.hasOwnProperty('chosenAnswerIdx') &&
            additionalData.hasOwnProperty('chosenAnswerCorrect') &&
            additionalData.chosenAnswerIdx === index ?
            <div className='mt-1 font-bold'>
              You chose this answer
            </div> : ''}

          {additionalData &&
            additionalData.hasOwnProperty('questionStats') &&
            additionalData.questionStats &&
            additionalData.questionStats.hasOwnProperty('chosen_answers') &&
            additionalData.questionStats.chosen_answers &&
            additionalData.questionStats.chosen_answers.hasOwnProperty(index) ?
            <div className='font-20 mt-2 pt-2 border-top'>
              <div>
                {additionalData.questionStats.chosen_answers[index]} {additionalData.questionStats.chosen_answers[index] === 1 ? 'student' : 'students'} chose this answer.
              </div>
              <div className='pr-12px'>
                <Progress percent={
                  additionalData.questionStats.total === 0 ?
                    0 :
                    ((additionalData.questionStats.chosen_answers[index] /
                      additionalData.questionStats.total) * 100).toFixed(2)
                } />
              </div>
            </div>
            : ''}
        </div>
      )}
    </div>
  );
};

const Assessment = ({ question, answers, showSolution, additionalData }) => (
  <div className="assessment">
    <div className="question">
      {question.map((component, index) => (
        <ComponentRenderer key={index} component={component} />
      ))}
    </div>
    <div className="answers" style={{ paddingTop: '20px' }}>
      {answers.map((answer, index) => (
        <Answer key={index} {...answer} index={index} showSolution={showSolution} additionalData={additionalData} />
      ))}
    </div>
  </div>
);

const componentMap = {
  H1,
  H2,
  H3,
  H4,
  P,
  ItemList,
  EnumList,
  Heading1,
  Heading2,
  Heading3,
  Table,
  Enumlist,
  Itemlist,
  Body,
  Passage,
  Bold,
  BoldText,
  Italic,
  Underline,
  UnderlineText,
  Blank,
  Linebreak,
  LineBreak,
  Text,
  Equation,
  Slicedgeometry,
  Slicedrectangle,
  Shapegroups: Shapegroups,
  Scatterchart,
  Plot,
  Linechart,
  Barchart,
  Piechart,
  Stemplot,
  Numberline,
  Circle,
  Square,
  Rectangle,
  Answer,
  Assessment,
};

const renderContent = (content) => {
  if (Array.isArray(content)) {
    return content.map((item, index) => <React.Fragment key={index}>{renderContent(item)}</React.Fragment>);
  }

  if (typeof content === 'object' && content !== null) {
    const { type, ...props } = content;
    const Component = componentMap[type];
    return Component ? <Component {...props} /> : null;
  }

  return content;
};

const ComponentRenderer = ({ component, additionalData }) => {
  if (Array.isArray(component)) {
    return (
      <>
        {component.map((subComponent, index) => (
          <ComponentRenderer key={index} component={subComponent} additionalData={additionalData} />
        ))}
      </>
    );
  }

  if (typeof component === 'object' && component !== null) {
    const { type, ...props } = component;
    const Component = componentMap[type];

    if (!Component) {
      console.warn(`Unsupported component type: ${type}`);
      return null;
    }

    return <Component {...props} additionalData={additionalData} />;
  }

  return component;
};

// Document component
export class Document extends React.Component {
  render() {
    const { components } = this.props;
    const additionalData = this.props.additionalData || {};

    return (
      <div className="document edviz-document-v2">
        {components.map((component, index) => (
          <ComponentRenderer key={index} component={component} additionalData={additionalData} />
        ))}
      </div>
    );
  }
}

export default Document;

