import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
import DraftStepContainer from './DraftStepContainer'
import {
  flattenDoc, getIDFromURL, capitalizeFirstChar,
  getQueryStringParam, createIEPGoalText, createELAPresentLevelText,
  createMathPresentLevelText, createSELPresentLevelText,
} from '../.././Util'
import { Layout, Icon, Button, Form, Row, Col, Radio } from 'antd'
import StandardNumber from '../../customcomponents/StandardNumber'
const { Content } = Layout
const Promise = require("bluebird")

class TeacherAddIEPGoalModify extends Component {
  state = {
    teacherId: null,
    studentId: null,
    teacher: null,
    iep: null,
    iepParagraph: '',
    totalPoints: null,
    students: [],
    ieps: [],
    iepStandardDict: {},
    iepDrafts: [],
    iepDraft: null,
    tabKey: '1',
    stepNum: 3,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var iepId = getQueryStringParam('iep')
    var draftId = getQueryStringParam('draft')

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      iepId: iepId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.iepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          this.setState({
            iepDraft: flattenDoc(doc),
            iepDraftMounted: true,
          }, () => {
            var iepDraft = this.state.iepDraft
            //console.log(iepDraft)

            db.collection(ColType.student)
              .doc(studentId)
              .get()
              .then((doc) => {
                var student = flattenDoc(doc)
                var ieps = []
                var iepDict = {}
                var iepSubjectDict = {}
                var iepSelections = {}
                var iepStandardDict = {}
                var standardPromises = []
                var grade = student.grade
                if (grade.toLowerCase() === 'kindergarten') {
                  grade = 'K'
                }

                iepDraft.subject.map((subject, index) => {
                  iepStandardDict[subject] = []
                  return iepSubjectDict[subject] = []
                })

                iepDraft.iepStandards.map((standard, index) => {
                  return standardPromises.push(db.collection(ColType.iepStandards)
                    .where('grade', '==', grade)
                    .where('category', '==', iepDraft.category)
                    .where('standard', '==', standard)
                    .where('level', '==', iepDraft.level)
                    //.orderBy('level', 'desc')
                    //.where('level', '==', iepDraft.level)
                    .get()
                  )
                })

                Promise.all(standardPromises)
                  .then((querySnapshots) => {
                    querySnapshots.forEach((querySnapshot, index) => {
                      querySnapshot.forEach((doc) => {
                        var iep = flattenDoc(doc)
                        //console.log('iep', iep)
                        // set starting accuracy levels
                        iep.numProgressMonitoringScores = 2
                        if (iepDraft.hasOwnProperty('ieps') &&
                          iepDraft.ieps &&
                          index <= iepDraft.ieps.length - 1) {
                          iep.numProgressMonitoringScores = iepDraft.ieps[index].numProgressMonitoringScores
                        }
                        iep.baselineAccuracyLevel = '<number>'
                        iep.targetAccuracyLevel = '<number>'
                        iep.presentLevel = JSON.parse(JSON.stringify(iepDraft.presentLevel))
                        // just in case leading and trailing spaces exist (fixes a user
                        // reported bug)
                        iep.mainSubject = iep.mainSubject.trim()
                        var strength = "N/A"
                        if (iepDraft.hasOwnProperty('strength-' + iep.mainSubject)) {
                          strength = iepDraft['strength-' + iep.mainSubject]
                        }
                        // add strength to iep
                        iep.presentLevel.strength = strength
                        if (iep.mainSubject === 'Social Emotional Learning') {
                          iep.presentLevel.presentLevelParagraph =
                            createSELPresentLevelText(iep, iep.presentLevel, student, strength)
                        }
                        else if (iep.mainSubject === 'Math') {
                          iep.presentLevel.presentLevelParagraph =
                            createMathPresentLevelText(iep, iep.presentLevel, student, strength)
                        }
                        else {
                          iep.presentLevel.presentLevelParagraph =
                            createELAPresentLevelText(iep, iep.presentLevel, student, strength)
                        }
                        iep.iepParagraph = createIEPGoalText(iep, student, iep.numProgressMonitoringScores)
                        iep.totalPoints = this.getIEPTotalPoints(iep)
                        ieps.push(iep)
                        if (!(iepStandardDict[iep.mainSubject].hasOwnProperty(iep.standard))) {
                          iepStandardDict[iep.mainSubject][iep.standard] = []
                        }
                        if (iep.level === iepDraft.level) {
                          iepSelections[iep.standard] = iep
                        }

                        iepStandardDict[iep.mainSubject][iep.standard].push(iep)
                        iepSubjectDict[iep.mainSubject].push(iep)
                        iepDict[iep.id] = iep
                      })
                    })

                    this.setState({
                      student: student,
                      ieps: ieps,
                      iepStandardDict: iepStandardDict,
                      iepDict: iepDict,
                      iepSubjectDict: iepSubjectDict,
                      iepSelections: iepSelections,
                    }, () => {
                      if (iepDraft.iepSelectionsNoCount &&
                        !(Object.keys(iepDraft.iepSelectionsNoCount).length === 0 &&
                          iepDraft.iepSelectionsNoCount.constructor === Object)) {
                        if (this.state.iepDraft.step.stepNum > this.state.stepNum)
                          this.props.form.setFieldsValue(iepDraft.iepSelectionsNoCount)
                      }
                    })
                  })
              })
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)
        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .doc(studentId)
      .get()
      .then((doc) => {
        var student = flattenDoc(doc)

        this.setState({
          student: student,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  serviceLocationChange = (value, options) => {
    this.setState({
      serviceLocation: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    var newState = this.state.iepDraft
    newState.step = { stepNum: this.state.stepNum + 1, path: 'progress-monitoring' }

    var ieps = []
    var selectedStandardDict = {}

    Object.keys(this.state.iepSelections).map((standard, index) => {
      var iep = this.state.iepSelections[standard]
      selectedStandardDict[standard] = iep.id
      let progressMonitoring = []
      for (let i = 0; i < iep.numProgressMonitoringScores; i++) {
        progressMonitoring.push(iep.progressMonitoring[i])
      }
      iep.progressMonitoring = progressMonitoring
      // copy of progress monitoring for baseline/target for the next step in the form.
      iep.baselineProgressMonitoring = JSON.parse(JSON.stringify(iep.progressMonitoring))
      iep.targetProgressMonitoring = JSON.parse(JSON.stringify(iep.progressMonitoring))
      for (var i = 0; i < iep.baselineProgressMonitoring.length; i++) {
        iep.baselineProgressMonitoring[i].max = iep.baselineProgressMonitoring[i].num
        iep.targetProgressMonitoring[i].max = iep.targetProgressMonitoring[i].num
        iep.baselineProgressMonitoring[i].num = 0
        iep.targetProgressMonitoring[i].num = 0
      }

      return ieps.push(iep)
    })

    console.log('selectedIEPS', ieps)
    newState.ieps = ieps
    newState.selectedStandardDict = selectedStandardDict

    db.collection(ColType.iepDrafts)
      .doc(this.state.draftId)
      .set(newState, { merge: true })
      .then(() => {
        this.props.history.push(
          {
            pathname: '/teacher/add-goal-progress-monitoring/' +
              this.state.teacherId + '?student=' +
              this.state.studentId +
              '&draft=' + this.state.draftId,
            state: newState, // pass state to confirm page
          }
        )
      })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  levelTabChange = (activeKey, standard) => {
    //console.log(activeKey + " " + standard)

    var iep = this.state.iepDict[activeKey]
    var iepSelections = this.state.iepSelections
    iepSelections[iep.standard] = iep
    this.setState({
      iepSelections: iepSelections,
    })
  }

  getIEPTotalPoints = (iep) => {
    let totalPoints = 0
    for (let i = 0; i < iep.numProgressMonitoringScores; i++) {
      totalPoints += iep.progressMonitoring[i].num
    }
    return totalPoints
  }

  progressMonitoringNumChange = (student, iep, numProgressMonitoringScores) => {
    let ieps = this.state.ieps

    for (let i = 0; i < ieps.length; i++) {
      let iepVal = ieps[i]
      if (iep.id === iepVal.id) {
        iepVal.iepParagraph = createIEPGoalText(iepVal, student, numProgressMonitoringScores)
        iepVal.numProgressMonitoringScores = numProgressMonitoringScores
        iepVal.totalPoints = this.getIEPTotalPoints(iepVal)
        break
      }
    }

    this.setState({
      ieps: ieps
    })
  }

  render() {
    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="min-w-1120">
              <div>
                <DraftStepContainer
                  teacherBaseProps={this.props.teacherBaseProps}
                  dataLoaded={this.state.draftId && this.state.iepDraftMounted}
                >
                  <div>
                    {this.state.draftId && this.state.iepDraftMounted ?
                      <div>


                        <div className="w-800 m-lr-auto background-fff br-15">
                          <div className='p-4 bkg-purple br-15'>
                            <div className='flex flex-v-center w-100'>
                              <div>
                                {this.props.teacherBaseProps &&
                                  this.props.teacherBaseProps.studentDict &&
                                  this.props.teacherBaseProps.studentDict
                                    .hasOwnProperty(this.state.studentId)
                                  ?
                                  <div className="flex flex-v-center mb-0 font-24">
                                    <div>
                                      <div className='font-20 font-fff'>
                                        IEP Draft for {this.props.teacherBaseProps
                                          .studentDict[this.state.studentId].firstName + ' ' +
                                          this.props.teacherBaseProps
                                            .studentDict[this.state.studentId].lastName}
                                      </div>
                                      <h1 className="font-40 mb-0 font-fff font-bold">
                                        Goal Options
                                      </h1>
                                    </div>
                                  </div>
                                  : ''}
                              </div>
                              <img src="/draft-icons/4.png" alt='draft-iep' width={250} className='ml-auto' />
                            </div>
                          </div>
                          <div className='p-4'>
                            <div>
                              {this.state.iepDraft.subject.map((subject, subjectIndex) => {
                                return <div className="mb-3" key={'baseline-target-block-' + subjectIndex}>
                                  <h2 className="mb-3 pb-1 mt-2 border-bottom font-30 font-normal">
                                    {subject}</h2>
                                  {!this.state.iepStandardDict.hasOwnProperty(subject) ?
                                    '' :
                                    <Row gutter={64} className="mb-3"
                                      key={'row-' + subject}>
                                      <Col span={24}>
                                        {this.state.iepSubjectDict[subject].map((iep, index) => {
                                          return <div key={'iep-' + iep.id}>
                                            <div className="mb-2">
                                              <h3 className="font-bold font-20 mb-0">
                                                <StandardNumber
                                                  district={this.props.teacherBaseProps.district}
                                                  iep={iep}
                                                  className='mr-0'
                                                  containerClassName={'pl-2 pr-2 pt-1 pb-1 mr-1 br-100 font-20'}
                                                  containerStyle={{ backgroundColor: '#e8e8e8' }}
                                                />
                                                <span>{capitalizeFirstChar(iep.standardDescription)}</span>
                                              </h3>
                                              <h2 className="mb-2 mt-2 font-16">Choose the option that best fits your student's needs.</h2>

                                              <Radio.Group
                                                onChange={(e) => {
                                                  if (this.state.student) {
                                                    this.progressMonitoringNumChange(this.state.student, iep, e.target.value)
                                                  }
                                                }}
                                                defaultValue={iep.numProgressMonitoringScores}
                                                optionType="button"
                                                buttonStyle="solid"
                                                size="large"
                                              >
                                                <Radio.Button value={2}>Option 1</Radio.Button>
                                                <Radio.Button value={3}>Option 2</Radio.Button>
                                                <Radio.Button value={4}>Option 3</Radio.Button>
                                              </Radio.Group>
                                              <div className="font-20 ant-shadow-more p-2 br-15 top-left-br-0">{iep.iepParagraph}</div>
                                            </div>
                                          </div>
                                        })}

                                      </Col>
                                    </Row>}
                                </div>
                              })
                              }

                              <div className="mt-4 flex flex-v-center">
                                {this.state.teacherId && this.state.studentId && this.state.draftId ?
                                  <Link
                                    to={'/teacher/add-goal-select/' +
                                      this.state.teacherId + '?student=' +
                                      this.state.studentId +
                                      '&draft=' + this.state.draftId}
                                    className="font-18"
                                  >
                                    <span className="left-hover-parent text-cyan">
                                      <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                      <span>Previous Step</span>
                                    </span>
                                  </Link>
                                  : ''}
                                <Button
                                  type="primary"
                                  size={'large'}
                                  htmlType="submit"
                                  className="lg-btn ml-auto"
                                  onClick={this.handleSubmit}
                                >
                                  Continue
                                </Button>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                      : ''}

                  </div>
                </DraftStepContainer>
              </div>

            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Form.create()(TeacherAddIEPGoalModify)
