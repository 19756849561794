import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import PersonAvatar from '../customcomponents/PersonAvatar'
import Empty from '../customcomponents/Empty'
import { getInitials } from '.././Util'
import { Select, Row, Col,Icon, Avatar, Menu } from 'antd'
const Option = Select.Option
var moment = require('moment')

class InstructionalFacilitatorReviewDates extends Component {
  state = {
    selectedMenuKey: [],
    measurementDict: {},
    calendarEvents: [],
    selectedEvent: null,
    selectedEventTier: null,
    selectedTeacherId: "",
    daysAwayFilter: 'all',
  }

  onSelect = (val) => {
    this.setState({
      selectedMenuKey: [val.key],
      selectedTeacherId: val.key,
      loadingNewData: true,
    })
  }

  onGroupSelect = (event, eventTier) => {
    this.setState({
      selectedEvent: event,
      selectedEventTier: eventTier,
    })
  }

  goBackToGroupView = () => {
    this.setState({
      selectedEvent: null,
      selectedEventTier: null,
    })
  }

  daysAwayFilterChange = (value) => {
    this.setState({
      daysAwayFilter: value,
    })
  }

  render() {
    console.log(this.props)
    return <div>
        {this.props && 
          this.props.schoolProps && 
          this.props.schoolProps.schoolTeachers && 
          this.props.schoolProps.schoolTeachers.length === 0 ?
            <h1 className="mt-3">No teachers have been added to this school.</h1>
          :
      <Row gutter={32} className="mt-4 pt-2">
        <Col span={6}>
      
        <Menu
          mode="inline"
          selectedKeys={this.state.selectedMenuKey}
          onSelect={this.onSelect}
          theme='light'
          className="w-100 menu-large"
        >
          <h2 className="mb-3">Teachers</h2>
          
          {this.props && 
          this.props.schoolProps && 
          this.props.schoolProps.schoolTeachers && 
          this.props.schoolProps.schoolTeachers.map((teacher, index) => {
            return <Menu.Item  className="pl-0 pr-0" style={{height: '56px'}} key={teacher.id}>
              <div className="w-100">
               <PersonAvatar
                  size={'large'}
                  person={teacher}
                  personClassName={'font-bold ellipsis max-w-140px'}
                  containerClassName={'p-1 font-16 h-initial lh-initial inline-block w-100'}
                  containerId={'teacher-avatar-' + teacher.id}
                />
              </div>
            </Menu.Item>
          })
          }
        </Menu>

        </Col>
        <Col span={18}>
        {this.state.selectedMenuKey.length === 0 ?
          <h2>
            <Icon type="arrow-left" className="mr-1" />
            Use the side menu to select a teacher.
          </h2>
        :
        <div className="mt-4 mb-4">
        <div className="mb-4">
          <span className="font-18 mr-2">Filter by days away</span>
          <Select style={{width: '200px'}} defaultValue={'all'}
          onSelect={this.daysAwayFilterChange}>
            <Option value="all">Show all</Option>
            <Option value="7">7 days away</Option>
            <Option value="14">14 days away</Option>
            <Option value="30">30 days away</Option>
            <Option value="60">60 days away</Option>
            <Option value="90">90 days away</Option>
            <Option value="180">180 days away</Option>
            <Option value="365">365 days away</Option>
          </Select>
        </div>
        {this.props.schoolProps.schoolTeachers && 
         this.props.schoolProps.schoolTeachers.map((teacher, index) => {
          if (teacher.id !== this.state.selectedTeacherId) return false
          var goalsThatMetFilter = 0
          return <div className="mb-2" key={'review-date-' + teacher.id}>
            <h1 className="flex flex-v-center">
              <span className="mr-1">Teacher:</span> 
              <PersonAvatar person={teacher} />
            </h1>
            {this.props.schoolProps.students && 
             this.props.schoolProps.students.map((student, index) => {
            return this.props.schoolProps.iepGoals && 
                   this.props.schoolProps.iepGoals.map((iepGoal, index) => {
              if (iepGoal.teacherId !== teacher.id || 
                  iepGoal.studentId !== student.id) return false
                var completionDateMoment = moment.utc(iepGoal.iep.completionDate)
                var currentMoment = moment.utc()
                if (this.state.daysAwayFilter !== 'all' && 
                completionDateMoment.diff(currentMoment, 'days') > 
                parseFloat(this.state.daysAwayFilter)) return false
                goalsThatMetFilter += 1
                return <Row gutter={32} className="flex flex-v-center font-24"
                key={'iep-' + iepGoal.id}>
                  <Col span={12}>
                  <div className="font-16 mb-2 text-align-left">
                    <Avatar 
                      size="large" 
                      className="mr-8" 
                      style={{ backgroundColor: student.avatarColor}}
                    >
                      {getInitials(student)}
                    </Avatar>
                    <div className="inline-block va-minus-14">
                      <div>{student.firstName + " " + student.lastName}</div>
                      <div className="text-align-left font-14 lh-14 text-muted">
                        Grade: {student.grade}
                      </div>
                    </div>
                  </div>
                  </Col>

                  <Col span={5}>
                  <div className="flex flex-v-center">
                    {completionDateMoment.format('MM/DD/YYYY')}
                  </div>
                  </Col>
                  <Col span={7}>
                    <div className="flex flex-v-center">
                      {completionDateMoment.diff(currentMoment, 'days') < 0 ?
                      -1 * (completionDateMoment.diff(currentMoment, 'days')) + " days ago"
                      :
                      completionDateMoment.diff(currentMoment, 'days') + " days away"}
                    </div>
                  </Col>
                </Row>
              })
            })
          }
         
          {goalsThatMetFilter === 0 ?
           <Empty 
           containerClassName="mt-2 text-center"
           width={55}
           height={55}
           src='/empty-image.svg'
         /> : ''}
          </div>
        })
        }
        </div>
      }
      </Col>
      </Row>
        }
    </div>
  }
}

export default InstructionalFacilitatorReviewDates