import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
//import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Form, Input, Button, Icon, Modal, notification } from 'antd'
const FormItem = Form.Item
const TextArea = Input.TextArea

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class SchoolReportForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        formData: [
            {
                label: 'Title',
                fieldDecorator: 'reportTitle',
                placeholder: '',
            },
            {
                label: 'Description',
                fieldDecorator: 'reportDescription',
                placeholder: '',
            },
            {
                label: 'Reflection',
                fieldDecorator: 'reportReflection',
                placeholder: '',
            },
            {
                label: "Administration will support and ensure standards-based planning by",
                fieldDecorator: 'adminIncentive',
                placeholder: '',
            },
            {
                label: "Administration will incentivize effective standards-based practices by",
                fieldDecorator: 'adminChangeEnvironment',
                placeholder: '',
            },
            {
                label: "Administration will monitor and support students' progress by",
                fieldDecorator: 'adminProgress',
                placeholder: '',
            },
            {
                label: 'Administration next steps',
                fieldDecorator: 'adminNextSteps',
                placeholder: '',
            },
            {
                label: "Coaches will facilitate standards-based planning by",
                fieldDecorator: 'coachesSupport',
                placeholder: '',
            },
            {
                label: "Coaches will facilitate effective standards-based practice by",
                fieldDecorator: 'coachesSupportPractice',
                placeholder: '',
            },
            {
                label: "Coaches will facilitate students' progress by",
                fieldDecorator: 'coachesCaptureProgress',
                placeholder: '',
            },
            {
                label: 'Coaches next steps',
                fieldDecorator: 'coachesNextSteps',
                placeholder: '',
            },
            {
                label: "Teachers will engage in standards-based planning by",
                fieldDecorator: 'teachersPlans',
                placeholder: '',
            },
            {
                label: "Teachers will use effective practices to ensure access to the standards by",
                fieldDecorator: 'teachersEnsureAccess',
                placeholder: '',
            },
            {
                label: "Teachers will capture students' progress by",
                fieldDecorator: 'teachersAssessProgress',
                placeholder: '',
            },
            {
                label: 'Teachers next steps',
                fieldDecorator: 'teachersNextSteps',
                placeholder: '',
            },
        ]
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleOk = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!(this.props.schoolId && this.props.districtId && this.props.person && this.props.accessLevel)) {
            console.error('missing props in report form', this.props)
            return
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values)

                this.setState({
                    submitting: true,
                })

                values.districtId = this.props.districtId
                values.schoolId = this.props.schoolId
                values.person = this.props.person
                values.personRole = this.props.accessLevel
                values.timeStamp = firebase.firestore.FieldValue.serverTimestamp()

                //console.log(values)

                db.collection(ColType.schoolReport)
                    .add(values)
                    .then((docRef) => {
                        console.log("Document written with ID: ", docRef.id)
                        successMessage('Report added successfully.')
                        this.setState({
                            submitting: false,
                        })
                        this.props.form.resetFields()
                        this.handleCancel()
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error)
                        errorMessage('Error adding report, please try again.')
                        this.setState({
                            submitting: false,
                        })
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div className='ml-auto'>
                <Button type="primary" className="lg-btn" onClick={this.showModal}>
                    <Icon type="plus" /> Add full report
                </Button>
                <Modal
                    title="Add full report"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        {this.state.formData.map((item, index) => {
                            return <FormItem {...formItemBlockLayout} label={item.label + '::'}>
                                {getFieldDecorator(item.fieldDecorator, {
                                    rules: [{ required: true, message: 'Required.' }],
                                })(
                                    <TextArea rows={4} size={"large"} placeholder={item.placeholder} />
                                )}
                            </FormItem>
                        })}

                        <FormItem className="mb-0">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="lg-btn float-right"
                            >
                                {this.state.submitting ?
                                    <span><Icon type="loading" className="mr-1" />Adding full report...</span> :
                                    <span><Icon type="plus" /> Add full report</span>}
                            </Button>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(SchoolReportForm)