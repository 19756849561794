import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import IEPDrafts from './IEPDrafts'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import {
  flattenDoc, getIDFromURL, capitalizeFirstChar, getTotalPoints,
  getQueryStringParam, createMTSSGoalText, createELAPresentLevelTextMTSS,
  createMathPresentLevelTextMTSS, createSELPresentLevelTextMTSS
} from '../.././Util'
import { Layout, Icon, Form, Button, Checkbox } from 'antd'
import DraftStepContainer from '../addiep/DraftStepContainer'
import StandardNumber from '../../customcomponents/StandardNumber'
const { Content } = Layout
const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group
const Promise = require("bluebird")

class TeacherAddIEPGoalSelect extends Component {
  state = {
    teacher: null,
    student: null,
    iepDict: {},
    iepSubjectDict: {},
    iepSelections: {},
    iepSelectionsNoCount: {},
    iepCategories: {},
    iepDraft: null,
    students: [],
    tabKey: '1',
    stepNum: 2,
    iepDraftMounted: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      if (this.props.form) this.props.form.resetFields()
      this.componentDidMount()
    }
  }

  componentDidMount() {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var draftId = getQueryStringParam('draft')
    console.log(teacherId, studentId)

    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      draftId: draftId,
      tabKey: '1',
    })

    if (draftId) {
      db.collection(ColType.pepDrafts)
        .doc(draftId)
        .get()
        .then((doc) => {
          this.setState({
            iepDraft: flattenDoc(doc),
            iepDraftMounted: true,
          }, () => {
            var iepDraft = this.state.iepDraft
            console.log(iepDraft)

            db.collection(ColType.student)
              .doc(studentId)
              .get()
              .then((doc) => {
                var student = flattenDoc(doc)
                var iepDict = {}
                var iepSubjectDict = {}
                var iepSelections = {}
                var iepCategories = {}
                var iepSelectionsNoCount = {}
                var standardPromises = []
                var grade = student.grade
                if (grade.toLowerCase() === 'kindergarten') {
                  grade = 'K'
                }

                iepDraft.subject.map((subject, index) => {
                  iepSubjectDict[subject] = []
                  iepSelectionsNoCount[subject] = []
                  if (subject === 'Reading Foundations') {
                    iepSelections[subject] = { curr: 0, max: 3, selections: [] }
                  }
                  else iepSelections[subject] = { curr: 0, max: 2, selections: [] }
                  return standardPromises.push(db.collection(ColType.pepStandards)
                    .where('grade', '==', grade)
                    .where('mainSubject', '==', subject)
                    .where('tier', '==', iepDraft.tier)
                    .orderBy('standard', 'asc')
                    .get()
                  )
                })

                Promise.all(standardPromises)
                  .then((querySnapshots) => {
                    querySnapshots.map((querySnapshot, index) => {
                      return querySnapshot.forEach((doc) => {
                        var iep = flattenDoc(doc)
                        // set starting accuracy levels
                        iep.baselineAccuracyLevel = '<number>'
                        if (iep.tier === 'Tier 2') {
                          iep.baselineAccuracyLevel = '0'
                        }
                        iep.targetAccuracyLevel = '<number>'
                        iep.presentLevel = JSON.parse(JSON.stringify(iepDraft.presentLevel))
                        if (!iepCategories.hasOwnProperty(iep.mainSubject)) {
                          iepCategories[iep.mainSubject] = {}
                        }
                        iepCategories[iep.mainSubject][iep.standardCategory] = true
                        var strength = "N/A"
                        if (iepDraft.hasOwnProperty('strength-' + iep.mainSubject)) {
                          strength = iepDraft['strength-' + iep.mainSubject]
                        }
                        // add strength to iep
                        iep.presentLevel.strength = strength
                        if (iep.mainSubject === 'Social Emotional Learning') {
                          iep.presentLevel.presentLevelParagraph =
                            createSELPresentLevelTextMTSS(iep, iep.presentLevel, student, strength)
                        }
                        else if (iep.mainSubject === 'Math') {
                          iep.presentLevel.presentLevelParagraph =
                            createMathPresentLevelTextMTSS(iep, iep.presentLevel, student, strength)
                        }
                        else {
                          iep.presentLevel.presentLevelParagraph =
                            createELAPresentLevelTextMTSS(iep, iep.presentLevel, student, strength)
                        }
                        iep.iepParagraph = createMTSSGoalText(iep, student)
                        iep.totalPoints = getTotalPoints(iep)

                        iepSubjectDict[iep.mainSubject].push(iep)
                        iepDict[iep.id] = iep
                      })
                    })

                    this.setState({
                      student: student,
                      iepDict: iepDict,
                      iepSubjectDict: iepSubjectDict,
                      iepSelections: iepSelections,
                      iepCategories: iepCategories,
                    }, () => {
                      var formObj = {}
                      if (iepDraft.iepSelectionsNoCount &&
                        !(Object.keys(iepDraft.iepSelectionsNoCount).length === 0 &&
                          iepDraft.iepSelectionsNoCount.constructor === Object)) {
                        formObj = iepDraft.iepSelectionsNoCount
                      }
                      /*if (iepDraft.hasOwnProperty('subject')) {
                        iepDraft.subject.map((subject, index) => {
                          if (!iepDraft.hasOwnProperty(subject + '-problemAnalysis')) return false
                          return formObj[subject + '-problemAnalysis'] = 
                                iepDraft[subject + '-problemAnalysis']
                        })
                      }*/
                      this.props.form.setFieldsValue(formObj)
                    })
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              })
          })
        })
    }

    db.collection(ColType.teacher)
      .doc(teacherId)
      .get()
      .then((doc) => {
        var teacher = flattenDoc(doc)
        this.setState({
          teacher: teacher,
        })
      })

    db.collection(ColType.student)
      .where('teacherId', '==', teacherId)
      .get()
      .then((querySnapshot) => {
        var students = []
        var studentDict = {}
        querySnapshot.forEach((doc) => {
          //console.log(doc.id, ' => ', doc.data())
          var student = flattenDoc(doc)
          students.push(student)
          studentDict[student.id] = student
        })

        this.setState({
          students: students,
          studentDict: studentDict,
        })
      })
  }

  checkBoxGroupChange = (checkedValue, subject) => {
    //console.log(checkedValue, subject)
    var iepSelections = this.state.iepSelections
    var iepSelectionsNoCount = this.state.iepSelectionsNoCount
    //console.log(iepSelections[subject].curr, iepSelections[subject].max,
    //  iepSelections[subject].selections)
    //if (checkedValue > iepSelections[subject].max) return
    iepSelections[subject].curr = checkedValue.length
    iepSelections[subject].selections = checkedValue
    iepSelectionsNoCount[subject] = checkedValue

    this.setState({
      iepSelections: iepSelections,
      iepSelectionsNoCount: iepSelectionsNoCount,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('received values from form', values)

        var newState = this.state.iepDraft
        newState.iepSelectionsNoCount = values
        var iepStandards = []
        var ieps = []
        var selectedStandardDict = {}

        console.log("newState", newState)

        newState.subject.map((subject, index) => {
          return newState.iepSelectionsNoCount[subject].map((iepId, index) => {
            //newState[subject + '-problemAnalysis'] = values[subject + '-problemAnalysis']
            //var iepId = newState.iepSelectionsNoCount[subject]
            //return newState.iepSelectionsNoCount[subject].map((iepId, index) => {
            var iep = this.state.iepDict[iepId]
            //iep.problemAnalysis = values[subject + '-problemAnalysis']
            selectedStandardDict[iep.standard] = iep.id
            if (newState.hasOwnProperty('mayHaveTroubleWith') && newState.mayHaveTroubleWith &&
              newState.hasOwnProperty('mayHaveTroubleWithDescription') && newState.mayHaveTroubleWithDescription) {
              iep.mayHaveTroubleWith = newState.mayHaveTroubleWith
              iep.mayHaveTroubleWithDescription = newState.mayHaveTroubleWithDescription
            }
            if (newState.hasOwnProperty("studentInterest") &&
              newState.studentInterest) {
              iep.studentInterest = newState.studentInterest
            }
            // copy of progress monitoring for baseline/target for the next step in the form.
            iep.baselineProgressMonitoring = JSON.parse(JSON.stringify(iep.progressMonitoring))
            iep.targetProgressMonitoring = JSON.parse(JSON.stringify(iep.progressMonitoring))
            for (var i = 0; i < iep.baselineProgressMonitoring.length; i++) {
              iep.baselineProgressMonitoring[i].max = iep.baselineProgressMonitoring[i].num
              iep.targetProgressMonitoring[i].max = iep.targetProgressMonitoring[i].num
              iep.baselineProgressMonitoring[i].num = 0
              iep.targetProgressMonitoring[i].num = 0
            }
            iepStandards.push(this.state.iepDict[iepId].standard)
            console.log("iep", iep)
            ieps.push(iep)
            return false
            // })
          })
        })

        newState.iepStandards = iepStandards
        newState.ieps = ieps
        newState.selectedStandardDict = selectedStandardDict

        //newState.step = {stepNum: this.state.stepNum + 1, path: 'modify'}
        newState.step = { stepNum: this.state.stepNum + 1, path: 'modify' }

        db.collection(ColType.pepDrafts)
          .doc(this.state.draftId)
          .set(newState, { merge: true })
          .then(() => {
            this.props.history.push(
              {
                pathname: '/teacher/mtss-add-goal-modify/' +
                  this.state.teacherId + '?student=' +
                  this.state.studentId +
                  '&draft=' + this.state.draftId,
                state: newState, // pass state to confirm page
              }
            )
          })
      }
    })
  }

  tabChange = (activeKey) => {
    this.setState({ tabKey: activeKey })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return <div>
      <Layout className="content layout-header-mt">
        <Layout>
          <Content className="min-w-1120">
            <div>
              {/* 
          <div className="text-center">
            <img src="/draft-plan/3.png" alt="steps" />
          </div>
          */}
              <DraftStepContainer
                teacherBaseProps={this.props.teacherBaseProps}
                dataLoaded={this.state.iepDraftMounted && this.state.teacher && this.state.student && this.state.draftId}
                isMTSS={true}
              >
                <div>
                  {this.state.teacher && this.state.student && this.state.draftId ?
                    <div>

                      <div className="w-800 m-lr-auto background-fff br-15">
                        <div className='p-4 bkg-purple br-15'>
                          <div className='flex flex-v-center w-100'>
                            <div>
                              {this.props.teacherBaseProps &&
                                this.props.teacherBaseProps.studentDict &&
                                this.props.teacherBaseProps.studentDict
                                  .hasOwnProperty(this.state.studentId)
                                ?
                                <div className="flex flex-v-center mb-0 font-24">
                                  <div>
                                    <div className='font-20 font-fff'>
                                      Tier Plan Draft
                                    </div>
                                    <h1 className="font-40 mb-0 font-fff font-bold">
                                      Standards
                                    </h1>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <img src="/draft-icons/3.png" alt='draft-iep' width={250} className='ml-auto' />
                          </div>
                        </div>

                        <div className='p-4'>
                          <div>
                            {this.state.student ?
                              <h3 className="mb-3 font-24">Based on formal and informal assessments,
                                knowledge and skills need to be improved in
                                (choose one goal per content area):</h3>
                              : ''}

                            <Form onSubmit={this.handleSubmit} className="login-form text-align-left">

                              {Object.keys(this.state.iepSubjectDict).map((subject, index) => {
                                return <div key={'form-item-' + subject}>
                                  <h2 className="mt-4 mb-0 pb-1 border-bottom">
                                    <span className="font-30 font-normal">{subject}</span>
                                  </h2>
                                  <FormItem {...formItemLayout} label={null}
                                    className="block-label">
                                    {getFieldDecorator(subject, {
                                      rules: [{ required: true, message: 'Required' }],
                                    })(
                                      <CheckboxGroup
                                        className="block"
                                        onChange={(checkedValue) =>
                                          this.checkBoxGroupChange(checkedValue, subject)}
                                      >
                                        {this.state.iepCategories.hasOwnProperty(subject) &&
                                          Object.keys(this.state.iepCategories[subject]).map((standardCategory, index) => {
                                            return <div key={"iep-math-" + standardCategory}>
                                              <div className="font-20 mb-2 mt-2 font-bold">
                                                {standardCategory}
                                              </div>
                                              {this.state.iepSubjectDict[subject].map((iep, index) => {
                                                if (iep.standardCategory !== standardCategory) {
                                                  return false
                                                }
                                                return <div className="font-18 mt-1 flex flex-v-center"
                                                  key={'iep-checkbox-row-' + iep.id}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      value={iep.id}
                                                      className="block ml-0 font-16 font-black"
                                                      key={'checkbox-iep-' + iep.id}
                                                    >
                                                      <span className="pl-1 font-normal">
                                                        <StandardNumber
                                                          district={this.props.teacherBaseProps.district}
                                                          iep={iep}
                                                          className='mr-0'
                                                          containerClassName={'pl-1 pr-1 mr-05 br-100 font-14 va-2-important'}
                                                          containerStyle={{ backgroundColor: '#e8e8e8' }}
                                                        />
                                                        <span>
                                                          {capitalizeFirstChar(iep.standardDescription)}
                                                        </span>
                                                      </span>
                                                    </Checkbox>
                                                  </div>
                                                </div>
                                              })
                                              }
                                            </div>
                                          })
                                        }
                                      </CheckboxGroup>
                                    )}
                                  </FormItem>


                                </div>
                              })
                              }


                              <div className="mb-0 mt-4 pt-2 flex flex-v-center">
                                <Link
                                  to={'/teacher/mtss-add-goal-information/' +
                                    this.state.teacherId + '?student=' +
                                    this.state.studentId +
                                    '&draft=' + this.state.draftId}
                                  className="font-18"
                                >
                                  <span className="left-hover-parent text-cyan">
                                    <Icon type="arrow-left" className="mr-1 left-hover-child" />
                                    <span>Previous Step</span>
                                  </span>
                                </Link>
                                <Button
                                  type="primary"
                                  size={'large'}
                                  htmlType="submit"
                                  className="lg-btn ml-auto"
                                >
                                  Continue
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ''}

                </div>
              </DraftStepContainer>
            </div>

          </Content>
        </Layout>
      </Layout>
    </div>
  }
}

export default Form.create()(TeacherAddIEPGoalSelect)