import React, { Component } from 'react';
import AssessmentAnalytics from './AssessmentAnalytics';
//import Empty from '../customcomponents/Empty';

class AdminAssessmentAnalytics extends Component {
    state = {
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                {this.props.adminBaseProps &&
                    this.props.adminBaseProps.district &&
                    this.props.adminBaseProps.districtSettings &&
                    this.props.adminBaseProps.schools &&
                    this.props.adminBaseProps.schoolsDict &&
                    this.props.adminBaseProps.admin ?
                    <AssessmentAnalytics
                        districtId={this.props.adminBaseProps.district.id}
                        district={this.props.adminBaseProps.district}
                        districtSettings={this.props.adminBaseProps.districtSettings}
                        schools={this.props.adminBaseProps.schools}
                        schoolsDict={this.props.adminBaseProps.schoolsDict}
                        reviewerId={this.props.adminBaseProps.admin.id}
                        person={{
                            firstName: this.props.adminBaseProps.admin.firstName,
                            lastName: this.props.adminBaseProps.admin.lastName,
                        }}
                        analyticsFor='district'
                    /> : ''}
            </div>

        );
    }
}

export default AdminAssessmentAnalytics;
