import React from 'react'

class MatchingGame extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            shuffledItems: this.shuffleItems(props.items),
        }
    }

    shuffleItems(items) {
        let itemsList = Object.entries(items)
        let list1, list2

        [list1, list2] = itemsList.reduce(([acc1, acc2], [k, v]) => {
            acc1.push(k)
            acc2.push(v)
            return [acc1, acc2]
        }, [[], []])

        for (let i = list1.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            let temp1 = list1[i]
            let temp2 = list2[i]
            list1[i] = list1[j]
            list1[j] = temp1
            list2[i] = list2[j]
            list2[j] = temp2
        }

        return list1.map((k, i) => ({ word: k, definition: list2[i] }))
    }

    render() {
        const { title, instructions } = this.props
        const { shuffledItems } = this.state

        return (
            <div>
                <div className='font-24 font-bold mb-2'>{title}</div>
                <div className='font-20 mb-1'>{instructions}</div>
                <div className='font-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        {shuffledItems.map((item, index) => (
                            <li key={index}>{item.word}</li>
                        ))}
                    </ol>
                    <ol style={{ listStyleType: 'decimal' }}>
                        {shuffledItems.map((item, index) => (
                            <li key={index}>{item.definition}</li>
                        ))}
                    </ol>
                </div>
            </div>
        )
    }
}

export default MatchingGame
