import React from 'react'
import MathText from './MathText'

class Text extends React.Component {
    render() {
        const { title, body } = this.props
        return (
            <div>
                <div className='font-24 font-bold mb-2'>{title}</div>
                <div className='font-20'>
                    <MathText text={body} />
                </div>
            </div>
        )
    }
}

export default Text
