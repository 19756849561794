import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, getQueryStringParam, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { Icon, Form, Input, Button, message } from 'antd'
const FormItem = Form.Item

const successMessage = (description) => {
  message.success(description)
}

const errorMessage = (description) => {
  message.error(description)
}

// Handles verify email, password resets, and recover email for users.
// This is the page users get redirected to from emails.
class UserManagement extends Component {
  state = {
    mode: '',
    actionCode: '',
    continueUrl: '',
    resettingPassword: false,
    emailIsVerified: true,
    emailVerificationError: false,
    authListener: null,
  }

  componentDidMount() {
    var auth = firebase.auth()
    // Get the action to complete.
    var mode = getQueryStringParam('mode')
    // Get the one-time code from the query parameter.
    var actionCode = getQueryStringParam('oobCode')
    // (Optional) Get the continue URL from the query parameter if available.
    var continueUrl = getQueryStringParam('continueUrl')

    if (mode === 'resetPassword') {
      // for reset password only, after resetting password they are logged in and
      // then redirected using linkAfterLogin func. VerifyEmail logs them in on that
      // page so there is no conflict. Also, on this page they are not logged in when
      // they get here so there is also no conflict.
      var authListener = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          console.log("signed in user", user)
          db.collection(ColType.users)
            .doc(user.uid)
            .get()
            .then((doc) => {
              var userInfo = flattenDoc(doc)
              console.log("userinfo", userInfo)
              db.collection(userInfo.accessLevel)
                .doc(userInfo.refId)
                .get()
                .then((doc) => {
                  var userJobInfo = flattenDoc(doc)
                  console.log(userInfo, userJobInfo)
                  linkAfterLogin(userInfo, userJobInfo)
                })
            })
        } else {
          console.log("signed out user", user)
          // User is signed out.
        }
      })

      this.setState({
        authListener: authListener,
      })
    }

    this.setState({
      mode: mode,
      actionCode: actionCode,
      continueUrl: continueUrl,
    }, () => {
      // Handle the user management action.
      switch (mode) {
        case 'resetPassword':
          // User will see a form and will reset their password, on success they will be redirected.
          break
        case 'recoverEmail':
          // Display email recovery handler and UI.
          this.handleRecoverEmail(auth, actionCode)
          break
        case 'verifyEmail':
          // Display email verification handler and UI.
          this.handleVerifyEmail(auth, actionCode, continueUrl)
          break
        default:
          console.log("mode not found.")
      }
    })
  }

  componentWillUnmount() {
    if (this.state.authListener) {
      this.state.authListener()
    }
  }

  handleResetPassword = (auth, actionCode, continueUrl, newPassword) => {
    // Verify the password reset code is valid.
    auth.verifyPasswordResetCode(actionCode).then(function (email) {
      var accountEmail = email

      // Save the new password.
      auth.confirmPasswordReset(actionCode, newPassword).then(function (resp) {
        // Password reset has been confirmed and new password updated.
        successMessage("Your password has been successfully changed.")
        // sign the user in with the new password, 
        // when auth changes they will be redirected to the correct page.
        firebase.auth().signInWithEmailAndPassword(accountEmail, newPassword).catch((error) => {
          // Handle Errors here.
          var errorCode = error.code
          var errorText = error.message
          console.log(errorCode, errorText)
        })

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword)

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch(function (error) {
        console.log(error)
        errorMessage("Something went wrong, please try again.")
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      })
    }).catch(function (error) {
      console.log(error)
      errorMessage("Something went wrong, please try again.")
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    })
  }

  handleRecoverEmail = (auth, actionCode) => {
    var restoredEmail = null
    // Confirm the action code is valid.
    auth.checkActionCode(actionCode).then(function (info) {
      // Get the restored email address.
      restoredEmail = info['data']['email']

      // Revert to the old email.
      return auth.applyActionCode(actionCode)
    }).then(function () {
      // Account email reverted to restoredEmail

      // TODO: Display a confirmation message to the user.

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
      auth.sendPasswordResetEmail(restoredEmail).then(function () {
        // Password reset confirmation sent. Ask user to check their email.
      }).catch(function (error) {
        // Error encountered while sending password reset code.
      })
    }).catch(function (error) {
      // Invalid code.
    })
  }


  handleVerifyEmail = (auth, actionCode, continueUrl) => {
    console.log("Handle verify email.")

    // Try to apply the email verification code.
    auth.applyActionCode(actionCode).then((resp) => {
      // Email address has been verified.
      console.log("Email address has been verified.")
      successMessage("Your account has been verified.")
      this.setState({
        emailIsVerified: true,
        continueUrl: continueUrl,
      })
      // continueURL has a path after the hostname dotitiep.app,
      // split to get the path so that it can be used in history.push
      // Redirect user to the continue URL
      // window.location.href = continueUrl
      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
      errorMessage("Email address could not be verified " +
        "(Have you already verified this email?).")
      this.setState({
        emailVerificationError: true,
      })
    })
  }

  // adding directly here, in the future it will send an email.
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      message.destroy() // destroy previous error messages on form submit.
      if (!err) {
        //console.log('Received values of form: ', values)

        if (values.password !== values.confirmPassword) {
          errorMessage("Passwords do not match, please try again.")
          return
        }
        else if (values.password.length < 6 || values.confirmPassword.length < 6) {
          errorMessage("Password must be at least 6 characters.")
          return
        }

        this.setState({
          resettingPassword: true,
        }, () => {
          this.handleResetPassword(firebase.auth(),
            this.state.actionCode, this.state.continueUrl, values.password)
        })
      }
    })
  }

  render() {
    console.log(this.state.mode)
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    return (
      <div className="h-100 pt-4 pb-70">
        {this.state.mode === "resetPassword" ?
          <div>
            <div className="block w-100 m-lr-auto mb-3 text-center">
              <div>
                <img src='/dotitlogo.png' alt='logo' height='64' />
              </div>
            </div>
            <div className="g-col p-4 w-500 m-lr-auto br-15 ant-shadow-more">
              <div className="mb-0 text-center font-30 font-bold">Reset your password</div>
              <div className="font-18 mb-3">Enter your new password and return back to Dot It.</div>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem {...formItemLayout} label="Password">
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input a password.' }],
                  })(
                    <Input
                      size={"large"}
                      type="password"
                      placeholder="Password..." />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Confirm Password">
                  {getFieldDecorator('confirmPassword', {
                    rules: [{ required: true, message: 'Please input a password.' }],
                  })(
                    <Input
                      size={"large"}
                      type="password"
                      placeholder="Password..." />
                  )}
                </FormItem>
                <FormItem className="mb-0">
                  <Button
                    disabled={this.state.resettingPassword}
                    size={"large"}
                    type="primary"
                    htmlType="submit"
                    className="full-width-btn mt-05">
                    {!this.state.resettingPassword ?
                      <span>Confirm new password</span> :
                      <span><Icon type="loading" className="mr-8" />Resetting...</span>
                    }
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div> : ''}
        {this.state.mode === "verifyEmail" ?
          <div className="pt-70 pb-70 background-light-cyan-gradient h-100">
            <div className="block w-100 m-lr-auto mb-4 pb-1 text-center">
              <img src='/dot-it-logo.png' alt='logo' height='64' />
            </div>
            <div className="g-col large-form-padding w-500 m-lr-auto br-15">
              <div className="mb-2 text-center pb-2 font-24 font-bold">
                Verify Email
              </div>
              <div className="font-18 mb-4 text-center">
                {this.state.emailVerificationError ?
                  <div>
                    <div>Your email could not be verified.</div>
                    <div>Have you already verified this email?</div>
                  </div>
                  :
                  this.state.emailIsVerified ?
                    <div>
                      <div className="mb-2">Your email is verified!</div>
                      <div>
                        {this.state.continueUrl ?
                          <a href={this.state.continueUrl}
                            className={"ant-btn ant-btn-primary ant-btn-large " +
                              "font-18 font-bold-important"}>
                            Go to My Account
                          </a>
                          : ''}
                      </div>
                    </div>
                    :
                    "Verifying your email..."
                }
              </div>
            </div>
            <div className="font-15 w-500 m-lr-auto text-center mt-2">
              If you are experiencing any issues please email support@dotit.app.
            </div>
          </div>
          : ''}
      </div>
    )
  }
}

export default Form.create()(UserManagement)