import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Row, Col } from 'antd'
var moment = require('moment')

class SuperAdminContactUs extends Component {
  state = {
    submitting: false,
    contactUsMessages: [],
  }

  componentDidMount() {
    db.collection(ColType.contactUs)
        .orderBy('timeStamp', 'desc')
        //.limit(30)
        .get()
        .then((querySnapshot) => {
          var contactUsMessages = []
          querySnapshot.forEach((doc) => {
            var contactUsMessage = flattenDoc(doc)
            contactUsMessages.push(contactUsMessage)
          })

          this.setState({
            contactUsMessages: contactUsMessages,
          })
        })
  }

  render() {
    return (
        <div>
        <SuperAdminHeader menuKey={'contact-us'} />
        <div className="mt-100">
            <div className="w-1024 p-3 m-lr-auto">
                <div className="mb-3 font-bold">
                    <div className="mb-3 font-30">Contact us (From Take a look)</div>
                    <Row gutter={32} className="font-16 font-bold mb-1">
                        <Col span={6}>
                            First Name
                        </Col>
                        <Col span={10}>
                            Email Address
                        </Col>
                        <Col span={8}>
                            Date
                        </Col>
                    </Row>
                    {this.state.contactUsMessages.map((message, idx) => {
                        return <div className="font-16 font-normal" key={message.id}>
                            <Row gutter={32}>
                                <Col span={6}>
                                    {message.firstName}
                                </Col>
                                <Col span={10}>
                                    {message.emailAddress}
                                </Col>
                                <Col span={8}>
                                    {moment.utc(message.timeStamp.seconds * 1000).format("MM/DD/YYYY")}
                                </Col>
                            </Row>
                        </div>
                    })}
                </div>
            </div>
            
        </div>
        </div>
    )
  }
}

export default SuperAdminContactUs
