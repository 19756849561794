import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { Button, Icon, Tooltip } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class CopyText extends Component {
    state = {
        visible: false,
        visibleTimeout: null,
    }

    componentDidMount() {

    }

    onCopy = () => {
        if (this.state.visibleTimeout) {
            clearTimeout(this.state.visibleTimeout)
        }

        this.setState({
            visible: true,
            visibleTimeout: setTimeout(() => {
                this.setState({
                    visible: false,
                })
            }, 2000)
        })
    }

    render() {
        return (
            <CopyToClipboard text={this.props.text}
                onCopy={() => { this.onCopy() }}>
                <Tooltip title="Copied!" visible={this.state.visible} placement="right">
                    <Button type="primary" size="large" className='button--main flex flex-v-center flex-h-center font-16'>
                        <Icon type="copy" />
                        <span>Copy</span>
                    </Button>
                </Tooltip>
            </CopyToClipboard>
        )
    }
}

export default CopyText
