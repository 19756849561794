import React from 'react'

function Assessment({ assessment }) {
    return (
        <div style={{ margin: '1in' }}>
            <h1>Assessment Questions</h1>
            <ol>
                {assessment.map((question, index) => (
                    <li key={index}>
                        <p>
                            (DOK {question.dokLevel}) {question.id}. {question.question}
                        </p>
                        <ul>
                            {question.answers.map((answer, answerIndex) => (
                                <li key={answerIndex}>
                                    {answer.correct ? (
                                        <div>
                                            <span style={{ color: 'green' }}>✔</span> {answer.text}
                                            <p>
                                                <strong>Reason Correct:</strong> {answer.reason}
                                            </p>
                                        </div>
                                    ) : (
                                        <div>
                                            <span style={{ color: 'red' }}>✖</span> {answer.text}
                                            <p>
                                                <strong>Reason Incorrect:</strong> {answer.reason}
                                            </p>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default Assessment
