import React, { Component } from 'react';
import '../styles/GlobalStyle.css';
import ColType from '../Types';
import { db, firebase } from '../firebase/Firebase';
import { Form, Input, Button, Icon, Modal, notification, DatePicker } from 'antd';
import moment from 'moment';

const { TextArea } = Input;
const FormItem = Form.Item;

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    });
};

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    });
};

class ProfessionalLearningReportUpdateForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        formData: [
            {
                label: 'Title',
                fieldDecorator: 'title',
                placeholder: 'Enter the title of the plan',
                type: 'input',
                required: true
            },
            {
                label: 'Description',
                fieldDecorator: 'description',
                placeholder: 'Describe the plan',
                type: 'textarea',
                required: true
            },
            {
                label: 'Planned by',
                fieldDecorator: 'plannedBy',
                placeholder: 'Enter the name of the planner',
                type: 'input',
                required: true
            },
            {
                label: 'Goal',
                fieldDecorator: 'goal',
                placeholder: 'Enter the goal for this plan',
                type: 'textarea',
                required: true
            },
            {
                label: "To support this plan the Administration will",
                fieldDecorator: 'adminSupport',
                placeholder: 'Describe administration support',
                type: 'textarea',
                required: true
            },
            {
                label: "To support the plan the Coach will",
                fieldDecorator: 'coachSupport',
                placeholder: 'Describe coach support',
                type: 'textarea',
                required: true
            },
            {
                label: "To support the plan the Teacher will",
                fieldDecorator: 'teacherSupport',
                placeholder: 'Describe teacher support',
                type: 'textarea',
                required: true
            },
            {
                label: "Reflection 1",
                fieldDecorator: 'reflection1',
                placeholder: 'Enter the first reflection (optional)',
                type: 'textarea',
                required: false
            },
            {
                label: "Reflection 1 Date",
                fieldDecorator: 'reflection1Date',
                type: 'date',
                required: false
            },
            {
                label: "Reflection 2",
                fieldDecorator: 'reflection2',
                placeholder: 'Enter the second reflection (optional)',
                type: 'textarea',
                required: false
            },
            {
                label: "Reflection 2 Date",
                fieldDecorator: 'reflection2Date',
                type: 'date',
                required: false
            },
        ]
    };

    componentDidMount() {
        if (this.props.report) {
            let formObj = {};
            for (let formVal of this.state.formData) {
                if (this.props.report.hasOwnProperty(formVal.fieldDecorator)) {
                    if (formVal.type === 'date' && this.props.report[formVal.fieldDecorator]) {
                        formObj[formVal.fieldDecorator] = moment(this.props.report[formVal.fieldDecorator].toDate());
                    } else {
                        formObj[formVal.fieldDecorator] = this.props.report[formVal.fieldDecorator];
                    }
                }
            }
            this.props.form.setFieldsValue(formObj);
        }
    }

    showModal = () => {
        this.setState({ modalVisible: true });
    };

    handleOk = () => {
        this.setState({ modalVisible: false });
    };

    handleCancel = () => {
        this.setState({ modalVisible: false });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!(this.props.schoolId && this.props.districtId && this.props.report)) {
            console.error('missing props', this.props);
            return;
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('here', values);
                this.setState({ submitting: true });
                if (!values.reflection1) {
                    values.reflection1 = '';
                }
                if (!values.reflection2) {
                    values.reflection2 = '';
                }
                if (!values.reflection1Date) {
                    values.reflection1Date = null;
                }
                if (!values.reflection2Date) {
                    values.reflection2Date = null;
                }

                // Convert moment objects to JavaScript Date objects
                if (values.hasOwnProperty('reflection1Date') && values.reflection1Date) {
                    values.reflection1Date = values.reflection1Date.toDate();
                }
                if (values.hasOwnProperty('reflection2Date') && values.reflection2Date) {
                    values.reflection2Date = values.reflection2Date.toDate();
                }

                db.collection(ColType.professionalLearningReport)
                    .doc(this.props.report.id)
                    .update(values)
                    .then(() => {
                        successMessage('Report edited successfully.');
                        this.setState({ submitting: false });
                        this.handleCancel();
                    })
                    .catch((error) => {
                        console.error("Error updating document: ", error);
                        errorMessage('Error editing report, please try again.');
                        this.setState({ submitting: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemBlockLayout = {
            labelCol: { xs: { span: 24 }, sm: { span: 24 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
        };

        return (
            <div>
                <Button type="default" className="lg-btn mr-1" onClick={this.showModal}>
                    <Icon type="edit" /> Edit
                </Button>
                <Modal
                    title="Edit plan report"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        {this.state.formData.map((item, index) => (
                            <FormItem {...formItemBlockLayout} label={item.label + ':'} key={index}>
                                {getFieldDecorator(item.fieldDecorator, {
                                    rules: [{ required: item.required, message: item.required ? 'Required.' : '' }],
                                })(
                                    item.type === 'input' ? (
                                        <Input size="large" placeholder={item.placeholder} />
                                    ) : item.type === 'textarea' ? (
                                        <TextArea rows={4} size="large" placeholder={item.placeholder} />
                                    ) : item.type === 'date' ? (
                                        <DatePicker size="large" style={{ width: '100%' }} />
                                    ) : null
                                )}
                            </FormItem>
                        ))}

                        <FormItem className="mb-0">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="lg-btn float-right"
                                disabled={this.state.submitting}
                            >
                                {this.state.submitting ?
                                    <span><Icon type="loading" className="mr-1" /> Editing...</span> :
                                    <span><Icon type="edit" /> Edit</span>}
                            </Button>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(ProfessionalLearningReportUpdateForm);