import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { firebaseStorage } from '../../firebase/Firebase'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import Empty from '../../customcomponents/Empty'
//import PDFViewer from '../student/PDFViewer'
//import { Document, Page } from 'react-pdf'
import { Modal } from 'antd'
import StudentUpload from '../student/StudentUpload'
//import StudentUploads from '../student/StudentUploads'


class TeacherStudentUploadFileView extends Component {
    state = {
        teacherId: '',
        studentId: '',
        fileId: '',
        student: null,
        fileIdx: -1,
        pageNumber: 0,
        uploadedFile: null,
        downloadURLFromFileName: '',
        downloadURL: '',
        deletePlanModalVisible: false,
        addFileVisible: false,
        deleteDropdownVisible: {},
        deletingUploadedFile: false,
        deleteUploadedFile: null,
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    getUploadedFileDownloadURL() {
        if (!this.props.uploadedFile) {
            return
        }

        var downloadURLFromFileName = this.props.uploadedFile.fileName

        this.setState({
            downloadURLFromFileName: downloadURLFromFileName,
        }, () => {
            // get download url from call, not stored
            firebaseStorage.ref(this.props.uploadedFile.filePath).getDownloadURL()
                .then((url) => {
                    // because files can quickly switch, and this is an async call,
                    // see if the newest selected file is still this file, if its not
                    // then don't setState with it, wait for the newer call to finish.
                    if (downloadURLFromFileName === this.state.downloadURLFromFileName) {
                        this.setState({
                            downloadURL: url
                        })
                    }
                })
                .catch((e) => {
                    console.log("error", e)
                })
        })
    }

    componentDidMount() {
        this.getUploadedFileDownloadURL()
    }

    componentWillReceiveProps(nextProps) {
        this.getUploadedFileDownloadURL()
    }

    onDeleteDropdownVisibleChange = (IEPId, visible) => {
        var deleteDropdownVisible = this.state.deleteDropdownVisible
        deleteDropdownVisible[IEPId] = visible
        this.setState({
            deleteDropdownVisible: deleteDropdownVisible,
        })
    }

    getFileIcon = (contentType) => {
        if (contentType === 'image/png' || contentType === 'image/jpg' ||
            contentType === 'image/jpeg') return 'picture'
        else if (contentType === 'application/octet-stream') return 'file-text'
        return 'file'
    }

    render() {
        let isDefaultVisibleFile = false
        if (this.props.uploadedFile &&
            this.props.uploadedFile.hasOwnProperty("downloadURL") &&
            this.props.uploadedFile.hasOwnProperty("contentType") &&
            (this.props.uploadedFile.contentType === "application/pdf" ||
                this.props.uploadedFile.contentType === "application/pdf" ||
                this.props.uploadedFile.contentType.includes("video") ||
                this.props.uploadedFile.contentType.includes("image"))) {
            isDefaultVisibleFile = true
        }

        return (
            <div>
                <Modal
                    title="Upload File"
                    visible={this.state.visible}
                    width={888}
                    footer={null}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div>
                        {this.props.teacherBaseProps.uploadedFilesFileNameDict ?
                            <StudentUpload
                                uploadedFilesFileNameDict={this.props.teacherBaseProps.uploadedFilesFileNameDict}
                                teacher={this.props.teacherBaseProps.teacher}
                                student={this.state.student}
                                onSuccess={this.hideModal}
                            />
                            : ''}
                    </div>
                </Modal>

                {this.props.uploadedFile &&
                    this.state.downloadURL ?
                    <div>


                        <div className="w-100 mb-2">
                            <div className="text-center">
                                {this.state.downloadURL && this.props.uploadedFile ?
                                    <div>
                                        {this.props.uploadedFile.hasOwnProperty("contentType") &&
                                            this.props.uploadedFile.contentType === "application/pdf" ?
                                            <div>
                                                <iframe title="pdf" width={1024} height={840} src={this.state.downloadURL} allowFullScreen={true} />
                                            </div>
                                            : ''}
                                        {this.props.uploadedFile.hasOwnProperty("contentType") &&
                                            this.props.uploadedFile.contentType.includes("video") ?
                                            <div>
                                                <iframe title="video" width={1024} height={576} src={this.state.downloadURL} allowFullScreen={true} />
                                            </div>
                                            : ''}
                                        {this.props.uploadedFile.hasOwnProperty("contentType") &&
                                            this.props.uploadedFile.contentType.includes("image") ?
                                            <img alt={this.props.uploadedFile.fileName}
                                                src={this.state.downloadURL}
                                                className="max-w-100"
                                            />
                                            : ''}

                                        {/*try to let firestore render everything other file type*/}
                                        {!isDefaultVisibleFile &&
                                            this.props.uploadedFile.hasOwnProperty("downloadURL") &&
                                            this.props.uploadedFile.hasOwnProperty("contentType") ?
                                            <div>
                                                <iframe title="iframe" width={1024} height={840} src={this.state.downloadURL} allowFullScreen={true} />
                                            </div>
                                            : ''}
                                    </div>
                                    : ''}
                            </div>
                        </div>



                    </div>
                    : ''}

            </div >
        )
    }
}

export default TeacherStudentUploadFileView
