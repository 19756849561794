import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'

class TextToSpeech extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text ? this.props.text : '',
            volume: 1,
            isPaused: false,
        };

        this.msg = new SpeechSynthesisUtterance();
        this.msg.onpause = () => this.setState({ isPaused: true });
        this.msg.onresume = () => this.setState({ isPaused: false });
    }

    handlePlay = () => {
        if (this.state.isPaused) {
            window.speechSynthesis.resume();
            return;
        }

        window.speechSynthesis.cancel(); // Cancel any ongoing speech
        this.msg.text = this.state.text;
        this.msg.volume = this.state.volume;
        window.speechSynthesis.speak(this.msg);
    }

    handlePause = () => {
        window.speechSynthesis.pause();
    }

    handleVolumeChange = (e) => {
        const volume = e.target.value;
        this.setState({ volume }, () => {
            this.msg.volume = this.state.volume;
        });
    }

    render() {
        return (
            <div>
                <button onClick={this.handlePlay}>
                    {this.state.isPaused ? 'Resume' : 'Play'}
                </button>
                <button onClick={this.handlePause}>
                    Pause
                </button>
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={this.state.volume}
                    onChange={this.handleVolumeChange}
                />
                <span>Volume: {this.state.volume}</span>
            </div>
        );
    }
}

export default TextToSpeech;
