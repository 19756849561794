import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import { Layout, Row, Col, Tooltip } from 'antd'
const { Content } = Layout

class InstructionalFacilitatorObservationChecklist extends Component {
  state = {
    
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Observation Checklist - dot it'
  }

  render() {
    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content-extra-pt">
            <div className="w-1024 m-lr-auto">
                <div>
                  <div className="flex flex-v-center mb-2 font-30 font-bold">
                  Coaching Guides
                </div>
                <Row gutter={48} className="ml-0">
                  <Col span={8} className="pl-0">
                    <Tooltip title="Tier one coaching guide."
                      mouseEnterDelay={.5}
                    >
                    <a
                      href={"https://firebasestorage.googleapis.com/v0" +
                      "/b/mtss-c4f76.appspot.com/o/Tier%20One%20Coachi" +
                      "ng%20Guide.pdf?alt=media&token=34f6be6d-5b90-49" +
                      "ec-bb2e-2035f51192ba"}
                      target="_blank"
                      className={"w-100 br-4 h-150 up-hover" +
                       " shadow-hover flex flex-center mb-2 background-tier1-btn"}
                    >
                        <div className="font-24 w-100 font-fff flex flex-h-center">
                          <span>Tier One</span>
                        </div>
                    </a>
                    </Tooltip>
                  </Col>
                  <Col span={8} className="pl-0">
                  <Tooltip title="Tier two coaching guide."
                      mouseEnterDelay={.5}
                    >
                     <a
                      href={"https://firebasestorage.googleapis.com/v0" +
                      "/b/mtss-c4f76.appspot.com/o/Tier%202%20Coaching" +
                      "%20Guide.pdf?alt=media&token=262666ae-9795-49fb" +
                      "-8724-e223ef69d1bf"}
                      target="_blank"
                      className={"w-100 br-4 h-150 up-hover" +
                       " shadow-hover flex flex-center mb-2 background-tier2-btn"}
                    >
                        <div className="font-24 w-100 font-fff flex flex-h-center">
                          <span>Tier Two</span>
                        </div>
                    </a>
                    </Tooltip>
                  </Col>
                  <Col span={8} className="pl-0">
                  <Tooltip 
                      title="Tier three coaching guide."
                      mouseEnterDelay={.5}
                    >
                     <a
                      href={"https://firebasestorage.googleapis.com/v0" +
                      "/b/mtss-c4f76.appspot.com/o/Tier%203%20Coaching" +
                      "%20Guide.pdf?alt=media&token=039b2384-1762-4988" +
                      "-a257-a78c5d8dc203"}
                      target="_blank"
                      className={"w-100 br-4 h-150 up-hover" +
                       " shadow-hover flex flex-center mb-2 background-tier3-btn"}
                    >
                        <div className="font-24 w-100 font-fff flex flex-h-center">
                          <span>Tier Three</span>
                        </div>
                    </a>
                    </Tooltip>
                  </Col>
                </Row>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default InstructionalFacilitatorObservationChecklist