import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { awardsIncNotes, isReadOnlyTeacherId } from '../.././Util'
import { Button, Form, Input, message, Modal, Icon, notification, Tooltip } from 'antd'
const FormItem = Form.Item
const { TextArea } = Input

const errorMessage = (description) => {
    message.error(description)
}

// Using this to add students until the step form for adding students is done.
class AddNoteForm extends Component {
    state = {
        teacherId: '',
        teacher: [],
        student: null,
        submitting: false,
        visible: false,
    }

    componentDidMount() {
        if (this.props.note && this.props.note.message) {
            this.props.form.setFieldsValue({
                message: this.props.note.message,
            })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        console.log(e)
        this.setState({
            visible: false,
        })
    }


    // add a new event to the teacher's events
    handleSubmit = (e) => {
        if (!(this.props.note && this.props.collectionName)) {
            errorMessage("Something went wrong when editing the note.")
            return
        }

        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    console.log('Received values of form: ', values)

                    db.collection(this.props.collectionName)
                        .doc(this.props.note.id)
                        .update({
                            message: values.message,
                        })
                        .then(() => {
                            console.log('Document updated')
                            //successMessage('Note added successfully.')
                            notification.open({
                                type: 'success',
                                message: 'Note edited successfully',
                                placement: 'bottomRight'
                            })
                            this.setState({
                                submitting: false,
                                visible: false
                            })

                        })
                        .catch((error) => {
                            console.error('Error adding document: ', error)
                            this.setState({
                                submitting: false,
                                visible: false,
                            })
                            errorMessage("Something went wrong when editing the note.")
                        })
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <span>
                <Tooltip title="Edit note">
                    <Button shape="circle" icon="edit"
                        className='mr-1'
                        onClick={this.showModal}
                        size={'large'}
                    />
                </Tooltip>
                <Modal
                    title="Edit note"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        <FormItem {...formItemLayout} label="Lesson notes">
                            {getFieldDecorator('message', {
                                rules: [{ required: true, message: 'Required.' }],
                            })(
                                <TextArea
                                    placeholder="Lesson notes"
                                    autosize={{ minRows: 5 }}
                                />

                            )}
                        </FormItem>
                        <div className="flex flex-v-center">
                            <Button
                                size={'large'}
                                disabled={this.state.submitting}
                                onClick={this.hideModal}
                                className="ml-auto mr-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                            >
                                Edit note
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </span>

        )
    }
}

export default Form.create()(AddNoteForm)
