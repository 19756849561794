import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../../Types'
import { isGoalOnTrack, awardsIncMeasurements, getFormattedProgressMonitoringText } from '../../Util'
import InputNumberPickerAnt from '../../customcomponents/InputNumberPickerAnt'
import { Progress, Divider, notification, Button, Icon, DatePicker, Row, Col } from 'antd'
var moment = require('moment')

const successMessage = (description) => {
  notification.open({
    type: 'success',
    message: description,
    placement: 'bottomRight'
  })
}

const errorMessage = (description) => {
  notification.open({
    type: 'error',
    message: description,
    placement: 'bottomRight'
  })
}

class ProgressMonitoringTest extends Component {
  state = {
    iep: this.props.iep,
    progressMonitoringCopy: null,
    totalPoints: 0,
    timeStamp: null,
    submitting: false,
  }

  componentDidMount() {
    console.log('component mounted')
    this.setState({
      totalPoints: 0,
      progressMonitoringCopy: this.makeProgressMonitoringCopy(),
    })
  }

  makeProgressMonitoringCopy = () => {
    var copy = JSON.parse(JSON.stringify(this.state.iep.iep.progressMonitoring))
    // initialize scores to 0
    copy.map((item, index) => {
      item.max = item.num
      item.num = 0
      return item
    })

    return copy
  }

  getTotalPoints = (progressMonitoring) => {
    var total = 0
    progressMonitoring.map((item, index) => {
      return total += item.num
    })

    return total
  }

  progressMonitoringChange = (newValue, iep, index) => {
    var pm = this.state.progressMonitoringCopy
    pm[index].num = newValue

    this.setState({
      progressMonitoringCopy: pm,
      totalPoints: this.getTotalPoints(pm)
    })
  }

  addMeasurement = () => {
    if (!this.state.timeStamp) {
      errorMessage("Please select a date for this score.")
      return
    }
    var timeStampMoment = moment.utc(this.state.timeStamp)
    for (var i = 0; i < this.props.measurements.length; i++) {
      var measurement = this.props.measurements[i]
      var measurementMoment = moment.utc(measurement.timeStamp.seconds * 1000)
      if (measurementMoment.isSame(timeStampMoment)) {
        errorMessage("A measurement already exists for this date. Select another date for this measurement.")
        return
      }
    }
    this.setState({
      submitting: true,
    }, () => {
      console.log('add', this.state.iep)
      // stop potential divide by 0 (should be impossible, means the rubrik's total score is 0)
      var newMeasurement = this.props.iep.iep.totalPoints === 0 ? 0 :
        (this.state.totalPoints / this.props.iep.iep.totalPoints * 100).toFixed(0)
      var newMeasurements = []
      this.props.measurements.map((measurement, index) => {
        return newMeasurements.push(measurement)
      })
      // push an object, isGoalOnTrack needs an object in the measurements array.
      newMeasurements.push({ measurement: newMeasurement })
      var totalMeasurements = newMeasurements.length
      var onTrack = isGoalOnTrack(this.props.iep.iep, newMeasurements)
      var promises = []
      promises.push(db.collection(ColType.measurements)
        .add({
          iepId: this.props.iep.id,
          teacherId: this.props.teacher.id,
          studentId: this.props.student.id,
          schoolId: this.props.teacher.schoolId,
          districtId: this.props.teacher.districtId,
          timeStamp: this.state.timeStamp._d,
          measurement: newMeasurement,
          progressMonitoringResult: this.state.progressMonitoringCopy,
        })
      )
      promises.push(db.collection(ColType.iep)
        .doc(this.props.iep.id)
        .update({
          totalMeasurements: totalMeasurements,
          onTrack: onTrack,
          latestMeasurementTimeStamp: this.state.timeStamp._d,
        })
      )

      // score promises
      Promise.all(promises)
        .then(() => {
          successMessage("New score added successfully.")
          this.setState({
            submitting: false,
            totalPoints: 0,
            timeStamp: null,
            progressMonitoringCopy: this.makeProgressMonitoringCopy(),
          })

          // switch back to chart on success
          if (this.props.setChartKey) this.props.setChartKey()

          // inc awards last
          awardsIncMeasurements(this.props.teacher)

          if (this.props.onCompletion) {
            this.props.onCompletion()
          }
        })
        .catch((error) => {
          console.log(error)
          errorMessage("New score could not be added.")
          this.setState({ submitting: false })
          if (this.props.onCompletion) {
            this.props.onCompletion()
          }
        })
    })
  }

  onDateChange = (date, dateString) => {
    console.log(date, dateString)
    this.setState({
      timeStamp: moment.utc(dateString),
    })
  }

  render() {
    let progressMonitoringQuestions = getFormattedProgressMonitoringText(this.props.iep)

    return (
      <div>
        {this.props.iep && this.state.progressMonitoringCopy ?
          <div>
            {this.props.iep.iep.progressMonitoring.map((item, index) => {
              var question = "N/A"
              if (index <= progressMonitoringQuestions.length - 1) {
                question = progressMonitoringQuestions[index]
              }
              return <Row className="mb-1" gutter={0} type="flex" align="top" key={"progress-monitoring-rubrik" + index}>
                <Col span={1}>
                  <div className="font-18">
                    {(index + 1) + "."}
                  </div>
                </Col>
                <Col span={16}>
                  <div className="font-18 pl-1 pr-1">
                    {question}
                  </div>
                </Col>
                <Col span={5}>
                  <InputNumberPickerAnt
                    meta={{ index: index }}
                    size={'large'}
                    min={0}
                    max={item.num}
                    value={this.state.progressMonitoringCopy[index].num}
                    className={''}
                    onChangeSuccess={this.progressMonitoringChange}
                  />
                </Col>
                <Col span={2}>
                  <div className="font-18 pt-1">
                    <span>/</span>
                    <span>{" " + item.num}</span>
                  </div>
                </Col>
              </Row>
            })
            }
            <div className="mt-3 pt-2 mb-3 pb-2 border-top border-bottom font-18">
              <div>
                Total points
              </div>
              <Row gutter={0} type="flex" align="middle">
                <Col span={16}>
                  <span className="pr-1">
                    <Progress
                      className="progress-lg"
                      percent={(this.state.totalPoints /
                        this.props.iep.iep.totalPoints * 100).toFixed(0)}
                    />
                  </span>
                </Col>
                <Col span={1}>
                  <Divider type="vertical" />
                </Col>
                <Col span={5}>
                  <div>
                    <span className="pl-1">{this.state.totalPoints}</span>
                  </div>
                </Col>
                <Col span={2}>
                  <span>/ {this.props.iep.iep.totalPoints}</span>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <DatePicker
                size={'large'}
                value={this.state.timeStamp}
                onChange={this.onDateChange}
                format={"MM/DD/YYYY"}
              />
            </div>
            <div className="flex flex-v-center">
              <Button
                size={"large"}
                disabled={this.state.submitting}
                onClick={this.props.onCancel}
                className="mr-1 ml-auto"
              >
                Cancel
              </Button>
              <Button type="primary" size="large"
                disabled={this.state.submitting}
                onClick={this.addMeasurement}>
                {this.state.submitting ?
                  <span>
                    <Icon type="loading" className="mr-1 font-25" />Adding...
                  </span>
                  :
                  <span>
                    Add measurement
                  </span>
                }
              </Button>
            </div>
          </div>
          : ''}
      </div>
    )
  }
}

export default ProgressMonitoringTest