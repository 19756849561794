import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db, firebaseStorage } from '../../firebase/Firebase'
import { } from '../.././Util'
import ColType from '../../Types'
import { Upload, Icon, notification, Progress, Input } from 'antd'
const Dragger = Upload.Dragger
const uuid = require("uuid")

const successMessage = (description) => {
  notification.open({
    type: 'success',
    message: description,
    placement: 'bottomRight'
  })
}

const errorMessage = (description) => {
  notification.open({
    type: 'error',
    message: description,
    placement: 'bottomRight'
  })
}

// Using this to add students until the step form for adding students is done.
class StudentUpload extends Component {
  state = {
    uploadingFile: null,
    uploadedFileTitle: '',
    uploadedFileDescription: '',
  }

  componentDidMount() {

  }

  uploadFile = (file) => {
    console.log("uploading file", file.name)

    // if a file exists with this file name add a (1) to ...(99)
    // at the end of the file name. will overwrite a file with (99) already
    // but that shouldn't happen often.
    if (this.props.uploadedFilesFileNameDict.hasOwnProperty(file.name)) {
      var originalFileName = file.name
      // overwrite file name(99) if the same name exists for 0 to 99
      // should be fine
      for (var i = 1; i < 99; i++) {
        file.name = originalFileName + " (" + i + ")"
        if (!this.props.uploadedFilesFileNameDict.hasOwnProperty(file.name)) {
          break
        }
      }
    }

    this.setState({
      uploadingFile: file,
    })

    var districtId = this.props.teacher.districtId
    var schoolId = this.props.teacher.schoolId
    var teacherId = this.props.teacher.id
    var studentId = this.props.student.id
    var IEPId = this.props.IEPId
    var uuidValue = uuid.v4()
    var filePath = districtId + '/' + schoolId +
      '/' + teacherId + '/' + studentId +
      '/' + IEPId + '/' + uuidValue + '/' + file.name
    // '/' creates a new directory, so I a using '--' in the db and storing it
    var filePathNoSlashes = districtId + '--' + schoolId +
      '--' + teacherId + '--' + studentId +
      '--' + IEPId + '--' + uuidValue + '--' + file.name
    var metadataObj = {
      'ownerId': teacherId,
      'districtId': districtId,
      'schoolId': schoolId,
      'teacherId': teacherId,
      'studentId': studentId,
      'IEPId': IEPId,
      'fileName': file.name,
      'filePath': filePath,
      'filePathNoSlashes': filePathNoSlashes,
    }
    var metadata = {
      customMetadata: metadataObj
    }

    console.log('filepath', filePath)

    // Create the file using filePath in storage, so that the file will create
    // sub directories. In the db the path with no slashes will be used so that
    // sub direcotires are created.
    var fileRef = firebaseStorage.ref().child(filePath)
    fileRef.put(file.originFileObj, metadata).then((snapshot) => {
      // get download url from ref now that it has been uploaded
      fileRef.getDownloadURL()
        .then((downloadURL) => {
          // no longer storing the download url, it is retrieved through getDownloadURL
          // add the storage bucket (don't need it now but might need it later)
          metadataObj.bucket = snapshot.metadata.bucket
          // add the type of file to metadata
          metadataObj.contentType = snapshot.metadata.contentType
          console.log(this.state.uploadedFileTitle, this.state.uploadedFileDescription)
          if (this.state.uploadedFileTitle) {
            metadataObj.uploadedFileTitle = this.state.uploadedFileTitle
          } else {
            metadataObj.uploadedFileTitle = 'Untitled upload'
          }

          if (this.state.uploadedFileDescription) {
            metadataObj.uploadedFileDescription = this.state.uploadedFileDescription
          } else {
            metadataObj.uploadedFileDescription = 'No description'
          }

          // storage can't have a timestamp, so add it now
          metadataObj.timeStamp = firebase.firestore.FieldValue.serverTimestamp()

          if (this.props.hasOwnProperty('extraData') &&
            this.props.extraData) {
            Object.keys(this.props.extraData).forEach((key) => {
              metadataObj[key] = this.props.extraData[key]
            })
          }

          // add metadata obj to the db
          db.collection(ColType.uploadedfiles)
            .add(metadataObj)
            .then(() => {
              console.log("metadataObj successfully set in db.")
              successMessage(file.name + " was uploaded successfully.")

              if (this.props.hasOwnProperty("onSuccess") && this.props.onSuccess) {
                this.props.onSuccess()
              }

              this.setState({
                uploadingFile: null,
                uploadedFileTitle: '',
                uploadedFileDescription: '',
              })
            })
            .catch((e) => {
              console.log("error adding metadataObj in db", e)
              errorMessage(file.name + " could not be uploaded, " +
                "try again or contact support@dotit.app. There is a 30 MB upload limit.")

              this.setState({
                uploadingFile: null,
              })
            })
        })
        .catch((e) => {
          errorMessage(file.name + " could not find a download url, " +
            "try again or contact support@dotit.app. There is a 30 MB upload limit.")
          console.log("get download url failed")
          this.setState({
            uploadingFile: null,
          })
        })
    })
      .catch((e) => {
        console.log('file upload error', e)
        errorMessage(file.name + " could not be uploaded, " +
          "try again or contact support@dotit.app. There is a 30 MB upload limit.")

        this.setState({
          uploadingFile: null,
        })
      })
  }

  onChange = (info) => {
    const status = info.file.status
    if (status !== 'uploading') {
      console.log(info.file, info.fileList)
      this.uploadFile(info.file, this.props)
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.state.uploadingFile ?
            <div style={{ height: "430px" }} className="w-100 br-4 border">
              <div className="flex flex-v-center flex-h-center p-3 w-100 h-100 text-center">
                <div className="w-100">
                  <h2 className="ellipsis font-bold mb-0" title={this.state.uploadingFile.name}>
                    {this.state.uploadingFile.name}
                  </h2>
                  <Progress percent={100} status="active" showInfo={false} />
                  <h2 className="mt-2 mb-0">
                    Uploading...
                  </h2>
                </div>
              </div>
            </div>
            :
            <div>
              <div className='font-20 font-bold mb-1'>Title</div>
              <Input className="mb-2" placeholder="Title..." size="large" onChange={(e) => {
                this.setState({
                  uploadedFileTitle: e.target.value
                })
              }} />
              <div className='font-20 font-bold mb-1'>Description</div>
              <Input className="mb-4" placeholder="Description..." size="large" onChange={(e) => {
                this.setState({
                  uploadedFileDescription: e.target.value
                })
              }} />
              <Dragger
                name={'file'}
                disabled={this.state.uploadingFile}
                showUploadList={false}
                directory={false}
                multiple={false}
                onChange={this.onChange}
              >
                <div className="pt-150 pb-150">
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag and drop a file to this area to upload.</p>
                </div>
              </Dragger>
            </div>}
        </div>
      </div>
    )
  }
}

export default StudentUpload