import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
//import { firebase, db } from '../../firebase/Firebase'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
//import ColType from '../.././Types'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { getStrengths, flattenDoc, getIDFromURL, getQueryStringParam } from '../.././Util'
//import { PDFDocument, StandardFonts } from 'pdf-lib'
import download from 'downloadjs'
import { FontColor } from './FormUtils'
import { Layout, Button, Form, Input, notification } from 'antd'
import FormSteps from './FormSteps'
//import CustomFooter from '../../ login / CustomFooter'
const { Content } = Layout
const FormItem = Form.Item
const TextArea = Input.TextArea

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

/*
const testPrefillObj = {
    studentGoodAt: "Student is good at sports.",
    familyStudentGoodAt: "Student is good at sports.",
    teacherStudentGoodAt: "Student is good at sports.",
    studentInterest: "Student is interested in sports.",
    teamStudentInterest: "Student is interested in sports.",
    studentSupports: "Student needs support in sports.",
    studentCommunicates: "Student communicates well in sports.",
    familyStudentPriorities: "Student's family priorities are sports.",
    teacherStudentPriorities: "Student's teacher priorities are sports.",
    iepTeamInformation: "Student's IEP team information is sports.",
}
*/

class FormPartOne extends Component {
    state = {
        teacherId: null,
        studentId: null,
        teacher: null,
        selectedStudent: null,
        students: [],
        studentDict: {},
        iepDrafts: [],
        tabKey: '1',
        iepDraft: null,
        iepDraftMounted: false,
        selectedSubjects: [],
        studentStrengths: getStrengths(),
        selectedMenuKey: 'currentDraft',
        // prefill obj keys
        formKeys: [
            'studentGoodAt',
            'familyStudentGoodAt',
            'teacherStudentGoodAt',
            'studentInterest',
            'teamStudentInterest',
            'studentSupports',
            'studentCommunicates',
            'familyStudentPriorities',
            'teacherStudentPriorities',
            'iepTeamInformation',
        ],
    }

    componentDidMount() {
        //this.loadPDF(testPrefillObj)

        window.scrollTo(0, 0)

        var teacherId = getIDFromURL(window.location)
        let draftId = getQueryStringParam('draft')

        this.setState({
            teacherId: teacherId,
            draftId: draftId,
        })

        if (!draftId) {
            console.error('draftId is null')
            return
        }

        db.collection(ColType.californiaForm)
            .doc(draftId)
            .get()
            .then(doc => {
                if (doc.exists) {
                    let draft = flattenDoc(doc)
                    let formData = {}
                    for (let key of this.state.formKeys) {
                        if (draft.hasOwnProperty(key)) {
                            formData[key] = draft[key]
                        }
                    }
                    this.props.form.setFieldsValue(formData)
                    this.setState({ draft: draft })
                }
            })
            .catch(err => {
                console.log(err)
                errorMessage('Error loading draft')
            })
    }

    loadPDF = async (prefillObj) => {
        /*const existingPdfBytes = await fetch('/california/iep.pdf').then(res => res.arrayBuffer())
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // Embed the Helvetica font
        const drawFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const secondPage = pages[1]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()
        console.log(width, height)

        const fontSize = 12
        const firstPageFirstLineY = height - 74
        const lineHeightY = 14
        const secondPageFirstLineY = height - 74
        const x = 42

        firstPage.drawText(prefillObj.studentGoodAt, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 12) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.familyStudentGoodAt, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 15) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.teacherStudentGoodAt, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 18) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.studentInterest, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 25) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.teamStudentInterest, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 28) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.studentSupports, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 35) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.studentCommunicates, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 38) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        firstPage.drawText(prefillObj.familyStudentPriorities, {
            x: x,
            y: (firstPageFirstLineY - lineHeightY * 45) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        secondPage.drawText(prefillObj.teacherStudentPriorities, {
            x: x,
            y: (secondPageFirstLineY - lineHeightY * 1) - 8,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        secondPage.drawText(prefillObj.iepTeamInformation, {
            x: x,
            y: (secondPageFirstLineY - lineHeightY * 7) - 10,
            size: fontSize,
            font: drawFont,
            color: FontColor,
        })

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
        // Trigger the browser to download the PDF document
        download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf")
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)

                if (!values.schoolTheStudentAttendsIfDifferent) {
                    values.schoolTheStudentAttendsIfDifferent = ''
                }

                // this.loadPDF(values)

                // only advance step if this is the furthest step
                // completed in the draft
                let formStep = 2
                if (this.state.draft.hasOwnProperty('step') &&
                    this.state.draft.step < formStep) {
                    values.step = formStep
                }

                db.collection(ColType.californiaForm)
                    .doc(this.state.draftId)
                    .update(values)
                    .then(() => {
                        this.props.history.push(
                            {
                                pathname: '/teacher/california-form-step-two/' +
                                    this.state.teacherId + '?draft=' + this.state.draftId,
                            }
                        )
                    })
                    .catch((e) => {
                        console.log(e)
                        errorMessage('Something went wrong. Please try again.')
                    })
            }
        })*/
    }



    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="min-w-1120 m-lr-auto background-fff" style={{ maxWidth: '1200px' }}>
                            <div className='mt-4 flex'>

                                <div style={{ minWidth: '800px', maxWidth: '800px' }}>
                                    <div className="br-15">
                                        <div className='p-4 br-15' style={{ background: '#efdbff' }}>
                                            <div className='flex flex-v-center w-100'>
                                                <div className='mr-3'>
                                                    <h2 className="mb-05 font-20 font-bold">Step 2</h2>
                                                    <h2 className="mb-3 font-30">Student Strengths, Interests, Preferences, and Learning Needs</h2>
                                                </div>
                                                <img src='/california/2.png' alt='draft-iep' width={250} className='ml-auto br-15 ant-shadow' />
                                            </div>
                                        </div>

                                        <div className='p-4'>
                                            <div className='font-24 font-bold mb-2'>Annual Individualized Education Program (IEP)</div>
                                            <Form onSubmit={this.handleSubmit}
                                                className="login-form text-align-left">

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Student) I am particularly good at:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentGoodAt', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Family/Guardian) Our child/young adult's strengths are:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('familyStudentGoodAt', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Teachers and Service Providers) The student's strengths at school and with their peers are:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('teacherStudentGoodAt', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Student) I am interested in and like to:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentInterest', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Other Team Members) The student also has expressed interests in and preferences for:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('teamStudentInterest', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Student) I learn and experience school best in these ways and with these supports:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentSupports', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Student) I communicate in these ways:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentCommunicates', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Family/Guardian) Our concerns and priorities for our child/young adult's education this year are:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('familyStudentPriorities', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (Teachers and Service Providers) Our priorities for the student's learning and making progress in the general education curriculum this year are:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('teacherStudentPriorities', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        (IEP Team) This information from formal and informal evaluations, including progress toward previous goals, is most important to consider in developing this IEP and informing instructional strategies:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('iepTeamInformation', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem className="mb-0">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="lg-btn float-right"
                                                    >
                                                        Continue
                                                    </Button>
                                                </FormItem>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className='ml-4 w-100' style={{ minWidth: '368px' }}>
                                    <FormSteps
                                        teacherId={this.state.teacherId}
                                        draft={this.state.draft}
                                    />
                                </div>

                            </div>
                        </Content>
                    </Layout>
                </Layout >
            </div >
        )
    }
}

export default Form.create()(FormPartOne)