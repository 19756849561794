import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase } from '../firebase/Firebase'
import { getInitials } from '.././Util'
import { Layout, Icon, Menu, Avatar, Popover, message } from 'antd'
const { Header } = Layout

const errorMessage = (description) => {
  message.error(description)
}

class HomeHeader extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    searchValue: '',
    showScrollClass: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (e) => {
    if (window.scrollY > 0 && !this.state.showScrollClass) {
      this.setState({
        showScrollClass: true,
      })
    }
    else if (window.scrollY === 0 && this.state.showScrollClass) {
      this.setState({
        showScrollClass: false,
      })
    }
  }

  componentWillReceiveProps(newProps) {

  }

  newVisibleChange = (visible) => {
    
  }

  accountVisibleChange = (visible) => {
    
  }

  signOut = () => {
    firebase.auth().signOut().then(function () {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      window.location.href = "/sign-in"
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/teacher/student/' +
          this.props.person.id + '?student=' +
          option.key
      }
    )
  }

  render() {
    console.log(this.props.selectedKey)
    console.log(this.props.person)
    var personHomePath = '/'
    if (this.props.loginMounted && this.props.person && this.props.accessLevel) {
      if (this.props.accessLevel === 'teachers') {
        personHomePath = "/teacher/home/" + this.props.person.id
      }
      else if(this.props.accessLevel === 'schoolAdmins') {
        personHomePath = "/school-admin/school-admin-home/" + this.props.person.id
      }
      else if (this.props.accessLevel === 'instructionalFacilitators') {
        personHomePath = "/instructional-facilitator/instructional-facilitator-home/" + 
                         this.props.person.id
      }
      else if(this.props.accessLevel === 'admins') {
        personHomePath = "/admin/admin-home/" + this.props.person.id
      }
    }

    return (
      <div style={{zIndex: 100}}>
          <Header
            className={this.state.showScrollClass ? 'ant-shadow' : ''}
            style={{
              padding: 0,
              textAlign: 'left',
              position: 'fixed',
              width: '100%',
              background: '#fff',
              zIndex: 2,
            }}
          >
            <div
              //selectedKeys={[this.props.selectedKey]}
              //mode="horizontal"
              className="font-16 flex flex-row border-bottom-none p-lr-20-important"
              style={{ lineHeight: '72px', height: '72px' }}
            >
              <div key="teacher-home" 
              className="block-important menu-no-bb">
                <Link to={'/'}>
                  <img src='/dotit-iep-logo.png' alt='logo' height='48' />
                </Link>
              </div>

           
              {this.props.loginMounted ?
              !this.props.person ?
              <div
              className="inline-flex flex-v-center menu-no-bb ml-auto">
                 <Link
                  to={'/sign-in'}
                  className={"ant-btn btn-cl ant-btn-primary text-fff " +
                  "font-20 flex flex-v-center ant-shadow up-hover"}
                >
                  <span className="font-500 font-20 block font-bold">Sign in</span>
                </Link>
              </div>
              :
              <div
                className="inline-flex flex-v-center menu-no-bb cursor-pointer ml-auto">
                <Popover
                  placement="bottomLeft"
                  title={
                    <div className="pt-1 pb-1">
                      <Avatar
                        size="large"
                        className="inline-block mr-8"
                        style={{ backgroundColor: '#000' }}
                      >
                        {getInitials(this.props.person)}
                      </Avatar>
                      <span className="font-16 font-bold vertical-align-middle">
                        {this.props.person.firstName + " " + this.props.person.lastName}
                      </span>
                    </div>
                  } content={
                    <div className="p-0 border-right-none negate-popover-padding">
                      <div className="ant-menu-item p-0 border-bottom">
                        <Link
                          to={personHomePath}
                          className="font-16 p-2 block"
                        >
                          <Icon type="home"
                            className="mr-3 text-muted font-bold font-20 va-middle" />
                          <span className="va-minus-1 fw-500">My Home</span>
                        </Link>
                      </div>
                      <Menu.Divider />
                      <div className="ant-menu-item p-0" onClick={this.signOut}>
                        <span className="font-16 p-2 block">
                          <Icon type="logout"
                            className="mr-3 text-muted font-bold font-20 va-middle" />
                          <span className="va-minus-1 fw-500">Sign out</span>
                        </span>
                      </div>
                    </div>
                  } trigger="click">
                  <span className="h-67px inline-flex flex-v-center">
                    <Avatar
                      size="large"
                      style={{ backgroundColor: '#000' }}
                    >
                      {getInitials(this.props.person)}
                    </Avatar>
                  </span>
                </Popover>
              </div>
            : ''}
            </div>

          </Header>
      </div>
    )
  }
}

export default HomeHeader