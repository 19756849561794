import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import { firebase, db } from '../../firebase/Firebase'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
//import ColType from '../.././Types'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { getStrengths, getIDFromURL, flattenDoc } from '../.././Util'
import { Button, Layout, Icon, notification, Popconfirm, Spin, Tooltip } from 'antd'
import Empty from '../../customcomponents/Empty'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import { loadPDFFromDraft } from './FormUtils'
//import CustomFooter from '../../ login / CustomFooter'
const { Content } = Layout


const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class FormHome extends Component {
    state = {
        teacherId: null,
        studentId: null,
        teacher: null,
        selectedStudent: null,
        students: [],
        studentDict: {},
        iepDrafts: [],
        drafts: [],
        tabKey: '1',
        iepDraft: null,
        iepDraftMounted: false,
        selectedSubjects: [],
        studentStrengths: getStrengths(),
        selectedMenuKey: 'currentDraft',
        submitting: false,
        draftsLoaded: false,
    }

    componentDidMount() {
        document.title = 'California Form - Dot It'

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })

        db.collection(ColType.californiaForm)
            .where('teacherId', '==', teacherId)
            .where('step', '<', 100)
            .orderBy('step', 'desc')
            .orderBy('timeStamp', 'asc')
            .limit(30)
            .get()
            .then((querySnapshot) => {
                let drafts = []
                querySnapshot.forEach((doc) => {
                    var data = flattenDoc(doc)
                    drafts.push(data)
                })

                drafts.sort((a, b) => {
                    return b.step - a.step
                })

                this.setState({
                    drafts: drafts,
                    draftsLoaded: true,
                })
            })
    }

    onNewDraftClick = () => {
        this.setState({
            submitting: true,
        })
        db.collection(ColType.californiaForm)
            .add({
                teacherId: this.state.teacherId,
                schoolId: this.props.teacherBaseProps.teacher.schoolId,
                districtId: this.props.teacherBaseProps.teacher.districtId,
                step: 0,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((doc) => {
                let id = doc.id
                this.props.history.push(
                    {
                        pathname: '/teacher/california-form-student/' +
                            this.state.teacherId + '?draft=' + id,
                    }
                )
            })
            .catch((error) => {
                console.log(error)
                errorMessage('Error creating new draft, please try again.')
                this.setState({
                    submitting: false,
                })
            })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="min-w-1120 m-lr-auto background-fff" style={{ minWidth: '1200px', maxWidth: '1200px' }}>
                            <div className='mt-4'>
                                <div>
                                    <div className="br-15">
                                        <div className='p-4 bkg-purple br-15'>
                                            <div className='flex flex-v-center w-100'>
                                                <div>
                                                    <h1 className="font-bold mb-0 font-40 font-fff">California Statewide Individualized Education Program (IEP)</h1>
                                                    <h2 className="mb-3 font-24 font-fff">Student Information</h2>
                                                </div>
                                                <img src="/draft-icons/1.png" alt='draft-iep' width={250} className='ml-auto' />
                                            </div>
                                        </div>
                                        <div className='p-4'>
                                            <Button
                                                size="large"
                                                type="primary"
                                                className="lg-btn mb-3"
                                                onClick={this.onNewDraftClick}
                                                disabled={this.state.submitting}
                                            >
                                                <Icon type="plus" />
                                                <span>New</span>
                                            </Button>

                                            {!this.state.draftsLoaded ?
                                                <div>
                                                    <div className={"flex flex-h-center" +
                                                        " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                                        <div>
                                                            <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div>
                                                    {this.state.drafts.length === 0 ?
                                                        <Empty
                                                            containerClassName="mt-4 pt-2 text-center"
                                                            width={55}
                                                            height={55}
                                                            image={'/empty/students.png'}
                                                            description={'No form drafts. Start a draft by clicking the "New" button.'}
                                                        />
                                                        : ''}
                                                    {this.state.drafts.map((draft, idx) => {
                                                        let draftStepURL = '/teacher/california-form-student/'
                                                        if (draft.hasOwnProperty('step')) {
                                                            if (draft.step === 1) {
                                                                draftStepURL = '/teacher/california-form-step-one/'
                                                            } else if (draft.step === 2) {
                                                                draftStepURL = '/teacher/california-form-step-two/'
                                                            } else if (draft.step === 3) {
                                                                draftStepURL = '/teacher/california-form-step-two/'
                                                            }
                                                        }
                                                        return <div key={draft.id}
                                                            className='flex flex-v-center w-100 mb-3'>
                                                            <Link
                                                                to={draftStepURL + this.state.teacherId + '?draft=' + draft.id}
                                                                key={draft.id}
                                                                className='flex flex-v-center w-100'
                                                            >
                                                                <div
                                                                    className='p-3 br-20 w-100 mr-2 background-fff ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center'
                                                                >
                                                                    {draft.hasOwnProperty('studentFirstName') &&
                                                                        draft.studentFirstName &&
                                                                        draft.hasOwnProperty('studentLastName') &&
                                                                        draft.studentLastName ?
                                                                        <PersonAvatar
                                                                            person={{
                                                                                firstName: draft.studentFirstName,
                                                                                lastName: draft.studentLastName,
                                                                            }}
                                                                            size={'large'}
                                                                            containerClassName="font-20"
                                                                            avatarClassName="font-20 font-bold mr-2"
                                                                            personClassName="font-black font-24"
                                                                            avatarColor='rgb(211, 173, 247)'
                                                                        />
                                                                        :
                                                                        <div className='font-20 font-black'>
                                                                            No student has been entered.
                                                                        </div>}

                                                                    {draft.step === 3 ?
                                                                        <div className='pl-2 pr-2 pt-1 pb-1 ml-auto font-bold' style={{ color: '#52c41a' }}>
                                                                            Completed
                                                                        </div> :
                                                                        <div className='pl-2 pr-2 pt-1 pb-1 ml-auto font-bold' style={{ color: '#1890ff' }}>
                                                                            In progress
                                                                        </div>}
                                                                </div>
                                                            </Link>
                                                            <div>
                                                                <Tooltip title={draft.step === 3 ? 'Download PDF' : 'Draft is still in progress'}>
                                                                    <Button
                                                                        type="default"
                                                                        className="br-50 flex flex-v-center flex-h-center mr-2 ant-shadow"
                                                                        style={{
                                                                            width: '44px',
                                                                            height: '44px'
                                                                        }}
                                                                        onClick={() => {
                                                                            loadPDFFromDraft(draft)
                                                                        }}
                                                                        disabled={draft.step !== 3}
                                                                    >
                                                                        <div className='w-100 h-100 flex flex-v-center flex-h-center'>
                                                                            <Icon type="download" className="font-20" />
                                                                        </div>
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                            <div>
                                                                <Popconfirm
                                                                    title="Are you sure you want to delete this draft?"
                                                                    onConfirm={() => {
                                                                        db.collection(ColType.californiaForm)
                                                                            .doc(draft.id)
                                                                            .delete()
                                                                            .then(() => {
                                                                                let drafts = this.state.drafts
                                                                                drafts.splice(idx, 1)
                                                                                this.setState({
                                                                                    drafts: drafts,
                                                                                })

                                                                                successMessage('Draft deleted successfully.')
                                                                            })
                                                                            .catch((error) => {
                                                                                console.log(error)
                                                                                errorMessage('Error deleting draft, please try again.')
                                                                            })
                                                                    }}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        type="danger"
                                                                        className="br-50 flex flex-v-center flex-h-center font-20 ant-shadow"
                                                                        style={{
                                                                            padding: '16px',
                                                                            width: '44px',
                                                                            height: '44px'
                                                                        }}
                                                                    >
                                                                        <Icon type="delete" />
                                                                    </Button>
                                                                </Popconfirm>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default FormHome