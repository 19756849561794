import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import { firebase } from '../../firebase/Firebase'
import '../../styles/GlobalStyle.css'
import {
    getStudentMayHaveTroubleWith, getIDFromURL, cleverIsAuthed, cleverGetStudents
} from '../.././Util'
import { Layout } from 'antd'
import CustomFooter from '../../login/CustomFooter'
import TeacherAddStudentsClever from './TeacherAddStudentsClever'
//import AddStudentCSV from './AddStudentCSV'
const { Content } = Layout

class TeacherAddStudentsCleverHome extends Component {
    state = {
        teacherId: '',
        teacher: [],
        students: [],
        mayHaveTroubleWith: getStudentMayHaveTroubleWith(),
        redirectToStudentPage: false,
        submitting: false,
    }

    componentDidMount() {
        document.title = "Add Students from Clever - Dot It"

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    syncStudentsClever = () => {
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            let headers = new Headers()

            headers.append('Authorization', 'Bearer ' + idToken)
            headers.append('Origin', 'https://my.dotit.app')
            headers.append('Content-Type', 'application/json')
            headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

            fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/clever-teacher-sync-students", {
                method: "POST",
                body: JSON.stringify({

                }),
                mode: 'cors',
                headers: headers,
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                })
                .catch(err => {
                    console.log(err)
                })
        })
    }

    addStudentsClever = () => {
        cleverIsAuthed()
            .then((res) => {
                console.log(res)
                if (res.message === 'reauthenticating with clever') {
                    // not sure how to handle this yet
                } else if (res.message === 'valid') {
                    // is authed with clever, can make clever api calls
                    cleverGetStudents()
                        .then((res) => {
                            console.log(res)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="w-650 m-lr-auto br-15 background-fff">

                                <TeacherAddStudentsClever
                                    teacherBaseProps={this.props.teacherBaseProps}
                                    history={this.props.history}
                                    reauthContinueURL={typeof window !== 'undefined' ? window.location.href : ''}
                                />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default TeacherAddStudentsCleverHome