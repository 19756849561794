import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import ColType from '.././Types'
import { Icon, Form, Input, Button, Checkbox, message } from 'antd'
const FormItem = Form.Item


// This page can only be accessed by us, it is how you add admins that then can add other users.
class AdminLockPacingGuideForm extends Component {
    state = {
        districtInfo: [],
        district: null,
        schools: [],
        teachers: [],
        pacingGuideLocked: false,
    }

    componentDidMount() {

    }

    // adding directly here, in the future it will send an email.
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)

                db.collection(ColType.district)
                    .doc(values.districtId)
                    .get()
                    .then((doc) => {
                        if (!doc.exists) {
                            console.log('doc doesnt exist')
                            return
                        }
                        var district = flattenDoc(doc)

                        console.log(district)

                        this.setState({
                            district: district,
                            pacingGuideLocked: district.hasOwnProperty('pacingGuideLocked') ? district.pacingGuideLocked : false,
                        })
                    })
            }
        })
    }

    onPacingGuideLockCheckboxChange = (e, district) => {
        const { checked } = e.target

        if (!district) {
            console.log('no district, checkbox change')
            return
        }

        console.log('district', district)

        db.collection(ColType.district)
            .doc(district.id)
            .update({
                pacingGuideLocked: checked,
            })
            .then(() => {
                console.log('Document successfully updated!')
                message.success('Pacing guide has been' + (checked ? ' locked' : ' unlocked') + '.');

                this.setState({
                    pacingGuideLocked: checked,
                })
            })
            .catch((error) => {
                console.error('Error updating document: ', error)
                message.error('Error updating pacing guide locked status.')
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 25 },
                sm: { span: 9 },
            },
            wrapperCol: {
                xs: { span: 25 },
                sm: { span: 15 },
            },
        }

        return (
            <div>
                <SuperAdminHeader menuKey={'lock-pacing-guide'} />

                <div className="border br-4 p-4 w-600 m-lr-auto mt-100 mb-100">
                    <h1>Lock/Unlock the pacing guide. Locking the pacing guide means
                        drag and drop is disabled, so no edits can be made.
                    </h1>
                    <div className="font-24 font-bold mb-3">Search for a district</div>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem {...formItemLayout} label="District Clever Id">
                            {getFieldDecorator('districtId', {
                                rules: [{ required: true, message: 'required.' }],
                            })(
                                <Input
                                    size={"large"}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="District Clever Id..." />
                            )}
                        </FormItem>
                        <FormItem className="mb-0">
                            <Button
                                size={"large"}
                                type="primary"
                                htmlType="submit"
                                className="login-form-button text-align-center w-100"
                            >
                                Search for a district
                            </Button>
                        </FormItem>
                    </Form>

                    {this.state.district ?
                        <div className="mt-3 pt-3 border-top">
                            <div className='font-30 mb-1'>{this.state.district.district}</div>
                            <div className='font-20'>Pacing Guide Locked: {this.state.pacingGuideLocked ? 'Yes' : 'No'}</div>
                            <Checkbox size={'large'}
                                checked={this.state.pacingGuideLocked}
                                onChange={(e) => {
                                    this.onPacingGuideLockCheckboxChange(e, this.state.district)
                                }}
                            >
                                <span className="pl-1">Lock Pacing Guide</span>
                            </Checkbox>
                        </div>
                        :
                        <div>No district found</div>
                    }
                </div>
            </div>
        )
    }
}

export default Form.create()(AdminLockPacingGuideForm)