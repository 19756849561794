import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { Popover } from 'antd'
import { capitalizeFirstChar, keywords } from '../../Util'


class Keywords extends Component {
    state = {
        keywords: keywords,
    }

    componentDidMount() {

    }

    atKeyword = (split, idx) => {
        let totalKeywordString = ''
        let totalKeywordsNoReplace = ''

        for (let i = idx; i < split.length; i++) {
            // replace commas so that the lookup in the keywords object works
            totalKeywordString += split[i].replaceAll(",", "").replaceAll(".", "").trim().toLowerCase()
            totalKeywordsNoReplace += split[i].trim()

            if (this.state.keywords.hasOwnProperty(totalKeywordString)) {
                // return back the keyword string without any commas replaced
                return {
                    success: true,
                    endIdx: i,
                    keyword: totalKeywordString,
                    totalKeywordString: totalKeywordsNoReplace,
                    definition: this.state.keywords[totalKeywordString],
                }
            }

            if (i !== split.length - 1) {
                totalKeywordString += ' '
                totalKeywordsNoReplace += ' '
            }
        }

        // no keywords found, return !success
        return { success: false, endIdx: -1, keyword: '', totalKeywordString: '', definition: '' }
    }

    pushAndClearCurrentNonKeywordWords = (words, currentNonKeywordWords) => {
        if (currentNonKeywordWords.length > 0) {
            words.push({ isKeyword: false, value: currentNonKeywordWords, definition: '' })
            currentNonKeywordWords = ''
        }

        return ''
    }

    splitStringIntoKeywords = (string) => {
        let split = string.split(" ")
        let words = []
        let currentNonKeywordWords = ''

        for (let i = 0; i < split.length; i++) {
            let word = split[i]

            let atKeyword = this.atKeyword(split, i)
            if (atKeyword.success) {
                currentNonKeywordWords = this.pushAndClearCurrentNonKeywordWords(words, currentNonKeywordWords)
                words.push({
                    isKeyword: true,
                    keyword: atKeyword.keyword,
                    value: atKeyword.totalKeywordString,
                    definition: atKeyword.definition
                })
                i = atKeyword.endIdx
            } else {
                currentNonKeywordWords += word
                if (i !== split.length - 1) {
                    currentNonKeywordWords += ' '
                }
            }
        }

        // if there are any remaining words in the currentNonKeywordWords, push them to the words array
        currentNonKeywordWords = this.pushAndClearCurrentNonKeywordWords(words, currentNonKeywordWords)

        return words
    }

    render() {
        let keywordSplit = []
        if (this.props.paragraph) {
            keywordSplit = this.splitStringIntoKeywords(this.props.paragraph)
        }

        return (
            <div>
                {keywordSplit.map((word, index) => {
                    // if the keyword ends in a comma, or period, display the keyword
                    // without the final punctuation as the highlighted keyword and add
                    // the final punctuation after it
                    let keyword = word.value
                    let finalPunctuation = ''
                    let isFinalIndex = index === keywordSplit.length - 1
                    if (word.isKeyword &&
                        keyword.length > 0 && (
                            keyword[keyword.length - 1] === ',' ||
                            keyword[keyword.length - 1] === '.' ||
                            keyword[keyword.length - 1] === '?' ||
                            keyword[keyword.length - 1] === '!'
                        )) {
                        finalPunctuation = keyword[keyword.length - 1]
                        keyword = keyword.substring(0, keyword.length - 1)
                    }
                    if (word.isKeyword) {
                        return (
                            <span key={'keyword-' + index}>
                                <Popover
                                    key={index}
                                    placement="top"
                                    overlayClassName={'notebook-popover'}
                                    title={null}
                                    autoAdjustOverflow={true}
                                    mouseEnterDelay={0.42}
                                    content={<div style={{ width: '350px', height: '350px', maxWidth: '350px', maxHeight: '350px' }} className="font-20 font-black w-100 transparent relative">
                                        <img src="/notebook2.png" alt="book-background" style={{ filter: 'drop-shadow(rgba(44, 44, 44) 2px 4px 4px)' }} className='absolute-tl w-100 h-100 z-index-minus-1' />
                                        <div style={{ maxHeight: '350px', padding: '32px', paddingLeft: '58px', paddingRight: '24px' }} className="w-100 h-100">
                                            <div style={{ maxHeight: 'calc(350px - 67px)' }} className="overflow-y-auto overflow-hidden pr-1">
                                                <div className="font-bold mb-1">{capitalizeFirstChar(word.keyword)}</div>
                                                <div>{word.definition}</div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                >
                                    <span className="text-darker-blue text-blue-hover blue-underline-hover cursor-pointer">
                                        {keyword}
                                    </span>
                                </Popover>
                                <span>
                                    {finalPunctuation + (!isFinalIndex ? ' ' : '')}
                                </span>
                            </span>
                        )
                    } else {
                        return (
                            <span key={'not-a-keyword-' + index}>
                                {word.value + (!isFinalIndex ? ' ' : '')}
                            </span>
                        )
                    }
                })}
            </div>
        )
    }
}

export default Keywords
