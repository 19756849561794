import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { getQueryStringParam } from '.././Util'
import { Layout } from 'antd'
//import CustomFooter from '../login/CustomFooter'
import DocumentDelagator from './../edviz/EdvizDelegator'


class RenderTestComponent extends Component {
    state = {
        document: null,
        loaded: false,
    }

    componentDidMount() {
        const documentStr = getQueryStringParam('component')
        if (documentStr) {
            this.setState({
                document: JSON.parse(decodeURIComponent(documentStr)),
                loaded: true,
            })
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Layout>
                        <Layout className="background-fff">
                            <div className='flex'>
                                <div style={{ minWidth: '680px' }}
                                    className='w-680 m-lr-auto font-black font-20 border ant-shadow br-4 p-3 mb-50 print-remove-padding print-remove-border print-remove-shadow'>
                                    <div id={this.state.loaded ? 'componentLoaded' : ''}>
                                        {this.state.document ?
                                            <DocumentDelagator
                                                document={this.state.document}
                                                additionalData={{
                                                    hideAllReasons: true,
                                                }}
                                            /> : ''}
                                    </div>
                                </div>
                            </div>
                        </Layout>
                    </Layout>
                </div>
            </div>
        )
    }
}

export default RenderTestComponent
