import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
//import { db } from '../../firebase/Firebase'
//import PersonHeader from '../../login/PersonHeader'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import CustomFooter from '../../login/CustomFooter'
import ReactToPrint from "react-to-print"
import { getIDFromURL, getQueryStringParam, getFormattedProgressMonitoringText } from '../../Util'
import { Layout, Icon, Button, Row, Col, Tooltip } from 'antd'
const { Content } = Layout

class TeacherGroupLessonPlan extends Component {
  state = {
    teacherId: null,
    eventId: null,
    componentMounted: false,
  }

  // Do fetch here
  componentDidMount() {
    document.title = 'Group Goal Guide - Dot It'

    var teacherId = getIDFromURL(window.location)
    var eventId = getQueryStringParam('calendarevent')

    this.setState({
      teacherId: teacherId,
      eventId: eventId,
      componentMounted: true,
    })
  }

  render() {
    return (
      <div className="background-fff-important">
        <Layout className="content layout-header-mt">
          {this.state.componentMounted &&
            this.state.eventId &&
            this.props.teacherBaseProps &&
            this.props.teacherBaseProps.IEPDict &&
            this.props.teacherBaseProps.studentDict ?
            <Layout className="background-fff-important">
              <div className="m-lr-auto mt-4 pl-4 pr-4">
                <div className="mb-2">
                  <div className="float-right mr-4 pr-2">
                    <ReactToPrint
                      trigger={() =>
                        <Tooltip title={'Print this guide. Select a landscape layout when the print dialog box appears.'}>
                          <Button
                            size={'large'}
                            className="btn-vl inline-block mr-2 up-hover"
                          >
                            <Icon type="printer" />
                            Print
                          </Button>
                        </Tooltip>
                      }
                      content={() => this.componentRef}
                    />
                  </div>
                </div>
                {/** offset the margin of the printable component so that it lines up correctly */}
                <Content style={{ marginLeft: -32, marginRight: -32 }}>
                  {/** printable component, gave it layout-content and offsetted it above */}
                  <div className="ml-4 mr-4 mt-4 mb-4" ref={el => (this.componentRef = el)}>
                    {this.props.teacherBaseProps.events &&
                      this.props.teacherBaseProps.events.map((event, index) => {
                        if (event.id !== this.state.eventId) return false
                        return <div>
                          <h1 className="border-bottom pb-3 mb-3 font-bold">
                            Group Goal Guide
                          </h1>
                          <Row gutter={24} className="mb-1">
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <h3 className="font-bold">Accommodations</h3>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <h3 className="font-bold">Presentation Strategy</h3>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <h3 className="font-bold">Engagement Strategy</h3>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <h3 className="font-bold">Expression Strategy</h3>
                            </Col>
                          </Row>
                          <div>
                            {event.ieps.map((iep, index) => {
                              if (!this.props.teacherBaseProps.IEPDict) return false
                              if (!this.props.teacherBaseProps.studentDict) return false
                              if (!(this.props.teacherBaseProps.IEPDict.hasOwnProperty(iep.iepId)))
                                return false
                              var studentId = this.props.teacherBaseProps.IEPDict[iep.iepId].studentId
                              if (!(this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)))
                                return false

                              var student = this.props.teacherBaseProps.studentDict[studentId]
                              var IEPGoal = this.props.teacherBaseProps.IEPDict[iep.iepId]
                              return <div key={'event-' + event.id + '-' + index}
                                className="mb-2 pb-2">
                                <Row gutter={24} type="flex" align="middle">
                                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    <PersonAvatar
                                      person={student}
                                      size={'large'}
                                      containerClassName="font-18"
                                      avatarClassName="font-18 font-bold mr-1"
                                      personClassName="font-black"
                                    />
                                  </Col>
                                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    {IEPGoal &&
                                      IEPGoal.hasOwnProperty("accommodations") &&
                                      IEPGoal.accommodations.hasOwnProperty('instructionBarrier') ?
                                      <h3 className="inline-block">
                                        {IEPGoal.accommodations.instructionBarrier}
                                      </h3>
                                      :
                                      student &&
                                        student.hasOwnProperty("accommodations") &&
                                        student.accommodations.hasOwnProperty('instructionBarrier') ?
                                        <h3 className="inline-block">
                                          {student.accommodations.instructionBarrier}.
                                        </h3>
                                        : ''}
                                  </Col>

                                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    {IEPGoal &&
                                      IEPGoal.hasOwnProperty("accommodations") &&
                                      IEPGoal.accommodations.hasOwnProperty('engagementBarrier') ?
                                      <h3 className="inline-block">
                                        {IEPGoal.accommodations.engagementBarrier}.
                                      </h3>
                                      :
                                      student &&
                                        student.hasOwnProperty("accommodations") &&
                                        student.accommodations.hasOwnProperty('engagementBarrier') ?
                                        <h3 className="inline-block">
                                          {student.accommodations.engagementBarrier}.
                                        </h3>
                                        : ''}
                                  </Col>

                                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    {IEPGoal &&
                                      IEPGoal.hasOwnProperty("accommodations") &&
                                      IEPGoal.accommodations.hasOwnProperty('organizationBarrier') ?
                                      <h3 className="inline-block">
                                        {IEPGoal.accommodations.organizationBarrier}.
                                      </h3>
                                      :
                                      student &&
                                        student.hasOwnProperty("accommodations") &&
                                        student.accommodations.hasOwnProperty('organizationBarrier') ?
                                        <h3 className="inline-block">
                                          {student.accommodations.organizationBarrier}.
                                        </h3>
                                        : ''}
                                  </Col>
                                </Row>
                              </div>
                            })
                            }
                          </div>
                        </div>
                      })
                    }

                    {this.props.teacherBaseProps.events &&
                      this.props.teacherBaseProps.events.map((event, index) => {
                        if (event.id !== this.state.eventId) return false
                        return <div>
                          <Row gutter={24} className="mb-1 pt-4 border-top">
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <h3 className="font-bold">Condition / Target Behavior</h3>
                            </Col>
                            <Col xs={24} sm={24} md={4} lg={4} xl={4}><h3 className="font-bold">Performance <div className="inline-block">Criterion 1</div></h3></Col>
                            <Col xs={24} sm={24} md={4} lg={5} xl={5}><h3 className="font-bold">Performance <div className="inline-block">Criterion 2</div></h3></Col>
                            <Col xs={24} sm={24} md={5} lg={4} xl={4}><h3 className="font-bold">Performance <div className="inline-block">Criterion 3</div></h3></Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}><h3 className="font-bold">Performance <div className="inline-block">Criterion 4</div></h3></Col>
                          </Row>
                          <div>
                            {event.ieps.map((iep, index) => {
                              if (!this.props.teacherBaseProps.IEPDict) return false
                              if (!this.props.teacherBaseProps.studentDict) return false
                              if (!(this.props.teacherBaseProps.IEPDict.hasOwnProperty(iep.iepId)))
                                return false
                              var studentId = this.props.teacherBaseProps.IEPDict[iep.iepId].studentId
                              if (!(this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)))
                                return false

                              var student = this.props.teacherBaseProps.studentDict[studentId]
                              var IEPGoal = this.props.teacherBaseProps.IEPDict[iep.iepId]
                              let progressMonitoringQuestions = getFormattedProgressMonitoringText(IEPGoal)
                              return <div key={'event-progress-monitoring-' + event.id + '-' + index}
                                className="mb-2 pb-2">
                                {progressMonitoringQuestions.length === 4 ?
                                  <Row gutter={24}>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                      <h3>
                                        {
                                          'By ' + (IEPGoal.iep.completionDate === '<date>,' ? '<date>' : IEPGoal.iep.completionDate) + ', given ' +

                                          IEPGoal.iep["given"][0] + " " + IEPGoal.iep["given"][1] + " " +


                                          student.firstName + ' will ' + IEPGoal.iep["given"][2] + ' by'
                                        }
                                      </h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                      <h3>
                                        {progressMonitoringQuestions[0]}
                                      </h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                      <h3>
                                        {progressMonitoringQuestions[1]}
                                      </h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                      <h3>
                                        {progressMonitoringQuestions[2]}
                                      </h3>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                      <h3>
                                        {progressMonitoringQuestions[3]}
                                      </h3>
                                    </Col>
                                  </Row>
                                  : ''}
                              </div>
                            })
                            }
                          </div>
                        </div>
                      })
                    }

                  </div>
                </Content>
              </div>
            </Layout>
            : ''}
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default TeacherGroupLessonPlan
