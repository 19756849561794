import React from 'react'

function ClozePassage(props) {
    const { title, direction, passage } = props

    // Replace "<blank>" in the passage with a stylized span for display
    const displayPassage = passage.split('<blank>').map((part, index) => {
        return (
            <span key={index}>
                {part}
                {index < passage.split('<blank>').length - 1 && (
                    <span style={{ borderBottom: '1px solid', width: '50px', display: 'inline-block' }} />
                )}
            </span>
        )
    })

    return (
        <div>
            <div className='font-24 font-bold mb-2'>{title}</div>
            <div className='font-20'>{'Directions: ' + direction}</div>
            <div className='font-20'>{displayPassage}</div>
        </div>
    )
}

export default ClozePassage
