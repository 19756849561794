import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { getIDFromURL } from '../.././Util'
import { Layout } from 'antd'
import CustomFooter from '../../login/CustomFooter'
const { Content } = Layout


// Using this to add students until the step form for adding students is done.
class ContactUs extends Component {
  state = {
    submitting: false,
    submitted: false,
  }

  componentDidMount() {
    document.title = "Contact us - Dot It"
    var teacherId = getIDFromURL(window.location)

    const script = document.createElement("script")

    script.src = "https://dotitteam.activehosted.com/f/embed.php?id=2"
    script.async = true

    document.body.appendChild(script)

    this.setState({
      teacherId: teacherId,
    })
  }

  render() {
    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content">

              <div className="w-527 m-lr-auto br-4 p-2 pt-3 pb-1 ant-shadow">
                <div className="block w-100 m-lr-auto text-center">
                  <img src="/contact-us-icon.png" alt="sign-in" height="300" />
                </div>
                {/*<iframe
                  src="https://dotitteam.activehosted.com/f/2"
                  className="w-100"
                  style={{ height: '500px' }}
                  frameBorder="0"
                />*/}
                <div className="_form_2"></div>
              </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div >
    )
  }
}

export default ContactUs
