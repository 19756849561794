import React, { Component } from 'react'
//import { Route } from 'react-router-dom'
//import StudentReport from './reports/StudentReport'
import '../styles/GlobalStyle.css'
import PacingGuideAssessment from '../teacher/pacingguide/PacingGuideAssessment'
//import { firebase, db } from '../firebase/Firebase'
import { getQueryStringParam } from '../Util'
//import ColType from '../Types'
import { Layout } from 'antd'
import CustomFooter from '../login/CustomFooter'
const Content = Layout.Content
const moment = require('moment')

class StudentAssessment extends Component {
    state = {
        assessmentId: '',
    }

    componentDidMount() {
        document.title = 'My Quiz - Dot It'
        let assessmentId = getQueryStringParam('quiz')
        this.setState({
            assessmentId: assessmentId
        })
    }

    render() {
        // make sure serverMoment has loaded
        const inTestingTimeRange = this.props.studentBaseProps &&
            this.props.studentBaseProps.inTestingTimeRange &&
            this.props.studentBaseProps.serverMoment
        let isAssessment = this.props.studentBaseProps &&
            this.props.studentBaseProps.assessmentsDict &&
            this.props.studentBaseProps.assessmentsDict.hasOwnProperty(this.state.assessmentId) &&
            this.props.studentBaseProps.assessmentsDict[this.state.assessmentId]
        let isCompletedAssessment = this.props.studentBaseProps &&
            this.props.studentBaseProps.completedAssessmentsDict &&
            this.props.studentBaseProps.completedAssessmentsDict.hasOwnProperty(this.state.assessmentId) &&
            this.props.studentBaseProps.completedAssessmentsDict[this.state.assessmentId]

        let assessment = null
        if (isAssessment) {
            assessment = this.props.studentBaseProps.assessmentsDict[this.state.assessmentId]
        } else if (isCompletedAssessment) {
            assessment = this.props.studentBaseProps.completedAssessmentsDict[this.state.assessmentId]
        }

        let testingTimeIsOver = false
        let testingIsOpen = true
        let canViewResults = false
        let assessmentCategory = ''
        // default to close testing if a week has passed since the assessment was created
        if (assessment &&
            assessment.hasOwnProperty('timeStamp') &&
            assessment.timeStamp &&
            assessment.timeStamp.hasOwnProperty('seconds')) {
            let assessmentMoment = moment.utc(assessment.timeStamp.seconds * 1000)
            let assessmentMomentEnd = assessmentMoment.clone().add(1, 'week')
            if (this.props.studentBaseProps && this.props.studentBaseProps.serverMoment) {
                if (this.props.studentBaseProps.serverMoment.isAfter(assessmentMomentEnd)) {
                    testingTimeIsOver = true
                }
            }
        }
        // but an existing isOpen property overrides that default
        if (assessment && assessment.hasOwnProperty('isOpen')) {
            testingIsOpen = assessment.isOpen
        }

        if (assessment) {
            assessmentCategory = assessment.quiz.assessmentCategory
            if (assessment.hasOwnProperty('quiz') &&
                assessment.quiz &&
                assessment.quiz.hasOwnProperty('assessmentTitle') &&
                assessment.quiz.assessmentTitle) {
                if (assessment.quiz.assessmentTitle.toLowerCase().includes('common assessment')) {
                    assessmentCategory = 'common_assessment'
                } else if (assessment.quiz.assessmentTitle.toLowerCase().includes('benchmark')) {
                    assessmentCategory = 'benchmark'
                } else if (assessment.quiz.assessmentTitle.toLowerCase().includes('quiz')) {
                    assessmentCategory = 'quiz'
                }
            }
            if (assessmentCategory === 'quiz') {
                canViewResults = true
            } else if (assessmentCategory === 'common_assessment') {
                // can view after 3 days
                if (assessment.hasOwnProperty('timeStamp') &&
                    assessment.timeStamp &&
                    assessment.timeStamp.hasOwnProperty('seconds') &&
                    this.props.studentBaseProps &&
                    this.props.studentBaseProps.serverMoment) {
                    let assessmentMoment = moment.utc(assessment.timeStamp.seconds * 1000)
                    let assessmentMomentEnd = assessmentMoment.clone().add(3, 'days')
                    if (this.props.studentBaseProps.serverMoment.isAfter(assessmentMomentEnd)) {
                        canViewResults = true
                    }
                }
            } else if (assessmentCategory === 'benchmark') {
                // cannot view results for benchmarks
            }
        }

        let containerClassName = ' question-container'
        if (isAssessment && (!testingIsOpen || !inTestingTimeRange)) {
            containerClassName = ' sub-menu-width pl-2 pr-2'
        } else if (isCompletedAssessment && !canViewResults) {
            containerClassName = ' w-750'
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout className="mt-0">
                        <Content className="mt-0">
                            <div className={"m-lr-auto mt-4 mb-100" + containerClassName}>
                                {!inTestingTimeRange && isAssessment ?
                                    <div className='p-4 br-4 text-center' style={{ background: '#e6f4ff' }}>
                                        <img src='/no-assessments.png' alt='no assessments' width={250} />
                                        <div className='font-24'>The testing window is Monday-Friday from 7:30am to 4:00pm.<br />
                                            You can continue testing then!</div>
                                    </div>
                                    : ''}

                                {isAssessment ?
                                    !testingIsOpen ?
                                        <div className='p-4 br-4 text-center' style={{ background: '#e6f4ff' }}>
                                            <img src='/no-assessments.png' alt='no assessments' width={250} />
                                            <div className='font-30'>Testing is currently closed.</div>
                                        </div>
                                        :
                                        inTestingTimeRange ?
                                            <PacingGuideAssessment
                                                quiz={this.props.studentBaseProps.assessmentsDict[this.state.assessmentId]}
                                            />
                                            : ''
                                    : ''}

                                {isCompletedAssessment ?
                                    <PacingGuideAssessment
                                        quiz={this.props.studentBaseProps.completedAssessmentsDict[this.state.assessmentId]}
                                        canViewResults={canViewResults}
                                    />
                                    : ''}

                                {!isAssessment && !isCompletedAssessment ?
                                    <div className='p-4 br-4 text-center' style={{ background: '#e6f4ff' }}>
                                        <img src='/no-assessments.png' alt='no assessments' width={250} />
                                        <div className='font-30'>This assessment was not found.</div>
                                    </div>
                                    : ''}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default StudentAssessment