import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Layout, message } from 'antd'
import AdminInstructionalFacilitatorSchoolsModal from './AdminInstructionalFacilitatorSchoolsModal'
const { Content } = Layout

const errorMessage = (description) => {
    message.error(description)
}

class AdminInstructionalFacilitatorsSchoolsChange extends Component {
    state = {
        newPopoverVisible: false,
        accountPopoverVisible: false,
        adminId: '',
        selectedId: null,
        teacherId: '',
        studentId: '',
        schoolId: '',
        teacher: null,
        student: null,
        teachers: null,
        students: [],
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
        calendarEvents: null,
        calendarDict: null,
        events: null,
        studentReportData: {},
        instructionalFacilitators: [],
        teacherTab: 'schedule',
        loadingData: false,
        loadedData: false,
        readOnly: true,
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Dashboard - Dot It'

        let adminId = getIDFromURL(window.location)
        let schoolId = getQueryStringParam('s')

        this.setState({
            adminId: adminId,
            schoolId: schoolId,
        })

        db.collection(ColType.admin)
            .doc(adminId)
            .get()
            .then(doc => {
                let admin = flattenDoc(doc)
                this.setState({
                    admin: admin,
                })

                db.collection(ColType.school)
                    .where('districtId', '==', admin.districtId)
                    .get()
                    .then(querySnapshot => {
                        let schools = []
                        querySnapshot.forEach(doc => {
                            let school = flattenDoc(doc)
                            schools.push(school)
                        })

                        this.setState({
                            schools: schools,
                        })


                        db.collection(ColType.instructionalFacilitator)
                            .where('districtId', '==', admin.districtId)
                            .get()
                            .then((querySnapshot) => {
                                let instructionalFacilitators = []
                                querySnapshot.forEach((doc) => {
                                    instructionalFacilitators.push(flattenDoc(doc))
                                })
                                this.setState({ instructionalFacilitators: instructionalFacilitators })
                            })
                            .catch((error) => {
                                console.log('error', error)
                                errorMessage('Error getting instructional facilitators')
                            })
                    })
            })
    }

    render() {
        return (
            <div>
                <div>
                    <Layout className="content relative">
                        <Layout >
                            <Content className="w-700 m-lr-auto">
                                <div className="font-20 font-bold mb-2 mt-2">
                                    Instructional Facilitators
                                </div>
                                <div className='font-20 font-black'>
                                    {this.state.instructionalFacilitators.map((instructionalFacilitator, index) => {
                                        return <div key={instructionalFacilitator.id} className="mb-1">
                                            <span className='pr-1'>
                                                {instructionalFacilitator.firstName + ' ' + instructionalFacilitator.lastName}
                                            </span>
                                            <AdminInstructionalFacilitatorSchoolsModal
                                                adminBaseProps={this.props.adminBaseProps}
                                                instructionalFacilitator={instructionalFacilitator}
                                            />
                                        </div>
                                    })}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <CustomFooter />
            </div>
        )
    }
}

export default AdminInstructionalFacilitatorsSchoolsChange