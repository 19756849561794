import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import IEPDrafts from './IEPDrafts'
//import ColType from '../.././Types'
import { getStrengths, getIDFromURL, getQueryStringParam, flattenDoc } from '../.././Util'
import { loadPDFFromDraft } from './FormUtils'
import { Layout, Button, Form, Input, DatePicker, Radio, Row, Col, notification } from 'antd'
//import CustomFooter from '../../ login / CustomFooter'
import moment from 'moment'
import FormSteps from './FormSteps'
const { Content } = Layout
const FormItem = Form.Item
const TextArea = Input.TextArea

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

/*
const testPrefillObj = {
    projectedGraduationDate: "5/25/2024",
    goalNextWeek: "Goal for next week",
    goalNextYear: "Goal for next year",
    goalNextThreeYears: "Goal for next three years",
    goalNextFiveToTenYears: "Goal for next five to ten years",
    goalAfterHighSchool: "Goal after high school",
    courseOfStudy: "Course of study",
    isAlternateRoute: true,
    alternateRouteJustification: "Alternate route justification",
    totalCreditsRequiredForGraduation: 100,
    creditsEarnedAlready: 50,
    remainingCreditsForGraduation: 50,
    importantTransitions: "None",
    otherSpecify: "Other specify",
    studentPreparedDescription: "Student prepared description",
}
*/

class FormPartTwo extends Component {
    state = {
        teacherId: null,
        studentId: null,
        teacher: null,
        selectedStudent: null,
        students: [],
        studentDict: {},
        iepDrafts: [],
        tabKey: '1',
        iepDraft: null,
        iepDraftMounted: false,
        selectedSubjects: [],
        studentStrengths: getStrengths(),
        selectedMenuKey: 'currentDraft',
        importantTransitions: '',
        isAlternateRoute: false,
        // prefill obj keys
        formKeys: [
            'projectedGraduationDate',
            'goalNextWeek',
            'goalNextYear',
            'goalNextThreeYears',
            'goalNextFiveToTenYears',
            'goalAfterHighSchool',
            'courseOfStudy',
            'isAlternateRoute',
            'alternateRouteJustification',
            'totalCreditsRequiredForGraduation',
            'creditsEarnedAlready',
            'remainingCreditsForGraduation',
            'importantTransitions',
            'otherSpecify',
            'studentPreparedDescription',
        ],
    }

    componentDidMount() {
        //this.loadPDF(testPrefillObj)

        window.scrollTo(0, 0)

        var teacherId = getIDFromURL(window.location)
        let draftId = getQueryStringParam('draft')

        this.setState({
            teacherId: teacherId,
            draftId: draftId,
        })

        if (!draftId) {
            console.error('draftId is null')
            return
        }

        db.collection(ColType.californiaForm)
            .doc(draftId)
            .get()
            .then(doc => {
                if (doc.exists) {
                    let draft = flattenDoc(doc)
                    let formData = {}
                    for (let key of this.state.formKeys) {
                        if (draft.hasOwnProperty(key)) {
                            if (key === 'projectedGraduationDate') {
                                formData[key] = moment.utc(formData[key])
                            } else {
                                formData[key] = draft[key]
                            }
                        }
                    }
                    draft.projectedGraduationDate = moment.utc(draft.projectedGraduationDate)
                    console.log('draft', draft)
                    this.props.form.setFieldsValue(formData)
                    this.setState({ draft: draft })
                }
            })
            .catch(err => {
                console.log(err)
                errorMessage('Error loading draft')
            })
    }

    loadPDF = async (prefillObj) => {
        loadPDFFromDraft(prefillObj)
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                values.projectedGraduationDate = values.projectedGraduationDate.format('MM/DD/YYYY')

                if (!values.hasOwnProperty('otherSpecify')) {
                    values.otherSpecify = ''
                }
                if (!values.hasOwnProperty('alternateRouteJustification')) {
                    values.alternateRouteJustification = ''
                }

                // only advance step if this is the furthest step
                // completed in the draft
                let formStep = 3
                if (this.state.draft.hasOwnProperty('step') &&
                    this.state.draft.step < formStep) {
                    values.step = formStep
                }

                // save draft
                db.collection(ColType.californiaForm)
                    .doc(this.state.draftId)
                    .update(values)
                    .then(() => {
                        console.log('Updated draft')
                    })
                    .catch(err => {
                        console.log(err)
                    })

                let draft = this.state.draft
                console.log(draft)
                console.log(values)
                // combine the values with the draft
                values = Object.assign(draft, values)

                this.loadPDF(values)
            }
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="min-w-1120 m-lr-auto background-fff" style={{ maxWidth: '1200px' }}>
                            <div className='mt-4 flex'>

                                <div style={{ minWidth: '800px', maxWidth: '800px' }}>
                                    <div className="br-15">
                                        <div className='p-4 br-15' style={{ background: '#efdbff' }}>
                                            <div className='flex flex-v-center w-100'>
                                                <div className='mr-3'>
                                                    <h2 className="mb-05 font-20 font-bold">Step 3</h2>
                                                    <h2 className="mb-3 font-30">Vision for the Future and Pathway to a High School Diploma</h2>
                                                </div>
                                                <img src='/california/3.png' alt='draft-iep' width={250} className='ml-auto br-15 ant-shadow' />
                                            </div>
                                        </div>

                                        <div className='p-4'>
                                            <div className='font-24 font-bold mb-2'>Annual Individualized Education Program (IEP)</div>
                                            <Form onSubmit={this.handleSubmit}
                                                className="login-form text-align-left">

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Projected date of graduation with a high school diploma:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('projectedGraduationDate', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <DatePicker
                                                            className='w-100'
                                                            size={'large'}
                                                        />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Next week I want to / I want my child or young adult to:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('goalNextWeek', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        In one year, I want to / I want my child or young adult to:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('goalNextYear', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        In three years, I want to / I want my child or young adult to:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('goalNextThreeYears', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        In five to ten years, I want to / I want my child or young adult to:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('goalNextFiveToTenYears', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        After I leave high school, I want to / I want my child or young adult to: (required for students age 14 and older)
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('goalAfterHighSchool', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Course of study that will lead to a high school diploma:
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('courseOfStudy', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Is this an alternate route to a high school diploma?
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('isAlternateRoute', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <Radio.Group className='radio-group-display-block' onChange={(value) => {
                                                            this.setState({ isAlternateRoute: value.target.value })
                                                        }}>
                                                            <Radio value={true}>Yes</Radio>
                                                            <Radio value={false}>No</Radio>
                                                        </Radio.Group>
                                                    )}
                                                </FormItem>

                                                {this.state.isAlternateRoute ?
                                                    <FormItem {...formItemBlockLayout} label={<div>
                                                        <div className='font-20 font-bold'>
                                                            If yes, justification for the use of an alternate route to a high school diploma including why the
                                                            student could not meet the high school diploma requirements with appropriate accommodations
                                                            and modifications.
                                                        </div>
                                                    </div>}
                                                        className="block-label">
                                                        {getFieldDecorator('alternateRouteJustification', {
                                                            rules: [{ required: true, message: 'Required.' }],
                                                        })(
                                                            <TextArea rows={4} size={'large'} />
                                                        )}
                                                    </FormItem> : ''}

                                                <Row gutter={32}>
                                                    <Col span={8}>
                                                        <FormItem {...formItemBlockLayout} label={<div>
                                                            <div className='font-20 font-bold'>
                                                                Total credits required for graduation
                                                            </div>
                                                        </div>}
                                                            className="block-label">
                                                            {getFieldDecorator('totalCreditsRequiredForGraduation', {
                                                                rules: [{ required: true, message: 'Required.' }],
                                                            })(
                                                                <Input size='large' />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                    <Col span={8}>
                                                        <FormItem {...formItemBlockLayout} label={<div>
                                                            <div className='font-20 font-bold'>
                                                                Credits earned already
                                                            </div>
                                                        </div>}
                                                            className="block-label">
                                                            {getFieldDecorator('creditsEarnedAlready', {
                                                                rules: [{ required: true, message: 'Required.' }],
                                                            })(
                                                                <Input size='large' style={{ marginTop: '22px' }} />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                    <Col span={8}>
                                                        <FormItem {...formItemBlockLayout} label={<div>
                                                            <div className='font-20 font-bold'>
                                                                Remaining credits needed for graduation
                                                            </div>
                                                        </div>}
                                                            className="block-label">
                                                            {getFieldDecorator('remainingCreditsForGraduation', {
                                                                rules: [{ required: true, message: 'Required.' }],
                                                            })(
                                                                <Input size='large' />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                </Row>

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        Important Transitions on the Pathway to a High School Diploma
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('importantTransitions', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <Radio.Group className='radio-group-display-block' onChange={(value) => {
                                                            this.setState({
                                                                importantTransitions: value.target.value
                                                            })
                                                        }}>
                                                            <Radio value={'None'}>None</Radio>
                                                            <Radio value={'Entering preschool from early intervention'}>
                                                                Entering preschool from early intervention
                                                            </Radio>
                                                            <Radio value={'Entering elementary school (Kindergarten)'}>
                                                                Entering elementary school (Kindergarten)
                                                            </Radio>
                                                            <Radio value={'Entering middle school'}>
                                                                Entering middle school
                                                            </Radio>
                                                            <Radio value={'Entering high school'}>
                                                                Entering high school
                                                            </Radio>
                                                            <Radio value={'Leaving high school'}>
                                                                Leaving high school
                                                            </Radio>
                                                            <Radio value={'Transitioning to a new school'}>
                                                                Transitioning to a new school
                                                            </Radio>
                                                            <Radio value={'Transitioning from a nonpublic placement'}>
                                                                Transitioning from a nonpublic placement
                                                            </Radio>
                                                            <Radio value={'Transitioning to more or less time in general education'}>
                                                                Transitioning to more or less time in general education
                                                            </Radio>
                                                            <Radio value={'Transitioning between available methods to participate in school (e.g., independent study, distance, hybrid, in-person learning'}>
                                                                Transitioning between available methods to participate in school (e.g., independent study, distance, hybrid, in-person learning
                                                            </Radio>
                                                            <Radio value={'Other'}>Other</Radio>
                                                        </Radio.Group>
                                                    )}
                                                </FormItem>

                                                {this.state.importantTransitions === 'Other' ?
                                                    <FormItem {...formItemBlockLayout} label={<div>
                                                        <div className='font-20 font-bold'>
                                                            Specify:
                                                        </div>
                                                    </div>}
                                                        className="block-label">
                                                        {getFieldDecorator('otherSpecify', {
                                                            rules: [{ required: true, message: 'Required.' }],
                                                        })(
                                                            <Input size={'large'} />
                                                        )}
                                                    </FormItem> : ''}

                                                <FormItem {...formItemBlockLayout} label={<div>
                                                    <div className='font-20 font-bold'>
                                                        How the student has been or will be prepared for any upcoming transitions and any supports needed to ensure a smooth transition.
                                                    </div>
                                                </div>}
                                                    className="block-label">
                                                    {getFieldDecorator('studentPreparedDescription', {
                                                        rules: [{ required: true, message: 'Required.' }],
                                                    })(
                                                        <TextArea rows={4} size={'large'} />
                                                    )}
                                                </FormItem>


                                                <FormItem className="mb-0">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="lg-btn float-right"
                                                    >
                                                        Generate PDF
                                                    </Button>
                                                </FormItem>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className='ml-4 w-100' style={{ minWidth: '368px' }}>
                                    <FormSteps
                                        teacherId={this.state.teacherId}
                                        draft={this.state.draft}
                                    />
                                </div>

                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default Form.create()(FormPartTwo)