import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { Layout, Icon, Row, Col, Button, message, notification, Table } from 'antd'
import CustomFooter from '../../login/CustomFooter'
import { firebase } from '../../firebase/Firebase'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import {
    getAvatarColor, FOLDER_CURRENT, awardsIncStudents, getIDFromURL
} from '../.././Util'
/*
import ColType from '../.././Types'
import {
    flattenDoc, getIDFromURL, getAvatarColor,
    getQueryStringParam, awardsIncStudents, FOLDER_CURRENT,
    capitalizeFirstChar, getStudentMayHaveTroubleWith
} from '../.././Util'
import CustomFooter from '../../login/CustomFooter'
const { Content } = Layout
const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
*/

const errorMessage = (description) => {
    message.error(description)
}

const Content = Layout.Content

// Using this to add students until the step form for adding students is done.
class AddStudentCSV extends Component {
    state = {
        teacherId: '',
        visible: false,
        csvSubmitted: false,
        resultList: { data: [], originalData: [], error: '' },
        submitting: false,
    }

    componentDidMount() {
        var teacherId = getIDFromURL(window.location)
        this.setState({
            teacherId: teacherId,
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    getMayHaveTroubleWithDescription = (value) => {
        if (value === 'information') {
            return "Recalling facts and remembering information."
        }
        else if (value === 'vocabulary') {
            return "Lack of background knowledge and " +
                "weak vocabulary."
        }
        else if (value === 'problemSolving') {
            return "Independent problem solving."
        }
        else if (value === 'efficiency') {
            return "Working quickly and efficiently to " +
                "complete assignments."
        }
        else if (value === 'ell') {
            return "Difficulty comprehending lessons due to " +
                "language acquisition."
        }
        else {
            return "Not specified."
        }
    }

    onChange = (info) => {
        const status = info.file.status
        console.log(info)
        if (status !== 'uploading') {
            //console.log(info)
        }
    }

    csvTrimValuesInRow = (row) => {
        for (var key in row) {
            row[key] = row[key].trim()
        }
    }

    parseCSVHeaders = (headers) => {
        for (let i = 0; i < headers.length; i++) {
            let h = headers[i].toLowerCase().trim()
            if (h.includes('first name')) {
                headers[i] = 'First name'
            } else if (h.includes('last name')) {
                headers[i] = 'Last name'
            } else if (h.includes('ethnicity')) {
                headers[i] = 'Ethnicity'
            } else if (h.includes('gender')) {
                headers[i] = 'Gender'
            } else if (h.includes('grade')) {
                headers[i] = 'Grade'
            }
        }
    }

    parseCSVGender = (gender) => {
        let lowerCaseGender = gender.toLowerCase()
        let parsedGender = ''
        if (lowerCaseGender === 'male' || lowerCaseGender === 'm') {
            parsedGender = 'Male'
        } else if (lowerCaseGender === 'female' || lowerCaseGender === 'f') {
            parsedGender = 'Female'
        }

        return parsedGender
    }

    parseCSVEthnicity = (ethnicity) => {
        let lowerCaseEthnicity = ethnicity.toLowerCase()
        let parsedEthnicity = ''
        if (lowerCaseEthnicity.includes('white') || lowerCaseEthnicity === 'w') {
            parsedEthnicity = 'White'
        } else if (lowerCaseEthnicity.includes('black') || lowerCaseEthnicity === 'b' || lowerCaseEthnicity === 'm') {
            parsedEthnicity = 'Black or African American'
        } else if (lowerCaseEthnicity.includes('hispanic') || lowerCaseEthnicity === 'h') {
            parsedEthnicity = 'Hispanic or Latino'
        } else if (lowerCaseEthnicity.includes('asian') || lowerCaseEthnicity === 'a') {
            parsedEthnicity = 'Asian'
        } else if (lowerCaseEthnicity.includes('native american') || lowerCaseEthnicity === 'n' || lowerCaseEthnicity === 'i') {
            parsedEthnicity = 'Native American or Alaska Native'
        } else if (lowerCaseEthnicity.includes('native hawaiian') || lowerCaseEthnicity === 'p') {
            parsedEthnicity = 'Native Hawaiian or Other Pacific Islander'
        }

        return parsedEthnicity
    }

    parseCSV = (csvText) => {
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher &&
            this.props.teacherBaseProps.students)) {
            return
        }

        var lines = csvText.split("\n")
        var result = { data: [], error: '' }
        var headers = lines[0].split(",")
        this.csvTrimValuesInRow(headers)
        this.parseCSVHeaders(headers)
        var headersMustInclude = ['First name', 'Last name', 'Ethnicity', 'Gender', 'Grade']
        for (let i = 0; i < headersMustInclude.length; i++) {
            if (!headers.includes(headersMustInclude[i])) {
                console.error("CSV file must include " + headersMustInclude[i])
                result.error = 'CSV file must contain headers: First name, Last name, Ethnicity, Gender, Grade'
                return result
            }
        }

        for (let i = 1; i < lines.length; i++) {
            var obj = {}
            lines[i] = lines[i].trim()
            // empty row
            if (lines[i].length === 0) {
                continue
            }
            var currentline = lines[i].split(",")
            this.csvTrimValuesInRow(currentline)
            if (currentline.length !== headers.length) {
                console.error("CSV file must have same number of columns for each row",
                    headers.length, currentline.length, currentline)
                result.error = 'CSV file must have same number of columns for each row'
                return result
            }
            for (var j = 0; j < headers.length; j++) {
                let header = headers[j]
                if (header === 'First name') {
                    header = 'firstName'
                } else if (header === 'Last name') {
                    header = 'lastName'
                } else if (header === 'Grade') {
                    header = 'grade'
                } else if (header === 'Ethnicity') {
                    header = 'ethnicity'
                    let ethnicityBeforeParse = currentline[j]
                    if (!ethnicityBeforeParse) {
                        ethnicityBeforeParse = 'missing in csv file'
                    }
                    currentline[j] = this.parseCSVEthnicity(currentline[j])
                    if (currentline[j] === '') {
                        result.error = 'Unexpected ethnicity value encountered: ' + ethnicityBeforeParse
                        return result
                    }
                } else if (header === 'Gender') {
                    header = 'gender'
                    let genderBeforeParse = currentline[j]
                    if (!genderBeforeParse) {
                        genderBeforeParse = 'missing in csv file'
                    }
                    currentline[j] = this.parseCSVGender(currentline[j])
                    if (currentline[j] === '') {
                        result.error = 'Gender must be Male, Female, M, or F, encountered: ' + genderBeforeParse
                        return result
                    }
                }

                obj[header] = currentline[j]
            }
            result.data.push(obj)
        }

        // add other fields now that csv is parsed
        for (let i = 0; i < result.data.length; i++) {
            let values = result.data[i]

            values['race'] = values['ethnicity']
            if (values['race'] === 'Hispanic or Latino') {
                values['race'] = 'White'
            } else {
                values['ethnicity'] = 'Not Hispanic or Latino'
            }

            values.mayHaveTroubleWith = 'information'
            values.studentInterest = ''

            values.teacherId = this.state.teacherId
            values.schoolId = this.props.teacherBaseProps.teacher.schoolId
            values.districtId = this.props.teacherBaseProps.teacher.districtId
            values.avatarColor = getAvatarColor(values.grade)
            values.disability = "Autism"
            values.timeStamp = firebase.firestore.FieldValue.serverTimestamp()
            values.folder = FOLDER_CURRENT
            values.mayHaveTroubleWithDescription = this.getMayHaveTroubleWithDescription(values.mayHaveTroubleWith)

            result.data[i] = values
        }

        result.data.sort((a, b) => {
            // grade
            if (a.grade < b.grade) {
                return -1
            } else if (a.grade > b.grade) {
                return 1
            } else {
                // last name
                if (a.lastName < b.lastName) {
                    return -1
                } else if (a.lastName > b.lastName) {
                    return 1
                } else {
                    // first name
                    if (a.firstName < b.firstName) {
                        return -1
                    } else if (a.firstName > b.firstName) {
                        return 1
                    } else {
                        return 0
                    }
                }
            }
        })

        result.originalData = result.data

        // filter out students who already exist
        let filteredResults = []
        for (let i = 0; i < result.data.length; i++) {
            let values = result.data[i]
            if (this.studentInStudents(this.props.teacherBaseProps.students, values.firstName, values.lastName, values.grade)) {
                continue
            }
            filteredResults.push(values)
        }
        result.data = filteredResults

        return result
    }

    studentInStudents(students, firstName, lastName, grade) {
        for (let i = 0; i < students.length; i++) {
            let student = students[i]
            if (student.firstName === firstName &&
                student.lastName === lastName &&
                student.grade === grade) {
                return true
            }
        }
        return false
    }

    showFile = async (e) => {
        console.log('call')
        e.preventDefault()
        if (e.target.files.length === 0) {
            let result = { data: [], error: '' }
            result.error = 'No file selected'
            this.setState({
                csvSubmitted: true,
                resultList: result
            })
            return
        }

        const fileName = e.target.files[0].name
        const isCSV = fileName.endsWith('.csv')
        console.log(fileName, isCSV)
        if (!isCSV) {
            let result = { data: [], error: '' }
            result.error = 'File must be a CSV file'
            this.setState({
                csvSubmitted: true,
                resultList: result
            })
            return
        }

        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            console.log(text)
            let resultList = this.parseCSV(text)
            console.log(resultList)

            this.setState({
                csvSubmitted: true,
                resultList: resultList
            })
        }
        reader.readAsText(e.target.files[0])
        // clear input so that same file can be uploaded again
        // if cancelled
        e.target.value = ''
    }

    addStudents = () => {
        if (!(this.state.teacherId &&
            this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher)) {
            errorMessage("Something went wrong, please try again or contact your administrator.")
            return
        }
        if (this.state.resultList.data.length === 0) {
            errorMessage("No students to add, please select another csv file.")
            return
        }

        this.setState({
            submitting: true
        })

        let students = this.state.resultList.data
        let promises = []

        awardsIncStudents(this.props.teacherBaseProps.teacher, students.length)

        for (let i = 0; i < students.length; i++) {
            let student = students[i]
            promises.push(new Promise((resolve, reject) => {
                db.collection(ColType.student)
                    .add(student)
                    .then((docRef) => {
                        resolve()
                    }).catch((error) => {
                        console.error(error)
                        reject()
                    })
            }))
        }

        Promise.all(promises)
            .then(() => {
                notification.open({
                    type: 'success',
                    duration: 4,
                    message: 'Students added successfully',
                    placement: 'bottomRight'
                })
                this.props.history.push(
                    {
                        pathname: '/teacher/home/' + this.state.teacherId,
                    }
                )
            }).catch((e) => {
                errorMessage("Something went wrong, please try again or contact your administrator.")
                this.setState({
                    submitting: false
                })
            })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="w-900 m-lr-auto br-15 background-fff">
                                <div className='p-4 bkg-purple br-15'>
                                    <div className='flex flex-v-center w-100'>
                                        <div>
                                            <h1 className="font-bold mb-0 font-40 font-fff">Add students from CSV file</h1>
                                        </div>
                                        <img src='/add-buttons/add-student.png' alt='add-student' width={220} className='ml-auto' />
                                    </div>
                                </div>
                                <div className='pt-4 pb-4 pl-2 pr-2'>
                                    <Row gutter={32}>
                                        <Col span={12} className='pr-2'>
                                            <div className='font-20 font-bold'>Select a csv file containing your students' information</div>
                                            <div className='font-16 mb-2'>Students that have already been added will not be added again</div>
                                            <label className="file-upload-btn ant-btn ant-btn-primary lg-btn inline-flex">
                                                <input type="file" className='' onChange={(e) => this.showFile(e)} />
                                                <Icon type="file" className="mr-1 font-30" />
                                                Select csv file
                                            </label>

                                            {this.state.csvSubmitted ?
                                                this.state.resultList.error !== '' ?
                                                    <div>
                                                        <div className='font-20 font-bold mb-1 mt-4'>
                                                            CSV file error
                                                        </div>
                                                        <div className='font-20 mb-2 background-light-grey p-2 br-15'>
                                                            {this.state.resultList.error}
                                                            <div>
                                                                {this.state.resultList.error.indexOf('Unexpected ethnicity value') !== -1 ?
                                                                    <div>
                                                                        <div className='mt-2 font-bold'>How to fix this issue</div>
                                                                        <div>Choose one of the following options:</div>
                                                                        <ul>
                                                                            <li><div>White, W</div></li>
                                                                            <li><div>Black, B</div></li>
                                                                            <li><div>Asian, A</div></li>
                                                                            <li><div>Hispanic, hispanic, Hispanic or Latino, H</div></li>
                                                                            <li><div>Native American or Alaska Native, native american, N</div></li>
                                                                            <li><div>Native Hawaiian or Other Pacific Islander, native hawaiian, P</div></li>
                                                                        </ul>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                        <div className='mb-2 font-20'>
                                                            Please correct this issue in the CSV file and try again.
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='font-20 font-bold mb-1 mt-4'>Students</div>
                                                        <Row gutter={16} className='mb-1'>
                                                            <Col span={9}>
                                                                <div className='font-20 p-1 br-15' style={{ background: '#f0f0f0' }}>First name</div>
                                                            </Col>
                                                            <Col span={9}>
                                                                <div className='font-20 p-1 br-15' style={{ background: '#f0f0f0' }}>Last name</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div className='font-20 p-1 br-15' style={{ background: '#f0f0f0' }}>Grade</div>
                                                            </Col>
                                                        </Row>
                                                        {this.state.resultList.data.length === 0 ?
                                                            this.state.resultList.originalData.length > 0 ?
                                                                <div className='font-20 mb-2'>
                                                                    No students to add. These students have already been added.
                                                                </div>
                                                                :
                                                                <div className='font-20 mb-2'>
                                                                    No students to add. The csv file does not contain any students.
                                                                </div>
                                                            : ''}
                                                        {this.state.resultList.data.map((student, index) => {
                                                            return <Row gutter={16} className='mb-05' key={'row-' + student.firstName + '-' + student.lastName + '-' + index}>
                                                                <Col span={9}>
                                                                    <div className='font-20 ellipsis'>
                                                                        {student.firstName}
                                                                    </div>
                                                                </Col>
                                                                <Col span={9}>
                                                                    <div className='font-20 ellipsis'>
                                                                        {student.lastName}
                                                                    </div>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <div className='font-20 ellipsis'>
                                                                        {student.grade}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        })}

                                                        <div className='flex flex-v-center w-100 mt-4'>
                                                            <Button className="ant-btn ant-btn-default lg-btn text-align-center font-bold mr-1 ml-auto"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        csvSubmitted: false,
                                                                        resultList: { data: [], originalData: [], error: '' }
                                                                    })
                                                                }}
                                                                disabled={this.state.submitting}
                                                            >
                                                                <span>Cancel</span>
                                                            </Button>
                                                            <Button className="ant-btn ant-btn-primary lg-btn text-align-center font-bold"
                                                                onClick={this.addStudents}
                                                                disabled={this.state.submitting ||
                                                                    this.state.resultList.data.length === 0}
                                                            >
                                                                <Icon type="user-add" className="mr-1 font-30" />
                                                                <span>Add students</span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                : ''}
                                        </Col>
                                        <Col span={12}>
                                            <div className='flex w-100 flex-v-center'>
                                                <div className='font-20 font-bold'>Example CSV File</div>
                                                <a download={'DotItCSVTemplate.csv'}
                                                    href={'https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/DotItCSVTemplate.csv?alt=media&token=41261083-a804-4a3d-a2ed-e0aa64c20210'}
                                                    className='ant-btn ant-btn-default ml-1'
                                                >
                                                    <Icon type='download' />
                                                    <span>Download</span>
                                                </a>
                                            </div>
                                            <Table pagination={false} columns={[{
                                                title: 'First name',
                                                dataIndex: 'firstName',
                                                key: 'firstName',
                                            },
                                            {
                                                title: 'Last name',
                                                dataIndex: 'lastName',
                                                key: 'lastName',
                                            },
                                            {
                                                title: 'Ethnicity',
                                                dataIndex: 'ethnicity',
                                                key: 'ethnicity',
                                            },
                                            {
                                                title: 'Gender',
                                                dataIndex: 'gender',
                                                key: 'gender',
                                            },
                                            {
                                                title: 'Grade',
                                                dataIndex: 'grade',
                                                key: 'grade',
                                            }
                                            ]}
                                                dataSource={[{
                                                    key: '1',
                                                    firstName: 'John',
                                                    lastName: 'Smith',
                                                    ethnicity: 'W',
                                                    gender: 'M',
                                                    grade: '2'
                                                },
                                                {
                                                    key: '2',
                                                    firstName: 'Emily',
                                                    lastName: 'Wu',
                                                    ethnicity: 'A',
                                                    gender: 'F',
                                                    grade: '3'
                                                },
                                                {
                                                    key: '3',
                                                    firstName: 'Jim',
                                                    lastName: 'Garcia',
                                                    ethnicity: 'H',
                                                    gender: 'M',
                                                    grade: '4'
                                                },
                                                {
                                                    key: '4',
                                                    firstName: 'Jane',
                                                    lastName: 'Johnson',
                                                    ethnicity: 'B',
                                                    gender: 'F',
                                                    grade: '5'
                                                }
                                                ]}
                                            />

                                            <div className='font-20 font-bold mb-1 mt-3'>Dot It CSV Instructions:</div>
                                            <div className='font-20'>The headers should be: First name, Last name, Ethnicity, Gender, Grade</div>

                                            <div className='font-20 font-bold mb-1 mt-2'>Ethnicity options:</div>
                                            <ul className='font-20'>
                                                <li>White, W</li>
                                                <li>Black, B</li>
                                                <li>Asian, A</li>
                                                <li>Hispanic, hispanic, Hispanic or Latino, H</li>
                                                <li>Native American or Alaska Native, native american, N</li>
                                                <li>Native Hawaiian or Other Pacific Islander, native hawaiian, P</li>
                                            </ul>

                                            <div className='font-20 font-bold mb-1'>Gender options:</div>
                                            <ul className='font-20'>
                                                <li>Male, M</li>
                                                <li>Female, F</li>
                                            </ul>

                                            <div className='font-20 font-bold mb-1'>Grade options:</div>
                                            <ul className='font-20'>
                                                <li>K</li>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                                <li>7</li>
                                                <li>8</li>
                                                <li>9</li>
                                                <li>10</li>
                                                <li>11</li>
                                                <li>12</li>
                                            </ul>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default AddStudentCSV