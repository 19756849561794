import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { Tabs, Select, Spin, Empty, Icon } from 'antd';
import { db } from '../firebase/Firebase';
import { getStateStandardNamesDict } from '../Util';

const { TabPane } = Tabs;
const { Option } = Select;

class DistrictStatsV3 extends React.Component {
  state = {
    assessmentData: [],
    loading: true,
    error: null,
    validCombinations: [],
    selectedSubject: '',
    selectedGrade: '',
    schools: [],
    teachers: [],
    students: [],
    selectedSchool: '',
    selectedTeacher: '',
    selectedStudent: '',
    activeTab: '1'
  };

  // Track mounted state to prevent setState after unmount
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const districtId = this.props.adminBaseProps?.admin?.districtId;

    if (districtId) {
      this.initializeData(districtId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const prevDistrictId = prevProps.adminBaseProps?.admin?.districtId;
    const currentDistrictId = this.props.adminBaseProps?.admin?.districtId;

    // Handle both initial load and district changes
    if (currentDistrictId && currentDistrictId !== prevDistrictId) {
      this.initializeData(currentDistrictId);
    }
  }

  // Safe setState that checks if component is mounted
  safeSetState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, callback);
    }
  };

  // Initialize all data for a district
  initializeData = async (districtId) => {
    this.safeSetState({ loading: true, error: null });

    try {
      await Promise.all([
        this.fetchValidCombinations(districtId),
        this.fetchSchools(districtId)
      ]);
      this.fetchTeachers(this.state.selectedSchool)
    } catch (error) {
      console.error("Error initializing data:", error);
      this.safeSetState({
        error: 'Failed to initialize data',
        loading: false
      });
    }
  };

  fetchValidCombinations = async (districtId) => {
    try {
      const academicYear = this.getCurrentAcademicYear();

      const query = await db
        .collection('cleverAssessmentReports')
        .where('districtId', '==', districtId)
        .where('academicYear', '==', academicYear)
        .where('recordType', '==', 'weekly')
        .get();

      const combinations = new Set();

      query.docs.forEach(doc => {
        const data = doc.data();
        if (data.standardCategory && data.standardName &&
          data.standardCategory !== 'Unknown' && data.standardName !== 'Unknown'
        ) {
          combinations.add(`${data.standardCategory}|${data.standardName}`);
        }
      });

      const validCombinations = Array.from(combinations).map(combo => {
        const [subject, grade] = combo.split('|');
        return { subject, grade };
      });

      const firstCombo = validCombinations[0];

      this.safeSetState({
        validCombinations,
        selectedSubject: firstCombo?.subject || '',
        selectedGrade: firstCombo?.grade || '',
        loading: false
      }, () => {
        if (firstCombo) {
          this.fetchAssessmentData(districtId, firstCombo.subject, firstCombo.grade);
        }
      });

    } catch (error) {
      throw new Error('Failed to fetch valid combinations: ' + error.message);
    }
  };

  getSchoolTypeSortWeight = (schoolType) => {
    if (!schoolType) {
      return -1
    }
    if (schoolType === 'elementarySchool') {
      return 0
    } else if (schoolType === 'k8School') {
      return 1
    } else if (schoolType === 'middleSchool') {
      return 2
    } else if (schoolType === 'highSchool') {
      return 3
    }

    return 0
  }

  fetchSchools = async (districtId) => {
    try {
      const schoolsQuery = await db
        .collection('schools')
        .where('districtId', '==', districtId)
        .get();

      let schools = schoolsQuery.docs.map(doc => ({
        id: doc.id,
        name: doc.data().schoolName  // Changed from name to schoolName
      }));
      schools.sort((a, b) => {
        if (a.hasOwnProperty('schoolType') &&
          b.hasOwnProperty('schoolType')) {
          return this.getSchoolTypeSortWeight(a.schoolType) - this.getSchoolTypeSortWeight(b.schoolType)
        }

        return 0
      });

      let stateObj = {
        schools: schools,
      }
      // default select first school
      if (schools.length > 0) {
        stateObj.selectedSchool = schools[0].id;
      }

      this.safeSetState(stateObj);
    } catch (error) {
      console.error("Error fetching schools:", error);
      this.safeSetState({ error: 'Failed to fetch schools' });
    }
  };

  fetchTeachers = async (schoolId) => {
    try {
      console.log("Fetching teachers for school:", schoolId);

      const teachersQuery = await db
        .collection('teachers')
        .where('schoolId', '==', schoolId)
        .where('districtId', '==', this.props.adminBaseProps?.admin?.districtId)
        .get();

      console.log("Teachers query result size:", teachersQuery.size);

      let teachers = teachersQuery.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          firstName: data.firstName,
          lastName: data.lastName,
          name: data.firstName && data.lastName ?
            `${data.firstName} ${data.lastName}`.trim() :
            'Unnamed Teacher'
        };
      });
      teachers.sort((a, b) => a.lastName.localeCompare(b.lastName));

      console.log("Processed teachers:", teachers);

      let defaultSelectedTeacher = this.state.selectedTeacher;
      if (!defaultSelectedTeacher && teachers.length > 0) {
        defaultSelectedTeacher = teachers[0].id;
      } else if (defaultSelectedTeacher && teachers.length > 0 &&
        !teachers.find(teacher => teacher.id === defaultSelectedTeacher)) {
        defaultSelectedTeacher = teachers[0].id;
      }

      this.safeSetState({
        teachers,
        selectedSchool: schoolId,
        // default select first teacher
        selectedTeacher: defaultSelectedTeacher,
        selectedStudent: ''
      });

      if (defaultSelectedTeacher) {
        this.fetchStudents(defaultSelectedTeacher);
      }
    } catch (error) {
      console.error("Error fetching teachers:", error);
      this.safeSetState({ error: 'Failed to fetch teachers' });
    }
  };


  fetchStudents = async (teacherId) => {
    try {
      const studentsQuery = await db
        .collection('students')
        .where('teacherId', '==', teacherId)
        .get();

      let students = studentsQuery.docs.map(doc => ({
        id: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        name: doc.data().firstName + ' ' + doc.data().lastName
      }));
      students.sort((a, b) => a.lastName.localeCompare(b.lastName));

      console.log("Students:", students);  // Debug log

      let defaultSelectedStudent = this.state.selectedStudent;
      if (!defaultSelectedStudent && students.length > 0) {
        defaultSelectedStudent = students[0].id;
      } else if (defaultSelectedStudent && students.length > 0 &&
        !students.find(student => student.id === defaultSelectedStudent)) {
        defaultSelectedStudent = students[0].id;
      }

      this.safeSetState({
        students,
        selectedTeacher: teacherId,
        // default select first student
        selectedStudent: defaultSelectedStudent
      });
    } catch (error) {
      console.error("Error fetching students:", error);
      this.safeSetState({ error: 'Failed to fetch students' });
    }
  };


  getCurrentAcademicYear = () => {
    const now = new Date();
    return now.getMonth() < 7
      ? `${now.getFullYear() - 1}-${now.getFullYear()}`
      : `${now.getFullYear()}-${now.getFullYear() + 1}`;
  };

  fetchAssessmentData = async (districtId, subject, grade, type = 'overview', id = null) => {
    if (!districtId || !subject || !grade) {
      this.safeSetState({ error: 'Missing required parameters', loading: false });
      return;
    }

    this.safeSetState({ loading: true });

    try {
      const academicYear = this.getCurrentAcademicYear();

      const weeklyQuery = await db
        .collection('cleverAssessmentReports')
        .where('districtId', '==', districtId)
        .where('academicYear', '==', academicYear)
        .where('recordType', '==', 'weekly')
        .where('standardCategory', '==', subject)
        .where('standardName', '==', grade)
        .get();

      const weeklyData = this.processWeeklyData(weeklyQuery.docs, type, id);
      const formattedData = this.formatWeeklyData(weeklyData);

      // Only update state if the component is still mounted and the selected values match
      if (this._isMounted &&
        this.state.selectedSubject === subject &&
        this.state.selectedGrade === grade &&
        ((type === 'overview') ||
          (type === 'school' && this.state.selectedSchool === id) ||
          (type === 'teacher' && this.state.selectedTeacher === id) ||
          (type === 'student' && this.state.selectedStudent === id))) {
        this.safeSetState({
          assessmentData: formattedData,
          loading: false,
          error: null
        });
      }
    } catch (error) {
      console.error("Error fetching assessment data:", error);
      if (this._isMounted) {
        this.safeSetState({
          error: 'Failed to load assessment data',
          loading: false
        });
      }
    }
  };

  processWeeklyData = (docs, type, id) => {
    console.log("Processing weekly data:", { type, id });
    const weeklyData = {};

    docs.forEach(doc => {
      const data = doc.data();
      console.log("Processing doc data:", {
        weekNumber: data.weekNumber,
        breakdowns: data.breakdowns,
        type,
        id
      });

      const weekNum = data.weekNumber;

      if (!weekNum) return;

      if (!weeklyData[weekNum]) {
        weeklyData[weekNum] = this.initializeWeekData(weekNum);
      }

      const breakdownData = this.getBreakdownData(data, type, id);
      console.log("Breakdown data for week", weekNum, ":", breakdownData);

      this.updateWeeklyMetrics(weeklyData[weekNum], breakdownData);
    });

    console.log("Final weekly data:", weeklyData);
    return weeklyData;
  };

  // Also add debugging to getBreakdownData
  getBreakdownData = (data, type, id) => {
    let breakdown;
    switch (type) {
      case 'school':
        breakdown = data.breakdowns?.bySchool?.[id] || {};
        break;
      case 'teacher':
        breakdown = data.breakdowns?.byTeacher?.[id] || {};
        break;
      case 'student':
        breakdown = data.breakdowns?.byStudent?.[id] || {};
        break;
      default:
        breakdown = data.breakdowns?.allSchools || {};
    }
    console.log("Getting breakdown data:", { type, id, breakdown });
    return breakdown;
  };

  updateWeeklyMetrics = (weekData, breakdownData) => {
    console.log("Updating metrics with breakdown data:", breakdownData);

    // Updated to use consistent naming between display and count keys
    const assessmentTypes = {
      'common_assessment': {
        displayName: 'Common Assessment',
        countKey: 'commonAssessmentCount'
      },
      'benchmark': {
        displayName: 'Benchmark',
        countKey: 'benchmarkCount'
      },
      /*'quiz': {
        displayName: 'Quiz',
        countKey: 'quizCount'
      }*/
    };

    Object.entries(breakdownData).forEach(([type, stats]) => {
      const assessmentType = assessmentTypes[type];
      if (assessmentType) {
        const { displayName, countKey } = assessmentType;
        weekData[displayName] = parseFloat((stats.averageScore * 100).toFixed(2)) || 0;
        weekData[countKey] = stats.assessmentCount || 0;
        console.log(`Updated ${displayName}:`, {
          score: weekData[displayName],
          count: weekData[countKey]
        });
      }
    });
  };

  initializeWeekData = (weekNum) => ({
    week: weekNum,
    'Common Assessment': 0,
    'Benchmark': 0,
    //'Quiz': 0,
    commonAssessmentCount: 0,
    benchmarkCount: 0,
    // quizCount: 0
  });



  formatWeeklyData = (weeklyData) => {
    return Object.values(weeklyData)
      .sort((a, b) => a.week - b.week)
      .map(data => ({
        ...data,
        week: `Week ${data.week}`
      }));
  };

  handleSchoolChange = (schoolId) => {
    this.setState({
      selectedSchool: schoolId,
      selectedTeacher: '',
      selectedStudent: '',
      students: []
    }, () => {
      const districtId = this.props.adminBaseProps?.admin?.districtId;
      Promise.all([
        this.fetchTeachers(schoolId),
        this.fetchAssessmentData(
          districtId,
          this.state.selectedSubject,
          this.state.selectedGrade,
          'school',
          schoolId
        )
      ]);
    });
  };

  handleTeacherChange = (teacherId) => {
    this.setState({
      selectedTeacher: teacherId,
      selectedStudent: ''
    }, () => {
      const districtId = this.props.adminBaseProps?.admin?.districtId;
      Promise.all([
        this.fetchStudents(teacherId),
        this.fetchAssessmentData(
          districtId,
          this.state.selectedSubject,
          this.state.selectedGrade,
          'teacher',
          teacherId
        )
      ]);
    });
  };

  handleStudentChange = (studentId) => {
    this.setState({
      selectedStudent: studentId
    }, () => {
      const districtId = this.props.adminBaseProps?.admin?.districtId;
      this.fetchAssessmentData(
        districtId,
        this.state.selectedSubject,
        this.state.selectedGrade,
        'student',
        studentId
      );
    });
  };

  handleSubjectChange = (value) => {
    const validGrades = this.state.validCombinations
      .filter(combo => combo.subject === value)
      .map(combo => combo.grade);

    let selectedGrade = this.state.selectedGrade;
    if (validGrades.indexOf(selectedGrade) === -1) {
      // default to first valid grade if selected grade is not in the list
      selectedGrade = validGrades[0];
    }
    this.setState({
      selectedSubject: value,
      selectedGrade: selectedGrade,
      // Reset selections when subject changes
      //selectedSchool: '',
      //  selectedTeacher: '',
      // selectedStudent: '',
      //teachers: [],
      // students: []
    }, () => {
      const districtId = this.props.adminBaseProps?.admin?.districtId;
      this.fetchAssessmentData(
        districtId,
        value,
        selectedGrade,
        'overview'
      );
    });
  };

  handleGradeChange = (value) => {
    this.setState({
      selectedGrade: value,
      // Reset selections when grade changes
      // selectedSchool: '',
      //selectedTeacher: '',
      // selectedStudent: '',
      //teachers: [],
      // students: []
    }, () => {
      const districtId = this.props.adminBaseProps?.admin?.districtId;
      this.fetchAssessmentData(
        districtId,
        this.state.selectedSubject,
        value,
        'overview'
      );
    });
  };

  renderChart = (data, title) => {
    if (!data || data.length === 0) {
      return <Empty description="No data available for selected criteria" />;
    }

    const assessmentTypes = [
      {
        key: 'Common Assessment',
        countKey: 'commonAssessmentCount',
        color: '#1890ff'
      },
      {
        key: 'Benchmark',
        countKey: 'benchmarkCount',
        color: '#52c41a'
      },
      /*{
        key: 'Quiz',
        countKey: 'quizCount',
        color: '#722ed1'
      }*/
    ];

    return (
      <div style={{ height: '400px' }} className='mt-3'>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 50,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="week"
              tick={{ fontSize: 12 }}
            />
            <YAxis
              domain={[0, 100]}
              tickFormatter={(value) => `${value}%`}
              tick={{ fontSize: 12 }}
              label={{
                value: 'Grade',
                angle: -90,
                position: 'insideLeft',
                offset: -35,
                style: { fontSize: '14px' }
              }}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <div style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px'
                    }}>
                      <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>{label}</p>
                      {payload.map(entry => {
                        // Find the matching assessment type to get the correct countKey
                        const assessmentType = assessmentTypes.find(type => type.key === entry.dataKey);
                        const count = assessmentType ? entry.payload[assessmentType.countKey] : 0;
                        return (
                          <p key={entry.dataKey} style={{ margin: '2px 0', color: entry.color }}>
                            {entry.dataKey}: {entry.value.toFixed(1)}%
                            <span style={{ marginLeft: '5px', fontSize: '0.9em', color: '#666' }}>
                              ({count} assessments)
                            </span>
                          </p>
                        );
                      })}
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend />
            {assessmentTypes.map((type) => (
              <Bar
                key={type.key}
                dataKey={type.key}
                fill={type.color}
                radius={[4, 4, 0, 0]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  handleTabChange = (activeKey) => {
    const districtId = this.props.adminBaseProps?.admin?.districtId;

    console.log('key:', activeKey, 'school:', this.state.selectedSchool,
      'teacher:', this.state.selectedTeacher,
      'student:', this.state.selectedStudent
    )

    this.setState({ activeTab: activeKey }, () => {
      // Fetch appropriate view based on tab without clearing selections
      switch (activeKey) {
        case '1': // All Schools
          this.fetchAssessmentData(
            districtId,
            this.state.selectedSubject,
            this.state.selectedGrade,
            'overview'
          );
          break;

        case '2': // By School
          if (this.state.selectedSchool) {
            Promise.all([
              this.fetchTeachers(this.state.selectedSchool),
              this.fetchAssessmentData(
                districtId,
                this.state.selectedSubject,
                this.state.selectedGrade,
                'school',
                this.state.selectedSchool
              )
            ])
          }
          break;

        case '3': // By Teacher
          if (this.state.selectedTeacher) {
            Promise.all([
              this.fetchStudents(this.state.selectedTeacher),
              this.fetchAssessmentData(
                districtId,
                this.state.selectedSubject,
                this.state.selectedGrade,
                'teacher',
                this.state.selectedTeacher
              )
            ])
          }
          break;

        case '4': // By Student
          if (this.state.selectedStudent) {
            this.fetchAssessmentData(
              districtId,
              this.state.selectedSubject,
              this.state.selectedGrade,
              'student',
              this.state.selectedStudent
            );
          }
          break;
      }
    });
  };

  getLoadingComponent = () => {
    return <div className='flex flex-v-center flex-h-center mt-100 w-100'>
      <Spin className='ant-spin-lg' />
    </div>;
  }

  render() {
    const {
      loading,
      error,
      assessmentData,
      schools,
      teachers,
      students,
      selectedSchool,
      selectedTeacher,
      selectedSubject,
      selectedGrade
    } = this.state;

    if (!this.props.adminBaseProps?.admin?.districtId) {
      return '';
    }

    if (error) {
      console.log("Error:", error);
      return 'An error occurred, try refreshing the page.';
    }

    return (
      <div className='mt-4 pt-1'>
        <div>
          <div className='mb-3'>
            <Select
              size='large'
              style={{ width: 200, marginRight: 16 }}
              value={selectedSubject}
              onChange={this.handleSubjectChange}
              placeholder="Select Subject"
            >
              {[...new Set(this.state.validCombinations.map(combo => combo.subject))].map(subject => (
                <Option key={subject} value={subject}>{subject}</Option>
              ))}
            </Select>

            <Select
              size='large'
              style={{ width: 200 }}
              value={selectedGrade}
              onChange={this.handleGradeChange}
              placeholder="Select Grade"
            >
              {this.state.validCombinations
                .filter(combo => combo.subject === selectedSubject)
                .map(combo => (
                  <Option key={combo.grade} value={combo.grade}>{combo.grade}</Option>
                ))
              }
            </Select>
          </div>

          <Tabs defaultActiveKey="1" activeKey={this.state.activeTab}
            onChange={this.handleTabChange} animated={false} size='large'>
            <TabPane tab="All Schools" key="1">
              {loading ? this.getLoadingComponent() : this.renderChart(assessmentData, 'District Overview')}
            </TabPane>

            <TabPane tab="By School" key="2">
              <div style={{ marginBottom: 16 }}>
                <Select
                  size='large'
                  className='mt-3'
                  style={{ width: 300 }}
                  value={selectedSchool}
                  onChange={this.handleSchoolChange}
                  placeholder="Select School"
                >
                  {schools.map(school => (
                    <Option key={school.id} value={school.id}>
                      {school.name || 'Unnamed School'}
                    </Option>
                  ))}
                </Select>
              </div>
              {loading ? this.getLoadingComponent() : selectedSchool && this.renderChart(assessmentData, 'School Performance')}
            </TabPane>

            <TabPane tab="By Teacher" key="3">
              <div style={{ marginBottom: 16 }}>
                <Select
                  size='large'
                  className='mt-3'
                  style={{ width: 300, marginRight: 16 }}
                  value={selectedSchool}
                  onChange={this.handleSchoolChange}
                  placeholder="Select School First"
                >
                  {schools.map(school => (
                    <Option key={school.id} value={school.id}>
                      {school.name || 'Unnamed School'}
                    </Option>
                  ))}
                </Select>

                <Select
                  size='large'
                  className='mt-3'
                  style={{ width: 300 }}
                  value={selectedTeacher}
                  onChange={this.handleTeacherChange}
                  placeholder="Select Teacher"
                  disabled={!selectedSchool}
                >
                  {teachers.map(teacher => (
                    <Option key={teacher.id} value={teacher.id}>
                      {teacher.name || 'Unnamed Teacher'}
                    </Option>
                  ))}
                </Select>
              </div>
              {loading ? this.getLoadingComponent() : selectedTeacher && this.renderChart(assessmentData, 'Teacher Performance')}
            </TabPane>

            <TabPane tab="By Student" key="4">
              <div style={{ marginBottom: 16 }}>
                <Select
                  size='large'
                  className='mt-3'
                  style={{ width: 300, marginRight: 16 }}
                  value={selectedSchool}
                  onChange={this.handleSchoolChange}
                  placeholder="Select School First"
                >
                  {schools.map(school => (
                    <Option key={school.id} value={school.id}>{school.name}</Option>
                  ))}
                </Select>

                <Select
                  size='large'
                  className='mt-3'
                  style={{ width: 300, marginRight: 16 }}
                  value={selectedTeacher}
                  onChange={this.handleTeacherChange}
                  placeholder="Select Teacher"
                  disabled={!selectedSchool}
                >
                  {teachers.map(teacher => (
                    <Option key={teacher.id} value={teacher.id}>{teacher.name}</Option>
                  ))}
                </Select>

                <Select
                  size='large'
                  className='mt-3'
                  style={{ width: 300 }}
                  value={this.state.selectedStudent}
                  onChange={this.handleStudentChange}
                  placeholder="Select Student"
                  disabled={!selectedTeacher}
                >
                  {students.map(student => (
                    <Option key={student.id} value={student.id}>{student.name}</Option>
                  ))}
                </Select>
              </div>
              {loading ? this.getLoadingComponent() : this.state.selectedStudent && this.renderChart(assessmentData, 'Student Performance')}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default DistrictStatsV3;
