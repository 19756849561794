import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase } from '../firebase/Firebase'
import { getInitials } from '.././Util'
import { Layout, Menu, Avatar, Popover, message, Icon } from 'antd'
const { Header } = Layout

const errorMessage = (description) => {
  message.error(description)
}

class SchoolAdminHeader extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    searchValue: '',
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {
    // on url change set popovers visible to false
    this.setState({
      newPopoverVisible: false,
      accountPopoverVisible: false,
    })
  }

  newVisibleChange = (visible) => {
    this.setState({ newPopoverVisible: visible })
  }

  accountVisibleChange = (visible) => {
    this.setState({ accountPopoverVisible: visible })
  }

  signOut = () => {
    firebase.auth().signOut().then(() => {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      if (this.props.person &&
        this.props.person.hasOwnProperty('districtId') &&
        this.props.person.districtId === 'vYTvkDwoLx19NkA6dJO8') {
        this.props.history.push(
          {
            pathname: '/beta/sign-in/'
          }
        )
      } else {
        this.props.history.push(
          {
            pathname: '/sign-in/'
          }
        )
      }
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/admin/school-summary/' +
          this.props.person.id + '?district=' +
          this.props.person.districtId + '&school=' +
          option.key
      }
    )
  }

  render() {
    return (
      <div>
        {this.props.person != null &&
          Object.keys(this.props.person).length !== 0 &&
          this.props.person.constructor === Object ?
          <Header
            className={'position-fixed'}
            style={{
              padding: 0,
              textAlign: 'left',
              width: '100%',
              zIndex: 2,
              borderBottom: '1px solid #f1f1f1'
            }}
          >
            <div className='p-lr-20-important'>
              <Menu
                selectedKeys={[this.props.selectedKey]}
                mode="horizontal"
                className="font-16 no-border-bottom flex flex-row background-fff"
                style={{ lineHeight: '80px' }}
              >
                <Menu.Item key="admin-logo" className="inline-flex flex-v-center menu-no-bb mr-3 pl-0">
                  <Link to={'/'} style={{ marginTop: '-3px' }}>
                    <img src='/dotitlogo.png' alt='logo' height='40' />
                  </Link>
                </Menu.Item>


                <Menu.Item key="school-admin-dashboard" className="font-500 inline-flex flex-center">
                  <Link
                    to={'/school-admin/school-admin-home/' + this.props.person.id}
                    className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'school-admin-home' ? ' text-cyan' : '')}>
                    <Icon type="home" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">School</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="pacing-guide" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/school-admin/pacing-guide/guide/' + this.props.person.id}
                    className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'guide' || this.props.selectedKey === 'lesson-plan' ? ' text-cyan' : '')}
                  >
                    <Icon type="read" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Pacing Guide</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="school-admin-teams" className="font-500 inline-flex flex-center">
                  <Link
                    to={'/school-admin/teams/' + this.props.person.id}
                    className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'teams' ? ' text-cyan' : '')}>
                    {this.props.schoolAdminBaseProps.hasChatNotifications ?
                      <div className="absolute-tr chat-active-dot"
                        style={{ top: '30px', right: '16px' }}>
                      </div>
                      : ''}
                    <Icon type="message"
                      className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">
                      <span>Teams</span>
                    </span>
                  </Link>
                </Menu.Item>

                {this.props.schoolAdminBaseProps &&
                  this.props.schoolAdminBaseProps.district &&
                  (this.props.schoolAdminBaseProps.district.id === "GCj1Hg1H5vipzer4pED4" ||
                    this.props.schoolAdminBaseProps.district.id === '5410a3828725bd2f0d00100d' ||
                    this.props.schoolAdminBaseProps.district.id === '64bec570267c8bfeea81f9ea') ?
                  <Menu.Item key="school-admin-workshop-observation-tool" className="font-500 inline-flex flex-center">
                    <Link
                      to={'/school-admin/school-admin-workshop-observation-tool/documents/' + this.props.person.id}
                      className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                        (this.props.selectedKey === 'school-admin-workshop-observation-tool' ? ' text-cyan' : '')}>
                      <Icon type="solution"
                        className={"va-middle " +
                          "mr-0 br-50 p-1 font-24"} />
                      <span className="va-minus-4 font-20">
                        <span>Workshop Observation Tool</span>
                      </span>
                    </Link>
                  </Menu.Item> : ''}


                {/* 
<Menu.Item key="admin-schools" className="font-500 inline-flex flex-center">
                <Tooltip title="Find student information" placement="bottom" mouseEnterDelay={.75}>
                  <Link
                    to={'/admin/admin-schools/' + this.props.person.id}>
                    <Icon type="user" className={"font-24 va-middle flex-h-center " +
                      "mr-0 br-50 border p-1 font-30 ant-btn-primary"} />
                  </Link>
                </Tooltip>
              </Menu.Item>
              */}


                {/* <Menu.Item key="district-summary" className="font-500 inline-flex flex-center">
                <Tooltip title="District summary" placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/admin/district-summary/' + this.props.person.id}>
                  <Icon type="bar-chart" className={"font-24 va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 font-30 ant-btn-primary"} />
                </Link>
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="schools" className="font-500 inline-flex flex-center">
                <Tooltip title="School summaries" 
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/admin/schools/' + this.props.person.id}>
                  <img src='/school-icon-fff.svg'
                  width={49}
                  height={49} 
                  style={{fill: 'white'}}
                  className={"va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 ant-btn-primary"} 
                  alt='schools'
                  />
                </Link>
                </Tooltip>
              </Menu.Item>*/}
                {/*<Menu.Item key="add-person" className="font-500 inline-flex flex-center">
                <Tooltip title="Add personnel to district" placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/admin/add-person/' + this.props.person.id}>
                  <Icon type="plus" className={"font-24 va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 font-30 ant-btn-primary"} />
                </Link>
                </Tooltip>
          </Menu.Item>*/}

                {/*<Menu.Item key="search" className="inline-flex flex-v-center menu-no-bb ml-auto">
                <Select
                  showSearch
                  className="ant-select-very-large cursor-text br-100"
                  placeholder={'Search schools'}
                  showArrow={false}
                  onChange={this.onChange}
                  onSelect={this.onSelect}
                  style={{ width: '350px' }}
                >
                  {this.props.schools && this.props.schools.map((school, index) => {
                    return <Option
                      key={school.id}
                      value={school.schoolName}
                      title={school.schoolName}
                    >
                      {school.schoolName}
                    </Option>
                  })
                  }
                </Select>
                <Icon type="search" className="font-16 select-suffix" />
                </Menu.Item>*/}

                <Menu.Item key="teacher-sign-out"
                  className="inline-flex flex-v-center menu-no-bb ml-auto pr-0">
                  <Popover
                    visible={this.state.accountPopoverVisible}
                    onVisibleChange={this.accountVisibleChange}
                    placement="bottomLeft"
                    title={
                      <div className="pt-1 pb-1">
                        <Avatar
                          size="large"
                          style={{ backgroundColor: '#d3adf7' }}
                          className="avatar-vlg"
                          containerClassName="font-30 mt-05 no-text-highlight"
                          avatarClassName="font-30 font-bold"
                          personClassName="font-40 font-bold mt-1"
                        >
                          <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                        </Avatar>
                        <span className="font-20 ml-1 font-bold vertical-align-middle">
                          {this.props.person.firstName + " " + this.props.person.lastName}
                        </span>
                      </div>
                    } content={
                      <div className="p-0 border-right-none negate-popover-padding">
                        <div className="ant-menu-item p-0" onClick={this.signOut}>
                          <span className="font-16 p-2 block font-bold">
                            <span className="va-minus-1 font-20">Sign out</span>
                          </span>
                        </div>
                      </div>
                    } trigger="click">
                    <span className="h-80px inline-flex flex-v-center cursor-pointer">
                      <span className="inline-block-important">
                        <Avatar
                          size="large"
                          style={{ backgroundColor: '#d3adf7' }}
                          className="avatar-vlg"
                          containerClassName="font-30 mt-05 no-text-highlight"
                          avatarClassName="font-30 font-bold"
                          personClassName="font-40 font-bold mt-1"
                        >
                          <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                        </Avatar>
                      </span>
                    </span>
                  </Popover>
                </Menu.Item>

              </Menu>
            </div>

          </Header>
          : ''}
      </div>
    )
  }
}

export default SchoolAdminHeader