import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import { flattenDoc, FOLDER_CURRENT, getQueryStringParam } from '../Util'
import { Layout, Row, Col, Select, Icon, Tabs } from 'antd'
import TeacherStudentReport from '../teacher/reports/TeacherStudentReport'
import PersonAvatar from '../customcomponents/PersonAvatar'
import SubjectNoteDisplay from '../teacher/progressmonitoring/SubjectNoteDisplay'
import NoteDisplay from '../teacher/progressmonitoring/NotesDisplay'
import StudentSummary from '../admin/StudentSummary'
const { Content } = Layout
const TabPane = Tabs.TabPane

class InstructionalFacilitatorStudentView extends Component {
    state = {
        schoolId: '',
        teacherId: '',
        teacher: null,
        studentId: '',
        student: null,
        students: null,
        studentDict: {},
        IEPGoals: [],
        IEPDict: {},
    }

    // Do fetch here
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getData()
    }

    componentWillReceiveProps(props, newProps) {
        this.getData()
    }

    getData() {
        let prevSchoolId = this.state.schoolId
        let prevTeacherId = this.state.teacherId
        let prevStudentId = this.state.studentId

        let schoolId = getQueryStringParam('s')
        let teacherId = getQueryStringParam('t')
        let studentId = getQueryStringParam('st')

        if (teacherId && studentId && this.props.instructionalFacilitatorBaseProps.instructionalFacilitator) {
            // already have this data
            if (schoolId === prevSchoolId && teacherId === prevTeacherId && studentId === prevStudentId) {
                return
            }

            this.setState({
                schoolId: schoolId,
                teacherId: teacherId,
                studentId: studentId,
            })

            db.collection(ColType.teacher)
                .doc(teacherId)
                .get()
                .then((doc) => {
                    var teacher = flattenDoc(doc)
                    this.setState({
                        teacher: teacher,
                    })
                })

            db.collection(ColType.student)
                .doc(studentId)
                .get()
                .then((doc) => {
                    var student = flattenDoc(doc)
                    this.setState({
                        student: student,
                    })
                })

            // get students once
            if (!this.state.students || prevTeacherId !== teacherId) {
                const studentUpdateStateFunc = (querySnapshot) => {
                    var students = []
                    var studentDict = {}

                    querySnapshot.forEach((doc) => {
                        var student = flattenDoc(doc)
                        students.push(student)
                        studentDict[student.id] = student
                    })

                    students.sort((a, b) => {
                        var levelDifference = 0
                        if (a.grade === 'K' && b.grade === 'K') {
                            if (levelDifference !== 0) return levelDifference
                            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                return 1
                            } else {
                                return -1
                            }
                        }
                        if (a.grade === 'K' && b.grade !== 'K') return -1
                        if (a.grade !== 'K' && b.grade === 'K') return 1
                        if (a.grade === b.grade) {
                            if (levelDifference !== 0) return levelDifference
                            if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                return 1
                            } else {
                                return -1
                            }
                        }
                        var aGrade = parseInt(a.grade, 10)
                        var bGrade = parseInt(b.grade, 10)
                        if (aGrade > bGrade) return 1
                        if (bGrade > aGrade) return -1
                        return 0
                    })

                    this.setState({
                        students: students,
                        studentDict: studentDict,
                    })
                }

                db.collection(ColType.student)
                    .where('districtId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId)
                    .where('schoolId', '==', schoolId)
                    .where('teacherIds', 'array-contains', teacherId)
                    .where('folder', '==', FOLDER_CURRENT)
                    .get()
                    .then((querySnapshot) => {
                        if (!querySnapshot.empty) {
                            studentUpdateStateFunc(querySnapshot)
                        } else {
                            db.collection(ColType.student)
                                .where('districtId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId)
                                .where('schoolId', '==', schoolId)
                                .where('teacherId', '==', teacherId)
                                .where('folder', '==', FOLDER_CURRENT)
                                .get()
                                .then((querySnapshot) => {
                                    studentUpdateStateFunc(querySnapshot)
                                })
                        }
                    })
            }

            db.collection(ColType.iep)
                .where('districtId', '==', this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.districtId)
                //.where('schoolId', '==', schoolId)
                //.where('teacherId', '==', teacherId)
                .where('studentId', '==', studentId)
                .where('folder', '==', FOLDER_CURRENT)
                .get()
                .then((querySnapshot) => {
                    var IEPGoals = []
                    var IEPDict = {}

                    querySnapshot.forEach((doc) => {
                        var iep = flattenDoc(doc)
                        IEPGoals.push(iep)
                        IEPDict[iep.id] = iep
                    })

                    this.setState({
                        IEPGoals: IEPGoals,
                        IEPDict: IEPDict,
                    })
                })
        }
    }

    // no customfooter as it needs the margin classes on layout and that has too much
    // margin top with teacherstudentreports margin.
    render() {
        return (
            <div>
                <div>
                    <Layout className="content relative layout-header-mt">
                        <Layout >
                            <Content>
                                <div>
                                    {this.state.schoolId &&
                                        this.state.student &&
                                        this.state.teacher &&
                                        this.state.teacherId &&
                                        this.state.studentId &&
                                        this.state.studentDict &&
                                        this.state.studentDict.hasOwnProperty(this.state.studentId) &&
                                        this.props.instructionalFacilitatorBaseProps.instructionalFacilitator &&
                                        this.props.instructionalFacilitatorBaseProps.district &&
                                        this.props.instructionalFacilitatorBaseProps.schoolsDict &&
                                        this.props.instructionalFacilitatorBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId)
                                        ?
                                        <div className="sub-menu-width-vlg m-lr-auto mt-3">
                                            <div className='flex'>
                                                <div style={{ width: '1024px', maxWidth: '1024px' }} className='mr-2 relative'>
                                                    <div>
                                                        <div className="w-100 flex flex-v-center mb-2 font-16 font-bold">
                                                            <Link to={'/instructional-facilitator/school/' + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + '?s=' + this.state.schoolId}>
                                                                {this.props.instructionalFacilitatorBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId) ?
                                                                    this.props.instructionalFacilitatorBaseProps.schoolsDict[this.state.schoolId].schoolName : ''}
                                                            </Link>
                                                            <Icon type="right" className="ml-1 mr-1 font-14" />
                                                            <Link to={'/instructional-facilitator/teacher/' + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + '?s=' + this.state.schoolId + '&t=' + this.state.teacher.id}>
                                                                {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}
                                                            </Link>
                                                            <Icon type="right" className="ml-1 mr-1 font-14" />
                                                            <span>
                                                                {this.state.studentDict[this.state.studentId].firstName + ' ' + this.state.studentDict[this.state.studentId].lastName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='br-20 overflow-hidden mb-3 mt-2'>
                                                        <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                            style={{ height: '280px', }}>

                                                            <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#333' }}>
                                                            </div>
                                                            <div className="absolute-tl w-100 h-100 image-box-mask"
                                                                style={{ backgroundImage: 'url(/student-banner.png)' }}
                                                            >
                                                            </div>
                                                            <div className='absolute-tl w-100 h-100'>
                                                                <div className='h-100'>
                                                                    <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                                                        <div className='w-100'>
                                                                            <div className='font-40 font-bold font-fff'>
                                                                                {this.state.studentDict[this.state.studentId].firstName + ' ' +
                                                                                    this.state.studentDict[this.state.studentId].lastName}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <Tabs size="large" animated={false} className="mt-1">
                                                                <TabPane tab={"Student Report"} key={"student-report"}>
                                                                    <div className='mt-2'>
                                                                        <TeacherStudentReport
                                                                            teacherBaseProps={this.state}
                                                                            showStudentNav={false}
                                                                            showFooter={false}
                                                                            noMarginTop={true}
                                                                            isAdmin={true}
                                                                        />
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab={"Tier One ELA Notes"} key={"ela-notes"}>
                                                                    <SubjectNoteDisplay
                                                                        teacherBaseProps={this.state}
                                                                        student={this.state.student}
                                                                        teacherId={this.state.teacherId}
                                                                        studentId={this.state.studentId}
                                                                        IEPId={'tier1'}
                                                                        subject={'ela'}
                                                                        isAdmin={true}
                                                                    />
                                                                </TabPane>
                                                                <TabPane tab={"Tier One Math Notes"} key={"math-notes"}>
                                                                    <SubjectNoteDisplay
                                                                        teacherBaseProps={this.state}
                                                                        student={this.state.student}
                                                                        teacherId={this.state.teacherId}
                                                                        studentId={this.state.studentId}
                                                                        IEPId={'tier1'}
                                                                        subject={'math'}
                                                                        isAdmin={true}
                                                                    />
                                                                </TabPane>
                                                                <TabPane tab={"Tier One SEL Notes"} key={"general-notes"}>
                                                                    <NoteDisplay
                                                                        teacherBaseProps={this.state}
                                                                        student={this.state.student}
                                                                        teacherId={this.state.teacherId}
                                                                        studentId={this.state.studentId}
                                                                        IEPId={'tier1'}
                                                                        isAdmin={true}
                                                                    />
                                                                </TabPane>
                                                                {/*
                                                                        <TabPane tab={"Uploads"} key={"upload-tab"}>
                                                                            <TeacherStudentUploadView
                                                                                teacherBaseProps={this.state}
                                                                                student={this.state.student}
                                                                                studentId={this.state.student.id}
                                                                                teacherId={this.state.teacherId}
                                                                                IEPId={'tier1'}
                                                                                isAdmin={true}
                                                                            />
                                                                        </TabPane>
                                                                        */}
                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='ml-2'>
                                                    <h1 className='font-24 font-bold mb-1'>Students</h1>
                                                    {this.state.students ?
                                                        <Select
                                                            showSearch
                                                            placeholder="Search for a student"
                                                            className='w-100 mb-2'
                                                            optionFilterProp="children"
                                                            onSelect={(studentId) => {
                                                                if (this.props.instructionalFacilitatorBaseProps.instructionalFacilitator && this.state.schoolId && this.state.teacherId && studentId) {
                                                                    this.props.history.push({
                                                                        pathname: "/instructional-facilitator/student/" + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + "?s=" + this.state.schoolId + '&t=' + this.state.teacherId + '&st=' + studentId
                                                                    })
                                                                }
                                                            }}
                                                            onSearch={(value) => {
                                                                console.log('search:', value)
                                                            }}

                                                            size="large"
                                                            filterOption={(input, option) => {
                                                                console.log('input', input)
                                                                if (input.length === 0) {
                                                                    return false
                                                                }
                                                                let person = option.props.children.props.person
                                                                let fullName = person.firstName + ' ' + person.lastName
                                                                return fullName.toLowerCase().includes(input.toLowerCase())
                                                            }}
                                                        >
                                                            {this.state.students &&
                                                                this.state.students.map((student, idx) => {
                                                                    return <Select.Option value={student.id} key={'search-' + student.id}>
                                                                        <PersonAvatar
                                                                            person={student}
                                                                            containerClassName="font-16"
                                                                            avatarClassName="font-16 font-bold mr-2"
                                                                            personClassName="font-black font-16"
                                                                        />
                                                                    </Select.Option>
                                                                })}
                                                        </Select>
                                                        : ''}
                                                    {this.state.students && this.state.students.length === 0 ?
                                                        <div className='font-16 font-bold mb-1'>No students</div>
                                                        : ''}
                                                    {this.state.students &&
                                                        this.state.students.map((student, idx) => {
                                                            return <Link to={"/instructional-facilitator/student/" + this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id + "?s=" + this.state.schoolId + '&t=' + this.state.teacherId + '&st=' + student.id}
                                                                id={student.id} key={student.id}>
                                                                <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative w-100 font-16"}>
                                                                    <PersonAvatar
                                                                        person={student}
                                                                        size={'large'}
                                                                        containerClassName="font-20"
                                                                        avatarClassName="font-20 font-bold mr-2"
                                                                        personClassName="font-black font-20 ellipsis"
                                                                        personStyle={{ maxWidth: '147px' }}
                                                                    />
                                                                    <StudentSummary student={student} />
                                                                </div>
                                                            </Link>
                                                        })}
                                                </div>
                                            </div>


                                        </div> : ''}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
                <CustomFooter />
            </div>
        )
    }
}

export default InstructionalFacilitatorStudentView