import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import { getIDFromURL } from '../../Util'
import { Layout, Row, Col } from 'antd'
import TeacherHelpDeskSideMenu from './TeacherHelpDeskSideMenu'
import TeacherHelpDeskContent from './TeacherHelpDeskContent'
const { Content } = Layout

class TeacherHelpDeskGettingStarted extends Component {
    state = {

    }

    componentDidMount() {
        document.title = 'Help Desk - Dot It'

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                <Row gutter={0} className="ml-0">
                                    <Col span={5}
                                        className="pl-0 pr-4">
                                        <TeacherHelpDeskSideMenu course={"Getting started with Dot It"} />
                                    </Col>

                                    <Col span={19}
                                        className="pl-0 pr-0">
                                        <TeacherHelpDeskContent
                                            videoURL={'https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/tutorialhome.webm?alt=media&token=b90a0d5e-a448-4773-b9f4-700fce93419f'}
                                            contentTitle={"Getting to know your home page"}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter teacherId={this.state.teacherId} />
            </div>
        )
    }
}

export default TeacherHelpDeskGettingStarted
