import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
//import PacingGuideSC from './PacingGuideSC'
import { Layout, Select, Icon, Tabs, Button, notification, Collapse } from 'antd'
//import PacingGuideAssessment from './PacingGuideAssessment'
//import ComponentFactory from '../edviz/ComponentFactory'
import { getQueryStringParam, flattenDoc, cleverStartQuiz, getQuarterAndWeekFromNum, shuffleArray } from '../../Util'
import { Link } from 'react-router-dom'
import { Document } from '../edvizv2/Edviz'
import PacingGuideUploads from './PacingGuideUploads'
import PacingGuideFeedback from './PacingGuideFeedback'
import PacingGuideAssessmentModalForm from './PacingGuideAssessmentModalForm'
const { Content } = Layout

class PacingGuideLessonPlanV2 extends Component {
    state = {
        lessonPlan: null,
        lessonPlanFound: true,
        fileURLDict: {},
        lessonPlanStandardNames: ['ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
            'ELA.6.AOR.1.2', 'ELA.C.1', 'ELA.K.F.1.4', '2.ATO.1', '3.ATO.1',
            '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1', 'K.NS.1', '3-ESS2-1',
            'B-LS1-5', 'USG.1.IP'],
        subjects: ['ELA', 'Math', 'Social Studies', 'Science'],
        lessonPlanStandardNamesSubjectDict: {
            'ELA': ['ELA.K.F.1.4', 'ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
                'ELA.6.AOR.1.2', 'ELA.C.1'],
            'Math': ['K.NS.1', '2.ATO.1', '3.ATO.1',
                '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1'],
            'Social Studies': ['USG.1.IP'],
            'Science': ['3-ESS2-1', 'B-LS1-5'],
        },
        standardName: '',
        standard: '',
        grade: '',
        category: '',
        startingQuiz: false,
        assessments: [],
        selectWeek: '',
        selectIdx: '',
        selectStandard: '',
        fromWeeklyPlanner: false,
        disabledQuizzesDict: {
            '5.NSBT.1': true,
            '6.NS.4.c': true,
            '7.NS.1.b': true,
            '7.NS.1.c': true,
            '8.GM.2.b': true,
            'A1.ASE.1*': true,
            'PS.SPCR.1.a': true,
        },
        reviewTheseQuizzesDict: {
            '7.NS.1.d': true,
        }
    }

    componentDidMount() {
        let standardName = getQueryStringParam('standardName')
        let standard = getQueryStringParam('standard')
        let grade = getQueryStringParam('grade')
        let category = getQueryStringParam('category')
        let quarter = getQueryStringParam('quarter')
        let week = getQueryStringParam('week')
        let standardIndex = getQueryStringParam('index')
        let fromWeeklyPlanner = getQueryStringParam('weeklyPlanner')
        this.setState({
            selectWeek: week,
            selectIdx: standardIndex,
            selectStandard: standard,
            fromWeeklyPlanner: fromWeeklyPlanner,
        })
        this.getData(standardName, standard, grade, category)

        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.setStandardNameAndCategoryFunc &&
            (this.props.pacingGuideBaseProps.standardName !== standardName ||
                this.props.pacingGuideBaseProps.standardCategory !== category)) {
            if (this.props.pacingGuideBaseProps.mounted) {
                this.props.pacingGuideBaseProps.setStandardNameAndCategoryFunc(
                    standardName, category
                )
            } else {
                // wait for base component to mount before calling func
                let baseMountedInterval = setInterval(() => {
                    if (this.props.pacingGuideBaseProps.mounted) {
                        this.props.pacingGuideBaseProps.setStandardNameAndCategoryFunc(
                            standardName, category
                        )
                        clearInterval(baseMountedInterval)
                    }
                }, 100)
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        let standardName = getQueryStringParam('standardName')
        let standard = getQueryStringParam('standard')
        let grade = getQueryStringParam('grade')
        let category = getQueryStringParam('category')
        //this.getData(standardName, standard, grade, category)
    }

    getData = (standardName, standard, grade, category) => {
        if (!(this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.hasOwnProperty('state') &&
            this.props.pacingGuideBaseProps.state &&
            standardName &&
            grade &&
            category)) {
            return
        }

        // already have this data
        if (standardName === this.state.standardName &&
            standard === this.state.standard &&
            grade === this.state.grade &&
            category === this.state.category) {
            return
        }

        this.setState({
            standardName: standardName,
            standard: standard,
            selectStandard: standard,
            grade: grade,
            category: category,
        })


        let docName = grade + '-' + category + '-' + standard
        docName = docName.replaceAll(' ', '_')
        console.log('docName', docName)
        /*db.collection(ColType.lessonPlans)
            .where('state', '==', this.props.pacingGuideBaseProps.state)
            .where('standard.standardName', '==', standardName)
            .where('standard.standardCategory', '==', category)
            .where('standard.standard', '==', standard)
            .limit(1)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0]*/
        db.collection(ColType.lessonPlanSC)
            .doc(docName)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let lessonPlan = flattenDoc(doc)
                    lessonPlan.docs = JSON.parse(lessonPlan.docs)

                    this.getFileURLDict(lessonPlan)

                    this.setState({
                        lessonPlan: lessonPlan,
                        lessonPlanFound: true,
                    })
                    console.log('lessonPlanSC', lessonPlan)
                } else {
                    console.log('lessonPlanSC doc does not exist')
                    this.setState({
                        lessonPlan: null,
                        lessonPlanFound: false,
                    })
                }
            })

    }

    downloadFile = (downloadURL) => {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = (event) => {
                var blob = xhr.response
                console.log('blob', blob)
                resolve(blob)
            }
            xhr.onerror = (event) => {
                console.error('error')
                reject()
            }
            xhr.open('GET', downloadURL)
            xhr.send()
        })
    }

    onLessonPlanStandardChange = (value) => {
        this.getData(this.state.standardName, value, this.state.grade, this.state.category)
    }

    onLessonPlanStandardChangeWeeks = (pValue) => {
        // split on ('--')
        let value = pValue.split('--')
        if (value.length !== 3) {
            return
        }
        const standardValue = value[1]
        this.setState({
            selectWeek: value[0],
            selectStandard: standardValue,
            selectIdx: value[2],
        })
        this.getData(this.state.standardName, standardValue, this.state.grade, this.state.category)
    }

    getFileURLDict = (lessonPlan) => {
        this.setState({ fileURLDict: {} })
        let fileURLDict = {}

        var storage = firebase.app().storage("gs://lessonplanpdfs")

        let promises = []
        if (lessonPlan) {
            lessonPlan.docs.map((doc, idx) => {
                if (!(doc.hasOwnProperty('metadata') &&
                    doc.metadata &&
                    doc.metadata.hasOwnProperty('uuid') &&
                    doc.metadata.uuid)) {
                    return false
                }
                if (!(doc.hasOwnProperty('metadata') &&
                    doc.metadata &&
                    doc.metadata.hasOwnProperty('docType') &&
                    doc.metadata.docType)) {
                    return false
                }

                promises.push(new Promise((resolve, reject) => {
                    var fileRef = storage.ref().child(doc.metadata.uuid + '.pdf')

                    fileRef.getDownloadURL().then((url) => {
                        //console.log('url', url)
                        let key = doc.metadata.docType
                        if (doc.metadata.docType === 'resource' ||
                            doc.metadata.docType === 'assessment') {
                            key = doc.metadata.uuid
                        }
                        fileURLDict[key] = url
                        resolve()
                    }).catch((error) => {
                        console.log('error', error)
                        reject(error)
                    })
                }))

                if (doc.metadata.docType === 'lesson_plan') {
                    promises.push(new Promise((resolve, reject) => {
                        var fileRef = storage.ref().child(doc.metadata.uuid + '_full.pdf')

                        fileRef.getDownloadURL().then((url) => {
                            //console.log('url', url)
                            fileURLDict['lesson_plan_full'] = url
                            resolve()
                        }).catch((error) => {
                            console.log('error', error)
                            reject(error)
                        })
                    }))
                }

                return false
            })
        }

        Promise.all(promises)
            .then(() => {
                //console.log('fileURLDict', fileURLDict)
                this.setState({ fileURLDict: fileURLDict })
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    startQuiz = (assessmentDocs) => {
        if (!this.props.teacherBaseProps.teacher) {
            console.log('startQuiz - teacher is null')
            return
        }
        if (!(this.state.lessonPlan &&
            this.state.lessonPlan.standard &&
            this.state.lessonPlan.standard.standard &&
            this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.standardsDict &&
            this.props.pacingGuideBaseProps.standardsDict.hasOwnProperty(this.state.lessonPlan.standard.standard) &&
            this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard])) {
            console.log('could not find pacing guide standard')
            return
        }

        let studentsWithCleverIds = []
        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.students) {
            studentsWithCleverIds = this.props.teacherBaseProps.students.filter((student) => {
                return student.hasOwnProperty('cleverId') && student.cleverId
            })
        }

        if (studentsWithCleverIds.length === 0) {
            console.log('startQuiz - no students with cleverIds')
            notification.open({
                type: 'error',
                duration: 4,
                message: 'You currently have no students from Clever. Please sync your students from Clever and try again.',
                placement: 'bottomRight'
            })
            return
        }

        this.setState({
            startingQuiz: true,
        })


        console.log(this.props.teacherBaseProps.teacher, studentsWithCleverIds, assessmentDocs,
            this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard])
        cleverStartQuiz(this.props.teacherBaseProps.teacher, studentsWithCleverIds, assessmentDocs,
            this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard])
            .then((result) => {
                if (result.hasOwnProperty('message') &&
                    result.message === 'Error during assessment creation') {
                    notification.open({
                        type: 'error',
                        duration: 4,
                        message: 'An error occurred while starting the quiz. Please try again.',
                        placement: 'bottomRight'
                    })
                } else {
                    notification.open({
                        type: 'success',
                        duration: 4,
                        message: 'Quiz started successfully',
                        placement: 'bottomRight'
                    })
                }

                this.setState({
                    startingQuiz: false,
                })
            })
            .catch(() => {
                notification.open({
                    type: 'error',
                    duration: 4,
                    message: 'An error occurred while starting the quiz. Please try again.',
                    placement: 'bottomRight'
                })

                this.setState({
                    startingQuiz: false,
                })
            })
    }

    render() {
        const componentContainerClassName = 'w-670 max-w-670 m-lr-auto'
        const pdfButtonClassName = 'ant-btn ant-btn-primary lg-btn inline-flex mr-1'
        const linkBack = this.state.fromWeeklyPlanner ? 'weekly-planner' : 'guide'
        const linkBackText = this.state.fromWeeklyPlanner ? 'Weekly Planner' : 'Pacing Guide'
        let person = null
        if (this.props.teacherBaseProps) {
            if (this.props.teacherBaseProps.teacher) {
                person = this.props.teacherBaseProps.teacher
            } else if (this.props.teacherBaseProps.admin) {
                person = this.props.teacherBaseProps.admin
            } else if (this.props.teacherBaseProps.schoolAdmin) {
                person = this.props.teacherBaseProps.schoolAdmin
            } else if (this.props.teacherBaseProps.instructionalFacilitator) {
                person = this.props.teacherBaseProps.instructionalFacilitator
            }
        }
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout className="mt-0">
                        <Content className="mt-0 sub-menu-width-vlg m-lr-auto" style={{ minWidth: '941px' }}>
                            {this.props.teacherBaseProps ?
                                <div className="mt-4">
                                    <div>
                                        <div className='flex flex-v-center w-100 mb-2 font-16'>
                                            {this.props.teacherBaseProps.teacher ?
                                                <Link to={'/teacher/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.teacher.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            {this.props.teacherBaseProps.admin ?
                                                <Link to={'/admin/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.admin.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            {this.props.teacherBaseProps.instructionalFacilitator ?
                                                <Link to={'/instructional-facilitator/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.instructionalFacilitator.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            {this.props.teacherBaseProps.schoolAdmin ?
                                                <Link to={'/school-admin/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.schoolAdmin.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            <Icon type='right' className='ml-1 mr-1 font-16' />
                                            <div>{this.state.standardName + ' - ' + this.state.standard}</div>

                                            <div className='flex flex-v-center ml-auto'>
                                                <div className='font-16 mr-1'>
                                                    View other standards:
                                                </div>
                                                {this.props.pacingGuideBaseProps &&
                                                    this.props.pacingGuideBaseProps.pacingGuide &&
                                                    this.props.pacingGuideBaseProps.pacingGuideHasData ?
                                                    <Select className='ml-auto' size='large' placeholder={'Select standard...'} value={
                                                        this.state.selectWeek && this.state.selectStandard && this.state.selectIdx ?
                                                            this.state.selectWeek + '--' + this.state.selectStandard + '--' + this.state.selectIdx :
                                                            undefined
                                                    }
                                                        onChange={this.onLessonPlanStandardChangeWeeks} style={{ width: '250px' }}>
                                                        {this.props.pacingGuideBaseProps &&
                                                            this.props.pacingGuideBaseProps.pacingGuide &&
                                                            this.props.pacingGuideBaseProps.weekNums &&
                                                            this.props.pacingGuideBaseProps.weekNums.map((weekNum, idx) => {
                                                                if (weekNum === 0) {
                                                                    return false
                                                                }
                                                                let quarterAndWeek = getQuarterAndWeekFromNum(weekNum)
                                                                const standards = this.props.pacingGuideBaseProps.pacingGuide.weeks[weekNum]
                                                                return <Select.OptGroup key={idx} label={'Quarter: ' + quarterAndWeek.quarter + ', Week ' + quarterAndWeek.week}>
                                                                    {standards.map((standard, standardIdx) => {
                                                                        return <Select.Option key={weekNum + '--' + standardIdx} value={weekNum + '--' + standard.standard + '--' + standardIdx}>
                                                                            {standard.standard}
                                                                        </Select.Option>
                                                                    })}
                                                                </Select.OptGroup>
                                                            })}
                                                    </Select>
                                                    :
                                                    <Select className='ml-auto' size='large' value={this.state.standard}
                                                        onChange={this.onLessonPlanStandardChange} style={{ width: '250px' }}>
                                                        {this.props.pacingGuideBaseProps &&
                                                            this.props.pacingGuideBaseProps.standards.map((standard, idx) => {
                                                                return <Select.Option key={standard.standard} value={standard.standard}>{standard.standard}</Select.Option>
                                                            })}
                                                    </Select>}
                                            </div>
                                        </div>

                                        <div className='relative mb-3'>
                                            <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                                style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                            <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                                <div>
                                                    <div className='font-40 font-bold font-fff'>{this.state.standardName + ' - ' + this.state.standard}</div>
                                                    <div className='font-24 font-bold font-fff'>{'Standard Lesson Plan and Assessment'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!this.state.lessonPlanFound ?
                                        <div style={{ width: '940px' }}>
                                            <div className='font-24 w-100'>
                                                This lesson plan is coming soon.
                                            </div>
                                        </div>
                                        : ''}
                                    {this.state.lessonPlan ?
                                        <div className='mt-4'>
                                            <Tabs tabPosition='left' size='large' defaultActiveKey="1" >
                                                <Tabs.TabPane tab="Standard" key="standard-tab">
                                                    <div className={componentContainerClassName}>
                                                        <div className='flex flex-v-center w-100 mb-3'>
                                                            {this.state.fileURLDict &&
                                                                this.state.fileURLDict.hasOwnProperty('lesson_plan_full') &&
                                                                this.state.fileURLDict['lesson_plan_full'] ?
                                                                <a
                                                                    href={this.state.fileURLDict['lesson_plan_full']}
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className={pdfButtonClassName}
                                                                >
                                                                    <Icon type='file-pdf' className='font-20 mr-1' />
                                                                    <div>{'View Full PDF'}</div>
                                                                </a>
                                                                : ''}
                                                            {this.state.lessonPlan && person ?
                                                                <PacingGuideFeedback
                                                                    person={person}
                                                                    standard={this.state.lessonPlan.standard}
                                                                    resourceName={'Standard'}
                                                                    doc={{ 'standard': this.state.lessonPlan.standard.standard }}
                                                                /> : ''}
                                                        </div>
                                                        <div className='font-20'>
                                                            <p><b>Subject:</b> {this.state.lessonPlan.standard.standardCategory}</p>
                                                            <p><b>Category:</b> {this.state.lessonPlan.standard.category}</p>
                                                            <p><b>Grade:</b> {this.state.lessonPlan.standard.grade}</p>
                                                            <p><b>Standard:</b> {this.state.lessonPlan.standard.standard}</p>
                                                            {this.state.lessonPlan &&
                                                                this.state.lessonPlan.standard &&
                                                                this.state.lessonPlan.standard.standard &&
                                                                this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.standardsDict &&
                                                                this.props.pacingGuideBaseProps.standardsDict.hasOwnProperty(this.state.lessonPlan.standard.standard) &&
                                                                this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard] &&
                                                                this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard].hasOwnProperty('isPriority') &&
                                                                this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard].isPriority ?
                                                                <p>*Priority Standard</p>
                                                                : ''}
                                                            <p>{this.state.lessonPlan.standard.title}</p>
                                                        </div>
                                                    </div>
                                                </Tabs.TabPane>

                                                {this.state.lessonPlan.docs.map((lessonPlanItem, idx) => {
                                                    const docType = 'lesson_plan'
                                                    if (lessonPlanItem.metadata.docType !== docType) {
                                                        return false
                                                    }
                                                    return <Tabs.TabPane tab="Lesson Plan" key="lesson-plan-tab">
                                                        <div className={componentContainerClassName}>
                                                            <div className='flex flex-v-center w-100 mb-3'>
                                                                {this.state.fileURLDict &&
                                                                    this.state.fileURLDict.hasOwnProperty(docType) &&
                                                                    this.state.fileURLDict[docType] ?
                                                                    <a
                                                                        href={this.state.fileURLDict[docType]}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className={pdfButtonClassName}
                                                                    >
                                                                        <Icon type='file-pdf' className='font-20 mr-1' />
                                                                        <div>{'View Lesson Plan PDF'}</div>
                                                                    </a>
                                                                    : ''}
                                                                {this.state.lessonPlan && person ?
                                                                    <PacingGuideFeedback
                                                                        person={person}
                                                                        standard={this.state.lessonPlan.standard}
                                                                        resourceName={'Lesson plan'}
                                                                        doc={lessonPlanItem}
                                                                    /> : ''}
                                                            </div>
                                                            <Document
                                                                components={lessonPlanItem.components}
                                                            />
                                                        </div>
                                                    </Tabs.TabPane>
                                                })}
                                                {this.state.lessonPlan.docs.map((lessonPlanItem, idx) => {
                                                    const docType = 'tier_one_differentiation'
                                                    if (lessonPlanItem.metadata.docType !== docType) {
                                                        return false
                                                    }
                                                    return <Tabs.TabPane tab="Tier 1 Differentiation" key="tier-one-differentiation-tab">
                                                        <div className={componentContainerClassName}>
                                                            <div className='flex flex-v-center w-100 mb-3'>

                                                                {this.state.fileURLDict &&
                                                                    this.state.fileURLDict.hasOwnProperty(docType) &&
                                                                    this.state.fileURLDict[docType] ?
                                                                    <a
                                                                        href={this.state.fileURLDict[docType]}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className={pdfButtonClassName}
                                                                    >
                                                                        <Icon type='file-pdf' className='font-20 mr-1' />
                                                                        <div>{'View Tier 1 Differentiation PDF'}</div>
                                                                    </a>
                                                                    : ''}
                                                                {this.state.lessonPlan && person ?
                                                                    <PacingGuideFeedback
                                                                        person={person}
                                                                        standard={this.state.lessonPlan.standard}
                                                                        resourceName={'Tier 1 Differentiation'}
                                                                        doc={lessonPlanItem}
                                                                    /> : ''}
                                                            </div>
                                                            <Document
                                                                components={lessonPlanItem.components}
                                                            />
                                                        </div>
                                                    </Tabs.TabPane>
                                                })}
                                                {this.state.lessonPlan.docs.filter(lessonPlanItem => lessonPlanItem.metadata.docType === 'resource')
                                                    .sort((a, b) => a.metadata.id > b.metadata.id).map((lessonPlanItem, idx) => {
                                                        if (lessonPlanItem.metadata.docType !== 'resource') {
                                                            return false
                                                        }
                                                        let resourceTitle = ''
                                                        if (lessonPlanItem.components.length > 0 &&
                                                            lessonPlanItem.components[0].hasOwnProperty('type') &&
                                                            lessonPlanItem.components[0].type === 'H1') {
                                                            resourceTitle = lessonPlanItem.components[0].heading
                                                        }
                                                        let docUUID = ''
                                                        if (lessonPlanItem.hasOwnProperty('metadata') &&
                                                            lessonPlanItem.metadata &&
                                                            lessonPlanItem.metadata.hasOwnProperty('uuid') &&
                                                            lessonPlanItem.metadata.uuid) {
                                                            docUUID = lessonPlanItem.metadata.uuid
                                                        }
                                                        return <Tabs.TabPane tab={<div className='mr-0 text-right'>
                                                            <div>{'Resource ' + (idx + 1) + ':'}</div>
                                                            <div className='ellipsis' style={{ maxWidth: '200px' }}>{resourceTitle}</div>
                                                        </div>} key={"resource-" + (idx + 1) + "-tab"}>
                                                            <div className={componentContainerClassName}>
                                                                <div className='flex flex-v-center w-100 mb-3'>
                                                                    {this.state.fileURLDict &&
                                                                        docUUID !== '' &&
                                                                        this.state.fileURLDict.hasOwnProperty(docUUID) &&
                                                                        this.state.fileURLDict[docUUID] ?
                                                                        <a
                                                                            href={this.state.fileURLDict[docUUID]}
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            className={pdfButtonClassName}
                                                                        >
                                                                            <Icon type='file-pdf' className='font-20 mr-1' />
                                                                            <div>{'View Resource PDF'}</div>
                                                                        </a>
                                                                        : ''}
                                                                    {this.state.lessonPlan && person ?
                                                                        <PacingGuideFeedback
                                                                            person={person}
                                                                            standard={this.state.lessonPlan.standard}
                                                                            resourceName={resourceTitle}
                                                                            doc={lessonPlanItem}
                                                                        /> : ''}
                                                                </div>
                                                                <Document
                                                                    components={lessonPlanItem.components}
                                                                />
                                                            </div>
                                                        </Tabs.TabPane>
                                                    })}
                                                <Tabs.TabPane tab="District Resources" key="district-resources-tab">
                                                    <div className={componentContainerClassName}>
                                                        {this.props.teacherBaseProps &&
                                                            this.props.teacherBaseProps.district &&
                                                            this.state.standard &&
                                                            this.state.category &&
                                                            person ?
                                                            <PacingGuideUploads
                                                                districtId={this.props.teacherBaseProps.district.id}
                                                                standard={this.state.standard}
                                                                standardCategory={this.state.category}
                                                                personId={person.id}
                                                            /> : ''}
                                                    </div>
                                                </Tabs.TabPane>
                                                {this.state.assessments.length > 0 &&
                                                    this.state.standardName !== '' &&
                                                    this.state.standardName !== 'Kindergarten' &&
                                                    this.state.standardName !== '1st Grade' ?
                                                    <Tabs.TabPane tab="Quiz" key="quiz-tab">
                                                        <div className={componentContainerClassName}>
                                                            <div className='mb-2 flex flex-v-center w-100'>
                                                                <div className='font-24 font-bold'>Quizzes</div>
                                                            </div>

                                                            <div>
                                                                {/*<Button size='large' type='primary'
                                                                                    className='mb-2 ant-btn ant-btn-primary lg-btn text-align-center font-bold'
                                                                                    disabled={true}
                                                                                    onClick={() => {
                                                                                        this.startQuiz(assessment.docs)
                                                                                    }}>
                                                                                    {this.state.startingQuiz ? 'Starting quiz...' : 'Start Quiz (Coming soon)'}
                                                                                </Button>*/}
                                                                <Collapse bordered={false}>
                                                                    {this.state.assessments.map((assessment, idx) => {
                                                                        let disableQuiz = false
                                                                        // this.state.lessonPlan.standard.standardCategory === 'Math' ||
                                                                        if (this.state.lessonPlan &&
                                                                            (this.state.disabledQuizzesDict.hasOwnProperty(this.state.lessonPlan.standard.standard) ||
                                                                                this.state.lessonPlan.standard.standardName === 'Kindergarten' ||
                                                                                this.state.lessonPlan.standard.standardName === '1st Grade')) {
                                                                            disableQuiz = true
                                                                        }
                                                                        let isKindergartenOrFirstGrade = false
                                                                        if (this.state.lessonPlan &&
                                                                            (this.state.lessonPlan.standard.standardName === 'Kindergarten' ||
                                                                                this.state.lessonPlan.standard.standardName === '1st Grade')) {
                                                                            isKindergartenOrFirstGrade = true
                                                                        }
                                                                        return <Collapse.Panel header={<div className='font-20'>{'Quiz #' + (idx + 1)}</div>} key={'quiz-panel-' + idx} className='font-black'>
                                                                            <div className='pt-2'>
                                                                                {this.state.lessonPlan &&
                                                                                    this.state.disabledQuizzesDict.hasOwnProperty(this.state.lessonPlan.standard.standard) ?
                                                                                    <div className='font-20 mb-05'>This quiz is currently under development and will be available soon.</div>
                                                                                    : ''}
                                                                                {isKindergartenOrFirstGrade ?
                                                                                    <div className='font-20 mb-05'>Kindergarten and 1st Grade quizzes are coming soon.</div>
                                                                                    : ''}
                                                                                <div className='flex flex-v-center w-100'>
                                                                                    {this.props.teacherBaseProps &&
                                                                                        this.props.teacherBaseProps.teacher ?
                                                                                        <PacingGuideAssessmentModalForm
                                                                                            assessment={assessment}
                                                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                                                            disableQuiz={disableQuiz}
                                                                                        />
                                                                                        : ''}

                                                                                    {this.state.lessonPlan && person ?
                                                                                        <div className={this.props.teacherBaseProps &&
                                                                                            this.props.teacherBaseProps.teacher ? 'ml-1' : ''
                                                                                        }>
                                                                                            <PacingGuideFeedback
                                                                                                person={person}
                                                                                                standard={this.state.lessonPlan.standard}
                                                                                                resourceName={'Quiz #' + (idx + 1)}
                                                                                                doc={{ 'quizNumber': idx, 'quiz': JSON.stringify(assessment) }}
                                                                                                disableFeedback={isKindergartenOrFirstGrade}
                                                                                            /></div> : ''}
                                                                                </div>
                                                                                {!isKindergartenOrFirstGrade && assessment.docs && assessment.docs.map((lessonPlanItem, idx) => {
                                                                                    return <div className={'font-black font-20 border ant-shadow br-4 p-3 mb-50' + (idx === 0 ? ' mt-2' : '')}
                                                                                        key={'assessment-idx-' + idx}>
                                                                                        <div className='font-24'>Question {idx + 1} - (DOK {lessonPlanItem.metadata.dokLevel})</div>
                                                                                        <Document
                                                                                            components={lessonPlanItem.components}
                                                                                        />
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                    })}
                                                                </Collapse>
                                                            </div>

                                                        </div>
                                                    </Tabs.TabPane> : ''}
                                            </Tabs>
                                        </div> : ''}
                                </div> : ''}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default PacingGuideLessonPlanV2
