import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { Route } from 'react-router-dom'
import { firebase, db } from '../firebase/Firebase'
import InstructionalFacilitatorHeader from '../login/InstructionalFacilitatorHeader'
import InstructionalFacilitatorHome from './InstructionalFacilitatorHome'
import InstructionalFacilitatorSchool from './InstructionalFacilitatorSchool'
import InstructionalFacilitatorSchools from './InstructionalFacilitatorSchools'
import InstructionalFacilitatorSchoolSummary from './InstructionalFacilitatorSchoolSummary'
import InstructionalFacilitatorTeacherView from './InstructionalFacilitatorTeacherView'
import InstructionalFacilitatorStudentView from './InstructionalFacilitatorStudentView'
import InstructionalFacilitatorObservationChecklist
  from './InstructionalFacilitatorObservationChecklist'
import InstructionalFacilitatorChatHome from './InstructionalFacilitatorChatHome'
import InstructionalFacilitatorAssessmentAnalytics from './InstructionalFacilitatorAssessmentAnalytics'
import PacingGuide from '../teacher/pacingguide/PacingGuide'
import QuizCreator from './QuizCreator'
import { flattenDoc, getURLSplitArray, doesChatNotificationDictHaveNotifications, getQueryStringParam } from '../Util'
import ColType from '../Types'
import { Layout } from 'antd'
import WorkshopObservationToolBase from '../admin/WorkshopObservationToolBase'

const PacingGuideComponent = (props, state) => {
  return <PacingGuide teacherBaseProps={state} {...props} />
}

const InstructionalFacilitatorHomeComponent = (props, state) => {
  return <InstructionalFacilitatorHome instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorSchoolsComponent = (props, state) => {
  return <InstructionalFacilitatorSchools instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorSchoolComponent = (props, state) => {
  return <InstructionalFacilitatorSchool instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorSchoolSummaryComponent = (props, state) => {
  return <InstructionalFacilitatorSchoolSummary
    instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorTeacherViewComponent = (props, state) => {
  return <InstructionalFacilitatorTeacherView
    instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorStudentViewComponent = (props, state) => {
  return <InstructionalFacilitatorStudentView
    instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorObservationChecklistComponent = (props, state) => {
  return <InstructionalFacilitatorObservationChecklist
    instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorChatHomeComponent = (props, state) => {
  return <InstructionalFacilitatorChatHome
    instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorAssessmentAnalyticsComponent = (props, state) => {
  return <InstructionalFacilitatorAssessmentAnalytics
    instructionalFacilitatorBaseProps={state} {...props} />
}

const InstructionalFacilitatorQuizCreatorComponent = (props, state) => {
  return state.district ?
    <QuizCreator
      instructionalFacilitatorBaseProps={state} {...props}
      // props just for quiz creator
      districtId={state.district.id}
      state={'SC'} //state.district.state}
    /> : ''
}

const WorkshopObservationToolBaseComponent = (props, state) => {
  let districtId = ''
  let schoolId = ''
  let authorId = ''
  if (state.district &&
    state.schools &&
    state.schools.length > 0 &&
    state.instructionalFacilitator) {
    districtId = state.district.id
    // use first school for now, queries should
    // use formData.school instead of schoolId
    schoolId = state.schools[0].id
    authorId = state.instructionalFacilitator.id
  }

  return <WorkshopObservationToolBase instructionalFacilitatorBaseProps={state} {...props}
    districtId={districtId}
    schoolId={schoolId}
    authorId={authorId}
    authorType={ColType.instructionalFacilitator}
  />
}

class InstructionalFacilitatorBase extends Component {
  state = {
    instructionalFacilitatorId: '',
    instructionalFacilitator: null,
    schools: null,
    schoolsDict: null,
    schoolTypes: null,
    allStatOptionsDict: {},
    loadingData: true,
    pathId: '',
    headerKey: '',
    setCurrentlyViewingChatIdFunc: null,
    districtSettings: null,
    chatNotificationDict: {},
    hasChatNotifications: false,
    authListener: null,
    districtListener: null,
    chatNotificationListener: null,
    districtSettingsListener: null,
    demoToken: null,
    user: null,
  }

  setCurrentlyViewingChatId = (chatId) => {
    this.setState({
      currentlyViewingChatId: chatId
    })
  }

  // Do fetch here
  componentDidMount() {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]
    var instructionalFacilitatorId = split[split.length - 1]
    let demoToken = getQueryStringParam('dt')

    this.setState({ demoToken: demoToken })

    /*
    const expectedPathLen = 6
    if (split.length > expectedPathLen) {
      const diff = split.length - expectedPathLen
      pathId = split[split.length - 2 - diff]
      instructionalFacilitatorId = split[split.length - 1 - diff]
    }
    */

    this.setState({
      setCurrentlyViewingChatIdFunc: this.setCurrentlyViewingChatId
    })

    let authListener = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // No user is signed in.
        this.props.history.push(
          {
            pathname: '/sign-in/'
          }
        )
      } else {
        db.collection(ColType.users)
          .doc(user.uid)
          .get()
          .then((doc) => {
            let userObj = flattenDoc(doc)
            //console.log('userObj', userObj)
            this.setState({
              user: userObj,
            }, () => {
              if (this.state.user &&
                this.state.user.hasOwnProperty('isLocked') &&
                this.state.user.isLocked) {
                this.props.history.push(
                  {
                    pathname: '/account-is-locked'
                  }
                )
              }
            });
          })
          .catch((error) => {
            console.log('error getting user', error)
          })
      }
    })
    this.setState({
      authListener: authListener
    })

    db.collection(ColType.instructionalFacilitator)
      .doc(instructionalFacilitatorId)
      .get()
      .then((doc) => {
        var instructionalFacilitator = flattenDoc(doc)
        //console.log('instructional facilitator', instructionalFacilitator)
        this.setState({
          pathId: pathId,
          instructionalFacilitatorId: instructionalFacilitatorId,
          instructionalFacilitator: instructionalFacilitator,
        }, () => {
          let districtListener = db.collection(ColType.district)
            .doc(instructionalFacilitator.districtId)
            .onSnapshot((doc) => {
              var district = flattenDoc(doc)

              this.setState({
                district: district,
              })
            })

          // Unsubscribe from previous listener (should never happen)
          if (this.state.districtListener) {
            this.state.districtListener()
          }
          this.setState({
            districtListener: districtListener
          })

          if (this.state.districtSettingsListener) {
            this.state.districtSettingsListener()
          }
          let districtSettingsListener = db.collection(ColType.districtSettings)
            .doc(instructionalFacilitator.districtId)
            .onSnapshot((doc) => {
              if (!doc.exists) {
                this.setState({
                  districtSettings: {
                    canReview: {
                      3: true,
                    },
                  },
                })
                return
              }
              var districtSettings = flattenDoc(doc)
              this.setState({
                districtSettings: districtSettings,
              })
            })
          this.setState({
            districtSettingsListener: districtSettingsListener
          })

          var schools = []
          var schoolsDict = {}
          var schoolTypes = {
            preschool: [],
            elementarySchool: [],
            k8School: [],
            middleSchool: [],
            highSchool: [],
          }

          this.state.instructionalFacilitator.schoolIds.map((schoolId, index) => {
            return db.collection(ColType.school)
              .doc(schoolId)
              .get()
              .then((doc) => {
                var school = flattenDoc(doc)
                //console.log('school', school)
                schools.push(school)
                schoolsDict[school.id] = school
                if (!schoolTypes.hasOwnProperty(school.schoolType)) {
                  schoolTypes[school.schoolType] = []
                }
                schoolTypes[school.schoolType].push(school)

                schools.sort((a, b) => {
                  if (a.hasOwnProperty('schoolType') &&
                    b.hasOwnProperty('schoolType')) {
                    return this.getSchoolTypeSortWeight(a.schoolType) - this.getSchoolTypeSortWeight(b.schoolType)
                  }

                  return 0
                })

                this.setState({
                  schools: schools,
                  schoolsDict: schoolsDict,
                  schoolTypes: schoolTypes,
                })
              })
          })

          db.collection(ColType.schoolStats)
            .where("schoolId", "in", instructionalFacilitator.schoolIds)
            .get()
            .then((querySnapshot) => {
              var allStatOptionsDict = this.state.allStatOptionsDict

              querySnapshot.forEach((doc) => {
                var schoolStatsDoc = flattenDoc(doc)
                allStatOptionsDict[schoolStatsDoc.id] = schoolStatsDoc
              })

              this.setState({
                allStatOptionsDict: allStatOptionsDict,
              })
            })

        })
      })

    var chatNotificationListener = db.collection(ColType.chatNotification)
      .doc(instructionalFacilitatorId)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          return
        }
        let chatNotificationDict = flattenDoc(doc)

        this.setState({
          chatNotificationDict: chatNotificationDict,
          hasChatNotifications: doesChatNotificationDictHaveNotifications(chatNotificationDict, this.state.currentlyViewingChatId)
        })
      })

    this.setState({
      chatNotificationListener: chatNotificationListener
    })
  }

  componentWillUnmount() {
    if (this.state.authListener) {
      this.state.authListener()
    }
    if (this.state.chatNotificationListener) {
      this.state.chatNotificationListener()
    }
    if (this.state.districtListener) {
      this.state.districtListener()
    }
    if (this.state.districtSettingsListener) {
      this.state.districtSettingsListener()
    }
  }

  getSchoolTypeSortWeight = (schoolType) => {
    if (!schoolType) {
      return -1
    }
    if (schoolType === 'elementarySchool') {
      return 0
    } else if (schoolType === 'k8School') {
      return 1
    } else if (schoolType === 'middleSchool') {
      return 2
    } else if (schoolType === 'highSchool') {
      return 3
    }

    return 0
  }

  componentWillReceiveProps(props, newProps) {
    var split = getURLSplitArray(window.location)
    var pathId = split[split.length - 2]

    this.setState({
      pathId: pathId,
    })
  }

  render() {
    const { match: { url } } = this.props

    return (
      <Layout>
        <InstructionalFacilitatorHeader
          instructionalFacilitatorBaseProps={this.state}
          person={this.state.instructionalFacilitator}
          selectedKey={this.state.pathId}
          history={this.props.history}
        />

        <Route
          path={`${url}/pacing-guide/`}
          render={props => PacingGuideComponent(props, this.state)}
        />
        <Route
          path={`${url}/instructional-facilitator-home/*`}
          render={props => InstructionalFacilitatorHomeComponent(props, this.state)}
        />
        <Route
          path={`${url}/school/*`}
          render={props => InstructionalFacilitatorSchoolComponent(props, this.state)}
        />
        <Route
          path={`${url}/schools/*`}
          render={props => InstructionalFacilitatorSchoolsComponent(props, this.state)}
        />
        <Route
          path={`${url}/teacher/*`}
          render={props => InstructionalFacilitatorTeacherViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/student/*`}
          render={props => InstructionalFacilitatorStudentViewComponent(props, this.state)}
        />
        <Route
          path={`${url}/school-summary/*`}
          render={props => InstructionalFacilitatorSchoolSummaryComponent(props, this.state)}
        />
        <Route
          path={`${url}/observation-checklist/*`}
          render={props =>
            InstructionalFacilitatorObservationChecklistComponent(props, this.state)}
        />
        <Route
          path={`${url}/instructional-facilitator-workshop-observation-tool/`}
          render={props => WorkshopObservationToolBaseComponent(props, this.state)}
        />
        <Route
          path={`${url}/teams/*`}
          render={props =>
            InstructionalFacilitatorChatHomeComponent(props, this.state)}
        />
        <Route
          path={`${url}/assessments/*`}
          render={props =>
            InstructionalFacilitatorAssessmentAnalyticsComponent(props, this.state)}
        />
        <Route
          path={`${url}/test-create-assessment/*`}
          render={props =>
            InstructionalFacilitatorQuizCreatorComponent(props, this.state)}
        />

      </Layout>
    )
  }
}

export default InstructionalFacilitatorBase