import React, { Component } from 'react';
import { Form, Radio, Icon, Checkbox, Row, Col, Input, DatePicker, Layout, Spin, Select } from 'antd';
import { getQueryStringParam } from '../Util';
import { db, firebase } from '../firebase/Firebase';
import ColType from '../Types'
import { PDFDownloadLink, Document, Page, View, Text, Font, Image } from '@react-pdf/renderer'
import quicksand from '../fonts/quicksand-medium.ttf'
import quicksandBold from '../fonts/Quicksand-Bold.ttf'
import mplusFont from '../fonts/MPLUSRounded1c-Regular.ttf'
const moment = require('moment');
const Content = Layout.Content;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;

Font.register({
  family: 'Quicksand',
  format: "truetype",
  src: quicksand,
});

Font.register({
  family: 'QuicksandBold',
  format: "truetype",
  src: quicksandBold,
});

Font.register({
  family: 'Mplus',
  format: "truetype",
  src: mplusFont,
});

let onFormValuesChangeOuter = null;

class TeacherObservationForm extends Component {
  state = {
    workshopDocumentId: '',
    formItems: [
      'Posted where students and teacher can reference it during the lesson',
      'Based on grade-level standard indicator(s)',
      'Focuses attention on relevant or important content',
      'Requires students to synthesize information in order to fully answer',
      'Short, focused, planned to be no longer than 10 min.',
      'Connection to readers/writers/researchers and previous learning: “I noticed…”',
      'Focused teaching point',
      'Teacher modeling with think aloud',
      'Generates thinking and questioning',
      'Uses gradual release structure',
      'Teacher articulates look-fors: What will I see & hear to tell me my lesson was successful?',
      'Students notice, name, and explain what they will do',
      'Teacher reminds students that no help is available for the next 4-8 minutes',
      'Teacher uses look-for data to pull together students with similar needs for additional instruction',
      'Teacher asks Generative Questions to find out what students do and do not understand',
      'Teacher provides focused, targeted instruction',
      'Teacher observes students for look-fors (without helping) to ensure student take-over',
      'Take notes on relevant sections of text or talk',
      'Notes are in own words (notes reflect what the student understands)',
      'Explain, elaborate, and ask one another questions',
      'Identify partial understandings, misunderstandings, and missing information',
      'Focused on explanation and evidence from today’s Focus Question(s)',
      'Write for at least 10 minutes; write the whole time',
      'Silent environment',
      'Teacher reflects and records noticings/data from workshop',
    ],
    headersGroup1: [
      'Focus Question',
      'Content and/or Process Mini-lesson',
      'Handover/Look-fors',
      'Small Group Instruction',
    ],
    headersGroup2: [
      'Taking Notes/ Talking from Notes',
      'Nonfiction Writing'
    ],
    initialFormSet: false,
    initialFormInterval: null,
    inputUpdateTimeoutsDict: {},
    saveStatus: 'none',
  }

  componentDidMount() {
    onFormValuesChangeOuter = this.onFormValuesChange

    let workshopDocumentId = getQueryStringParam('document')
    this.setState({
      workshopDocumentId: workshopDocumentId
    })

    let initialFormInterval = setInterval(() => {
      const initialFormSet = this.setInitialFormValues(this.props)
      if (initialFormSet) {
        clearInterval(initialFormInterval)
      }
    }, 50)
    this.setState({
      initialFormInterval: initialFormInterval
    })
  }

  componentWillUnmount() {
    if (this.state.initialFormInterval) {
      clearInterval(this.state.initialFormInterval)
    }
  }

  setInitialFormValues = (props) => {
    if (this.state.initialFormSet) {
      return false
    }
    if (this.state.workshopDocumentId === '') {
      return false
    }

    if (this.state.workshopDocumentId &&
      props.workshopObservationBaseProps &&
      props.workshopObservationBaseProps.workshopObservationsDict &&
      props.workshopObservationBaseProps.workshopObservationsDict.hasOwnProperty(this.state.workshopDocumentId)) {
      let formData = props.workshopObservationBaseProps.workshopObservationsDict[this.state.workshopDocumentId].formData
      if (formData.hasOwnProperty('date')) {
        formData.date = moment(formData.date)
      }
      this.setState({
        initialFormSet: true
      }, () => {
        props.form.setFieldsValue(formData)
      })

      return true
    }

    return false
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  onFormValuesChange = (pChangedValues, allValues) => {
    if (!this.state.workshopDocumentId) {
      console.log('missing workshopDocumentId')
      return
    }
    // initial form set values will provide a change list of
    // all form items, so we can ignore this initial change
    if (Object.keys(pChangedValues).length !== 1) {
      return
    }
    const pChangeKey = Object.keys(pChangedValues)[0]

    // date is automatically converted to a string by JSON.parse/JSON.stringify
    let changedValues = JSON.parse(JSON.stringify(pChangedValues))

    // change all keys to formData.key for firestore to just update this value
    Object.keys(changedValues).forEach((key) => {
      changedValues['formData.' + key] = changedValues[key]
      delete changedValues[key]
    })

    // these are the keys that have inputs or textareas
    let timeoutDuration = 1
    if (pChangeKey === 'teacher_subject' ||
      pChangeKey === 'observation_time' ||
      pChangeKey.includes('evidence')) {
      timeoutDuration = 1000
    }

    changedValues.lastSave = firebase.firestore.FieldValue.serverTimestamp()

    if (this.state.inputUpdateTimeoutsDict.hasOwnProperty(pChangeKey)) {
      clearTimeout(this.state.inputUpdateTimeoutsDict[pChangeKey])
    }

    let timeoutVal = setTimeout(() => {
      this.setState({
        'saveStatus': 'saving'
      })
      db.collection(ColType.workshopObservationTool)
        .doc(this.state.workshopDocumentId)
        .update(changedValues)
        .then(() => {
          //console.log('Document successfully updated!');
          this.setState({
            'saveStatus': 'none'
          })
        })
        .catch((error) => {
          console.log('Error updating document: ', error);
          this.setState({
            'saveStatus': 'none'
          })
        })
    }, timeoutDuration)

    let inputUpdateTimeoutsDict = this.state.inputUpdateTimeoutsDict
    inputUpdateTimeoutsDict[pChangeKey] = timeoutVal
    this.setState({
      inputUpdateTimeoutsDict: inputUpdateTimeoutsDict
    })
  }

  tryGetFormDataValue = (formData, key) => {
    if (formData.hasOwnProperty(key)) {
      if (key === 'date') {
        return formData[key].format('MM/DD/YYYY')
      }
      if (key === 'school') {
        if (!(this.props.workshopObservationBaseProps &&
          this.props.workshopObservationBaseProps.schoolsDict &&
          this.props.workshopObservationBaseProps.schoolsDict.hasOwnProperty(formData[key]))) {
          return ''
        }
        return this.props.workshopObservationBaseProps.schoolsDict[formData[key]].schoolName
      }
      if (key === 'observed') {
        if (formData[key] === 'entire_workshop') {
          return 'Entire Workshop'
        } else {
          // capitalize first letter
          return formData[key].charAt(0).toUpperCase() + formData[key].slice(1)
        }
      }
      return formData[key]
    }
    return ''
  }

  getPDFDocument = () => {
    const formData = this.props.form.getFieldsValue()
    return (
      <Document style={{ fontFamily: 'Quicksand' }}>
        <Page size="A4" style={{ padding: 16 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', alignItems: 'flex-end', marginBottom: 16 }}>
            <Image src={'/district-logos/clarendonsc-logo.png'}
              style={{ width: '130px', height: '124px' }} />
            <View style={{ marginLeft: 16, paddingBottom: 4 }}>
              <Text style={{ fontSize: '24px', fontFamily: 'QuicksandBold' }}>Workshop Observation Tool</Text>
              <Text style={{ fontSize: '20px', fontFamily: 'QuicksandBold' }}>2023-2024</Text>
            </View>
          </View>
          <View>
            <View style={{ flexDirection: 'row', flexWrap: 'no-wrap' }}>
              <View style={{ width: '30%', padding: 8 }}>
                <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Teacher/Subject:</Text>
                <Text style={{ fontSize: '16px' }}>{this.tryGetFormDataValue(formData, 'teacher_subject')}</Text>
              </View>
              <View style={{ width: '40%', padding: 8 }}>
                <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>School:</Text>
                <Text style={{ fontSize: '16px' }}>{this.tryGetFormDataValue(formData, 'school')}</Text>
              </View>
              <View style={{ width: '30%', padding: 8 }}>
                <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Date:</Text>
                <Text style={{ fontSize: '16px' }}>{this.tryGetFormDataValue(formData, 'date')}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row', flexWrap: 'no-wrap' }}>
              <View style={{ width: '50%', padding: 8 }}>
                <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Observation Time:</Text>
                <Text style={{ fontSize: '16px' }}>{this.tryGetFormDataValue(formData, 'observation_time')}</Text>
              </View>
              <View style={{ width: '50%', padding: 8 }}>
                <Text style={{ fontSize: '14px', fontFamily: 'QuicksandBold' }}>Observed:</Text>
                <Text style={{ fontSize: '16px' }}>{this.tryGetFormDataValue(formData, 'observed')}</Text>
              </View>
            </View>

            <View style={{ border: '1px solid #000' }}>
              <View style={{ flexDirection: 'row', flexWrap: 'no-wrap', borderBottom: '1px solid #000' }}>
                <View style={{ width: '10%', padding: 8 }}>
                  <View>
                    <Text style={{ fontSize: '16px', fontFamily: 'Quicksand' }}>{'Evident'}</Text>
                  </View>
                </View>
                <View style={{ width: '10%', padding: 8 }}>
                  <View>
                    <Text style={{ fontSize: '16px', fontFamily: 'Quicksand' }}>{'Not Evident'}</Text>
                  </View>
                </View>
                <View style={{ width: '10%', padding: 8 }}>
                  <View>
                    <Text style={{ fontSize: '16px', fontFamily: 'Quicksand' }}>{'N/A'}</Text>
                  </View>
                </View>
                <View style={{ width: '70%', padding: 8, textAlign: 'center' }}>
                  <Text style={{ fontSize: '24px', fontFamily: 'QuicksandBold' }}>Expectation</Text>
                </View>
              </View>
              {this.state.headersGroup1.map((header, headerIdx) => {
                return <View>
                  <View style={{ flexDirection: 'row', flexWrap: 'no-wrap' }}>
                    <View style={{ width: '10%', padding: 8 }}>
                      <View>
                      </View>
                    </View>
                    <View style={{ width: '10%', padding: 8 }}>
                      <View>
                      </View>
                    </View>
                    <View style={{ width: '10%', padding: 8 }}>
                      <View>
                      </View>
                    </View>
                    <View style={{ width: '70%', padding: 8, paddingBottom: 0 }}>
                      <Text style={{ fontSize: '20px', fontFamily: 'QuicksandBold' }}>{header}</Text>
                    </View>
                  </View>
                  {this.state.formItems.map((item, index) => {
                    const formKey = `observationItem${index}`
                    if (headerIdx === 0 && index > 3) {
                      return false
                    } else if (headerIdx === 1 && (index < 4 || index > 9)) {
                      return false
                    } else if (headerIdx === 2 && (index < 10 || index > 12)) {
                      return false
                    } else if (headerIdx === 3 && (index < 13 || index > 16)) {
                      return false
                    }
                    return <View>
                      <View style={{ flexDirection: 'row', flexWrap: 'no-wrap' }}>
                        <View style={{ width: '10%', padding: 8 }}>
                          <View style={{ positoin: 'relative', backgroundColor: '#ccc', border: '1px solid #000', borderRadius: '4px', width: '40px', height: '40px' }}>
                            {formData.hasOwnProperty(formKey) && formData[formKey] === 'evident' ?
                              <Text style={{ fontFamily: 'Mplus', fontSize: '30px', 'position': 'absolute', 'top': '-1px', 'left': '2px' }}>✓</Text>
                              : <Text></Text>}
                          </View>
                        </View>
                        <View style={{ width: '10%', padding: 8 }}>
                          <View style={{ positoin: 'relative', backgroundColor: '#ccc', border: '1px solid #000', borderRadius: '4px', width: '40px', height: '40px' }}>
                            {formData.hasOwnProperty(formKey) && formData[formKey] === 'notevident' ?
                              <Text style={{ fontFamily: 'Mplus', fontSize: '30px', 'position': 'absolute', 'top': '-1px', 'left': '2px' }}>✓</Text>
                              : <Text></Text>}
                          </View>
                        </View>
                        <View style={{ width: '10%', padding: 8 }}>
                          <View style={{ positoin: 'relative', backgroundColor: '#ccc', border: '1px solid #000', borderRadius: '4px', width: '40px', height: '40px' }}>
                            {formData.hasOwnProperty(formKey) && formData[formKey] === 'na' ?
                              <Text style={{ fontFamily: 'Mplus', fontSize: '30px', 'position': 'absolute', 'top': '-1px', 'left': '2px' }}>✓</Text>
                              : <Text></Text>}
                          </View>
                        </View>
                        <View style={{ width: '70%', padding: 8 }}>
                          <Text style={{ fontSize: '16px', fontFamily: 'Quicksand' }}>{item}</Text>
                        </View>
                      </View>
                    </View>
                  })}
                  <View style={{ padding: '8px', borderBottom: '1px solid #000', borderTop: '1px solid #000' }}>
                    <Text style={{ fontSize: '16px', fontFamily: 'QuicksandBold', width: '100%', }}>
                      Evidence:
                    </Text>
                    <Text style={{ fontSize: '16px', fontFamily: 'Quicksand', width: '100%' }}>
                      {formData.hasOwnProperty(`evidence${headerIdx}`) ? formData[`evidence${headerIdx}`] : ''}
                    </Text>
                  </View>
                </View>
              })}

            </View>
          </View>
        </Page>
      </Document>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let workshopDocument = null
    if (this.state.workshopDocumentId &&
      this.props.workshopObservationBaseProps &&
      this.props.workshopObservationBaseProps.workshopObservationsDict &&
      this.props.workshopObservationBaseProps.workshopObservationsDict.hasOwnProperty(this.state.workshopDocumentId)) {
      workshopDocument = this.props.workshopObservationBaseProps.workshopObservationsDict[this.state.workshopDocumentId]
    }

    return (
      <div>
        <div>
          <Layout className="content relative layout-header-mt">
            <Layout>
              <Content className='mt-2 sub-menu-width m-lr-auto flex w-100'>
                <div className='font-black w-100'>
                  <div style={{ position: 'sticky', top: '84px', zIndex: '1000' }} className='background-fff pt-2 pb-2 mb-2 border-bottom-lighter'>
                    <div className='flex w-100'>
                      <div>
                        <div className='font-40 font-bold font-black'>Workshop Observation Tool</div>
                        <div className='font-24 font-bold font-black flex flex-v-center w-100'>
                          <div>2023-2024</div>
                          <div className='ml-2 text-muted font-16 font-normal'>
                            {this.state.saveStatus === 'none' ?
                              <div>
                                <Icon type='check' className='mr-05' />
                                {'All changes saved' + (workshopDocument && workshopDocument.lastSave &&
                                  workshopDocument.lastSave.seconds ? ' (Last saved on: ' +
                                  moment(workshopDocument.lastSave.seconds * 1000).format("MM/DD/YYYY - h:mm A") + ')' : '')}.
                              </div>
                              : ''}
                            {this.state.saveStatus === 'saving' ?
                              <div>
                                <Icon type='loading' className='mr-05' />
                                Saving...
                              </div> : ''}
                          </div>
                        </div>
                      </div>
                      {/*
                      <div className='ml-auto'>
                        <PDFDownloadLink document={<this.getPDFDocument />}
                          fileName={'WorkshopObservationTool.pdf'}
                          className='ant-btn ant-btn-primary lg-btn ant-shadow'>
                          {({ blob, url, loading, error }) => {
                            return <div className='flex flex-v-center'>
                              <Icon type='file-pdf' className='font-20 mr-1' />
                              <div>{'Download as a PDF'}</div>
                            </div>
                          }}
                        </PDFDownloadLink>
                      </div>*/}
                    </div>
                  </div>
                  {!this.state.initialFormSet ?
                    <div className='text-center w-100 mt-4'>
                      <Spin size="large" className="ant-spin-vlg mb-1" />
                      <div className='font-20'>Loading Workshop Observation Tool...</div>
                    </div> :
                    <div>
                      <div className='font-30 font-bold mb-2 font-black mt-3'>Observation Information</div>

                      <Form onSubmit={this.handleSubmit}>
                        <Row gutter={32}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <FormItem label="Teacher/Subject">
                              {getFieldDecorator('teacher_subject', {
                                rules: [{ required: true, message: 'Please input the teacher/subject!' }],
                              })(<Input size='large' placeholder="Enter Teacher/Subject" />)}
                            </FormItem>
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <FormItem label="School">
                              {getFieldDecorator('school', {
                                rules: [{ required: true, message: 'Please input the school!' }],
                              })(
                                <Select size='large' placeholder="Select School">
                                  {this.props.workshopObservationBaseProps.schools &&
                                    this.props.workshopObservationBaseProps.schools.map((school) => {
                                      return <Select.Option key={school.id} value={school.id}>{school.schoolName}</Select.Option>
                                    })}
                                </Select>
                              )}
                            </FormItem>
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <div>
                              <FormItem label="Date">
                                {getFieldDecorator('date', {
                                  rules: [{ required: true, message: 'Please input the date!' }],
                                })(<DatePicker size='large' />)}
                              </FormItem>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={32}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                            <FormItem label="Observation Time">
                              {getFieldDecorator('observation_time', {
                                rules: [{ required: true, message: 'Please input the observation time!' }],
                              })(<Input size='large' placeholder="Enter Observation Time" />)}
                            </FormItem>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <FormItem label="Observed">
                              {getFieldDecorator('observed', {
                                rules: [{ required: true, message: 'Please select one!' }],
                              })(
                                <RadioGroup >
                                  <RadioButton className='font-black' value="entire_workshop">Entire Workshop</RadioButton>
                                  <RadioButton className='font-black' value="beginning">Beginning</RadioButton>
                                  <RadioButton className='font-black' value="middle">Middle</RadioButton>
                                  <RadioButton className='font-black' value="end">End</RadioButton>
                                </RadioGroup>
                              )}
                            </FormItem>
                          </Col>
                        </Row>

                        {this.state.headersGroup1.map((header, headerIdx) => {
                          return <div key={'group-1-' + headerIdx}>
                            <div className='font-30 font-bold mb-2 mt-3 font-black'>{header}</div>
                            {this.state.formItems.map((item, index) => {
                              if (headerIdx === 0 && index > 3) {
                                return false
                              } else if (headerIdx === 1 && (index < 4 || index > 9)) {
                                return false
                              } else if (headerIdx === 2 && (index < 10 || index > 12)) {
                                return false
                              } else if (headerIdx === 3 && (index < 13 || index > 16)) {
                                return false
                              }
                              return <div><FormItem key={index} className='mb-0'>
                                <Row gutter={16} className='border-bottom-lighter mb-2 pb-2'>
                                  <Col span={16}>
                                    <div className='font-20'>{item}</div>
                                  </Col>
                                  <Col span={8}>
                                    {getFieldDecorator(`observationItem${index}`, {
                                      rules: [{ required: true, message: 'Please select one!' }],
                                    })(
                                      <RadioGroup className='font-black' size='large' >
                                        <RadioButton className='font-black' value="evident">Evident</RadioButton>
                                        <RadioButton className='font-black' value="notevident">Not Evident</RadioButton>
                                        <RadioButton className='font-black' value="na">N/A</RadioButton>
                                      </RadioGroup>,
                                    )}
                                  </Col>
                                </Row>
                              </FormItem>
                              </div>
                            })}
                            <div>
                              <FormItem label="Evidence">
                                {getFieldDecorator(`evidence${headerIdx}`, {
                                  rules: [{ required: true, message: 'Please input evidence' }],
                                })(<Input.TextArea rows={5} className='font-16' placeholder="Evidence..." />)}
                              </FormItem>
                            </div>
                          </div>
                        })}

                        <div className='font-24 font-bold mb-2 font-black'>Students Engaged</div>
                        <FormItem className='mb-0'>
                          {getFieldDecorator('studentsEngaged', {
                            rules: [{ required: true, message: 'Please select one!' }],
                          })(
                            <RadioGroup>
                              <RadioButton className='font-black' value="75-100">At least 75% of time</RadioButton>
                              <RadioButton className='font-black' value="50-74">50-74%</RadioButton>
                              <RadioButton className='font-black' value="0-49">Less than 50% of time</RadioButton>
                            </RadioGroup>,
                          )}
                        </FormItem>

                        <div className='border-bottom-lighter mb-2 pb-2'></div>

                        <div className='font-24 font-bold mb-2 font-black'>Independent or Collaborative Work</div>
                        <FormItem className='mb-0'>
                          {getFieldDecorator('independentOrCollaborativeWork', {
                            rules: [{ required: true, message: 'Please select one!' }],
                          })(
                            <CheckboxGroup>
                              <Checkbox value="workingIndependently" className='ml-0 flex flex-v-center mr-1'>
                                <div className='ml-1 font-black font-normal'>Working independently</div>
                              </Checkbox>
                              <Checkbox value="workingCollaborating" className='ml-0 flex flex-v-center mr-1'>
                                <div className='ml-1 font-black font-normal'>Working collaboratively</div></Checkbox>
                              <Checkbox value="problemSolving" className='ml-0 flex flex-v-center pr-1'>
                                <div className='ml-1 font-black font-normal'>Problem solving</div></Checkbox>
                              <Checkbox value="exploring" className='ml-0 flex flex-v-center pr-1'>
                                <div className='ml-1 font-black font-normal'>Exploring</div></Checkbox>
                            </CheckboxGroup>,
                          )}
                        </FormItem>

                        <div className='border-bottom-lighter mb-2 pb-2'></div>

                        {this.state.headersGroup2.map((header, headerIdx) => {
                          return <div key={'group-2-' + headerIdx}>
                            <div className='font-30 font-bold mb-2 mt-3 font-black'>{header}</div>
                            {this.state.formItems.map((item, index) => {
                              if (headerIdx === 0 && (index < 17 || index > 20)) {
                                return false
                              } else if (headerIdx === 1 && (index < 21 || index > 24)) {
                                return false
                              }
                              return <FormItem key={index} className='mb-0'>
                                <Row gutter={16} className='border-bottom-lighter mb-2 pb-2'>
                                  <Col span={16}>
                                    <div className='font-20'>{item}</div>
                                  </Col>
                                  <Col span={8}>
                                    {getFieldDecorator(`observationItem${index}`, {
                                      rules: [{ required: true, message: 'Please select one!' }],
                                    })(
                                      <RadioGroup className='font-black' size='large'>
                                        <RadioButton className='font-black' value="evident">Evident</RadioButton>
                                        <RadioButton className='font-black' value="notevident">Not Evident</RadioButton>
                                        <RadioButton className='font-black' value="na">N/A</RadioButton>
                                      </RadioGroup>,
                                    )}
                                  </Col>
                                </Row>
                              </FormItem>
                            })}
                          </div>
                        })}
                        <div>
                          <FormItem label="Evidence">
                            {getFieldDecorator(`evidence${4}`, {
                              rules: [{ required: true, message: 'Please input evidence' }],
                            })(<Input.TextArea rows={5} className='font-16' placeholder="Evidence..." />)}
                          </FormItem>
                        </div>

                        {/*<FormItem className="mb-0">
                        <Button
                          type="primary"
                          size={'large'}
                          htmlType="submit"
                          className="ant-btn ant-btn-primary lg-btn text-align-center float-right font-bold"
                        >
                          <Icon type="save" className="mr-1 font-30" />
                          <span>Submit</span>
                        </Button>
                        </FormItem>*/}
                      </Form>
                    </div>}
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    );
  }
}

export default Form.create({
  onValuesChange: (props, changedValues, allValues) => {
    if (onFormValuesChangeOuter) {
      onFormValuesChangeOuter(changedValues, allValues);
    }
  }
})(TeacherObservationForm);
