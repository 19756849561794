import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../../styles/GlobalStyle.css'
//import PersonAvatar from '../../../customcomponents/PersonAvatar'
import IEPNavigation from '../IEPNavigation'
import MainPlanView from '../MainPlanView'
import { Layout, Spin } from 'antd'
//import { db } from '../../../firebase/Firebase'
//import ColType from '../../../Types'
import { FOLDER_CURRENT, getQueryStringParam, getIDFromURL, FOLDER_ARCHIVED, FOLDER_TRASHBIN } from '../../../Util'
import { Link } from 'react-router-dom'
const Content = Layout.Content

class ArchivedStudentPlan extends Component {
    state = {
        teacherId: null,
        studentId: null,
        IEPId: null,
    }

    componentDidMount() {
        let teacherId = getIDFromURL(window.location)
        let studentId = getQueryStringParam('student')
        let IEPId = getQueryStringParam('plan')

        this.setState({
            teacherId: teacherId,
            studentId: studentId,
            IEPId: IEPId,
        })

        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        let teacherId = getIDFromURL(window.location)
        let studentId = getQueryStringParam('student')
        let IEPId = getQueryStringParam('plan')

        this.setState({
            teacherId: teacherId,
            studentId: studentId,
            IEPId: IEPId,
        })

        this.getData()
    }

    getData = () => {
        let studentId = getQueryStringParam('student')
        this.setState({
            studentId: studentId,
        })
        // get the data for the student (will only get it once in
        // archivedBase)
        if (this.props.archivedBaseProps.hasOwnProperty('getIEPSForStudentFunc')) {
            this.props.archivedBaseProps.getIEPSForStudentFunc(studentId)
        }
    }

    render() {
        let dataLoaded = this.props.archivedBaseProps.archivedStudentsLoaded
        let alreadyMovedAction = ''
        let alreadyMovedDescription = ''
        if (this.props.archivedBaseProps.baseFolder === FOLDER_ARCHIVED) {
            alreadyMovedAction = 'Back to archived students'
            alreadyMovedDescription = 'Check to see if this student or plan was moved to your caseload or deleted.'
        } else if (this.props.archivedBaseProps.baseFolder === FOLDER_TRASHBIN) {
            alreadyMovedAction = 'Back to recycle bin'
            alreadyMovedDescription = 'Check to see if this student or plan was moved to your caseload or archived.'
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                {this.props.archivedBaseProps.moveInProcess ?
                                    <div className={"flex flex-h-center" +
                                        " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                                        <div>
                                            <Spin size="large" className="ant-spin-vlg cyan-spin" />
                                        </div>
                                    </div>
                                    : this.state.studentId &&
                                        this.state.IEPId &&
                                        this.props.archivedBaseProps.studentDict.hasOwnProperty(this.state.studentId) &&
                                        this.props.archivedBaseProps.IEPDict.hasOwnProperty(this.state.IEPId) ?
                                        <div>
                                            <IEPNavigation
                                                history={this.props.history}
                                                teacherBaseProps={this.props.archivedBaseProps}
                                                onMoveFromCurrent={null}
                                                onBeforeMove={this.props.archivedBaseProps.onBeforeMoveFunc}
                                                onSingleGoalMoved={this.props.archivedBaseProps.singleGoalMovedFunc}
                                                onAllGoalsMoved={this.props.archivedBaseProps.removeStudentFunc}
                                                iep={this.props.archivedBaseProps.IEPDict[this.state.IEPId]}
                                                studentsIEPs={this.props.archivedBaseProps.studentIEPDict[this.state.studentId]}
                                                studentId={this.state.studentId}
                                                folderFrom={this.props.archivedBaseProps.baseFolder}
                                                folderTo={FOLDER_CURRENT}
                                            />
                                            <MainPlanView
                                                studentId={this.state.studentId}
                                                IEPId={this.state.IEPId}
                                                student={this.props.archivedBaseProps.studentDict[this.state.studentId]}
                                                iep={this.props.archivedBaseProps.IEPDict[this.state.IEPId]}
                                                teacherBaseProps={this.props.archivedBaseProps}
                                            />
                                        </div>
                                        :
                                        dataLoaded ?
                                            <div>
                                                <div className="font-30 font-bold">
                                                    No student or IEP selected
                                                </div>
                                                <div className="font-20 mb-2">
                                                    {alreadyMovedDescription}
                                                </div>
                                                <Link className="font-20" to={"/teacher/" + this.props.archivedBaseProps.urlRoute + "/students/" + this.state.teacherId}>
                                                    {alreadyMovedAction}
                                                </Link>
                                            </div>
                                            : ''
                                }
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default ArchivedStudentPlan