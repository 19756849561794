import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import PersonAvatar from '../customcomponents/PersonAvatar'
import CustomFooter from '../login/CustomFooter'
//import SubjectPieChart from './SubjectPieChart'
import SubjectHistogram from '../admin/SubjectHistogram'
import SupportPieChart from '../admin/SupportPieChart'
import EditPersonnelNameForm from '../admin/EditPersonnelNameForm'
import SchoolAdminGroupProgressMonitoring from './SchoolAdminGroupProgressMonitoring'
import SchoolAdminReviewDates from './SchoolAdminReviewDates'
//import SchoolAdminStudentRecords from './SchoolAdminStudentRecords'
import Empty from '../customcomponents/Empty'
import ColType from '.././Types'
import { flattenDoc, summaryIndex, capitalizeFirstChar } from '.././Util'
import { Layout, Select, Row, Col, Progress, Icon, Tabs } from 'antd'
const { Content } = Layout
const Option = Select.Option
const TabPane = Tabs.TabPane

class SchoolAdminSchoolSummary extends Component {
  state = {
    schoolAdministrators: this.props.schoolAdminBaseProps.schoolAdministrators,
    schoolTeachers: this.props.schoolAdminBaseProps.schoolTeachers,
    schoolTeachersDict: this.props.schoolAdminBaseProps.schoolTeachersDict,
    students: this.props.schoolAdminBaseProps.students,
    studentDict: this.props.schoolAdminBaseProps.studentDict,
    iepGoals: this.props.schoolAdminBaseProps.iepGoals,
    iepDict: this.props.schoolAdminBaseProps.iepDict,
    records: this.props.schoolAdminBaseProps.records,
    schoolProps: this.props.schoolAdminBaseProps,
    schoolId: this.props.schoolAdminBaseProps.schoolId,
    districtId: this.props.schoolAdminBaseProps.districtId,
    grade: 'all',
    subject: 'all',
    raceOrEthnicity: 'all',
    grades: ['all', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    subjects: ['all', 'Writing', 'Reading Comprehension in Literature',
                  'Reading Comprehension in Informational Text', 'Reading Foundations',
                  'Math', 'Social Emotional Learning'],
    racesOrEthnicities: ['all', 'Asian', 'Black or African American',
                        'Hispanic or Latino', 
                        'Native American or Alaska Native',
                        'Native Hawaiian or Other Pacific Islander',
                        'White'],
    supportLevels: ['h', 'm', 'l', 'total', 'totalWithMeasurements', 'onTrack']
  }

  componentWillReceiveProps(nextProps) {
    console.log('next props')
    this.setState({
      schoolAdministrators: nextProps.schoolAdminBaseProps.schoolAdministrators,
      schoolTeachers: nextProps.schoolAdminBaseProps.schoolTeachers,
      schoolTeachersDict: nextProps.schoolAdminBaseProps.schoolTeachersDict,
      students: nextProps.schoolAdminBaseProps.students,
      studentDict: nextProps.schoolAdminBaseProps.studentDict,
      iepGoals: nextProps.schoolAdminBaseProps.iepGoals,
      iepDict: nextProps.schoolAdminBaseProps.iepDict,
      schoolProps: nextProps.schoolAdminBaseProps,
      records: nextProps.schoolAdminBaseProps.records,
      schoolId: nextProps.schoolAdminBaseProps.schoolId,
      districtId: nextProps.schoolAdminBaseProps.districtId,
    })
  }

  componentDidMount() {
    document.title = 'School Summary - dot it'
  }

  gradeChange = (value) => {
    this.setState({
      grade: value
    })
  }

  getStudents = () => {
    db.collection(ColType.student)
    .where('districtId', '==', this.props.schoolAdminBaseProps.schoolAdmin.districtId)
    .where('schoolId', '==', this.props.schoolAdminBaseProps.schoolAdmin.schoolId)
    .get()
    .then((querySnapshot) => {
      var students = []
      var studentDict = {}

      querySnapshot.forEach((doc) => {
        var student = flattenDoc(doc)
        students.push(student)
        studentDict[student.id] = student
      })

      students.sort((a, b) => {
        if (a.grade === 'K' && b.grade === 'K') {
          if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
            return 1
          } else {
            return -1
          }
        }
        if (a.grade === 'K' && b.grade !=='K') return -1
        if (a.grade !== 'K' && b.grade === 'K') return 1
        if (a.grade === b.grade) {
          if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
            return 1
          } else {
            return -1
          }
        }
        var aGrade = parseInt(a.grade, 10)
        var bGrade = parseInt(b.grade, 10)
        if (aGrade > bGrade) return 1
        if (bGrade > aGrade) return -1
        return 0
      })

      this.setState({
        students: students,
        studentDict: studentDict,
      })
    })
  }

  getTeachers = () => {
    if (!this.props.schoolAdminBaseProps.schoolAdmin) return
    db.collection(ColType.teacher)
    .where('districtId', '==', this.props.schoolAdminBaseProps.schoolAdmin.districtId)
    .where('schoolId', '==', this.props.schoolAdminBaseProps.schoolAdmin.schoolId)
    .get()
    .then((querySnapshot) => {
      var schoolTeachers = []
      
      querySnapshot.forEach((doc) => {
        var teacher = flattenDoc(doc)
        schoolTeachers.push(teacher)
      })

      this.setState({
        schoolTeachers: schoolTeachers
      })
    })
  }

  getSchoolAdmins = () => {
    if (!this.props.schoolAdminBaseProps.schoolAdmin) return
    db.collection(ColType.schoolAdmin)
      .where('districtId', '==', this.props.schoolAdminBaseProps.schoolAdmin.districtId)
      .where('schoolId', '==', this.props.schoolAdminBaseProps.schoolAdmin.schoolId)
      .get()
      .then((querySnapshot) => {
        var schoolAdministrators = []

        querySnapshot.forEach((doc) => {
          var schoolAdministrator = flattenDoc(doc)
          schoolAdministrators.push(schoolAdministrator)
        })

        this.setState({
          schoolAdministrators: schoolAdministrators
        })
      }) 
  }

  render() {
    console.log("render teachers", this.state.schoolTeachers, 
    'schoolAdmins', this.state.schoolAdministrators, 'props teachers',
    this.props.schoolAdminBaseProps.schoolTeachers)

    // doing the sort here because records may not have the student data in the records
    // on the initial pull. At some point though it will have everything.
    this.state.records.sort((a, b) => {
      if (!a || !b) return 0
      else if (a.studentRecords.length === 0 && b.studentRecords.length === 0) return 0
      else if (b.studentRecords.length === 0) return 1
      else if (a.studentRecords.length === 0) return -1
      var lastStudentRecordA = a.studentRecords[a.studentRecords.length - 1]
      var lastStudentRecordB = b.studentRecords[b.studentRecords.length - 1]
      if (!this.state.studentDict.hasOwnProperty(lastStudentRecordA.studentId) && 
          !this.state.studentDict.hasOwnProperty(lastStudentRecordB.studentId)) return 0
      else if (!this.state.studentDict.hasOwnProperty(lastStudentRecordB.studentId)) return -1
      else if (!this.state.studentDict.hasOwnProperty(lastStudentRecordA.studentId)) return 1
      var lastStudentA = this.state.studentDict[lastStudentRecordA.studentId]
      var lastStudentGradeA = lastStudentA.grade === 'K' ?  
      'K' : parseInt(lastStudentA.grade, 10)
      var lastStudentB = this.state.studentDict[lastStudentRecordB.studentId]
      var lastStudentGradeB = lastStudentB.grade === 'K' ?  
      'K' : parseInt(lastStudentB.grade, 10)
      if (lastStudentGradeA === lastStudentGradeB) return 0
      else if (lastStudentGradeA !== 'K' && lastStudentGradeB === 'K') return 1
      else if (lastStudentGradeA === 'K' && lastStudentGradeB !== 'K') return -1
      else if (lastStudentGradeA > lastStudentGradeB) return 1
      else if (lastStudentGradeA < lastStudentGradeB) return -1
      return 0
    })
    
    return (
      <div>
        <Layout className="content layout-header-mt">
        <Layout className="w-1024 m-lr-auto">
            <Content className="layout-content">
              <div>
              {this.props.schoolAdminBaseProps.loadingData ?
               <div className={"h-300 w-100 flex flex-h-center" +
               " flex-center font-30 font-bold text-cyan"}>
                 <div>
                   <Icon type="loading" className="mr-2"/>
                   <span>Loading School Information...</span>
                 </div>
               </div>
              : ''}
              {this.props.schoolAdminBaseProps.school && 
              this.props.schoolAdminBaseProps.schoolSummary && 
              !this.props.schoolAdminBaseProps.loadingData ?
              <div>
              <h1 className="font-bold">
                {this.props.schoolAdminBaseProps.school.schoolName}
              </h1>
              <Tabs defaultActiveKey="1" size={'large'} className="overflow-visible" 
              animated={false} onChange={() => {}}>
                <TabPane tab="Summary" key="1">
                <div className="mt-4">
                <h2 className="mb-0 pb-2 border-bottom">
                  <Row gutter={32}>
                  <Col span={12}>
           
                  </Col>
                  <Col span={12}>
                  <span className="float-right w-100">
                    <Row>
                      <Col span={7}>
                        <span className="font-18">Filter by grade:</span>
                      </Col>
                      <Col span={17}>
                        <Select size={'large'} defaultValue={'all'} 
                        placeholder="Grade..." className="w-100 mb-0 inline-block" 
                        onSelect={this.gradeChange}>
                          <Option value="all">All</Option>
                          <Option value="K">K</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                          <Option value="7">7</Option>
                          <Option value="8">8</Option>
                          <Option value="9">9</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                        </Select>
                      </Col>
                    </Row>
                  </span>
                  </Col>
                  </Row>
                </h2>
                
                
                {this.state.racesOrEthnicities.map((race, index) => {
                  var total = this.props.schoolAdminBaseProps
                  .schoolSummary[summaryIndex(this.state.grade, 
                                  this.state.subject, race, 'total')]
                  if (total < 0) total = 0
                  var totalWithMeasurements = 
                  this.props.schoolAdminBaseProps.schoolSummary[summaryIndex(this.state.grade, 
                    this.state.subject, race, 'totalWithMeasurements')]
                  if (totalWithMeasurements < 0) totalWithMeasurements = 0
                  var onTrack = this.props.schoolAdminBaseProps
                  .schoolSummary[summaryIndex(this.state.grade, 
                    this.state.subject, race, 'onTrack')]
                  if (onTrack < 0) onTrack = 0
                  var lowSupport = this.props.schoolAdminBaseProps
                  .schoolSummary[summaryIndex(this.state.grade, 
                    this.state.subject, race, 'l')]
                  if (lowSupport < 0) lowSupport = 0
                  var mediumSupport = this.props.schoolAdminBaseProps
                  .schoolSummary[summaryIndex(this.state.grade, 
                    this.state.subject, race, 'm')]
                  if (mediumSupport < 0) mediumSupport = 0
                  var highSupport = this.props.schoolAdminBaseProps
                  .schoolSummary[summaryIndex(this.state.grade, 
                    this.state.subject, race, 'h')]
                  if (highSupport < 0) highSupport = 0
                  console.log(onTrack, totalWithMeasurements)
                  return <div key={race} className="p-4 pl-0 pr-0 border-bottom">
                    <h2 className="mb-3 font-bold">
                      <span className="mr-3">
                        {race === 'all' ? capitalizeFirstChar(race) : race}
                      </span>
                      <span className="text-muted font-16 float-right">{total} IEP Goals</span>
                    </h2>
                    <Row gutter={48}>
                    <Col span={8}>
                      <div className={"ant-shadow h-100 min-h-300 w-100 " +
                        "p-3 pr-4 br-4 relative"}>
                        <h2 className="text-center font-bold mb-0">IEP Goals by Content Area</h2>
                        {total > 0 ?
                          /**<SubjectPieChart 
                            Writing={this.props.schoolAdminBaseProps
                              .districtSummary.summary[summaryIndex(this.state.grade, 
                              'Writing', race, 'total')]}
                            RL={this.props.schoolAdminBaseProps
                              .districtSummary.summary[summaryIndex(this.state.grade, 
                              'Reading Comprehension in Literature', race, 'total')]}
                            RI={this.props.schoolAdminBaseProps
                              .districtSummary.summary[summaryIndex(this.state.grade, 
                              'Reading Comprehension in Informational Text', race, 'total')]
                            }
                            RF={this.props.schoolAdminBaseProps
                              .districtSummary.summary[summaryIndex(this.state.grade, 
                              'Reading Foundations', race, 'total')]
                            }
                            Math={this.props.schoolAdminBaseProps
                              .districtSummary.summary[summaryIndex(this.state.grade, 
                              'Math', race, 'total')]}
                            SEL={this.props.schoolAdminBaseProps
                              .districtSummary.summary[summaryIndex(this.state.grade, 
                              'Social Emotional Learning', race, 'total')]}
                            total={total}
                              />*/
                           <SubjectHistogram 
                            Wr={this.props.schoolAdminBaseProps
                              .schoolSummary[summaryIndex(this.state.grade, 
                              'Writing', race, 'total')]}
                            RL={this.props.schoolAdminBaseProps
                              .schoolSummary[summaryIndex(this.state.grade, 
                              'Reading Comprehension in Literature', race, 'total')]}
                            RI={this.props.schoolAdminBaseProps
                              .schoolSummary[summaryIndex(this.state.grade, 
                              'Reading Comprehension in Informational Text', race, 'total')]
                            }
                            RF={this.props.schoolAdminBaseProps
                              .schoolSummary[summaryIndex(this.state.grade, 
                              'Reading Foundations', race, 'total')]
                            }
                            Math={this.props.schoolAdminBaseProps
                              .schoolSummary[summaryIndex(this.state.grade, 
                              'Math', race, 'total')]}
                            SEL={this.props.schoolAdminBaseProps
                              .schoolSummary[summaryIndex(this.state.grade, 
                              'Social Emotional Learning', race, 'total')]}
                            total={total}
                            />
                        :
                        <Empty 
                        containerClassName="mt-4 pt-2 text-center"
                        width={55}
                        height={55}
                      />
                        }
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className={"ant-shadow h-100 min-h-300 w-100 " +
                        "p-3 pr-4 br-4 relative"}>
                        <h2 className="text-center font-bold mb-0">Goals on Track (%)</h2>
                        {totalWithMeasurements > 0 ?
                          <div className="mt-4 pt-2 text-center">
                          <Progress className="stroke-cyan" type="circle" 
                          percent={Math.round(onTrack / totalWithMeasurements * 100, 10)} />
                          </div>
                          : 
                          <Empty 
                          containerClassName="mt-4 pt-2 text-center"
                          width={55}
                          height={55}
                        />
                        }
                      </div>
                      </Col>
                      <Col span={8}>
                      <div className={"ant-shadow h-100 min-h-300 w-100 " +
                        "p-3 pr-4 br-4 relative"}>
                        <h2 className="text-center font-bold mb-0">Support Level of Goals</h2>
                          {total > 0 && lowSupport + mediumSupport + highSupport > 0 ?
                            <SupportPieChart 
                              lowSupport={lowSupport}
                              mediumSupport={mediumSupport}
                              highSupport={highSupport}
                            />
                          : 
                          <Empty 
                          containerClassName="mt-4 pt-2 text-center"
                          width={55}
                          height={55}
                        />
                        }
                        </div>
                      </Col>
                    </Row>
                  </div>
                })}
              
              </div>
              </TabPane>
              <TabPane tab="Review Dates" key="2">
                <SchoolAdminReviewDates schoolProps={this.state.schoolProps} />
              </TabPane>
              <TabPane tab="Group Progress Monitoring" key="3">
                <SchoolAdminGroupProgressMonitoring 
                  schoolProps={this.state.schoolProps}
                />
              </TabPane>
              {/*<TabPane tab="Student Records" key="4">
                <SchoolAdminStudentRecords 
                teachers={this.state.schoolTeachers}
                teachersDict={this.state.schoolTeachersDict}
                schoolProps={this.state} 
                onSuccess={this.getStudents}
                schoolId={this.state.schoolId}
                districtId={this.state.districtId}
                records={this.state.records}
                schoolAdminBaseProps={this.props.schoolAdminBaseProps}
              />
              </TabPane>*/}
                <TabPane tab="Personnel" key="5">
                  <div className="mt-4 pl-05 pr-05 pb-1">
                    <Row gutter={32}>
                      <Col span={12}>
                        <div className={"ant-shadow w-100 " +
                          "p-4 br-4"}>
                          <h2 className="text-center font-bold mb-2">
                          School Administrators</h2>
                          {this.state.schoolAdministrators && 
                          this.state.schoolAdministrators.length > 0 ?
                          this.state.schoolAdministrators
                          .map((schoolAdministrator, index) => {
                              return <div className="mb-1" key={'avatar-' + schoolAdministrator.id}>
                                <PersonAvatar person={schoolAdministrator} />
                                <span className="float-right">
                                  <EditPersonnelNameForm 
                                    person={schoolAdministrator}
                                    collectionType={ColType.schoolAdmin}
                                    onEditSuccessful={this.getSchoolAdmins}
                                  />
                                </span>
                              </div>
                            })
                          : 
                          <Empty 
                          containerClassName="mt-3 text-center"
                          width={55}
                          height={55}
                        />
                        }
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className={"ant-shadow w-100 " +
                          "p-4 br-4"}>
                          <h2 className="text-center font-bold mb-2">
                          Teachers</h2>
                          {this.state.schoolTeachers && 
                          this.state.schoolTeachers.length > 0 ?
                          this.state.schoolTeachers
                          .map((schoolTeacher, index) => {
                              return <Row gutter={32} key={'avatar-' + schoolTeacher.id}>
                              <Col span={20}>
                              <Link 
                                to={{
                                  pathname: '/teacher/home/' +  schoolTeacher.id,
                                  state: { schoolAdminBaseProps: this.props.schoolAdminBaseProps },
                                }}
                                className={"w-100 h-100 br-4 text-left p-2 inline-block up-hover" +
                                " shadow-hover mb-1 ant-btn" +
                                " ant-btn relative parent-hover"}
                            >
                              <Row gutter={32}>
                                <Col span={12}>
                                <PersonAvatar person={schoolTeacher} size={'large'} />
                                </Col>
                                <Col span={12} className="pt-5px">
                                  <span className="float-right font-14">
                                    <Icon type="eye-o" className="mr-1 font-24 va-middle" />
                                    <span>View</span>
                                  </span>
                                </Col>
                              </Row>
                            </Link>
                            </Col>
                            <Col span={4}>
                                <EditPersonnelNameForm 
                                  person={schoolTeacher}
                                  collectionType={ColType.teacher}
                                  onEditSuccessful={this.getTeachers}
                                  btnStyles={'mt-2'}
                                />
                            </Col>
                            </Row>
                            })
                          : 
                          <Empty 
                          containerClassName="mt-3 text-center"
                          width={55}
                          height={55}
                        />
                        }
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
              </div>
              : ''}
              </div>
            </Content>
          </Layout>
        </Layout>
        <CustomFooter />
      </div>
    )
  }
}

export default SchoolAdminSchoolSummary