import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import ColType from '.././Types'
import { Icon, Form, Input, Button, Select, Row, Col, message } from 'antd'
const FormItem = Form.Item
const Option = Select.Option

const successMessage = (description) => {
    message.success(description)
}

const errorMessage = (description) => {
    message.error(description)
}

// This page can only be accessed by us, it is how you add admins that then can add other users.
class SuperAdminDeletePerson extends Component {
    state = {
        schoolId: null,
        districtInfo: [],
        district: null,
        schools: [],
        teachers: [],
    }

    componentDidMount() {

    }

    // adding directly here, in the future it will send an email.
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                db.collection(ColType.district)
                    .where('district', '==', values.districtName)
                    .get()
                    .then((querySnapshot) => {
                        var districts = []
                        querySnapshot.forEach((doc) => {
                            var district = flattenDoc(doc)
                            districts.push(district)
                        })

                        console.log(districts)

                        if (districts.length > 0) {
                            db.collection(ColType.school)
                                .where('districtId', '==', districts[0].id)
                                .get()
                                .then((querySnapshot) => {
                                    var schools = []

                                    querySnapshot.forEach((doc) => {
                                        var school = flattenDoc(doc)
                                        schools.push(school)
                                    })

                                    this.setState({
                                        schools: schools,
                                    })
                                })
                        }
                    })
            }
        })
    }

    selectSchool = (schoolId) => {
        if (!schoolId) {
            return
        }

        this.setState({
            schoolId: schoolId
        })

        db.collection(ColType.teacher)
            .where('schoolId', '==', schoolId)
            .get()
            .then((querySnapshot) => {
                var teachers = []
                querySnapshot.forEach((doc) => {
                    var teacher = flattenDoc(doc)
                    teachers.push(teacher)
                })

                this.setState({
                    teachers: teachers,
                })

                console.log(teachers)
            })
    }

    setTeacherVisibility = (teacher, isVisible) => {
        db.collection(ColType.teacher)
            .doc(teacher.id)
            .update({
                isVisible: isVisible,
                // lock the account if the user isn't visible
                isLocked: !isVisible
            })
            .then(() => {
                // refresh teachers to show the visibility update
                this.selectSchool(teacher.schoolId)
            })
            .catch((e) => {
                console.log("set teacher visibility error", e)
            })
    }

    deleteTeacher = (teacherId) => {
        db.collection(ColType.teacher)
            .doc(teacherId)
            .delete()
            .then(() => {
                successMessage("Teacher deleted successfully.")
                // refresh teachers
                this.selectSchool(this.state.schoolId)
            })
            .catch((e) => {
                errorMessage("Something went wrong deleting a teacher.")
                console.log('delete teacher error', e)
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 25 },
                sm: { span: 9 },
            },
            wrapperCol: {
                xs: { span: 25 },
                sm: { span: 15 },
            },
        }

        return (
            <div>
                <SuperAdminHeader menuKey={'delete-person'} />

                <div className="border br-4 p-4 w-600 m-lr-auto mt-100 mb-100">
                    <h1>Delete Person (Do not use unless you are Britt!!)
                    </h1>
                    <div className="font-24 font-bold mb-3">Search for a district</div>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem {...formItemLayout} label="District name">
                            {getFieldDecorator('districtName', {
                                rules: [{ required: true, message: 'required.' }],
                            })(
                                <Input
                                    size={"large"}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="District name..." />
                            )}
                        </FormItem>
                        <FormItem className="mb-0">
                            <Button
                                size={"large"}
                                type="primary"
                                htmlType="submit"
                                className="login-form-button text-align-center w-100"
                            >
                                Search for a district
                            </Button>
                        </FormItem>
                    </Form>

                    <div className="mt-3 pt-3 border-top">
                        <h2>Select School</h2>
                        <Select className="w-100" onSelect={this.selectSchool}>
                            {this.state.schools.map((school, idx) => {
                                return <Option key={school.id} value={school.id}>{school.schoolName}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="mt-3 pt-3 border-top">
                        {this.state.teachers.map((teacher, idx) => {
                            return <div key={teacher.id} className="border-bottom mb-1 pb-1">
                                <Row>
                                    <Col span={12}>
                                        <span className="font-14">{teacher.firstName + " " + teacher.lastName}</span>
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={(e) => {
                                            this.deleteTeacher(teacher.id)
                                        }}>
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create()(SuperAdminDeletePerson)