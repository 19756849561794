import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import ChangeFolder from './ChangeFolder'
import { Row, Col, Icon } from 'antd'
//import { db } from '../../../firebase/Firebase'
//import ColType from '../../../Types'
import { capitalizeFirstChar, FOLDER_ARCHIVED, FOLDER_CURRENT, FOLDER_PERMANENTLY_DELETED, FOLDER_TRANSFER, FOLDER_TRASHBIN, getIconFromMainSubject, getTierCircleColor } from '../../Util'
import StandardNumber from '../../customcomponents/StandardNumber'
//var moment = require('moment')

class IEPNavigation extends Component {
    state = {

    }

    componentDidMount() {

    }

    render() {
        let IEPIdx = -1
        let prevIEPIdx = 0
        let nextIEPIdx = 0
        if (this.props.studentsIEPs) {
            for (let i = 0; i < this.props.studentsIEPs.length; i++) {
                if (this.props.studentsIEPs[i] === this.props.iep) {
                    IEPIdx = i
                    break
                }
            }
            if (IEPIdx !== -1) {
                prevIEPIdx = IEPIdx - 1
                nextIEPIdx = IEPIdx + 1
                if (prevIEPIdx < 0) {
                    prevIEPIdx = 0
                }
                if (nextIEPIdx > this.props.studentsIEPs.length - 1) {
                    nextIEPIdx = this.props.studentsIEPs.length - 1
                }
            }
        }

        let studentNavigationTitle = ''
        let studentNavigationLink = ''
        let studentNameNavigationLink = ''
        if (this.props.folderFrom === FOLDER_CURRENT) {
            studentNavigationTitle = 'Students'
            studentNavigationLink = '/teacher/home/'
            studentNameNavigationLink = '/teacher/student/'
        } else if (this.props.folderFrom === FOLDER_ARCHIVED) {
            studentNavigationTitle = 'Archived'
            studentNavigationLink = '/teacher/archived/students/'
            studentNameNavigationLink = '/teacher/archived/student/'
        } else if (this.props.folderFrom === FOLDER_TRANSFER) {
            studentNavigationTitle = 'Transfers'
            studentNavigationLink = '/teacher/transfers/students/'
            studentNameNavigationLink = '/teacher/transfers/student/'
        } else if (this.props.folderFrom === FOLDER_TRASHBIN) {
            studentNavigationTitle = 'Recycle bin'
            studentNavigationLink = '/teacher/deleted/students/'
            studentNameNavigationLink = '/teacher/deleted/student/'
        } else {
            console.error('IEPNavigation.js: folderFrom and folderTo are not set correctly', this.props.folderFrom, this.props.folderTo)
        }

        return (
            <div>
                {this.props.teacherBaseProps &&
                    this.props.teacherBaseProps.teacher &&
                    this.props.teacherBaseProps.studentDict &&
                    this.props.studentId &&
                    this.props.teacherBaseProps.studentDict.hasOwnProperty(this.props.studentId) ?
                    <div className="background-fff br-4">
                        <div className="w-100 flex flex-v-center mb-2 font-16 font-bold">
                            <Link to={studentNavigationLink + this.props.teacherBaseProps.teacher.id}>
                                {studentNavigationTitle}
                            </Link>
                            <Icon type="right" className="ml-1 mr-1 font-16" />
                            <Link to={studentNameNavigationLink + this.props.teacherBaseProps.teacher.id +
                                "?student=" + this.props.teacherBaseProps.studentDict[this.props.studentId].id}
                                className="white-space-nowrap ellipsis" style={{ maxWidth: '300px' }}>
                                {this.props.teacherBaseProps.studentDict[this.props.studentId].firstName + " " + this.props.teacherBaseProps.studentDict[this.props.studentId].lastName}
                            </Link>
                            <Icon type="right" className=" ml-1 mr-1 font-16" />
                            <span className="ellipsis">
                                <StandardNumber
                                    district={this.props.teacherBaseProps.district}
                                    iep={this.props.iep.iep}
                                    className="mr-0"
                                    containerClassName={'pl-1 pr-1 pt-05 pb-05 mr-1 br-100'}
                                    containerStyle={{ backgroundColor: '#e8e8e8' }}
                                />

                                {capitalizeFirstChar(this.props.iep.iep.standardDescription)}
                            </span>
                        </div>

                        <div className='br-20 overflow-hidden'>
                            <div className='sub-menu-width m-lr-auto br-4 relative pl-3 pr-3 pt-3 relative'
                                style={{ height: '300px', }}>

                                <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#531dab' }}>
                                </div>

                                <div className="absolute-tl w-100 h-100 image-box-mask">
                                </div>

                                <div className="flex w-100 h-100 mt-4">
                                    <Row className='font-fff'>
                                        {/* 
                                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                            {IEPIdx !== -1 ?
                                                <div className={"float-left flex flex-v-center" +
                                                    (IEPIdx === 0 ? " invisible" : "")}>
                                                    <Link to={planLink + this.props.teacherBaseProps.teacher.id +
                                                        "?student=" + this.props.teacherBaseProps.studentDict[this.props.studentId].id +
                                                        "&plan=" + this.props.studentsIEPs[prevIEPIdx].id}
                                                        className="float-left ant-btn ant-btn-circle circle-icon-btn-lg">
                                                        <Icon type="arrow-left" />
                                                    </Link>
                                                    <span className="font-20 font-bold ml-2">Previous goal</span>
                                                </div>
                                                : ''}
                                        </Col>*/}
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="w-100">
                                                <div className="flex flex-v-center">
                                                    <div className="br-50 flex flex-h-center flex-v-center mr-4" style={{ background: getTierCircleColor(this.props.iep), minWidth: '140px', width: '140px', height: '140px' }}>
                                                        <img
                                                            src={getIconFromMainSubject(this.props.iep.iep.mainSubject, this.props.iep.iep.hasOwnProperty('tier') ? this.props.iep.iep.tier : '')}
                                                            alt="icon"
                                                            width={88}
                                                        />
                                                    </div>

                                                    <div>
                                                        <div className='font-20 font-bold'>
                                                            {this.props.teacherBaseProps.studentDict[this.props.studentId].firstName + "'s " +
                                                                ((this.props.iep.hasOwnProperty("isMTSS") && this.props.iep.isMTSS) ?
                                                                    this.props.iep.iep.tier + " Goal" : "IEP Goal")}
                                                        </div>
                                                        <div className="mb-1 font-24 font-bold">{this.props.iep.iep.mainSubject}</div>
                                                        <div className="font-20">
                                                            <StandardNumber
                                                                district={this.props.teacherBaseProps.district}
                                                                iep={this.props.iep.iep}
                                                                className='mr-0'
                                                                containerClassName={'pl-1 pr-1 pt-05 pb-05 mr-1 br-100'}
                                                                containerStyle={{ backgroundColor: '#efdbff', color: '#391085' }}
                                                            />
                                                            <span>
                                                                {capitalizeFirstChar(this.props.iep.iep.standardDescription)}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>

                                        {/* 
                                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                            {IEPIdx !== -1 ?
                                                <div className={"float-right flex flex-v-center" +
                                                    (IEPIdx === this.props.studentsIEPs.length - 1 ? " invisible" : "")}>
                                                    <span className="font-20 font-bold">Next goal</span>
                                                    <Link to={planLink + this.props.teacherBaseProps.teacher.id +
                                                        "?student=" + this.props.teacherBaseProps.studentDict[this.props.studentId].id +
                                                        "&plan=" + this.props.studentsIEPs[nextIEPIdx].id}
                                                        className="float-right ant-btn ant-btn-circle circle-icon-btn-lg transparent-btn font-fff">
                                                        <Icon type="right" className='font-fff' />
                                                    </Link>
                                                </div>
                                                : ''}
                                        </Col>*/}
                                    </Row>

                                    <div className="w-100" style={{ position: 'absolute', bottom: '24px', left: '0px' }}>
                                        <div className="button-row">
                                            <div className='flex flex-h-center'>
                                                <ChangeFolder
                                                    history={this.props.history}
                                                    onMoveFromCurrent={this.props.onMoveFromCurrent}
                                                    onSingleGoalMoved={this.props.onSingleGoalMoved}
                                                    onBeforeMove={this.props.onBeforeMove}
                                                    onAllGoalsMoved={this.props.onAllGoalsMoved}
                                                    teacherBaseProps={this.props.teacherBaseProps}
                                                    teacherId={this.props.teacherBaseProps.teacher.id}
                                                    student={this.props.teacherBaseProps.studentDict[this.props.studentId]}
                                                    totalIEPGoals={
                                                        this.props.teacherBaseProps &&
                                                            this.props.teacherBaseProps.studentIEPDict &&
                                                            this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.studentId)
                                                            ?
                                                            this.props.teacherBaseProps.studentIEPDict[this.props.studentId]
                                                            :
                                                            []
                                                    }
                                                    IEPGoals={[this.props.iep]}
                                                    folderFrom={this.props.folderFrom}
                                                    folderTo={this.props.folderTo}
                                                    isNotStudent={true}
                                                    changeStudentFolderToo={true}
                                                />

                                                {this.props.folderFrom !== FOLDER_TRASHBIN ?
                                                    <ChangeFolder
                                                        history={this.props.history}
                                                        onMoveFromCurrent={this.props.onMoveFromCurrent}
                                                        onSingleGoalMoved={this.props.onSingleGoalMoved}
                                                        onBeforeMove={this.props.onBeforeMove}
                                                        onAllGoalsMoved={this.props.onAllGoalsMoved}
                                                        teacherBaseProps={this.props.teacherBaseProps}
                                                        teacherId={this.props.teacherBaseProps.teacher.id}
                                                        student={this.props.teacherBaseProps.studentDict[this.props.studentId]}
                                                        totalIEPGoals={
                                                            this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.studentIEPDict &&
                                                                this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.studentId)
                                                                ?
                                                                this.props.teacherBaseProps.studentIEPDict[this.props.studentId]
                                                                :
                                                                []
                                                        }
                                                        IEPGoals={[this.props.iep]}
                                                        folderFrom={this.props.folderFrom}
                                                        folderTo={FOLDER_TRASHBIN}
                                                        isNotStudent={true}
                                                        changeStudentFolderToo={true}
                                                    /> : ''}

                                                {this.props.folderFrom === FOLDER_TRASHBIN ?
                                                    <ChangeFolder
                                                        history={this.props.history}
                                                        onMoveFromCurrent={this.props.onMoveFromCurrent}
                                                        onSingleGoalMoved={this.props.onSingleGoalMoved}
                                                        onBeforeMove={this.props.onBeforeMove}
                                                        onAllGoalsMoved={this.props.onAllGoalsMoved}
                                                        teacherBaseProps={this.props.teacherBaseProps}
                                                        teacherId={this.props.teacherBaseProps.teacher.id}
                                                        student={this.props.teacherBaseProps.studentDict[this.props.studentId]}
                                                        totalIEPGoals={
                                                            this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.studentIEPDict &&
                                                                this.props.teacherBaseProps.studentIEPDict.hasOwnProperty(this.props.studentId)
                                                                ?
                                                                this.props.teacherBaseProps.studentIEPDict[this.props.studentId]
                                                                :
                                                                []
                                                        }
                                                        IEPGoals={[this.props.iep]}
                                                        folderFrom={this.props.folderFrom}
                                                        folderTo={FOLDER_PERMANENTLY_DELETED}
                                                        isNotStudent={true}
                                                        changeStudentFolderToo={true}
                                                    /> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </div>
        )
    }
}

export default IEPNavigation
