import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Input, Layout, Button, Icon, Menu, Select, notification, Popconfirm } from 'antd'
import { Document, getTextFromDocument } from '../teacher/edvizv2/Edviz'
const Sider = Layout.Sider
const Content = Layout.Content

const TEST_DISTRICT_ID = '64bec570267c8bfeea81f9ea'

class SuperAdminGoals extends Component {
    state = {
        districtId: '5410a3828725bd2f0d00100d',
        district: null,
        assessments: [],
        assessmentsDict: {},
        assessmentsWeekDict: {},
        selectedAssessment: null,
        menuKey: [],
        endWeek: null,
        endWeeks: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39],
        loadingData: false,
        replacingQuestionIdxs: {},
        districts: [
            { district: 'Clarendon', districtId: '5410a3828725bd2f0d00100d' },
            { district: 'Dillon', districtId: '521ce2f24da7037445000552' },
            // Do not add the regular #DEMO district here, as questions were copied over
            // from other districts and it can overwrite them!!!!
            // Kentucky is fine though.
            { district: '#DEMO Dot It Kentucky Sandbox District', districtId: '64e3cb7826043cd12a0f829e' }
        ],
        superAdminDistrictSettings: null,
        superAdminDistrictSettingsListener: null,
        questionReviewsDict: {},
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        if (this.state.superAdminDistrictSettingsListener) {
            this.state.superAdminDistrictSettingsListener()
        }
    }

    getQuizzesForDistrict = (setLoadingData) => {
        if (!this.state.districtId) {
            return
        }
        if (!this.state.endWeek) {
            return
        }

        if (setLoadingData) {
            this.setState({
                loadingData: true,
            })
        }

        if (this.state.superAdminDistrictSettingsListener) {
            this.state.superAdminDistrictSettingsListener()
        }
        let superAdminDistrictSettingsListener = db.collection(ColType.superAdminDistrictSettings)
            .doc(this.state.districtId)
            .onSnapshot((doc) => {
                let superAdminDistrictSettings = flattenDoc(doc)
                this.setState({
                    superAdminDistrictSettings: superAdminDistrictSettings,
                })
            })
        this.setState({
            superAdminDistrictSettingsListener: superAdminDistrictSettingsListener,
        })

        db.collection(ColType.district)
            .doc(this.state.districtId)
            .get()
            .then((doc) => {
                let district = flattenDoc(doc)
                this.setState({ district: district })
            })

        console.log('districtId', this.state.districtId, this.state.endWeek)
        db.collection(ColType.assessmentSC)
            .where('districtId', '==', this.state.districtId)
            .where('assessmentCategory', '!=', 'quiz')
            .where('endWeek', '==', this.state.endWeek)
            .get()
            .then((querySnapshot) => {
                let assessments = []
                let assessmentsDict = {}
                let assessmentsWeekDict = {}
                querySnapshot.forEach((doc) => {
                    let assessment = flattenDoc(doc)
                    assessment.docs = JSON.parse(assessment.docs)
                    // shuffle questions
                    assessment.docs.map((doc) => {
                        try {
                            // will throw error here before shuffle, determining if format is correct
                            let answers = doc.components[0].answers
                            // format is known, shuffle
                            doc.components[0].answers = shuffleArray(doc.components[0].answers)
                        } catch (e) {
                            // pass
                            return false
                        }

                        return false
                    })
                    assessments.push(assessment)
                    assessmentsDict[assessment.id] = assessment
                    if (!assessmentsWeekDict.hasOwnProperty(assessment.endWeek)) {
                        assessmentsWeekDict[assessment.endWeek] = {
                            assessments: [],
                        }
                    }
                    assessmentsWeekDict[assessment.endWeek].assessments.push(assessment)
                })
                assessments.sort((a, b) => {
                    if (a.standardName < b.standardName) {
                        return -1
                    }
                    if (a.standardName > b.standardName) {
                        return 1
                    }
                    if (a.standardCategory < b.standardCategory) {
                        return -1
                    }
                    if (a.standardCategory > b.standardCategory) {
                        return 1
                    }
                    if (a.endWeek < b.endWeek) {
                        return -1
                    }
                    if (a.endWeek > b.endWeek) {
                        return 1
                    }
                    return 0
                })
                if (setLoadingData) {
                    this.setState({
                        assessments: assessments,
                        assessmentsDict: assessmentsDict,
                        assessmentsWeekDict: assessmentsWeekDict,
                        selectedAssessment: null,
                        menuKey: [],
                        loadingData: false,
                    })
                } else {
                    this.setState({
                        assessments: assessments,
                        assessmentsDict: assessmentsDict,
                        assessmentsWeekDict: assessmentsWeekDict,
                    })
                }
                console.log('assessments', assessments)
            })
    }

    /*
    deleteAllCommonAssessments = () => {
        return
        this.state.assessments.map((assessment) => {
            if (assessment.assessmentCategory === 'common_assessment') {
                db.collection(ColType.assessmentSC)
                    .doc(assessment.id)
                    .delete()
                    .then(() => {
                        console.log('deleted')
                    })
                    .catch((error) => {
                        console.error('error', error)
                    })

            }
        })
    }
    */

    copyCommonAssessmentsToTestDistrict = () => {
        if (!this.state.assessments) {
            return
        }
        if (this.state.assessments.length === 0) {
            return
        }
        this.state.assessments.map((assessment) => {
            let uuid = 'ca_' + self.crypto.randomUUID();
            console.log("uuid", uuid)

            let copyAssessment = JSON.parse(JSON.stringify(assessment))
            copyAssessment.docs = JSON.stringify(copyAssessment.docs)
            copyAssessment.districtId = TEST_DISTRICT_ID
            copyAssessment.uuid = uuid
            // del id from flattenDoc (its just the uuid added to data when its read)
            delete copyAssessment.id

            db.collection(ColType.assessmentSC)
                .doc(uuid)
                .set(copyAssessment)
                .then(() => {
                    console.log('copied')
                })
                .catch((error) => {
                    console.error('error', error)
                })
        })
    }

    replaceQuestion = (assessment, questionIdx) => {
        const url = 'https://backend.dotit.app/replace-question'
        const data = {
            // get from input later
            'apiKey': 'EerGXor9kRDJ7qM',
            'assessmentUUID': assessment.uuid,
            'questionUUID': assessment.docs[questionIdx].metadata.uuid,
        }
        let replacingQuestionIdxs = this.state.replacingQuestionIdxs
        replacingQuestionIdxs[questionIdx] = true
        this.setState({
            replacingQuestionIdxs: replacingQuestionIdxs,
        })
        console.log('replaceQuestion', questionIdx, data)
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => {
                console.log('response', response)
                return response.json()
            })
            .then((data) => {
                console.log('data', data)
                if (data.success) {
                    const doc = data.result
                    let selectedAssessment = this.state.selectedAssessment
                    selectedAssessment.docs[questionIdx] = doc
                    this.setState({
                        selectedAssessment: selectedAssessment,
                    })

                    notification.open({
                        type: 'success',
                        duration: 10,
                        message: 'Question #' + (questionIdx + 1) + ' has been replaced successfully.',
                        placement: 'bottomRight'
                    })
                } else {
                    // err
                    setTimeout(() => {
                        this.getQuizzesForDistrict(false)
                    }, 250)

                    notification.open({
                        type: 'error',
                        duration: 5,
                        message: 'An error occurred. Question #' + (questionIdx + 1) + ' could not be replaced, try again. Attempting to refresh assessments...',
                        placement: 'bottomRight'
                    })
                }

                let replacingQuestionIdxs = this.state.replacingQuestionIdxs
                delete replacingQuestionIdxs[questionIdx]
                this.setState({
                    replacingQuestionIdxs: replacingQuestionIdxs,
                })
            })
            .catch((error) => {
                console.log('error', error)
                let replacingQuestionIdxs = this.state.replacingQuestionIdxs
                delete replacingQuestionIdxs[questionIdx]
                this.setState({
                    replacingQuestionIdxs: replacingQuestionIdxs,
                })

                setTimeout(() => {
                    this.getQuizzesForDistrict(false)
                }, 250)

                notification.open({
                    type: 'error',
                    duration: 5,
                    message: 'An error occurred. Question #' + (questionIdx + 1) + ' could not be replaced, try again. Attempting to refresh assessments...',
                    placement: 'bottomRight'
                })
            })
    }

    endWeekOnChange = (e) => {
        this.setState({
            endWeek: e,
        }, () => {
            this.getQuizzesForDistrict(true)
        })
    }

    districtOnChange = (e) => {
        this.setState({
            districtId: e,
        }, () => {
            this.getQuizzesForDistrict(true)
        })
    }

    onMenuChange = (selectedAssessment) => {
        if (!this.state.questionReviewsDict.hasOwnProperty(selectedAssessment.uuid)) {
            db.collection(ColType.questionReview)
                .where('districtId', '==', this.state.districtId)
                .where('assessmentUUID', '==', selectedAssessment.uuid)
                .orderBy('timeStamp', 'asc')
                .get()
                .then((querySnapshot) => {
                    let questionReviews = {}
                    querySnapshot.forEach((doc) => {
                        let questionReview = flattenDoc(doc)
                        if (!questionReviews.hasOwnProperty(questionReview.questionUUID)) {
                            questionReviews[questionReview.questionUUID] = []
                        }
                        questionReviews[questionReview.questionUUID].push(questionReview)
                    })
                    console.log('questionReviews', questionReviews)
                    let questionReviewsDict = this.state.questionReviewsDict
                    questionReviewsDict[selectedAssessment.uuid] = questionReviews
                    this.setState({
                        questionReviewsDict: questionReviewsDict,
                    })
                })
                .catch((error) => {
                    console.error('error', error)
                })
        }

        this.setState({
            selectedAssessment: selectedAssessment,
        })
        window.scrollTo(0, 0)

        //const docText = getTextFromDocument(selectedAssessment.docs[0].components[0].question)
        //console.log('question text', docText)
    }

    /*
    deleteAssessment = (assessment) => {
        db.collection(ColType.assessmentSC)
            .doc(assessment.id)
            .delete()
            .then(() => {
                console.log('deleted')
                this.getQuizzesForDistrict(false)
                this.setState({
                    selectedAssessment: null,
                })
                notification.open({
                    type: 'success',
                    duration: 4,
                    message: 'Assessment deleted successfully.',
                    placement: 'bottomRight'
                })
            })
            .catch((error) => {
                console.error('error', error)
                notification.open({
                    type: 'error',
                    duration: 4,
                    message: 'An error occurred. Assessment could not be deleted.',
                    placement: 'bottomRight'
                })
            })
    }
    */

    render() {
        return (
            <div>
                <SuperAdminHeader menuKey={'quiz-review'} />
                <div>
                    <Sider
                        width={270}
                        style={{
                            overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 64px)',
                            position: 'fixed',
                            left: 0, borderRight: '1px solid #f1f1f1',
                            background: '#fff',
                        }}>
                        <div style={{ marginTop: 64 }}>
                            <div className='pl-2 pr-2'>
                                <Select placeholder='Select district...' size='large'
                                    className='w-100'
                                    value={this.state.districtId}
                                    onChange={this.districtOnChange}>
                                    {this.state.districts.map((district, districtIdx) => {
                                        return <Select.Option key={districtIdx} value={district.districtId}>{district.district}</Select.Option>
                                    })}
                                </Select>

                                <Select
                                    value={this.state.endWeek}
                                    size='large'
                                    className='w-100 mt-1'
                                    placeholder={'Select week...'}
                                    onChange={this.endWeekOnChange}
                                >
                                    {this.state.endWeeks.map((endWeek, endWeekIdx) => {
                                        return <Select.Option key={endWeekIdx} value={endWeek}>{'Week ' + endWeek}</Select.Option>
                                    })}
                                </Select>

                            </div>
                            {this.state.district ?
                                <div className='mt-2 pl-2 pr-2 font-16'>
                                    <div className='font-bold'>
                                        {this.state.district.district}</div>
                                    <div>Common Assessments or Benchmarks</div>
                                    <Button className='mt-2 mb-2' size='large' type='primary'
                                        onClick={(e) => {
                                            this.getQuizzesForDistrict(false)
                                            notification.open({
                                                type: 'success',
                                                duration: 4,
                                                message: 'Assessment refreshed successfully.',
                                                placement: 'bottomRight'
                                            })
                                        }}>
                                        <Icon type='redo' />
                                        Refresh assessment
                                    </Button>
                                    <div className='mb-05'>Week {this.state.endWeek}</div>
                                </div> : ''}
                            {/*<Popconfirm title='Are you sure you want to delete all district common assessments?' onConfirm={(e) => {
                                this.deleteAllCommonAssessments()
                            }}>
                                <Button size='large' type='danger'
                                    className='flex flex-v-center flex-h-center mt-1'>
                                    <Icon type='delete' />
                                    Delete Common Assessments
                                </Button>
                        </Popconfirm>*/}
                            {/*
                            <Popconfirm title='Are you sure you want to copy all district common assessments?'
                                onConfirm={(e) => {
                                    this.copyCommonAssessmentsToTestDistrict()
                                }}>
                                <Button size='large' type='default'
                                    className='flex flex-v-center flex-h-center mt-1'>
                                    <Icon type='copy' />
                                    Copy to test district
                                </Button>
                            </Popconfirm>*/}
                            {!this.state.loadingData && this.state.assessments && this.state.assessments.length === 0 ?
                                <div className='font-16 pl-2 pr-2 mt-2'>No assessments found.</div>
                                : ''}
                            <Menu selectedKeys={this.state.menuKey} onSelect={(e) => {
                                console.log('menu change', e)
                                console.log(this.state.assessments, this.state.assessments[parseInt(e.key, 10)])
                                let keyAsInt = parseInt(e.key, 10)
                                this.setState({
                                    menuKey: [e.key],
                                })
                                this.onMenuChange(this.state.assessments[keyAsInt])
                            }}>
                                {!this.state.loadingData && this.state.assessments.map((assessment, assessmentIdx) => {
                                    return (
                                        <Menu.Item key={'' + assessmentIdx}>
                                            <div>
                                                <div>{assessment.standardCategory + ' ' + assessment.standardName}</div>
                                            </div>
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        </div>
                    </Sider>
                    <Layout className="content" style={{ marginLeft: 270, marginTop: "32px", overflowX: 'initial' }}>
                        <Content
                            className={"layout-content background-fff p-3 pt-0 br-4 print-remove-padding print-remove-margin"}
                        >
                            <div className='w-650 m-lr-auto'>
                                {/*this.state.selectedAssessment ?
                                    <Popconfirm title='Are you sure you want to delete this assessment?'
                                        cancelText='No'
                                        okText='Yes'
                                        onConfirm={(e) => {
                                            this.deleteAssessment(this.state.selectedAssessment)
                                        }}>
                                        <Button type='danger'>Delete</Button>
                                    </Popconfirm> : ''*/}
                                {this.state.selectedAssessment ?
                                    this.state.selectedAssessment.docs &&
                                    this.state.selectedAssessment.docs.map((lessonPlanItem, idx) => {
                                        return <div className='mt-2 font-black ' key={'assessment-idx-' + idx}>
                                            <div className='flex w-100'>
                                                <div>
                                                    <div className='font-30'>Question {idx + 1} - (DOK {lessonPlanItem.metadata.dokLevel})</div>
                                                    {this.state.superAdminDistrictSettings &&
                                                        this.state.superAdminDistrictSettings.hasOwnProperty('canRegen') &&
                                                        this.state.superAdminDistrictSettings.canRegen &&
                                                        this.state.superAdminDistrictSettings.canRegen.hasOwnProperty(this.state.endWeek) &&
                                                        this.state.superAdminDistrictSettings.canRegen[this.state.endWeek] ?
                                                        <Button type='primary' size='large'
                                                            disabled={this.state.replacingQuestionIdxs.hasOwnProperty(idx)}
                                                            className='font-20 mt-1 mb-1' onClick={(e) => {
                                                                this.replaceQuestion(this.state.selectedAssessment, idx)
                                                            }}>
                                                            {!this.state.replacingQuestionIdxs.hasOwnProperty(idx) ?
                                                                <div>
                                                                    <Icon type='sync' className='font-24 mr-1' />
                                                                    Replace question with a new one
                                                                </div>
                                                                :
                                                                <div>
                                                                    <Icon type='loading' className='font-24 mr-1' />
                                                                    Replacing question, this could take a minute...
                                                                </div>
                                                            }
                                                        </Button> : ''}
                                                    <Document
                                                        assessment={this.state.selectedAssessment}
                                                        components={lessonPlanItem.components}
                                                    />
                                                    <div className='mb-3 pb-3 border-bottom'>
                                                        <div className='font-20 mt-3 font-bold'>{'Standard - ' + lessonPlanItem.metadata.standard.standardCategory + ':' + lessonPlanItem.metadata.standard.standard + ' (DOK ' + lessonPlanItem.metadata.dokLevel + ')'}</div>
                                                        <div className='font-20'>{lessonPlanItem.metadata.standard.title}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {this.state.questionReviewsDict.hasOwnProperty(this.state.selectedAssessment.uuid) &&
                                                        this.state.questionReviewsDict[this.state.selectedAssessment.uuid].hasOwnProperty(lessonPlanItem.metadata.uuid) ?
                                                        this.state.questionReviewsDict[this.state.selectedAssessment.uuid][lessonPlanItem.metadata.uuid].map((questionReview, questionReviewIdx) => {
                                                            return <div className='mb-3 pb-3 border-bottom' key={'question-review-' + questionReviewIdx}>
                                                                <div className='font-20 mt-3 font-bold'>{'Question Review #' + (questionReviewIdx + 1)}</div>
                                                                <ul>
                                                                    {questionReview.reviewChoices.map((choice, choiceIdx) => {
                                                                        return <li key={'choice-' + choiceIdx}>{choice}</li>
                                                                    })}
                                                                </ul>
                                                                {questionReview.otherReviewMessage ?
                                                                    <div>{questionReview.otherReviewMessage}</div>
                                                                    : ''}
                                                            </div>
                                                        })
                                                        : ''}

                                                </div>
                                            </div>
                                        </div>
                                    })
                                    :
                                    <div className='font-20 mt-4'>No assessment selected, use the left side menu to select an assessment!</div>
                                }
                            </div>
                        </Content>
                    </Layout>
                </div>
            </div>
        )
    }
}

export default SuperAdminGoals