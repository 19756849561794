import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import Keywords from './Keywords'

class AccommodationView extends Component {
  state = {}

  componentDidMount() {

  }

  render() {
    let accommodationNum = 0
    let accommodationNums = {}
    if (this.props.iep && this.props.iep.hasOwnProperty('accommodations')) {
      if (this.props.iep.accommodations.hasOwnProperty('instructionBarrier')) {
        accommodationNum += 1
        accommodationNums["instructionBarrier"] = accommodationNum
      }
      if (this.props.iep.accommodations.hasOwnProperty('engagementBarrier')) {
        accommodationNum += 1
        accommodationNums["engagementBarrier"] = accommodationNum
      }
      if (this.props.iep.accommodations.hasOwnProperty('organizationBarrier')) {
        accommodationNum += 1
        accommodationNums["organizationBarrier"] = accommodationNum
      }
    }
    else if (this.props.student && this.props.student.hasOwnProperty('accommodations')) {
      if (this.props.student.accommodations.hasOwnProperty('instructionBarrier')) {
        accommodationNum += 1
        accommodationNums["instructionBarrier"] = accommodationNum
      }
      if (this.props.student.accommodations.hasOwnProperty('engagementBarrier')) {
        accommodationNum += 1
        accommodationNums["engagementBarrier"] = accommodationNum
      }
      if (this.props.student.accommodations.hasOwnProperty('organizationBarrier')) {
        accommodationNum += 1
        accommodationNums["organizationBarrier"] = accommodationNum
      }
    }

    return (
      <div>
        {this.props.iep && this.props.iep.hasOwnProperty('accommodations') ?
          <div>

            {this.props.iep.accommodations.hasOwnProperty('instructionBarrier') ?
              <div className="mb-2">
                <h3 className="mb-05 font-bold font-20">
                  {accommodationNums.hasOwnProperty('instructionBarrier') ?
                    "" + accommodationNums['instructionBarrier'] + ". "
                    : ''}
                  Presentation Strategy:
                </h3>
                <h3 className="inline-block font-20">
                  <Keywords paragraph={this.props.iep.accommodations.instructionBarrier + '.'} />
                </h3>
              </div>
              :
              ''}

            {this.props.iep.accommodations.hasOwnProperty('engagementBarrier') ?
              <div className="mb-2">
                <h3 className="mb-05 font-bold font-20">
                  {accommodationNums.hasOwnProperty('engagementBarrier') ?
                    "" + accommodationNums['engagementBarrier'] + ". "
                    : ''}
                  Engagement Strategy:
                </h3>
                <h3 className="inline-block font-20">
                  <Keywords paragraph={this.props.iep.accommodations.engagementBarrier + '.'} />
                </h3>
              </div>
              :
              ''}

            {this.props.iep.accommodations.hasOwnProperty('organizationBarrier') ?
              <div className="mb-4">
                <h3 className="mb-05 font-bold font-20">
                  {accommodationNums.hasOwnProperty('organizationBarrier') ?
                    "" + accommodationNums['organizationBarrier'] + ". "
                    : ''}
                  Expression Strategy:
                </h3>
                <h3 className="inline-block font-20">
                  <Keywords paragraph={this.props.iep.accommodations.organizationBarrier + '.'} />
                </h3>
              </div>
              :
              ''}

          </div>
          :
          this.props.student && this.props.student.hasOwnProperty('accommodations') ?
            <div>

              {this.props.student.accommodations.hasOwnProperty('instructionBarrier') ?
                <div className="mb-2">
                  <h3 className="mb-05 font-bold font-20">
                    {accommodationNums.hasOwnProperty('instructionBarrier') ?
                      "" + accommodationNums['instructionBarrier'] + ". "
                      : ''}
                    Presentation Strategy:
                  </h3>
                  <h3 className="inline-block font-20">
                    {this.props.student.accommodations.instructionBarrier}.
                  </h3>
                </div>
                :
                ''}

              {this.props.student.accommodations.hasOwnProperty('engagementBarrier') ?
                <div className="mb-2">
                  <h3 className="mb-05 font-bold font-20">
                    {accommodationNums.hasOwnProperty('engagementBarrier') ?
                      "" + accommodationNums['engagementBarrier'] + ". "
                      : ''}
                    Engagement Strategy:
                  </h3>
                  <h3 className="inline-block font-20">
                    {this.props.student.accommodations.engagementBarrier}.
                  </h3>
                </div>
                :
                ''}

              {this.props.student.accommodations.hasOwnProperty('organizationBarrier') ?
                <div className="mb-4">
                  <h3 className="mb-05 font-bold font-20">
                    {accommodationNums.hasOwnProperty('organizationBarrier') ?
                      "" + accommodationNums['organizationBarrier'] + ". "
                      : ''}
                    Expression Strategy:
                  </h3>
                  <h3 className="inline-block font-20">
                    {this.props.student.accommodations.organizationBarrier}.
                  </h3>
                </div>
                :
                ''}

            </div>
            :
            <h3>N/A</h3>
        }
      </div>
    )
  }
}

export default AccommodationView
