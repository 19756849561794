//import { rgb, PDFDocument, StandardFonts } from "pdf-lib"
/* 
import download from 'downloadjs'

export const CheckSVGPath = 'M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
export const CheckSVGScale = 0.032
export const FontColor = rgb(0, 0, 0)
*/

export function loadPDFFromDraft(draft) {
    return new Promise((resolve, reject) => {
        resolve()
    })
    /*
    return new Promise(async (resolve, reject) => {
        const existingPdfBytes = await fetch('/california/iep.pdf').then(res => res.arrayBuffer())
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // Embed the Helvetica font
        const drawFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        if (!draft.hasOwnProperty('step')) {
            return
        }
        if (!draft.step) {
            return
        }
        if (draft.step === 0) {
            return
        }

        if (draft.step >= 1) {
            writePDFStep0(pdfDoc, drawFont, draft)
        }
        if (draft.step >= 2) {
            writePDFStep1(pdfDoc, drawFont, draft)
        }
        if (draft.step >= 3) {
            writePDFStep2(pdfDoc, drawFont, draft)
        }

        let pdfDocumentFileName = draft.studentFirstName + '-' + draft.studentLastName + '.pdf'

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
        // Trigger the browser to download the PDF document
        download(pdfBytes, pdfDocumentFileName, "application/pdf")
        resolve()
    })
}

export function writePDFStep0(pdfDoc, drawFont, prefillObj) {
    // Get the first page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()
    console.log(width, height)

    const fontSize = 12
    const firstPageFirstLineY = height - 74
    const firstPageLineHeightY = 14
    const studentFirstNameWidth = drawFont.widthOfTextAtSize(prefillObj.studentFirstName, fontSize)
    const studentLastNameWidth = drawFont.widthOfTextAtSize(prefillObj.studentLastName, fontSize)
    const spaceWidth = drawFont.widthOfTextAtSize(' ', fontSize)
    const studentNameWidth = studentFirstNameWidth + spaceWidth + studentLastNameWidth

    firstPage.drawText(prefillObj.startDate, {
        x: 433,
        y: height - 53,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.endDate, {
        x: 513,
        y: height - 53,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentFirstName, {
        x: 202,
        y: firstPageFirstLineY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentLastName, {
        x: 202 + studentFirstNameWidth + spaceWidth,
        y: firstPageFirstLineY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentPronouns, {
        x: 202 + studentNameWidth + (spaceWidth * 2),
        y: firstPageFirstLineY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentIDNumber, {
        x: 150,
        y: firstPageFirstLineY - firstPageLineHeightY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentGrade, {
        x: 255,
        y: firstPageFirstLineY - firstPageLineHeightY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    const studentBirthdateWidth = drawFont.widthOfTextAtSize(prefillObj.studentBirthdate, fontSize)

    firstPage.drawText(prefillObj.studentBirthdate, {
        x: 183,
        y: (firstPageFirstLineY - firstPageLineHeightY * 2) - 1,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentAge, {
        x: 183 + studentBirthdateWidth + 3,
        y: (firstPageFirstLineY - firstPageLineHeightY * 2) - 1,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentPrimaryLanguage, {
        x: 220,
        y: (firstPageFirstLineY - firstPageLineHeightY * 3) - 1,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentPrimaryLanguageOfFamilyGuardian, {
        x: 273,
        y: (firstPageFirstLineY - firstPageLineHeightY * 4) - 2,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    const secondRowX = 410
    firstPage.drawText(prefillObj.schoolDistrict, {
        x: secondRowX,
        y: firstPageFirstLineY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.schoolOfResidenceOrChoice, {
        x: secondRowX + 80,
        y: firstPageFirstLineY - firstPageLineHeightY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.schoolTheStudentAttendsIfDifferent, {
        x: secondRowX + 127,
        y: (firstPageFirstLineY - firstPageLineHeightY * 2) - 1,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    let checkboxY = (firstPageFirstLineY - firstPageLineHeightY * 3) - 6
    if (prefillObj.IEPType === 'Initial') {
        firstPage.drawSvgPath(CheckSVGPath, {
            x: secondRowX - 83,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.IEPType === 'Annual') {
        firstPage.drawSvgPath(CheckSVGPath, {
            x: secondRowX - 24,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.IEPType === 'Amendment') {
        firstPage.drawSvgPath(CheckSVGPath, {
            x: secondRowX + 41,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    }
}

export function writePDFStep1(pdfDoc, drawFont, prefillObj) {
    // Get the first page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const secondPage = pages[1]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()
    console.log(width, height)

    const fontSize = 12
    const firstPageFirstLineY = height - 74
    const lineHeightY = 14
    const secondPageFirstLineY = height - 74
    const x = 42

    firstPage.drawText(prefillObj.studentGoodAt, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 12) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.familyStudentGoodAt, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 15) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.teacherStudentGoodAt, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 18) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentInterest, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 25) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.teamStudentInterest, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 28) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentSupports, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 35) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.studentCommunicates, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 38) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    firstPage.drawText(prefillObj.familyStudentPriorities, {
        x: x,
        y: (firstPageFirstLineY - lineHeightY * 45) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.teacherStudentPriorities, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 1) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.iepTeamInformation, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 7) - 10,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })
}

export function writePDFStep2(pdfDoc, drawFont, prefillObj) {
    // Get the second page of the document
    const pages = pdfDoc.getPages()
    const secondPage = pages[1]
    const thirdPage = pages[2]

    // Get the width and height of the second page
    const { width, height } = secondPage.getSize()
    console.log(width, height)

    const fontSize = 12
    const secondPageFirstLineY = height - 74
    const thirdPageFirstLineY = height - 12
    const lineHeightY = 14
    const x = 42
    const thirdPageX = 38

    secondPage.drawText(prefillObj.projectedGraduationDate, {
        x: x + 485,
        y: (secondPageFirstLineY - lineHeightY * 14),
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.goalNextWeek, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 18),
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.goalNextYear, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 21) + 2,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.goalNextThreeYears, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 23) - 7,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.goalNextFiveToTenYears, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 26) - 5,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.goalAfterHighSchool, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 30) - 5,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText(prefillObj.courseOfStudy, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 35) - 4,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    let checkboxY = (secondPageFirstLineY - lineHeightY * 36) - 8
    if (prefillObj.isAlternateRoute) {
        secondPage.drawSvgPath(CheckSVGPath, {
            x: x + 388,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else {
        secondPage.drawSvgPath(CheckSVGPath, {
            x: x + 446,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    }

    secondPage.drawText(prefillObj.alternateRouteJustification, {
        x: x,
        y: (secondPageFirstLineY - lineHeightY * 41) - 8,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    const creditsY = (secondPageFirstLineY - lineHeightY * 47) - 2
    secondPage.drawText('' + prefillObj.totalCreditsRequiredForGraduation, {
        x: x,
        y: creditsY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText('' + prefillObj.creditsEarnedAlready, {
        x: x + 184,
        y: creditsY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    secondPage.drawText('' + prefillObj.remainingCreditsForGraduation, {
        x: x + 342,
        y: creditsY,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })

    checkboxY = (thirdPageFirstLineY - lineHeightY * 3) + 2
    const checkboxDiffY = 17
    const checkboxFirstColumnX = thirdPageX - 2
    const checkboxSecondColumnX = thirdPageX + 254

    if (prefillObj.importantTransitions === 'None') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Entering preschool from early intervention') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY - checkboxDiffY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Entering elementary school (Kindergarten)') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY - (checkboxDiffY * 2),
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Entering middle school') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY - (checkboxDiffY * 3),
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Entering high school') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY - (checkboxDiffY * 4),
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Leaving high school') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY - (checkboxDiffY * 5),
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Transitioning to a new school') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxFirstColumnX,
            y: checkboxY - (checkboxDiffY * 6),
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Transitioning from a nonpublic placement') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxSecondColumnX,
            y: checkboxY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Transitioning to more or less time in general education') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxSecondColumnX,
            y: checkboxY - checkboxDiffY,
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Transitioning between available methods to participate in school (e.g., independent study, distance, hybrid, in-person learning') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxSecondColumnX,
            y: checkboxY - (checkboxDiffY * 3),
            color: FontColor,
            scale: CheckSVGScale,
        })
    } else if (prefillObj.importantTransitions === 'Other') {
        thirdPage.drawSvgPath(CheckSVGPath, {
            x: checkboxSecondColumnX,
            y: checkboxY - (checkboxDiffY * 5) - 10,
            color: FontColor,
            scale: CheckSVGScale,
        })
    }

    thirdPage.drawText('' + prefillObj.otherSpecify, {
        x: checkboxSecondColumnX + 105,
        y: checkboxY - (checkboxDiffY * 6) - 5,
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })
    thirdPage.drawText('' + prefillObj.studentPreparedDescription, {
        x: x - 1,
        y: checkboxY - (checkboxDiffY * 11),
        size: fontSize,
        font: drawFont,
        color: FontColor,
    })
     */
}