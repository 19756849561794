import React, { Component } from 'react';
import '../styles/GlobalStyle.css';
import { Modal } from 'antd';

class ModalVideo extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible && !this.props.visible) {
            this.pauseVideo();
        }
    }

    pauseVideo = () => {
        if (this.videoRef.current) {
            this.videoRef.current.pause();
        }
    };

    handleCancel = () => {
        this.pauseVideo();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    render() {
        const { title, visible, onOk, width, className, src } = this.props;

        return (
            <Modal
                title={title}
                visible={visible}
                onOk={onOk}
                onCancel={this.handleCancel}
                footer={null}
                width={width}
                className={className}
            >
                <div>
                    <video
                        ref={this.videoRef}
                        className='video-player w-100'
                        style={{ objectFit: 'cover' }}
                        height={500}
                        controls
                    >
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </Modal>
        );
    }
}

export default ModalVideo;